import React, { useEffect, useState } from "react";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import ReactSelect from "../../../common/ReactSelect";
import { Button } from "primereact/button";
import axiosInstance from "../../../_helper/Interceptor";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditDoctorChemistMap = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_DOCTORCHEMISTMAP_API?.toString();
  console.log(apiname)
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    error,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: doctorchemistmap }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );


  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error: doctorchemistError,
    data: response,
  }: any = useCreateByID(apiname, undefined);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselecteddoctor({
          label: data.Doctor,
          value: data.DoctorId,
        });

        let newData: any =
          data?.ChemistList?.map((d: any) => ({
            label: d?.Value,
            value: d?.Id,
          }));
        setselectedchemist([...newData]);
        setValue("ChemistIdList", newData.map((data: any) => data.value))
      }


    }
  }, [data]);

  const [selecteddoctor, setselecteddoctor]: any = useState(null);
  const [selectedchemist, setselectedchemist]: any = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const doctorchemistFormData = (data: any) => {

    data.DoctorId = typeof data.DoctorId === "object" ? Number(data.DoctorId.value) : Number(data.DoctorId);
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (doctorchemistError?.response?.data?.StatusCode !== 400) {
        toast.error(doctorchemistError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Doctor Chemist`}
        modal
        style={{ width: "1250px" }}
      // footer={
      //   <div className="text-center">
      //     <Button
      //       label="Back"
      //       severity="secondary"
      //       onClick={() => setisOPen(false)}
      //       outlined
      //     />
      //     {id === 0 ? (
      //       <Button
      //         label="Reset"
      //         severity="danger"
      //         onClick={() => {
      //           reset();
      //         }}
      //         outlined
      //       />
      //     ) : (
      //       ""
      //     )}

      //     {isLoading ? (
      //       <Button
      //         onClick={handleSubmit(doctorchemistFormData)}
      //         outlined
      //         disabled
      //       >
      //         <span className="p-button-label p-c mr-1">Submit</span>{" "}
      //         <Spinner width={"1rem"} height={"1rem"} />
      //       </Button>
      //     ) : (
      //       <Button onClick={handleSubmit(doctorchemistFormData)} outlined>
      //         <span className="p-button-label p-c">Submit</span>
      //       </Button>
      //     )}
      //   </div>
      // }
      >
        {!doctorchemistmap ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(doctorchemistFormData);
              }
            }}
            onSubmit={handleSubmit(doctorchemistFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Doctor"
                name={"DoctorId"}
                isRequired
                Disabled={id > 0 ? true : false}
                onChange={(e: any) => {

                  axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${e?.value.value}`).then((res) => {

                    const newChemistData = res?.data?.Data?.ChemistList?.map((d: any) => {
                      return {
                        label: d?.Value,
                        value: d?.Id,
                      }
                    });
                    setselectedchemist([...newChemistData]);
                    setValue("ChemistIdList", newChemistData.map((data: any) => data.value))

                  }).catch((err) => { setselectedchemist(null); setValue("ChemistIdList", null) })
                  setselecteddoctor(e);
                  setValue("DoctorId", e?.value);

                }}
                value={selecteddoctor}
                control={control}
                api={process.env.REACT_APP_DOCTORDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DoctorId?.message ||
                  error?.response?.data?.errors?.DoctorId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Doctor is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Chemist"
                name={"ChemistIdList"}
                isRequired
                Multi
                onChange={(e: any) => {
                  setselectedchemist(e);
                  console.log(e, "data of e")
                  setValue("ChemistIdList", e.map((data: any) => {
                    if (typeof data?.value == "object") {

                      return data?.value?.value
                    }
                    else {
                      return data?.value
                    }

                  }

                  ));
                  setIsEdited(true);
                }}
                Disabled={selecteddoctor === null}
                value={selectedchemist}
                control={control}
                api={process.env.REACT_APP_CHEMIST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.ChemistIdList?.message ||
                  error?.response?.data?.errors?.ChemistIdList?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Chemist is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedchemist([]); setselecteddoctor(null); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2" disabled={id && !isEdited}>
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};