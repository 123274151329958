import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";

const PaymentInvoice = () => {
  const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString();
  const [selectedRow, setselectedRow]: any = useState([]);
  const [bool, setbool]: any = useState(null);

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "2%",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Payment Invoice</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Payment Invoice"
          list={true}
          LinkURL={"/layout/inventory/AddPaymentInvoice"}
        />
        <DatatableCommon
          apiname={apiname}
          selecteditem={selectedRow}
          setselecteditem={setselectedRow}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
        />
      </div>
    </div>
  );
};

export default PaymentInvoice;
