interface iPropsErrorMessage {
    message?: any
}

export const ErrorMessage = ({ message }: iPropsErrorMessage) => {
    return (
        <>
            <span className='text-danger'>{message}</span>
        </>
    )
}