import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../_helper/Interceptor";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Spinner } from "../../../common/Spinner";

const ViewTourPlan = () => {
  const location = useLocation();
  const apiname1 = process.env.REACT_APP_TOURPLAN_API?.toString();
  const navigate = useNavigate();
  const controller = new AbortController();
  const [state, setState]: any = useState([]);
  let [loader, setLoader] = useState(false);

  const getData = () => {
    setLoader(true);
    axiosInstance
      .get(`v1/Reporting/TourPlan/GetTourPlanByMR?Date=${location?.state}`, { signal: controller.signal })
      .then((e) => {
        setLoader(false);
        console.log(e.data.Data);
        setState(e.data.Data);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    }
  }, [location]);

  const [deleteID, setdeleteID]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const handleApproval = async () => {
    const data = {
      Date: location.state,
    };
    console.log(location);
    await axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_TOURPLANSTATUS_API}`,
        data
      )
      .then((res) => {
        toast.success("Send Approval Successfull", { position: "top-right" });
        navigate("/layout/mr/calender");
      });
  };

  return (
    <>
      <Helmet>
        <title>View Tour Plan</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>View Tour Plan</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Tour Plan
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* {!isLoading ? ( */}
      <div className="pd-20 card-box mb-30 table-responsive">
        {loader ? (
          <div className="col-md-12 text-center align-self-center">
            <Spinner width={"20rem"} height={"20rem"} />
          </div>
        ) : (
          <>
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Plan(City)</th>
                  <th scope="col">Doctor</th>
                  <th scope="col">Hospital</th>
                  <th scope="col">Chemist</th>
                  <th scope="col">Stockist</th>
                  <th scope="col">Event</th>
                  <th scope="col">On Transist</th>
                  <th scope="col">Action</th>
                  {/* {state?.IsDisable === 0 && <th scope="col">Action</th>} */}
                </tr>
              </thead>
              <tbody>
                {state?.TourPlanViewModels?.map((e: any, i: any) => {

                  const doctorvisit = e?.doctorVisitViewModels?.map(
                    (ab: any, index: any) => {
                      return <span style={{ display: "block" }}>{`${index + 1}) `}Dr. {ab.DoctorName}</span>;
                    }
                  );
                  const chemistvisit = e?.chemistVisitViewModels?.map(
                    (ab: any, index: any) => {
                      return <span style={{ display: "block" }}>{`${index + 1}) `}{ab.ChemistName}</span>;
                    }
                  );
                  const hospitalvisit = e?.hospitalVisitViewModels?.map(
                    (ab: any, index: any) => {
                      return <span style={{ display: "block" }}>{`${index + 1}) `}{ab.HospitalName}</span>;
                    }
                  );

                  const stockistvisit = e?.stockistVisitViewModels?.map(
                    (ab: any, index: any) => {
                      return <span style={{ display: "block" }}>{`${index + 1}) `}{ab.StockistName}</span>;
                    }
                  );

                  const cityvisit = e?.toutPlanAreaMapViewModels?.map(
                    (ab: any, index: any) => {
                      return <span style={{ display: "block" }}>{`${index + 1}) `} {ab.CityName}</span>;
                    }
                  );

                  const eventname = e?.nwDayViewModel?.EventName;

                  const transist =
                    e.nwDayViewModel == null
                      ? ""
                      : e?.nwDayViewModel?.OnTransist === false
                        ? "No"
                        : "Yes";

                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{e?.TourDate.split("T")[0]}</td>
                        <td>{e?.RouteName || cityvisit}</td>
                        <td>{doctorvisit}</td>
                        <td>{hospitalvisit}</td>
                        <td>{chemistvisit}</td>
                        <td>{stockistvisit}</td>
                        <td>{eventname}</td>
                        <td>{transist}</td>
                        <td>
                          <Action
                            id={e.TourPlanId}
                            editurl="layout/mr/edittourplan"
                            displayid={deleteiddetect}
                            flag
                          />
                        </td>

                        {/* {state?.IsDisable === 0 && (
                      <td>
                        <Action
                          id={e.TourPlanId}
                          editurl="layout/mr/edittourplan"
                          displayid={deleteiddetect}
                          flag
                        />
                      </td>
                    )} */}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="col-md-12 text-center align-self-center">
              <Link
                to="/layout/mr/calender"
                className="btn btn-sm btn-secondary mr-1"
              >
                Back
              </Link>
              {state?.SendForApprovalStatus && (
                <button
                  onClick={handleApproval}
                  className="btn btn-sm btn-primary"
                >
                  Send For Approval
                </button>
              )}
            </div>
          </>
        )}
      </div>
      {/* ) : ( */}
      {/* <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div> */}
      {/* )} */}

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            // refetch();
          }
        }}
        apiname={apiname1}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default ViewTourPlan;
