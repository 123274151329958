import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCreateByID } from "../../../hooks/Api/Api";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { RejectModal } from "../../../common/RejectModal";
import { Helmet } from "react-helmet";
import { Spinner } from "../../../common/Spinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Badge } from "primereact/badge";

const ShowTourPlan = () => {
  const { id, name } = useParams();
  const [modalopen, setmodalopen] = useState(false);
  const navigate = useNavigate();
  const [modelSuccess, setmodelSuccess] = useState(false);
  const apiname = process.env.REACT_APP_MONTHLYADMINAPPROVAL_API?.toString();
  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );

  const finalData = {
    MtpId: "",
    Status: "Reject",
    Remark: "",
  };
  useEffect(() => {
    if (modelSuccess) {
      setmodelSuccess(false);
      navigate("/layout/mr/admin/tourplan");
    }
  }, [modelSuccess])

  const [gridData, setgridData] = useState([]);
  const [loader, setLoader] = useState(false);
  const fetchgridData = async () => {
    setLoader(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_TOURPLANGETTOURPLANBYMTP_API}?MTPId=${id}`
      )
      .then((res) => {
        setLoader(false);
        console.log(res.data.Data);
        let temp = res.data.Data.TourPlanViewModels.map((d: any, i: any) => {
          return { ...d, SrNo: i + 1 }

        })
        setgridData(temp);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.message, { position: "top-right" });
      });
  };

  useEffect(() => {
    fetchgridData();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Approved Successfull", { position: "top-right" });
      navigate("/layout/mr/admin/tourplan");
    }
    if (isError) {
      toast.error(error, { position: "top-right" });
    }
  }, [isSuccess]);

  const handleApprove = () => {
    const data = {
      MtpId: Number(id),
      Status: "Approve",
      Remark: "",
    };
    mutate(data);
  };

  const hospitalFunction = (e: any) => {
    return e.hospitalVisitViewModels.map((ak: any, i: any) => {
      return <span style={{ display: "block" }}><strong>{`${i + 1})  `}</strong>{ak.HospitalName} Hospital</span>;
    });
  };

  const doctorFunction = (e: any) => {
    return e.doctorVisitViewModels.map((ak: any, i: any) => {
      return <span style={{ display: "block" }}><strong>{`${i + 1})  `}</strong>{ak.DoctorName}</span>;
    });
  };

  const chemistFunction = (e: any) => {
    return e.chemistVisitViewModels.map((ak: any, i: any) => {
      return <span style={{ display: "block" }}><strong>{`${i + 1})  `}</strong>{ak.ChemistName}</span>;
    });
  };

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusId == 2
          ? "success"
          : e.StatusId == 7
            ? "primary"
            : "danger"
          }`}
      >
        {e.StatusId == 2 ? "Approved" : e.StatusId == 3 ? "Rejected" : e.StatusId}
      </span>
    );
  };

  return (
    <>
      <Helmet>
        <title>Show Tour Plan</title>
      </Helmet>
      <div>
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Show Tour Plan</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Show Tour Plan
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="pd-20 card-box mb-30">
          {loader ? (
            <div className="col-md-12 text-center align-self-center">
              <Spinner width={"15rem"} height={"15rem"} />
            </div>
          ) : (
            <>
              <DataTable
                value={gridData}
                tableStyle={{ minWidth: "50rem" }}
                showGridlines
                stripedRows
                size="small"
              >
                <Column
                  field="SrNo"
                  header="#"
                />
                <Column
                  header="Date"
                  body={(e: any) =>
                  // moment(e.TourDate).format("DD MMM, YYYY hh:mm A")
                  {
                    if (e.TourDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"success"}
                            value={moment(e.TourDate).format("DD MMM, YYYY")}
                          ></Badge>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={moment(e.TourDate).format("hh:mm A")}
                            style={{ backgroundColor: "#978EEB" }}
                          ></Badge>
                        </>
                      );
                    }
                  }

                  }


                ></Column>
                <Column field="RouteName" header="Plan(City)"></Column>
                <Column field="MrName" header="MR"></Column>
                <Column
                  header="Doctor"
                  body={(e: any) => doctorFunction(e)}
                ></Column>
                <Column
                  header="Hospital"
                  body={(e: any) => hospitalFunction(e)}
                ></Column>
                <Column
                  header="Chemist"
                  body={(e: any) => chemistFunction(e)}
                ></Column>
                <Column
                  header="Status"
                  body={(e: any) => statusFunction(e)}
                ></Column>
              </DataTable>
              <div className="col-md-12 text-center align-self-center mt-3">
                <Link
                  to="/layout/mr/admin/tourplan"
                  className="btn btn-primary btn-lg mr-1"
                >
                  Back
                </Link>
                {name == "P" && (
                  <>
                    {!isLoading ? (
                      <button
                        className="btn btn-success mr-1"
                        type="submit"
                        onClick={handleApprove}
                      >
                        Approve
                      </button>
                    ) : (
                      <button
                        className="btn btn-success mr-1"
                        type="submit"
                        onClick={handleApprove}
                        disabled
                      >
                        Approve <Spinner width={"1rem"} height={"1rem"} />
                      </button>
                    )}
                    <button
                      className="btn btn-danger mr-1"
                      type="button"
                      onClick={() => {
                        setmodalopen(true);
                      }}
                    >
                      Reject
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <RejectModal
        id={id}
        open={modalopen}
        setisOPen={setmodalopen}
        apiname={apiname}
        jsonData={finalData}
        successMessage={() => setmodelSuccess(true)}
      />
    </>
  );
};

export default ShowTourPlan;
