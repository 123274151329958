import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../../../common/PageHeader'
import DatatableCommon from '../../../common/DatatableCommon'
import { DeleteModal } from '../../../common/DeleteModal'
import { Action } from '../../../common/Action'
import { NullHandler } from "../../../common/NullHandler";


export const PermissionAction = () => {
    const apiname = process.env.REACT_APP_ACTIONPERMISSION_API?.toString();
    const [bool, setbool]: any = useState(null);
    const [deleteID, setdeleteID]: any = useState();
    const setDeleteidtoDelete = (id: any) => {
      setdeleteID(id);
    };
  
    const columns = [
      {
        header: "Action",
        body: (e: any) => actionActionPermissionTemplate(e),
        sortable: false,
        width:"1vw",
      },
      {
        field: "PermissionName",
        header: "Permission Name",
        body: (data: any) => {
          return NullHandler(data.PermissionName);
        },
        sortable: true,
        width:"10vw",
      },
      {
        field: "ControllerName",
        header: "Controller Name",
         body: (data: any) => {
          return NullHandler(data.ControllerName);
        },
        sortable: true,
        width:"10vw",
      },
      {
        field: "ActionName",
        header: "Action Name",
         body: (data: any) => {
          return NullHandler(data.ActionName);
        },
        sortable: true,
        width:"10vw",
      },
     
    ];
  
    const actionActionPermissionTemplate = (id: any) => {
      return (
        <>
          <Action
            id={id.ActionPermissionId}
            editurl={"layout/admin/permission/editpermissionaction"}
            displayid={setDeleteidtoDelete}
          />
        </>
      );
    };

  return (
    <>
       <Helmet>
        <title>Permission Action</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Permission Action"
          list={true}
          LinkURL={"/layout/admin/permission/addpermissionaction"}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          // check={true}
        />
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  )
}
