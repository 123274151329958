import React from 'react';
import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditAttendanceStatus } from './AddEditAttendanceStatus';
import DatatableCommon from "../../../common/DatatableCommon";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from 'primereact/api';

export const AttendanceStatus = () => {
  const apiname = process.env.REACT_APP_ATTENDANCESTATUS_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedRows, setSelectedRows]: any = useState([]);
  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };
  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionAttendanceStatusTemplate(e),
      width: "30%",
    },
    {
      field: "Name",
      header: "Name",
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Name",
      body: null,
      width: "10vw",
    },

  ];
  const filterJson = {
    Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  const [updateId, setupdateId]: any = useState(null);

  const actionAttendanceStatusTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };
  return (

    <>
      <Helmet>
        <title> Attendance Status </title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Attendance Status"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/eventattributes"}
          linkflagtitle={"Event Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          filterJson={filterJson}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}

        />


        {updateId !== null && (
          <AddEditAttendanceStatus
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />

        )}
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />

    </>

  )

}
