import { Link } from "react-router-dom";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button"

interface iPropsaaction {
  id?: number;
  editurl?: string;
  displayid?: any;
  doctorid?: any;
  addDetail?: any;
  onClick?: any;
  editModal?: any;
  viewDetail?: any;
  flag?: boolean;
  userId?: number;
  lockUnlockEmployee?: any;
  islocked?: any;
  open?: any;
  setisOPen?: any;
}

export const Action = ({
  id,
  editurl,
  displayid,
  doctorid,
  addDetail,
  viewDetail,
  onClick = () => displayid(id),
  editModal,
  flag = false,
}: iPropsaaction) => {



  return (
    <>
      {doctorid != null || doctorid != undefined ? (
        <button className="btn p-0" title="View">
          <i
            className="pi pi-eye mr-1 text-primary"
            onClick={() => editModal(id)}
          ></i>
        </button>
      ) : editurl != null || editurl != undefined ? (
        <Link to={`/${editurl}/${id}`}>
          <button className="btn p-0" title="View">
            <i className="pi pi-eye mr-1 text-primary"></i>
          </button>
        </Link>
      ) : (
        <button className="btn p-0" title="View">
          <i
            className="pi pi-eye mr-1 text-primary"
            onClick={() => editModal(id)}
          ></i>
        </button>
      )}

      {!flag && (
        <>
          <button className="btn p-0" title="Delete">
            <i className="pi pi-trash mr-1 text-danger" onClick={onClick}></i>
          </button>

        </>
      )}
      {addDetail && (
        <Link to={`/${addDetail}/${id}`} title="Addition Details">
          <button className="btn p-0">
            <i className="pi pi-plus"></i>
          </button>
        </Link>
      )}


    </>
  );
};
