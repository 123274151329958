import React, { useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import AddEditContact from "./AddEditContact";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";

const Contact = () => {
  const apiname = process.env.REACT_APP_CONTACT_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedRows, setSelectedRows]: any = useState([]);

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [updateId, setupdateId]: any = useState(null);

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "2%",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      // frozen:true,
      body: (e: any) => actionContactTemplate(e),
      width: "6%",
    },
    {
      field: "Name",
      // frozen:true,
      bodyClassName: "text-info h6",
      header: "Name",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by name",
      body: null,
      width: "10vw",
    },
    {
      field: "Email",
      header: "Email",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Email);
      },
      width: "10vw",
    },
    {
      field: "PhoneNumber",
      header: "Phone No.",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.PhoneNumber);
      },
      width: "10vw",
    },
    {
      field: "Address",
      header: "Address",
      sortable: true,
      // body: (data: any) => {
      //   return NullHandler(data.Address);
      // },
      body: (data: any) => {
        return (
          <p
            title={data.Address}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {data.Address === "" ? " - " : data.Address}
          </p>
        );
      },
      width: "10vw",
    },
  ];

  const actionContactTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Networking Contact"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditContact
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default Contact;
