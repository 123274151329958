import { useEffect } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../../common/Spinner";
import { Input } from "../../../../common/Input";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

const AddEditPTRPTSSetting = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_PTRPTSSETTING_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: cityLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      console.log(data);
      reset(data);
    }
  }, [data]);

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const ptrptsFormData = (data: any) => {
    data.IsDefault = true;
    data.MedicalMarginNumber = Number(data.MedicalMarginNumber);
    data.AgeneyMargin = Number(data.AgeneyMargin);
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("PTR PTS Setting Added Successfully", {
          position: "top-right",
        });
        fetch(true);
      } else {
        toast.success("PTR PTS Setting Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      toast.error(error?.response?.data?.Message, { position: "top-right" });
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} PTS PTR Setting`}
        modal
        style={{ width : "1100px" }}
      >

        {!cityLoader ? (
          <form
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.key === "NumpadEnter") {
              handleSubmit(ptrptsFormData);
            }
          }}
          onSubmit={handleSubmit(ptrptsFormData)}
        >
              <div className="row">
                <Input
                  containerClass="col-lg-12"
                  label="PTR PTS Name"
                  isRequired
                  type="text"
                  register={{
                    ...register("PTrptssettingName", {
                      required: {
                        value: true,
                        message: "Please Enter PTR PTS Setting Name",
                      },
                      pattern: {
                        value: /^.{1,50}$/gm,
                        message:
                          "Enter a valid ptr pts setting name (1-50 alphanumeric characters only)",
                      }
                    }),
                  }}
                  onChange={(e:any) => {setValue("PTrptssettingName", e.target.value); clearErrors(["PTrptssettingName"])}}
                  errorMessage={
                    errors?.PTrptssettingName?.message ||
                    error?.response?.data?.errors?.PTrptssettingName?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-12"
                  label="Medical Margin Number"
                  isRequired
                  type="text"
                  register={{
                    ...register("MedicalMarginNumber", {
                      required: {
                        value: true,
                        message: "Please Enter Medical Margin Number",
                      },
                      pattern: {
                        value: /^\d{0,5}$/,
                        message:
                          "Enter a valid medical margin number (1-5 digits only)",
                      }
                    }),
                  }}
                  onChange={(e:any) => {setValue("MedicalMarginNumber", e.target.value); clearErrors(["MedicalMarginNumber"])}}
                  errorMessage={
                    errors?.MedicalMarginNumber?.message ||
                    error?.response?.data?.errors?.MedicalMarginNumber?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-12"
                  label="Agency Margin"
                  isRequired
                  type="text"
                  register={{
                    ...register("AgeneyMargin", {
                      onchange,
                      required: {
                        value: true,
                        message: "Please Enter Agency Margin",
                      },
                      pattern: {
                        value: /^\d{0,5}$/,
                        message:
                          "Enter a valid agency margin number (1-5 digits only)",
                      }
                    }),
                  }}
                  errorMessage={
                    errors?.AgeneyMargin?.message ||
                    error?.response?.data?.errors?.AgeneyMargin?.map(
                      (e: any) => e
                    )
                  }
                />

<div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>


              </div>
            </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditPTRPTSSetting;
