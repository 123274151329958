import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginData } from "../../hooks/login/useLoginData";
import { Helmet } from "react-helmet";
import { Input } from "../../common/Input";
import { Spinner } from "../../common/Spinner";
import moduleName from "../../assets/images/login-page-img.png";
import "./styles.css";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/Animation - 1710499922684.json";

const invisblity = (
  <i id="eyeicon" className="fa fa-eye-slash" aria-hidden="true"></i>
);
const eye = <i id="eyeicon" className="fa fa-eye" aria-hidden="true"></i>;

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  }: any = useForm();
  const { mutate, isSuccess, isError, error, isLoading }: any = useLoginData();

  const location = useLocation();

  const role = localStorage.getItem("Roles");

  useEffect(() => {

    if (location) {
      console.log(location.state, "location");
      setValue("LoginId", location.state);
    }
  }, [location]);

  const navigate = useNavigate();

  const [passwordShown, setPasswordShown]: any = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const loginFormData = (data: any) => {

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  if (isSuccess) {
    toast.success("Login Successfully Done");
    // {
    //   role === "MR" ||
    //   role === "BDM" ||
    //   role === "ASM" ||
    //   role === "RSM" ||
    //   role === "ZSM"
    //     ? navigate("/layout/dashboard")
    //     : navigate("/layout/dashboard");
    // }

    if (role !== "admin") {
      navigate("/layout/dashboard");
    }
    else {
      navigate("/layout/maindashboard");
    }
  }

  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError]);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div
        className="login-wrap d-flex align-items-center flex-wrap justify-content-center"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-8 ">
              {/* <img src={moduleName} alt="" /> */}
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{ width: 700, height: 700 }}
              />
            </div>
            <div className="col-md-6 col-lg-4 ">
              <div className="login-box bg-white box-shadow border-radius-10">
                <div className="login-title text-center">
                  <img
                    src="https://bioslab.co.in/images/logo.svg"
                    width="165"
                    height="42"
                    alt="Bios"
                  />
                </div>

                <form
                  onSubmit={handleSubmit((logininfo: any) => {
                    loginFormData(logininfo);
                  })}
                >
                  <Input
                    containerClass="col-lg-12"
                    label="Username"
                    type="text"
                    placeholder="Please enter username"
                    isRequired
                    errorMessage={
                      errors?.LoginId?.message ||
                      error?.response?.data?.errors?.LoginId?.map((e: any) => e)
                    }
                    onChange={(e: any) => {
                      setValue("LoginId", e.target.value);
                      clearErrors(["LoginId"]);
                    }}
                    register={{
                      ...register("LoginId", {
                        required: {
                          value: true,
                          message: "This Field is required",
                        },
                      }),
                    }}
                  />

                  <div className="pass-wrapper">
                    <Input
                      containerClass="col-lg-12"
                      label="Password"
                      type={passwordShown ? "text" : "password"}
                      placeholder="Please enter password"
                      isRequired
                      errorMessage={
                        errors?.Password?.message ||
                        error?.response?.data?.errors?.Password?.map(
                          (e: any) => e
                        )
                      }
                      onChange={(e: any) => {
                        setValue("Password", e.target.value);
                        clearErrors(["Password"]);
                      }}
                      register={{
                        ...register("Password", {
                          required: {
                            value: true,
                            message: "This Field is required",
                          },
                        }),
                      }}
                    />
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ? eye : invisblity}
                    </i>
                  </div>

                  <div className="row pb-30">
                    <div className="offset-6 col-6">
                      <div className="forgot-password">
                        <Link to="/forgetPassword">Forgot Password?</Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 text-center">
                      {isLoading ? (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled
                        >
                          Sign In <Spinner width={"1rem"} height={"1rem"} />
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Sign In
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
