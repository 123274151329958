import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DatatableCommon from "../../../common/DatatableCommon";
import { NullHandler } from "../../../common/NullHandler";
import moment from "moment";
import { AddEditSampleGrid } from "./AddEditSampleGrid";

export const SampleGrid = () => {
  const apiname = process.env.REACT_APP_SAMPLTE_API?.toString();
  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3%",
    },
    {
      field: "action",
      header: "Action",
      // frozen: true,
      body: (data: any) => {
        return (
          // <button
          //   className="btn btn-primary"
          //   onClick={() => {
          //     setupdateId(data.SampleEmpMappingId);
          //   }}
          // >
          //   Edit
          // </button>

          <button
            className="btn"
            title="View"
            onClick={() => {
              setupdateId(data.SampleEmpMappingId);
            }}
          >
            <i className="pi pi-eye mr-1 text-primary"></i>
          </button>
        );
      },
      width: "5%",
    },
    {
      field: "EmpName",
      header: "Employee Name",
      // frozen: true,
      width: "3rem",
    },
    {
      field: "ProductName",
      header: "Product Name",
      // frozen: true,
      width: "3rem",
    },
    {
      field: "WarehouseName",
      header: "Warehouse Name",
      // frozen: true,
      width: "3rem",
    },
    {
      field: "Qty",
      header: "Quantity",
      // frozen: true,
      width: "3rem",
    },
    {
      field: "IssuedDate",
      header: "Issued Date",
      // frozen: true,
      body: (data: any) => {
        return NullHandler(
          data.IssuedDate == null
            ? ""
            : moment(data.IssuedDate).format("DD MMM, YYYY")
        );
      },
      width: "3rem",
    },
    {
      field: "RequestedDate",
      header: "Requested Date",
      // frozen: true,
      body: (data: any) => {
        return NullHandler(
          data.RequestedDate == null
            ? ""
            : moment(data.RequestedDate).format("DD MMM, YYYY")
        );
      },
      width: "3rem",
    },
    {
      field: "ReceivedDate",
      header: "Received Date",
      // frozen: true,
      body: (data: any) => {
        return NullHandler(
          data.ReceivedDate == null
            ? ""
            : moment(data.ReceivedDate).format("DD MMM, YYYY")
        );
      },
      width: "3rem",
    },

  ];
  const [updateId, setupdateId]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  return (
    <>
      <Helmet>
        <title>Sample Product Assignments</title>
      </Helmet>
      <div className="min-height-200px ">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Sample Product Assignments</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sample Product Assignments{" "}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={() => {
                  setupdateId(0);
                }}
              >
                Add Sample
              </button>
            </div>
          </div>
        </div>
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          showGridlines
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditSampleGrid
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>
    </>
  );
};
