import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateByID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import PaymentInvoice from "../../../common/PaymentInvoice";
import PurchaseSaleInvoiceCommon from "../../../common/PurchaseSaleInvoiceCommon";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import Textarea from "../../../common/Textarea";

const AddPurchaseReturnInvoice = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const apiname =
    process.env.REACT_APP_PURCHASERETURNINVOICE_API?.toString();

  const [selectReturnType, setselectedReturnType]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    reset,
  }: any = useForm();

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname);

  // const [paymentArray, setPaymentArray]: any = useState([]);
  const [purchaseDetailArray, setPurchaseDetailArray]: any = useState([]);

  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleTabChange = (accordion: any, e: any) => {
    if (accordion === activeAccordion && e.index === activeIndex) {
      setActiveAccordion(null);
      setActiveIndex(null);
    } else {
      setActiveAccordion(accordion);
      setActiveIndex(e.index);
    }
  };

  // Purchase Return Invoice
  const onPurchaseReturnSubmit = (data: any) => {
    let submitData = {};

    let dataFirst = { ...data, Discount: Number(data.Discount), VendorId : 1, WarehouseId : 1, PurchaseInvoiceId : 7 };

    let purchaseArray = purchaseDetailArray.map((e: any) => {
      return {
        ...e,
        ProductId: e?.ProductId?.value,
        Quantity: Number(e.Quantity),
        Discount: Number(e.Discount),
        MRP: Number(e.MRP),
        PurchasePrice: Number(e.PurchasePrice),
      };
    });

    submitData = {
      PurchaseReturnInvoiceToCreate: dataFirst,
      PurchaseReturnInvoiceDetails: purchaseArray,
    };

    mutate(submitData);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/inventory/PurchaseReturnInvoice");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);
  return (
    <div>
      <Helmet>
        <title>Add Purchase Return Invoice</title>
      </Helmet>

      <PageHeader
        headTitle="Purchase Return Invoice"
        headTitleURL={"/layout/inventory/PurchaseReturnInvoice"}
        list={false}
      />

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onPurchaseReturnSubmit)}>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="Return Type"
              name={"ReturnTypeId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setselectedReturnType(e);
                  setValue("ReturnTypeId", e?.value);
                } else {
                  setselectedReturnType(null);
                  setValue("ReturnTypeId", null);
                }
              }}
              value={selectReturnType}
              control={control}
              api={process.env.REACT_APP_INVENTORYRETURNTYPE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ReturnTypeId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Return Type is required" },
              }}
            />

            <Input
              containerClass="col-lg-6"
              label="Discount"
              type="number"
              isRequired
              onChange={(e: any) => {
                setValue("Discount", e.target.value);
                clearErrors(["Discount"]);
              }}
              register={{
                ...register("Discount", {
                  required: {
                    value: true,
                    message: "Discount is required",
                  },
                }),
              }}
              errorMessage={
                errors?.Discount?.message ||
                error?.response?.data?.errors?.Discount?.map((e: any) => e)
              }
            />

            <Textarea
              containerClass="col-lg-12"
              label="Note"
              isRequired
              register={{
                ...register("Note", {
                  required: {
                    value: true,
                    message: "Note is required",
                  },
                }),
              }}
              maxLength={500}
              onChange={(e: any) => {
                setValue("Note", e.target.value);
                clearErrors(["Note"]);
              }}
              errorMessage={
                errors?.Note?.message ||
                error?.response?.data?.errors?.Note?.map((e: any) => e)
              }
            />
          </div>

          {/* Purchase Invoice Details */}
          <PurchaseSaleInvoiceCommon
            purchaseDetailArray={purchaseDetailArray}
            setPurchaseDetailArray={setPurchaseDetailArray}
            //   activeIndex={activeAccordion === "Accordion1" ? activeIndex : null}
            //   onTabChange={(e: any) => handleTabChange("Accordion1", e)}
            type="purchasereturn"
          />

          {/* Payment Details */}
          {/* <PaymentInvoice
          paymentArray={paymentArray}
          setPaymentArray={setPaymentArray}
          activeIndex={activeAccordion === "Accordion2" ? activeIndex : null}
          onTabChange={(e: any) => handleTabChange("Accordion2", e)}
        /> */}

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/inventory/PurchaseReturnInvoice"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>

            {id === undefined ? (
              <button
                type="button"
                className="btn btn-outline-danger mt-2 mx-2"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </button>
            ) : (
              ""
            )}
            {isLoading ? (
              <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                Submit <Spinner width={"1rem"} height={"1rem"} />
              </button>
            ) : (
              <Button type="submit" color="primary" text="Submit"></Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPurchaseReturnInvoice;
