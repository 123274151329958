import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { useCreateByID } from "../../../hooks/Api/Api";
import moment from "moment";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import TourPlanLockedModel from "../../../common/TourPlanLockedModel";
import MessageModal from "../../../common/MessageModal";
const AddEditDailyTourPlan = () => {
  const { id } = useParams();
  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID("Reporting/DailyTourPlan", id);
  const [selectedRoute, setselectedRoute]: any = useState(null);
  const [selectedSubRoute, setselectedSubRoute]: any = useState("");
  const controller = new AbortController();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  }: any = useForm({
    defaultValues: {
      Route: null,
      SubRoute: 0,
      DoctorList: [],
      ChemistList: [],
      StockistList: [],
      HospitalList: [],
    },
  });

  const navigate = useNavigate();

  const getTodayDailyTourPlan = () => {
    axiosInstance
      .get("v1/Reporting/DailyTourPlan/GetTodayDailyTourPlanByMr", { signal: controller.signal })
      .then((e: any) => {
        if (e.data.Data != null && id === undefined) {
          navigate("/layout/mr/admin/dailyTouPlan");
        } else {
          getDCR();
        }
      }).catch((error) => {

      });
  };

  // useEffect(() => {
  //   getTodayDailyTourPlan();
  // }, []);

  useEffect(() => {
    if (id) {
      getById();
    }
    else {
      getTodayDailyTourPlan();
    }
    return () => {
      controller.abort();
    }
  }, [id]);

  const [State, setState]: any = useState(null);
  let [status, SetStatus] = useState(false);

  const getDCR = () => {
    axiosInstance.get("v1/Reporting/DCR", { signal: controller.signal }).then((e: any) => {
      let data = e.data.Data;

      if (data !== null) {
        if (data.RouteId !== null) {
          setselectedRoute({
            label: data.RouteName,
            value: data.RouteId,
          });
          setValue("Route", data.RouteId);
        }
        if (data.SubRouteName !== null) {
          setselectedSubRoute({
            label: data.SubRouteName,
            value: data.SubRouteId,
          });
        }
        if (data.nwDayViewModel === null) {
          if (data.DoctorList.length !== 0) {
            const DoctorList = data.DoctorList.map((e: any) => {
              return {
                DoctorId: {
                  label: e?.DoctorName,
                  value: e?.DoctorId,
                },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
              };
            });
            setValue("DoctorList", DoctorList);
            setValue("Doctor", DoctorList);
          }
          if (data.ChemistList.length !== 0) {
            const ChemistList = data.ChemistList.map((e: any) => {
              return {
                ChemistId: {
                  label: e?.ChemistName,
                  value: e?.ChemistId,
                },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
              };
            });
            setValue("ChemistList", ChemistList);
            setValue("Chemist", ChemistList);
          }
          if (data.HospitalList.length !== 0) {
            const HospitalList = data.HospitalList.map((e: any) => {
              return {
                HospitalId: {
                  label: e?.HospitalName,
                  value: e?.HospitalId,
                },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
              };
            });
            setValue("HospitalList", HospitalList);
            setValue("Hospital", HospitalList);
          }
          if (data.StockistList.length !== 0) {
            const StockistList = data.StockistList.map((e: any) => {
              return {
                StockistId: {
                  label: e?.StockistName,
                  value: e?.StockistId,
                },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
              };
            });
            setValue("StockistList", StockistList);
            setValue("Stockist", StockistList);
          }
        } else {
          console.log(data?.nwDayViewModel, "this is data");
          if (!data?.nwDayViewModel?.IsExecute) {
            setState(data?.nwDayViewModel);
          } else {
            SetStatus(data?.nwDayViewModel?.IsExecute);
          }
        }
      }
    }).catch((error) => {

    });
  };

  console.log(State, "this is state Value");

  const getById = () => {

    axiosInstance.get(`v1/Reporting/DailyTourPlan/${id}`, { signal: controller.signal }).then((e: any) => {
      console.log(e.data.Data);
      const data = e.data.Data;
      if (data.RouteId !== null) {
        setselectedRoute({
          label: data.RouteName,
          value: data.RouteId,
        });
      }
      setValue("Route", data.RouteId);
      if (data.SubRouteName !== null) {
        setselectedSubRoute({
          label: data.SubRouteName,
          value: data.SubRouteId,
        });
      }
      if (data.doctorVisitViewModels.length !== 0) {
        const DoctorList = data.doctorVisitViewModels.map((e: any) => {
          return {
            DoctorId: {
              label: e?.DoctorName,
              value: e?.DoctorId,
            },
            ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
          };
        });
        setValue("DoctorList", DoctorList);
        setValue("Doctor", DoctorList);
      }
      if (data.chemistVisitViewModels.length !== 0) {

        const ChemistList = data.chemistVisitViewModels.map((e: any) => {
          return {
            ChemistId: {
              label: e?.ChemistName,
              value: {
                label: e?.ChemistName,
                value: e?.ChemistId
              }
            },
            ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
          };
        });
        console.log(ChemistList, "chehhhfhh")
        setValue("Chemist", ChemistList);
        setValue("ChemistList", ChemistList);

      }
      if (data.stockistVisitViewModels.length !== 0) {
        const StockistList = data.stockistVisitViewModels.map((e: any) => {
          return {
            StockistId: {
              label: e?.StockistName,
              value: e?.StockistId,
            },
            ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
          };
        });
        setValue("StockistList", StockistList);
        setValue("Stockist", StockistList);
      }
      if (data.hospitalVisitViewModels.length !== 0) {
        const HospitalList = data.hospitalVisitViewModels.map((e: any) => {
          return {
            HospitalId: {
              label: e?.HospitalName,
              value: e?.HospitalId,
            },
            ExpectedVisitTime: moment(e?.ExpectedVisitTime).format("HH:mm"),
          };
        });
        setValue("HospitalList", HospitalList);
        setValue("Hospital", HospitalList);
      }
    }).catch((error) => {

    });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "DoctorList", // Name of your array field
  });

  const {
    fields: chemistField,
    append: chemistAppend,
    remove: chemistRemove,
  } = useFieldArray({
    control,
    name: "ChemistList", // Name of your array field
  });

  const {
    fields: stockistField,
    append: stockistAppend,
    remove: stockistRemove,
  } = useFieldArray({
    control,
    name: "StockistList", // Name of your array field
  });

  const {
    fields: hospitalField,
    append: hospitalAppend,
    remove: hospitalRemove,
  } = useFieldArray({
    control,
    name: "HospitalList", // Name of your array field
  });

  const onSubmit = (data: any) => {
    delete data.Hospital;
    delete data.Stockist;
    delete data.Chemist;
    delete data.Doctor;
    console.log(data, "submitted Data");
    const FinalDoctorList = data?.DoctorList?.map((e: any) => {
      return {
        ...e,
        DoctorId:
          typeof e.DoctorId?.value === "object"
            ? e.DoctorId?.value?.value
            : e.DoctorId?.value,
        ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      };
    });
    const FinalChemistList = data?.ChemistList?.map((e: any) => {
      return {
        ...e,
        ChemistId: e.ChemistId?.value?.value,
        ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      };
    });
    const FinalHospitalList = data?.HospitalList?.map((e: any) => {
      return {
        ...e,
        HospitalId: e.HospitalId?.value,
        ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      };
    });
    const FinalStockistList = data?.StockistList?.map((e: any) => {
      return {
        ...e,
        StockistId: e.StockistId?.value,
        ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
      };
    });
    console.log(selectedRoute, "error");
    const finalData: any = {
      RouteId: selectedRoute?.value,
      SubRouteId: selectedSubRoute?.value,
      DoctorIds: FinalDoctorList,
      ChemistIds: FinalChemistList,
      HospitalIds: FinalHospitalList,
      StockistIds: FinalStockistList,
    };

    if (id) {
      finalData.DailyTourPlanId = Number(id);
    }

    // if (data?.HospitalList.length == 0) {
    //   delete finalData.HospitalIds
    // }
    console.log(finalData, "data of submit");

    mutate(finalData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (!id) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/mr/admin/dailyTouPlan");
        reset();
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/mr/admin/dailyTouPlan");
        reset();
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Helmet>
        <title>{id !== undefined ? "Edit" : "Add"} Daily Tour Plan</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>{id !== undefined ? "Edit" : "Add"} Daily Tour Plan</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/tourprogram"}>Tour Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {id !== undefined ? "Edit" : "Add"} Daily Tour Plan
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <ReactSelect
              isRequired
              label="Route"
              containerClass="col-lg-6"
              control={control}
              onChange={(e: any) => {
                setValue("Route", e);
                setselectedRoute(e);
                setselectedSubRoute("");
                clearErrors(["Route"]);
              }}
              value={selectedRoute}
              api={process.env.REACT_APP_SRC_API}
              keys={{ label: "Value", value: "Id" }}
              name="Route"
              rules={{
                required: {
                  value: true,
                  message: "Route is required",
                },
              }}
              errorMessage={
                errors?.Route?.message ||
                error?.response?.data?.errors?.Route?.map((e: any) => e)
              }
            />
            <ReactSelect
              label="Sub Route"
              containerClass="col-lg-6"
              control={control}
              onChange={(e: any) => {
                setValue("SubRoute", e);
                setselectedSubRoute(e);
              }}
              value={selectedSubRoute}
              api={
                selectedRoute !== null
                  ? `${process.env.REACT_APP_SRCSUBROUTE_BY_SRCROUTEID_API}/${selectedRoute.value}`
                  : ""
              }
              keys={{ label: "Value", value: "Id" }}
              Disabled={selectedRoute === null ? true : false}
              name="SubRoute"
            />

            <div className="col-md-3">
              <div className="col-md-12 d-flex align-items-center">
                <div className="offset-md-6 col-md-6 text-right ">
                  <button
                    type="button"
                    onClick={() => {
                      append({
                        DoctorId: "",
                        ExpectedVisitTime: "",
                      });
                    }}
                    className="btn btn-sm btn-outline-primary "
                  >
                    Add Doctor
                  </button>
                </div>
              </div>
              {fields?.map((item: any, index: any) => {
                return (
                  <>
                    <div className="row">
                      <ReactSelect
                        register={{
                          ...register(`DoctorList.${index}.DoctorId`, {
                            required: {
                              value: true,
                              message: "Doctor  is required",
                            },
                          }),
                        }}
                        isRequired
                        label="Doctor"
                        containerClass="col-lg-12"
                        name={"Doctor"}
                        value={getValues(`DoctorList.${index}.DoctorId`)}
                        onChange={(e: any) => {

                          setValue(`DoctorList.${index}.DoctorId`, e);
                          setValue("Doctor", e?.value);
                          clearErrors([`DoctorList.${index}.DoctorId`]);
                        }}
                        control={control}
                        // api={process.env.REACT_APP_GETDOCTORFORDAILYTOURPLAN_API}
                        api={process.env.REACT_APP_DOCTORDETAIL_API}
                        keys={{ label: "Value", value: "Id" }}
                        errorMessage={
                          errors?.["DoctorList"]?.[index]?.["DoctorId"]?.message
                        }
                        rules={{
                          required: {
                            value: true,
                            message: "Doctor  is required",
                          },
                        }}
                      />

                      <Input
                        isRequired
                        containerClass="col-lg-12"
                        label="Expected Visit Time"
                        type="time"
                        errorMessage={
                          errors?.["DoctorList"]?.[index]?.["ExpectedVisitTime"]
                            ?.message
                        }
                        onChange={(e: any) => {
                          setValue(
                            `DoctorList.${index}.ExpectedVisitTime`,
                            e.target.value
                          );

                          clearErrors([
                            `DoctorList.${index}.ExpectedVisitTime`,
                          ]);
                        }}
                        register={{
                          ...register(`DoctorList.${index}.ExpectedVisitTime`, {
                            required: {
                              value: true,
                              message: "Expected Visit Time is required",
                            },
                          }),
                        }}
                      />
                      <div className="col-lg-12 text-center ">
                        <button
                          type="button"
                          onClick={() => {
                            remove(index);
                          }}
                          className="btn btn-sm btn-outline-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-md-3">
              <div className="col-md-12 d-flex align-items-center">
                <div className="offset-md-6 col-md-6 text-right ">
                  <button
                    type="button"
                    onClick={() => {
                      chemistAppend({
                        ChemistId: "",
                        ExpectedVisitTime: "",
                      });
                    }}
                    className="btn btn-sm btn-outline-primary "
                  >
                    Add Chemist
                  </button>
                </div>
              </div>
              {chemistField.map((item: any, index: any) => {
                return (
                  <>
                    <div className="row">
                      <ReactSelect
                        isRequired
                        register={{
                          ...register(`ChemistList.${index}.ChemistId`, {
                            required: {
                              value: true,
                              message: "Chemist  is required",
                            },
                          }),
                        }}
                        label="Chemist"
                        containerClass="col-lg-12"
                        control={control}
                        onChange={(e: any) => {
                          setValue(`ChemistList.${index}.ChemistId`, e);
                          setValue("Chemist", e);
                          clearErrors([`ChemistList.${index}.ChemistId`]);
                        }}
                        errorMessage={
                          errors?.["ChemistList"]?.[index]?.["ChemistId"]
                            ?.message
                        }
                        value={getValues(`ChemistList.${index}.ChemistId`)}
                        api={process.env.REACT_APP_CHEMIST_API}
                        keys={{ label: "Value", value: "Id" }}
                        name={"Chemist"}
                      />

                      <Input
                        containerClass="col-lg-12"
                        label="Expected Visit Time"
                        type="time"
                        isRequired
                        errorMessage={
                          errors?.["ChemistList"]?.[index]?.[
                            "ExpectedVisitTime"
                          ]?.message
                        }
                        onChange={(e: any) => {
                          setValue(
                            `ChemistList.${index}.ExpectedVisitTime`,
                            e.target.value
                          );
                          clearErrors([
                            `ChemistList.${index}.ExpectedVisitTime`,
                          ]);
                        }}
                        register={{
                          ...register(
                            `ChemistList.${index}.ExpectedVisitTime`,
                            {
                              required: {
                                value: true,
                                message: "Expected Visit Time",
                              },
                            }
                          ),
                        }}
                      />
                      <div className="col-lg-12 text-center">
                        <button
                          type="button"
                          onClick={() => {
                            chemistRemove(index);
                          }}
                          className="btn btn-sm btn-outline-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-md-3">
              <div className="col-md-12 d-flex align-items-center">
                <div className="offset-md-6 col-md-6 text-right ">
                  <button
                    type="button"
                    onClick={() => {
                      stockistAppend({
                        StockistId: "",
                        ExpectedVisitTime: "",
                      });
                    }}
                    className="btn btn-sm btn-outline-primary "
                  >
                    Add Stockist
                  </button>
                </div>
              </div>
              {stockistField?.map((item: any, index: any) => {
                return (
                  <>
                    <div className="row">
                      <ReactSelect
                        isRequired
                        label="Stockist"
                        register={{
                          ...register(`StockistList.${index}.StockistId`, {
                            required: {
                              value: true,
                              message: "Stockist is required",
                            },
                          }),
                        }}
                        containerClass="col-lg-12"
                        control={control}
                        onChange={(e: any) => {
                          console.log(
                            getValues(`StockistList.${index}.StockistId`)
                          );
                          setValue(`StockistList.${index}.StockistId`, e);
                          setValue("Stockist", e);
                          clearErrors([`StockistList.${index}.StockistId`]);
                        }}
                        errorMessage={
                          errors?.["StockistList"]?.[index]?.["StockistId"]
                            ?.message
                        }
                        value={getValues(`StockistList.${index}.StockistId`)}
                        api={process.env.REACT_APP_STOCKIST_API}
                        keys={{ label: "Value", value: "Id" }}
                        name={"Stockist"}
                      />

                      <Input
                        containerClass="col-lg-12"
                        label="Expected Visit Time"
                        type="time"
                        isRequired
                        errorMessage={
                          errors?.["StockistList"]?.[index]?.[
                            "ExpectedVisitTime"
                          ]?.message
                        }
                        onChange={(e: any) => {
                          setValue(
                            `StockistList.${index}.ExpectedVisitTime`,
                            e.target.value
                          );
                          clearErrors([
                            `StockistList.${index}.ExpectedVisitTime`,
                          ]);
                        }}
                        register={{
                          ...register(
                            `StockistList.${index}.ExpectedVisitTime`,
                            {
                              required: {
                                value: true,
                                message: "Expected Visit Time is required",
                              },
                            }
                          ),
                        }}
                      />
                      <div className="col-lg-12 text-center">
                        <button
                          type="button"
                          onClick={() => {
                            stockistRemove(index);
                          }}
                          className="btn btn-sm btn-outline-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-md-3">
              <div className="col-md-12 d-flex align-items-center">
                <div className="offset-md-6 col-md-6 text-right ">
                  <button
                    type="button"
                    onClick={() => {
                      hospitalAppend({
                        HospitalId: "",
                        ExpectedVisitTime: "",
                      });
                    }}
                    className="btn btn-sm btn-outline-primary "
                  >
                    Add Hospital
                  </button>
                </div>
              </div>
              {hospitalField.map((item: any, index: any) => {
                return (
                  <>
                    <div className="row">
                      <ReactSelect
                        isRequired
                        register={{
                          ...register(`HospitalList.${index}.HospitalId`, {
                            required: {
                              value: true,
                              message: "Hospital is required",
                            },
                          }),
                        }}
                        label="Hospital"
                        containerClass="col-lg-12"
                        control={control}
                        onChange={(e: any) => {
                          setValue(`HospitalList.${index}.HospitalId`, e);
                          setValue("Hospital", e);
                          clearErrors([`HospitalList.${index}.HospitalId`]);
                        }}
                        errorMessage={
                          errors?.["HospitalList"]?.[index]?.["HospitalId"]
                            ?.message
                        }
                        value={getValues(`HospitalList.${index}.HospitalId`)}
                        api={process.env.REACT_APP_HOSPITAL_API}
                        keys={{ label: "Value", value: "Id" }}
                        name={"Hospital"}
                      />

                      <Input
                        containerClass="col-lg-12"
                        label="Expected Visit Time"
                        type="time"
                        isRequired
                        errorMessage={
                          errors?.["HospitalList"]?.[index]?.[
                            "ExpectedVisitTime"
                          ]?.message
                        }
                        onChange={(e: any) => {
                          setValue(
                            `HospitalList.${index}.ExpectedVisitTime`,
                            e.target.value
                          );
                          clearErrors([
                            `HospitalList.${index}.ExpectedVisitTime`,
                          ]);
                        }}
                        register={{
                          ...register(
                            `HospitalList.${index}.ExpectedVisitTime`,
                            {
                              required: {
                                value: true,
                                message: "Expected Visit Time is required",
                              },
                            }
                          ),
                        }}
                      />
                      <div className="col-lg-12 text-center">
                        <button
                          type="button"
                          onClick={() => {
                            hospitalRemove(index);
                          }}
                          className="btn btn-sm btn-outline-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="col-lg-12 text-center mt-5">
              <Link
                to={"/layout/mr/admin/dailyTouPlan"}
                className="btn btn-primary m-3 text-center"
              >
                Back
              </Link>
              <button type="submit" className="btn btn-success ">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <TourPlanLockedModel
        open={State !== null}
        onClick={() => {
          let temp = {
            Id: State?.Id,
            EventId: State?.EventId !== null ? State?.EventId : "",
            Date: moment(State?.Date).format("YYYY-MM-DD"),
            OnTransist: State?.OnTransist,
          };

          if (temp?.EventId === "") {
            delete temp.EventId;
          }

          axiosInstance
            .put(`v1/Reporting/NwDays/${State?.Id}`, temp)
            .then((e) => {
              console.log(e.data.Data);
              setState(null);
              navigate("/layout/dashboard");
            })
            .catch((err) => {
              console.log(err, "this is err");
            });
        }}
        onClose={() => {
          setState(null);
        }}
      />

      <MessageModal
        open={status}
        onClose={() => {
          SetStatus(false);
          navigate("/layout/dashboard");
        }}
      />
    </>
  );
};

export default AddEditDailyTourPlan;
