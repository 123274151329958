import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../hooks/Api/Api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Spinner } from "../../common/Spinner";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { toast } from "react-toastify";
import { Address } from "../../common/Address";
import Textarea from "../../common/Textarea";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export const AddEditVendor = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_COMPANYVENDORSERVICE_API?.toString();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: VendorServiceLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const Hcity = watch("CityId");

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        if (
          data.VendorServiceTypeId !== null &&
          data.VendorServiceTypeId !== undefined
        ) {
          setValue("VendorServiceTypeId", {
            label: data.VendorServiceTypeName,
            value: data.VendorServiceTypeId,
          });
          setSelectedVendorType({
            label: data.VendorServiceTypeName,
          value: data.VendorServiceTypeId,
          });
        } else {
          setValue("VendorServiceTypeId", null);
          setSelectedVendorType(null);
        }

        if (data.CityId !== null) {
          setselectedcity({
            label: data.CityName,
            value: data.CityId,
          });
        } else {
          setselectedcity(null);
        }

        if (data.PinCodeId !== null) {
          setselectedpincode({
            label: data.PinCodeName,
            value: data.PinCodeId,
          });
        } else {
          setselectedpincode(null);
        }
      }
    }
  }, [data, id]);

  const [SelectedVendorType, setSelectedVendorType]: any = useState(null);
  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedpincode, setselectedpincode]: any = useState(null);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const VendorFormData = (data: any) => {
    data.CityId = selectedcity?.value;
    data.PinCodeId = selectedpincode?.value;
    data.VendorServiceTypeId = SelectedVendorType?.value;

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isSuccess, isError, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Vendor`}
        modal
        style={{ width: "1100px" }}
      >
        {!VendorServiceLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(VendorFormData);
              }
            }}
            onSubmit={handleSubmit(VendorFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Vendor Service Type"
                isRequired
                name={"VendorServiceTypeId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("VendorServiceTypeId", e?.value);
                    setSelectedVendorType(e);
                  } else {
                    setValue("VendorServiceTypeId", null);
                    setSelectedVendorType(null);
                  }
                }}
                value={SelectedVendorType}
                control={control}
                api={process.env.REACT_APP_VENDORSERVICETYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.VendorServiceTypeId?.message ||
                  error?.response?.data?.errors?.VendorServiceTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: { value: true, message: "Vendor Type is required" },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Name", e.target.value);
                  clearErrors(["Name"]);
                }}
                register={{
                  ...register("Name", {
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s',-]{1,50}$/,
                      message: "Enter a valid name (1-50 alphabet characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Phone No"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("Phone", e.target.value);
                  clearErrors(["Phone"]);
                }}
                register={{
                  ...register("Phone", {
                    required: {
                      value: true,
                      message: "Phone No is required",
                    },
                    pattern: {
                      value: /^\d{6,10}$/,
                      message: "Enter a valid phone no. (6-10 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Phone?.message ||
                  error?.response?.data?.errors?.Phone?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Contact Phone No"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("ContactPhone", e.target.value);
                  clearErrors(["ContactPhone"]);
                }}
                register={{
                  ...register("ContactPhone", {
                    required: {
                      value: true,
                      message: "Contact Phone No is required",
                    },
                    pattern: {
                      value: /^\d{6,10}$/,
                      message: "Enter a valid phone no. (6-10 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.ContactPhone?.message ||
                  error?.response?.data?.errors?.ContactPhone?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Rating"
                type="text"
                isRequired
                errorMessage={
                  errors?.Rating?.message ||
                  error?.response?.data?.errors?.Rating?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Rating", e.target.value);
                  clearErrors(["Rating"]);
                }}
                register={{
                  ...register("Rating", {
                    required: {
                      value: true,
                      message: "Rating is required",
                    },
                    pattern: {
                      value: /^[0-5]$/,
                      message: "Enter a valid rating (0-5 numeric value)",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remark"
                maxLength={1000}
                errorMessage={
                  errors?.Remark?.message ||
                  error?.response?.data?.errors?.Remark?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remark", e.target.value);
                  clearErrors(["Remark"]);
                }}
                register={{
                  ...register("Remark"),
                }}
              />

              <Address
                requiredflag
                header="Hospital Address"
                control={control}
                address1register={{
                  ...register("AddressLine1", {
                    required: {
                      value: true,
                      message: "Address Line 1 is required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("AddressLine1", e.target.value);
                  clearErrors(["AddressLine1"]);
                }}
                address1errorMessage={
                  errors?.AddressLine1?.message ||
                  error?.response?.data?.errors?.AddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("AddressLine2", {
                    required: {
                      value: true,
                      message: "Area is required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("AddressLine2", e.target.value);
                  clearErrors(["AddressLine2"]);
                }}
                address2errorMessage={
                  errors?.AddressLine2?.message ||
                  error?.response?.data?.errors?.AddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  setValue("CityId", e?.value);
                  setselectedcity(e);
                  setselectedpincode(null);
                }}
                address4errorMessage={
                  errors?.CityId?.message ||
                  error?.response?.data?.errors?.CityId?.map((e: any) => e)
                }
                selectedCity={selectedcity}
                address5onChange={(e: any) => {
                  setValue("PinCodeId", e?.value);
                  setselectedpincode(e);
                }}
                address5errorMessage={
                  errors?.PinCodeId?.message ||
                  error?.response?.data?.errors?.PinCodeId?.map((e: any) => e)
                }
                selectedPincode={selectedpincode}
                cityKeys={{ label: "Value", value: "Id" }}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityName={"CityId"}
                cityrules={{
                  required: { value: true, message: "City is required" },
                }}
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeApi={
                  Hcity &&
                  `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${Hcity}`
                }
                pincodeName={"PinCodeId"}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
                pincodeDisable={Hcity ? false : true}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedcity({});
                      setselectedpincode({});
                      setSelectedVendorType({});
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
