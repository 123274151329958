import React, { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddEditConsultingFeesClinicModal from "./AddEditConsultingFeesClinicModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const ConsultingFeesClinic = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname =
    process.env.REACT_APP_DOCTORCONSULTINFFEESFORPATIENT_API?.toString();

  const filterJson = {
    ConsultingFeesForPatientType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConsultingFeesForPatienAmount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ConsultingFeesForPatienDuration: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConsultingFeesForPatienCurrentAmount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "ConsultingFeesForPatientType",
      header: "Consulting Type",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by consulting type",
    },
    {
      field: "ConsultingFeesForPatienAmount",
      header: "Amount",
      body: null,
      sortable: true,
      filter: true,
      dataType: "numeric",
      filterPlaceholder: "Search by amount",
    },
    {
      field: "ConsultingFeesForPatienStartDate",
      header: "Startdate",
      body: (e: any) => actionDateTemplate(e),
      sortable: true,
      dataType: "date"
    },
    {
      field: "ConsultingFeesForPatienEndDate",
      header: "Enddate",
      body: (e: any) => actionEndDateTemplate(e),
      sortable: true,
      dataType: "date"
    },
    {
      field: "ConsultingFeesForPatienDuration",
      header: "Duration",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by duration",
    },
    {
      field: "ConsultingFeesForPatienCurrentAmount",
      header: "Current Amount",
      body: null,
      sortable: true,
      filter: true,
      dataType: "numeric",
      filterPlaceholder: "Search by current amount",
    },

  ];

  const actionDateTemplate = (id: any) => {
    return id.ConsultingFeesForPatienStartDate?.split("T")[0];
  };

  const actionEndDateTemplate = (id: any) => {
    return id.ConsultingFeesForPatienEndDate?.split("T")[0];
  };

  const [bool, setbool]: any = useState(null);
  const [updateId, setupdateId]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ConsultingFeesForPatientId}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.ConsultingFeesForPatientId);
          }}
          onClick={() => modelFunction(apiname, id.ConsultingFeesForPatientId)}
        />
      </>
    );
  };

  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);

  return (
    <>
      <Accordion>
        <AccordionTab header="Consulting Fees (Clinic)">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Consulting Fees (Clinic)`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              selecteditem={selectedRow}
              setselecteditem={setselectedRow}
              filterJson={filterJson}
              pdfTitle="Consulting Fees Clinic"
            />
          </div>
        </AccordionTab>
      </Accordion>

      {updateId !== null && (
        <AddEditConsultingFeesClinicModal
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
