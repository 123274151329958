import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import { AddEditPriorityTypeModal } from "./AddEditPriorityTypeModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { NullHandler } from "../../../../common/NullHandler";


export const PriorityType = () => {

  const apiname = process.env.REACT_APP_PRIORITYTYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => { return e.PriorityTypeId })

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "5%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionPriorityTypeTemplate(e),
      sortable: false,
      width: "10%",
    },
    {
      field: "PriorityTypeName",
      header: "Priority Type",
      body: null,
      bodyClassName: "text-info h6",
      sortable: true,
      width: "10vw",
    },
    {
      field: "PriorityNumber",
      header: "Priority Number",
      body: (data: any) => {
        return NullHandler(data.PriorityNumber);
      },
      sortable: true,
      width: "10vw",
    },

  ];


  const actionPriorityTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.PriorityTypeId}
          editModal={() => {
            setupdateId(id.PriorityTypeId)
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };


  return (
    <>
      <Helmet>
        <title>Priority Type</title>
      </Helmet>

      <PageHeader headTitle="Priority Type" onClick={() => { setupdateId(0); }} id={0} list={true} />

      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          // check={true}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) } }}
        />
        {updateId !== null && (
          <AddEditPriorityTypeModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true); } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};