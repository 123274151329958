import { useState } from "react";
import { Action } from "../../../../common/Action";
import { Helmet } from "react-helmet";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import AddEditProductLineModal from "./AddEditProductLineModal";
import { InputSwitch } from "primereact/inputswitch";
import DatatableCommon from "../../../../common/DatatableCommon";

export default function ProductLine() {

  const apiname = process.env.REACT_APP_PRODUCTLINE_API?.toString()
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [updateId, setupdateId]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row)
  }

  const [selectedproductline, setselectedproductline]: any = useState([]);
  const multi = selectedproductline?.map((e: any) => { return e.ProductLineId })

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionProductLineTemplate(e),
      sortable: false,
      width: "10%",
    },
    {
      field: "ProductLineName",
      header: "Product Line Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "IsCompetitorProduct",
      header: "Is Competitor Product",
      body: (e: any) => actionTemplate(e),
      sortable: true,
      width: "10vw",
    },

  ];

  const actionTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.IsCompetitorProduct} />
      </>
    );
  };

  const actionProductLineTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ProductLineId}
          editModal={() => {
            setupdateId(id.ProductLineId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> Product Line </title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Product Line"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/productattributes"}
          linkflagtitle={"Product Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          columnData={columnData}
          // check
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedproductline}
          selecteditem={selectedproductline}
          multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedproductline?.length === 0 ? null : multi) } }}
        />

        {updateId !== null && (
          <AddEditProductLineModal
            setisOPen={() => {
              return setupdateId(null);
            }}
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>


      <DeleteModal id={deleteID} deleteMessage={(data: boolean) => { if (data) { setbool(true); } }} apiname={apiname} setisOPen={() => setdeleteID(null)} open={deleteID != null} />
    </>
  )
}
