import { useState } from "react";
import DatatableCommon from "../../../common/DatatableCommon";
import { Link } from "react-router-dom";

const Inbox = () => {
  const [selectedCategory, setSelectedCategory]: any = useState([]);
  const [bool, setbool]: any = useState(null);
  const columnData = [
    {
      field: "Subject",
      body: (e: any) => actionCategoryTemplate(e),
    },
  ];

  const actionCategoryTemplate = (id: any) => {
    return (
      <>
        <div className="d-flex">
          <div className="col-lg-8">
            <h6 className="mt-3">
              {id.FromUserName}
            </h6>
            <p>{id.Subject}</p>
          </div>
          <div className="col-lg-4 text-right m-auto">
            <Link to={`/layout/emailDetail/${id.Id}`} className="btn btn-primary">View</Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="inbox-container">
      <h1>Inbox</h1>
      <DatatableCommon
        apiname={"Reporting/Email/Get?EmailType=Inbox"}
        type={"data"}
        doctorid={0}
        showGridlines={false}
        setselecteditem={setSelectedCategory}
        selecteditem={selectedCategory}
        columnData={columnData}
        refetchData={bool}
        setrefetchData={setbool}
        enableDownload={false}
      />
    </div>
  );
};

export default Inbox;
