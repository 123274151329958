import axiosInstance from "../_helper/Interceptor";
import Modal from "react-bootstrap/Modal";
import Textarea from "./Textarea";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../hooks/Api/Api";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface iPropsmoadl {
  id?: any;
  deleteMessage?: any;
  apiname?: string;
  setisOPen?: any;
  open?: any;
  jsonData?: any;
}

export const CancelDCRModal = ({
  id,
  apiname,
  setisOPen,
  open,
  jsonData,
}: iPropsmoadl) => {
  const api =
    jsonData === "chemist"
      ? process.env.REACT_APP_CHEMISTVISIT_API?.toString()
      : jsonData === "hospital"
        ? process.env.REACT_APP_HOSPITALVISIT_API?.toString()
        : jsonData === "doctor"
          ? process.env.REACT_APP_DOCTORVISIT_API?.toString()
          : "";

  const { mutate, isSuccess, isError, error }: any = useCreateByID(api, id);

  const rejectData = (data: any) => {

    if (jsonData === "chemist") {
      data.ChemistVisitId = Number(id);
    } else if (jsonData === "doctor") {
      data.DoctorVisitId = Number(id);
    } else if (jsonData === "hospital") {
      data.HospitalVisitId = Number(id);
    }
    console.log(data, "ndfsjnfdnfjk");
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      toast.success("Cancel Successfull", { position: "top-right" });
      window.location.reload();
    }
  }, [isSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  }: any = useForm({ mode: "all" });

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-body">
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              rejectData(productinfo);
            })}
          >
            <Textarea
              containerClass="col-lg-12"
              label="Remarks"
              rows={2}
              isRequired
              maxLength={50}
              errorMessage={errors?.Remark?.message}
              register={{
                ...register("Remark", {
                  required: {
                    value: true,
                    message: "Remarks is required",
                  },
                  pattern: {
                    value: /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                    message: "Enter Valid Remarks and Remove whitespace from both sides of a string",
                  },
                }),
              }}
            />
            <div className="col-lg-12 text-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
