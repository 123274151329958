import React, { useEffect, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useTerritoryByID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import ReactSelect from "../../../../common/ReactSelect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export default function AddEditRegionModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_REGION_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: regionLoader }: any = useTerritoryByID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (
          data.StateId !== null &&
          data.StateId !== undefined
        ) {
          setValue("StateId", {
            label: data.StateName,
            value: data.StateId,
          });
          setselectedstate({
            label: data.StateName,
            value: data.StateId,
          });
        } else {
          setValue("StateId", null);
          setselectedstate(null);
        }
      
      }
    }
  }, [data]);

  const [selectedstate, setselectedstate]: any = useState(null);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const stateFormData = (data: any) => {
data.StateId = selectedstate?.value

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Region`}
        modal
        style={{ width: "1100px" }}
      >
        {!regionLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(stateFormData);
              }
            }}
            onSubmit={handleSubmit(stateFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-12"
                control={control}
                isRequired

                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("StateId", e?.value);
                    setselectedstate(e);
                  } else {
                    setValue("StateId", null);
                    setselectedstate(null);
                  }
                }}
                value={selectedstate}
                name="StateId"
                label="State"
                api={process.env.REACT_APP_STATE_API}
                keys={{ label: "Value", value: "Id" }}
                rules={{
                  required: { value: true, message: "State is required" },
                }}
                errorMessage={
                  errors?.StateId?.message ||
                  error?.response?.data?.errors?.StateId?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-12"
                label="Region"
                type="text"
                isRequired
                errorMessage={
                  errors?.RegionName?.message ||
                  error?.response?.data?.errors?.RegionName?.map((e: any) => e)
                }
                onChange={(e: any) => { setValue("RegionName", e.target.value); clearErrors(["RegionName"]) }}
                register={{
                  ...register("RegionName", {
                    required: {
                      value: true,
                      message: "Region Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s'-]{0,50}$/,
                      message:
                        "Enter a valid region name (1-50 alphabet characters only)",
                    },
                  }),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedstate(null); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
