import { Link } from "react-router-dom";
import { Button } from "./Button";
import { Helmet } from "react-helmet";

interface iPropsalert {
  id?: any;
  headTitle?: string;
  headTitleURL?: any;
  LinkURL?: any;
  list?: boolean;
  onClick?: any;
  onClickDownload?: any;
  downloadlist?: boolean;
  linkflag?: boolean;
  linkflagtitle? :any;
  linkflagURL? :any;
}

export const PageHeader = ({
  id,
  headTitle,
  headTitleURL,
  list,
  LinkURL,
  onClick,
  onClickDownload,
  downloadlist,
  linkflag = false,
  linkflagtitle,
  linkflagURL,
}: iPropsalert) => {
  return (
    <>
      <Helmet>
        <title>{headTitle}</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              {list ? (
                <h4>{headTitle}</h4>
              ) : (
                <h4>
                  {id === undefined ? "Add" : id == 0 ? "" : "Edit"} {headTitle}
                </h4>
              )}
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                {linkflag && (
                  <li className="breadcrumb-item">
                    <Link to={linkflagURL}>{linkflagtitle}</Link>
                  </li>
                )}
                {list ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {headTitle}
                  </li>
                ) : (
                  <>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to={headTitleURL}>
                        {id == 0 ? "Doctor" : headTitle}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {id === undefined ? "Add" : id == 0 ? "" : "Edit"}{" "}
                      {headTitle}
                    </li>
                  </>
                )}
              </ol>
            </nav>
          </div>
          {list ? (
            <div className="col-md-6">
              {downloadlist ? (
                <>
                  <Link to={LinkURL} className="pull-right">
                    <Button
                      text={`Add ${headTitle}`}
                      onclick={onClick}
                      color="primary"
                    />
                  </Link>
                  <Link to={LinkURL} className="pull-right">
                    <Button
                      text="Download"
                      onclick={onClickDownload}
                      color="secondary"
                    />
                  </Link>
                </>
              ) : (
                <Link to={LinkURL} className="pull-right">
                  <Button
                    text={`Add ${headTitle}`}
                    onclick={onClick}
                    color="primary"
                  />
                </Link>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
