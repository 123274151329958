import React, { useState } from "react";
import { PageHeader } from "../../../common/PageHeader";
import { Helmet } from "react-helmet";
import { Action } from "../../../common/Action";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import DatatableCommon from "../../../common/DatatableCommon";
import AddEditNwDays from "./AddEditNwDays";
import { DeleteModal } from "../../../common/DeleteModal";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";

const NwDays = () => {
  const apiname = process.env.REACT_APP_NWDAYS_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const [bool, setbool]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [updateId, setupdateId]: any = useState(null);

  const filterJson = {
    EventName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3%",
    },
    {
      header: "Action",
      sortable: false,
      // frozen: true,
      filter: false,
      body: (e: any) => actionNWDaysTemplate(e),
      width: "8%",
    },
    {
      field: "EventName",
      // frozen: true,
      header: "Event Name",
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by event name",
      body: (e: any) => EventNameTemplate(e),
      width: "10vw",
    },
    {
      field: "Date",
      header: "Date",
      sortable: true,
      body: (e: any) => DateTemplate(e),
      width: "10vw",
      dataType: "date"
    },
    {
      field: "OnTransist",
      header: "OnTransist",
      sortable: true,
      body: (e: any) => actionTemplate(e),
      width: "10vw",
    },
  ];

  const DateTemplate = (id: any) => {
    return moment(id.Date).format("DD MMM,YYYY");
  };

  const actionTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.OnTransist} />
      </>
    );
  };
  const EventNameTemplate = (id: any) => {
    if (id.OnTransist) {
      return "On Transist";
    }
    else {
      return id.EventName;
    }
  }
  const actionNWDaysTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>NW Days</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="N/W Days"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/tourprogram"}
          linkflagtitle={"Tour Program"}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          filterJson={filterJson}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditNwDays
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default NwDays;
