import { useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { NullHandler } from "../../../common/NullHandler";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../common/Button";

const CRMAccount = () => {
  let navigate = useNavigate();
  const apiname = process.env.REACT_APP_DOCTORACCOUNT_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const columns = [
    {
      header: "#",
      field: "srNo",
      width: "2rem"
    },
    {
      header: "Action",
      body: (e: any) => actioncrmAccountTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "Doctor",
      header: "Doctor",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "Percentage",
      header: "Percentage",
      body: (data: any) => {
        return NullHandler(data.Percentage);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "DateTime",
      header: "DateTime",
      body: (e: any) =>
        NullHandler(
          e.DateTime == null ? "" : moment(e.DateTime).format("DD MMM, YYYY")
        ),
      sortable: true,
      width: "10vw",
      dataType: "date"
    },
    {
      field: "ZoneName",
      header: "Zone Name",
      body: (data: any) => {
        return NullHandler(data.ZoneName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "StateName",
      header: "State Name",
      body: (data: any) => {
        return NullHandler(data.StateName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "RegionName",
      header: "Region Name",
      body: (data: any) => {
        return NullHandler(data.RegionName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "CityName",
      header: "City Name",
      body: (data: any) => {
        return NullHandler(data.CityName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "MobileNumber",
      header: "Mobile No.",
      body: (data: any) => {
        return NullHandler(data.MobileNumber);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      body: (data: any) => {
        return NullHandler(data.EmployeeName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "Remarks",
      header: "Remarks",
      body: (data: any) => {
        return NullHandler(data.Remarks);
      },
      sortable: true,
      width: "10vw",
    },
  ];

  const actionDateTemplate = (id: any) => {
    return id.DateTime?.split("T")[0];
  };

  const actioncrmAccountTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.DoctorAccountId}
          editurl={"layout/crm/Editcrmaccount"}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Account</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Account
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div className="pull-right">
                <Button
                  text={`Manage Credit`}
                  onclick={() => {
                    navigate("/layout/crm/managecredit");
                  }}
                  color="secondary"
                />
              </div>
              <div className="pull-right">
                <Button
                  text={`CRM Report`}
                  onclick={() => {
                    navigate("/layout/crm/crmreport");
                  }}
                  color="secondary"
                />
              </div>
              <div className="pull-right">
                <Button
                  text={`Add Account`}
                  onclick={() => {
                    navigate("/layout/crm/Addcrmaccount");
                    // setupdateId(0);
                  }}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <PageHeader headTitle="Account" list={true} LinkURL={"/layout/crm/Addcrmaccount"} /> */}
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
        // check={true}
        />
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default CRMAccount;
