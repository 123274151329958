import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetchCalender } from "../../../hooks/Api/Api";
import { Helmet } from "react-helmet";
import { Spinner } from "../../../common/Spinner";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
const localizer = momentLocalizer(moment);

export const Calender = () => {
  const controller = new AbortController();
  const navigate = useNavigate();
  const { id } = useParams();
  const [myEvents, setEvents] = useState<any>([]);
  const [state, setstate] = useState(new Date());
  const apiname = process.env.REACT_APP_CALENDER_API?.toString();

  const { data, isSuccess, isError, error, isLoading, refetch } =
    useFetchCalender(apiname, moment(state).format("yyyy-MM-DD"), controller.signal);

  useEffect(() => {
    if (data) {
      const test = data?.tourPlanCalendarViewModes
        ?.map((e: any) => {
          if (e.TourPlanStatus) {
            console.log("true");
          } else {
            console.log("false");
          }
          if (e.Leave || e.Holiday || e.TourPlanStatus || e.Disable) {
            const color =
              e.Leave == true
                ? "bg-secondary"
                : e.Holiday == true
                  ? "bg-warning"
                  : e.TourPlanStatus == true
                    ? "bg-success"
                    : e.Disable == true
                      ? "bg-danger"
                      : "";

            return {
              start: moment(e.Date).toDate(),
              end: moment(e.Date).toDate(),
              id: e.TourPlanId,
              title:
                e.NwDay && e.TourPlanStatus
                  ? "No Working Day"
                  : e.TourPlanStatus
                    ? "Tour Plan Created"
                    : e.Leave
                      ? "Leave"
                      : e.HolidayName
                        ? e.HolidayName
                        : "Holiday",
              color: color,
              resource: e.Disable || e.Holiday || e.Leave,
            };
          }
          return null;
        })
        .filter((data: any) => data != null);
      setEvents(test);
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const handleSelectSlot = ({ start, end, slots, action, i }: any) => {

    let isDisable = myEvents.filter((data: any) =>
      moment(start).isSame(data.start)
    );
    console.log(isDisable, "This is disable");
    if (isDisable.length) {
      return false;
    }

    if (action == "click") {
      navigate("/layout/mr/tourplan", {
        state: moment(start).format("YYYY-MM-DD"),
      });
    } else {
      const date = slots.map((e: any) => moment(e).format("YYYY-MM-DD"));
      navigate("/layout/mr/tourplan", { state: date });
    }
  };

  const handleSelectEvent = (event: any) => {

    console.log(event, "event data");
    if (event.id !== null) {
      navigate(`/layout/mr/edittourplan/${event.id}`);
    }
  };

  const handleShow = () => {
    navigate("/layout/mr/viewTourPlan", {
      state: moment(state).format("yyyy-MM-DD"),
    });
  };

  const [load, setLoad] = useState(false);

  const handle = () => {
    setLoad(true);
    const form = {
      MonthlyTourPlanId: data.MtpId,
      MonthlyTourPlanDate: data.tourPlanCalendarViewModes[0].Date,
    };
    console.log(form, "formValue");
    axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_MONTHLYTOURPLANUNLOCKMONTHLYTOURPLANREQUESTFORUNLOCK_API}`,
        form
      )
      .then((res) => {
        setLoad(false);
        console.log(res.data);
        refetch();
        toast.success("Request Sent for approval", { position: "top-right" });
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>Calender</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Calendar</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/tourprogram"}>Tour Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Calendar
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="pd-20 card-box mb-30">
        <Calendar
          localizer={localizer}
          events={myEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          defaultDate={moment().toDate()}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          selectable
          eventPropGetter={({ start, end, color }: any) => {
            return { className: color };
          }}
          onNavigate={(newDate: any) => {
            setstate(moment(newDate).toDate());
          }}
        />

        <div className="col-md-12 text-center mt-3">
          {data?.ShowTourPlan && (
            <button className="btn btn-primary" onClick={handleShow}>
              Show Tour Plan
            </button>
          )}
          {data?.DisableLabel && (
            <button
              className="btn btn-primary ml-2"
              onClick={handle}
              disabled={
                data?.IsDisable === 0 || data?.IsDisable === 2 ? true : false
              }
            >
              {data?.DisableLabel}
            </button>
          )}
        </div>

        {isLoading && (
          <div
            className="pd-20 card-box mb-30 text-center"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </div>
    </>
  );
};
