import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";
import AddEditContact from "../Contact/AddEditContact";
import { AddEditPatient } from "../Patient/AddEditPatient";
import { AddEditDoctor } from "../../doctor/AddEditDoctor";
import moment from "moment";
import { da } from "@faker-js/faker";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

const AddEditAttendee = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const [eventId, setEventId] = useState(null);
  const apiname = process.env.REACT_APP_ATTENDEE_API?.toString();
  const eventapiname = process.env.REACT_APP_EVENTATTENDANCE_API?.toString();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    debugger
    if (data) {
      if (id) {
        reset(data);

        if (data.EventId !== null && data.EventId !== undefined) {
          setselectedevent({
            label: data.EventName,
            value: data.EventId,
          });
          setValue("EventId", data.EventId);
        } else {
          setValue("EventId", null);
          setselectedevent(null);
        }

        if (data.AttendeeTypeId !== null && data.AttendeeTypeId !== undefined) {
          setselectedattendeetype({
            label: data.AttendeeTypeName,
            value: data.AttendeeTypeId,
          });
          setValue("AttendeeTypeId", data.AttendeeTypeId);
        } else {
          setValue("AttendeeTypeId", null);
          setselectedattendeetype(null);
        }

        if (data.DoctorId !== null && data.DoctorId !== undefined) {
          setselecteddoctor({
            label: data.DoctorName,
            value: data.DoctorId,
          });
          setValue("DoctorId", data.DoctorId);
        } else {
          setValue("DoctorId", null);
          setselecteddoctor(null);
        }

        if (data.ContactId !== null && data.ContactId !== undefined) {
          setselectedcontact({
            label: data.ContactName,
            value: data.ContactId,
          });
          setValue("ContactId", data.ContactId);
        } else {
          setValue("ContactId", null);
          setselectedcontact(null);
        }

        if (data.PatientId !== null && data.PatientId !== undefined) {
          setselectedpatient({
            label: data.PatientName,
            value: data.PatientId,
          });
          setValue("PatientId", data.PatientId);
        } else {
          setValue("PatientId", null);
          setselectedpatient(null);
        }
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const {
    mutate: postmutate,
    isSuccess: postisSuccess,
    isError: postisError,
    error: postError,
  }: any = useCreateByID(eventapiname, id === 0 ? undefined : id);

  const [selectedevent, setselectedevent]: any = useState(null);

  const [selectedattendeetype, setselectedattendeetype]: any = useState(null);

  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  const [selectedpatient, setselectedpatient]: any = useState(null);

  const [selectedcontact, setselectedcontact]: any = useState(null);

  const attendeeFormData = (data: any) => {
    debugger
    setEventId(data.EventId);

    if (data.AttendeeTypeId === 2) {
      data.PatientId = Number(data?.PatientId);
      delete data.DoctorId;
      delete data.DoctorName;
      delete data.ContactId;
      delete data.ContactName;
      delete data.AttendeeTypeName;
    }

    if (data.AttendeeTypeId === 1) {
      if (typeof data?.DoctorId == "object") {

        data.DoctorId = Number(data?.DoctorId?.value);
      }
      else {
        data.DoctorId = Number(data?.DoctorId);

      }
      delete data.PatientId;
      delete data.PatientName;
      delete data.ContactId;
      delete data.ContactName;
      delete data.AttendeeTypeName;
    }

    if (data.AttendeeTypeId === 3) {
      data.ContactId = Number(data?.ContactId);
      delete data.DoctorId;
      delete data.DoctorName;
      delete data.PatientId;
      delete data.PatientName;
      delete data.AttendeeTypeName;
    }

    // delete data.EventId;
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};

    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  const [bool, setbool]: any = useState(null);
  const [state, setstate] = useState(false);
  const [patient, setpatient] = useState(false);
  const [doctor, setdoctor] = useState(false);

  const postData = (data: any) => {
    const formData = {
      EventId: eventId,
      AttendeeId: data,
      AttendanceStatusId: 2,
      AttendanceDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    postmutate(formData);
  };

  useEffect(() => {
    if (postisSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      fetch(true);
      setEventId(null);
    }

    if (postisError) {
      if (postError?.response?.data?.StatusCode !== 400) {
        toast.error(postError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("postError...", { position: "top-right" });
      }
    }
  }, [postisError, postisSuccess]);

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        fetch(true);
        postData(response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Attendee`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(attendeeFormData);
              }
            }}
            onSubmit={handleSubmit(attendeeFormData)}
          >
            <div className="row">
              <div className="col-lg-4 row">
                <ReactSelect
                  containerClass="col-lg-12"
                  label={"Event"}
                  isRequired
                  name={"EventId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("EventId", e?.value);
                      setselectedevent(e);
                    } else {
                      setValue("EventId", null);
                      setselectedevent(null);
                    }
                  }}
                  value={selectedevent}
                  control={control}
                  api={process.env.REACT_APP_EVENT_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.EventId?.message ||
                    error?.response?.data?.errors?.EventId?.map((e: any) => e)
                  }
                  rules={{
                    required: { value: true, message: "Event is required" },
                  }}
                />
              </div>

              <div className="col-lg-4 row">
                <ReactSelect
                  containerClass="col-lg-12"
                  label={"Attendee Type"}
                  isRequired
                  name={"AttendeeTypeId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("AttendeeTypeId", e?.value);
                      setselectedattendeetype(e);
                      setselecteddoctor({});
                      setselectedpatient({});
                      setselectedcontact({});
                    } else {
                      setValue("AttendeeTypeId", null);
                      setselectedattendeetype(null);
                      setselecteddoctor(null);
                      setselectedpatient(null);
                      setselectedcontact(null);
                    }
                  }}
                  value={selectedattendeetype}
                  control={control}
                  api={process.env.REACT_APP_ATTENDEETYPE_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.AttendeeTypeId?.message ||
                    error?.response?.data?.errors?.AttendeeTypeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Attendee Type is required",
                    },
                  }}
                />
              </div>

              {(selectedattendeetype === null
                ? false
                : selectedattendeetype.label === "Doctor") && (
                  <div className="col-lg-4 row">
                    <ReactSelect
                      containerClass="col-lg-10"
                      label={"Doctor"}
                      isRequired
                      name={"DoctorId"}
                      onChange={(e: any) => {
                        if (e?.value !== undefined) {
                          setValue("DoctorId", e?.value);
                          setselecteddoctor(e);
                        } else {
                          setValue("DoctorId", null);
                          setselecteddoctor(null);
                        }
                      }}
                      value={selecteddoctor}
                      control={control}
                      refetch={bool}
                      setRefecth={setbool}
                      api={process.env.REACT_APP_DOCTORDETAIL_API}
                      keys={{ label: "Value", value: "Id" }}
                      errorMessage={
                        errors?.DoctorId?.message ||
                        error?.response?.data?.errors?.DoctorId?.map(
                          (e: any) => e
                        )
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Doctor is required",
                        },
                      }}
                    />
                    <div className="col-lg-2 p-1 mt-4 pt-2">
                      <Button
                        type="button"
                        icon="pi pi-plus"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                        onClick={() => setdoctor(true)}
                        severity="info"
                      ></Button>
                    </div>
                  </div>
                )}

              {selectedattendeetype === null
                ? false
                : selectedattendeetype.label === "Patient" && (
                  <div className="col-lg-4 row">
                    <ReactSelect
                      containerClass="col-lg-10"
                      label={"Patient"}
                      isRequired
                      name={"PatientId"}
                      onChange={(e: any) => {
                        if (e?.value !== undefined) {
                          setValue("PatientId", e?.value);
                          setselectedpatient(e);
                        } else {
                          setValue("PatientId", null);
                          setselectedpatient(null);
                        }
                      }}
                      value={selectedpatient}
                      control={control}
                      refetch={bool}
                      setRefecth={setbool}
                      api={process.env.REACT_APP_PATIENT_API}
                      keys={{ label: "Value", value: "Id" }}
                      errorMessage={
                        errors?.PatientId?.message ||
                        error?.response?.data?.errors?.PatientId?.map(
                          (e: any) => e
                        )
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Patient is required",
                        },
                      }}
                    />
                    <div className="col-lg-2 p-1 mt-4 pt-2">
                      <Button
                        type="button"
                        icon="pi pi-plus"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                        onClick={() => setpatient(true)}
                        severity="info"
                      ></Button>
                    </div>
                  </div>
                )}

              {selectedattendeetype === null
                ? false
                : selectedattendeetype.label === "Other" && (
                  <div className="col-lg-4 row">
                    <ReactSelect
                      containerClass="col-lg-10 "
                      label={"Contact"}
                      isRequired
                      name={"ContactId"}
                      onChange={(e: any) => {
                        if (e?.value !== undefined) {
                          setValue("ContactId", e?.value);
                          setselectedcontact(e);
                        } else {
                          setValue("ContactId", null);
                          setselectedcontact(null);
                        }
                      }}
                      value={selectedcontact}
                      control={control}
                      refetch={bool}
                      setRefecth={setbool}
                      api={process.env.REACT_APP_CONTACT_API}
                      keys={{ label: "Value", value: "Id" }}
                      errorMessage={
                        errors?.ContactId?.message ||
                        error?.response?.data?.errors?.ContactId?.map(
                          (e: any) => e
                        )
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Contact is required",
                        },
                      }}
                    />
                    <div className="col-lg-2 p-1 mt-4 pt-2">
                      <Button
                        type="button"
                        icon="pi pi-plus"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                        onClick={() => setstate(true)}
                        severity="info"
                      ></Button>
                    </div>
                  </div>
                )}

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedevent(null);
                      setselectedattendeetype(null);
                      setselecteddoctor(null);
                      setselectedcontact({});
                      setselectedpatient({});
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}

        <AddEditDoctor
          open={doctor}
          parameter
          setisOPen={setdoctor}
          id={0}
          fetch={(data: boolean) => {
            if (data) {
              setdoctor(true);
              setbool(true);
            }
          }}
        />

        <AddEditPatient
          open={patient}
          setisOPen={setpatient}
          id={0}
          fetch={(data: boolean) => {
            if (data) {
              setpatient(true);
              setbool(true);
            }
          }}
        />

        <AddEditContact
          open={state}
          setisOPen={setstate}
          id={0}
          fetch={(data: boolean) => {
            if (data) {
              setstate(true);
              setbool(true);
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default AddEditAttendee;
