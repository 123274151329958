import axiosInstance from "../_helper/Interceptor";

export const PaginationServiceByTourPlan = {
  async getData(params: any, apiname: any) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${apiname}/?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}&${
        params.search === undefined || params.search === null
          ? ""
          : `CurrentSearch=${params.search}`
      }&SortColumnName=${params.sortField}&SortColumnDirection=${
        params.sortOrder === 1 ? "asc" : "desc"
      }`
    );
    return res.data;
  },
};

export const PaginationService = {
  async getData(
    params: any,
    apiname: any,
    doctorid?: any,
    type?: any,
    filterBy?: any,
    sortBy?: any,
    isCalenderLeave?: any,
    employeeId?: any,
    IsSelf?: any,
    signal?: any,
    hqId?: any
  ) {
    console.log(typeof isCalenderLeave, "in page");

    if (type === undefined) {
      // debugger
      if (doctorid === undefined && employeeId === undefined) {
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_BASEURL}${apiname}${
            isCalenderLeave == "false" || isCalenderLeave == false
              ? "/Get?"
              : "&"
          }PageNumber=${
            params.pageCount === undefined
              ? params.page === undefined
                ? 1
                : params.page
              : params.page + 1
          }&PageSize=${params.rows}${
            params.search === undefined ||
            params.search === null ||
            params.search === ""
              ? ""
              : `&CurrentSearch=${params.search}`
          }${
            sortBy.sortField === null
              ? ""
              : `&SortColumnName=${sortBy.sortField}&SortColumnDirection=${
                  sortBy.sortOrder === 1 ? "asc" : "desc"
                }`
          }${
            filterBy?.filters?.length !== 0
              ? `&Filter=${JSON.stringify(filterBy)}`
              : ""
          }${
            IsSelf !== null && IsSelf !== undefined && IsSelf !== ""
              ? `&IsSelf=${IsSelf}`
              : ""
          }${
            hqId !== null && hqId !== undefined && hqId !== ""
              ? `&HqId=${hqId}`
              : ""
          }`,
          { signal }
        );
        return res.data;
      } else if (employeeId !== undefined && employeeId !== null) {
        const res = await axiosInstance.get(
          `${
            process.env.REACT_APP_BASEURL
          }${apiname}/Get?UserId=${employeeId}&PageNumber=${
            params.pageCount === undefined
              ? params.page === undefined
                ? 1
                : params.page
              : params.page + 1
          }&PageSize=${params.rows}${
            params.search === undefined ||
            params.search === null ||
            params.search === ""
              ? ""
              : `&CurrentSearch=${params.search}`
          }${
            sortBy.sortField === null
              ? ""
              : `&SortColumnName=${sortBy.sortField}&SortColumnDirection=${
                  sortBy.sortOrder === 1 ? "asc" : "desc"
                }`
          }${
            filterBy?.filters?.length !== 0
              ? `&Filter=${JSON.stringify(filterBy)}`
              : ""
          }${
            hqId !== null && hqId !== undefined && hqId !== ""
              ? `&HqId=${hqId}`
              : ""
          }
          `,
          { signal }
        );
        return res.data;
      } else {
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_BASEURL}${apiname}/Get?DoctorId=${doctorid}${
            params.pageCount === undefined
              ? ""
              : `&PageNumber=${
                  params.pageCount === undefined
                    ? params.page === undefined
                      ? 1
                      : params.page
                    : params.page + 1
                }`
          }${params.rows === undefined ? "" : `&PageSize=${params.rows}`}${
            params.search === undefined
              ? ""
              : `${
                  params.search === undefined ||
                  params.search === null ||
                  params.search === ""
                    ? ""
                    : `&CurrentSearch=${params.search}`
                }`
          }${
            sortBy.sortField === null
              ? ""
              : `&SortColumnName=${sortBy.sortField}&SortColumnDirection=${
                  sortBy.sortOrder === 1 ? "asc" : "desc"
                }`
          }${
            filterBy?.filters?.length !== 0
              ? `&Filter=${JSON.stringify(filterBy)}`
              : ""
          }`,
          { signal }
        );
        return res.data;
      }
    } else {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_BASEURL}${apiname}&PageNumber=${
          params.pageCount === undefined
            ? params.page === undefined
              ? 1
              : params.page
            : params.page + 1
        }&PageSize=${params.rows}${
          params.search === undefined ||
          params.search === null ||
          params.search === ""
            ? ""
            : `&CurrentSearch=${params.search}`
        }${
          sortBy.sortField === null
            ? ""
            : `&SortColumnName=${sortBy.sortField}&SortColumnDirection=${
                sortBy.sortOrder === 1 ? "asc" : "desc"
              }`
        }`,
        { signal }
      );
      return res.data;
    }
  },
};

export const PaginationServiceBydoctorid = {
  async getData(params: any, apiname: any, doctorid: any) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }${apiname}/Get?DoctorId=${doctorid}&PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}&${
        params.search === undefined || params.search === null
          ? ""
          : `CurrentSearch=${params.search}`
      }&SortColumnName=${params.sortField}&SortColumnDirection=${
        params.sortOrder === 1 ? "asc" : "desc"
      }`
    );
    return res.data;
  },
};

export const PaginationServiceByChemist = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/Stockist/GetStockistFromStockistRCPAofChemist/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceByProduct = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/Stockist/GetStockistFromStockistRCPAofProduct/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceByCRMReport = {
  async getData(params: any) {
    // if (params.sortField != null) {
    //   params.first = 0;
    // }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_CRMPOTENCIAL_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page == undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.sortField !== null ? `&SortColumnName=${params.sortField}` : ""
      }${
        params.sortOrder !== 0 && params.sortOrder !== null
          ? `&SortColumnDirection=${params.sortOrder === 1 ? "asc" : "desc"}`
          : ""
      }${
        params.paramter !== null
          ? `&StartDate=${params?.paramter?.StartDate}&EndDate=${
              params?.paramter?.EndDate
            }${
              params?.paramter?.doctorIds === undefined
                ? ""
                : `&doctorIds=${params.paramter.doctorIds.value}`
            }${
              params?.paramter?.specialityIds === undefined
                ? ""
                : `&specialityIds=${params?.paramter?.specialityIds}`
            }${
              params?.paramter?.categoryIds === undefined
                ? ""
                : `&categoryIds=${params.paramter.categoryIds}`
            }${
              params?.paramter?.cityIds === undefined ||
              params?.paramter?.cityIds === null
                ? ""
                : `&cityIds=${params.paramter.cityIds}`
            }${
              params?.paramter?.hqIds === undefined ||
              params?.paramter?.hqIds === null
                ? ""
                : `&hqIds=${params.paramter.hqIds}`
            }${
              params?.paramter?.stateIds === undefined ||
              params?.paramter?.stateIds === null
                ? ""
                : `&stateIds=${params.paramter.stateIds}`
            }`
          : ""
      }`
    );
    return res.data;
  },
};

export const PaginationServiceByChemistServices = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP
      }GetStockiesRCPA?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}&stockiesName=${params.paramter}`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceByProductServices = {
  async getData(params: any) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP
      }GetPrimarySalesRemarks?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}&stockiesName=${params.paramter}`
    );
    return res.data;
  },
};

export const PaginationServiceBySecondaryProductServices = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP
      }GetStockiesRCPAProducts?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}&stockiesName=${params.paramter}`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceByCRMChemistReport = {
  async getData(params: any, apiName: string) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/CRMPotencial/${apiName}?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.paramter !== null
          ? `&StartDate=${params.paramter.StartDate}&EndDate=${
              params.paramter.EndDate
            }&DoctorIds=${params.paramter.DoctorIds}${
              params.paramter.ChemistId !== undefined
                ? `&ChemistId=${params.paramter.ChemistId}`
                : ""
            }`
          : ""
      }`
    );
    return res.data;
  },
};

export const PaginationServiceByDashboard = {
  async getData(params: any, apiName: string) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${apiName}?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}&SortColumnName=${
        params.sortField
      }&SortColumnDirection=${params.sortOrder === 1 ? "asc" : "desc"}${
        params.paramter !== null
          ? `&StartDate=${params.paramter.StartDate}&EndDate=${
              params.paramter.EndDate
            }${
              params.paramter.HqId !== undefined
                ? `&HqId=${params.paramter.HqId}`
                : ""
            }`
          : ""
      }`
      // {
      //   headers: {
      //     Authorization: `Bearer ${accessToken}`,
      //   },
      // }
    );
    return res.data;
  },
};

// Admin Tour Plan
export const PaginationServiceByAdminTourPlan = {
  async getData(params: any, sort: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_TOURPLANGET_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        sort.sortField !== null
          ? `&SortColumnName=${sort.sortField}&SortColumnDirection=${
              sort.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }${
        params.paramter.UserId !== null && params.paramter.UserId !== undefined
          ? `&UserId=${params?.paramter?.UserId?.value}`
          : ""
      }&Status=${params.paramter.Status}`,
      { signal }
    );
    return res.data;
  },
};

// Admin Expense
export const PaginationServiceByAdminExpense = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/Expense/GetAllExpenseByAdmin?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.paramter.UserId !== undefined && params.paramter.UserId !== null
          ? `&UserId=${params?.paramter?.UserId?.value}`
          : ""
      }&statusId=${params.paramter.statusId}`,
      { signal }
    );
    return res.data;
  },
};

// Admin Leave
export const PaginationServiceByAdminLeave = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_GETALLBYADMIN_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.paramter.UserId !== undefined && params.paramter.UserId !== null
          ? `&UserId=${params?.paramter?.UserId?.value}`
          : ""
      }&statusId=${params.paramter.statusId}`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceByAdminDailyTourPlan = {
  async getData(params: any, sort: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_DAILYTOURPLANGET_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        sort.sortField !== null
          ? `&SortColumnName=${sort.sortField}&SortColumnDirection=${
              sort.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }${
        params.paramter.UserId !== undefined && params.paramter.UserId !== null
          ? `&UserId=${params?.paramter?.UserId?.value}`
          : ""
      }&StatusId=${params.paramter.StatusId}`,
      { signal }
    );
    return res.data;
  },
};

// Doctor Deleting Approval
export const PaginationServiceByDoctorDeletedApproval = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/DoctorDetail/GetDeleteApprovelList?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params?.paramter !== undefined && params?.paramter !== null
          ? `&UserId=${params?.paramter?.value}`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Chemist Deleting Approval
export const PaginationServiceByChemistDeletedApproval = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/Chemist/GetDeleteApprovelList?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params?.paramter !== undefined && params?.paramter !== null
          ? `&UserId=${params?.paramter?.value}`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Stockist Deleting Approval
export const PaginationServiceByStockistDeletedApproval = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_BASEURL
      }Reporting/Stockist/GetDeleteApprovelList?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params?.paramter !== undefined && params?.paramter !== null
          ? `&UserId=${params?.paramter?.value}`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Admin Doctor Availibilty
export const PaginationServiceByAdminDoctorAvailibilty = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_DOCTORAVAILABILITYGETAPPROVELIST_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}`,
      { signal }
    );
    return res.data;
  },
};

// Warehouse Stock Transfer
export const PaginationServiceOfWarehouseStockTransfer = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_WAREHOUSESTOCKTRANSFER_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Purchase Invoice
export const PaginationServiceOfPurchaseInvoice = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_INVENTORYPURCHASEINVOICE_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceOfSalesInvoice = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_INVENTORYSALESINVOICE_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Vendor Credit Note
export const PaginationServiceOfVendorCreditNote = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_VENDORGETALLCREDITNOTES_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Customer Credit Note
export const PaginationServiceOfCustomerCreditNote = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_CUSTOMERGETALLCREDITNOTES_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Invoice
export const PaginationServiceOfInvoice = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_INVENTORYINVOICE_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// GetAccounts Invoice
export const PaginationServiceOfGetAccountsInvoice = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_INVENTORYINVOICEGETACCOUNTS_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Purchase Invoice
export const PaginationServiceOfPurchaseReturnInvoice = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_PURCHASERETURNINVOICE_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Sales Invoice
export const PaginationServiceOfSalesReturnInvoice = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_SALESRETURNINVOICE_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Warehouse Stock
export const PaginationServiceByWarehouseStock = {
  async getData(params: any) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_INVENTORYWAREHOUSESTOCK_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }${
        params?.paramter !== undefined && params?.paramter !== null
          ? `&WarehouseId=${params?.paramter?.value}`
          : ""
      }${
        params?.product !== undefined && params?.product !== null
          ? `&ProductId=${params?.product?.value}`
          : ""
      }`
    );
    return res.data;
  },
};

// Warehouse Stock
export const PaginationServiceByWarehouseStockProductHistory = {
  async getData(params: any) {
    console.log(params?.paramter?.ProductId?.value, "dsdhgsahdgsha");
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_WAREHOUSESTOCKGETPRODUCTHISTORY_API
      }?${
        params?.paramter !== undefined && params?.paramter !== null
          ? `WarehouseId=${params?.paramter?.WarehouseId?.value}`
          : ""
      }${
        params?.paramter !== undefined && params?.paramter !== null
          ? `&ProductId=${params?.paramter?.ProductId?.value?.value}`
          : ""
      }${
        params.paramter !== null
          ? `&StartDate=${params.paramter.StartDate}&EndDate=${params.paramter.EndDate}`
          : ""
      }`
    );
    return res.data;
  },
};

// Issued Sample
export const PaginationServiceOfIssuedSample = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_SAMPLEMAPPINGGETALLISSUEDSAMPLE_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

export const PaginationServiceOfSampleRequest = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_SAMPLEEMPLOYEE_GETSAMPLEREQUEST_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// Employee Device Map
export const PaginationServiceOfEmployeeDeviceMap = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_EMPLOYEEDEVICEMAPPING_API
      }/Get?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};

// SFC
export const PaginationServiceOfSFC = {
  async getData(params: any, signal: any = false) {
    if (params.sortField != null) {
      params.first = 0;
    }
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${
        process.env.REACT_APP_SFCGET_API
      }?PageNumber=${
        params.pageCount === undefined
          ? params.page === undefined
            ? 1
            : params.page
          : params.page + 1
      }&PageSize=${params.rows}${
        params.search === undefined ||
        params.search === null ||
        params.search === ""
          ? ""
          : `&CurrentSearch=${params.search}`
      }${
        params.sortField !== null
          ? `&SortColumnName=${params.sortField}&SortColumnDirection=${
              params.sortOrder === 1 ? "asc" : "desc"
            }`
          : ""
      }`,
      { signal }
    );
    return res.data;
  },
};
