import { useEffect, useState } from "react";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { InputMask } from "primereact/inputmask";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export default function AddEditCFAModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_CFA_API?.toString();
  const { data, isLoading: CFALoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });


  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (data.StateId !== null && data.StateId !== undefined) {
          setValue("StateId", {
            label: data.State, value: data.StateId
          });
          setselectedstate({
            label: data.State, value: data.StateId
          });
        } else {
          setValue("StateId", null);
          setselectedstate(null);
        }

        if (data.PinCodeId !== null && data.PinCodeId !== undefined) {
          setValue("PinCodeId", {
            label: data.PinCode, value: data.PinCodeId
          });
          setselectedpincode({
            label: data.PinCode, value: data.PinCodeId
          });
        } else {
          setValue("PinCodeId", null);
          setselectedpincode(null);
        }
      }

    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const Rstate = watch("StateId");

  const [selectedstate, setselectedstate]: any = useState(null);

  const [selectedpincode, setselectedpincode]: any = useState(null);

  const cfaFormData = (data: any) => {
    data.MobileNo1 = data.MobileNo1?.replace(/[^\d]/g, "");
    data.MobileNo2 = data.MobileNo2?.replace(/[^\d]/g, "");
    data.MobileNo3 = data.MobileNo3?.replace(/[^\d]/g, "");

    data.StateId = selectedstate?.value;
    data.PinCodeId = selectedpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  // Success & Error Messages
  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} CFA`}
        modal
        style={{ width: "1100px" }}
      >
        {!CFALoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(cfaFormData);
              }
            }}
            onSubmit={handleSubmit(cfaFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-3"
                label="Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Name", e.target.value);
                  clearErrors(["Name"]);
                }}
                register={{
                  ...register("Name", {
                    required: { value: true, message: "Name is required" },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid name (1-50 alphabet characters or special characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Place"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("Place", e.target.value);
                  clearErrors(["Place"]);
                }}
                register={{
                  ...register("Place", {
                    required: { value: true, message: "Place is required" },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid place (2-50 alphabet characters or special characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Place?.message ||
                  error?.response?.data?.errors?.Place?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Contact Person"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("ContactPerson", e.target.value);
                  clearErrors(["ContactPerson"]);
                }}
                register={{
                  ...register("ContactPerson", {
                    required: {
                      value: true,
                      message: "Contact Person is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid contact person (2-50 alphabet characters or special characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.ContactPerson?.message ||
                  error?.response?.data?.errors?.ContactPerson?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Address 1"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("Address1", e.target.value);
                  clearErrors(["Address1"]);
                }}
                register={{
                  ...register("Address1", {
                    required: { value: true, message: "Address1 is required" },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid Address(2-250 characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Address1?.message ||
                  error?.response?.data?.errors?.Address1?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Area"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("Address2", e.target.value);
                  clearErrors(["Address2"]);
                }}
                register={{
                  ...register("Address2", {
                    required: { value: true, message: "Area is required" },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid Area(2-250 characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Address2?.message ||
                  error?.response?.data?.errors?.Address2?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-3"
                label="State"
                isRequired
                name={"StateId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("StateId", e?.value);
                    setselectedstate(e);
                    setselectedpincode({});
                  } else {
                    setValue("StateId", null);
                    setValue("PinCodeId", null);
                    setselectedstate(null);
                    setselectedpincode(null);
                  }
                }}
                value={selectedstate}
                control={control}
                api={process.env.REACT_APP_STATE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.StateId?.message ||
                  error?.response?.data?.errors?.StateId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "State is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-3"
                label="Pincode"
                isRequired
                name={"PinCodeId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PinCodeId", e?.value);
                    setselectedpincode(e);
                  } else {
                    setValue("PinCodeId", null);
                    setselectedpincode(null);
                  }
                }}
                value={selectedpincode}
                control={control}
                api={
                  selectedstate != null ? `${process.env.REACT_APP_PARENT_PINCODE_API}?StateId=${selectedstate?.value}` : ""
                }
                keys={{ label: "Value", value: "Id" }}
                flag
                errorMessage={
                  errors?.PinCodeId?.message ||
                  error?.response?.data?.errors?.PinCodeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Pincode is required" },
                }}
                Disabled={selectedstate != null ? false : true}
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">
                    Mobile No.1<span className="text-danger">*</span>
                  </label>
                  <InputMask
                    {...register("MobileNo1", {
                      required: {
                        value: true,
                        message: "Mobile No.1 is required",
                      },
                    })}
                    mask="(999) 999-9999"
                    className="form-control"
                    onChange={(e) => {
                      setValue("MobileNo1", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    message={
                      errors?.MobileNo1?.message ||
                      error?.response?.data?.errors?.MobileNo1?.map(
                        (e: any) => e
                      )
                    }
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">Mobile No.2<span className="text-danger">*</span></label>
                  <InputMask
                    {...register("MobileNo2", {
                      required: {
                        value: true,
                        message: "Mobile No.2 is required",
                      },
                    })}
                    mask="(999) 999-9999"
                    className="form-control"
                    onChange={(e) => {
                      setValue("MobileNo2", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    message={
                      errors?.MobileNo2?.message ||
                      error?.response?.data?.errors?.MobileNo2?.map(
                        (e: any) => e
                      )
                    }
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">Mobile No.3</label>
                  <InputMask
                    {...register("MobileNo3")}
                    mask="(999) 999-9999"
                    className="form-control"
                    onChange={(e) => {
                      setValue("MobileNo3", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    message={
                      errors?.MobileNo3?.message ||
                      error?.response?.data?.errors?.MobileNo3?.map(
                        (e: any) => e
                      )
                    }
                  />
                </div>
              </div>

              <Input
                containerClass="col-lg-3"
                label="Phone No"
                type="text"
                onChange={(e: any) => {
                  setValue("Phone", e.target.value);
                  clearErrors(["Phone"]);
                }}
                register={{
                  ...register("Phone", {
                    pattern: {
                      value: /^\d{6,10}$/,
                      message: "Enter a valid phone no. (6-10 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Phone?.message ||
                  error?.response?.data?.errors?.Phone?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Email"
                isRequired
                type="email"
                onChange={(e: any) => {
                  setValue("Email", e.target.value);
                  clearErrors(["Email"]);
                }}
                register={{
                  ...register("Email", {
                    required: { value: true, message: "Email is required" },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                      message: "Email format is Invalid..",
                    },
                  }),
                }}
                maxLength={50}
                errorMessage={
                  errors?.Email?.message ||
                  error?.response?.data?.errors?.Email?.map((e: any) => e)
                }
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">
                    DL No1<span className="text-danger">*</span>
                  </label>
                  <InputMask
                    {...register("DLno1", {
                      required: {
                        value: true,
                        message: "DLno1 is required",
                      },
                    })}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    mask="9999 99999999999"
                    className="form-control"
                  />
                  <ErrorMessage
                    message={
                      errors?.DLno1?.message ||
                      error?.response?.data?.errors?.DLno1?.map((e: any) => e)
                    }
                  />
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">
                    DL No2<span className="text-danger">*</span>
                  </label>
                  <InputMask
                    {...register("DLno2", {
                      required: {
                        value: true,
                        message: "DLno2 is required",
                      },
                    })}
                    onChange={(e) => setValue(e.target.value)}
                    mask="9999 99999999999"
                    className="form-control"
                  />
                  <ErrorMessage
                    message={
                      errors?.DLno2?.message ||
                      error?.response?.data?.errors?.DLno2?.map((e: any) => e)
                    }
                  />
                </div>
              </div>

              <Input
                containerClass="col-lg-3"
                label="GST"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("Gst", e.target.value);
                  clearErrors(["Gst"]);
                }}
                register={{
                  ...register("Gst", {
                    required: { value: true, message: "GST is required" },
                    pattern: {
                      value: /^[A-Z0-9]{15}$/,
                      message:
                        "Enter a valid GstNo (15 Capital letters & digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Gst?.message ||
                  error?.response?.data?.errors?.Gst?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="FSSAI"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("Fssai", e.target.value);
                  clearErrors(["Fssai"]);
                }}
                register={{
                  ...register("Fssai", {
                    required: { value: true, message: "FSSAI is required" },
                    pattern: {
                      value: /^[0-9]{14}$/,
                      message: "Enter a valid FSSAI (14 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Fssai?.message ||
                  error?.response?.data?.errors?.Fssai?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="NRX"
                isRequired
                type="text"
                maxLength={10}
                onChange={(e: any) => {
                  setValue("NRX", e.target.value);
                  clearErrors(["NRX"]);
                }}
                register={{
                  ...register("NRX", {
                    required: { value: true, message: "NRX is required" },
                  }),
                }}
                errorMessage={
                  errors?.NRX?.message ||
                  error?.response?.data?.errors?.NRX?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Other"
                type="text"
                maxLength={10}
                onChange={(e: any) => {
                  setValue("Other", e.target.value);
                  clearErrors(["Other"]);
                }}
                register={{
                  ...register("Other"),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedpincode(null);
                      setselectedstate(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
