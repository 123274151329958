import { useDeleteById } from "../hooks/Api/Api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button"

interface iPropsmoadl {
  id?: any;
  deleteMessage?: any;
  apiname?: string;
  setisOPen?: any;
  open?: any;
}

export const DeleteModal = ({
  id,
  deleteMessage,
  apiname,
  setisOPen,
  open,
}: iPropsmoadl) => {
  const { mutateAsync, isSuccess, isError, error }:any = useDeleteById(
    apiname,
    id
  );
 
  useEffect(() => {
    if (isSuccess) {
      toast.success("Deleted Successfully", { position: "top-right" });
      deleteMessage(true,apiname);
      setisOPen(false);
    }
    // if (isError) {
    //   toast.error(error.message, { position: "top-right" });
    // }
    if (isError) {
      debugger
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
      setisOPen(false);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header="Confirm Deletion"
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setisOPen(false)}
            />
            <Button
              label="Delete"
              icon="pi pi-check"
              className="p-button-text"
              onClick={mutateAsync}
            />
          </div>
        }
      >
        Are you sure you want to delete this item?
      </Dialog>
    </>
  );
};
