import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axiosInstance from "../../_helper/Interceptor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { Badge, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { ColumnGroup } from "primereact/columngroup";
import { Paginator } from "primereact/paginator";
import { TabPanel, TabView } from "primereact/tabview";
import Select from "react-select";
import { Spinner } from "../../common/Spinner";
import { LoaderContext } from "../../App";
import "../dashboard/Dashboard.css";

const documentStyle = getComputedStyle(document.documentElement);

const textColor = documentStyle.getPropertyValue("--text-color");
const textColorSecondary = documentStyle.getPropertyValue(
  "--text-color-secondary"
);
const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

export default function DetailDashboard() {
  const fullScreenLoader = useContext(LoaderContext);

  const [loader, setLoader] = useState(false);

  const controller = new AbortController();
  let [achievementGrowth, setAchievementGrowth] = useState([]);

  const [ploader, setPLoader] = useState(false);

  let GetAchivementGrowth = (division: any = "") => {
    setLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETACHIEVEMENTGROWTH_API
        }?Year=${startDate}${division == "" ? "" : `&DivisionId=${division}`}`,
        { signal: controller.signal }
      )
      .then((res) => {
        setAchievementGrowth(res.data.Data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const role = localStorage.getItem("Roles");
  let division: any = localStorage.getItem("Division");
  division = JSON.parse(division || {});

  const [SalesData, setSalesData] = useState({});
  const [SalesOptions, setSalesOptions] = useState({});

  const [AMSData, setAMSData] = useState({});
  const [AMSOptions, setAMSOptions] = useState({});

  const [AMSGrowthData, setAMSGrowthData] = useState({});
  const [AMSGrowthOptions, setAMSGrowthOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: [
        `Total Sales(${startDate}-${endDate.substring(2)})`,
        `Total Sales(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
        })`,
      ],
      datasets: [
        {
          label: "Sales",
          data: [
            achievementGrowth?.map((e: any) => e.CurrentYearTotalSales)[0],
            achievementGrowth?.map((e: any) => e.PrevYearTotalSales)[0],
          ],
          backgroundColor: [
            "rgba(255, 159, 64, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: [
            "rgb(255, 159, 64)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setSalesData(data);
    setSalesOptions(options);

    const monthAvg = {
      labels: [
        `AMS(${startDate}-${endDate.substring(2)})`,
        `AMS(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1})`,
      ],
      datasets: [
        {
          label: "Sales",
          data: [
            achievementGrowth?.map((e: any) => e.CurrentYearAMS)[0],
            achievementGrowth?.map((e: any) => e.PrevYearAMS)[0],
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: ["rgb(54, 162, 235)", "rgb(153, 102, 255)"],
          borderWidth: 1,
        },
      ],
    };
    const monthAvgOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setAMSData(monthAvg);
    setAMSOptions(monthAvgOptions);

    const doughnutChartData = {
      labels: ["AMS Growth(%)"],
      datasets: [
        {
          data: [achievementGrowth?.map((e: any) => e.AMSGrowth)[0], 100],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--blue-100"),
          ],
          borderColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--blue-100"),
          ],
          borderWidth: 1,
        },
      ],
    };
    const doughnutChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: "60%",
    };

    setAMSGrowthData(doughnutChartData);
    setAMSGrowthOptions(doughnutChartOptions);
  }, [achievementGrowth]);

  let [HQAchievementGrowth, setHQAchievementGrowth]: any = useState([]);
  const [hqloading, setHQLoading] = useState(false);
  const [previousId, setPreviousId]: any = useState(1);

  const onSubmitProduct = (divisionId: any) => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 1;
    const endingYear = currentYear;
    setHQLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETHQACHIEVEMENTGROWTH_API
        }?Year=${startDate !== "" ? startDate : `${startingYear}-${endingYear}`?.split("-")[0]}${activeIndex == 0
          ? `&DivisionId=${divisionId}`
          : division[0]?.Id == 3 || activeIndex == 2
            ? "&DivisionId=3"
            : `&DivisionId=${activeIndex}`
        }`,
        { signal: controller.signal }
      )
      .then((e) => {
        setHQAchievementGrowth(e.data.Data);
        setHQLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setHQLoading(false);
      });
  };

  let [HQGraphData, setHQGraphData] = useState({});
  let [HQGraphOptions, setHQGraphOptions] = useState({});

  useEffect(() => {
    const HQdata = {
      labels: HQAchievementGrowth?.map((e: any) => e?.HQName),

      datasets: [
        {
          data: HQAchievementGrowth?.map((e: any) => e?.CurrentYearTotalSales),
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-900"),
            documentStyle.getPropertyValue("--teal-900"),
            documentStyle.getPropertyValue("--orange-900"),
            documentStyle.getPropertyValue("--green-900"),

            documentStyle.getPropertyValue("--blue-800"),
            documentStyle.getPropertyValue("--teal-800"),
            documentStyle.getPropertyValue("--orange-800"),
            documentStyle.getPropertyValue("--green-800"),

            documentStyle.getPropertyValue("--blue-700"),
            documentStyle.getPropertyValue("--teal-700"),
            documentStyle.getPropertyValue("--orange-700"),
            documentStyle.getPropertyValue("--green-700"),

            documentStyle.getPropertyValue("--blue-600"),
            documentStyle.getPropertyValue("--teal-600"),
            documentStyle.getPropertyValue("--orange-600"),
            documentStyle.getPropertyValue("--green-600"),

            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--teal-500"),
            documentStyle.getPropertyValue("--orange-500"),

            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--teal-400"),
            documentStyle.getPropertyValue("--orange-400"),
          ],
        },
      ],
    };
    const HQOPtions = {
      plugins: {
        legend: {
          position: "left",
          labels: {
            boxWidth: 10,
            usePointStyle: true,
          },
        },
      },
    };
    setHQGraphData(HQdata);
    setHQGraphOptions(HQOPtions);
  }, [HQAchievementGrowth]);

  let [ProductAchievementGrowth, setProductAchievementGrowth] = useState([]);

  const onSubmitProductAchievementGrowth = (id: number, divisionId: any) => {
    setPLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETHQWISEPRODUCTACHIEVEMENTGROWTH_API
        }?Year=${startDate}&HQId=${id}${activeIndex == 0
          ? `&DivisionId=${divisionId}`
          : division[0]?.Id == 3 || activeIndex == 2
            ? "&DivisionId=3"
            : `&DivisionId=${activeIndex}`
        }`
      )
      .then((e) => {
        setProductAchievementGrowth(e.data.Data);
        setPLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setPLoader(false);
      });
  };

  let [HQMonthlyData, setHQMonthlyData] = useState([]);
  const [monthloader, setMonthLoader] = useState(false);

  const onSubmitMonthlyData = (id: number, divisionId: any) => {
    setMonthLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETMONTHWISEHQTOTALSALES_API
        }?Year=${startDate}&HQId=${id}${activeIndex == 0
          ? `&DivisionId=${divisionId}`
          : division[0]?.Id == 3 || activeIndex == 2
            ? "&DivisionId=3"
            : `&DivisionId=${activeIndex}`
        }`
      )
      .then((e) => {
        setHQMonthlyData(e.data.Data);
        setMonthLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setMonthLoader(false);
      });
  };

  let [MonthlyGraphDAta, setMonthlyGraphDAta] = useState([]);
  let [MonthlyGraph, setMonthlyGraph] = useState({});
  let [MonthlyGraphOptions, setMonthlyGraphOptions] = useState({});

  const getMonthlyData = (division: any) => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETMONTHWISEHQTOTALSALES_API
        }?Year=${startDate}${division == 0 ? "" : `&DivisionId=${division}`}`,
        { signal: controller.signal }
      )
      .then((e) => {
        setMonthlyGraphDAta(e.data.Data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setMonthlyGraph({
      labels: MonthlyGraphDAta?.map((e: any) => {
        return e.Month;
      }),
      datasets: [
        {
          type: "bar",
          label: `Total Sales(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
            })`,
          backgroundColor: "green",
          data: MonthlyGraphDAta?.map((e: any) => {
            return e[
              `TotalSales${Number(startDate) - 1}${Number(endDate.substring(2)) - 1
              }`
            ];
          }),
          borderColor: "white",
          borderWidth: 2,
        },
        {
          type: "bar",
          label: `Total Sales(${startDate}-${endDate.substring(2)})`,
          backgroundColor: "orange",
          data: MonthlyGraphDAta?.map((e: any) => {
            return e[`TotalSales${startDate}${endDate.substring(2)}`];
          }),
        },
      ],
    });

    setMonthlyGraphOptions({
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    });
  }, [MonthlyGraphDAta]);

  let [MonthlyGraphQty, setMonthlyGraphQty] = useState({});
  let [MonthlyGraphOptionsQty, setMonthlyGraphOptionsQty] = useState({});

  useEffect(() => {
    setMonthlyGraphQty({
      labels: MonthlyGraphDAta?.map((e: any) => {
        return e.Month;
      }),
      datasets: [
        {
          label: `Total Sales QTY(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
            })`,
          fill: false,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          yAxisID: "y",
          tension: 0.4,
          data: MonthlyGraphDAta?.map((e: any) => {
            return e[
              `TotalSalesQTY${Number(startDate) - 1}${Number(endDate.substring(2)) - 1
              }`
            ];
          }),
        },
        {
          label: `Total Sales QTY(${startDate}-${endDate.substring(2)})`,
          fill: false,
          borderColor: documentStyle.getPropertyValue("--green-500"),
          yAxisID: "y1",
          tension: 0.4,
          data: MonthlyGraphDAta?.map((e: any) => {
            return e[`TotalSalesQTY${startDate}${endDate.substring(2)}`];
          }),
        },
      ],
    });
    setMonthlyGraphOptionsQty({
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            drawOnChartArea: false,
            color: surfaceBorder,
          },
        },
      },
    });
  }, [MonthlyGraphDAta]);

  let [HQProductMonthlyData, setHQProductMonthlyData] = useState([]);
  let [displayedData, setDisplayedData] = useState([]);
  const [monthwiseloader, setMonthwiseloader] = useState(false);
  const [Subexpanded, setSubexpanded]: any = useState(null);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    setDisplayedData(
      HQProductMonthlyData.slice(event.first, event.first + event.rows)
    );
  };

  const onSubmitProductMonthlyData = (id: number, divisionId: any) => {
    setMonthwiseloader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETMONTHWISEPRODUCTSALES_API
        }?Year=${startDate}&HQId=${id}${activeIndex == 0
          ? `&DivisionId=${divisionId}`
          : division[0]?.Id == 3 || activeIndex == 2
            ? "&DivisionId=3"
            : `&DivisionId=${activeIndex}`
        }`
      )
      .then((e) => {
        let newData = e?.data?.Data?.map((dt: any) => {
          let total = Object.values(dt)
            .filter((d: any) =>
              typeof d == "string" ? d?.match(/\(/) != null : false
            )
            ?.map((d: any) => d?.split(/\(/)[0].replace(/\s/, ""))
            .reduce((sum: any, a: any) => sum + parseFloat(a), 0);
          let totalQ = Object.values(dt)
            .filter((d: any) =>
              typeof d == "string" ? d?.match(/\(/) != null : false
            )
            ?.map((d: any) => d?.split(/\(/)[1].replace(/\)/, ""))
            .reduce((sum: any, a: any) => sum + parseFloat(a), 0);
          return {
            ...dt,
            total: `${total} (${parseFloat(totalQ).toFixed(2)})`,
          };
        });

        setHQProductMonthlyData(newData);
        setDisplayedData(newData.slice(0, rows));
        setMonthwiseloader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setMonthwiseloader(false);
      });
  };

  let [HQCustomerMonthlyData, setHQCustomerMonthlyData] = useState([]);
  const [Sub2expanded, setSub2expanded]: any = useState(null);
  let [displayedCustomerData, setDisplayedCustomerData] = useState([]);
  const [customerloading, setCustomerLoading] = useState(false);
  const [rowsCustomerData, setRowsCustomerData] = useState(10);
  const [firstCustomerData, setFirstCustomerData] = useState(0);

  const onPageChangeCustomerData = (event: any) => {
    setFirstCustomerData(event.first);
    setRowsCustomerData(event.rows);
    setDisplayedCustomerData(
      HQCustomerMonthlyData.slice(event.first, event.first + event.rows)
    );
  };

  const onSubmitCustomerMonthlyData = (id: number, divisionId: any) => {
    setCustomerLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETHQWISECUSTOMERMONTHSALES_API
        }?Year=${startDate}&HQId=${id}${activeIndex == 0
          ? `&DivisionId=${divisionId}`
          : division[0]?.Id == 3 || activeIndex == 2
            ? "&DivisionId=3"
            : `&DivisionId=${activeIndex}`
        }`
      )
      .then((e) => {
        let newData = e?.data?.Data?.map((dt: any) => {
          let total = Object.values(dt)
            .filter((d: any) => typeof d == "number")
            .reduce((sum: any, a: any) => sum + parseFloat(a), 0);
          return {
            ...dt,
            total,
          };
        });
        setHQCustomerMonthlyData(newData);
        setDisplayedCustomerData(newData.slice(0, rowsCustomerData));
        setCustomerLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setCustomerLoading(false);
      });
  };

  let [ProductMonthWiseData, setProductMonthWiseData] = useState([]);
  let [displayedProductMonthData, setDisplayedProductMonthData] = useState([]);
  const [rowsproductmonth, setRowsProductMonth] = useState(10);
  const [firstproductmonth, setFirstProductMonth] = useState(0);

  const onPageChangeProductMonth = (event: any) => {
    setFirstProductMonth(event.first);
    setRowsProductMonth(event.rows);
    setDisplayedProductMonthData(
      ProductMonthWiseData.slice(event.first, event.first + event.rows)
    );
  };
  let GetMonthWiseProductSales = (division: any = 0) => {
    setProductMonthWiseData([]);
    setDisplayedProductMonthData([]);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETMONTHWISEPRODUCTSALES_API
        }?Year=${startDate}${division == 0
          ? ""
          : division == 2
            ? "&DivisionId=3"
            : `&DivisionId=${division}`
        }`,
        { signal: controller.signal }
      )
      .then((res) => {
        let newData = res?.data?.Data?.map((dt: any) => {
          let total = Object.values(dt)
            .filter((d: any) =>
              typeof d == "string" ? d?.match(/\(/) != null : false
            )
            ?.map((d: any) => d?.split(/\(/)[0].replace(/\s/, ""))
            .reduce((sum: any, a: any) => sum + parseFloat(a), 0);
          let totalQ = Object.values(dt)
            .filter((d: any) =>
              typeof d == "string" ? d?.match(/\(/) != null : false
            )
            ?.map((d: any) => d?.split(/\(/)[1].replace(/\)/, ""))
            .reduce((sum: any, a: any) => sum + parseFloat(a), 0);
          return {
            ...dt,
            total: `${total} (${parseFloat(totalQ).toFixed(2)})`,
          };
        });
        console.log(newData, "newData --- Total");
        setProductMonthWiseData(newData);
        setDisplayedProductMonthData(newData.slice(0, rowsproductmonth));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  function compareKeys(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    return aKeys[0] == b;
  }

  const [expanded, setExpanded]: any = useState(null);

  const rowExpansionTemplate1 = (data: any) => {
    const temp = { ...data };
    delete temp.ProductName;

    return (
      <div className="col-lg-12 overflow-auto">
        <Chart
          type="line"
          style={{ height: "100%", width: "89%" }}
          data={{
            labels: Object.keys(temp)
              ?.filter(
                (d: any) =>
                  d != "" &&
                  d != "total" &&
                  d != "DivisionName" &&
                  d != "DivisionId"
              )
              ?.map((e: any) => {
                return e;
              }),
            datasets: [
              {
                label: "Sales",
                fill: false,
                borderColor: documentStyle.getPropertyValue("--blue-500"),
                yAxisID: "y",
                tension: 0.4,
                data: Object.keys(temp)
                  ?.filter(
                    (d: any) =>
                      d != "" &&
                      d != "total" &&
                      d != "DivisionName" &&
                      d != "DivisionId"
                  )
                  ?.map((e: any) => {
                    return parseFloat(temp[e]?.split(/\(/)[0]);
                  }),
              },
            ],
          }}
        />
      </div>
    );
  };

  const rowExpansionTemplate2 = (data: any) => {
    const temp = { ...data };
    delete temp.ProductName;

    return (
      <div className="col-lg-12">
        <Chart
          type="line"
          style={{ height: "500px", width: "89%" }}
          data={{
            labels: Object.keys(temp)
              ?.filter(
                (d: any) =>
                  d != "" &&
                  d != "total" &&
                  d != "DivisionName" &&
                  d != "DivisionId"
              )
              ?.map((e: any) => {
                return e;
              }),
            datasets: [
              {
                label: "Sales",
                fill: false,
                borderColor: documentStyle.getPropertyValue("--blue-500"),
                yAxisID: "y",
                tension: 0.4,
                data: Object.keys(temp)
                  ?.filter(
                    (d: any) =>
                      d != "" &&
                      d != "total" &&
                      d != "DivisionName" &&
                      d != "DivisionId"
                  )
                  ?.map((e: any) => {
                    return parseFloat(temp[e]?.split(/\(/)[0]);
                  }),
              },
            ],
          }}
        />
      </div>
    );
  };

  const rowExpansionTemplate3 = (data: any) => {
    const temp = { ...data };
    delete temp.Customer;

    return (
      <div className="col-lg-12">
        <Chart
          type="line"
          style={{ height: "300px", width: "50%" }}
          data={{
            labels: Object.keys(temp)
              ?.filter((d) => d != "")
              ?.map((e: any) => {
                return e;
              }),
            datasets: [
              {
                label: "Sales",
                fill: false,
                borderColor: documentStyle.getPropertyValue("--blue-500"),
                yAxisID: "y",
                tension: 0.4,
                data: Object.values(temp)?.map((e: any) => {
                  return e;
                }),
              },
            ],
          }}
        />
      </div>
    );
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const onGlobalFilterChange1 = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters1(_filters);
    setGlobalFilterValue1(value);
  };

  const header1 = () => {
    return (
      <div className="d-flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const [subhqexpanded, setSubHqExpanded]: any = useState(null);
  const [productextended, setProductExtended]: any = useState(null);
  const [monthextended, setMonthExtended]: any = useState(null);

  const [ProductMonthextended, setProductMonthextended]: any = useState(null);
  const [Customerextended, setCustomerextended]: any = useState(null);

  const [active, setActive]: any = useState("Product");

  let footerColumnGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" footerStyle={{ textAlign: "left" }} />
        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Apr${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`May${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Jun${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Jul${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Aug${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Sep${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Oct${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Nov${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Dec${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Jan${endDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Feb${endDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value[`Mar${endDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedData.forEach((value: any) => {
              let localStr = value?.total?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
            });
            return (
              <>
                <p
                  className="mb-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalQTY}
                </p>
                <p
                  className="mt-0"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </>
            );
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />
      </Row>
    </ColumnGroup>
  );

  // Footer Total
  let footerColumnGroupProductMonth = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" footerStyle={{ textAlign: "left" }} />
        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Apr${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`May${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Jun${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Jul${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Aug${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Sep${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Oct${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Nov${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Dec${startDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Jan${endDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Feb${endDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value[`Mar${endDate.substring(2)}`]?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;
            let totalQTY = 0;

            displayedProductMonthData.forEach((value: any) => {
              let localStr = value?.total?.split(/\(/);
              let amnt = "";
              let qty = "";
              if (localStr !== undefined) {
                amnt = localStr[0]?.replace(/\s/, "");
                qty = localStr[1]?.replace(/\)/, "");
              } else {
                amnt = "0";
                qty = "0";
              }
              totalAmount += Number(amnt);
              totalQTY += Number(qty);
              total = `${totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              })} (${totalQTY})`;
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />
      </Row>
    </ColumnGroup>
  );

  let footerColumnGroupCustomerData = (
    <ColumnGroup>
      <Row>
        <Column footer="Total" footerStyle={{ textAlign: "left" }} />
        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Apr${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`May${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Jun${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Jul${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Aug${startDate.substring(2)}`];
              totalAmount += Number(localStr);

              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Sep${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Oct${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Nov${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Dec${startDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Jan${endDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Feb${endDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value[`Mar${endDate.substring(2)}`];
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />

        <Column
          footer={() => {
            let total = "";
            let totalAmount = 0;

            displayedCustomerData.forEach((value: any) => {
              let localStr = value?.total;
              totalAmount += Number(localStr);
              total = totalAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            });
            return total;
          }}
          footerStyle={{ textAlign: "left", fontSize: 12, fontWeight: "bold" }}
        />
      </Row>
    </ColumnGroup>
  );

  const rowHQExpansionTemplate1 = () => {
    return (
      <div className="pd-20 card-box mb-30">
        <span className="h4 mb-2">HQ Wise Sale</span>
        <DataTable
          value={HQAchievementGrowth}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          key={"HQId"}
          removableSort
          tableStyle={{ minWidth: "50rem" }}
          size="normal"
          expandedRows={
            active === "Product"
              ? productextended
              : active === "Month"
                ? monthextended
                : active === "ProductMonth"
                  ? ProductMonthextended
                  : Customerextended
          }
          rowExpansionTemplate={
            active == "Product"
              ? rowProductExpansionTemplate
              : active === "Month"
                ? rowMonthExpansionTemplate
                : active === "ProductMonth"
                  ? rowProductMonthExpansionTemplate
                  : rowCustomerExpansionTemplate
          }
          dataKey="HQId"
          loading={hqloading}
        >
          <Column
            header="HQ Name"
            sortable
            field="HQName"
            bodyClassName={"text-primary h6"}
            bodyStyle={{ fontSize: 14 }}
            body={(e: any) => {
              return (
                <>
                  <div className="row align-items-center justify-content-between">
                    <span className="mr-2">{e.HQName}</span>
                    <div>
                      <button
                        className="btn p-0"
                        key={"id-9"}
                        type="button"
                        style={{ width: "1.5rem", height: "2rem" }}
                      >
                        <i
                          className={
                            e.HQId ==
                              (productextended !== null
                                ? Object?.keys(productextended)[0]
                                : "")
                              ? "micon bi-layout-text-window-reverse text-black"
                              : "micon bi-layout-text-window-reverse text-success"
                          }
                          onClick={() => {
                            let obj: any = {};
                            if (
                              productextended != null &&
                              compareKeys(productextended, e?.HQId)
                            ) {
                              obj = {};
                            } else {
                              obj[e?.HQId] = true;
                            }
                            onSubmitProductAchievementGrowth(
                              e?.HQId,
                              e?.divisionId
                            );
                            setProductExtended(obj);
                            setActive("Product");
                            setProductAchievementGrowth([]);
                            setHQMonthlyData([]);
                            setDisplayedData([]);
                            setDisplayedCustomerData([]);
                            setMonthExtended({});
                            setProductMonthextended({});
                            setCustomerextended({});
                          }}
                        ></i>
                      </button>

                      <button
                        className="btn p-0"
                        type="button"
                        style={{ width: "1.5rem", height: "2rem" }}
                      >
                        <i
                          className={
                            e.HQId ==
                              (monthextended !== null
                                ? Object?.keys(monthextended)[0]
                                : "")
                              ? "pi pi-calendar text-black"
                              : "pi pi-calendar text-warning"
                          }
                          onClick={() => {
                            let obj: any = {};
                            if (
                              monthextended != null &&
                              compareKeys(monthextended, e?.HQId)
                            ) {
                              obj = {};
                            } else {
                              obj[e?.HQId] = true;
                            }
                            setMonthExtended(obj);
                            onSubmitMonthlyData(e?.HQId, e?.divisionId);
                            setActive("Month");
                            setProductAchievementGrowth([]);
                            setHQMonthlyData([]);
                            setDisplayedData([]);
                            setDisplayedCustomerData([]);
                            setProductMonthextended({});
                            setCustomerextended({});
                            setProductExtended({});
                          }}
                        ></i>
                      </button>

                      <button
                        className="btn p-0"
                        type="button"
                        style={{ width: "1.5rem", height: "2rem" }}
                      >
                        <i
                          className={
                            e.HQId ==
                              (ProductMonthextended !== null
                                ? Object?.keys(ProductMonthextended)[0]
                                : "")
                              ? "micon bi-layout-text-window text-black"
                              : "micon bi-layout-text-window text-primary"
                          }
                          onClick={() => {
                            let obj: any = {};
                            if (
                              ProductMonthextended != null &&
                              compareKeys(ProductMonthextended, e?.HQId)
                            ) {
                              obj = {};
                            } else {
                              obj[e?.HQId] = true;
                            }
                            setProductMonthextended(obj);
                            onSubmitProductMonthlyData(e?.HQId, e?.divisionId);
                            setActive("ProductMonth");
                            setProductAchievementGrowth([]);
                            setHQMonthlyData([]);
                            setDisplayedData([]);
                            setDisplayedCustomerData([]);
                            setCustomerextended({});
                            setProductExtended({});
                            setMonthExtended({});
                          }}
                        ></i>
                      </button>

                      <button
                        className="btn p-0"
                        type="button"
                        style={{ width: "1.5rem", height: "2rem" }}
                      >
                        <i
                          className={
                            e.HQId ==
                              (Customerextended !== null
                                ? Object?.keys(Customerextended)[0]
                                : "")
                              ? "pi pi-user text-black"
                              : "pi pi-user text-danger"
                          }
                          onClick={() => {
                            let obj: any = {};
                            if (
                              Customerextended != null &&
                              compareKeys(Customerextended, e?.HQId)
                            ) {
                              obj = {};
                            } else {
                              obj[e?.HQId] = true;
                            }
                            setCustomerextended(obj);
                            onSubmitCustomerMonthlyData(e?.HQId, e?.divisionId);
                            setActive("Customer");
                            setProductAchievementGrowth([]);
                            setHQMonthlyData([]);
                            setDisplayedData([]);
                            setDisplayedCustomerData([]);
                            setProductExtended({});
                            setMonthExtended({});
                            setProductMonthextended({});
                          }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </>
              );
            }}
          />
          <Column
            field="CurrentYearTotalSales"
            header={`Total Sales(${startDate}-${endDate.substring(2)})`}
            bodyClassName={"text-success h6"}
            sortable
            bodyStyle={{ fontSize: 14 }}
            body={({ CurrentYearTotalSales }) => {
              return parseFloat(CurrentYearTotalSales)?.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "INR",
                }
              );
            }}
          />
          <Column
            field="CurrentYearTotalQty"
            header={`Total Qty(${startDate}-${endDate.substring(2)})`}
            bodyClassName={" h6"}
            bodyStyle={{ fontSize: 14 }}
          />
          <Column
            field="PrevYearTotalSales"
            header={`Total Sales(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
              })`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ PrevYearTotalSales }) => {
              return parseFloat(PrevYearTotalSales)?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            }}
          />
          <Column
            field="PrevYearTotalQty"
            header={`Total Qty(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
              })`}
            bodyClassName={" h6"}
            bodyStyle={{ fontSize: 14 }}
          />
          <Column
            field="CurrentYearAMS"
            header={`AMS(${startDate}-${endDate.substring(2)})`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ CurrentYearAMS }) => {
              return parseFloat(CurrentYearAMS)?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            }}
          />

          <Column
            field="PrevYearAMS"
            header={`AMS(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
              })`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ PrevYearAMS }) => {
              return parseFloat(PrevYearAMS)?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            }}
          />

          <Column
            field="AMSGrowth"
            header="AMS Growth(%)"
            bodyClassName={" h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ AMSGrowth }) => {
              return `${parseFloat(AMSGrowth)}%`;
            }}
          />
        </DataTable>
      </div>
    );
  };

  const rowProductExpansionTemplate = () => {
    return (
      <div className="pd-20 card-box mb-30">
        <span className="h4 mb-2">Product wise Sale</span>
        <DataTable
          value={ProductAchievementGrowth}
          stripedRows
          paginator
          rows={10}
          globalFilterFields={["ProductName"]}
          removableSort
          rowsPerPageOptions={[10, 20, 50, 100]}
          key={"id-8"}
          filters={filters}
          rowHover
          size="normal"
          loading={ploader}
        >
          <Column
            key="name-1"
            field="ProductName"
            header="Product Name"
            sortable
            bodyClassName={"text-primary h6"}
            bodyStyle={{ fontSize: 14 }}
          />
          <Column
            key="name-1"
            field="CurrentYearTotalSales"
            header={`Total Sales(${startDate}-${endDate.substring(2)})`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ CurrentYearTotalSales }) => {
              return parseFloat(CurrentYearTotalSales)?.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "INR",
                }
              );
            }}
          />
          <Column
            key="name-2"
            field="CurrentYearTotalQty"
            header={`Total Qty(${startDate}-${endDate.substring(2)})`}
            bodyStyle={{ fontSize: 14 }}
          />
          <Column
            key="name-3"
            field="PrevYearTotalSales"
            header={`Total Sales(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
              })`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ PrevYearTotalSales }) => {
              return parseFloat(PrevYearTotalSales)?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            }}
          />
          <Column
            key="name-4"
            field="PrevYearTotalQty"
            header={`Total Qty(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
              })`}
            bodyStyle={{ fontSize: 14 }}
          />
          <Column
            key="name-5"
            field="CurrentYearAMS"
            header={`AMS(${startDate}-${endDate.substring(2)})`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ CurrentYearAMS }) => {
              return parseFloat(CurrentYearAMS)?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            }}
          />
          <Column
            key="name-6"
            field="PrevYearAMS"
            header={`AMS(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
              })`}
            bodyClassName={"text-success h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ PrevYearAMS }) => {
              return parseFloat(PrevYearAMS)?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
              });
            }}
          />
          <Column
            key="name-7"
            field="AMSGrowth"
            header="AMS Growth(%)"
            bodyClassName={"text-primary h6"}
            bodyStyle={{ fontSize: 14 }}
            body={({ AMSGrowth }) => {
              return `${parseFloat(AMSGrowth)}%`;
            }}
          />
        </DataTable>
      </div>
    );
  };

  const rowMonthExpansionTemplate = () => {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 mb-20">
        <div className="pd-20 card-box mb-30">
          <span className="h4 mb-2">Month wise Sale</span>
          <DataTable
            value={HQMonthlyData}
            stripedRows
            rowHover
            size="normal"
            key={"id-8"}
            loading={monthloader}
          >
            <Column
              key="name-1"
              field="Month"
              header="Month"
              bodyClassName={"text-primary h6"}
              bodyStyle={{ fontSize: 14 }}
            />
            <Column
              key="name-1"
              field={`TotalSales${startDate}${endDate.substring(2)}`}
              header={`Total Sales(${startDate}-${endDate.substring(2)})`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 14 }}
              body={(e) => {
                return parseFloat(
                  e[`TotalSales${startDate}${endDate.substring(2)}`]
                )?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "INR",
                });
              }}
            />
            <Column
              key="name-2"
              field={`TotalSalesQTY${startDate}${endDate.substring(2)}`}
              header={`Total Sales QTY(${startDate}-${endDate.substring(2)})`}
              bodyStyle={{ fontSize: 14 }}
            />
            <Column
              key="name-3"
              field={`TotalSales${Number(startDate) - 1}${Number(endDate.substring(2)) - 1
                }`}
              header={`Total Sales(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
                })`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 14 }}
              body={(e) => {
                return parseFloat(
                  e[
                  `TotalSales${Number(startDate) - 1}${Number(endDate.substring(2)) - 1
                  }`
                  ]
                )?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "INR",
                });
              }}
            />
            <Column
              key="name-4"
              field={`TotalSalesQTY${Number(startDate) - 1}${Number(endDate.substring(2)) - 1
                }`}
              header={`Total Sales QTY(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
                })`}
              bodyStyle={{ fontSize: 14 }}
            />
          </DataTable>
        </div>
      </div>
    );
  };

  const rowProductMonthExpansionTemplate = () => {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 mb-20">
        <div className="pd-20 card-box mb-30">
          <span className="h4 mb-2">Month wise Sale</span>
          <DataTable
            value={displayedData}
            stripedRows
            removableSort
            key={"ProductName"}
            dataKey="ProductName"
            rowHover
            footerColumnGroup={footerColumnGroup}
            expandedRows={Subexpanded}
            rowExpansionTemplate={rowExpansionTemplate2}
            size="normal"
            loading={monthwiseloader}
          >
            <Column
              field="ProductName"
              header="Product Name"
              sortable
              style={{
                minWidth: "10rem",
                fontSize: 14,
                position: "sticky",
                left: 0,
                zIndex: 100,
                backgroundColor: "#f5f5f5",

              }}
              bodyClassName={"text-primary h6"}
              body={(e: any) => {
                return (
                  <a
                    style={{ color: "red", cursor: 'pointer' }}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        Subexpanded != null &&
                        compareKeys(Subexpanded, e.ProductName)
                      ) {
                        obj = {};
                      } else {
                        obj[e.ProductName] = true;
                      }
                      setSubexpanded(obj);
                    }}
                  >
                    {e.ProductName}
                  </a>
                );
              }}
            />
            <Column
              field={`Apr${startDate.substring(2)}`}
              header={`Apr-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Apr${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`May${startDate.substring(2)}`}
              header={`May-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`May${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Jun${startDate.substring(2)}`}
              header={`Jun-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Jun${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Jul${startDate.substring(2)}`}
              header={`Jul-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Jul${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Aug${startDate.substring(2)}`}
              header={`Aug-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Aug${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Sep${startDate.substring(2)}`}
              header={`Sep-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Sep${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Oct${startDate.substring(2)}`}
              header={`Oct-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Oct${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }

                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Nov${startDate.substring(2)}`}
              header={`Nov-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Nov${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }

                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Dec${startDate.substring(2)}`}
              header={`Dec-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Dec${startDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }

                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Jan${endDate.substring(2)}`}
              header={`Jan-${endDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Jan${endDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }

                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Feb${endDate.substring(2)}`}
              header={`Feb-${endDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Feb${endDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }

                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
            <Column
              field={`Mar${endDate.substring(2)}`}
              header={`Mar-${endDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                let localStr = e[`Mar${endDate.substring(2)}`]?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }

                return (
                  <>
                    <p className="text-info h6 mb-0" style={{ fontSize: 12 }}>
                      {qty}
                    </p>
                    <p
                      className="text-success h6 mt-0"
                      style={{ fontSize: 12 }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />

            <Column
              header="Total"
              bodyClassName={"text-info h6"}
              bodyStyle={{ fontSize: 12, fontWeight: "bold" }}
              body={({ total }) => {
                let localStr = total?.split(/\(/);
                let amnt = "";
                let qty = "";
                if (localStr !== undefined) {
                  amnt = localStr[0]?.replace(/\s/, "");
                  qty = localStr[1]?.replace(/\)/, "");
                } else {
                  amnt = "0";
                  qty = "0";
                }
                return (
                  <>
                    <p
                      className="text-primary h6 mb-0"
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      {qty}
                    </p>
                    <p
                      className="text-primary h6 mt-0"
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      {parseFloat(amnt)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </p>
                  </>
                );
              }}
            />
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={HQProductMonthlyData?.length}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    );
  };

  const rowCustomerExpansionTemplate = () => {
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 mb-20">
        <div className="pd-20 card-box mb-30">
          <span className="h4 mb-2">Customer wise Sale</span>
          <DataTable
            value={displayedCustomerData}
            stripedRows
            removableSort
            key={"Customer"}
            dataKey="Customer"
            rowHover
            expandedRows={Sub2expanded}
            rowExpansionTemplate={rowExpansionTemplate3}
            size="normal"
            footerColumnGroup={footerColumnGroupCustomerData}
            loading={customerloading}
          >
            <Column
              field="Customer"
              header="Customer Name"
              sortable
              style={{
                minWidth: "10rem",
                fontSize: 14,
                position: "sticky",
                left: 0,
                zIndex: 100,
                backgroundColor: "#f5f5f5",
              }}
              bodyClassName={"text-primary h6"}
              body={(e: any) => {
                return (
                  <a
                    style={{ color: "red", cursor: 'pointer' }}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        Sub2expanded != null &&
                        compareKeys(Sub2expanded, e.Customer)
                      ) {
                        obj = {};
                      } else {
                        obj[e.Customer] = true;
                      }
                      setSub2expanded(obj);
                    }}
                  >
                    {e.Customer}
                  </a>
                );
              }}
            />
            <Column
              field={`Apr${startDate.substring(2)}`}
              header={`Apr-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Apr${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`May${startDate.substring(2)}`}
              header={`May-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`May${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Jun${startDate.substring(2)}`}
              header={`Jun-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Jun${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Jul${startDate.substring(2)}`}
              header={`Jul-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Jul${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Aug${startDate.substring(2)}`}
              header={`Aug-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Aug${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Sep${startDate.substring(2)}`}
              header={`Sep-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Sep${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Oct${startDate.substring(2)}`}
              header={`Oct-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Oct${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Nov${startDate.substring(2)}`}
              header={`Nov-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Nov${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Dec${startDate.substring(2)}`}
              header={`Dec-${startDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Dec${startDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Jan${endDate.substring(2)}`}
              header={`Jan-${endDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Jan${endDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Feb${endDate.substring(2)}`}
              header={`Feb-${endDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Feb${endDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              field={`Mar${endDate.substring(2)}`}
              header={`Mar-${endDate.substring(2)}`}
              bodyClassName={"text-success h6"}
              bodyStyle={{ fontSize: 12 }}
              body={(e) => {
                return e[`Mar${endDate.substring(2)}`]?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "INR",
                  }
                );
              }}
            />
            <Column
              header="Total"
              bodyClassName={"text-info h6"}
              bodyStyle={{ fontSize: 12, fontWeight: "bold" }}
              body={({ total }) => {
                return total?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "INR",
                });
              }}
            />
          </DataTable>
          <Paginator
            first={firstCustomerData}
            rows={rowsCustomerData}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={HQCustomerMonthlyData?.length}
            onPageChange={onPageChangeCustomerData}
          />
        </div>
      </div>
    );
  };

  const [activeIndex, setActiveIndex] = useState(role === "admin" ? 0 : 1);

  const [selected, setSelected]: any = useState(null);
  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 1;
    const endingYear = currentYear;
    setSelected({
      value: `${startingYear}-${endingYear}`,
      label: `${startingYear}-${endingYear}`,
    });

    if (role === "MR") {
      onSubmitProduct(1);
    }
  }, []);

  function generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;
    const endingYear = currentYear;

    const years = [];
    for (let i = startingYear; i <= endingYear; i++) {
      years.push({
        label: `${i}-${i + 1}`,
        value: `${i}-${i + 1}`,
      });
    }
    return years;
  }

  useEffect(() => {
    if (selected == null) {
      return;
    }
    let temp = selected?.value;
    let startdate = `${temp?.split("-")[0]}`;
    let enddate = `${temp?.split("-")[1]}`;
    setStartDate(startdate);
    setEndDate(enddate);
  }, [selected]);

  useEffect(() => {
    if (startDate !== "") {
      GetAchivementGrowth(
        activeIndex === 0 ? "" : activeIndex === 2 ? 3 : activeIndex
      );
      GetMonthWiseProductSales(
        activeIndex === 0 ? "" : activeIndex === 2 ? 3 : activeIndex
      );
      getMonthlyData(
        activeIndex === 0 ? "" : activeIndex === 2 ? 3 : activeIndex
      );

      if (role === "MR") {
        onSubmitProduct(1);
      }

      // getMonthlyData(role === "admin" ? 0 : division[0].Id);
    }

    // return () => {
    //   controller.abort();
    // };
  }, [startDate]);

  useEffect(() => {
    if (loader) {
      fullScreenLoader.setloader(true);
    } else {
      fullScreenLoader.setloader(false);
    }
  }, [loader]);

  return (
    <>
      <Helmet>
        <title>Analytic dashboard</title>
      </Helmet>

      <div className="title pb-20 pl-20">
        <Row className="align-items-center">
          <Badge bg="info">
            <span className="micon bi bi-house text-white h6"></span>
          </Badge>
          <span className="mb-0 ml-2 text-secondary">Dashboard</span>
        </Row>
      </div>

      <div className="row pb-10">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="pd-20 card-box">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => {
                GetAchivementGrowth(
                  e.index == 0 ? "" : e.index == 2 ? 3 : e.index
                );
                GetMonthWiseProductSales(
                  e.index == 0 ? "" : e.index == 2 ? 3 : e.index
                );
                getMonthlyData(
                  e.index === 0 ? "" : e.index === 2 ? 3 : e.index
                );
                setActiveIndex(e.index);
                setProductExtended(null);
                setMonthExtended(null);
                setProductMonthextended(null);
                setCustomerextended(null);
              }}
            >
              {role === "admin" && (
                <TabPanel header="All">
                  <div
                    className="row pb-10"
                    style={{ backgroundColor: "#ecf0f4" }}
                  >
                    {!loader ? (
                      <>
                        <div
                          className="col-xl-4 col-lg-12 col-md-4"
                          style={{ backgroundColor: "#ecf0f4" }}
                        >
                          <div className="form-group">
                            <label className="col-form-label">
                              Financial Year{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={generateFinancialYears()}
                              className="z-3"
                              isClearable
                              classNamePrefix={"z-3"}
                              onChange={(e: any) => {
                                setSelected(e);
                              }}
                              value={selected}
                            />
                          </div>
                        </div>

                        <div className="d-flex flex-wrap w-100 chartWrap">
                          <div
                            className="col-xl-4 col-lg-6 col-md-12"
                            style={{ backgroundColor: "#ecf0f4" }}
                          >
                            <div className="pd-20 card-box mb-30">
                              <Chart
                                type="bar"
                                width="250"
                                height="300"
                                data={SalesData}
                                options={SalesOptions}
                              />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="pd-20 card-box mb-30">
                              <Chart
                                type="bar"
                                // style={{
                                //   width: "100%",
                                //   height: "calc(100% - (250px))",
                                // }}
                                width="250"
                                height="300"
                                data={AMSData}
                                options={AMSOptions}
                              />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-12 col-md-12">
                            <div className="pd-20 card-box mb-30 justify-content-center align-items-center d-flex">
                              <Chart
                                type="doughnut"
                                data={AMSGrowthData}
                                width="250"
                                height="300"
                                options={AMSGrowthOptions}
                              />
                            </div>
                          </div>
                        </div>

                        {/* <div className="d-flex flex-wrap w-100 raw justify-content-between mb-4">
                          <div className="card-box col m-3 py-5">
                            <div style={{width:'100%', margin:"auto"}}>
                            <Chart
                              type="bar"
                              width="250"
                              height="200"
                              data={SalesData}
                              options={SalesOptions}
                              />
                              </div>
                          </div>
                          <div className="card-box col m-3 py-5">
                            <Chart
                              type="bar"
                              // style={{
                              //   width: "100%",
                              //   height: "calc(100% - (250px))",
                              // }}
                              width="250"
                              height="200"
                              data={AMSData}
                              options={AMSOptions}
                            />
                          </div>
                          <div className="col m-3 pt-5 pb-3 card-box">
                            <div className="justify-content-center d-flex">
                              <Chart
                                type="doughnut"
                                data={AMSGrowthData}
                                width="350"
                                height="350"
                                options={AMSGrowthOptions}
                              />
                            </div>
                          </div>
                        </div> */}

                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="pd-20 card-box mb-30">
                            <DataTable
                              value={achievementGrowth}
                              stripedRows
                              rowHover
                              size="normal"
                              dataKey="divisionId"
                              style={{ cursor: "pointer" }}
                              rowExpansionTemplate={rowHQExpansionTemplate1}
                              expandedRows={subhqexpanded}
                              onRowToggle={(e) => {
                                console.log("onRowToggle", e);
                                let expanded: any = e.data;
                                if (Object.keys(e.data).length > 1) {
                                  delete expanded[previousId];
                                  setSubHqExpanded(expanded);
                                } else {
                                  setSubHqExpanded(expanded);
                                }
                                if (Object.keys(e.data).length !== 0) {
                                  onSubmitProduct(Object.keys(e.data)[0]);
                                  setPreviousId(Object.keys(e.data)[0]);
                                }
                              }}
                              tableStyle={{ minWidth: "60rem" }}
                            >
                              <Column expander style={{ width: "5rem" }} />
                              <Column
                                key="name-0"
                                header="Division"
                                bodyClassName={"text-primary h6"}
                                bodyStyle={{ fontSize: 14 }}
                                body={(e: any) => {
                                  return <span>{e?.divisionName}</span>;
                                }}
                              />
                              <Column
                                key="name-1"
                                header={`Total Sales(${startDate}-${endDate.substring(
                                  2
                                )})`}
                                bodyClassName={"text-primary h6"}
                                bodyStyle={{ fontSize: 14 }}
                                body={(e: any) => {
                                  return role !== "admin"
                                    ? ""
                                    : parseFloat(
                                      e?.CurrentYearTotalSales
                                    )?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    });
                                }}
                              // body={(e: any) => {
                              //   console.log(e, "this is data from api");
                              //   return (
                              //     <a
                              //       style={{ color: "red" }}
                              //       onClick={() => {
                              //         let obj: any = {};
                              //         if (
                              //           subhqexpanded != null &&
                              //           compareKeys(
                              //             subhqexpanded,
                              //             e?.CurrentYearTotalSales
                              //           )
                              //         ) {
                              //           obj = {};
                              //         } else {
                              //           obj[e?.CurrentYearTotalSales] = true;
                              //         }
                              //         onSubmitProduct(e.divisionId);
                              //         setSubHqExpanded(obj);
                              //         setProductExtended(null);
                              //         setMonthExtended(null);
                              //         setProductMonthextended(null);
                              //         setCustomerextended(null);
                              //         setHQAchievementGrowth([]);
                              //         setProductAchievementGrowth([]);
                              //         setHQMonthlyData([]);
                              //         setDisplayedData([]);
                              //         setDisplayedCustomerData([]);
                              //       }}
                              //     >
                              //       {role !== "admin" ? (
                              //         <button className="btn btn-secondary btn-sm">
                              //           View
                              //         </button>
                              //       ) : (
                              //         parseFloat(
                              //           e?.CurrentYearTotalSales
                              //         )?.toLocaleString("en-US", {
                              //           style: "currency",
                              //           currency: "INR",
                              //         })
                              //       )}
                              //     </a>
                              //   );
                              // }}
                              />
                              <Column
                                key="name-2"
                                header={`Total Sales(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
                                  })`}
                                bodyClassName={"text-primary h6"}
                                bodyStyle={{ fontSize: 14 }}
                                body={({ PrevYearTotalSales }) => {
                                  return role !== "admin"
                                    ? ""
                                    : parseFloat(
                                      PrevYearTotalSales
                                    )?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    });
                                }}
                              />
                              <Column
                                key="name-3"
                                header={`AMS(${startDate}-${endDate.substring(
                                  2
                                )})`}
                                bodyClassName={"text-primary h6"}
                                bodyStyle={{ fontSize: 14 }}
                                body={({ CurrentYearAMS }) => {
                                  return role !== "admin"
                                    ? ""
                                    : parseFloat(
                                      CurrentYearAMS
                                    )?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                    });
                                }}
                              />
                              <Column
                                key="name-4"
                                header={`AMS(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
                                  })`}
                                bodyClassName={"text-primary h6"}
                                bodyStyle={{ fontSize: 14 }}
                                body={({ PrevYearAMS }) => {
                                  return role !== "admin"
                                    ? ""
                                    : parseFloat(PrevYearAMS)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    );
                                }}
                              />
                              <Column
                                key="name-5"
                                header="AMS Growth(%)"
                                bodyClassName={"text-primary h6"}
                                bodyStyle={{ fontSize: 14 }}
                                body={({ AMSGrowth }) => {
                                  return role !== "admin"
                                    ? ""
                                    : `${AMSGrowth}%`;
                                }}
                              />
                            </DataTable>
                          </div>
                        </div>

                        {subhqexpanded != null &&
                          Object.keys(subhqexpanded)?.length > 0 && (
                            <div className="col-xl-6 col-lg-12 col-md-12">
                              <div className="pd-20 card-box mb-30 justify-content-center align-items-center d-flex">
                                <Chart
                                  type="pie"
                                  data={HQGraphData}
                                  options={HQGraphOptions}
                                  style={{ height: "600px", width: "600px" }}
                                />
                              </div>
                            </div>
                          )}
                        <div className="col-xl-6 col-lg-12 col-md-12">
                          <div className=" pd-20 card-box mb-30">
                            <Chart
                              type="bar"
                              data={MonthlyGraph}
                              options={MonthlyGraphOptions}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                          <div className=" pd-20 card-box mb-30 ">
                            <Chart
                              type="line"
                              data={MonthlyGraphQty}
                              options={MonthlyGraphOptionsQty}
                            />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="pd-20 card-box mb-30">
                            <span className="h4 mb-2">Product Wise Data</span>
                            <DataTable
                              value={displayedProductMonthData}
                              stripedRows
                              globalFilterFields={["ProductName"]}
                              header={header1}
                              removableSort
                              key={"ProductName"}
                              dataKey="ProductName"
                              filters={filters1}
                              rowHover
                              expandedRows={expanded}
                              rowExpansionTemplate={rowExpansionTemplate1}
                              size="normal"
                              footerColumnGroup={footerColumnGroupProductMonth}
                            >
                              <Column
                                field="ProductName"
                                header="Product Name"
                                sortable
                                style={{
                                  minWidth: "10rem",
                                  fontSize: 14,
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 100,
                                  backgroundColor: "#f5f5f5",

                                }}
                                bodyClassName={"text-primary h6"}
                                body={(e: any) => {
                                  return (
                                    <a
                                      style={{ color: "red", cursor: "pointer" }}
                                      onClick={() => {
                                        let obj: any = {};
                                        if (
                                          expanded != null &&
                                          compareKeys(expanded, e.ProductName)
                                        ) {
                                          obj = {};
                                        } else {
                                          obj[e.ProductName] = true;
                                        }
                                        setExpanded(obj);
                                      }}
                                    >
                                      {e.ProductName}
                                    </a>
                                  );
                                }}
                              />
                              <Column
                                field={`Apr${startDate.substring(2)}`}
                                header={`Apr-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Apr${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`May${startDate.substring(2)}`}
                                header={`May-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`May${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Jun${startDate.substring(2)}`}
                                header={`Jun-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Jun${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Jul${startDate.substring(2)}`}
                                header={`Jul-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Jul${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Aug${startDate.substring(2)}`}
                                header={`Aug-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Aug${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Sep${startDate.substring(2)}`}
                                header={`Sep-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Sep${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Oct${startDate.substring(2)}`}
                                header={`Oct-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Oct${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Nov${startDate.substring(2)}`}
                                header={`Nov-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Nov${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Dec${startDate.substring(2)}`}
                                header={`Dec-${startDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Dec${startDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Jan${endDate.substring(2)}`}
                                header={`Jan-${endDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Jan${endDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Feb${endDate.substring(2)}`}
                                header={`Feb-${endDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Feb${endDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                              <Column
                                field={`Mar${endDate.substring(2)}`}
                                header={`Mar-${endDate.substring(2)}`}
                                bodyClassName={"text-success h6"}
                                bodyStyle={{ fontSize: 12 }}
                                body={(e) => {
                                  let localStr =
                                    e[`Mar${endDate.substring(2)}`]?.split(
                                      /\(/
                                    );
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />

                              <Column
                                header="Total"
                                bodyClassName={"text-info h6"}
                                bodyStyle={{ fontSize: 12, fontWeight: "bold" }}
                                body={({ total }) => {
                                  let localStr = total?.split(/\(/);
                                  let amnt = "";
                                  let qty = "";
                                  if (localStr !== undefined) {
                                    amnt = localStr[0]?.replace(/\s/, "");
                                    qty = localStr[1]?.replace(/\)/, "");
                                  } else {
                                    amnt = "0";
                                    qty = "0";
                                  }
                                  return `${parseFloat(amnt)?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )} (${qty})`;
                                }}
                              />
                            </DataTable>
                            <Paginator
                              first={firstproductmonth}
                              rows={rowsproductmonth}
                              rowsPerPageOptions={[10, 20, 50, 100]}
                              totalRecords={ProductMonthWiseData?.length}
                              onPageChange={onPageChangeProductMonth}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </TabPanel>
              )}
              {division?.map((e: any) => {
                return (
                  <TabPanel header={e.Value}>
                    <div
                      className="row pb-10"
                      style={{ backgroundColor: "#ecf0f4" }}
                    >
                      {!loader ? (
                        <>
                          <div
                            className="col-xl-4 col-lg-4 col-md-4"
                            style={{ backgroundColor: "#ecf0f4" }}
                          >
                            <div className="form-group">
                              <label className="col-form-label">
                                Financial Year{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={generateFinancialYears()}
                                className="z-3"
                                isClearable
                                classNamePrefix={"z-3"}
                                onChange={(e: any) => {
                                  setSelected(e);
                                }}
                                value={selected}
                              />
                            </div>
                          </div>

                          {/* <div
                            className="col-xl-8 col-lg-8 col-md-8"
                            style={{ backgroundColor: "#ecf0f4" }}
                          ></div>

                          <div
                            className="col-xl-4 col-lg-4 col-md-4"
                            style={{ backgroundColor: "#ecf0f4" }}
                          >
                            <div className="pd-20 card-box mb-30">
                              <Chart
                                type="bar"
                                width="250"
                                height="200"
                                data={SalesData}
                                options={SalesOptions}
                              />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="pd-20 card-box mb-30">
                              <Chart
                                type="bar"
                                width="250"
                                height="200"
                                data={AMSData}
                                options={AMSOptions}
                              />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="pd-20 card-box mb-30 justify-content-center align-items-center d-flex">
                              <Chart
                                type="doughnut"
                                data={AMSGrowthData}
                                width="350"
                                height="350"
                                options={AMSGrowthOptions}
                              />
                            </div>
                          </div> */}

                          <div className="d-flex flex-wrap w-100">
                            <div
                              className="col-xl-4 col-lg-6 col-md-12"
                              style={{ backgroundColor: "#ecf0f4" }}
                            >
                              <div className="pd-20 card-box mb-30">
                                <Chart
                                  type="bar"
                                  width="250"
                                  height="300"
                                  data={SalesData}
                                  options={SalesOptions}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                              <div className="pd-20 card-box mb-30">
                                <Chart
                                  type="bar"
                                  // style={{
                                  //   width: "100%",
                                  //   height: "calc(100% - (250px))",
                                  // }}
                                  width="250"
                                  height="300"
                                  data={AMSData}
                                  options={AMSOptions}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-12 col-md-12">
                              <div className="pd-20 card-box mb-30 justify-content-center align-items-center d-flex">
                                <Chart
                                  type="doughnut"
                                  data={AMSGrowthData}
                                  width="250"
                                  height="300"
                                  options={AMSGrowthOptions}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="pd-20 card-box mb-30">
                              {role !== "MR" ?
                                <DataTable
                                  value={achievementGrowth}
                                  stripedRows
                                  rowHover
                                  expandedRows={subhqexpanded}
                                  rowExpansionTemplate={rowHQExpansionTemplate1}
                                  size="normal"
                                  dataKey="divisionId"
                                  style={{ cursor: "pointer" }}
                                  onRowToggle={(e) => {
                                    console.log("onRowToggle", e);
                                    let expanded: any = e.data;
                                    if (Object.keys(e.data).length > 1) {
                                      delete expanded[previousId];
                                      setSubHqExpanded(expanded);
                                    } else {
                                      setSubHqExpanded(expanded);
                                    }
                                    if (Object.keys(e.data).length !== 0) {
                                      onSubmitProduct(Object.keys(e.data)[0]);
                                      setPreviousId(Object.keys(e.data)[0]);
                                    }
                                  }}
                                  tableStyle={{ minWidth: "60rem" }}
                                >
                                  <Column expander style={{ width: "5rem" }} />
                                  <Column
                                    key="name-1"
                                    header={`Total Sales(${startDate}-${endDate.substring(
                                      2
                                    )})`}
                                    bodyClassName={"text-primary h6"}
                                    bodyStyle={{ fontSize: 14 }}
                                    body={(e: any) => {
                                      return role == "MR"
                                        ? ""
                                        : parseFloat(
                                          e?.CurrentYearTotalSales
                                        )?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        });
                                    }}
                                  />
                                  <Column
                                    key="name-2"
                                    header={`Total Sales(${Number(startDate) - 1
                                      }-${Number(endDate.substring(2)) - 1})`}
                                    bodyClassName={"text-primary h6"}
                                    bodyStyle={{ fontSize: 14 }}
                                    body={({ PrevYearTotalSales }) => {
                                      return role == "MR"
                                        // return role !== "admin"
                                        ? ""
                                        : parseFloat(
                                          PrevYearTotalSales
                                        )?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        });
                                    }}
                                  />
                                  <Column
                                    key="name-3"
                                    header={`AMS(${startDate}-${endDate.substring(
                                      2
                                    )})`}
                                    bodyClassName={"text-primary h6"}
                                    bodyStyle={{ fontSize: 14 }}
                                    body={({ CurrentYearAMS }) => {
                                      return role == "MR"
                                        // return role !== "admin"
                                        ? ""
                                        : parseFloat(
                                          CurrentYearAMS
                                        )?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                        });
                                    }}
                                  />
                                  <Column
                                    key="name-4"
                                    header={`AMS(${Number(startDate) - 1}-${Number(endDate.substring(2)) - 1
                                      })`}
                                    bodyClassName={"text-primary h6"}
                                    bodyStyle={{ fontSize: 14 }}
                                    body={({ PrevYearAMS }) => {
                                      // return role !== "admin"
                                      return role == "MR"
                                        ? ""
                                        : parseFloat(PrevYearAMS)?.toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "INR",
                                          }
                                        );
                                    }}
                                  />
                                  <Column
                                    key="name-5"
                                    header="AMS Growth(%)"
                                    bodyClassName={"text-primary h6"}
                                    bodyStyle={{ fontSize: 14 }}
                                    body={({ AMSGrowth }) => {
                                      // return role !== "admin"
                                      return role == "MR"
                                        ? ""
                                        : `${AMSGrowth}%`;
                                    }}
                                  />
                                </DataTable>
                                : rowHQExpansionTemplate1()
                              }
                            </div>
                          </div>

                          {/* {subhqexpanded != null &&
                            Object.keys(subhqexpanded)?.length > 0 && (
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="pd-20 card-box mb-30 justify-content-center align-items-center d-flex">
                                  <Chart
                                    type="pie"
                                    data={HQGraphData}
                                    options={HQGraphOptions}
                                    style={{ height: "600px", width: "600px" }}
                                  />
                                </div>
                              </div>
                            )}

                          <div className="row " style={{marginLeft:'1px'}}>
                            <div className="col-xl-6 col-lg-6 col-md-6 "> 
                              <div className="pd-20 card-box mb-30 " >
                                <Chart
                                  type="bar"
                                  data={MonthlyGraph}
                                  options={MonthlyGraphOptions}
                                  style={{
                                    height: "400px",
                                    width: "400px",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6">
                              <div className="pd-20 card-box mb-30">
                                <Chart
                                  type="line"
                                  data={MonthlyGraphQty}
                                  options={MonthlyGraphOptionsQty}
                                  style={{
                                    height: "400px",
                                    width: "400px",
                                  }}
                                />
                              </div>
                            </div>
                          </div> */}

                          {subhqexpanded != null &&
                            Object.keys(subhqexpanded)?.length > 0 && (
                              <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="pd-20 card-box mb-30 justify-content-center align-items-center d-flex">
                                  <Chart
                                    type="pie"
                                    data={HQGraphData}
                                    options={HQGraphOptions}
                                    style={{ height: "600px", width: "600px" }}
                                  />
                                </div>
                              </div>
                            )}
                          <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className=" pd-20 card-box mb-30">
                              <Chart
                                type="bar"
                                data={MonthlyGraph}
                                options={MonthlyGraphOptions}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className=" pd-20 card-box mb-30 ">
                              <Chart
                                type="line"
                                data={MonthlyGraphQty}
                                options={MonthlyGraphOptionsQty}
                              />
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="pd-20 card-box mb-30">
                              <span className="h4 mb-2">Product Wise Data</span>
                              <DataTable
                                value={displayedProductMonthData}
                                stripedRows
                                globalFilterFields={["ProductName"]}
                                header={header1}
                                removableSort
                                key={"ProductName"}
                                dataKey="ProductName"
                                filters={filters1}
                                rowHover
                                expandedRows={expanded}
                                rowExpansionTemplate={rowExpansionTemplate1}
                                size="normal"
                                style={{ cursor: "pointer" }}
                                footerColumnGroup={
                                  footerColumnGroupProductMonth
                                }
                              >
                                <Column
                                  field="ProductName"
                                  header="Product Name"
                                  sortable
                                  style={{
                                    minWidth: "10rem",
                                    fontSize: 14,
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 100,
                                    backgroundColor: "#f5f5f5",
                                  }}
                                  bodyClassName={"text-primary h6"}
                                  body={(e: any) => {
                                    return (
                                      <a
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          let obj: any = {};
                                          if (
                                            expanded != null &&
                                            compareKeys(expanded, e.ProductName)
                                          ) {
                                            obj = {};
                                          } else {
                                            obj[e.ProductName] = true;
                                          }
                                          setExpanded(obj);
                                        }}
                                      >
                                        {e.ProductName}
                                      </a>
                                    );
                                  }}
                                />
                                <Column
                                  field={`Apr${startDate.substring(2)}`}
                                  header={`Apr-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Apr${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`May${startDate.substring(2)}`}
                                  header={`May-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`May${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Jun${startDate.substring(2)}`}
                                  header={`Jun-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Jun${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Jul${startDate.substring(2)}`}
                                  header={`Jul-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Jul${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Aug${startDate.substring(2)}`}
                                  header={`Aug-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Aug${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Sep${startDate.substring(2)}`}
                                  header={`Sep-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Sep${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Oct${startDate.substring(2)}`}
                                  header={`Oct-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Oct${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Nov${startDate.substring(2)}`}
                                  header={`Nov-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Nov${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Dec${startDate.substring(2)}`}
                                  header={`Dec-${startDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Dec${startDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Jan${endDate.substring(2)}`}
                                  header={`Jan-${endDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Jan${endDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Feb${endDate.substring(2)}`}
                                  header={`Feb-${endDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Feb${endDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  field={`Mar${endDate.substring(2)}`}
                                  header={`Mar-${endDate.substring(2)}`}
                                  bodyClassName={"text-success h6"}
                                  bodyStyle={{ fontSize: 12 }}
                                  body={(e) => {
                                    let localStr =
                                      e[`Mar${endDate.substring(2)}`]?.split(
                                        /\(/
                                      );
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                                <Column
                                  header="Total"
                                  bodyClassName={"text-info h6"}
                                  bodyStyle={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                  }}
                                  body={({ total }) => {
                                    let localStr = total?.split(/\(/);
                                    let amnt = "";
                                    let qty = "";
                                    if (localStr !== undefined) {
                                      amnt = localStr[0]?.replace(/\s/, "");
                                      qty = localStr[1]?.replace(/\)/, "");
                                    } else {
                                      amnt = "0";
                                      qty = "0";
                                    }
                                    return `${parseFloat(amnt)?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                      }
                                    )} (${qty})`;
                                  }}
                                />
                              </DataTable>
                              <Paginator
                                first={firstproductmonth}
                                rows={rowsproductmonth}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                totalRecords={ProductMonthWiseData?.length}
                                onPageChange={onPageChangeProductMonth}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </TabPanel>
                );
              })}
            </TabView>
          </div>
        </div>
      </div>
    </>
  );
}
