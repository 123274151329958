import { useMutation, useQuery, useQueryClient } from 'react-query'
import axiosInstance from '../../_helper/Interceptor'


export const useCreate = (apiname?: any) => useMutation(
    async (data: any) => {
        const response = await axiosInstance.post(`${process.env.REACT_APP_BASEURL}${apiname}`, data)
        return response
    }
)

export const useCreateByID = (apiname?: any, id?: any) => useMutation(
    async (data: any) => {
        if (id === undefined) {
            const response = await axiosInstance.post(`${process.env.REACT_APP_BASEURL}${apiname}`, data)
            return response
        } else {
            const response = await axiosInstance.put(`${process.env.REACT_APP_BASEURL}${apiname}/${id}`, data)
            return response
        }
    }
)


export const useCreateDoctor = (apiname?: any, id?: any) => useMutation(
    async (data: any) => {
        if (id === undefined) {
            const response = await axiosInstance.post(`${process.env.REACT_APP_BASEURL}${apiname}`, data)
            return response
        } else {
            const response = await axiosInstance.put(`${process.env.REACT_APP_BASEURL}${apiname}/${id}`, data)
            return response
        }
    }
)

const fetchAll = async (apiname?: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}`)
    return response.data.Data
}

const fetchAllID = async (apiname?: any, id?: any, doctorid?: any, signal: any = false) => {
    if (id === undefined || id === null) {
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${id}${doctorid == null || doctorid == undefined ? "" : `/${doctorid}`} `, { signal })
    return response.data.Data
}

const fetchTerritoryID = async (apiname?: any, id?: any,) => {
    if (id === undefined) {
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/GetById/${id}`)
    return response.data.Data
}

const fetchDoctorID = async (apiname?: any, id?: any, signal: any = false) => {
    if (id == undefined) {
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${id}`, { signal })
    return response.data.Data
}

export const useFetchAll = (apiname: any) => {
    return useQuery(["fetchAll", apiname], () => fetchAll(apiname))
}

export const useFetchAllID = (apiname: any, id: any, doctorid?: any, signal: any = false) => {
    return useQuery(["fetchAll", apiname, id], () => fetchAllID(apiname, id, doctorid, signal))
}

export const useTerritoryByID = (apiname: any, id: any) => {
    return useQuery(["fetchAll", apiname, id], () => fetchTerritoryID(apiname, id))
}

export const useFetchDoctorID = (apiname: any, id: any, signal: any = false) => {
    return useQuery(["fetchAll", apiname, id], () => fetchDoctorID(apiname, id, signal))
}

const doctorFetchAllID = async (apiname?: any, doctorid?: any) => {
    console.log(`${process.env.REACT_APP_BASEURL}${apiname}${doctorid == null || doctorid == undefined ? "" : `?doctorId=${doctorid}`} `, "URL")
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}${doctorid == null || doctorid == undefined ? "" : `?doctorId=${doctorid}`} `)
    return response.data.Data
}

export const useDoctorFetchAllID = (apiname: any, doctorid?: any) => {
    return useQuery(["doctorFetchAll", apiname, doctorid], () => doctorFetchAllID(apiname, doctorid))
}

export const useDeleteById = (apiname?: any, id?: any) =>
    useMutation(async () => {
        if (typeof (id) === "number") {
            const response = await axiosInstance.delete(`${process.env.REACT_APP_BASEURL}${apiname}/${id}`);
            return response
        } else {
            const demo = id?.map((e: any) => `id=${e}`)
            console.log(String(demo))
            const response = await axiosInstance.delete(`${process.env.REACT_APP_BASEURL}${apiname}/MultipleDelete?${String(demo).replaceAll(",", "&")}`);
            return response
        }
    });

const fetchGraph = async (apiname?: any, id?: any) => {
    if (id == undefined) {
        return {};
    }
    const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASEURL}${apiname}?productId=${id}`
    );
    return response.data.Data;
};

export const useFetchGraph = (apiname: any, id: any) => {
    return useQuery(["fetchAll", apiname, id], () => fetchGraph(apiname, id));
};


const fetchCalender = async (apiname?: any, date?: any, signal: any = false) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}?date=${date}`, { signal })
    return response.data.Data
}

export const useFetchCalender = (apiname: any, date?: any, signal: any = false) => {
    return useQuery(["fetchAll", apiname, date], () => fetchCalender(apiname, date, signal))
}

