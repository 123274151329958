import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { toast } from "react-toastify";
import axiosInstance from "../../_helper/Interceptor";
import ReactSelect from "../../common/ReactSelect";
import { Input } from "../../common/Input";
import { useForm } from "react-hook-form";
import { Badge } from "primereact/badge";
import moment from "moment";
import { NullHandler } from "../../common/NullHandler";
import { AddEditSampleGrid } from "../upload/sampleUpload/AddEditSampleGrid";
import { AddEditGiftGrid } from "../upload/giftUpload/AddEditGiftGrid";

const IssuedSample = () => {
  const {
    formState: { errors },
    control,
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const [selecteduser, setselecteduser]: any = useState(null);
  const [selectedproduct, setselectedproduct]: any = useState(null);
  const [selectedgift, setselectedgift]: any = useState(null);

  let [transferedData, setTransferredData] = useState(null);

  const [totalData, settotalData]: any = useState(null);
  console.log(totalData, "totalData");
  const [loading, setLoading] = useState(false);

  const [Nodata, setNodata] = useState(false);

  // Date
  const [startdate, setDate] = useState("");
  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split("T")[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState("");
  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split("T")[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  const [totalRecord, setTotalRecords] = useState(0);

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    sortField: null,
    sortOrder: null,
  });

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const loadLazyData = () => {
    setLoading(true);
    if (lazyState.sortField != null) {
      lazyState.first = 0;
    }
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_SAMPLEMAPPINGGETALLISSUEDSAMPLE_API
        }?PageNumber=${
          lazyState?.pageCount === undefined
            ? lazyState?.page === undefined
              ? 1
              : lazyState?.page
            : lazyState?.page + 1
        }&PageSize=${lazyState?.rows}${
          lazyState?.search === undefined ||
          lazyState?.search === null ||
          lazyState?.search === ""
            ? ""
            : `&CurrentSearch=${lazyState?.search}`
        }${
          lazyState?.sortField !== null
            ? `&SortColumnName=${lazyState?.sortField}&SortColumnDirection=${
                lazyState?.sortOrder === 1 ? "asc" : "desc"
              }`
            : ""
        }${selecteduser !== null ? `&UserId=${selecteduser?.value}` : ""}${
          selectedproduct !== null
            ? `&ProductId=${selectedproduct?.value?.value}`
            : ""
        }${
          selectedgift !== null ? `&GiftId=${selectedgift?.value}` : ""
        }&StartDate=${startdate}&EndDate=${defaultDate}`
      )
      .then((res) => {
        const temp = res?.data?.Data;
        settotalData(temp);
        console.log(res?.data?.TotalDataCount, "total data");
        setTotalRecords(res?.data?.TotalDataCount);
        setLoading(false);
        if (temp?.length === 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        settotalData(null);
        if (error?.response?.data?.StatusCode !== 400) {
          toast.error(error?.response?.data?.Message, {
            position: "top-right",
          });
        } else {
          toast.error(`${error.message}`, { position: "top-right" });
        }
      });
  };

  useEffect(() => {
    loadLazyData();
  }, [
    selecteduser,
    selectedgift,
    selectedproduct,
    startdate,
    defaultDate,
    lazyState,
  ]);

  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);

  let [userIdss, setUserIdss]: any = useState(null);

  const [updateId, setupdateId]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  useEffect(() => {
    if (bool) {
      loadLazyData();
    }
  }, [bool]);

  const [giftupdateId, setgiftupdateId]: any = useState(null);

  const actionNameTemplate = (id: any) => {
    let gift = id?.GiftName == null ? "" : `${id?.GiftName}`;
    let product = id?.ProductName == null ? "" : `${id?.ProductName}`;

    return product + " " + gift || " - ";
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div>
        <h6 className="mb-1">Sample Requests</h6>

        {updateId !== null && (
          <AddEditSampleGrid
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
            transfer={transferedData}
            setTransfer={setTransferredData}
          />
        )}

        {giftupdateId !== null && (
          <AddEditGiftGrid
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setgiftupdateId(null);
            }}
            open={giftupdateId !== null}
            id={giftupdateId}
          />
        )}

        <DataTable
          scrollable
          showGridlines
          value={data?.SampleRequests}
          size="small"
          className="custom-datatable"
          stripedRows
          dataKey="SampleRequestId"
          expandedRows={subexpandedRows}
          onRowToggle={(e) => {
            let expanded: any = e.data;
            if (Object.keys(e.data).length > 1) {
              delete expanded[subpreviousId];
              setSubExpandedRows(expanded);
            } else {
              setSubExpandedRows(expanded);
            }
            if (Object.keys(e.data).length !== 0) {
              setSubPreviousId(Object.keys(e.data)[0]);
            }
          }}
          rowExpansionTemplate={rowSubExpansionTemplate}
        >
          {data?.SampleRequests?.some(allowSubExpansion) && (
            <Column
              expander={allowSubExpansion}
              style={{ width: "2%", textAlign: "center" }}
            />
          )}
          <Column
            header="Product/Gift"
            body={actionNameTemplate}
            style={{ width: "58%" }}
          ></Column>
          <Column field="Qty" header="Qty" style={{ width: "10%" }}></Column>
          <Column
            field="RequestedDate"
            header="Requested Date"
            alignHeader={"center"}
            style={{ width: "20%", textAlign: "center" }}
            body={(e: any) => {
              if (e.RequestedDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"success"}
                      value={moment(e.RequestedDate).format("DD MMM, YYYY")}
                    ></Badge>
                    <Badge
                      className="m-1"
                      severity={"info"}
                      value={moment(e.RequestedDate).format("hh:mm A")}
                      style={{ backgroundColor: "#978EEB" }}
                    ></Badge>
                  </>
                );
              }
            }}
          ></Column>
          <Column
            header="Action"
            alignHeader={"center"}
            body={(e: any) => {
              return (
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  {e.GiftId ? (
                    // <button
                    //   type="button"
                    //   className="btn btn-outline-primary"
                    //   onClick={() => {
                    //     setgiftupdateId(0);
                    //     setTransferredData(e);
                    //   }}
                    // >
                    //   Issue
                    // </button>

                    <Link
                      to=""
                      onClick={() => {
                        setgiftupdateId(0);
                        setTransferredData(e);
                      }}
                      title="Issue"
                    >
                      <button className="btn p-0">
                        <i
                          className="pi pi-truck text-secondary"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>
                    </Link>
                  ) : e.ProductId && e.IsAllQtyIssued === false ? (
                    // <button
                    //   type="button"
                    //   className="btn btn-outline-primary"
                    //   onClick={() => {
                    //     setupdateId(0);
                    //     setTransferredData(e);
                    //   }}
                    // >
                    //   Issue
                    // </button>

                    <Link
                      to=""
                      onClick={() => {
                        setupdateId(0);
                        setTransferredData(e);
                      }}
                      title="Issue"
                    >
                      <button className="btn p-0">
                        <i
                          className="pi pi-truck text-secondary"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>
                    </Link>
                  ) : (
                    <div className="text-secondary">-</div>
                  )}
                </div>
              );
            }}
            style={{ width: "10%", textAlign: "center" }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  console.log(localStorage.getItem("Id"), "Data----->");
  const userId = localStorage.getItem("Id");

  const handleAction = async (params: any) => {
    const data = {
      SampleEmpMappingId: params,
    };
    console.log(params, "Params");
    await axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_SAMPLEEMPMAPPINGRECEIVEDSAMPLE_API}/${params}`,
        data
      )
      .then(() => {
        toast.success(`Recieved Sample Successfull`, { position: "top-right" });
        setbool(true);
      })
      .catch((error) => {
        if (error?.response?.data?.StatusCode !== 400) {
          toast.error(error?.response?.data?.Message, {
            position: "top-right",
          });
        } else {
          toast.error(`${error.message}`, { position: "top-right" });
        }
      });
  };

  const tempFun = (e: any) => {
    return (
      <>
        {e.ReceivedDate == null ? (
          <Link
            to=""
            onClick={() => {
              handleAction(e.SampleEmpMapId);
            }}
            title="Recieved"
          >
            <button className="btn p-0">
              <i
                className="pi pi-inbox text-secondary"
                style={{ fontSize: "20px" }}
              ></i>
            </button>
          </Link>
        ) : (
          <div className="text-secondary">-</div>
        )}
      </>
    );
  };

  const [subexpandedRows, setSubExpandedRows]: any = useState(null);
  const [subpreviousId, setSubPreviousId]: any = useState(1);

  const rowSubExpansionTemplate = (data: any) => {
    let tmp = Object.keys(userIdss)[0];
    console.log(tmp,"fdfdfdfd")
    return (
      <div>
        <h6 className="mb-1">Sample Issued</h6>
        <DataTable
          scrollable
          showGridlines
          value={data?.IssuedSamples}
          size="small"
          className="custom-datatable"
          stripedRows
          dataKey="Id"
        >
          <Column
            field="WarehouseName"
            header="Warehouse"
            body={(data: any) => {
              return NullHandler(data?.WarehouseName);
            }}
          ></Column>
          <Column field="Qty" header="Qty"></Column>
          <Column field="Status" header="Status"></Column>
          <Column
            field="IssuedByName"
            header="Issued By"
            body={(data: any) => {
              return NullHandler(data?.IssuedByName);
            }}
          ></Column>
          <Column
            field="IssuedDate"
            header="Issued Date"
            alignHeader={"center"}
            style={{ width: "20%", textAlign: "center" }}
            body={(e: any) => {
              if (e.IssuedDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"success"}
                      value={moment(e.IssuedDate).format("DD MMM, YYYY")}
                    ></Badge>
                    <Badge
                      className="m-1"
                      severity={"info"}
                      value={moment(e.IssuedDate).format("hh:mm A")}
                      style={{ backgroundColor: "#978EEB" }}
                    ></Badge>
                  </>
                );
              }
            }}
          ></Column>
          <Column
            field="ReceivedDate"
            header="Received Date"
            alignHeader={"center"}
            style={{ width: "20%", textAlign: "center" }}
            body={(e: any) => {
              if (e.ReceivedDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"success"}
                      value={moment(e.ReceivedDate).format("DD MMM, YYYY")}
                    ></Badge>
                    <Badge
                      className="m-1"
                      severity={"info"}
                      value={moment(e.ReceivedDate).format("hh:mm A")}
                      style={{ backgroundColor: "#978EEB" }}
                    ></Badge>
                  </>
                );
              }
            }}
          ></Column>
          <Column
            field="Note"
            header="Note"
            body={(data: any) => {
              return NullHandler(data?.Note);
            }}
          ></Column>
          {userId === Object.keys(userIdss)[0]  && <Column header="Action" body={tempFun}></Column>}
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData: any) => {
    return rowData?.SampleRequests?.length > 0;
  };

  const allowSubExpansion = (rowData: any) => {
    return rowData?.IssuedSamples?.length > 0;
  };

  return (
    <>
      <Helmet>
        <title>Issued Sample</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Issued Sample</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/Inventory"}>Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Issued Sample
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <form>
            <div className="row">
              <Input
                containerClass={"col-lg-3"}
                label="Start Date"
                type="date"
                errorMessage={errors?.StartDate?.message}
                onChange={(e: any) => {
                  clearErrors(["StartDate"]);
                  handleDateChange(e);
                }}
                value={startdate}
              />

              <Input
                containerClass={"col-lg-3"}
                label="End Date"
                type="date"
                errorMessage={errors?.EndDate?.message}
                onChange={(e: any) => {
                  clearErrors(["EndDate"]);
                  handleEndDateChange(e);
                }}
                value={defaultDate}
              />

              <ReactSelect
                containerClass={"col-lg-3"}
                label="Product"
                name={"ProductId"}
                onChange={(e: any) => {
                  setselectedproduct(e);
                  setValue("ProductId", e);
                }}
                value={selectedproduct}
                api={process.env.REACT_APP_PRODUCT_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.ProductId?.message}
              />

              <ReactSelect
                containerClass={"col-lg-3"}
                label="Gift"
                name={"GiftId"}
                onChange={(e: any) => {
                  setselectedgift(e);
                  setValue("GiftId", e);
                }}
                value={selectedgift}
                api={process.env.REACT_APP_GIFTMASTER_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.GiftId?.message}
              />

              <ReactSelect
                containerClass={"col-lg-3"}
                label="User"
                name={"UserId"}
                onChange={(e: any) => {
                  setselecteduser(e);
                  setValue("UserId", e);
                }}
                value={selecteduser}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.UserId?.message}
              />
            </div>
          </form>
        </div>

        {!loading && totalData !== null ? (
          <div className="pd-20 card-box mb-30">
            <>
              <div className="p-0">
                <DataTable
                  scrollable
                  showGridlines
                  value={totalData}
                  header={header}
                  sortField={lazyState.sortField}
                  sortOrder={lazyState.sortOrder}
                  onSort={OnSortChange}
                  loading={loading}
                  size="small"
                  className="custom-datatable"
                  stripedRows
                  expandedRows={expandedRows}
                  dataKey="UserId"
                  onRowToggle={(e) => {
                    debugger;
                    let expanded: any = e.data;
                    if (Object.keys(e.data).length > 1) {
                      delete expanded[previousId];
                      setExpandedRows(expanded);
                      setUserIdss(null);
                    } else {
                      setExpandedRows(expanded);
                      setUserIdss(expanded);
                    }
                    if (Object.keys(e.data).length !== 0) {
                      setPreviousId(Object.keys(e.data)[0]);
                    }
                  }}
                  rowExpansionTemplate={rowExpansionTemplate}
                >
                  {totalData?.some(allowExpansion) && (
                    <Column
                      expander={allowExpansion}
                      style={{ width: "2%", textAlign: "center" }}
                    />
                  )}
                  <Column field="EmployeeName" header="Employee"></Column>
                </DataTable>
              </div>
            </>
          </div>
        ) : (
          Nodata && (
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box text-center">
                <h3 className="text-secondary">No data found.</h3>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default IssuedSample;
