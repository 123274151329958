import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactSelect from "./ReactSelect";
import { Input } from "./Input";
import Textarea from "./Textarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface iPropspayment {
  type?: string;
  paymentArray?: any;
  setPaymentArray?: any;
  activeIndex?: any;
  onTabChange?: any;
  SetAlertIndex?:any
}

const PaymentInvoice = ({
  type,
  paymentArray,
  setPaymentArray,
  activeIndex,
  onTabChange,
  SetAlertIndex
}: iPropspayment) => {
  const {
    register: paymentRegister,
    handleSubmit: paymentHandleSubmit,
    formState: { errors: paymentErrors },
    setValue: paymentSetValue,
    control: paymentControl,
    clearErrors: paymentClearErrors,
    reset: paymentReset,
  }: any = useForm();
  const [paymentEditIndex, setPaymentEditIndex]: any = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod]: any = useState(null);


  useEffect(()=>{
    if(selectedPaymentMethod !== null || selectedPaymentMethod?.value !== undefined){
      SetAlertIndex(true)
    }else{
      SetAlertIndex(false)
    }
  },[selectedPaymentMethod])

  const onPaymentSubmit = (data: any) => {
    if (paymentEditIndex !== null) {
      let updateData = paymentArray.map((e: any, i: any) => {
        data.No = i + 1;
        return i == paymentEditIndex ? data : e;
      });

      setPaymentArray(updateData);
      setPaymentEditIndex(null);
    } else {
      data.No = paymentArray.length + 1;
      setPaymentArray((prevData: any) => [...prevData, data]);
    }

    paymentReset({
      PaymentMethodId: setSelectedPaymentMethod(null),
      Amount: null,
      Notes: null,
    });
  };

  const onPaymentEdit = (index: any) => {
    let item = paymentArray[index - 1];
    paymentSetValue("PaymentMethodId", item?.PaymentMethodId);
    setSelectedPaymentMethod(item?.PaymentMethodId);
    paymentSetValue("Amount", item?.Amount);
    paymentSetValue("Notes", item?.Notes);
    setPaymentEditIndex(index - 1);
  };

  const onPaymentDelete = (index: any) => {
    setPaymentArray(paymentArray.filter((e: any) => e.No != index));
  };

  const paymentAction = (id: any) => {
    return (
      <>
        <button
          className="btn btn-secondary btn-sm mr-2"
          type="button"
          onClick={() => onPaymentEdit(id.No)}
        >
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm"
          type="button"
          onClick={() => onPaymentDelete(id.No)}
        >
          Delete
        </button>
      </>
    );
  };
  return (
    <>
      <Accordion activeIndex={activeIndex} onTabChange={onTabChange}>
        <AccordionTab header={"Payment Details"}>
          {/* <div className="row">
            <div className="col-md-12 text-right">
              <button
                type="submit"
                className="btn btn-sm btn-primary mt-1"
                onClick={paymentHandleSubmit(onPaymentSubmit)}
              >
                {paymentEditIndex != null ? "Update" : "Add"}
              </button>
            </div>
          </div> */}

          <div className="row">
            <ReactSelect
              containerClass="col-lg-3"
              control={paymentControl}
              isRequired
              api={process.env.REACT_APP_INVENTORYPAYMENTMETHOD_API}
              keys={{ label: "Value", value: "Id" }}
              onChange={(e: any) => {
                paymentSetValue("PaymentMethodId", e);
                setSelectedPaymentMethod(e);
              }}
              value={selectedPaymentMethod}
              name="PaymentMethodId"
              label="Payment Method"
              errorMessage={paymentErrors?.PaymentMethodId?.message}
              rules={{
                required: {
                  value: true,
                  message: "Payment Method is required",
                },
              }}
            />

            <Input
              isRequired
              containerClass="col-lg-3"
              label="Amount"
              type="text"
              disabled={selectedPaymentMethod==null}
              onChange={(e: any) => {
                paymentSetValue("Amount", Number(e.target.value));
                paymentClearErrors(["Amount"]);
              }}
              register={{
                ...paymentRegister(`Amount`, {
                  required: {
                    value: true,
                    message: "Amount is required",
                  },
                }),
              }}
              errorMessage={paymentErrors?.Amount?.message}
            />

            <Textarea
              containerClass="col-lg-4"
              label="Notes"
              register={{
                ...paymentRegister("Notes"),
              }}
              disable={selectedPaymentMethod==null}
              maxLength={500}
              onChange={(e: any) => {
                paymentSetValue("Notes", e.target.value);
                paymentClearErrors(["Notes"]);
              }}
              errorMessage={paymentErrors?.Notes?.message}
              style={{ height: "75px" }}
            />

            <div className="col-md-2 text-center mt-5">
              <button
                type="submit"
                className="btn btn-outline-success"
                onClick={paymentHandleSubmit(onPaymentSubmit)}
              >
                {paymentEditIndex != null ? "Update" : "Add"}
              </button>
              <button
                type="submit"
                className="btn btn-outline-danger ml-2"
                onClick={() => {
                  paymentReset();
                  setSelectedPaymentMethod(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>

          {/* Payment Grid */}
          {paymentArray?.length > 0 && (
            <>
              <DataTable
                dataKey="id"
                stripedRows
                value={paymentArray}
                showGridlines
                size="small"
              >
                <Column
                  field="PaymentMethodId"
                  header="Payment Method"
                  body={(e: any) => {
                    let temp = e?.PaymentMethodId?.label;
                    return temp;
                  }}
                />
                <Column
                  field="Amount"
                  header="Amount"
                  style={{ width: "300px" }}
                />
                <Column
                  field="Notes"
                  header="Notes"
                  style={{ width: "300px" }}
                />
                <Column
                  header="Action"
                  style={{ width: "300px" }}
                  body={paymentAction}
                />
              </DataTable>
            </>
          )}
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default PaymentInvoice;
