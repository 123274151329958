import { ErrorMessage } from "./ErrorMessage";

interface iPropstextarea {
  maxLength?: number;
  minLength?: number;
  className?: string;
  label?: string;
  isRequired?: boolean;
  register?: object;
  errorMessage?: string;
  containerClass?: string;
  rows?: number;
  onChange?:any;
  disable?:boolean;
  style?:any
}

export default function Textarea({
  maxLength,
  minLength,
  className,
  label,
  isRequired = false,
  register,
  errorMessage,
  containerClass,
  rows,
  onChange,
  disable = false,
  style 
}: iPropstextarea) {
  return (
    <div className={containerClass}>
      <div className="form-group">
        <label className="col-form-label">
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>
        <textarea
          {...register}
          onChange={onChange}
          className={"form-control " + className}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disable}
          rows={rows}
          style={style}
        ></textarea>
        <ErrorMessage message={errorMessage} />
      </div>
    </div>
  );
}
