import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
export const AddEditCompany = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_INVENTORYCOMPANY_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });
  const [selectedCurrency, setSelectedCurrency]: any = useState();
  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const [selectedFssAiExpiryDate, setSelectedFssAiExpiryDate]: any = useState();
  const [selectedDlNo1ExpiryDate, setSelectedDlNo1ExpiryDate]: any = useState();
  const [selectedDlNo2ExpiryDate, setSelectedDlNo2ExpiryDate]: any = useState();
  const [selectedNrxExpiryDate, setSelectedNrxExpiryDate]: any = useState();
  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);
  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);

        setValue("FssAiExpiryDate", data?.FssAiExpiryDate?.split("T")[0]);
        setValue("DlNo1ExpiryDate", data?.DlNo1ExpiryDate?.split("T")[0]);
        setValue("DlNo2ExpiryDate", data?.DlNo2ExpiryDate?.split("T")[0]);
        setValue("NrxExpiryDate", data?.NrxExpiryDate?.split("T")[0]);

        setSelectedCurrency({
          label: data.CurrencyName,
          value: data.CurrencyId,
        });
      }
    }
  }, [id, data]);

  const ComapnyFormData = (data: any) => {
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let submitData = {};
    if (id) {
      submitData = {
        CompanyToUpdate: data,
      };
    } else {
      submitData = {
        CompanyToCreate: data,
      };
    }
    mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Company`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form onSubmit={handleSubmit(ComapnyFormData)}>
            <div className="row">
              <Input
                containerClass="col-lg-3"
                label="Company Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Name", e.target.value);
                  clearErrors(["Name"]);
                }}
                register={{
                  ...register("Name", {
                    required: {
                      value: true,
                      message: "Company Name is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
              />
              <ReactSelect
                containerClass="col-lg-3"
                isRequired
                label="Currency"
                name={"CurrencyId"}
                onChange={(e: any) => {
                  if (e !== null) {
                    setValue("CurrencyId", e?.value);
                    setSelectedCurrency(e);
                  } else {
                    setValue("CurrencyId", null);
                    setSelectedCurrency(null);
                  }
                }}
                value={selectedCurrency}
                control={control}
                api="Inventory/Currency?pageNumber=1&pageSize=10"
                keys={{ label: "Value", value: "Id" }}
                rules={{
                  required: {
                    value: true,
                    message: "Currency Is Required",
                  },
                }}
                errorMessage={errors?.CurrencyId?.message}
              />
              <Input
                containerClass="col-lg-3"
                label="FSSAI"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("FssAi", e.target.value);
                  clearErrors(["FssAi"]);
                }}
                register={{
                  ...register("FssAi", {
                    required: {
                      value: true,
                      message: "FSSAI is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.FssAi?.message ||
                  error?.response?.data?.errors?.FssAi?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="DL No 1"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("DlNo1", e.target.value);
                  clearErrors(["DlNo1"]);
                }}
                register={{
                  ...register("DlNo1", {
                    required: {
                      value: true,
                      message: "DL Number 1 Is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.DlNo1?.message ||
                  error?.response?.data?.errors?.DlNo1?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="DL No 2"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("DlNo2", e.target.value);
                  clearErrors(["DlNo2"]);
                }}
                register={{
                  ...register("DlNo2", {
                    required: {
                      value: true,
                      message: "DL Number 2 Is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.DlNo2?.message ||
                  error?.response?.data?.errors?.DlNo2?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="NRX"
                type="text"
                isRequired
                maxLength={10}
                onChange={(e: any) => {
                  setValue("Nrx", e.target.value);
                  clearErrors(["Nrx"]);
                }}
                register={{
                  ...register("Nrx", {
                    required: {
                      value: true,
                      message: "NRX is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Nrx?.message ||
                  error?.response?.data?.errors?.Nrx?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="FSSAI Expiry Date"
                type="date"
                isRequired
                errorMessage={
                  errors?.FssAiExpiryDate?.message ||
                  error?.response?.data?.errors?.FssAiExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("FssAiExpiryDate", e.target.value);
                  setSelectedFssAiExpiryDate(e.target.value);

                  clearErrors(["FssAiExpiryDate"]);
                }}
                register={{
                  ...register("FssAiExpiryDate", {
                    required: {
                      value: true,
                      message: "FSSAI Expiry Date  is Required",
                    },
                  }),
                }}
                value={selectedFssAiExpiryDate}
              />
              <Input
                containerClass="col-lg-3"
                label="DL No1 Expiry Date"
                type="date"
                isRequired
                errorMessage={
                  errors?.DlNo1ExpiryDate?.message ||
                  error?.response?.data?.errors?.DlNo1ExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DlNo1ExpiryDate", e.target.value);
                  setSelectedDlNo1ExpiryDate(e.target.value);
                  clearErrors(["DlNo1ExpiryDate"]);
                }}
                register={{
                  ...register("DlNo1ExpiryDate", {
                    required: {
                      value: true,
                      message: "DL No1 Expiry Date is Required",
                    },
                  }),
                }}
                value={selectedDlNo1ExpiryDate}
                // max={new Date().toISOString().split("T")[0]}
              />
              <Input
                containerClass="col-lg-3"
                label="DL No2 Expiry Date"
                type="date"
                isRequired
                errorMessage={
                  errors?.DlNo2ExpiryDate?.message ||
                  error?.response?.data?.errors?.DlNo2ExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DlNo2ExpiryDate", e.target.value);
                  setSelectedDlNo2ExpiryDate(e.target.value);
                  clearErrors(["DlNo2ExpiryDate"]);
                }}
                register={{
                  ...register("DlNo2ExpiryDate", {
                    required: {
                      value: true,
                      message: "DL No2 Expiry Date is Required",
                    },
                  }),
                }}
                value={selectedDlNo2ExpiryDate}
                // max={new Date().toISOString().split("T")[0]}
              />
              <Input
                containerClass="col-lg-3"
                label="NRX Expiry Date"
                type="date"
                isRequired
                errorMessage={
                  errors?.NrxExpiryDate?.message ||
                  error?.response?.data?.errors?.NrxExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("NrxExpiryDate", e.target.value);
                  setSelectedNrxExpiryDate(e.target.value);
                  clearErrors(["NrxExpiryDate"]);
                }}
                register={{
                  ...register("NrxExpiryDate", {
                    required: {
                      value: true,
                      message: "NRX Expiry Date is Required",
                    },
                  }),
                }}
                value={selectedNrxExpiryDate}
                // max={new Date().toISOString().split("T")[0]}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setSelectedCurrency(null);
                      setSelectedNrxExpiryDate(null);
                      setSelectedDlNo2ExpiryDate(null);
                      setSelectedDlNo1ExpiryDate(null);
                      setSelectedFssAiExpiryDate(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
