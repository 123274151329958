import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Action } from "../../../../common/Action";
import AddEditEventPartnerType from "./AddEditEventPartnerType";
import DatatableCommon from "../../../../common/DatatableCommon";

const EventPartnerType = () => {
  const apiname = process.env.REACT_APP_EVENTPARTNERTYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [updateId, setupdateId]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => { return e.EventPartnerTypeId })

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionStockistTypeTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "EventPartnerTypeName",
      header: "Event Partner Type Name",
      body: null,
      sortable: true,
      width: "10vw",
      bodyClassName: "text-info h6",
    },

  ];

  const actionStockistTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.EventPartnerTypeId}
          editModal={() => {
            setupdateId(id.EventPartnerTypeId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Event Partner Type</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Event Partner Type"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/employeeattributes"}
          linkflagtitle={"Employee Attributes"}
        />
        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DatatableCommon
                apiname={apiname}
                columnData={columns}
                // check={true}
                refetchData={bool}
                setrefetchData={setbool}
                setselecteditem={setselectedRow}
                selecteditem={selectedRow}
                multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) } }}
              />
              {updateId !== null && (
                <AddEditEventPartnerType
                  setisOPen={() => {
                    return setupdateId(null);
                  }}
                  fetch={(data: boolean) => {
                    if (data) {
                      setbool(true);
                    }
                  }}
                  open={updateId !== null}
                  id={updateId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default EventPartnerType;
