import React, { useState } from 'react'
import { DeleteModal } from '../../../../common/DeleteModal';
import AddEditMediaType from './AddEditMediaType';
import DatatableCommon from '../../../../common/DatatableCommon';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import { Action } from '../../../../common/Action';

const MediaType = () => {
  const apiname = process.env.REACT_APP_MEDIATYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [updateId, setupdateId]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => { return e.MediaTypeId })

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionMediaTypeTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "MediaName",
      header: "Media Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },

  ];

  const actionMediaTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.MediaTypeId}
          editModal={() => {
            setupdateId(id.MediaTypeId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Media Type</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Media Type"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/productattributes"}
          linkflagtitle={"Product Attributes"}
        />
        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DatatableCommon
                apiname={apiname}
                columnData={columns}
                refetchData={bool}
                // check={true}
                setrefetchData={setbool}
                setselecteditem={setselectedRow}
                selecteditem={selectedRow}
                multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) } }}
              />

              {updateId !== null && (
                <AddEditMediaType
                  setisOPen={() => {
                    return setupdateId(null);
                  }}
                  fetch={(data: boolean) => {
                    if (data) {
                      setbool(true);
                    }
                  }}
                  open={updateId !== null}
                  id={updateId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  )
}

export default MediaType
