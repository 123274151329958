import React, { useState } from "react";
import { Link } from "react-router-dom";

function AdminPanelCard({
  name,
  imageSrc,
  imageSrc1,
  link,
  color,
  bgColor,
  imgWidth,
}: any) {
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = {
    display: "flex",
    backgroundColor: isHovered ? "#B0CFFF " : "#c0dbff",
    justifyContent: "space-evenly",
    // height: 150,
    alignItems: "center",
    borderRadius: "10px",
    padding: "10px",
    transition: "ease 0.3s",
    boxShadow: isHovered ? "0px 0px 20px #c0dbff" : "0 0 0px #B0CFFF",
  };

  return (
    <Link
      to={link}
      style={{ textDecoration: "none", color: "inherit" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={cardStyle} className="cardStyle">
        {imageSrc && (
          <img
            className="m-3"
            src={imageSrc}
            alt={name}
            style={{
              width: "13%",
              transition: "ease 0.3s",
              filter: isHovered ? "drop-shadow(0px 0px 10px #8CA4B7)" : "",
            }}
          />
        )}
        <h3
        className="textStyle"
          style={{
            color: color,
            textAlign: "center",
            flex: "1",
            fontWeight:isHovered? 550 : 500,
          }}
        >
          {name}
        </h3>
        {imageSrc1 && (
          <img
            className="m-3"
            src={imageSrc1}
            alt={name}
            style={{
              width: "13%",
              transition: "ease 0.3s",
              filter: isHovered ? "drop-shadow(0px 0px 10px #8CA4B7)" : "",
            }}
          />
        )}
      </div>
    </Link>
  );
}

export default AdminPanelCard;