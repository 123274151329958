import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";
import { useForm } from "react-hook-form";
import axiosInstance from "../../_helper/Interceptor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { NullHandler } from "../../common/NullHandler";
import { Badge } from "primereact/badge";
import { Paginator } from "primereact/paginator";

const DoctorVisitCountReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    getValues,
  }: any = useForm({ mode: "all" });

  const [selectedEmployee, setSelectedEmployee]: any = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(false);

  let role = localStorage.getItem("Roles");

  // date
  const [startdate, setDate] = useState("");

  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay?.toISOString()?.split("T")[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate?.toISOString()?.split("T")[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  const onSubmit = (data: any) => {
    setSubExpandedRows(null);
    setSubExpandedRowstwo(null);
    setSubExpandedRowsthree(null);
    setSubExpandedRowsM(null);
    setSubExpandedRowsT(null);
    setSubExpandedRowsthreeabove(null);

    setLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DASHBOARDGETDOCTORVISITCOUNTBYEMPLOYEE_API
        }?StartDate=${startdate}&EndDate=${defaultDate}${data.UserId !== undefined ? `&UserId=${data.UserId}` : ""
        }`
      )
      .then((e) => {
        if (e.data.Data !== null) {
          let Temp = e.data.Data.map((d: any, index: any) => {
            return {
              ...d,
              SrNo:
                e?.data?.PageNumber * e?.data?.PageSize -
                e?.data?.PageSize +
                (index + 1),
            };
          });
          setLoader(false);
          setData(Temp);
        } else {
          setLoader(false);
          setData([]);
        }
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  function compareKeys(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    return aKeys[0] == b;
  }
  const [subexpandedrows, setSubExpandedRows]: any = useState(null);
  const [subexpandedrowstwo, setSubExpandedRowstwo]: any = useState(null);
  const [subexpandedrowsthree, setSubExpandedRowsthree]: any = useState(null);
  const [subexpandedrowsM, setSubExpandedRowsM]: any = useState(null);
  const [subexpandedrowsT, setSubExpandedRowsT]: any = useState(null);
  const [subexpandedrowsthreeabove, setSubExpandedRowsthreeabove]: any =
    useState(null);

  let [expandedDataOne, setExpandedDataOne]: any = useState([]);

  const [active, setActive]: any = useState("");

  const onSubmitExpandedOne = (id: number, type: any) => {
    const start = getValues("StartDate");
    const enddate = getValues("EndDate");
    setDisplayedData([]);
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GETDASHBOARDVISITDETAILBYEMPLOYEE_API}?StartDate=${start}&EndDate=${enddate}&EmployeeId=${id}&Type=${type}`
      )
      .then((e) => {
        setFirst(0);
        setRows(10);
        let Temp = e.data.Data.map((d: any, i: any) => {
          return { ...d, SrNo: i + 1 };
        });
        setExpandedDataOne(Temp);
        if (e.data.Data == null) {
          setDisplayedData([]);
        } else {
          let Temp = e.data.Data.slice(0, 10).map((d: any, index: any) => {
            return {
              ...d,
              SrNo: index + 1,
            };
          });
          setDisplayedData(Temp);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  let [first, setFirst] = useState(0);
  let [rows, setRows] = useState(10);
  let [displayedData, setDisplayedData] = useState([]);

  let onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    // let Temp = expandedDataOne.slice(event.first, event.first + event.rows).map((d:any,index:any)=>{
    //       return{
    //         ...d,
    //         srNo: ((res?.data?.PageNumber * res?.data?.PageSize) - res?.data?.PageSize) + (index + 1)
    //       }
    // })
    setDisplayedData(
      expandedDataOne.slice(event.first, event.first + event.rows)
    );
  };

  const VisitCountExpandableRows = (rowData: any) => {
    return (
      <>
        <div className="pd-20 card-box mb-30">
          <DataTable
            value={displayedData}
            stripedRows
            rowHover
            rowsPerPageOptions={[10, 20, 50, 100]}
            size="normal"
            emptyMessage="No data found."
            style={{ cursor: "text" }}
            loading={loading}
          >
            <Column
              field="SrNo"
              header="#"
              headerStyle={{ backgroundColor: "lightgray" }}
              style={{ width: "5%" }}
            />
            <Column
              field="doctorName"
              header="Doctor Name"
              headerStyle={{ backgroundColor: "lightgray" }}
              style={{ padding: "5px", width: "20%" }}
              body={(e: any) => {
                return NullHandler(e.doctorName);
              }}
            />
            <Column
              field="cityName"
              header="City Name"
              headerStyle={{ backgroundColor: "lightgray" }}
              style={{ padding: "5px", width: "20%" }}
              body={(e: any) => {
                if (typeof e.cityName == "object") {
                  return " - ";
                } else {
                  return NullHandler(e.cityName);
                }
              }}
            />
            <Column
              field="categoryName"
              align={"center"}
              header="Category"
              headerStyle={{ backgroundColor: "lightgray" }}
              style={{ padding: "5px", width: "20%" }}
              body={(e: any) => {
                return NullHandler(
                  <span className="mr-2">
                    <Badge severity={"danger"} value={e.categoryName}></Badge>
                  </span>
                );
              }}
            />
            <Column
              field="Speciality1"
              header="Speciality"
              headerStyle={{ backgroundColor: "lightgray" }}
              style={{ padding: "5px", width: "20%" }}
              body={(e: any) => {
                return NullHandler(
                  <span className="mr-2">
                    <Badge severity={"success"} value={e.Speciality1}></Badge>
                  </span>
                );
              }}
            />
            {
              active !== "T" &&
              <Column
                field="VisitTime"
                // header="Visit Time"
                header={active == "M" ? "Last Visit Time" : "Visit Time"}
                headerStyle={{ backgroundColor: "lightgray" }}
                style={{ padding: "5px", width: "20%" }}
                body={(e: any) => {
                  debugger;
                  console.log(e?.VisitTime, "-->");
                  let temp =
                    typeof e?.VisitTime === "string"
                      ? e?.VisitTime?.split(",")
                      : "";
                  console.log(temp, "temp");
                  console.log(typeof temp, "temp type");
                  if (typeof temp === "string") {
                    return "-";
                  } else {
                    return temp?.length > 1 ? (
                      <>
                        {temp?.map((d: any) => {
                          return d !== "" && d !== null ? (
                            <p>{moment(d).format("DD MMM, YYYY hh:mm:ss A")}</p>
                          ) : (
                            ""
                          );
                        })}
                      </>
                    ) : e?.VisitTime !== "" && e?.VisitTime !== null ? (
                      <p>
                        {moment(e?.VisitTime).format("DD MMM, YYYY hh:mm:ss A")}
                      </p>
                    ) : (
                      "-"
                    );
                  }
                }}
              />
            }

          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={expandedDataOne?.length}
            onPageChange={onPageChange}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Doctor Visit Count Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Doctor Visit Count Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Doctor Visit Count Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((info: any) => {
            onSubmit(info);
          })}
        >
          <div className="row">
            <Input
              containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
              label="Start Date"
              type="date"
              isRequired
              errorMessage={errors?.StartDate?.message}
              register={{
                ...register("StartDate", {
                  required: {
                    value: true,
                    message: "Start Date is required",
                  },
                }),
              }}
              defaultValue={
                new Date(new Date().getFullYear(), new Date().getMonth(), 2)
                  ?.toISOString()
                  ?.split("T")[0]
              }
              onChange={(e: any) => {
                clearErrors(["StartDate"]);
                handleDateChange(e);
              }}
            />

            <Input
              containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
              label="End Date"
              type="date"
              isRequired
              errorMessage={errors?.EndDate?.message}
              register={{
                ...register("EndDate", {
                  required: {
                    value: true,
                    message: "End Date is required",
                  },
                }),
              }}
              defaultValue={
                new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
                  ?.toISOString()
                  ?.split("T")[0]
              }
              onChange={(e: any) => {
                clearErrors(["EndDate"]);
                handleEndDateChange(e);
              }}
            />

            {role !== "MR" && (
              <ReactSelect
                containerClass="col-lg-4"
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                onChange={(e: any) => {
                  setValue("UserId", e?.value);
                  setSelectedEmployee(e);
                }}
                value={selectedEmployee}
                name="UserId"
                label="User"
              />
            )}

            <div className="col-md-12 text-center">
              {!loader ? (
                <button type="submit" className="btn btn-outline-primary">
                  Submit
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                  disabled
                >
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      {data?.length > 0 ? (
        <div className="pd-20 card-box mb-30">
          <DataTable
            value={data}
            stripedRows
            key={"employeeId"}
            dataKey="employeeId"
            rowHover
            expandedRows={
              active === "1"
                ? subexpandedrows
                : active === "2"
                  ? subexpandedrowstwo
                  : active === "3"
                    ? subexpandedrowsthree
                    : active === "3>"
                      ? subexpandedrowsthreeabove
                      : active === "T"
                        ? subexpandedrowsT
                        : active === "M"
                          ? subexpandedrowsM
                          : ""
            }
            rowExpansionTemplate={VisitCountExpandableRows}
            size="normal"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            style={{ cursor: "pointer" }}
            tableStyle={{ minWidth: "50rem" }}
            emptyMessage="No data found."
          >
            <Column
              field="SrNo"
              header="#"
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              style={{ width: "5%" }}
            />
            <Column
              header="Employee Name"
              bodyStyle={{ fontSize: 14 }}
              field="name"
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              body={(e: any) => {
                return NullHandler(e.name);
              }}
              style={{ padding: "8px" }}
            />
            <Column
              align={"center"}
              header="T"
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              field="T"
              style={{ padding: "8px" }}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={
                      e?.employeeId ==
                        (subexpandedrowsT !== null
                          ? Object?.keys(subexpandedrowsT)[0]
                          : "")
                        ? "warning"
                        : "info"
                    }
                    value={e["T"]}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        subexpandedrowsT != null &&
                        compareKeys(subexpandedrowsT, e.employeeId)
                      ) {
                        obj = {};
                      } else {
                        obj[e.employeeId] = true;
                      }
                      setSubExpandedRowsT(obj);
                      onSubmitExpandedOne(e.employeeId, "5");
                      setActive("T");
                      setSubExpandedRows({});
                      setSubExpandedRowsM({});
                      setSubExpandedRowsthree({});
                      setSubExpandedRowsthreeabove({});
                      setSubExpandedRowstwo({});
                    }}
                  ></Badge>
                );
              }}
            />
            <Column
              align={"center"}
              header="M"
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              field="M"
              style={{ padding: "8px" }}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={
                      e?.employeeId ==
                        (subexpandedrowsM !== null
                          ? Object?.keys(subexpandedrowsM)[0]
                          : "")
                        ? "warning"
                        : "info"
                    }
                    value={e["M"]}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        subexpandedrowsM != null &&
                        compareKeys(subexpandedrowsM, e.employeeId)
                      ) {
                        obj = {};
                      } else {
                        obj[e.employeeId] = true;
                      }
                      setSubExpandedRowsM(obj);
                      onSubmitExpandedOne(e.employeeId, "6");
                      setActive("M");
                      setSubExpandedRows({});
                      setSubExpandedRowsT({});
                      setSubExpandedRowsthree({});
                      setSubExpandedRowsthreeabove({});
                      setSubExpandedRowstwo({});
                    }}
                  ></Badge>
                );
              }}
            />
            <Column
              align={"center"}
              header="1"
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              field="1"
              style={{ padding: "8px" }}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={
                      e?.employeeId ==
                        (subexpandedrows !== null
                          ? Object?.keys(subexpandedrows)[0]
                          : "")
                        ? "warning"
                        : "info"
                    }
                    value={e["1"]}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        subexpandedrows != null &&
                        compareKeys(subexpandedrows, e.employeeId)
                      ) {
                        obj = {};
                      } else {
                        obj[e.employeeId] = true;
                      }
                      setSubExpandedRows(obj);
                      onSubmitExpandedOne(e.employeeId, "1");
                      setActive("1");
                      setSubExpandedRowsM({});
                      setSubExpandedRowsT({});
                      setSubExpandedRowsthree({});
                      setSubExpandedRowsthreeabove({});
                      setSubExpandedRowstwo({});
                    }}
                  ></Badge>
                );
              }}
            />
            <Column
              header="2"
              bodyStyle={{ fontSize: 14 }}
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              field="2"
              style={{ padding: "8px" }}
              align={"center"}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={
                      e?.employeeId ==
                        (subexpandedrowstwo !== null
                          ? Object?.keys(subexpandedrowstwo)[0]
                          : "")
                        ? "warning"
                        : "info"
                    }
                    value={e["2"]}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        subexpandedrowstwo != null &&
                        compareKeys(subexpandedrowstwo, e.employeeId)
                      ) {
                        obj = {};
                      } else {
                        obj[e.employeeId] = true;
                      }
                      setSubExpandedRowstwo(obj);
                      onSubmitExpandedOne(e.employeeId, "2");
                      setActive("2");
                      setSubExpandedRows({});
                      setSubExpandedRowsT({});
                      setSubExpandedRowsthree({});
                      setSubExpandedRowsthreeabove({});
                      setSubExpandedRowsM({});
                    }}
                  ></Badge>
                );
              }}
            />
            <Column
              header="3"
              bodyStyle={{ fontSize: 14 }}
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              field="3"
              style={{ padding: "8px" }}
              align={"center"}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={
                      e?.employeeId ==
                        (subexpandedrowsthree !== null
                          ? Object?.keys(subexpandedrowsthree)[0]
                          : "")
                        ? "warning"
                        : "info"
                    }
                    value={e["3"]}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        subexpandedrowsthree != null &&
                        compareKeys(subexpandedrowsthree, e.employeeId)
                      ) {
                        obj = {};
                      } else {
                        obj[e.employeeId] = true;
                      }
                      setSubExpandedRowsthree(obj);
                      onSubmitExpandedOne(e.employeeId, "3");
                      setActive("3");
                      setSubExpandedRows({});
                      setSubExpandedRowsT({});
                      setSubExpandedRowsM({});
                      setSubExpandedRowsthreeabove({});
                      setSubExpandedRowstwo({});
                    }}
                  ></Badge>
                );
              }}
            />
            <Column
              header="3+"
              bodyStyle={{ fontSize: 14 }}
              headerStyle={{
                fontSize: 16,
                backgroundColor: "lightgray",
              }}
              align={"center"}
              field="3>"
              style={{ padding: "8px" }}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={
                      e?.employeeId ==
                        (subexpandedrowsthreeabove !== null
                          ? Object?.keys(subexpandedrowsthreeabove)[0]
                          : "")
                        ? "warning"
                        : "info"
                    }
                    value={e["3>"]}
                    onClick={() => {
                      let obj: any = {};
                      if (
                        subexpandedrowsthreeabove != null &&
                        compareKeys(subexpandedrowsthreeabove, e.employeeId)
                      ) {
                        obj = {};
                      } else {
                        obj[e.employeeId] = true;
                      }
                      setSubExpandedRowsthreeabove(obj);
                      onSubmitExpandedOne(e.employeeId, "4");
                      setActive("3>");
                      setSubExpandedRows({});
                      setSubExpandedRowsT({});
                      setSubExpandedRowsthree({});
                      setSubExpandedRowsM({});
                      setSubExpandedRowstwo({});
                    }}
                  ></Badge>
                );
              }}
            />
          </DataTable>
        </div>
      ) : (
        data !== null && (
          <div className="col-md-12 col-sm-12 mb-30">
            <div className="pd-20 card-box text-center">
              <h3 className="text-secondary">No data found.</h3>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default DoctorVisitCountReport;
