import AdminPanelCard from "../common/AdminPanelCard";
import DoctorImg from "../assets/images/Vector (1).png";
import ChemistImg from "../assets/images/Group 5252.png";
import EmployeeImg from "../assets/images/Group.png";
import StockistImg from "../assets/images/Group (1).png";
import ProductImg from "../assets/images/product-svgrepo-com.png";
import deviceImg from "../assets/images/device-workstation-svgrepo-com.png";
import { Helmet } from "react-helmet";
export const Mapping = () => {
  const role = localStorage.getItem("Roles");

  let admincard = [
    {
      name: "Doctor Chemist",
      link: "/layout/master/mastermapping/doctorchemistmap",
      img: DoctorImg,
      img1: ChemistImg,
    },
    {
      name: "Doctor Product",
      link: "/layout/master/mastermapping/doctorproductmap",
      img: DoctorImg,
      img1: ProductImg,
    },
  ];

  if (role !== "MR") {
    admincard.push(
      {
        name: "Employee Chemist",
        link: "/layout/master/mastermapping/employeechemistmap",
        img: EmployeeImg,
        img1: ChemistImg,
      },
      {
        name: "Employee Stockist",
        link: "/layout/master/mastermapping/stockistmap",
        img: EmployeeImg,
        img1: StockistImg,
      },
      {
        name: "Employee Device",
        link: "/layout/master/mastermapping/employeedevicemap",
        img: EmployeeImg,
        img1: deviceImg,
      }
    );
  }

  return (
    <>
      <Helmet>
        <title>Mapping</title>
      </Helmet>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard
              name={card?.name}
              link={card?.link}
              imageSrc={card?.img}
              imageSrc1={card?.img1}
            />
          </div>
        ))}
      </div>
    </>
  );
};
