import React, { useEffect } from "react";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export default function AddEditChemistCategoryModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_CHEMISTCATEGORY_API?.toString();

  const { data, isLoading: chemistcategoryloader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    clearErrors,
    setValue
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const chemistCategoryFormData = (data: any) => {
    data.ChemistCategoryOrder = Number(data.ChemistCategoryOrder);

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  // Success & Error Messages
  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Chemist Category`}
        modal
        style={{ width: "1100px" }}
        // footer={
        //   <div className="text-center">
        //     <Button
        //       label="Back"
        //       severity="secondary"
        //       onClick={() => setisOPen(false)}
        //       outlined
        //     />
        //     {id === 0 ? (
        //       <Button
        //         label="Reset"
        //         severity="danger"
        //         onClick={() => {
        //           reset();
        //         }}
        //         outlined
        //       />
        //     ) : (
        //       ""
        //     )}

        //     {isLoading ? (
        //       <Button
        //         onClick={handleSubmit(chemistCategoryFormData)}
        //         outlined
        //         disabled
        //       >
        //         <span className="p-button-label p-c mr-1">Submit</span>{" "}
        //         <Spinner width={"1rem"} height={"1rem"} />
        //       </Button>
        //     ) : (
        //       <Button onClick={handleSubmit(chemistCategoryFormData)} outlined>
        //         <span className="p-button-label p-c">Submit</span>
        //       </Button>
        //     )}
        //   </div>
        // }
      >
        {!chemistcategoryloader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(chemistCategoryFormData);
              }
            }}
            onSubmit={handleSubmit(chemistCategoryFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Chemist Category Name"
                type="text"
                isRequired
                register={{
                  ...register("ChemistCategoryName", {
                    required: {
                      value: true,
                      message: "Chemist Category Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s'-]{0,50}$/,
                      message:
                        "Enter a valid chemist category name (1-50 alphabet characters only)",
                    },
                  }),
                }}
                onChange={(e:any) => {setValue("ChemistCategoryName", e.target.value); clearErrors(["ChemistCategoryName"])}}
                errorMessage={
                  errors?.ChemistCategoryName?.message ||
                  error?.response?.data?.errors?.ChemistCategoryName?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-6"
                label="Chemist Category Order"
                isRequired
                type="text"
                register={{
                  ...register("ChemistCategoryOrder", {
                    required: {
                      value: true,
                      message: "Chemist Category Order is required",
                    },
                    pattern: {
                      value: /^\d{2,5}$/,
                      message: "Enter a valid categoryorder (2-5 numbers only)",
                    },
                  }),
                }}
                onChange={(e:any) => {setValue("ChemistCategoryOrder", e.target.value); clearErrors(["ChemistCategoryOrder"])}}
                errorMessage={
                  errors?.ChemistCategoryOrder?.message ||
                  error?.response?.data?.errors?.ChemistCategoryOrder?.map(
                    (e: any) => e
                  )
                }
              />

<div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
