import React, { useEffect } from "react";
import { useCreateByID } from "../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Textarea from "./Textarea";
import { toast } from "react-toastify";

interface iPropsmoadl {
  id?: any;
  deleteMessage?: any;
  adminapiname?: string;
  setisOPen?: any;
  open?: any;
  jsonData?: any;
}

export const DailyPlanRejectModal = ({
  id,
  adminapiname,
  setisOPen,
  open,
  jsonData,
}: iPropsmoadl) => {
  const { mutate, isSuccess, isError, error }: any = useCreateByID(adminapiname, id);

  const rejectData = (data: any) => {
    jsonData.DailyTourPlanId = Number(id);
    jsonData.Remark = data.Remark;
    mutate(jsonData);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      toast.success("Reject Successfull", { position: "top-right" });
      window.location.reload();
    }
  }, [isSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  }: any = useForm({ mode: "all" });

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-body">
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              rejectData(productinfo);
            })}
          >
            <Textarea
              containerClass="col-lg-12"
              label="Remarks"
              rows={2}
              isRequired
              maxLength={50}
              errorMessage={errors?.Remark?.message}
              register={{
                ...register("Remark", {
                  required: {
                    value: true,
                    message: "Remarks is required",
                  },
                  pattern: {
                    value: /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                    message:
                      "Enter Valid Remarks and Remove whitespace from both sides of a string",
                  },
                }),
              }}
            />
            <div className="col-lg-12 text-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
