import React, { useEffect, useState, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { Spinner } from "../../../common/Spinner";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

interface FileObject {
  id: number;
  fileName: string;
  filePath: string;
  file: File;
}

const DraftDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading: emailLoading } = useFetchAllID("Reporting/Email", id);
  useEffect(() => {
    if (data) {
      reset(data);
      console.log(data);
      const EmailToViewModelsSelected = data.EmailToViewModels.map((e: any) => ({
        label: e.ToUserName,
        value: e.ToUserId,
      }));
      setValue("ToUserId", EmailToViewModelsSelected);
      setselectedemployee(EmailToViewModelsSelected);
      setContent(data.Message);
      data.EmailAttchmentViewModels = data?.EmailAttchmentViewModels.map(
        (e: any) => ({
          id: e?.Id,
          file: e?.FileName,
        })
      );
      setFiles(data?.EmailAttchmentViewModels);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });
  const [selectedemployee, setselectedemployee]: any = useState([]);
  const [EmailType, setEmailType] = useState("");
  const [content, setContent] = useState();

  const handleChange = (e: any) => {
    setContent(e);
  };

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    "Reporting/Email",
    id
  );

  const [files, setFiles]: any = useState([]);
  useEffect(() => {
    console.log("Updated Files = ", files)
  }, [files])


  const onDrop = useCallback((acceptedFiles: any) => {
    const temp = acceptedFiles.map((e: any) => ({
      id: 0,
      file: e
    }))

    var temp_final = [...temp, ...files]

    setFiles(temp_final);
  }, [files]);

  const removeFile = (fileToRemove: any, e: any) => {
    e.preventDefault();
    const updatedFiles = files.filter((file: any) => file !== fileToRemove);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSubmit = (data: any, type: string) => {

    delete data.EmailAttchmentViewModels;
    delete data.EmailToViewModels;
    delete data.FromUserName;
    delete data.FromUserId;
    delete data.IsActive;
    data.Files = files;
    data.Message = content;
    data.EmailType = type;
    console.log(data, "Email Data");
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        if (key === "Files") {
          data?.Files?.map((e: any, index: any) => {
            debugger
            if (typeof e.file === "object") {
              formData.append(`Files[${index}].id`, e.id);
              formData.append(`Files[${index}].File`, e.file);
            } else {
              formData.append(`Files[${index}].id`, e.id);
            }
          });
        } else if (key === "ToUserId") {
          data.ToUserId.map((e: any, index: any) => {
            formData.append("ToUserId[" + index + "]", e?.value);
          });
        }
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Email Send Successfully", { position: "top-right" });
      navigate("/layout/email");
    }
  }, [isSuccess, isError]);


  return (
    <div className="pd-20 card-box mb-30">
      <h5 className="mb-3">New Message</h5>
      <form>
        <div className="row">
          <ReactSelect
            containerClass="col-lg-12"
            label="To"
            isRequired
            name={"ToUserId"}
            onChange={(e: any) => {
              setValue("ToUserId", e);
              setselectedemployee(e);
            }}
            value={selectedemployee}
            control={control}
            api={process.env.REACT_APP_EMPLOYEE_API}
            // api="Employee"
            keys={{ label: "Value", value: "Id" }}
            errorMessage={
              errors?.ToUserId?.message
              // error?.response?.data?.errors?.To?.map((e: any) => e)
            }
            rules={{
              required: { value: true, message: "To is required" },
            }}
            Multi
          />

          <Input
            containerClass="col-lg-12"
            label="Subject"
            type="text"
            isRequired
            errorMessage={
              errors?.Subject?.message
              // error?.response?.data?.errors?.Title?.map((e: any) => e)
            }
            onChange={() => clearErrors(["Subject"])}
            register={{
              ...register("Subject", {
                required: {
                  value: true,
                  message: "Subject is required",
                },
              }),
            }}
          />

          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="col-form-label">Message</label>
              <ReactQuill
                value={content}
                style={{ height: "100px" }}
                onChange={handleChange}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image", "video"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "link",
                  "video",
                ]}
              />
            </div>
          </div>

          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
            style={{
              border: "2px dashed #cccccc",
              borderRadius: "4px",
              margin: "20px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              width: "100%",
              backgroundColor: isDragActive ? "#eee" : "white",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          {files?.length !== 0 && (
            <div className="col-lg-12">
              <h4>Dropped Files:</h4>
              <ul style={{ width: "100%" }}>
                {files?.map((file: any, index: any) => {
                  console.log(file, "files")
                  return (
                    <li
                      key={index}
                      style={{
                        width: "100%",
                        margin: "5px 0",
                        padding: "10px",
                        border: "1px solid #cccccc",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {typeof file.file === "object" ? file?.file?.name : file.file}
                      <button
                        style={{
                          marginLeft: "10px",
                          padding: "5px 10px",
                          backgroundColor: "#ff0000",
                          color: "#ffffff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={(e: any) => removeFile(file, e)}
                      >
                        Remove
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/email"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>
            {!isLoading ? (
              <button
                type="submit"
                onClick={handleSubmit((data: any) => {
                  setEmailType("Send");
                  onSubmit(data, "Send");
                })}
                className="btn btn-outline-primary mt-2 mx-2"
              >
                Send
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleSubmit((data: any) => {
                  setEmailType("Send");
                  onSubmit(data, "Send");
                })}
                disabled
                className="btn btn-outline-primary mt-2 mx-2"
              >
                Send <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DraftDetail;
