import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { AddEditConsultingFeeTypeModal } from "../../../master/submaster/consultingFeeType/AddEditConsultingFeeTypeModal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export default function AddEditConsultingFeesClinicModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "ConsultingFeesType") {
      setselectedfeestype(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname =
    process.env.REACT_APP_DOCTORCONSULTINFFEESFORPATIENT_API?.toString();

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );
  useEffect(() => {
    if (data) {
      if (id) {
        const StartDate = data?.ConsultingFeesForPatienStartDate?.split("T")[0];
        const EndDate = data?.ConsultingFeesForPatienEndDate?.split("T")[0];
        reset(data);
        setValue("ConsultingFeesForPatienStartDate", StartDate);
        setValue("ConsultingFeesForPatienEndDate", EndDate);

        if (
          data.ConsultingFeesForPatientTypeId !== null &&
          data.ConsultingFeesForPatientTypeId !== undefined
        ) {
          setValue("ConsultingFeesForPatientTypeId", {
            label: data.ConsultingFeesForPatientType,
            value: data.ConsultingFeesForPatientTypeId,
          });
          setselectedfeestype({
            label: data.ConsultingFeesForPatientType,
            value: data.ConsultingFeesForPatientTypeId,
          });
        } else {
          setValue("ConsultingFeesForPatientTypeId", null);
          setselectedfeestype(null);
        }
      }
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedfeestype, setselectedfeestype]: any = useState(null);

  const [feesclinic, setfeesclinic]: any = useState(null);
  const [defaultValue, setdefaultValue]: any = useState(null);
  const handlefeesclinicChange = (inputvalue: any) => {
    setfeesclinic(0);
    setdefaultValue(inputvalue);
  };

  const consultingfeesData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.ConsultingFeesForPatienCurrentAmount = Number(
      data.ConsultingFeesForPatienCurrentAmount
    );
    data.ConsultingFeesForPatienAmount = Number(
      data.ConsultingFeesForPatienAmount
    );

    data.ConsultingFeesForPatientTypeId = Number(selectedfeestype?.value);

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Consulting Fees (Clinic)`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(consultingfeesData);
              }
            }}
            onSubmit={handleSubmit(consultingfeesData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Type"
                control={control}
                name="ConsultingFeesForPatientTypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ConsultingFeesForPatientTypeId", e?.value);
                    setselectedfeestype(e);
                  } else {
                    setValue("ConsultingFeesForPatientTypeId", null);
                    setselectedfeestype(null);
                  }
                }}
                value={selectedfeestype}
                // options={ConsultingFeesTypeOptions}
                api={process.env.REACT_APP_CONSULTINGFEESTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.ConsultingFeesForPatientTypeId?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForPatientTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Consulting Fees Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Amount"
                type="text"
                maxLength={7}
                errorMessage={
                  errors?.ConsultingFeesForPatienAmount?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForPatienAmount?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConsultingFeesForPatienAmount", e.target.value);
                  clearErrors(["ConsultingFeesForPatienAmount"]);
                }}
                register={{
                  ...register("ConsultingFeesForPatienAmount"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Start Date"
                type="date"
                errorMessage={
                  errors?.ConsultingFeesForPatienStartDate?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForPatienStartDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConsultingFeesForPatienStartDate", e.target.value);
                  clearErrors(["ConsultingFeesForPatienStartDate"]);
                }}
                register={{
                  ...register("ConsultingFeesForPatienStartDate"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="End Date"
                type="date"
                errorMessage={
                  errors?.ConsultingFeesForPatienEndDate?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForPatienEndDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConsultingFeesForPatienEndDate", e.target.value);
                  clearErrors(["ConsultingFeesForPatienEndDate"]);
                }}
                register={{
                  ...register("ConsultingFeesForPatienEndDate"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Duration"
                type="text"
                maxLength={15}
                errorMessage={
                  errors?.ConsultingFeesForPatienDuration?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForPatienDuration?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConsultingFeesForPatienDuration", e.target.value);
                  clearErrors(["ConsultingFeesForPatienDuration"]);
                }}
                register={{
                  ...register("ConsultingFeesForPatienDuration"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Current Amount"
                type="text"
                maxLength={7}
                errorMessage={
                  errors?.ConsultingFeesForPatienCurrentAmount?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForPatienCurrentAmount?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue(
                    "ConsultingFeesForPatienCurrentAmount",
                    e.target.value
                  );
                  clearErrors(["ConsultingFeesForPatienCurrentAmount"]);
                }}
                register={{
                  ...register("ConsultingFeesForPatienCurrentAmount"),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedfeestype(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}

        {feesclinic !== null && (
          <AddEditConsultingFeeTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setfeesclinic(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue(
                  "ConsultingFeesForPatientTypeId",
                  res.ConsultingFeesTypeId
                );
                setselectedfeestype({
                  label: res.ConsultingFeesTypeName,
                  value: res.ConsultingFeesTypeId,
                });
                // fetchAOITOptions();
              }
            }}
            open={feesclinic !== null}
            id={feesclinic}
          />
        )}
      </Dialog>
    </>
  );
}
