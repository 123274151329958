import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { Input } from "../../../../common/Input";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import Textarea from "../../../../common/Textarea";
import { Spinner } from "../../../../common/Spinner";
import { AddEditRelationTypeModal } from "../../../master/submaster/relationType/AddEditRelationTypeModal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import moment from "moment";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}
export const AddEditFamilyFriendsDetailsModal = ({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) => {
  useEffect(() => {
    if (refetchData == true && api == "Relation") {
      // fetchRelationOptions();
      setselectedrelation(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname =
    process.env.REACT_APP_DOCTORFAMILYANDFRIENDDETAIL_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        const DOB = data?.FamilyAndFriendDateOfBirth?.split("T")[0];

        const DOA = data?.FamilyAndFriendDateOfAnniversary?.split("T")[0];
        const SpeicialDate = data?.FamilyAndFriendSpeicialDate?.split("T")[0];
        reset(data);
        setValue("FamilyAndFriendDateOfBirth", DOB);
        setValue("FamilyAndFriendDateOfAnniversary", DOA);
        setValue("FamilyAndFriendSpeicialDate", SpeicialDate);
        if (data.FamilyAndFriendRelationId !== null) {
          setValue("FamilyAndFriendRelationId", data.FamilyAndFriendRelationId);
          setselectedrelation({
            label: data.FamilyAndFriendRelationName,
            value: data.FamilyAndFriendRelationId,
          });
        } else {
          setValue("FamilyAndFriendRelationId", null);
          setselectedrelation(null);
        }
      }

    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedrelation, setselectedrelation]: any = useState(null);
  const [relation, setrelation]: any = useState(null);
  const [defaultValue, setdefaultValue] = useState(null);
  const handlerelationChange = (inputvalue: any) => {
    setrelation(0);
    setdefaultValue(inputvalue);
  };

  const familyData = (data: any) => {
    data.Date = moment(data.FamilyAndFriendSpeicialDate).format(
      "YYYY-MM-DDTHH:mm:ss"
    );

    data.FamilyAndFriendRelationId = Number(data.FamilyAndFriendRelationId);

    for (let prop in data) {
      if (data[prop] === null || data[prop] === undefined || data[prop] === "" || data[prop]?.length == 0) {
        delete data[prop]
      }
    }

    data.DoctorId = Number(doctorid);
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Family / Friends Details`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(familyData);
              }
            }}
            onSubmit={handleSubmit(familyData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.FamilyAndFriendName?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("FamilyAndFriendName", e.target.value); clearErrors(["FamilyAndFriendName"]) }}

                register={{
                  ...register("FamilyAndFriendName", {
                    required: { value: true, message: "Name is required" },
                    pattern: {
                      value: /^\w[a-zA-Z\s',-]{1,50}$/,
                      message:
                        "Enter a valid family and friends name (1-50 alphabet characters (a-zA-Z',-))",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Relation"
                control={control}
                isRequired
                name="FamilyAndFriendRelationId"
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("FamilyAndFriendRelationId", e?.value);
                    setselectedrelation(e);
                  } else {
                    setValue("FamilyAndFriendRelationId", null);
                    setselectedrelation(null);
                  }
                }}
                value={selectedrelation}
                api={process.env.REACT_APP_RELATION_API}
                keys={{ label: "Value", value: "Id" }}
                rules={{
                  required: { value: true, message: "Relation is required." },
                }}
                errorMessage={
                  errors?.FamilyAndFriendRelationId?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendRelationId?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-4"
                label="DOB"
                type="date"
                errorMessage={
                  errors?.FamilyAndFriendDateOfBirth?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendDateOfBirth?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("FamilyAndFriendDateOfBirth", e.target.value); clearErrors(["FamilyAndFriendDateOfBirth"]) }}

                register={{
                  ...register("FamilyAndFriendDateOfBirth")
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="DOA"
                type="date"
                errorMessage={
                  errors?.FamilyAndFriendDateOfAnniversary?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendDateOfAnniversary?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("FamilyAndFriendDateOfAnniversary", e.target.value); clearErrors(["FamilyAndFriendDateOfAnniversary"]) }}

                register={{
                  ...register("FamilyAndFriendDateOfAnniversary")
                }}
              />

              {/* <Input
                containerClass="col-lg-4"
                label="DOA"
                type="datetime-local"
                // isRequired
                errorMessage={
                  errors?.FamilyAndFriendDateOfAnniversary?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendDateOfAnniversary?.map(
                    (e: any) => e
                  )
                }
                onChange={() =>
                  clearErrors(["FamilyAndFriendDateOfAnniversary"])
                }
                register={{
                  ...register("FamilyAndFriendDateOfAnniversary", {
                    // required: { value: true, message: "Date of Anniversary is required." },
                  }),
                }}
              /> */}

              <Input
                containerClass="col-lg-4"
                label="Special Day"
                type="text"
                maxLength={30}
                errorMessage={
                  errors?.FamilyAndFriendSpeicialDay?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendSpeicialDay?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("FamilyAndFriendSpeicialDay", e.target.value); clearErrors(["FamilyAndFriendSpeicialDay"]) }}

                register={{
                  ...register("FamilyAndFriendSpeicialDay"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Special Date"
                type="date"
                errorMessage={
                  errors?.FamilyAndFriendSpeicialDate?.message ||
                  error?.response?.data?.errors?.FamilyAndFriendSpeicialDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("FamilyAndFriendSpeicialDate", e.target.value); clearErrors(["FamilyAndFriendSpeicialDate"]) }}

                register={{
                  ...register("FamilyAndFriendSpeicialDate")
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={500}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => { setValue("Remarks", e.target.value); clearErrors(["Remarks"]) }}

                register={{
                  ...register("Remarks")
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedrelation(null); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}

        {relation !== null && (
          <AddEditRelationTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setrelation(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue("FamilyAndFriendRelationId", res.RelationId);
                setselectedrelation({
                  label: res.RelationName,
                  value: res.RelationId,
                });
                // fetchRelationOptions();
              }
            }}
            open={relation !== null}
            id={relation}
          />
        )}
      </Dialog>
    </>
  );
};
