import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Input } from "../../../../common/Input";
import ReactSelect from "../../../../common/ReactSelect";
import Textarea from "../../../../common/Textarea";
import { Spinner } from "../../../../common/Spinner";
import { AddEditEntertainmentTypeModal } from "../../../master/submaster/entertainmentType/AddEditEntertainmentTypeModal";
import { AddEditEntertainmentActivityTypeModal } from "../../../master/submaster/entertainmentActivityType/AddEditEntertainmentActivityTypeModal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import moment from "moment";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export const AddEditWeekendVacationActivityModal = ({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) => {
  useEffect(() => {
    if (refetchData === true && api === "EntertainmentType") {
      // fetchEntertainmentTypeOptions();
      setselectedentertainmenttype(null);
      resetstate(false);
    } else if (refetchData === true && api === "EntertainmentActivityType") {
      // fetchEntertainmentActivityTypeOptions();
      setselectedactivity(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname =
    process.env.REACT_APP_DOCTORWEEKENDANDVACATIONACTIVITY_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        const SpeicialDate =
          data?.WeekendAndVacationActivityPreferredDateTime?.split("T")[0];
        reset(data);
        setValue("WeekendAndVacationActivityPreferredDateTime", SpeicialDate);

        if (data.WeekendAndVacationActivityTypeId !== null) {
          setValue("WeekendAndVacationActivityTypeId", {
            label: data.WeekendAndVacationActivityType,
            value: data.WeekendAndVacationActivityTypeId,
          });
          setselectedactivity({
            label: data.WeekendAndVacationActivityType,
            value: data.WeekendAndVacationActivityTypeId,
          });
        } else {
          setValue("WeekendAndVacationActivityTypeId", null);
          setselectedactivity(null);
        }

        if (data.EntertainmentTypeId !== null) {
          setValue("EntertainmentTypeId", {
            label: data.EntertainmentType,
          value: data.EntertainmentTypeId,
          });
          setselectedentertainmenttype({
            label: data.EntertainmentType,
            value: data.EntertainmentTypeId,
          });
        } else {
          setValue("EntertainmentTypeId", null);
          setselectedentertainmenttype(null);
        }
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedentertainmenttype, setselectedentertainmenttype]: any = useState(
    null
  );
  const [entertainmentType, setentertainmentType]: any = useState(null);
  const [defaultValue, setdefaultValue] = useState(null);
  const handledefaultValueChange = (inputvalue: any) => {
    setentertainmentType(0);
    setdefaultValue(inputvalue);
  };

  const [selectedactivity, setselectedactivity]: any = useState(null);
  const [entertainmentactivityType, setentertainmentactivityType]: any =
    useState(null);
  const handleactivitytypeChange = (inputvalue: any) => {
    setentertainmentactivityType(0);
    setdefaultValue(inputvalue);
  };

  const areaofinterestFormData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.WeekendAndVacationActivityTypeId = Number(
      selectedactivity?.value
    )
    data.EntertainmentTypeId = Number(selectedentertainmenttype?.value);

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Weekend / Vacation Activity`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              areaofinterestFormData(productinfo);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Entertainment (Weekend / Vacation)"
                control={control}
                name="EntertainmentTypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("EntertainmentTypeId", e?.value);
                    setselectedentertainmenttype(e);
                  } else {
                    setValue("EntertainmentTypeId", null);
                    setselectedentertainmenttype(null);
                  }
                }}
                value={selectedentertainmenttype}
                api={process.env.REACT_APP_ENTERTAINMENTTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EntertainmentTypeId?.message ||
                  error?.response?.data?.errors?.SocialActivityPincodeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Entertainment Type is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Activity Type"
                control={control}
                name="WeekendAndVacationActivityTypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("WeekendAndVacationActivityTypeId", e?.value);
                    setselectedactivity(e);
                  } else {
                    setValue("WeekendAndVacationActivityTypeId", null);
                    setselectedactivity(null);
                  }
                }}
                value={selectedactivity}
                api={process.env.REACT_APP_ENTERTAINMENTACTIVITYTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.WeekendAndVacationActivityTypeId?.message ||
                  error?.response?.data?.errors?.WeekendAndVacationActivityTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Activity Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Place"
                type="text"
                maxLength={50}
                isRequired
                errorMessage={
                  errors?.WeekendAndVacationActivityPlace?.message ||
                  error?.response?.data?.errors?.WeekendAndVacationActivityPlace?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("WeekendAndVacationActivityPlace", e.target.value);
                  clearErrors(["WeekendAndVacationActivityPlace"]);
                }}
                register={{
                  ...register("WeekendAndVacationActivityPlace", {
                    required: {
                      value: true,
                      message: "Activity Place is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message: "Enter a valid activity place (1-50 characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Preferred Date-Time"
                type="date"
                isRequired
                min={moment(new Date()).format("YYYY-MM-DD")}
                errorMessage={
                  errors?.WeekendAndVacationActivityPreferredDateTime
                    ?.message ||
                  error?.response?.data?.errors?.WeekendAndVacationActivityPreferredDateTime?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue(
                    "WeekendAndVacationActivityPreferredDateTime",
                    e.target.value
                  );
                  clearErrors(["WeekendAndVacationActivityPreferredDateTime"]);
                }}
                register={{
                  ...register("WeekendAndVacationActivityPreferredDateTime", {
                    required: {
                      value: true,
                      message: "Preferred Date-Time is required",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={500}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                register={{
                  ...register("Remarks"),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => {
                    reset();
                    setselectedactivity(null);
                    setselectedentertainmenttype(null);
                  }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}

        {entertainmentType !== null && (
          <AddEditEntertainmentTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setentertainmentType(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue("EntertainmentTypeId", res.EntertainmentTypeId);
                setselectedentertainmenttype({
                  label: res.EntertainmentTypeName,
                  value: res.EntertainmentTypeId,
                });
                // fetchEntertainmentTypeOptions();
              }
            }}
            open={entertainmentType !== null}
            id={entertainmentType}
          />
        )}

        {entertainmentactivityType !== null && (
          <AddEditEntertainmentActivityTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setentertainmentactivityType(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue(
                  "WeekendAndVacationActivityTypeId",
                  res.EntertainmentActivityTypeId
                );
                setselectedactivity({
                  label: res.EntertainmentActivityTypeName,
                  value: res.EntertainmentActivityTypeId,
                });
                // fetchEntertainmentActivityTypeOptions();
              }
            }}
            open={entertainmentactivityType !== null}
            id={entertainmentactivityType}
          />
        )}
      </Dialog>
    </>
  );
};
