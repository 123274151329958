import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import PaymentInvoice from "../../../common/PaymentInvoice";
import ReactSelect from "../../../common/ReactSelect";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";

const AddPaymentInvoice = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const apiname =
    process.env.REACT_APP_INVENTORYPAYMENT_API?.toString();

  const [selectedInvoiceType, setSelectedInvoiceType]: any = useState(null);
  const [selectedInvoice, setSelectedInvoice]: any = useState(null);

  let [AlertIndex, SetAlertIndex] = useState(false)

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  }: any = useForm();

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname);

  const [paymentArray, setPaymentArray]: any = useState([]);

  // Payment Purchase Invoice
  const onPaymentPurchaseSubmit = (data: any) => {
    let submitData = {};
    let payment = paymentArray.map((e: any) => {
      return { ...e, PaymentMethodId: e?.PaymentMethodId?.value };
    });

    submitData = {
      ...data,
      InvoiceId: location?.state?.state,
      Payments: payment,
    };

    if (!AlertIndex) {
      mutate(submitData);
    } else {
      toast.error('Something went wrong!!!!!', { position: "top-right" });
    }

  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/inventory/Invoice");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  console.log(location?.state?.id, "location")

  return (
    <>
      <Helmet>
        <title>Add Payment Invoice</title>
      </Helmet>
      <PageHeader
        headTitle="Payment Invoice"
        headTitleURL={"/layout/inventory/Invoice"}
        list={false}
      />

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onPaymentPurchaseSubmit)}>
          <div className="row p-3">
            <div className="col-md-4">
              <h5>Invoice Type : {location?.state?.name}</h5>
            </div>
            <div className="col-md-4">
              <h5>Invoice Number : {location?.state?.id}</h5>
            </div>
            <div className="col-md-4">
              <h5>Closing Balance : {location?.state?.closingBalance > 0 ? location?.state?.closingBalance + " (CR) " : Math.abs(location?.state?.closingBalance) + " (DR) "}</h5>
            </div>

            {/* <ReactSelect
              containerClass="col-lg-6"
              label="Invoice Type"
              name={"InvoiceTypeId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setSelectedInvoiceType(e);
                  setValue("InvoiceTypeId", e?.value);
                } else {
                  setSelectedInvoiceType(null);
                  setValue("InvoiceTypeId", null);
                }
              }}
              value={selectedInvoiceType}
              control={control}
              api={process.env.REACT_APP_INVENTORYINVOICETYPE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.InvoiceTypeId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Invoice Type is required" },
              }}
            /> */}

            {/* <ReactSelect
              containerClass="col-lg-6"
              label="Invoice"
              name={"InvoiceId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setSelectedInvoice(e);
                  setValue("InvoiceId", e?.value);
                } else {
                  setSelectedInvoice(null);
                  setValue("InvoiceId", null);
                }
              }}
              value={selectedInvoice}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.InvoiceId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Invoice is required" },
              }}
            /> */}
          </div>

          {/* Payment Details */}
          <PaymentInvoice
            paymentArray={paymentArray}
            setPaymentArray={setPaymentArray}
            SetAlertIndex={SetAlertIndex}
          />

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/inventory/Invoice"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>

            {id === undefined ? (
              <button
                type="button"
                className="btn btn-outline-danger mt-2 mx-2"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </button>
            ) : (
              ""
            )}
            {isLoading ? (
              <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                Submit <Spinner width={"1rem"} height={"1rem"} />
              </button>
            ) : (
              <Button type="submit" color="primary" text="Submit"></Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPaymentInvoice;
