import { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddEditDegreeModal from "./AddEditDegreeModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const Degree = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname = process.env.REACT_APP_DOCTORDEGREE_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);

  const filterJson = {
    DegreeTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DegreeSpecialityName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DegreeAdmissionYear: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DegreePassOutYear: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DegreeCollege: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DegreeRegistrationNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "DegreeTypeName",
      bodyClassName: "text-info h6",
      header: "Degree",
      body: null,
      filter: true,
      filterPlaceholder: "Search by degree name",
      sortable: true,
      width: "10vw",
    },
    {
      field: "DegreeSpecialityName",
      header: "Speciality",
      body: null,
      filter: true,
      filterPlaceholder: "Search by speciality",
      sortable: false,
      width: "10vw",
    },
    {
      field: "DegreeAdmissionYear",
      header: "Admission Year",
      body: null,
      filter: true,
      filterPlaceholder: "Search by admission year",
      sortable: true,
      width: "5vw",
    },
    {
      field: "DegreePassOutYear",
      header: "PassOut Year",
      body: null,
      filter: true,
      filterPlaceholder: "Search by passout year",
      sortable: true,
      width: "5vw",
    },
    {
      field: "DegreeCollege",
      header: "College",
      body: null,
      filter: true,
      filterPlaceholder: "Search by college name",
      sortable: true,
      width: "10vw",
    },
    {
      field: "DegreeImage",
      header: "Degree Image",
      body: null,
      sortable: true,
      width: "10vw"
    },
    {
      field: "DegreeCollegeAddress",
      header: "Address",
      body: null,
      sortable: true,
      width: "10vw"
    },
    {
      field: "DegreeRegistrationNumber",
      header: "Registration Number",
      body: null,
      filter: true,
      filterPlaceholder: "Search by registration number",
      sortable: true,
      width: "5vw"
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.DegreeId}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.DegreeId);
          }}
          onClick={() => modelFunction(apiname, id.DegreeId)}
        />
      </>
    );
  };

  return (
    <>
      <Accordion>
        <AccordionTab header="Degree">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Doctor Degree`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              filterJson={filterJson}
              selecteditem={selectedRow}
              setselecteditem={setselectedRow}
              pdfTitle="Degree"
            />
          </div>
        </AccordionTab>
      </Accordion>

      {updateId !== null && (
        <AddEditDegreeModal
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
