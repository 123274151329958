// SentItems.js
import React, { useState, useEffect } from "react";
import EmailDetail from "./EmailDetail";
import axiosInstance from "../../../_helper/Interceptor";
import DatatableCommon from "../../../common/DatatableCommon";
import { Link } from "react-router-dom";

const SentItems = () => {
  const [selectedCategory, setSelectedCategory]: any = useState([]);
  const [bool, setbool]: any = useState(null);
  const columnData = [
    {
      field: "Subject",
      // header:"Subject",
      body: (e: any) => actionCategoryTemplate(e),
    },
  ];

  const actionCategoryTemplate = (id: any) => {
    return (
      <>
        <div className="d-flex">
          <div className="col-lg-8">
            <h6 className="mt-3">
              {id.EmailToViewModels.map((e: any) => e.ToUserName).join(", ")}
            </h6>
            <p>{id.Subject}</p>
          </div>
          <div className="col-lg-4 text-right m-auto">
            <Link to={`/layout/sendDetail/${id.Id}`} className="btn btn-primary">View</Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="inbox-container">
      <h1>Send Items</h1>
      <DatatableCommon
        apiname={"Reporting/Email/Get?EmailType=Send"}
        type={"data"}
        doctorid={1}
        showGridlines={false}
        // multiDelete={(data: boolean) => { if (data) { setdeleteID(selectedCategory?.length === 0 ? null : multi) } }}
        setselecteditem={setSelectedCategory}
        selecteditem={selectedCategory}
        columnData={columnData}
        refetchData={bool}
        setrefetchData={setbool}
        enableDownload={false}
      />
    </div>
  );
};

export default SentItems;
