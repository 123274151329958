import React, { useState } from 'react'
import { Action } from '../../../../common/Action';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import DatatableCommon from '../../../../common/DatatableCommon';
import { AddEditAttendedAs } from './AddEditAttendedAs';
import { DeleteModal } from '../../../../common/DeleteModal';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

export const AttendedAs = () => {
  const apiname = process.env.REACT_APP_ATTENDEDAS_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.AttendedAsId;
  });

  const filterJson = {
    AttendedAsName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionAttendedAsTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "AttendedAsName",
      header: "Attended As Name",
      bodyClassName: "text-info h6",
      filter: true,
      body: null,
      filterPlaceholder: "Search by attended as",
      sortable: true,
      width: "10vw",
    },

  ];

  const actionAttendedAsTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.AttendedAsId}
          editModal={() => {
            setupdateId(id.AttendedAsId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <>
      <Helmet>
        <title> Attended As </title>
      </Helmet>

      <PageHeader
        headTitle="Attended As"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
      />

      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          filterJson={filterJson}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
        />
        {updateId !== null && (
          <AddEditAttendedAs
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};