import { useForm } from "react-hook-form";
import { Input } from "../common/Input";
import ReactSelect from "../common/ReactSelect";
import { PageHeader } from "../common/PageHeader";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
export const Attendance = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
          beginAtZero: true,
        },
      },
    },
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Sales",
        data: [540, 325, 702, 620, 420],
        backgroundColor: [
          "rgba(255, 159, 64, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: ["Doctor", "Chemist", "Stockist"],
    datasets: [
      {
        label: "Sales",
        data: [540, 325, 702],
        backgroundColor: [
          "rgba(255, 159, 64, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div>
        <PageHeader
          list
          headTitle="Attendance"
          LinkURL="/layout/Attendance/todayActivity"
        />
      </div>
      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit(() => {
            navigate("/layout/Attendance/todayActivity");
          })}
        >
          <div className="row">
            <Input
              containerClass="col-lg-4"
              label="Month-Year"
              type="month"
              // isRequired
              errorMessage={
                errors?.MonthYear?.message
                //  ||
                // error?.response?.data?.errors?.DateTime?.map((e: any) => e)
              }
              onChange={() => clearErrors(["MonthYear"])}
              // register={{
              //     ...register("MonthYear", {
              //         required: {
              //             value: true,
              //             message: "Month Year is required.",
              //         },
              //     }),
              // }}
            />
            <ReactSelect
              containerClass="col-lg-4"
              label={"User"}
              // isRequired

              name={"User"}
              // onChange={(e: any) => {
              //     setselecteddivision(e);
              //     setValue("DivisionId", e?.value);
              // }}
              // value={selecteddivision}
              control={control}
              // api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={
                errors?.User?.message
                // ||
                // error?.response?.data?.errors?.DivisionId?.map(
                // (e: any) => e
                // )
              }
              // rules={{
              //     required: { value: true, message: "User is required" },
              // }}
            />
            <div className="d-flex align-items-center ">
              <Button type="submit">
                <span className="p-button-label p-c">Today Working</span>
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className=" d-flex justify-content-around pt-9 pb-20">
        <div
          className="col-2 card-box text-center p-2 attendanceCard"
        >
          <h4 style={{color:"#4A4A4A"}}>Leaves</h4>
          <h6>12</h6>
        </div>
        <div
          className="col-2 card-box text-center p-2 attendanceCard"
        >
          <h4 style={{color:"#4A4A4A"}}>Present</h4>
          <h6>15</h6>
        </div>
        <div
          className="col-2 card-box text-center p-2 attendanceCard"
        >
          <h4 style={{color:"#4A4A4A"}}>Holiday</h4>
          <h6>4</h6>
        </div>
        <div
          className="col-2 card-box text-center p-2 attendanceCard"
        >
          <h4 style={{color:"#4A4A4A"}}>No Working Days</h4>
          <h6>0</h6>
        </div>
      </div>
      <div className="d-flex flex-wrap w-100 chartWrap">
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="pd-20 card-box mb-30">
            <Chart type="bar" data={data} options={options} />
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="pd-20 card-box mb-30">
            <Chart type="bar" data={data2} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};
