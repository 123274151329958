import { useMutation } from "react-query";
import axios from "axios";
import axiosInstance from "../../_helper/Interceptor";

const loginform = async (logincreden: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASEURL_MAIN}Account/authenticate`,
    logincreden,
    { headers: { "Content-Type": "application/json", currentappversion: "v1" } }
  );
  localStorage.setItem("Refresh", response.data.Data.RefreshToken);
  localStorage.setItem("Access", response.data.Data.JWToken);
  localStorage.setItem("Roles", response.data.Data.Roles);
  localStorage.setItem("User", response.data.Data.Name);
  localStorage.setItem("Imagepath", response.data.Data.ImagePath);
  localStorage.setItem("Id", response.data.Data.Id);
  localStorage.setItem(
    "Division",
    JSON.stringify(response.data.Data.Divisions)
  );
};

export const useLoginData = () => {
  return useMutation(loginform);
};

export const useChangePassword = () =>
  useMutation(async (data: any) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BASEURL_MAIN}Account/change-Password`,
      data
    );
    return response;
  });

export const useForgetPassword = () =>
  useMutation(async (data: any) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BASEURL_MAIN}Account/forgot-password`,
      data
    );
    return response;
  });

export const useResetPassword = () =>
  useMutation(async (data: any) => {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BASEURL_MAIN}Account/reset-password`,
      data
    );
    return response;
  });
