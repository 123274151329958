import AdminPanelCard from "../common/AdminPanelCard";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const DoctorAttributes = () => {
  let admincard = [
    {
      name: "Doctor Category",
      link: "/layout/master/doctor/Category",
    },
    {
      name: "Doctor Speciality",
      link: "/layout/master/doctor/Speciality",
    },
    {
      name: "Doctor Qualification",
      link: "/layout/master/doctor/Qualification",
    },
    {
      name: "Doctor Degree Type",
      link: "/layout/submaster/doctorDegreeType",
    },
    {
      name: "Doctor Guidelines Type",
      link: "/layout/submaster/doctorGuidelinesType",
    },
    {
      name: "Entertainment Type",
      link: "/layout/submaster/entertainmentType",
    },
    {
      name: "Entertainment Activity Type",
      link: "/layout/submaster/entertainmentActivityType",
    },
    {
      name: "Hospital Role Type",
      link: "/layout/submaster/hospitalRoleType",
    },
    {
      name: "Doctor Area Of Interest Type",
      link: "/layout/submaster/doctorAreaOfInterestType",
    },
    {
      name: "Vendor Type",
      link: "/layout/submaster/vendorservicetype",
    },
    {
      name: "Achievement Type",
      link: "/layout/submaster/achievementType",
    },
    {
      name: "Fees Type",
      link: "/layout/submaster/consultingFeeType",
    },
    {
      name: "Conference And Webinar Attended Type",
      link: "/layout/submaster/conferenceAndWebinarAttendedType",
    },
    {
      name: "Conferences And Webinar Subject Type",
      link: "/layout/submaster/conferenceAndWebinarSubjectType",
    },
    {
      name: "Relation Type",
      link: "/layout/submaster/relationType",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Doctor Attributes</title>
      </Helmet>
      <div className="d-flex row ml-4 admin-panel">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Doctor Attributes</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/attributes"}>Attributes</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Doctor Attributes
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard name={card?.name} link={card?.link} />
          </div>
        ))}
      </div>
    </>
  );
};
