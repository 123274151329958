import { Link } from "react-router-dom"

const RedirectPage = () => {
    return (
        <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center" style={{ position: "absolute", top: "0", left: "0", bottom: "0", right: "0" }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">

                        <div className="login-box bg-white box-shadow border-radius-10" style={{ maxWidth: "500px" }}>
                            <div className="error-page-wrap text-center">
                                <h3 className="text-success mb-4">Email has been sent!!</h3>
                                <p>Please check you email and update you password</p>
                                <div className="pt-20 mx-auto max-width-200">
                                    <Link to={"/"} className="btn btn-outline-primary">Go to Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedirectPage
