import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../_helper/Interceptor";
import { Spinner } from "../../common/Spinner";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import { Badge } from "primereact/badge";
interface iPropsmodal {
  data?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

const AddProductMap = ({ data, setisOPen, open, fetch }: iPropsmodal) => {
  const [productData, setProductData] = useState([]);
  const [suggestionProductData, setSuggestionProductData]: any = useState({});
  const [subExpandedRows, setSubExpandedRows]: any = useState(null);
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [selecteddegree, setselecteddegree]: any = useState(null);
  const [bool, setbool] = useState(false);
  let [blankState, setBlankState] = useState(0)

  console.log(productData, "productData")

  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit
  }: any = useForm({ mode: "all" });

  useEffect(() => {
    const temp = data?.products?.map((e: any, i: any) => {
      return { ...e, id: i + 1 };
    });
    setProductData(temp);
  }, [data]);

  function compareKeys(a: any, b: any) {

    var aKeys = Object.keys(a).sort();
    console.log(aKeys, "aKeys");
    return aKeys[0] == b;
  }

  const onSubmitProduct = () => {

    let temp_data = productData.filter((e: any) => {
      return e.id != blankState
    })
    console.log(temp_data, "this is temp data")

    console.log(selectedProduct, "this is seleted product")

    if (selectedProduct !== null) {
      const temp = {
        stockistId: data.stockistId,
        stockistName: data.stockistName,
        chemistName: data.chemistName,
        chemistId: data.chemistId,
        productName: suggestionProductData?.productName,
        MatchingProductId:
          selectedProduct.productId === 0
            ? selecteddegree?.value
            : selectedProduct.productId,
      };
      console.log(temp, "Final data");
      axiosInstance
        .post(
          `${process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP}UpdateProductName`,
          temp
        )
        .then((e) => {
          if (productData.length == 1) {
            setisOPen(false)
            fetch(true);
          } else {
            let temp_data = productData.filter((e: any) => {
              return e.id != blankState
            })
            setProductData(temp_data)
          }
          setSubExpandedRows(null);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const productoption = (rowData: any) => {
    return (
      <>
        <Badge value={rowData?.name} size="normal" severity={"warning"} />
      </>
    );
  };

  const rowExpansionTemplate = (dataid: any) => {
    console.log(dataid, "this is eeee")
    setBlankState(dataid)
    return (
      <>
        {!load ? (
          <div className="col-md-12 text-center">
            <h3 >
              {!isDataFound
                ? "No Suggestions found Please Select Manually"
                : "Suggestions found"}
            </h3>
            {isDataFound && (
              <DataTable
                value={suggestionProductData?.suggestions}
                selectionMode={"radiobutton"}
                selection={selectedProduct}
                onSelectionChange={(e) => {
                  setSelectedProduct(e?.value);
                }}
                dataKey="productId"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  selectionMode="single"
                  headerStyle={{ width: "3rem" }}
                ></Column>

                <Column field="name" header="Product Name" />
              </DataTable>
            )}

            {!isDataFound || selectedProduct?.name === "Other" ? (
              <div className="col-md-12">
                <ReactSelect
                  containerClass="col-lg-12"
                  label="Product"
                  control={control}
                  refetch={bool}
                  name="DegreeTypeId"
                  isRequired
                  errorMessage={errors?.DegreeTypeId?.message}
                  rules={{
                    required: {
                      value: true,
                      message: "Please select product",
                    },
                  }}
                  onChange={(e: any) => {
                    setValue("DegreeTypeId", e?.value);
                    setselecteddegree(e);
                  }}
                  value={selecteddegree}
                  api={process.env.REACT_APP_PRODUCT_API}
                  keys={{ label: "Value", value: "Id" }}
                />
              </div>
            ) : (
              ""
            )}

            <div className="mt-3 text-center">
              <button className="btn btn-primary" onClick={handleSubmit(onSubmitProduct)}>
                Update
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 text-center">
            <Spinner width={"5rem"} height={"5rem"} />
          </div>
        )}
      </>
    );
  };

  const [isDataFound, setIsDataFound] = useState(false);
  const [load, setLoad] = useState(false);

  const onSubmit = (e: any) => {
    setLoad(true);
    setSelectedProduct(null);
    const temp = {
      productName: e.name,
      divisionName: e.divisionName,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP}MatchProductNames`,
        temp
      )
      .then((e: any) => {
        setLoad(false);
        console.log(e.data.data);

        const temp = e.data.data;

        temp?.suggestions.push({
          productId: 0,
          name: "Other",
          divisionId: 0,
        });

        setIsDataFound(true);
        setSuggestionProductData(temp);
      })
      .catch((err: any) => {
        setLoad(false);
        setSuggestionProductData({});
        setIsDataFound(false);
        console.log(err);
      });
  };

  const tempFunction = (rowData: any) => {
    return (
      <span className="mr-2">
        <Badge
          key={"id-1"}
          onClick={() => {
            let obj: any = {};
            if (
              subExpandedRows != null &&
              compareKeys(subExpandedRows, rowData.id)
            ) {
              obj = {};
            } else {
              obj[rowData.id] = true;
              onSubmit(rowData);
            }
            setSubExpandedRows(obj);
            setBlankState(0)
          }}
          style={{ cursor: "pointer" }}
          severity={"info"}
          value={rowData.name}
        ></Badge>
      </span>
    );
  };

  return (
    <Dialog
      visible={open}
      onHide={() => setisOPen(false)}
      header={
        <div>
          <div className="d-flex justify-content-between mr-3">
            <div className="h6">
              <span className="mr-2">Stockist Name</span>
              <Badge
                value={data.stockistName}
                size="normal"
                severity={"info"}
              />
            </div>
            <div className="h6">
              <span className="mr-2">Stockist Name</span>
              <Badge
                value={data.chemistName}
                size="normal"
                severity={"warning"}
              />
            </div>
          </div>
          <p
            className="text-center"
            style={{
              marginTop: "30px",
              color: "red",
              fontWeight: 500,
              fontSize: "14px",
              margin: 0,
              padding: 0,
            }}
          >
            *Following Product Names does not match with available Products,
            Please Resolve first
          </p>
        </div>
      }
      modal
      style={{ width: "1500px" }}
      footer={<div className="text-center"></div>}
    >
      <div className="pd-20 card-box mb-30">
        <DataTable
          value={productData}
          dataKey="id"
          className="custom-datatable"
          showGridlines
          size="small"
          rowHover
          expandedRows={subExpandedRows}
          rowExpansionTemplate={(e: any) => rowExpansionTemplate(e.id)}
        >
          <Column header="Product Name" body={tempFunction}></Column>
          <Column field="divisionName" header="Division Name"></Column>
          <Column field="rate" header="Rate"></Column>
        </DataTable>
      </div>
    </Dialog>
  );
};

export default AddProductMap;
