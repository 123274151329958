import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Spinner } from "../../../common/Spinner";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";
import axiosInstance from "../../../_helper/Interceptor";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditStockistMap = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_STOCKISTMAP_API?.toString();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    error,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: stockistmap }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error: stockistError,
    data: response,
  }: any = useCreateByID(apiname, undefined);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselectedemployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        });

        let newData =
          data?.StockistList?.map((d: any) => ({
            label: d?.Value,
            value: d?.Id,
          }));
        setselectedstockist([...newData]);
        setValue("StockistIdList", newData.map((data: any) => data.value))

      }



    }
  }, [data]);

  const [selectedstockist, setselectedstockist]: any = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const [selectedemployee, setselectedemployee]: any = useState(null);

  const stockistFormData = (data: any) => {
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (stockistError?.response?.data?.StatusCode !== 400) {
        toast.error(stockistError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Stockist Map`}
        modal
        style={{ width: "1250px" }}
      >
        {!stockistmap ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(stockistFormData);
              }
            }}
            onSubmit={handleSubmit(stockistFormData)}
          >
            <div className="row">


              <ReactSelect
                containerClass="col-lg-6"
                label="Employee"
                name={"EmployeeId"}
                isRequired
                Disabled={id > 0 ? true : false}
                onChange={(e: any) => {
                  console.log(e)
                  axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${e?.value}`).then((res) => {
                    console.log(res)
                    const newStockistData = res?.data?.Data?.StockistList?.map((d: any) => {
                      return {
                        label: d?.Value,
                        value: d?.Id,
                      }
                    });
                    setselectedstockist([...newStockistData]);
                    setValue("StockistIdList", newStockistData.map((data: any) => data.value))

                  }).catch((err) => { setselectedstockist(null); setValue("StockistIdList", null) })
                  setselectedemployee(e);
                  setValue("EmployeeId", e?.value);
                  setIsEdited(true);
                }}
                value={selectedemployee}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Employee is required" },
                }}
              />
              <ReactSelect
                containerClass="col-lg-6"
                label="Stockist"
                name={"StockistIdList"}
                isRequired
                Multi
                Disabled={selectedemployee === null}
                onChange={(e: any) => {
                  setselectedstockist(e);
                  setValue("StockistIdList", e?.map((data: any) => data.value));
                  setIsEdited(true);
                }}
                value={selectedstockist}
                control={control}
                api={process.env.REACT_APP_STOCKIST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.StockistIdList?.message ||
                  error?.response?.data?.errors?.StockistIdList?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Stockist is required" },
                }}
              />
              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedemployee(null); setselectedstockist([]) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2" disabled={id && !isEdited}>
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
