import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
  transfer?: any;
  setTransfer?: any;
}

export const AddEditSampleGrid = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
  transfer,
  setTransfer,
}: iPropsmodal) => {
  console.log(transfer, "this is trransfer data");
  const Addapiname = process.env.REACT_APP_SAMPLTEADDEDIT_API?.toString();
  const apiname = process.env.REACT_APP_SAMPLTE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(Addapiname);

  useEffect(() => {
    if (data) {
      if (id !== 0) {
        reset(data);
        console.log(data, "this is fetch data");
        setValue("employeeId", {
          label: data.EmpName,
          value: data.EmpId,
        });

        setValue("WarehouseId", {
          label: data.WarehouseName,
          value: data.WarehouseId,
        });

        setValue("productId", {
          label: data.ProductName,
          value: data.ProductId,
        });
        setselectedemployee({
          label: data.EmpName,
          value: data.EmpId,
        });
        setselectedwarehouse({
          label: data.WarehouseName,
          value: data.WarehouseId,
        });
        setselectedproduct({
          label: data.ProductName,
          value: data.ProductId,
        });
      }
    }
  }, [data]);

  const onSubmit = (data: any) => {
    debugger;
    console.log(transfer?.EmployeeId, "this is data");
    const formData: any = {
      EmpId: !transfer ? selectedemployee?.value : transfer?.EmployeeId,
      ProductId: !transfer ? selectedproduct?.value : transfer?.ProductId,
      WarehouseId: selectedwarehouse?.value,
      Qty: Number(data.Qty),
      TrackingLink: data.TrackingLink,
      SampleRequestId: transfer && transfer?.SampleRequestId,
    };
    for (let prop in formData) {
      if (
        formData[prop] === null ||
        formData[prop] === undefined ||
        formData[prop] === "" ||
        formData[prop]?.length == 0
      ) {
        delete formData[prop];
      }
    }

    console.log(formData, "this is form data");
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      toast.error(error?.response?.data?.Message, { position: "top-right" });
    }
  }, [isError, isSuccess, id]);
  const [selectedwarehouse, setselectedwarehouse]: any = useState(null);
  const [selectedproduct, setselectedproduct]: any = useState(null);
  const [selectedemployee, setselectedemployee]: any = useState(null);
  const [selecteddivision, setselecteddivision]: any = useState(null);
  const [selectedhq, setselectedhq]: any = useState(null);
  let [bool, setBool] = useState(true);
  let [boo, setBoo] = useState(true);

  useEffect(() => {
    if (transfer) {
      setValue("employeeId", {
        label: transfer.RequestedByName,
        value: transfer.EmployeeId,
      });
      setselectedemployee({
        label: transfer.RequestedByName,
        value: transfer.EmployeeId,
      });

      setValue("productId", {
        label: transfer.ProductName,
        value: transfer.ProductId,
      });
      setselectedproduct({
        label: transfer.ProductName,
        value: transfer.ProductId,
      });
    }
  }, [transfer]);

  useEffect(() => {
    if (!transfer) {
      if (selectedhq !== null && selecteddivision !== null) {
        setBool(false);
      } else {
        setBool(true);
      }
      if (selecteddivision !== null && selectedwarehouse !== null) {
        setBoo(false);
      } else {
        setBoo(true);
      }
    } else {
      if (selectedwarehouse !== null) {
        setBoo(false);
      } else {
        setBoo(true);
      }
    }
  }, [selecteddivision, selectedhq, selectedwarehouse, transfer]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Sample`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Warehouse"
                name={"WarehouseId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setselectedwarehouse(e);
                    setValue("WarehouseId", e?.value);
                  } else {
                    setselectedwarehouse(null);
                    setValue("WarehouseId", null);
                  }
                }}
                value={selectedwarehouse}
                control={control}
                api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.WarehouseId?.message}
                isRequired
                rules={{
                  required: { value: true, message: "Warehouse is required" },
                }}
              />

              {id == 0 && (
                <>
                  {!transfer && (
                    <ReactSelect
                      containerClass="col-lg-6"
                      label="Division"
                      name={"divisionId"}
                      onChange={(e: any) => {
                        setselectedemployee(null);
                        setValue("employeeId", null);
                        setselectedproduct(null);
                        setValue("productId", null);
                        if (e?.value !== undefined) {
                          setselecteddivision(e);
                          setValue("divisionId", e?.value);
                        } else {
                          setselecteddivision(null);
                          setselectedemployee(null);
                          setselectedproduct(null);
                          setValue("divisionId", null);
                        }
                      }}
                      value={selecteddivision}
                      control={control}
                      api={process.env.REACT_APP_DIVISION_API}
                      keys={{ label: "Value", value: "Id" }}
                      errorMessage={errors?.divisionId?.message}
                      isRequired
                      rules={{
                        required: {
                          value: id == 0 ? true : false,
                          message: "Division is required",
                        },
                      }}
                    />
                  )}
                </>
              )}

              {transfer ? (
                <ReactSelect
                  containerClass="col-lg-6"
                  label="Product"
                  name={"productId"}
                  value={{
                    label: transfer.ProductName,
                    value: transfer.ProductId,
                  }}
                  control={control}
                  api={
                    selectedwarehouse !== null
                      ? `${process.env.REACT_APP_PRODUCTFILTER_API}?WarehouseId=${selectedwarehouse?.value}`
                      : ""
                  }
                  flag
                  Disabled
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={errors?.productId?.message}
                  isRequired
                  rules={{
                    required: { value: true, message: "Product is required" },
                  }}
                />
              ) : (
                <ReactSelect
                  containerClass="col-lg-6"
                  label="Product"
                  name={"productId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setselectedproduct(e);
                      setValue("productId", e?.value);
                    } else {
                      setselectedproduct(null);
                      setValue("productId", null);
                    }
                  }}
                  value={selectedproduct}
                  control={control}
                  api={
                    selecteddivision !== null && selectedwarehouse !== null
                      ? `${process.env.REACT_APP_PRODUCTFILTER_API}?DivisionId=${selecteddivision?.value}&WarehouseId=${selectedwarehouse?.value}`
                      : ""
                  }
                  flag
                  Disabled={boo}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={errors?.productId?.message}
                  isRequired
                  rules={{
                    required: { value: true, message: "Product is required" },
                  }}
                />
              )}

              {id == 0 && !transfer && (
                <>
                  <ReactSelect
                    containerClass="col-lg-6"
                    label="Hq"
                    name={"hqId"}
                    onChange={(e: any) => {
                      console.log(e?.value, "this is hq values");
                      if (e?.value !== undefined) {
                        setselectedhq(e);
                        setValue("hqId", e?.value);
                      } else {
                        setselectedhq(null);
                        setselectedemployee(null);
                        setValue("hqId", null);
                      }
                    }}
                    value={selectedhq}
                    control={control}
                    api={process.env.REACT_APP_HQ_API}
                    keys={{ label: "Value", value: "Id" }}
                    errorMessage={errors?.hqId?.message}
                    isRequired={!transfer}
                    rules={{
                      required: {
                        value: id == 0 && !transfer ? true : false,
                        message: "HQ is required",
                      },
                    }}
                  />
                </>
              )}

              {transfer ? (
                <ReactSelect
                  containerClass="col-lg-6"
                  label="Employee"
                  name={"employeeId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setselectedemployee(e);
                      setValue("employeeId", e?.value);
                    } else {
                      setselectedemployee(null);
                      setValue("employeeId", null);
                    }
                  }}
                  value={{
                    label: transfer.RequestedByName,
                    value: transfer.EmployeeId,
                  }}
                  control={control}
                  api={process.env.REACT_APP_EMPLOYEEFILTER_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={errors?.employeeId?.message}
                  Disabled
                  isRequired
                  rules={{
                    required: { value: true, message: "Employee is required" },
                  }}
                />
              ) : (
                <ReactSelect
                  containerClass="col-lg-6"
                  label="Employee"
                  name={"employeeId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setselectedemployee(e);
                      setValue("employeeId", e?.value);
                    } else {
                      setselectedemployee(null);
                      setValue("employeeId", null);
                    }
                  }}
                  value={selectedemployee}
                  control={control}
                  api={
                    selecteddivision !== null && selectedhq !== null
                      ? `${process.env.REACT_APP_EMPLOYEEFILTER_API}?divisionId=${selecteddivision?.value}&hqId=${selectedhq?.value}`
                      : ""
                  }
                  flag
                  Disabled={bool}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={errors?.employeeId?.message}
                  isRequired
                  rules={{
                    required: { value: true, message: "Employee is required" },
                  }}
                />
              )}

              <Input
                containerClass="col-lg-6"
                label="Quantity"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Qty", e.target.value);
                  clearErrors(["Qty"]);
                }}
                register={{
                  ...register("Qty", {
                    required: {
                      value: true,
                      message: "Quantity is required",
                    },
                    pattern: {
                      value: /^(?!\s)\d+(?!\s)$/,
                      message:
                        "Enter only digits and required 10 digits or Remove spaces from front",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Qty?.message ||
                  error?.response?.data?.errors?.Qty?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-6"
                label="Tracking Link"
                type="text"
                onChange={(e: any) => {
                  setValue("TrackingLink", e.target.value);
                }}
                register={{
                  ...register("TrackingLink"),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => reset()}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
