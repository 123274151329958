import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner } from "../../../common/Spinner";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Button } from "../../../common/Button";
import { LoaderContext } from "../../../App";

export const GiftUpload = () => {
  const fullScreenLoader = useContext(LoaderContext);
  const apiname = process.env.REACT_APP_GIFTEMPMAPPING_API?.toString();
  let navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset: downloadReset,
  }: any = useForm({ mode: "all" });

  const [isLoading, setIsLoading] = useState(false);

  const {
    register: uploadRegister,
    handleSubmit: uploadSubmit,
    formState: { errors: uploadErrors },
    reset,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: giftLoading,
  }: any = useCreateByID(apiname);

  const [selecteddivision, setselecteddivision]: any = useState(null);
  const [selectedwarehouse, setselectedwarehouse]: any = useState(null);
  const [selectedhq, setselectedhq]: any = useState(null);
  const [selectedproduct, setselectedproduct]: any = useState(null);
  const [selectedemployee, setselectedemployee]: any = useState(null);
  let [bool, setBool] = useState(true);

  useEffect(() => {
    if (selectedhq !== null && selecteddivision !== null) {
      setBool(false);
    } else {
      setBool(true);
    }
  }, [selecteddivision, selectedhq]);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownload = async () => {


    const productid = selectedproduct?.map((e: any) => `GiftIds=${e?.value}`);

    const employeeid = selectedemployee?.map(
      (e: any) => `EmployeeIds=${e?.value}`
    );
    console.log(String(productid).replaceAll(",", "&"), "this is productId");
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GIFTEMPMAPPINGDOWNLOADWITHDATA_API
        }?WarehouseId=${selectedwarehouse.value}&${String(productid).replaceAll(
          ",",
          "&"
        )}&${String(employeeid).replaceAll(",", "&")}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "GiftWithData.xlsx");
        downloadReset();
        setselecteddivision(null);
        setselectedemployee(null);
        setselectedhq(null);
        setselectedproduct(null);
        setselectedwarehouse(null);
        setBool(true);
        setIsLoading(false);
        toast.success(`Download With Data Successfull`, {
          position: "top-right",
        });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const handleUpload = (data: any) => {

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(`File Uploaded Successfull`, { position: "top-right" });
      reset();
    }
    if (isError) {
      toast.error(`${error?.response?.data?.Message}`, {
        position: "top-right",
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    if (giftLoading) {
      fullScreenLoader.setloader(true)
    }
    else {
      fullScreenLoader.setloader(false)
    }
  }, [giftLoading])
  return (
    <>
      <Helmet>
        <title>Gift Upload</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Gift Upload</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Gift Upload
                </li>
              </ol>
            </nav>
          </div>

          <div className="col-md-6">
            <div className="pull-right">
              <Button
                text={`View Gift`}
                onclick={() => {
                  navigate("/layout/master/general/GiftMaster");
                }}
                color="secondary"
              />

              <Button
                text={`Gift Assignments`}
                onclick={() => {
                  navigate("/layout/upload/giftGrid");
                }}
                color="primary"
              />
              {/* <button
              className="btn btn-outline-primary pull-right"
              type="button"
              onClick={() => {
                navigate("/layout/upload/sampleGrid");
              }}
            >
              Sample Product Assignments
            </button> */}
            </div>
            <div className="pull-right">
              {/* <Button
                text={`Download Blank`}
                onclick={() => {
                  handleDownloadBlank();
                }}
                color="primary"
              /> */}

              {/* {!isLoad ? (
                <button
                  className="btn btn-outline-primary pull-right"
                  type="button"
                  onClick={handleDownloadBlank}
                >
                  Download Blank
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={handleDownloadBlank}
                  disabled
                >
                  Download Blank <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Download Excel</h5>
        </div>
        <form onSubmit={handleDownload}>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="Warehouse"
              name={"WarehouseId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setselectedwarehouse(e);
                  setValue("WarehouseId", e?.value);
                } else {
                  setselectedwarehouse(null);
                  setValue("WarehouseId", null);
                }
              }}
              value={selectedwarehouse}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.WarehouseId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Warehouse is required" },
              }}
            />
            <ReactSelect
              containerClass="col-lg-6"
              label="Division"
              name={"divisionId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setselecteddivision(e);
                  setValue("divisionId", e?.value);
                } else {
                  setselecteddivision(null);
                  setselectedemployee(null);
                  setselectedproduct(null);
                  setValue("divisionId", null);
                }
              }}
              value={selecteddivision}
              control={control}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.divisionId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Division is required" },
              }}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Hq"
              name={"hqId"}
              onChange={(e: any) => {
                console.log(e?.value, "this is hq values");
                if (e?.value !== undefined) {
                  setselectedhq(e);
                  setValue("hqId", e?.value);
                } else {
                  setselectedhq(null);
                  setselectedemployee(null);
                  setValue("hqId", null);
                }
              }}
              value={selectedhq}
              control={control}
              api={process.env.REACT_APP_HQ_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.hqId?.message}
              isRequired
              rules={{
                required: { value: true, message: "HQ is required" },
              }}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Gift"
              name={"productId"}
              Multi
              onChange={(e: any) => {
                if (e?.length !== 0) {
                  setselectedproduct(e);
                  setValue("productId", e?.value);
                } else {
                  setselectedproduct(null);
                  setValue("productId", null);
                }
              }}
              value={selectedproduct}
              control={control}
              api={
                selectedwarehouse !== null
                  ? `${process.env.REACT_APP_GIFTFILTER_API}?WarehouseId=${selectedwarehouse?.value}`
                  : ""
              }
              flag
              Disabled={selectedwarehouse == null ? true : false}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.productId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Product is required" },
              }}
            />
            <ReactSelect
              containerClass="col-lg-6"
              label="Employee"
              name={"employeeId"}
              onChange={(e: any) => {
                if (e?.length !== 0) {
                  setselectedemployee(e);
                  setValue("employeeId", e?.value);
                } else {
                  setselectedemployee(null);
                  setValue("employeeId", null);
                }
              }}
              Multi
              value={selectedemployee}
              control={control}
              api={
                selecteddivision !== null && selectedhq !== null
                  ? `${process.env.REACT_APP_EMPLOYEEFILTER_API}?divisionId=${selecteddivision?.value}&hqId=${selectedhq?.value}`
                  : ""
              }
              flag
              Disabled={bool}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.employeeId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Employee is required" },
              }}
            />

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={handleDownload}
                >
                  Download
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={handleDownload}
                  disabled
                >
                  Download <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Upload Excel</h5>
        </div>
        <form
          onSubmit={uploadSubmit((data: any) => {
            handleUpload(data);
          })}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Select File <span className="text-danger">*</span>
                </label>
                <input
                  {...uploadRegister("GiftEmpMappingExcel", {
                    required: {
                      value: true,
                      message: "Please Upload Excel",
                    },
                  })}
                  type="file"
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <ErrorMessage
                  message={uploadErrors?.GiftEmpMappingExcel?.message}
                />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!giftLoading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Uploading...
                  {/* <Spinner width={"1rem"} height={"1rem"} /> */}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
