import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}
export default function AddEditAssociatedHospitalsModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "Recursive") {
      setselectedrecursive(null);
      resetstate(false);
    }
  }, [refetchData]);
  const [activeState, setActiveState] = useState(false);
  const apiname = process.env.REACT_APP_DOCTORATTACHEDHOSPITAL_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (data.AssociatedHospitalActive !== undefined && data.AssociatedHospitalActive !== null) {
          setActiveState(data.AssociatedHospitalActive);
          setValue("AssociatedHospitalActive", data.AssociatedHospitalActive)
        }


        if (
          data.CityId !== null &&
          data.CityId !== undefined
        ) {
          setValue("CityId", {
            label: data.CityName,
            value: data.CityId,
          });
          setselectedcity({
            label: data.CityName,
            value: data.CityId,
          });
        } else {
          setValue("CityId", null);
          setselectedcity(null);
        }

        if (
          data.AssociatedHospitalRecusive !== null &&
          data.AssociatedHospitalRecusive !== undefined
        ) {
          setValue("AssociatedHospitalRecusive", {
            label: data.AssociatedHospitalRecusiveName,
            value: data.AssociatedHospitalRecusive,
          });
          setselectedrecursive({
            label: data.AssociatedHospitalRecusiveName,
            value: data.AssociatedHospitalRecusive,
          });
        } else {
          setValue("AssociatedHospitalRecusive", null);
          setselectedrecursive(null);
        }

        if (data.AssociatedHospitalCityId !== null) {
          setValue("AssociatedHospitalCityId", {
            label: data.AssociatedHospitalCityName,
            value: data.AssociatedHospitalCityId,
          });
          setselectedhcity({
            label: data.AssociatedHospitalCityName,
            value: data.AssociatedHospitalCityId,
          });
        } else {
          setValue("AssociatedHospitalCityId", null);
          setselectedhcity(null);
        }
        if (data.AssociatedHospitalPincodeId !== null) {
          setValue("AssociatedHospitalPincodeId", {
            label: data.AssociatedHospitalPincodeName,
            value: data.AssociatedHospitalPincodeId,
          });
          setselectedhpincode({
            label: data.AssociatedHospitalPincodeName,
            value: data.AssociatedHospitalPincodeId,
          });
        } else {
          setValue("AssociatedHospitalPincodeId", null);
          setselectedhpincode(null);
        }
      }
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });
  const Hcity = watch("AssociatedHospitalCityId");

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedrecursive, setselectedrecursive]: any = useState(null);
  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);

  const AssociatedHospitalData = (data: any) => {
    data.DoctorId = Number(doctorid);

    data.AssociatedHospitalRecusive = selectedrecursive?.value;
    data.CityId = selectedcity?.value;
    data.AssociatedHospitalCityId = selectedhcity?.value;
    data.AssociatedHospitalPincodeId = selectedhpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Associated Hospital`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(AssociatedHospitalData);
              }
            }}
            onSubmit={handleSubmit(AssociatedHospitalData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Name of Hospital"
                type="text"
                isRequired
                errorMessage={
                  errors?.AssociatedHospitalName?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AssociatedHospitalName", e.target.value);
                  clearErrors(["AssociatedHospitalName"]);
                }}
                register={{
                  ...register("AssociatedHospitalName", {
                    required: {
                      value: true,
                      message: "Hospital Name is required",
                    },
                    pattern: {
                      value: /^.{2,100}$/gm,
                      message:
                        "Enter a valid hospital name (2-100 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Place (City)"
                control={control}
                name="CityId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("CityId", e?.value);
                    setselectedcity(e);
                  } else {
                    setValue("CityId", null);
                    setselectedcity(null);
                  }
                }}
                value={selectedcity}
                api={process.env.REACT_APP_CITY_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.CityId?.message ||
                  error?.response?.data?.errors?.CityId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Place(City) is required",
                  },
                }}
              />

              <div className="col-lg-4">
                <div className="form-group">
                  <label className="col-form-label">
                    {" "}
                    Active (Yes / No)
                  </label>
                  <div className="custom-control custom-checkbox pt-2">
                    <input
                      {...register("AssociatedHospitalActive")}
                      id="customCheck1"
                      type="checkbox"
                      className="custom-control-input"
                      onClick={(e) => {
                        console.log(e);
                        setActiveState(!activeState);
                      }}
                      onChange={(e: any) => {
                        setValue("AssociatedHospitalActive", e.target.value);
                        clearErrors(["AssociatedHospitalActive"]);
                      }}
                      checked={activeState}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    ></label>
                  </div>
                </div>
              </div>

              <ReactSelect
                containerClass="col-lg-4"
                label="Is Recursive"
                control={control}
                name="AssociatedHospitalRecusive"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("AssociatedHospitalRecusive", e?.value);
                    setselectedrecursive(e);
                  } else {
                    setValue("AssociatedHospitalRecusive", null);
                    setselectedrecursive(null);
                  }
                }}
                value={selectedrecursive}
                api={process.env.REACT_APP_RECURSIVE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.AssociatedHospitalRecusive?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalRecusive?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Recursive is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Daily"
                type="text"
                isRequired
                errorMessage={
                  errors?.AssociatedHospitalDaily?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalDaily?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AssociatedHospitalDaily", e.target.value);
                  clearErrors(["AssociatedHospitalDaily"]);
                }}
                register={{
                  ...register("AssociatedHospitalDaily", {
                    required: { value: true, message: "Daily is required" },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message: "Enter a valid daily (1-7 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Weekly"
                type="text"
                isRequired
                errorMessage={
                  errors?.AssociatedHospitalWeekly?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalWeekly?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AssociatedHospitalWeekly", e.target.value);
                  clearErrors(["AssociatedHospitalWeekly"]);
                }}
                register={{
                  ...register("AssociatedHospitalWeekly", {
                    required: { value: true, message: "Weekly is required" },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message: "Enter a valid weekly (1-7 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Monthly"
                type="text"
                isRequired
                errorMessage={
                  errors?.AssociatedHospitalMonthly?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalMonthly?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AssociatedHospitalMonthly", e.target.value);
                  clearErrors(["AssociatedHospitalMonthly"]);
                }}
                register={{
                  ...register("AssociatedHospitalMonthly", {
                    required: { value: true, message: "Monthly is required" },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message: "Enter a valid monthly (1-7 digits only)",
                    },
                  }),
                }}
              />

              <Address
                header="Address"
                address1register={{
                  ...register("AssociatedHospitalAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("AssociatedHospitalAddressLine1", e.target.value);
                  clearErrors(["AssociatedHospitalAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.AssociatedHospitalAddressLine1?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("AssociatedHospitalAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("AssociatedHospitalAddressLine2", e.target.value);
                  clearErrors(["AssociatedHospitalAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.AssociatedHospitalAddressLine2?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("AssociatedHospitalCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("AssociatedHospitalCityId", null);
                    setValue("AssociatedHospitalPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.AssociatedHospitalCityId?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalCityId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("AssociatedHospitalPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("AssociatedHospitalPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.AssociatedHospitalPincodeId?.message ||
                  error?.response?.data?.errors?.AssociatedHospitalPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedhcity}
                selectedPincode={selectedhpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"AssociatedHospitalCityId"}
                control={control}
                pincodeApi={
                  selectedhcity !== null ?
                    `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}` : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"AssociatedHospitalPincodeId"}
                pincodeDisable={selectedhcity !== null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedcity(null);
                      setselectedhcity(null);
                      setselectedhpincode(null);
                      setselectedrecursive(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
