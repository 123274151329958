import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddDCR = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_TOURPLAN_API?.toString();
  const [byArea, setByarea] = useState(true);
  const [selectedCity, setselectedCity] = useState([]);
  const [selectedRoute, setselectedRoute]: any = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  }: any = useForm({
    mode: "all",
  });
  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );

  const { data } = useFetchAllID(apiname, id);

  useEffect(() => {
    if (id) {
      console.log(data, "view Data");
      if (data) {
        if (data.RouteId == null) {
          setByarea(true);
          const city = data?.toutPlanAreaMapViewModels.map((e: any) => {
            return {
              label: e?.CityName,
              value: e?.CityId,
            };
          });
          setselectedCity(city);
        } else {
          setByarea(false);
          setselectedRoute({
            label: data.RouteName,
            value: data.RouteId,
          });
        }
        const doctor = data.doctorVisitViewModels.map((e: any) => {
          return `${e.DoctorId}`;
        });
        const hospital = data.hospitalVisitViewModels.map((e: any) => {
          return `${e.HospitalId}`;
        });
        const chemist = data.chemistVisitViewModels.map((e: any) => {
          return `${e.ChemistId}`;
        });
        setValue("DoctorIds", doctor);
        setValue("HospitalIds", hospital);
        setValue("ChemistIds", chemist);
      }
    }
  }, [data]);

  const cityid = selectedCity?.map((e: any) => `cityid=${e?.value}`);
  const [doctorOptions, setdoctorOptions] = useState([]);
  const fetchdoctorOptions = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORDETAILGETDOCTORBYCITY_API}?${byArea == true
          ? String(cityid)?.replaceAll(",", "&")
          : `routeId=${selectedRoute?.value}`
        }`
      )
      .then((res) => {
        setdoctorOptions(res?.data?.Data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const [hospitalOptions, sethospitalOptions] = useState([]);
  const fetchhospitalOptions = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_HOSPITALGETHOSPITALBYCITY_API}?${byArea == true
          ? String(cityid)?.replaceAll(",", "&")
          : `routeId=${selectedRoute?.value}`
        }`
      )
      .then((res) => {
        sethospitalOptions(res?.data?.Data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const [chemistOptions, setchemistOptions] = useState([]);
  const fetchchemistOptions = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTGETCHEMISTBYCITY_API}?${byArea == true
          ? String(cityid)?.replaceAll(",", "&")
          : `routeId=${selectedRoute?.value}`
        }`
      )
      .then((res) => {
        setchemistOptions(res?.data?.Data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    if (selectedCity !== undefined && selectedCity?.length != 0) {
      fetchdoctorOptions();
      fetchhospitalOptions();
      fetchchemistOptions();
    } else if (selectedRoute?.length != 0) {
      fetchdoctorOptions();
      fetchhospitalOptions();
      fetchchemistOptions();
    } else {
      setdoctorOptions([]);
      sethospitalOptions([]);
      setchemistOptions([]);
    }
  }, [selectedCity, selectedRoute]);

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };
  const onSubmit = (form: any) => {

    form.TourPlanId = Number(id);
    form.TourDates = data?.TourDate?.split("T")[0];
    form.CityId = selectedCity.map((e: any) => e?.value);
    form.RouteId = selectedRoute?.value;

    if (form.RouteId == "" || form.RouteId == undefined) {
      delete form.RouteId;
    } else {
      delete form.CityId;
    }

    if (typeof form.DoctorIds == "string") {
      form.DoctorIds = [Number(form.DoctorIds)];
    } else {
      form.DoctorIds = form.DoctorIds.map(Number);
    }

    if (typeof form.HospitalIds == "string") {
      form.HospitalIds = [Number(form.HospitalIds)];
    } else {
      form.HospitalIds = form.HospitalIds.map(Number);
    }

    if (typeof form.ChemistIds == "string") {
      form.ChemistIds = [Number(form.ChemistIds)];
    } else {
      form.ChemistIds = form.ChemistIds.map(Number);
    }
    console.log(form);
    mutate(form);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Addedd Successfully", { position: "top-right" });
      fetch(true)
      handleClose()
      // navigate("/layout/mr/viewTourPlan");
    }
    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
        handleClose()
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);
  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      key={"Category"}
      size="xl"
    >
      <div className="modal-header">
        <h5 className="modal-title">Category</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((categoryinfo: any) => {
            onSubmit(categoryinfo);
          })}
        >
          {selectedCity.length != 0 || selectedRoute.length != 0 ? (
            <>
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Doctor
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="list-group">
                    <div className="ms-2 border col-sm-12 col-md-12 rounded overflow-auto">
                      <div className="tourplanscroller">
                        <div className="content">
                          {doctorOptions?.map((e: any, index) => {
                            return (
                              <div className="row mt-2" key={index}>
                                <div className="col-md-2 col-sm-2 col-2 mt-1">
                                  <div className="custom-control custom-checkbox mb-5">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`doctor` + index}
                                      {...register("DoctorIds")}
                                      value={e.DoctorId}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`doctor` + index}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-7 col-sm-6 col-8">
                                  <h2 className="col-form-label">
                                    Doctor Name : {e.DoctorName}
                                  </h2>
                                </div>
                                {/* <div className="col-md-3 col-sm-4 col-4 text-lg-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      setdid(e.DoctorId);
                                      setdoctorids(0);
                                    }}
                                  >
                                    Details
                                  </button>
                                </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  {" "}
                  Hospital{" "}
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="list-group">
                    <div className="border col-sm-12 col-md-12 rounded overflow-auto">
                      <div className="tourplanscroller">
                        <div className="content">
                          {hospitalOptions?.map((e: any, index) => {
                            return (
                              <div className="row mt-2" key={index}>
                                <div className="col-md-2 col-sm-2 col-2 mt-1">
                                  <div className="custom-control custom-checkbox mb-5">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`hospital` + index}
                                      {...register("HospitalIds")}
                                      value={e.HospitalId}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`hospital` + index}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-7 col-sm-6 col-8">
                                  <h2 className="col-form-label">
                                    Hospital Name : {e.HospitalName}
                                  </h2>
                                </div>
                                {/* <div className="col-md-3 col-sm-4 col-4 text-lg-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      sethid(e.HospitalId);

                                      sethospitalids(0);
                                    }}
                                  >
                                    Details
                                  </button>
                                </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Chemist
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="list-group">
                    <div className=" ms-2 border col-sm-12 col-md-12 rounded overflow-auto">
                      <div className="tourplanscroller">
                        <div className="content">
                          {chemistOptions?.map((e: any, index) => {
                            return (
                              <div className="row mt-2" key={index}>
                                <div className="col-md-2 col-sm-2 col-2 mt-1">
                                  <div className="custom-control custom-checkbox mb-5">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`chemist` + index}
                                      {...register("ChemistIds")}
                                      value={e.ChemistId}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`chemist` + index}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-7 col-sm-6 col-8">
                                  <h2 className="col-form-label">
                                    Chemist Name : {e.ChemistName}
                                  </h2>
                                </div>
                                {/* <div className="col-md-3 col-sm-4 col-4 text-lg-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      setcid(e.ChemistId);
                                      setchemistids(0);
                                    }}
                                  >
                                    Details
                                  </button>
                                </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="col text-center">
            <button
              className="btn btn-secondary btn-lg mx-2"
              onClick={handleClose}
            >
              Back
            </button>
            {selectedCity.length != 0 || selectedRoute.length != 0 ? (
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            ) : (
              <button type="submit" className="btn btn-success" disabled>
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};
