import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { AddEditConsultingFeeTypeModal } from "../../../master/submaster/consultingFeeType/AddEditConsultingFeeTypeModal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export default function AddEditConsultingFeesMRModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "ConsultingFeesType") {
      // fetchAOITOptions();
      setselectedfees(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname =
    process.env.REACT_APP_DOCTORCONSULTINFFEESFORMR_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (
          data.ConsultingFeesForMrtypeId !== null &&
          data.ConsultingFeesForMrtypeId !== undefined
        ) {
          setValue("ConsultingFeesForMrtypeId", {
            label: data.ConsultingFeesForMrtype,
            value: data.ConsultingFeesForMrtypeId,
          });
          setselectedfees({
            label: data.ConsultingFeesForMrtype,
            value: data.ConsultingFeesForMrtypeId,
          });
        } else {
          setValue("ConsultingFeesForMrtypeId", null);
          setselectedfees(null);
        }
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  const [selectedfees, setselectedfees]: any = useState(null);

  const [feesmr, setfeesmr]: any = useState(null);
  const [defaultValue, setdefaultValue]: any = useState(null);
  const handlefeesmrChange = (inputvalue: any) => {
    setfeesmr(0);
    setdefaultValue(inputvalue);
  };

  const consultingfeesData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.ConsultingFeesForMrtypeId = Number(selectedfees?.value);
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Consulting Fees (MR)`}
        modal
        style={{ width: "1000px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(consultingfeesData);
              }
            }}
            onSubmit={handleSubmit(consultingfeesData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-12"
                label="Type"
                // useCustomOption
                onDelete={(deletedValue: number) => {
                  deleteFunction("ConsultingFeesType", deletedValue);
                }}
                updateid={setfeesmr}
                control={control}
                name="ConsultingFeesForMrtypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ConsultingFeesForMrtypeId", e?.value);
                    setselectedfees(e);
                  } else {
                    setValue("ConsultingFeesForMrtypeId", null);
                    setselectedfees(null);
                  }
                }}
                value={selectedfees}
                api={process.env.REACT_APP_CONSULTINGFEESTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                handleCreate={handlefeesmrChange}
                errorMessage={
                  errors?.ConsultingFeesForMrtypeId?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForMrtypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Consulting Fees Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-12"
                label="Amount"
                type="text"
                isRequired
                errorMessage={
                  errors?.ConsultingFeesForMramount?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForMramount?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConsultingFeesForMramount", e.target.value);
                  clearErrors(["ConsultingFeesForMramount"]);
                }}
                register={{
                  ...register("ConsultingFeesForMramount", {
                    required: { value: true, message: "Amount is required" },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message: "Enter a valid amount (1-7 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-12"
                label="Valid Upto"
                type="text"
                isRequired
                errorMessage={
                  errors?.ConsultingFeesForMrvalidUpto?.message ||
                  error?.response?.data?.errors?.ConsultingFeesForMrvalidUpto?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConsultingFeesForMrvalidUpto", e.target.value);
                  clearErrors(["ConsultingFeesForMrvalidUpto"]);
                }}
                register={{
                  ...register("ConsultingFeesForMrvalidUpto", {
                    required: {
                      value: true,
                      message: "Valid Upto is required",
                    },
                    pattern: {
                      value: /^\w[A-Za-z0-9\s-]{0,10}$/,
                      message:
                        "Enter a valid valid upto (1-10 alphanumeric character (A-Za-z0-9))",
                    },
                  }),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}

        {feesmr !== null && (
          <AddEditConsultingFeeTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setfeesmr(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue("ConsultingFeesForMrtypeId", res.ConsultingFeesTypeId);
                setselectedfees({
                  label: res.ConsultingFeesTypeName,
                  value: res.ConsultingFeesTypeId,
                });
                // fetchAOITOptions();
              }
            }}
            open={feesmr !== null}
            id={feesmr}
          />
        )}
      </Dialog>
    </>
  );
}
