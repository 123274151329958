import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageHeader } from '../../../common/PageHeader'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link, useNavigate } from 'react-router-dom'
import { Badge } from 'primereact/badge'
import moment from 'moment'
import { Paginator } from 'primereact/paginator'
import { toast } from 'react-toastify'
import { PaginationServiceOfSalesReturnInvoice } from '../../../service/PaginationService'

const SalesReturnInvoice = () => {
  const navigate = useNavigate();
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfSalesReturnInvoice.getData(
        lazyState,
        controller.signal
      )
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller?.signal?.reason?.code == 20) {
            console.log("Request Canceled", error);
          } else {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3 text-center">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <DataTable value={data.SalesReturnInvoiceDetails} size="small">
          <Column field="ProductName" header="Product"></Column>
          <Column field="BatchNo" header="Batch"></Column>
          <Column field="Quantity" header="Qty"></Column>
          <Column field="SalePrice" header="Return Price"></Column>
          <Column field="MRP" header="MRP"></Column>
          <Column field="Discount" header="Discount %"></Column>
          <Column field="GSTPer" header="GST %"></Column>
          <Column field="Gstamount" header="GST Amt"></Column>
          <Column field="BillAmount" header="Bill Amt"></Column>
          <Column
            field="ManufactureDate"
            header="Mfg"
            body={(e: any) => {
              if (e.ManufactureDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"success"}
                      value={moment(e.ManufactureDate).format("DD/MM/YY")}
                    ></Badge>
                  </>
                );
              }
            }}
            bodyStyle={{ fontSize: "12px" }}
          ></Column>
          <Column
            field="ExpiryDate"
            header="Exp"
            body={(e: any) => {
              if (e.ExpiryDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"danger"}
                      value={moment(e.ExpiryDate).format("DD/MM/YY")}
                    ></Badge>
                  </>
                );
              }
            }}
            bodyStyle={{ fontSize: "12px" }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData: any) => {
    return rowData?.SalesReturnInvoiceDetails?.length > 0;
  };

  const nameDisplay = (e: any) => {
    let chemist = e?.ChemistName == null ? "" : `Chemist : ${e?.ChemistName}`;
    let stockist = e?.StockistName == null ? "" : `Stockist : ${e?.StockistName}`;
    let doctor = e?.DoctorName == null ? "" : `Doctor : ${e?.DoctorName}`;
    let customer = e?.CustomerName == null ? "" : `Customer : ${e?.CustomerName}`;

    return chemist + "" + stockist + "" + doctor + "" + customer;
  };

  return (
    <div>
      <Helmet>
        <title>Sales Return Register</title>
      </Helmet>

      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Sales Return Register</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/Inventory"}>Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sales Return Register
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column
                  expander={allowExpansion}
                  style={{ width: "2%", textAlign: "center" }}
                />
                <Column field="SrNo" header="#" style={{ width: "3rem" }} />
                <Column
                  field="InvoiceNumber"
                  header="Invoice No"
                  sortable
                ></Column>
                <Column
                  field="ReturnTypeName"
                  header="Return Type"
                  sortable
                ></Column>

                <Column
                  field="WarehouseName"
                  header="Warehouse"
                  sortable
                ></Column>
                <Column header="Name" body={nameDisplay}></Column>
                <Column
                  field="ExtraCharges"
                  header="Extra Charges"
                ></Column>
                <Column
                  field="TotalAmount"
                  header="Total Amt"
                  sortable
                ></Column>
                <Column field="Discount" header="Discount %"></Column>
                <Column
                  field="TotalDiscount"
                  header="Total Discount"
                ></Column>
                <Column
                  field="TotalGstamount"
                  header="Total GST Amt"
                ></Column>
                <Column
                  field="TotalBillAmount"
                  header="Total Bill Amt"
                ></Column>
                <Column
                  field="Note"
                  header="Note"
                  body={(data: any) => {
                    return (
                      <p
                        title={data.Note}
                        style={{
                          width: "100px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {data.Note === "" ? " - " : data.Note}
                      </p>
                    );
                  }}
                ></Column>
                <Column
                  header="Action"
                  body={(e: any) => {
                    return (
                      <>
                        <Link to={`/layout/viewinvoice/sr/${e.InvoiceId}`} target="_blank" >
                          <button type="button" className="btn p-0" title="Return Invoice">
                            <i className="bi bi-receipt text-success p-1" title="Return Invoice" />
                          </button>
                        </Link>
                        {/* <button type="button" className="btn p-0" title="Invoice">
                          <i
                            className="bi bi-receipt text-success p-1"
                            title="Invoice"
                            onClick={() => {
                              navigate(`/layout/viewinvoice`, {
                                state: {
                                  id: e.InvoiceId,
                                  type: "sr"
                                }
                              });
                            }}
                          />
                        </button> */}
                      </>
                    );
                  }}
                  style={{ width: "3%", textAlign: "center" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesReturnInvoice
