import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import Textarea from "../../../common/Textarea";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export default function AddEditGiftMasterModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_GIFTMASTER_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
    error,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: giftMasterLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error: giftError,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselecteddivision({
          label: data.DivisionName,
          value: data.DivisionId,
        });
      }

    }
  }, [data]);

  const [selecteddivision, setselecteddivision]: any = useState(null);

  const giftMasterFormData = (data: any) => {
    data.GiftPrice = Number(data.GiftPrice);

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }
    mutate(data);
  };

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  // Success & Error Messages
  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (giftError?.response?.data?.StatusCode !== 400) {
        toast.error(giftError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Gift Master`}
        modal
        style={{ width: "1250px" }}
      >
        {!giftMasterLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(giftMasterFormData);
              }
            }}
            onSubmit={handleSubmit(giftMasterFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Gift Name"
                isRequired
                type="text"
                register={{
                  ...register("GiftName", {
                    required: {
                      value: true,
                      message: "Gift Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s'-,]{0,50}$/,
                      message:
                        "Enter a valid gift name (1-50 alphabet characters only)",
                    },
                  }),
                }}
                onChange={(e: any) => { setValue("GiftName", e.target.value); clearErrors(["GiftName"]) }}
                errorMessage={
                  errors?.GiftName?.message ||
                  error?.response?.data?.errors?.GiftName?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Gift Price"
                isRequired
                type="text"
                register={{
                  ...register("GiftPrice", {
                    required: {
                      value: true,
                      message: "Gift Price is required",
                    },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message: "Enter a valid gift price (1-7 digits only)",
                    },
                  }),
                }}
                onChange={(e: any) => { setValue("GiftPrice", e.target.value); clearErrors(["GiftPrice"]) }}
                errorMessage={
                  errors?.GiftPrice?.message ||
                  error?.response?.data?.errors?.GiftPrice?.map((e: any) => e)
                }
              />

              <ReactSelect
                isRequired
                containerClass="col-lg-4"
                label="Division"
                name={"DivisionId"}

                onChange={(e: any) => {
                  if (e == null) {
                    setselecteddivision(null);
                    setValue("DivisionId", null);
                  }
                  else {

                    setselecteddivision(e);
                    setValue("DivisionId", e?.value);
                  }
                }}
                value={selecteddivision}
                control={control}
                api={process.env.REACT_APP_DIVISION_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DivisionId?.message ||
                  error?.response?.data?.errors?.DivisionId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Division is required",
                  },
                }}
              />

              <div className="col-lg-8">
                <div className="form-group">
                  <label className="col-form-label">Type of Gift</label>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="custom-control custom-checkbox pt-1">
                        <input
                          {...register("DoctorGift", {})}
                          id="customCheck1"
                          type="checkbox"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          Doctor Gift
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="custom-control custom-checkbox pt-1">
                        <input
                          {...register("ChemistGift", {})}
                          id="customCheck2"
                          type="checkbox"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck2"
                        >
                          Chemist Gift
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="custom-control custom-checkbox pt-1">
                        <input
                          {...register("StockistGift", {})}
                          id="customCheck3"
                          type="checkbox"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck3"
                        >
                          Stockist Gift
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="custom-control custom-checkbox pt-1">
                        <input
                          {...register("EmployeeGift", {})}
                          id="customCheck4"
                          type="checkbox"
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck4"
                        >
                          Employee Gift
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Textarea
                containerClass="col-lg-12"
                label="Remark"
                register={{
                  ...register("Remark"),
                }}
                maxLength={500}
                onChange={(e: any) => { setValue("Remark", e.target.value); clearErrors(["Remark"]) }}
                errorMessage={
                  errors?.Remark?.message ||
                  error?.response?.data?.errors?.Remark?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselecteddivision(null); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
