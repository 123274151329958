import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import Textarea from "../../../../common/Textarea";
import { Spinner } from "../../../../common/Spinner";
import { AddEditDoctorAreaOfInterestTypeModal } from "../../../master/submaster/doctorAreaOfInterestType/AddEditDoctorAreaOfInterestTypeModal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export default function AddEditAreaofinterestModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "DoctorAreaOfInterestType") {
      setselectedareaofinterest(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname = process.env.REACT_APP_DOCTORAREAOFINTEREST_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (
          data.AreaOfInterestTypeId !== null &&
          data.AreaOfInterestTypeId !== undefined
        ) {
          setValue("AreaOfInterestTypeId", {
            label: data.AreaOfInterestTypeName,
            value: data.AreaOfInterestTypeId,
          });
          setselectedareaofinterest({
            label: data.AreaOfInterestTypeName,
            value: data.AreaOfInterestTypeId,
          });
        } else {
          setValue("AreaOfInterestTypeId", null);
          setselectedareaofinterest(null);
        }
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedareaofinterest, setselectedareaofinterest]: any =
    useState(null);

  const [areaOfIntrest, setAreaOfIntrest]: any = useState(null);
  const [defaultValue, setdefaultvalue]: any = useState(null);

  const areaofinterestFormData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.AreaOfInterestTypeId = Number(selectedareaofinterest?.value);

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Area of Interest`}
        modal
        style={{ width: "1000px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(areaofinterestFormData);
              }
            }}
            onSubmit={handleSubmit(areaofinterestFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-12"
                label="Area Of Interest In Medical Field"
                control={control}
                name="AreaOfInterestTypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("AreaOfInterestTypeId", e?.value);
                    setselectedareaofinterest(e);
                  } else {
                    setValue("AreaOfInterestTypeId", null);
                    setselectedareaofinterest(null);
                  }
                }}
                value={selectedareaofinterest}
                api={process.env.REACT_APP_DOCTORAREAOFINTERESTTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.AreaOfInterestTypeId?.message ||
                  error?.response?.data?.errors?.AreaOfInterestTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Area Of Interest Type is required",
                  },
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={1000}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                register={{
                  ...register("Remarks"),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedareaofinterest(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}

        {areaOfIntrest !== null && (
          <AddEditDoctorAreaOfInterestTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setAreaOfIntrest(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue(
                  "AreaOfInterestTypeId",
                  res.DoctorAreaOfInterestTypeId
                );
                setselectedareaofinterest({
                  label: res.DoctorAreaOfInterestTypeName,
                  value: res.DoctorAreaOfInterestTypeId,
                });
                // fetchAOITOptions();
              }
            }}
            open={areaOfIntrest !== null}
            id={areaOfIntrest}
          />
        )}
      </Dialog>
    </>
  );
}
