import AdminPanelCard from "../common/AdminPanelCard";
import { Helmet } from "react-helmet";

export const Inventory = () => {
  let admincard = [
    {
      name: "Master",
      link: "/layout/InventoryMaster",
    },
    {
      name: "Invoice",
      link: "/layout/inventory/Invoice",
    },
    {
      name: "Account Invoice",
      link: "/layout/inventory/GetAccountsInvoice",
    },
    {
      name: "Purchase Invoice",
      link: "/layout/inventory/PurchaseInvoice",
    },
    {
      name: "Sales Invoice",
      link: "/layout/inventory/SalesInvoice",
    },
    {
      name: "Vendor Credit Note",
      link: "/layout/inventory/VendorCreditNote",
    },
    {
      name: "Customer Credit Note",
      link: "/layout/inventory/CustomerCreditNote",
    },
    {
      name: "Sales Return Register",
      link: "/layout/inventory/SalesReturnInvoice",
    },
    {
      name: "Purchase Return Register",
      link: "/layout/inventory/PurchaseReturnInvoice",
    },
    {
      name: "Warehouse Stock",
      link: "/layout/inventory/WarehouseStock",
    },
    {
      name: "Warehouse Product History",
      link: "/layout/inventory/WarehouseProductHistory",
    },
    {
      name: "Product",
      link: "/layout/Product",
    },
    {
      name: "Sample Request",
      link: "/layout/inventory/sampleRequest",
    },
    {
      name: "Issued Sample",
      link: "/layout/Inventory/IssuedSample",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Inventory</title>
      </Helmet>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard name={card?.name} link={card?.link} />
          </div>
        ))}
      </div>
    </>
  );
};
