interface iPropsalert {
    color?: string;
    message?: string;
  }
  
  export const Alert = ({ color, message }: iPropsalert) => {
    return (
      <>
        <div
          className={`alert alert-${color} alert-dismissible fade show`}
          role="alert"
        >
          <strong>{message}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </>
    );
  };