import { useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";

const Credit = () => {
    const apiname = process.env.REACT_APP_DOCTORCREDIT_API?.toString();
    const [bool, setbool]: any = useState(null);

    const [deleteID, setdeleteID]: any = useState();
    const setDeleteidtoDelete = (id: any) => {
        setdeleteID(id);
    };

    const columns = [
        {
            header: "Action",
            body: (e: any) => actioncrmAccountTemplate(e),
            sortable: false,
            width:"2vw",
        },
        {
            field: "CreditDateTime",
            header: "CreditDateTime",
            body: (e: any) => actionDateTemplate(e),
            sortable: true,
            width:"10vw",
        },
        {
            field: "CreditValue",
            header: "CreditValue",
            body: null,
            sortable: true,
            width:"10vw",
        },
       
    ];

    const actionDateTemplate = (id: any) => {
        return id.CreditDateTime?.split("T")[0];
    };

    const actioncrmAccountTemplate = (id: any) => {
        return (
            <>
                <Action
                    id={id.DoctorCreditId}
                    editurl={"layout/crm/Editcrmaccount"}
                    displayid={setDeleteidtoDelete}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>Credit</title>
            </Helmet>
            <div className="min-height-200px">
                <PageHeader headTitle="Account" list={true} LinkURL={"/layout/crm/Addcrmaccount"} />
                <DatatableCommon
                    apiname={apiname}
                    columnData={columns}
                    refetchData={bool}
                    setrefetchData={setbool}
                // check={true}
                />
            </div>
            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => { if (data) { setbool(true) } }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />
        </>
    )
}

export default Credit
