import AdminPanelCard from "../common/AdminPanelCard";
import TourImg from "../assets/images/tour 2.png";
import leaveImg from "../assets/images/Group (2).png";
import ExpenceImg from "../assets/images/Group 1.png";
import lockunlock from "../assets/images/unlock-svgrepo-com.png";
import { Helmet } from "react-helmet";

export const Approvals = () => {
  let admincard = [
    {
      name: "Monthly Tour Plan",
      link: "/layout/mr/admin/tourplan",
      img: TourImg,
    },
    {
      name: "Daily Tour Plan",
      link: "/layout/mr/admin/dailyplan",
      img: TourImg,
    },
    {
      name: "Leave",
      link: "/layout/mr/admin/adminLeave",
      img: leaveImg,
    },
    {
      name: "Allowance / Expense",
      link: "/layout/mr/admin/adminExpense",
      img: ExpenceImg,
    },
    {
      name: "Lock / Unlock Tour Plan",
      link: "/layout/mr/admin/adminlockunlock",
      img: lockunlock,
    },
    {
      name: "Doctor Availibility",
      link: "/layout/mr/admin/adminDoctorAvailibilty",
      img: leaveImg,
    },
    {
      name: "Deleted Approval Doctor",
      link: "/layout/approvalForDeleted/ApprovalForDeleted",
    },
    {
      name: "Deleted Approval Chemist",
      link: "/layout/approvalForDeleted/ApprovalForDeletedChemist",
    },
    {
      name: "Deleted Approval Stockist",
      link: "/layout/approvalForDeleted/ApprovalForDeletedStockist",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Approvals</title>
      </Helmet>
      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard
              name={card?.name}
              link={card?.link}
              imageSrc={card?.img}
            />
          </div>
        ))}
      </div>
    </>
  );
};
