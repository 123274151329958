import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useState } from "react";
import ReactSelect from "./ReactSelect";
import { Input } from "./Input";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Badge } from "primereact/badge";
import noimage from "../assets/images/NoImage.jpg";

interface iPropspurchasesale {
  type?: any;
  purchaseDetailArray?: any;
  setPurchaseDetailArray?: any;
  activeIndex?: any;
  onTabChange?: any;
  selectedwarehouse?: any;
  productIndex?: boolean;
  SetAlertIndex?: any;
  Party?: any;
}

const PurchaseSaleInvoiceCommon = ({
  type,
  purchaseDetailArray,
  setPurchaseDetailArray,
  activeIndex,
  onTabChange,
  selectedwarehouse,
  SetAlertIndex,
  Party = null
}: iPropspurchasesale) => {
  console.log(Party, "part")
  console.log(selectedwarehouse, "selectedwarehouse")
  const {
    register: purchaseRegister,
    handleSubmit: purchaseHandleSubmit,
    formState: { errors: purchaseErrors },
    setValue: purchaseSetValue,
    control: purchaseControl,
    clearErrors: purchaseClearErrors,
    reset: purchaseReset,
    getValues: purchaseGetValues,
    watch: purchaseWatch,
  }: any = useForm();

  let temp = purchaseWatch();

  console.log(temp, "this is temp");

  const [purchaseDetailEditIndex, setPurchaseDetailEditIndex]: any =
    useState(null);
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [selectedBatchNo, setSelectedBatchNo]: any = useState(null);

  console.log(selectedBatchNo, "selectedBatchNo");

  let [editState, setEditState] = useState(null);

  useEffect(() => {
    if (editState !== null) {
      SetAlertIndex(true);
    } else {
      SetAlertIndex(false);
    }
  }, [editState]);

  const onPurchaseDetailSubmit = (data: any) => {
    debugger;
    if (purchaseDetailEditIndex !== null) {
      let updateData = purchaseDetailArray.map((e: any, i: any) => {
        debugger;
        // if (i == purchaseDetailEditIndex) {
        //   data.No = i + 1;
        // }
        if (i == purchaseDetailEditIndex) {
          let temp = e;
          temp.No = i + 1;
          temp.Quantity = data.Quantity;
          temp.SalePrice = data.SalePrice;
          temp.Discount = data.Discount;
          return temp;
        } else {
          return e;
        }
        // return i == purchaseDetailEditIndex ? data : e;
      });

      setEditState(null);
      setPurchaseDetailArray(updateData);
      setPurchaseDetailEditIndex(null);
    } else {
      if (purchaseDetailArray !== undefined) {
        data.No = purchaseDetailArray?.length + 1;
      } else {
        data.No = 1;
      }
      setPurchaseDetailArray((prevData: any) => {
        return Array.isArray(prevData) ? [...prevData, data] : [data];
      });
    }

    purchaseReset({
      ProductId: setSelectedProduct(null),
      Quantity: null,
      BatchNo:
        type === "purchase" || type === "purchasereturn"
          ? null
          : setSelectedBatchNo(null),
      SampleQuantity: null,
      ExpiryDate: null,
      Discount: null,
      MRP: null,
      PurchasePrice: null,
      ManufactureDate: null,
      FreeQty: null,
      SalePrice: null,
    });
  };

  const onPurchaseDetailEdit = (index: any) => {
    let item = purchaseDetailArray[index - 1];
    setPurchaseDetailEditIndex(index - 1);

    if (type === "sale" || type === "purchase") {
      purchaseSetValue("ProductId", item?.ProductId);
      setSelectedProduct(item?.ProductId);
      purchaseSetValue("BatchNo", item?.BatchNo);
      purchaseSetValue("SampleQuantity", item?.SampleQuantity);
      purchaseSetValue("ExpiryDate", item?.ExpiryDate);
      purchaseSetValue("ManufactureDate", item?.ManufactureDate);
      purchaseSetValue("Discount", item?.Discount);
      purchaseSetValue("MRP", item?.MRP);
      purchaseSetValue("FreeQty", item?.FreeQty);
      purchaseSetValue("SalePrice", item?.SalePrice);
      purchaseSetValue("Quantity", item?.Quantity);
      purchaseSetValue("PurchasePrice", item?.PurchasePrice);
    } else {
      setEditState(index);
      purchaseSetValue("ProductId", item?.ProductId);
      setSelectedProduct(item?.ProductId);
      purchaseSetValue("BatchNo", item?.BatchNo);
      setSelectedBatchNo(item?.BatchNo);
      purchaseSetValue("SampleQuantity", item?.SampleQuantity);
      // purchaseSetValue("ExpiryDate", item?.ExpiryDate);
      // purchaseSetValue("ManufactureDate", item?.ManufactureDate);
      purchaseSetValue("Discount", 0);
      // purchaseSetValue("MRP", item?.MRP);
      purchaseSetValue("FreeQty", item?.FreeQty);
      purchaseSetValue("SalePrice", item?.SalePrice);
      purchaseSetValue("Quantity", item?.Quantity);
      purchaseSetValue("PurchasePrice", item?.PurchasePrice);
    }
  };

  const onPurchaseDetailDelete = (index: any) => {
    let temp = purchaseDetailArray.filter((e: any) => e.No != index);
    let new_temp = temp.map((e: any, i: number) => {
      return { ...e, No: i + 1 };
    });
    setPurchaseDetailArray(new_temp);
  };

  const purchaseAction = (id: any) => {
    return (
      <>
        {editState !== id.No ? (
          <>
            <button type="button" className="btn p-0" title="View">
              <i
                className="pi pi-eye mr-1 text-primary"
                onClick={() => onPurchaseDetailEdit(id.No)}
              ></i>
            </button>
            <button type="button" className="btn p-0" title="Delete">
              <i
                className="pi pi-trash mr-1 text-danger"
                onClick={() => onPurchaseDetailDelete(id.No)}
              ></i>
            </button>
          </>
        ) : (
          <>
            <button type="button" className="btn p-0" title="Update">
              <i
                className="pi pi-pencil mr-1 text-primary"
                onClick={purchaseHandleSubmit(onPurchaseDetailSubmit)}
              ></i>
            </button>
            <button type="button" className="btn p-0" title="Update">
              <i
                className="pi pi-times mr-1 text-danger"
                onClick={() => {
                  setEditState(null)
                }}
              ></i>
            </button>

          </>
        )}
      </>
    );
  };

  useEffect(() => {
    console.log(Party, selectedBatchNo, "Batch And Party")
    if (type === "sales" || type === "salesreturn") {
      if (selectedBatchNo !== null) {
        purchaseSetValue(
          "ExpiryDate",
          selectedBatchNo?.value?.expirydate.split("T")[0]
        );
        purchaseSetValue(
          "ManufactureDate",
          selectedBatchNo?.value?.manufacturedate.split("T")[0]
        );
        purchaseSetValue("MRP", selectedBatchNo?.value?.MRP);
        if (Party !== null && selectedBatchNo !== null) {
          if (type === "sales") {
            if (Party?.value == "Stockist") {
              console.log(selectedBatchNo, "selectedBatchNo")
              purchaseSetValue("SalePrice", Number(selectedBatchNo.value.PTS));
            }
            else {
              purchaseSetValue("SalePrice", Number(selectedBatchNo.value.PTR));

            }

          }
        }
        else {
          if (type === "sales") {
            purchaseSetValue("SalePrice", null);
          }
        }
      }
      else {
        purchaseSetValue(
          "ExpiryDate",
          null
        );
        purchaseSetValue(
          "ManufactureDate",
          null
        );
        if (type === "sales") {
          purchaseSetValue("SalePrice", null);
        }
      }

    }
  }, [selectedBatchNo, Party]);

  useEffect(() => {
    debugger;
    if (type === "sales" || type === "salesreturn") {
      if (selectedProduct) {
        if (editState == null) {
          setSelectedBatchNo(null);
          purchaseSetValue("ExpiryDate", null);
          purchaseSetValue("ManufactureDate", null);
          purchaseSetValue("MRP", null);
        }
      }
    }

    if (selectedProduct !== null || selectedProduct?.value !== undefined) {
      SetAlertIndex(true);
    } else {
      SetAlertIndex(false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedwarehouse) {
      setSelectedProduct(null);
      setSelectedBatchNo(null);
      purchaseSetValue("ExpiryDate", null);
      purchaseSetValue("ManufactureDate", null);
      purchaseSetValue("MRP", null);
    }
  }, [selectedwarehouse]);

  return (
    <>
      <Accordion activeIndex={activeIndex} onTabChange={onTabChange}>
        <AccordionTab
          header={
            type === "sales"
              ? "Sales Invoice Details"
              : type === "purchase"
                ? "Purchase Invoice Details"
                : type === "purchasereturn"
                  ? "Purchase Return Invoice Details"
                  : type === "salesreturn"
                    ? "Sales Return Invoice Details"
                    : ""
          }
        >
          {(type === "purchase" || type === "sales") && (
            <div className="row">
              {type === "purchase" && (
                <ReactSelect
                  containerClass="col-lg-3"
                  control={purchaseControl}
                  isRequired
                  api={process.env.REACT_APP_PRODUCT_API}
                  keys={{ label: "Value", value: "Id" }}
                  onChange={(e: any) => {
                    purchaseSetValue("ProductId", e);
                    setSelectedProduct(e);
                  }}
                  value={selectedProduct}
                  name="ProductId"
                  label="Product"
                  rules={{
                    required: {
                      value: true,
                      message: "Product is required",
                    },
                  }}
                  errorMessage={purchaseErrors?.ProductId?.message}
                />
              )}

              {type === "sales" && (
                <ReactSelect
                  containerClass="col-lg-3"
                  control={purchaseControl}
                  isRequired
                  api={
                    (selectedwarehouse != null &&
                      selectedwarehouse?.value !== undefined)
                      ? `${process.env.REACT_APP_WAREHOUSESTOCKTRANSFER_API}?warehouseId=${selectedwarehouse?.value}`
                      : ""
                  }
                  keys={{ label: "Value", value: "Id" }}
                  onChange={(e: any) => {
                    purchaseSetValue("ProductId", e);
                    setSelectedProduct(e);
                  }}
                  value={selectedProduct}
                  name="ProductId"
                  label="Product"
                  rules={{
                    required: {
                      value: true,
                      message: "Product is required",
                    },
                  }}
                  Disabled={
                    selectedwarehouse != null ||
                      selectedwarehouse?.value !== undefined
                      ? false
                      : true
                  }
                  errorMessage={purchaseErrors?.ProductId?.message}
                />
              )}

              {type === "sales" && (
                <ReactSelect
                  containerClass="col-lg-3"
                  control={purchaseControl}
                  isRequired
                  api={
                    (selectedProduct != null && selectedwarehouse?.value !== undefined)
                      ? `${process.env.REACT_APP_WAREHOUSESTOCKTRANSFERGETALLBATCH_API}?warehouseId=${selectedwarehouse?.value}&ProductId=${selectedProduct?.value}`
                      : ""
                  }
                  keys={{ label: "Value", value: "Id" }}
                  onChange={(e: any) => {
                    purchaseSetValue("BatchNo", e);
                    setSelectedBatchNo(e);
                  }}
                  value={selectedBatchNo}
                  name="BatchNo"
                  label="Batch No. - (Expiry Date)"
                  rules={{
                    required: {
                      value: true,
                      message: "Batch No is required",
                    },
                  }}
                  Disabled={selectedProduct != null ? false : true}
                  errorMessage={purchaseErrors?.BatchNo?.message}
                />
              )}

              {type === "purchase" && (
                <Input
                  isRequired
                  containerClass="col-lg-3"
                  label="Batch No."
                  type="text"
                  onChange={(e: any) => {
                    purchaseSetValue("BatchNo", e.target.value);
                    purchaseClearErrors(["BatchNo"]);
                  }}
                  register={{
                    ...purchaseRegister(`BatchNo`, {
                      required: {
                        value: true,
                        message: "Batch No. is required",
                      },
                    }),
                  }}
                  errorMessage={purchaseErrors?.BatchNo?.message}
                />
              )}

              {type === "sales" && (
                <Input
                  isRequired
                  min={0}
                  containerClass="col-lg-3"
                  label="Price"
                  type="number"
                  onChange={(e: any) => {
                    purchaseSetValue("SalePrice", Number(e.target.value));
                    purchaseClearErrors(["SalePrice"]);
                  }}
                  register={{
                    ...purchaseRegister(`SalePrice`, {
                      required: {
                        value: true,
                        message: "Price is required",
                      }
                    }),
                  }}
                  errorMessage={purchaseErrors?.SalePrice?.message}
                />
              )}

              {type === "purchase" && (
                <Input
                  min={0}
                  isRequired
                  containerClass="col-lg-3"
                  label="Price"
                  type="number"
                  onChange={(e: any) => {
                    purchaseSetValue("PurchasePrice", Number(e.target.value));
                    purchaseClearErrors(["PurchasePrice"]);
                  }}
                  register={{
                    ...purchaseRegister(`PurchasePrice`, {
                      required: {
                        value: true,
                        message: "Price is required",
                      },
                    }),
                  }}
                  errorMessage={purchaseErrors?.PurchasePrice?.message}
                />
              )}

              {type === "sales" && (
                <Input
                  containerClass="col-lg-3"
                  label={`Quantity${selectedBatchNo !== null
                    ? ` - (Available Stock : ${selectedBatchNo?.value?.quantity})`
                    : ""
                    }`}
                  isRequired
                  type="number"
                  min={0}
                  onChange={(e: any) => {
                    purchaseSetValue("Quantity", Number(e.target.value));
                    purchaseClearErrors(["Quantity"]);
                  }}
                  register={{
                    ...purchaseRegister(`Quantity`, {
                      required: {
                        value: true,
                        message: "Quantity is required",
                      },
                      validate: (value: any) =>
                        value <=
                        (purchaseGetValues("FreeQty") !== undefined
                          ? selectedBatchNo?.value?.quantity -
                          purchaseGetValues("FreeQty")
                          : selectedBatchNo?.value?.quantity) ||
                        `Value should be less or equal to ${purchaseGetValues("FreeQty") !== undefined
                          ? selectedBatchNo?.value?.quantity -
                          purchaseGetValues("FreeQty")
                          : selectedBatchNo?.value?.quantity
                        }`,
                    }),
                  }}
                  errorMessage={purchaseErrors?.Quantity?.message}
                />
              )}

              {type === "purchase" && (
                <Input
                  isRequired
                  containerClass="col-lg-3"
                  label="Quantity"
                  min={0}
                  type="number"
                  onChange={(e: any) => {
                    purchaseSetValue("Quantity", Number(e.target.value));
                    purchaseClearErrors(["Quantity"]);
                  }}
                  register={{
                    ...purchaseRegister(`Quantity`, {
                      required: {
                        value: true,
                        message: "Quantity is required",
                      },
                    }),
                  }}
                  errorMessage={purchaseErrors?.Quantity?.message}
                />
              )}

              {type === "purchase" && (
                <Input
                  min={0}
                  containerClass="col-lg-3"
                  label="Sample Quantity"
                  type="number"
                  onChange={(e: any) => {
                    purchaseSetValue("SampleQuantity", Number(e.target.value));
                    purchaseClearErrors(["SampleQuantity"]);
                  }}
                  register={{
                    ...purchaseRegister(`SampleQuantity`),
                  }}
                  errorMessage={purchaseErrors?.SampleQuantity?.message}
                />
              )}

              {type === "sales" && (
                <Input
                  containerClass="col-lg-3"
                  label="Free Quantity"
                  type="text"
                  min={0}
                  onChange={(e: any) => {
                    purchaseSetValue("FreeQty", Number(e.target.value));
                    purchaseClearErrors(["FreeQty"]);
                  }}
                  register={{
                    ...purchaseRegister(`FreeQty`, {
                      validate: (value: any) =>
                        value <=
                        (purchaseGetValues("Quantity") !== undefined
                          ? selectedBatchNo?.value?.quantity -
                          purchaseGetValues("Quantity")
                          : selectedBatchNo?.value?.quantity) ||
                        `Value should be less or equal to ${purchaseGetValues("Quantity") !== undefined
                          ? selectedBatchNo?.value?.quantity -
                          purchaseGetValues("Quantity")
                          : selectedBatchNo?.value?.quantity
                        }`,
                    }),
                  }}
                  errorMessage={purchaseErrors?.FreeQty?.message}
                />
              )}

              <Input
                containerClass="col-lg-3"
                label="Discount %"
                type="number"
                min={0}
                onChange={(e: any) => {
                  purchaseSetValue("Discount", Number(e.target.value));
                  purchaseClearErrors(["Discount"]);
                }}
                register={{
                  ...purchaseRegister(`Discount`, {
                    validate: (value: any) =>
                      value <= 100 || `Value should be less or equal to 100`,
                  }),
                }}
                errorMessage={purchaseErrors?.Discount?.message}
              />

              {type === "purchase" && (
                <Input
                  containerClass="col-lg-3"
                  label="Manufacture Date"
                  type="date"
                  isRequired
                  errorMessage={purchaseErrors?.ManufactureDate?.message}
                  onChange={(e: any) => {
                    purchaseSetValue("ManufactureDate", e.target.value);
                    purchaseClearErrors(["ManufactureDate"]);
                  }}
                  register={{
                    ...purchaseRegister("ManufactureDate", {
                      required: {
                        value: true,
                        message: "Manufacture Date is required",
                      },
                    }),
                  }}
                />
              )}

              {type === "sales" && (
                <Input
                  containerClass="col-lg-3"
                  label="Manufacture Date"
                  type="date"
                  isRequired
                  disabled
                  errorMessage={purchaseErrors?.ManufactureDate?.message}
                  onChange={(e: any) => {
                    purchaseSetValue("ManufactureDate", e.target.value);
                    purchaseClearErrors(["ManufactureDate"]);
                  }}
                  register={{
                    ...purchaseRegister("ManufactureDate", {
                      required: {
                        value: true,
                        message: "Manufacture Date is required",
                      },
                    }),
                  }}
                />
              )}

              {type === "purchase" && (
                <Input
                  containerClass="col-lg-3"
                  label="Expiry Date"
                  type="date"
                  isRequired
                  errorMessage={purchaseErrors?.ExpiryDate?.message}
                  onChange={(e: any) => {
                    purchaseSetValue("ExpiryDate", e.target.value);
                    purchaseClearErrors(["ExpiryDate"]);
                  }}
                  register={{
                    ...purchaseRegister("ExpiryDate", {
                      required: {
                        value: true,
                        message: "Expiry Date is required",
                      },
                    }),
                  }}
                />
              )}

              {type === "sales" && (
                <Input
                  containerClass="col-lg-3"
                  label="Expiry Date"
                  type="date"
                  isRequired
                  disabled
                  errorMessage={purchaseErrors?.ExpiryDate?.message}
                  onChange={(e: any) => {
                    purchaseSetValue("ExpiryDate", e.target.value);
                    purchaseClearErrors(["ExpiryDate"]);
                  }}
                  register={{
                    ...purchaseRegister("ExpiryDate", {
                      required: {
                        value: true,
                        message: "Expiry Date is required",
                      },
                    }),
                  }}
                />
              )}

              {type === "sales" && (
                <Input
                  isRequired
                  containerClass="col-lg-3"
                  label="MRP"
                  type="number"
                  min={0}
                  disabled
                  onChange={(e: any) => {
                    purchaseSetValue("MRP", Number(e.target.value));
                    purchaseClearErrors(["MRP"]);
                  }}
                  register={{
                    ...purchaseRegister(`MRP`, {
                      required: {
                        value: true,
                        message: "MRP is required",
                      },
                    }),
                  }}
                  errorMessage={purchaseErrors?.MRP?.message}
                />
              )}

              {type === "purchase" && (
                <Input
                  isRequired
                  containerClass="col-lg-3"
                  label="MRP"
                  min={0}
                  type="number"
                  onChange={(e: any) => {
                    purchaseSetValue("MRP", Number(e.target.value));
                    purchaseClearErrors(["MRP"]);
                  }}
                  register={{
                    ...purchaseRegister(`MRP`, {
                      required: {
                        value: true,
                        message: "MRP is required",
                      },
                    }),
                  }}
                  errorMessage={purchaseErrors?.MRP?.message}
                />
              )}

              {type === "purchase" && selectedProduct !== null && (
                <div className="col-lg-3">
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={
                        selectedProduct?.value?.QRCodeName === "null"
                          ? noimage
                          : selectedProduct?.value?.imagepath
                      }
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-3 text-left mt-4" style={{ paddingTop: "13px" }}>
                <button
                  type="submit"
                  className="btn btn-outline-success"
                  onClick={purchaseHandleSubmit(onPurchaseDetailSubmit)}
                >
                  {purchaseDetailEditIndex != null ? "Update" : "Add"}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ml-2"
                  onClick={() => {
                    purchaseReset({
                      ProductId: setSelectedProduct(null),
                      Quantity: null,
                      BatchNo:
                        type === "purchase" || type === "purchasereturn"
                          ? null
                          : setSelectedBatchNo(null),
                      SampleQuantity: null,
                      ExpiryDate: null,
                      Discount: null,
                      MRP: null,
                      PurchasePrice: null,
                      ManufactureDate: null,
                      FreeQty: null,
                      SalePrice: null,
                    });
                    // setSelectedProduct(null);
                    // setSelectedBatchNo(null);
                  }}
                >
                  Cancel
                </button>
              </div>

              {/* <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary mt-1"
                  onClick={purchaseHandleSubmit(onPurchaseDetailSubmit)}
                >
                  {purchaseDetailEditIndex != null ? "Update" : "Add"}
                </button>
              </div> */}
            </div>
          )}

          {purchaseDetailArray?.length > 0 && (
            <>
              <DataTable
                dataKey="id"
                stripedRows
                value={purchaseDetailArray}
                showGridlines
                size="small"
                style={{ marginTop: "15px" }}
              >
                <Column
                  field="ProductId"
                  header="Product"
                  body={(e: any) => {
                    let temp = e?.ProductId?.label;
                    return temp;
                  }}
                  style={{ width: "20%" }}
                />
                <Column
                  field="BatchNo"
                  header="Batch"
                  style={{ width: "10%" }}
                  body={(e: any) => {
                    if (type === "sales") {
                      return e.BatchNo.label;
                    } else {
                      return e.BatchNo;
                    }
                  }}
                />

                {/* <Column
                  field={
                    type === "sales" || type === "salesreturn"
                      ? "FreeQty"
                      : "SampleQuantity"
                  }
                  header={
                    type === "sales" || type === "salesreturn"
                      ? "Free Quantity"
                      : "Sample Quantity"
                  }
                  style={{ width: "10%" }}
                /> */}

                <Column
                  field="ManufactureDate"
                  header="Mfg"
                  style={{ width: "10%" }}
                  body={(data: any) => {
                    if (data.ManufactureDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"success"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                />

                <Column
                  field="ExpiryDate"
                  header="Exp"
                  style={{ width: "10%" }}
                  body={(data: any) => {
                    if (data.ExpiryDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"danger"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ExpiryDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                />

                <Column
                  field="Quantity"
                  header="Qty"
                  style={{ width: "10%" }}
                  body={(e: any) => {
                    return type === "sales" || type === "purchase" ? (
                      e?.Quantity
                    ) : editState == e.No ? (
                      <>
                        <Input
                          isRequired
                          containerClass="col-lg-12 mt-4"
                          type="number"
                          min={0}
                          onChange={(e: any) => {
                            purchaseSetValue(
                              "Quantity",
                              Number(e.target.value)
                            );
                            purchaseClearErrors(["Quantity"]);
                          }}
                          register={{
                            ...purchaseRegister(`Quantity`, {
                              required: {
                                value: true,
                                message: "Quantity is required",
                              },
                              validate: (value: any) =>
                                value <= e.Quantity ||
                                `Value should be less or equal to ${e.Quantity}`,
                            }),
                          }}
                          errorMessage={purchaseErrors?.Quantity?.message}
                        />
                      </>
                    ) : (
                      e?.Quantity
                    );
                  }}
                />

                <Column
                  header={
                    type === "sales" || type === "salesreturn"
                      ? "Sale Price"
                      : "Purchase Price"
                  }
                  body={(e: any) => {
                    if (type === "purchase" || type === "purchasereturn") {
                      if (type === "purchase") {
                        return e.PurchasePrice;
                      } else {
                        if (editState == e.No) {
                          return (
                            <>
                              <Input
                                isRequired
                                containerClass="col-lg-12 mt-4"
                                type="number"
                                onChange={(e: any) => {
                                  purchaseSetValue(
                                    "PurchasePrice",
                                    Number(e.target.value)
                                  );
                                  purchaseClearErrors(["PurchasePrice"]);
                                }}
                                register={{
                                  ...purchaseRegister(`PurchasePrice`, {
                                    required: {
                                      value: true,
                                      message: "Price is required",
                                    },
                                    validate: (value: any) =>
                                      value <= e.PurchasePrice ||
                                      `Value should be less or equal to ${e.PurchasePrice}`,
                                  }),
                                }}
                                errorMessage={
                                  purchaseErrors?.PurchasePrice?.message
                                }
                              />
                            </>
                          );
                        } else {
                          return e.PurchasePrice;
                        }
                      }
                    } else {
                      if (type === "sales") {
                        return e.SalePrice;
                      } else {
                        if (editState == e.No) {
                          return (
                            <>
                              <Input
                                isRequired
                                containerClass="col-lg-12 mt-4"
                                type="number"
                                onChange={(e: any) => {
                                  purchaseSetValue(
                                    "SalePrice",
                                    Number(e.target.value)
                                  );
                                  purchaseClearErrors(["SalePrice"]);
                                }}
                                register={{
                                  ...purchaseRegister(`SalePrice`, {
                                    required: {
                                      value: true,
                                      message: "Sale Price is required",
                                    },
                                    validate: (value: any) =>
                                      value <= e.SalePrice ||
                                      `Value should be less or equal to ${e.SalePrice}`,
                                  }),
                                }}
                                errorMessage={
                                  purchaseErrors?.SalePrice?.message
                                }
                              />
                            </>
                          );
                        } else {
                          return e.SalePrice;
                        }
                      }
                    }
                  }}
                  style={{ width: "10%" }}
                />
                <Column field="MRP" header="MRP" style={{ width: "10%" }} />
                <Column
                  field="Discount"
                  header="Discount %"
                  style={{ width: "10%" }}
                  body={(e: any) => {
                    return type === "sales" || type === "purchase" ? (
                      e?.Discount
                    ) : editState == e.No ? (
                      <>
                        <Input
                          isRequired
                          containerClass="col-lg-12 mt-4"
                          type="number"
                          onChange={(e: any) => {
                            purchaseSetValue(
                              "Discount",
                              Number(e.target.value)
                            );
                            purchaseClearErrors(["Discount"]);
                          }}
                          register={{
                            ...purchaseRegister(`Discount`, {
                              required: {
                                value: true,
                                message: "Discount is required",
                              },
                              validate: (value: any) =>
                                value <= 100 ||
                                `Value should be less or equal to 100`,
                            }),
                          }}
                          errorMessage={purchaseErrors?.Discount?.message}
                        />
                      </>
                    ) : (
                      e?.Discount
                    );
                  }}
                />

                {type === "purchase" || type === "sales" ? editState == null && (
                  <Column
                    header="Action"
                    style={{ width: "6%" }}
                    body={purchaseAction}
                  />
                ) : (
                  <Column
                    header="Action"
                    style={{ width: "6%" }}
                    body={purchaseAction}
                  />
                )}
              </DataTable>
            </>
          )}
        </AccordionTab>
      </Accordion >
    </>
  );
};

export default PurchaseSaleInvoiceCommon;
