import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import AddEditHospital from "./AddEditHospital";
import DatatableCommon from "../../../../common/DatatableCommon";
import { NullHandler } from "../../../../common/NullHandler";
import { Link } from "react-router-dom";
import { Chart } from 'primereact/chart';

export const Hospital = () => {
  const apiname = process.env.REACT_APP_HOSPITAL_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.HospitalId;
  });

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "2%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistContactPersonTemplate(e),
      sortable: false,
      width: "5%",
    },
    {
      field: "HospitalName",
      header: "Hospital Name",
      body: (rowData: any) => {
        return (
          <>
            <div className="align-items-center h6">
              <Link to={""} className="text-bold">
                {rowData.HospitalName.toUpperCase()}
              </Link>
            </div>
          </>
        );
      },
      bodyClassName: "text-info h6",
      sortable: true,
      width: "10vw",
    },
    {
      field: "Address",
      header: "Address",
      // body: (data: any) => {
      //   return NullHandler(data.Address);
      // },
      body: (data: any) => {
        return (
          <p
            title={data.Address}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {data.Address === "" ? " - " : data.Address}
          </p>
        );
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "GstNo",
      header: "GST Number",
      body: (data: any) => {
        return NullHandler(data.GstNo);
      },
      sortable: true,
      width: "10vw",
    },


  ];
  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        label: 'Sales',
        data: [540, 325, 702, 620, 420],
        backgroundColor: [
          'rgba(255, 159, 64, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)'
        ],
        borderColor: [
          'rgb(255, 159, 64)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)'
        ],
        borderWidth: 1
      }
    ]
  };
  const actionChemistContactPersonTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.HospitalId}
          editModal={() => {
            setupdateId(id.HospitalId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <>
      <Helmet>
        <title> Hospital </title>
      </Helmet>

      <PageHeader
        headTitle="Hospital"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
        linkflag
        linkflagURL={"/layout/adminpanel"}
        linkflagtitle={"Admin Panel"}
      />
      {/* <div className="d-flex row m-2 justify-content-around mb-2 card p-4">
        <div className="card col-6 m-auto">
          <Chart type="bar" data={data} options={options} />
        </div>

      </div> */}
      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          // check={true}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
        />

        {updateId !== null && (
          <AddEditHospital
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
