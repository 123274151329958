import React, { useEffect, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditChemistType = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_CHEMISTTYPE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: chemistTypeLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
      }
    }
  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const chemistTypeFormData = (data: any) => {
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <Dialog
      visible={open}
      onHide={() => setisOPen(false)}
      header={`${id === 0 ? "Add" : "Edit"} Chemist Type`}
      modal
      style={{ width: "750px" }}
    >
      {!chemistTypeLoader ? (
        <form
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.key === "NumpadEnter") {
              handleSubmit(chemistTypeFormData);
            }
          }}
          onSubmit={handleSubmit(chemistTypeFormData)}
        >
          <div className="row">
            <Input
              containerClass="col-lg-12"
              label="Chemist Type Name"
              type="text"
              isRequired
              errorMessage={
                errors?.ChemistTypeName?.message ||
                error?.response?.data?.errors?.ChemistTypeName?.map(
                  (e: any) => e
                )
              }
              onChange={(e:any) => {setValue("ChemistTypeName", e?.target?.value); clearErrors(["ChemistTypeName"])}}
              register={{
                ...register("ChemistTypeName", {
                  required: {
                    value: true,
                    message: "Chemist Type Name is required",
                  },
                  pattern: {
                    value: /^.{2,50}$/gm,
                    message: "Enter a valid achievement type (2-50 characters)",
                  },
                }),
              }}
            />

<div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

          </div>
        </form>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </Dialog>
  );
};
