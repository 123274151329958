import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
// import AddEditCallObjectiveModal from "./AddEditCallObjectiveModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import AddEditDirectClubGroup from "./AddEditDirectClubGroup";

const DirectClubGroup = () => {

  const apiname = process.env.REACT_APP_DIRECTCLUBGROUP_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]:any = useState([]);
  const multi = selectedRow?.map((e:any) => {return e.DcgroupId})

  const columns = [
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionCallObjectiveTemplate(e),
      sortable: false,
      width:"1vw",
    },
    {
      field: "Dcgroup1",
      header: "Direct Club group",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width:"10vw",
    },
    
  ];


  const actionCallObjectiveTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.DcgroupId}
          editModal={() => {
            setupdateId(id.DcgroupId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  

  return (
    <>
      <Helmet>
        <title> Direct Club Group </title>
      </Helmet>

      <PageHeader
        headTitle="Direct Club Group"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
      />

      <div className="pd-20 card-box mb-30">
        
      <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              // check={true}
              setrefetchData={setbool}
              setselecteditem={setselectedRow}
              selecteditem={selectedRow}
              multiDelete={(e:boolean) => {if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) }}}
            />
        {updateId !== null && (
          <AddEditDirectClubGroup
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data:boolean) => { if (data) { setbool(true) }}}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default DirectClubGroup;
