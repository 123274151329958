import React, { useState } from 'react'
import { Action } from '../../common/Action';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../common/PageHeader';
import DatatableCommon from '../../common/DatatableCommon';
import { DeleteModal } from '../../common/DeleteModal';
import { AddEditVendor } from './AddEditVendor';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Rating } from 'primereact/rating';
import { NullHandler } from "../../common/NullHandler";


export const Vendor = () => {
    const apiname = process.env.REACT_APP_COMPANYVENDORSERVICE_API?.toString();

    const [bool, setbool]: any = useState(null);

    const [deleteID, setdeleteID]: any = useState();
    const setDeleteidtoDelete = (id: any) => {
        setdeleteID(id);
    };

    const [selectedRows, setselectedRows]: any = useState([]);

    const filterJson = {
        Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        VendorServiceTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        Phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        ContactPhone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        Rating: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        Address: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    }

    const columns = [
        {
            field: "srNo",
            header: "#",
            // frozen: true,
            width: "3rem",
        },
        {
            header: "Action",
            body: (e: any) => actionVendorTemplate(e),
            sortable: false,
            width: "2vw",
        },
        {
            field: "Name",
            header: "Name",
            bodyClassName: "text-info h6",
            filter: true,
            body: null,
            sortable: true,
            filterPlaceholder: "Search by name",
            width: "10vw",
        },
        {
            field: "VendorServiceTypeName",
            header: "Vendor Service Type",
            filter: true,
            body: (data: any) => {
                return NullHandler(data.VendorServiceTypeName);
            },
            sortable: true,
            filterPlaceholder: "Search by vendor service type",
            width: "10vw",
        },
        {
            field: "Phone",
            header: "Phone",
            filter: true,
            body: (data: any) => {
                return NullHandler(data.Phone);
            },
            sortable: true,
            filterPlaceholder: "Search by phone",
            width: "10vw",
        },
        {
            field: "ContactPhone",
            header: "Contact Phone",
            filter: true,
            body: (data: any) => {
                return NullHandler(data.ContactPhone);
            },
            sortable: true,
            filterPlaceholder: "Search by contact phone",
            width: "10vw",
        },
        {
            field: "Rating",
            header: "Rating",
            body: (e: any) => ratingBodyTemplate(e),
            sortable: true,
            width: "10vw",
        },
        {
            field: "Address",
            header: "Address",
            filter: true,
            // body: (data: any) => {
            //     return NullHandler(data.Address);
            //   },
            body: (data: any) => {
                return <p title={data.Address} style={{
                    width: "200px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }}>{data.Address === "" ? " - " : data.Address}</p>;
            },
            sortable: true,
            filterPlaceholder: "Search by address",
            width: "10vw",
        },

    ];

    const [updateId, setupdateId]: any = useState(null);

    const ratingBodyTemplate = (e: any) => {
        return <Rating value={e.Rating} readOnly cancel={false} />;
    };

    const actionVendorTemplate = (id: any) => {
        return (
            <>
                <Action
                    id={id.Id}
                    editModal={() => {
                        setupdateId(id.Id);
                    }}
                    displayid={setDeleteidtoDelete}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>Vendor</title>
            </Helmet>
            <div className="min-height-200px">
                <PageHeader
                    headTitle="Vendor"
                    onClick={() => {
                        setupdateId(0);
                    }}
                    id={0}
                    list={true}
                />
                <DatatableCommon
                    apiname={apiname}
                    columnData={columns}
                    filterJson={filterJson}
                    refetchData={bool}
                    setrefetchData={setbool}
                    // multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRows?.length === 0 ? null : multi) } }}
                    selecteditem={selectedRows}
                    setselecteditem={setselectedRows}
                />
                {updateId !== null && (
                    <AddEditVendor
                        fetch={(data: boolean) => {
                            if (data) {
                                setbool(true);
                            }
                        }}
                        setisOPen={() => {
                            return setupdateId(null);
                        }}
                        open={updateId !== null}
                        id={updateId}
                    />
                )}
            </div>

            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => { if (data) { setbool(true); } }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />
        </>
    )
}
