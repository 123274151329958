import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../../common/PageHeader";
import Textarea from "../../../common/Textarea";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/Input";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Button } from "primereact/button";

export const AddEditPermission = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_PERMISSION_API?.toString();

  const { data, isLoading: permissionLoader }: any = useFetchAllID(apiname, id);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
      }
    }
  }, [data, id]);

  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error,
    data: response,
  }: any = useCreateByID(apiname, id);

  const PermissionData = (data: any) => {
    mutate(data);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/admin/permission/permission");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/admin/permission/permission");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Helmet>
        <title>{id === undefined ? "Add" : "Edit"} Permission </title>
      </Helmet>

      <PageHeader
        headTitle="Permission"
        headTitleURL={"/layout/admin/permission/permission"}
        list={false}
        id={id}
      />

      {!permissionLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((pinfo: any) => {
              PermissionData(pinfo);
            })}
          >
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Permission"
                type="text"
                maxLength={100}
                isRequired
                errorMessage={
                  errors?.Permission1?.message ||
                  error?.response?.data?.errors?.Permission1?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Permission1", e.target.value);
                  clearErrors(["Permission1"]);
                }}
                register={{
                  ...register("Permission1", {
                    required: {
                      value: true,
                      message: "Permission is required",
                    },
                    pattern: {
                      value: /^(?!\s)\w[a-z0-9A-Z\s',-]+(?!\s)$/,
                      message:
                        "Enter only letters and digits, Remove whitespace from both sides of a string",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-6"
                label="Description"
                isRequired
                register={{
                  ...register("Description", {
                    required: {
                      value: true,
                      message: "Description is required.",
                    },
                  }),
                }}
                maxLength={500}
                rows={3}
                onChange={(e: any) => {
                  setValue("Description", e.target.value);
                  clearErrors(["Description"]);
                }}
                errorMessage={
                  errors?.Description?.message ||
                  error?.response?.data?.errors?.Description?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center mt-3">
                <Link to={"/layout/admin/permission/permission"}>
                  <Button type="button" severity="secondary" outlined>
                    <span className="p-button-label p-c">Back</span>
                  </Button>
                </Link>

                {
                  id == undefined && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};
