import { useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";

export const SRC = () => {
  const apiname = process.env.REACT_APP_SRC_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedCategory, setSelectedCategory]: any = useState([]);
  const multi = selectedCategory?.map((e: any) => {
    return e.CategoryId;
  });

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width:'2%'
    },
    {
      header: "Action",
      sortable: false,
      body: (e: any) => actionCategoryTemplate(e),
      width: "6%",
    },
    {
      field: "RouteName",
      header: "Route Name",
      bodyClassName: "text-info h6",
      sortable: true,
      body: null,
      width: "45%",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      bodyClassName: " h6",
      sortable: true,
      body: null,
      width: "45%",
    },
  ];

  const [updateId, setupdateId]: any = useState(null);
  const actionCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.RouteId}
          editurl={"layout/master/claim/Editsrc"}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>SRC</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="SRC"
          list={true}
          LinkURL={"/layout/master/claim/Addsrc"}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedCategory?.length === 0 ? null : multi);
            }
          }}
          setselecteditem={setSelectedCategory}
          selecteditem={selectedCategory}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {/* {updateId !== null && (
          <AddEditCategoryModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true)
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )} */}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
