import { useState } from "react";
import { Helmet } from "react-helmet";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { AddEditEventAttendance } from "./AddEditEventAttendance";
import { Button } from "primereact/button";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";
import moment from 'moment'
import { Badge } from "primereact/badge";


export const EventAttendance = () => {
  const apiname = process.env.REACT_APP_EVENTATTENDANCE_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const [bool, setbool]: any = useState(null);
  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row.Id);
  };

  const filterJson = {
    EventName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    AttendanceStatusName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  }


  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3%",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionEventTemplate(e),
      width: "2vw",
    },
    {
      field: "EventName",
      header: "Event Name",
      sortable: true,
      body: null,
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by event name",
    },
    {
      field: "AttendanceDate",
      header: "Attendance Date",
      sortable: true,
      body: (e: any) =>
      // NullHandler(e.AttendanceDate == null ? "" : moment(e.AttendanceDate).format("DD MMM, YYYY")),
      {
        if (e.AttendanceDate === null) {
          return "-";
        } else {
          return (
            <>
              <Badge
                className="m-1"
                severity={"success"}
                value={moment(e.AttendanceDate).format("DD MMM, YYYY")}
              ></Badge>

            </>
          );
        }
      },
      dataType: "date",
      width: "10vw",
    },
    {
      field: "AttendanceStatusName",
      header: "Attendance Status Name",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.AttendanceStatusName);
      },
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by attedance status name",
    },

  ];

  const attendaceDate = (id: any) => {
    return id.AttendanceDate?.split("T")[0];
  };

  const onSubmit = (data: any, flag: boolean) => {
    const temp = {
      Id: data.Id,
      AttendanceStatusId: flag == true ? 1 : 2,
    };
    axiosInstance
      .put(`${process.env.REACT_APP_BASEURL}${apiname}/${data.Id}`, temp)
      .then((e) => {
        console.log(e);
        toast.success("Updated Successfully", { position: "top-right" });
        setbool(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const actionEventTemplate = (id: any) => {
    return (
      <>
        <div>
          <Button
            type="button"
            icon="pi pi-check"
            style={{ width: "2.5rem", height: "2.5rem" }}
            severity="success"
            className="mr-1"
            rounded
            onClick={() => {
              console.log(id, "idsssss");
              onSubmit(id, true);
            }}
          ></Button>
          <Button
            type="button"
            icon="pi pi-times"
            style={{ width: "2.5rem", height: "2.5rem" }}
            severity="info"
            className="mr-1"
            rounded
            onClick={() => {
              console.log(id, "idsssss");
              onSubmit(id, false);
            }}
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            className="mr-1"
            style={{ width: "2.5rem", height: "2.5rem" }}
            severity="danger"
            rounded
            onClick={() => {
              deleteiddetect(id);
            }}
          ></Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Event Attendance</title>
      </Helmet>

      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Event Attendance</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Event Attendance
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditEventAttendance
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
