import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useEffect, useState } from "react";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Alert } from "../../../common/Alert";
import { Modal } from "react-bootstrap";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import Textarea from "../../../common/Textarea";
import { ErrorMessage } from "../../../common/ErrorMessage";
import cameraLogo from "../../../assets/images/Camera.png";
import { AddEditExpenseTypeModal } from "../../master/submaster/expenseType/AddEditExpenseTypeModal";
import ButtonCommon from "../../../common/ButtonCommon";
import { Spinner } from "../../../common/Spinner";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

const AddEditExpense = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_EXPENSE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const { data, isLoading: fetchLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const [BillImage, setBillImage]: any = useState(null);
  const [ImageDisplay, setImageDisplay]: any = useState(cameraLogo);
  useEffect(() => {
    if (data) {
      if (id) {
        setImageDisplay(data.BillImagePath);
        reset(data);
        setValue("ExpenseDate", data.ExpenseDate?.split("T")[0]);
        setselectedexpense({
          label: data.ExpenseTypeName,
          value: data.ExpenseTypeId,
        });
        // setBillImage(data?.BillImage);
      }

    }
  }, [data]);

  const [selectedexpense, setselectedexpense]: any = useState(null);

  const [expense, setexpense]: any = useState(null);
  const [defaultValue, setdefaultValue]: any = useState(null);
  const handleExpensenputChange = (inputvalue: any, actionMeta: any) => {
    setexpense(0);
    setdefaultValue(inputvalue);
  };

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };
  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = cameraLogo;
  };
  const expenseFormData = (form: any) => {
    if (form.Note == null) {
      delete form.Note
    }

    if (BillImage !== null) {
      form.BillImage = BillImage[0];
    }
    if (BillImage == null) {
      delete form.BillImage;
    }
    let newData: any = {};
    Object.keys(form).forEach(
      (k) =>
        (newData[k] = typeof form[k] == "string" ? form[k].trim() : form[k])
    );
    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue);
      }
    }
    console.log("--->", formData);
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Expense Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Expense Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }
    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
    // if (isError) {
    //   toast.error("error...", {position : "top-right"})
    // }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title">{id === 0 ? "Add" : "Edit"} Expense </h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {!fetchLoader ? (
          <div className="modal-body">
            <form
              onSubmit={handleSubmit((productinfo: any) => {
                expenseFormData(productinfo);
              })}
            >
              <div className="row">
                <Input
                  containerClass="col-lg-4"
                  label="Expense Date"
                  type="date"
                  isRequired
                  errorMessage={
                    errors?.ExpenseDate?.message ||
                    error?.response?.data?.errors?.ExpenseDate?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("ExpenseDate", e.target.value);
                    clearErrors(["ExpenseDate"]);
                  }}
                  register={{
                    ...register("ExpenseDate", {
                      required: {
                        value: true,
                        message: "Expense Date is required",
                      },
                    }),
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  label="Expense Type"
                  control={control}
                  name="ExpenseTypeId"
                  isRequired

                  onChange={(e: any) => {
                    console.log(e);
                    setValue("ExpenseTypeId", e?.value);
                    setselectedexpense(e);
                  }}
                  value={selectedexpense}
                  handleCreate={handleExpensenputChange}
                  api={process.env.REACT_APP_EXPENSETYPE_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={errors?.ExpenseTypeId?.message}
                  rules={{
                    required: {
                      value: true,
                      message: "Expense Type is required",
                    },
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Amount"
                  type="text"
                  isRequired
                  errorMessage={
                    errors?.Amount?.message ||
                    error?.response?.data?.errors?.Amount?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    setValue("Amount", e.target.value);
                    clearErrors(["Amount"]);
                  }}
                  register={{
                    ...register("Amount", {
                      required: {
                        value: true,
                        message: "Amount is required",
                      },
                    }),
                  }}
                />

                <Textarea
                  containerClass="col-lg-12"
                  label="Note"
                  maxLength={500}
                  errorMessage={
                    errors?.Note?.message ||
                    error?.response?.data?.errors?.Note?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    setValue("Note", e.target.value);
                    clearErrors(["Note"]);
                  }}
                  register={{
                    ...register("Note"),
                  }}
                />

                <div className={"col-lg-4"}>
                  <div className="form-group image-upload">
                    <label className="col-form-label">Bill Image</label>
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <img
                          src={ImageDisplay}
                          alt="Click Here To Add Image"
                          className="uploaded-image"
                          onError={handleError}
                          width={"150px"}
                        />

                        <input
                          {...register("BillImage")}
                          type="file"
                          data-required="image"
                          onChange={(e: any) => {
                            if (e.target.files[0]) {
                              const filePath = URL.createObjectURL(e.target.files[0]);
                              setImageDisplay(filePath);
                            }
                            setBillImage(e.target.files)
                          }
                          }
                          className="form-control image-input"
                          hidden
                          accept=".pdf,image/*"
                        />
                      </label>
                    </div>
                    {BillImage == null ? (
                      <a href={`${data?.BillImagePath}`} target="blank">
                        {data?.BillImage}
                      </a>
                    ) : (
                      <p>{BillImage[0]?.name}</p>
                    )}
                    {!data.BillImage && BillImage == null && (
                      <ErrorMessage message={errors?.BillImage?.message} />
                    )}
                  </div>
                </div>

                <div className="col-lg-12 text-center mt-3">
                  <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                    <span className="p-button-label p-c">Back</span>
                  </Button>

                  {
                    id === 0 && <Button type="reset" severity="danger" onClick={() => {
                      reset(); setselectedexpense(null);
                      setBillImage(null);
                    }} outlined className="ml-2">
                      <span className="p-button-label p-c">Reset</span>
                    </Button>
                  }

                  {isLoading ? (
                    <Button type="submit" outlined disabled className="ml-2">
                      <span className="p-button-label p-c">
                        Submit <Spinner width={"1rem"} height={"1rem"} />
                      </span>
                    </Button>
                  ) : (
                    <Button type="submit" outlined className="ml-2">
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
                </div>


              </div>
            </form>

            {expense !== null && (
              <AddEditExpenseTypeModal
                defaultValue={defaultValue}
                setisOPen={() => {
                  return setexpense(null);
                }}
                fetch={(data: boolean, res: any) => {
                  if (data) {
                    setValue("ExpenseTypeId", res.ExpenseTypeId);
                    setselectedexpense({
                      label: res.ExpenseTypeName,
                      value: res.ExpenseTypeId,
                    });
                    // fetchexpenseOptions();
                  }
                }}
                open={expense !== null}
                id={expense}
              />
            )}
          </div>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddEditExpense;
