import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../../common/PageHeader";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import Textarea from "../../../common/Textarea";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import PaymentInvoice from "../../../common/PaymentInvoice";
import PurchaseSaleInvoiceCommon from "../../../common/PurchaseSaleInvoiceCommon";
import { toast } from "react-toastify";

const AddEditPurchaseInvoice = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const apiname =
    process.env.REACT_APP_INVENTORYPURCHASEINVOICE_API?.toString();
  const apiname2 = process.env.REACT_APP_PURCHASERETURNINVOICE_API?.toString();

  let [AlertIndex, SetAlertIndex] = useState(false);

  console.log(AlertIndex, "this is alertIndex");

  const [selectedwarehouse, setselectedwarehouse]: any = useState(null);
  const [selectedVendor, setSelectedVendor]: any = useState(null);
  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    watch,
  }: any = useForm();

  let temp = watch();

  useEffect(() => {
    if (data) {
      setSelectedVendor({
        label: data.VendorName,
        value: data.VendorId,
      });
      setValue("VendorId", data.VendorId);
      setselectedwarehouse({
        label: data.WarehouseName,
        value: data.WarehouseId,
      });
      setValue("WarehouseId", data.WarehouseId);
    }
  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    id == undefined ? apiname : apiname2
  );

  const [paymentArray, setPaymentArray]: any = useState([]);
  const [purchaseDetailArray, setPurchaseDetailArray]: any = useState([]);
  const [selectReturnType, setselectedReturnType]: any = useState(null);
  const [selectStockType, setselectedStockType]: any = useState(null);

  useEffect(() => {
    debugger;
    if (purchaseDetailArray.length == 0) {
      SetAlertIndex(true);
    } else {
      SetAlertIndex(false);
    }
  }, [purchaseDetailArray]);
  useEffect(() => {
    if (selectStockType !== null) {
      let purchaseData = data?.ProductDetails?.filter((e: any, i: number) => {
        return e.StockType == selectStockType.value;
      });

      let tempData = purchaseData.map((e: any, i: number) => {
        return {
          ProductId: {
            label: e.ProductName,
            value: e.ProductId,
          },
          Quantity: e.Quantity,
          BatchNo: e.BatchNo,
          SampleQuantity: e.SampleQuantity,
          ExpiryDate: e.ExpiryDate,
          ManufactureDate: e.ManufactureDate,
          Discount: e.Discount,
          MRP: e.MRP,
          PurchasePrice: e.PurchasePrice,
          FreeQty: "",
          SalePrice: "",
          No: i + 1,
        };
      });

      console.log(tempData, "purchaseData this is");

      setPurchaseDetailArray(tempData);
    }
  }, [selectStockType]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);


  const handleTabChange = (accordion: any, e: any) => {
    if (accordion === activeAccordion && e.index === activeIndex) {
      setActiveAccordion(null);
      setActiveIndex(null);
    } else {
      setActiveAccordion(accordion);
      setActiveIndex(e.index);
    }
  };

  // Purchase Invoice
  const onPurchaseSubmit = (data: any) => {
    debugger;
    let submitData = {};
    let dataFirst = {};
    if (id === undefined) {
      dataFirst = {
        ...data,
        ExtraCharges: Number(data.ExtraCharges),
        Discount: Number(data.Discount),
      };
    } else {
      dataFirst = {
        ...data,
        ExtraCharges: Number(data.ExtraCharges),
        Discount: Number(data.Discount),
        PurchaseInvoiceId: Number(id),
      };
    }

    let purchaseArray = purchaseDetailArray.map((e: any) => {
      return {
        ...e,
        ProductId:
          id === undefined ? e?.ProductId?.value.value : e?.ProductId?.value,
        Quantity: Number(e.Quantity),
        SampleQuantity: Number(e.SampleQuantity),
        PurchasePrice: Number(e.PurchasePrice),
        MRP: Number(e.MRP),
        Discount: Number(e.Discount),
      };
    });

    let payment = paymentArray.map((e: any) => {
      return {
        ...e,
        PaymentMethodId: e?.PaymentMethodId?.value,
        Amount: Number(e.Amount),
      };
    });

    if (id === undefined) {
      submitData = {
        PurchaseInvoiceToCreate: dataFirst,
        PurchaseInvoiceDetails: purchaseArray,
        Payments: payment,
      };
    } else {
      submitData = {
        PurchaseReturnInvoiceToCreate: dataFirst,
        PurchaseReturnInvoiceDetails: purchaseArray,
      };
    }

    if (!AlertIndex) {
      mutate(submitData);
    } else {
      if (purchaseDetailArray.length !== 0) {
        toast.error(
          "There are some unsaved changes, please save it before you submit.",
          { position: "top-right" }
        );
      } else {
        toast.error("You must Add Atleast One product", {
          position: "top-right",
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/inventory/PurchaseInvoice");
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <div>
      <Helmet>
        <title>
          {id == undefined
            ? "Add Purchase Invoice"
            : "Add Purchase Return Invoice"}{" "}
        </title>
      </Helmet>

      <PageHeader
        headTitle={
          id == undefined ? "Purchase Invoice" : "Purchase Return Invoice"
        }
        headTitleURL={"/layout/inventory/PurchaseInvoice"}
        list={false}
      />

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onPurchaseSubmit)}>
          <div className="row">
            {id !== undefined && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Return Type"
                name={"ReturnTypeId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ReturnTypeId", e?.value);
                    setselectedReturnType(e);
                  } else {
                    setValue("ReturnTypeId", null);
                    setselectedReturnType(null);
                  }
                }}
                value={selectReturnType}
                control={control}
                api={process.env.REACT_APP_INVENTORYRETURNTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.ReturnTypeId?.message}
                isRequired
                rules={{
                  required: { value: true, message: "Return Type is required" },
                }}
              />
            )}

            {id !== undefined && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Stock Type"
                name={"ReturnTypeId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setselectedStockType(e);
                  } else {
                    setselectedStockType(null);
                  }
                }}
                value={selectStockType}
                control={control}
                api={process.env.REACT_APP_INVENTORYRETURNTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.ReturnTypeId?.message}
                isRequired
                rules={{
                  required: { value: true, message: "Stock Type is required" },
                }}
              />
            )}

            <ReactSelect
              containerClass="col-lg-3"
              label="Vendor"
              name={"VendorId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setValue("VendorId", e?.value);
                  setSelectedVendor(e);
                } else {
                  setValue("VendorId", null);
                  setSelectedVendor(null);
                }
              }}
              value={temp.VendorId !== undefined ? selectedVendor : null}
              control={control}
              api={process.env.REACT_APP_INVENTORYVENDOR_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.VendorId?.message}
              isRequired
              Disabled={id !== undefined}
              rules={{
                required: { value: true, message: "Vendor is required" },
              }}
            />

            <ReactSelect
              containerClass="col-lg-3"
              label="Warehouse"
              name={"WarehouseId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setselectedwarehouse(e);
                  setValue("WarehouseId", e?.value);
                } else {
                  setselectedwarehouse(null);
                  setValue("WarehouseId", null);
                }
              }}
              value={temp.WarehouseId !== undefined ? selectedwarehouse : null}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.WarehouseId?.message}
              isRequired
              Disabled={id !== undefined}
              rules={{
                required: { value: true, message: "Warehouse is required" },
              }}
            />
            {id === undefined && (
              <>
                <Input
                  containerClass="col-lg-3"
                  label="Bill No"
                  type="text"
                  isRequired
                  onChange={(e: any) => {
                    setValue("BillNo", e.target.value);
                    clearErrors(["BillNo"]);
                  }}
                  register={{
                    ...register("BillNo"),
                  }}
                  errorMessage={
                    errors?.BillNo?.message ||
                    error?.response?.data?.errors?.BillNo?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-3"
                  label="Bill Date"
                  type="date"
                  isRequired
                  errorMessage={errors?.BillDate?.message}
                  onChange={(e: any) => {
                    setValue("BillDate", e.target.value);
                    clearErrors(["BillDate"]);
                  }}
                  register={{
                    ...register("BillDate", {
                      required: {
                        value: true,
                        message: "Bill Date is required",
                      },
                    }),
                  }}
                />
              </>
            )}
            <Input
              containerClass="col-lg-3"
              label="Extra Charges"
              type="number"
              min={0}
              onChange={(e: any) => {
                setValue("ExtraCharges", Number(e.target.value));
                clearErrors(["ExtraCharges"]);
              }}
              register={{
                ...register("ExtraCharges"),
              }}
              errorMessage={
                errors?.ExtraCharges?.message ||
                error?.response?.data?.errors?.ExtraCharges?.map((e: any) => e)
              }
            />

            <Input
              containerClass="col-lg-3"
              label="Discount %"
              type="number"
              min={0}
              onChange={(e: any) => {
                setValue("Discount", Number(e.target.value));
                clearErrors(["Discount"]);
              }}
              register={{
                ...register("Discount"),
              }}
              errorMessage={
                errors?.Discount?.message ||
                error?.response?.data?.errors?.Discount?.map((e: any) => e)
              }
            />

            <Textarea
              containerClass={id !== undefined ? "col-lg-3" : "col-lg-6"}
              label="Note"
              register={{
                ...register("Note"),
              }}
              maxLength={500}
              onChange={(e: any) => {
                setValue("Note", e.target.value);
                clearErrors(["Note"]);
              }}
              errorMessage={
                errors?.Note?.message ||
                error?.response?.data?.errors?.Note?.map((e: any) => e)
              }
              style={{ height: "75px" }}
            />
          </div>

          {/* Purchase Invoice Details */}
          {(selectStockType !== null || id == undefined) && (
            <PurchaseSaleInvoiceCommon
              purchaseDetailArray={purchaseDetailArray}
              SetAlertIndex={SetAlertIndex}
              setPurchaseDetailArray={setPurchaseDetailArray}
              activeIndex={
                activeAccordion === "Accordion1" ? activeIndex : null
              }
              onTabChange={(e: any) => handleTabChange("Accordion1", e)}
              type={id == undefined ? "purchase" : "purchasereturn"}
            />
          )}

          {/* Payment Details */}

          {id == undefined && (
            <PaymentInvoice
              paymentArray={paymentArray}
              setPaymentArray={setPaymentArray}
              activeIndex={
                activeAccordion === "Accordion2" ? activeIndex : null
              }
              SetAlertIndex={SetAlertIndex}
              onTabChange={(e: any) => handleTabChange("Accordion2", e)}
            />
          )}

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/inventory/PurchaseInvoice"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>

            {isLoading ? (
              <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                Submit <Spinner width={"1rem"} height={"1rem"} />
              </button>
            ) : (
              <Button type="submit" color="primary" text="Submit"></Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditPurchaseInvoice;
