import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import { Input } from "../../../../common/Input";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
const AddEditProductGroupModal = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_PRODUCTGROUP_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });
  const { data, isLoading: cityLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (defaultValue) {
      setValue("ProductGroupName", defaultValue.textbox);
      setValue("IsCompetitorProduct", defaultValue.checkbox);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (data) {
      console.log(data);
      reset(data);
    }
  }, [data]);

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const productgroupFormData = (data: any) => {
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Product Group`}
        modal
        style={{ width: "1100px" }}
      >
        {!cityLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(productgroupFormData);
              }
            }}
            onSubmit={handleSubmit(productgroupFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-12"
                label="Product Group Name"
                isRequired
                type="text"
                register={{
                  ...register("ProductGroupName", {
                    required: {
                      value: true,
                      message: "Please Enter Product Group Name",
                    },
                    pattern: {
                      value: /^.{1,50}$/gm,
                      message:
                        "Enter a valid product group name (1-50 alphanumeric characters only)",
                    }
                  }),
                }}
                onChange={(e:any) => {setValue("ProductGroupName", e.target.value); clearErrors(["ProductGroupName"])}}
                errorMessage={
                  errors?.ProductGroupName?.message ||
                  error?.response?.data?.errors?.ProductGroupName?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-12"
                label="Product Group Code"
                isRequired
                type="text"
                register={{
                  ...register("ProductGroupCode", {
                    required: {
                      value: true,
                      message: "Please Enter Product Group Code",
                    },
                    pattern: {
                      value: /^\w[A-Za-z0-9]{2,5}$/,
                      message: "Enter a valid group code (2-5 alphanumeric only)",
                    }
                  }),
                }}
                onChange={(e:any) => {setValue("ProductGroupCode", e.target.value); clearErrors(["ProductGroupCode"])}}
                errorMessage={
                  errors?.ProductGroupCode?.message ||
                  error?.response?.data?.errors?.ProductGroupCode?.map(
                    (e: any) => e
                  )
                }
              />

              <div className="col-lg-12">
                <div className="custom-control custom-checkbox mb-5">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    {...register("IsCompetitorProduct")}
                    id="productGroup"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="productGroup"
                  >
                    Is Competitor Product
                  </label>
                </div>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditProductGroupModal;
