import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import Textarea from "../../../../common/Textarea";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}
export default function AddEditMedicalAdvisorySpeakerModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname =
    process.env.REACT_APP_DOCTORMEDICALADVISORYORSPEAKER_API?.toString();

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
    watch,
  }: any = useForm({ mode: "all" });

  useEffect(() => {
    if (data) {
      if (id) {
        const StartDate =
          data?.MedicalAdvisoryOrSpeakerStartDate?.split("T")[0];
        const EndDate = data?.MedicalAdvisoryOrSpeakerEndDate?.split("T")[0];
        reset(data);

        setValue("MedicalAdvisoryOrSpeakerStartDate", StartDate);

        setValue("MedicalAdvisoryOrSpeakerEndDate", EndDate);

        if (data.MedicalAdvisoryOrSpeakerCompanyCityId !== null) {
          setValue("MedicalAdvisoryOrSpeakerCompanyCityId", {
            label: data.MedicalAdvisoryOrSpeakerCompanyCity,
            value: data.MedicalAdvisoryOrSpeakerCompanyCityId,
          });
          setselectedhcity({
            label: data.MedicalAdvisoryOrSpeakerCompanyCity,
            value: data.MedicalAdvisoryOrSpeakerCompanyCityId,
          });
        } else {
          setValue("MedicalAdvisoryOrSpeakerCompanyCityId", null);
          setselectedhcity(null);
        }
        if (data.MedicalAdvisoryOrSpeakerCompanyPincodeId !== null) {
          setValue("MedicalAdvisoryOrSpeakerCompanyPincodeId", {
            label: data.MedicalAdvisoryOrSpeakerCompanyPincode,
            value: data.MedicalAdvisoryOrSpeakerCompanyPincodeId,
          });
          setselectedhpincode({
            label: data.MedicalAdvisoryOrSpeakerCompanyPincode,
            value: data.MedicalAdvisoryOrSpeakerCompanyPincodeId,
          });
        } else {
          setValue("MedicalAdvisoryOrSpeakerCompanyPincodeId", null);
          setselectedhpincode(null);
        }
      }
    }
  }, [data]);

  const Hcity = watch("MedicalAdvisoryOrSpeakerCompanyCityId");

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);

  const areaofinterestFormData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.MedicalAdvisoryOrSpeakerBussinessValue = Number(
      data.MedicalAdvisoryOrSpeakerBussinessValue
    );
    data.MedicalAdvisoryOrSpeakerAmount = Number(
      data.MedicalAdvisoryOrSpeakerAmount
    );

    data.MedicalAdvisoryOrSpeakerCompanyCityId = selectedhcity?.value;
    data.MedicalAdvisoryOrSpeakerCompanyPincodeId = selectedhpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Medical Advisory / Speaker`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(areaofinterestFormData);
              }
            }}
            onSubmit={handleSubmit(areaofinterestFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Purpose"
                type="text"
                isRequired
                errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerPurpose?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerPurpose?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("MedicalAdvisoryOrSpeakerPurpose", e.target.value);
                  clearErrors(["MedicalAdvisoryOrSpeakerPurpose"]);
                }}
                register={{
                  ...register("MedicalAdvisoryOrSpeakerPurpose", {
                    required: { value: true, message: "Purpose is required" },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid purpose (1-50 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Amount"
                type="text"
                maxLength={7}
                errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerAmount?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerAmount?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("MedicalAdvisoryOrSpeakerAmount", e.target.value);
                  clearErrors(["MedicalAdvisoryOrSpeakerAmount"]);
                }}
                register={{
                  ...register("MedicalAdvisoryOrSpeakerAmount"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Company Name"
                type="text"
                errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerCompanyName?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerCompanyName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue(
                    "MedicalAdvisoryOrSpeakerCompanyName",
                    e.target.value
                  );
                  clearErrors(["MedicalAdvisoryOrSpeakerCompanyName"]);
                }}
                register={{
                  ...register("MedicalAdvisoryOrSpeakerCompanyName", {
                    pattern: {
                      value: /^.{2,100}$/gm,
                      message:
                        "Enter a valid company name (2-100 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Start Date"
                type="date"
                errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerStartDate?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerStartDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("MedicalAdvisoryOrSpeakerStartDate", e.target.value);
                  clearErrors(["MedicalAdvisoryOrSpeakerStartDate"]);
                }}
                register={{
                  ...register("MedicalAdvisoryOrSpeakerStartDate"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="End Date"
                type="date"
                errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerEndDate?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerEndDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("MedicalAdvisoryOrSpeakerEndDate", e.target.value);
                  clearErrors(["MedicalAdvisoryOrSpeakerEndDate"]);
                }}
                register={{
                  ...register("MedicalAdvisoryOrSpeakerEndDate"),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Business Value"
                type="text"
                maxLength={15}
                errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerBussinessValue?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerBussinessValue?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue(
                    "MedicalAdvisoryOrSpeakerBussinessValue",
                    e.target.value
                  );
                  clearErrors(["MedicalAdvisoryOrSpeakerBussinessValue"]);
                }}
                register={{
                  ...register("MedicalAdvisoryOrSpeakerBussinessValue"),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={500}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                register={{
                  ...register("Remarks"),
                }}
              />

              <Address
                header="Address"
                control={control}
                address1register={{
                  ...register("MedicalAdvisoryOrSpeakerCompanyAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue(
                    "MedicalAdvisoryOrSpeakerCompanyAddressLine1",
                    e.target.value
                  );
                  clearErrors(["MedicalAdvisoryOrSpeakerCompanyAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerCompanyAddressLine1
                    ?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerCompanyAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("MedicalAdvisoryOrSpeakerCompanyAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue(
                    "MedicalAdvisoryOrSpeakerCompanyAddressLine2",
                    e.target.value
                  );
                  clearErrors(["MedicalAdvisoryOrSpeakerCompanyAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerCompanyAddressLine2
                    ?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerCompanyAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("MedicalAdvisoryOrSpeakerCompanyCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("MedicalAdvisoryOrSpeakerCompanyCityId", null);
                    setValue("MedicalAdvisoryOrSpeakerCompanyPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerCompanyCityId?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerCompanyCityId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("MedicalAdvisoryOrSpeakerCompanyPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("MedicalAdvisoryOrSpeakerCompanyPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.MedicalAdvisoryOrSpeakerCompanyPincodeId?.message ||
                  error?.response?.data?.errors?.MedicalAdvisoryOrSpeakerCompanyPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedhcity}
                selectedPincode={selectedhpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"MedicalAdvisoryOrSpeakerCompanyCityId"}
                pincodeApi={
                  selectedhcity != null ?
                  `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}` : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"MedicalAdvisoryOrSpeakerCompanyPincodeId"}
                pincodeDisable={selectedhcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedhcity(null);
                      setselectedhpincode(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
