import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/images/logo-removebg-preview.png"
import qrImage from "../../assets/images/qr-code.png"
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../_helper/Interceptor";
import { Spinner } from "../../common/Spinner";
import { Button } from "primereact/button";
import { ToWords } from 'to-words';
import { LoaderContext } from "../../App";
import moment from "moment";

const toWords = new ToWords();


const InvoiceView = () => {
  const fullScreenLoader = useContext(LoaderContext);

  const { id, type } = useParams();

  const [loader, setLoader] = useState(true);
  const [qty, setQty]: any = useState({ QtySum: 0, free: 0 });
  const [roundOf, setRoundOf]: any = useState(0);
  useEffect(() => {
    if (loader) {
      fullScreenLoader.setloader(true);
    } else {
      fullScreenLoader.setloader(false);
    }
  }, [loader]);


  const pdfRef: any = useRef();
  const [invoiceData, setInvoiceData]: any = useState(null);
  const getInvoiceData = async () => {

    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}Inventory/Invoice/GetInvoice?InvoiceId=${id}`).then((data: any) => {

        console.log("Data", data.data.Data)
        let QtySum = 0;
        let free = 0;
        let roundedValue = 0;
        setInvoiceData(data.data.Data);
        data?.data?.Data?.ProductDetails.map((Quantity: any) => {
          QtySum = QtySum + Quantity?.Quentity;
          free = free + Quantity?.Free;
        })
        setQty({ QtySum: QtySum, free: free })

        if (data.data.Data?.GSTDetail?.SGSTAmount !== null && data.data.Data?.GSTDetail?.SGSTAmount !== undefined && data.data.Data?.GSTDetail?.SGSTAmount !== 0
          && data.data.Data?.GSTDetail?.CGSTAmount !== null && data.data.Data?.GSTDetail?.CGSTAmount !== undefined && data.data.Data?.GSTDetail?.CGSTAmount !== 0) {
          roundedValue = Math.round(data.data.Data?.GSTDetail?.CGSTAmount + data.data.Data?.GSTDetail?.SGSTAmount) - (data.data.Data?.GSTDetail?.CGSTAmount + data.data.Data?.GSTDetail?.SGSTAmount);

        }
        else if (invoiceData?.GSTDetail?.IGSTAmount !== null && invoiceData?.GSTDetail?.IGSTAmount !== undefined && invoiceData?.GSTDetail?.IGSTAmount !== 0) {
          roundedValue = Math.round(data.data.Data?.GSTDetail?.IGSTAmount) - (data.data.Data?.GSTDetail?.IGSTAmount);

        }
        else {
          roundedValue = 0;
        }
        setRoundOf(roundedValue);
        setLoader(false);
      }).catch((err) => {
        console.log("error", err)
      })

  }


  useEffect(() => {
    if (id) {
      setLoader(true);
      getInvoiceData()
    }
  }, [id])

  let temp: any = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Invoice</title>
  <!-- <link rel="stylesheet" href="style.css" /> -->
</head>


<body style=" font-family: Arial, sans-serif;
margin: 0;
padding: 0;
background-color: #f9f9f9;">
  <div style="margin-top: 25px; overflow-x: auto;  width: 100%;
  height:100%,
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;" >

    <table style="width: 100%; width: 100%;
    border-collapse: collapse;  border: 1px solid #000;">
      <tr>
      ${type == "p" ?
      ` <td style="width: 40%;  margin-left:30%; " rowspan="2" >
           
          <p style=" margin: 5px 40px;">M/S. <strong>${invoiceData?.CustomerDetail?.Name !== null && invoiceData?.CustomerDetail?.Name !== undefined ? invoiceData?.CustomerDetail?.Name : " "}</strong> </p>
          <p style=" margin: 5px 40px;">${invoiceData?.CustomerDetail?.Address !== null && invoiceData?.CustomerDetail?.Address !== undefined ? invoiceData?.CustomerDetail?.Address : " "}</p>
        
          <p style=" margin: 5px 40px;">DL No : ${invoiceData?.CustomerDetail?.DLNo !== null && invoiceData?.CustomerDetail?.DLNo !== undefined ? invoiceData?.CustomerDetail?.DLNo : " "}</p>
          <p style=" margin: 5px 40px;">GSTIN No : ${invoiceData?.CustomerDetail?.GSTINNo !== null && invoiceData?.CustomerDetail?.GSTINNo !== undefined ? invoiceData?.CustomerDetail?.GSTINNo : " "}</p>
          <p style=" margin: 5px 40px;">PAN : ${invoiceData?.CustomerDetail?.PANNo !== null && invoiceData?.CustomerDetail?.PANNo !== undefined ? invoiceData?.CustomerDetail?.PANNo : " "}</p>
          <p style=" margin: 5px 40px;">State Code:  ${invoiceData?.CustomerDetail?.State !== null && invoiceData?.CustomerDetail?.State !== undefined ? invoiceData?.CustomerDetail?.State : " "}</p>

        
        </td>` : `<td style="width: 45%;" rowspan="2">
           <div style=" width:25%; float: left; margin-right: 15px; margin-left: 15px; "><img width="100%"
              src=${logo}></div>
           <div style="float: left; width: 63%; ">
            <p style="color: #000080; font-size: 18px;  margin: 5px 0px;">BIOS LAB PRIVATE LIMITED </p>
            <p style="font-size: 14px;  margin: 5px 0;">205-206-MARUTINADAN COMPLEX,
            <p style="font-size: 14px;  margin: 5px 0;">2/10-BHAKTINAGAR STATION PLOT, RAJKOT</p>
            <strong style="font-size: 15px;">Ph. : (0281)2465065,7820025250, <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9979879993</strong>
            <p style="font-size: 15px;  margin: 5px 0;">D.L.No.:20BRAJ76649,21BRAJ76650, <br> &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21B155854, 20B155853</p>
            <p style="font-size: 15px;  margin: 5px 0;">GSTIN: 24AAGCB2547Q1ZC</p>
            <p style="font-size: 15px;  margin: 5px 0;">PAN No: AAGCB2547Q</p>


          </div>
        </td>`
    }
       
      ${type == "p" ? `<td style="width: 21%;  border-left: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;"></td>`
      : ` <td style="width: 26%;  border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
          <p style="font-weight: bold; font-size: 13px;  margin: 5px 0;">Customer Name, Address & Licence Details.
        </td>`
    }
       
        <td style="width: 28%;  border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
          <strong>${type === "p" ? "Purchase" : "Tax"} Invoice</strong>
          <span style="padding-left: 31%; font-weight: bold;">${type === "p" ? "" : "Debit Memo"}</span>
        </td>


      </tr>
      <tr>
        ${type == "p" ?

      `<td style="width: 35%;border-left: 1px solid #000;
        padding: 8px;
        padding-left: 48px;
        padding-bottom: 33px;
        text-align: left;
        font-size: 14px;">
           <div style="float: left; width: 100%; ">
            <p style="color: #000080; font-size: 18px;  margin: 5px 0px; ">BIOS LAB PRIVATE LIMITED </p>
            <p style="font-size: 14px;  margin: 5px 0px;">205-206-MARUTINADAN COMPLEX,
            <p style="font-size: 14px;  margin: 5px 0px;">2/10-BHAKTINAGAR STATION PLOT, RAJKOT</p>
            <strong style="font-size: 15px;">Ph. : (0281)2465065,7820025250,9979879993 </strong>
            <p style="font-size: 15px;  margin: 5px 0px;">D.L.No.:  20BRAJ76649 , 21BRAJ76650 , 21B155854 , 20B155853 </p>
            <p style="font-size: 15px;  margin: 5px 0px;">GSTIN: 24AAGCB2547Q1ZC</p>
            <p style="font-size: 15px;  margin: 5px 0px;">PAN No: AAGCB2547Q</p>


          </div>
        </td>` : `<td style="width: 30%;  border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
          <p style=" margin: 5px 0;">M/S. <strong>${invoiceData?.CustomerDetail?.Name !== null && invoiceData?.CustomerDetail?.Name !== undefined ? invoiceData?.CustomerDetail?.Name : " "}</strong> </p>
          <p style=" margin: 5px 0;">${invoiceData?.CustomerDetail?.Address !== null && invoiceData?.CustomerDetail?.Address !== undefined ? invoiceData?.CustomerDetail?.Address : " "}</p>
        
          <p style=" margin: 5px 0;">DL No : ${invoiceData?.CustomerDetail?.DLNo !== null && invoiceData?.CustomerDetail?.DLNo !== undefined ? invoiceData?.CustomerDetail?.DLNo : " "}</p>
          <p style=" margin: 5px 0;">GSTIN No : ${invoiceData?.CustomerDetail?.GSTINNo !== null && invoiceData?.CustomerDetail?.GSTINNo !== undefined ? invoiceData?.CustomerDetail?.GSTINNo : " "}</p>
          <p style=" margin: 5px 0;">PAN : ${invoiceData?.CustomerDetail?.PANNo !== null && invoiceData?.CustomerDetail?.PANNo !== undefined ? invoiceData?.CustomerDetail?.PANNo : " "}</p>
          <p style=" margin: 5px 0;">State Code:  ${invoiceData?.CustomerDetail?.State !== null && invoiceData?.CustomerDetail?.State !== undefined ? invoiceData?.CustomerDetail?.State : " "}</p>

        </td>`

    }
        
        <td style="width: 30%;  border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
        ${invoiceData?.BillDetail?.BillNo !== null && invoiceData?.BillDetail?.BillNo !== undefined ?
      `<p style=" margin: 5px 0;">Bill No. ${invoiceData?.BillDetail?.BillNo}</p>` : ""}
        ${invoiceData?.BillDetail?.BillDate !== null && invoiceData?.BillDetail?.BillDate !== undefined ? `<p style=" margin: 5px 0px; padding-bottom:10px;">Bill Dt. ${invoiceData?.BillDetail?.BillDate.split("T")[0]}</p>` : ""}
        <p style=" margin: 5px 0;">Invoice No. ${invoiceData?.BillDetail?.InvoiceNo !== null && invoiceData?.BillDetail?.InvoiceNo !== undefined ? invoiceData?.BillDetail?.InvoiceNo : " "}</p>
        <p style=" margin: 5px 0;padding-bottom:10px;">Invoice Dt. ${invoiceData?.BillDetail?.InvoiceDate !== null && invoiceData?.BillDetail?.InvoiceDate !== undefined ? invoiceData?.BillDetail?.InvoiceDate.split("T")[0] : " "}</p>
         
          <p style=" margin: 5px 0;">Transport : ${invoiceData?.BillDetail?.Transport !== null && invoiceData?.BillDetail?.Transport !== undefined ? invoiceData?.BillDetail?.Transport : " "}</p>
          <p style=" margin: 5px 0;">Case : ${invoiceData?.BillDetail?.Case !== null && invoiceData?.BillDetail?.Case !== undefined ? invoiceData?.BillDetail?.Case : " "}</p>
          <p style=" margin: 5px 0;">Due Date : ${invoiceData?.BillDetail?.DueDate !== null && invoiceData?.BillDetail?.DueDate !== undefined ? invoiceData?.BillDetail?.DueDate.split("T")[0] : " "}</p>


        </td>
      </tr >
    </table >
    <table style="width: 100%;
    border-collapse: collapse;  border: 1px solid #000;">
      <tr>
        <th style="width: 2%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Sr</th>
        <th style="width: 5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">HSN</th>
        <th style="width: 20%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Product Name</th>
        <th style="width: 5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Pack</th>
       
        <th style="width: 5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Batch</th>
     <th style="width: 5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Mfg</th>
        <th style="width:8%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Exp</th>
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">MRP</th>
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Qty</th>
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Free</th>
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">PTS</th>
       
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">PTR</th>
     <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Disc %</th>
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Taxable Amt</th>
        <th style="width:5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">GST%</th>
        <th style="width: 5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">GST Amt</th>
        <th style="width: 5%;  border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">Amount</th>
      </tr>

    ${invoiceData?.ProductDetails?.length > 0 ? invoiceData?.ProductDetails?.map((data: any, index: any) => {
        return `<tr>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${index + 1}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.HSN !== null && data?.HSN !== undefined ? data?.HSN : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.ProductName !== null && data?.ProductName !== undefined ? data?.ProductName : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.PakingType !== null && data?.PakingType !== undefined ? data?.PakingType : " - "}</td>
       
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.Batch !== null && data?.Batch !== undefined ? data?.Batch : " - "}</td>
     <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.VandorName !== null && data?.VandorName !== undefined ? data?.VandorName : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;

    font-size: 14px;">${data?.ExpiryDate !== null && data?.ExpiryDate !== undefined ? moment(data?.ExpiryDate).format('DD-MM-YYYY') : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.MRP !== null && data?.MRP !== undefined ? data?.MRP : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.Quentity !== null && data?.Quentity !== undefined ? data?.Quentity : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.Free !== null && data?.Free !== undefined ? data?.Free : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.PTS !== null && data?.PTS !== undefined ? data?.PTS : " - "}</td>
       
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.PTR !== null && data?.PTR !== undefined ? data?.PTR : " - "}</td>
     <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.Discount !== null && data?.Discount !== undefined ? data?.Discount : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.TaxableAmt !== null && data?.TaxableAmt !== undefined ? data?.TaxableAmt : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.GST !== null && data?.GST !== undefined ? data?.GST : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.GSTAmt !== null && data?.GSTAmt !== undefined ? data?.GSTAmt : " - "}</td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;">${data?.Amount !== null && data?.Amount !== undefined ? data?.Amount : " - "}</td>
      </tr>`;
      }).join('') : ""}
      
      
      
      <tr style="height: 200px ;">
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
        <td style=" border: 1px solid #000;
    padding: 8px;
    text-align: left;
    font-size: 14px;"></td>
      </tr>
      <tr valign="bottom">

        <td style=" border: 1px solid #000;" colspan="8"></td>
        <td style="border: 1px solid #000; padding: 8px;">${qty.QtySum}</td>
        <td style="padding: 8px;  text-align: left;">${qty.free}</td>

        <td colspan="6" style="font-weight: bold;  border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">Sub Total</td>
        <td style="    font-size: 14px;  border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">${invoiceData?.GSTDetail?.TotalAmount !== null && invoiceData?.GSTDetail?.TotalAmount !== undefined ? Math.round(invoiceData?.GSTDetail?.TotalAmount) : " - "}</td>
      </tr>


    </table >
    <table style="width: 100%;
    border-collapse: collapse;  border: 1px solid #000;">
      <tr>
        <td style="width: 35%; border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
          <p style="font-size: 14px;  margin: 5px 0;">Terms :</p>
          <p style="font-size: 12px;  margin: 5px 0;">* Payment should be made within 21days from date of bill.</p>
          <p style="font-size: 13px;  margin: 5px 0;">* Complaints regarding this bill should be lodged immediately
            or otherwise they will not be entertained.</p>
          <p style="font-size: 12px;  margin: 5px 0;">* While taking delivery from the carrier, please check weight and
            number with LRs.Claims for loss should be submitted to carriers</p>
        </td>

        <td style=" border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
          <table style="width: 100%;
    border-collapse: collapse;  border: 1px solid #000;">
            <tr>
              <td style="border: 1px solid black; padding: 0; padding-bottom: 7%; border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px;">
                <div style="background-color: #c0c0c0; text-align: center; font-weight: bold;">Taxable</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.TotalAmount !== null && invoiceData?.GSTDetail?.TotalAmount !== undefined ? invoiceData?.GSTDetail?.TotalAmount : " - "}</p>
               
              </td>
              <td
                style="border: 1px solid black; padding: 0; padding-bottom: 7%; border: 1px solid #000; padding: 8px; text-align: left; font-size: 14px;">
                <div style="background-color: #c0c0c0; text-align: center;font-weight: bold;">SGST   %</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.SGST !== null && invoiceData?.GSTDetail?.SGST !== undefined ? invoiceData?.GSTDetail?.SGST : " - "}</p>
              </td>
              <td style="border: 1px solid black; padding: 0; padding-bottom: 7%;  border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px;">
              
                <div style="background-color: #c0c0c0; text-align: center;font-weight: bold;">CGST    %</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.CGST !== null && invoiceData?.GSTDetail?.CGST !== undefined ? invoiceData?.GSTDetail?.CGST : " - "}</p>
               
              </td>
              <td style="border: 1px solid black; padding: 0; padding-bottom: 7%;  border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px;">
                <div style="background-color: #c0c0c0; text-align: center;font-weight: bold;">SGST</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.SGSTAmount !== null && invoiceData?.GSTDetail?.SGSTAmount !== undefined ? invoiceData?.GSTDetail?.SGSTAmount : " - "}</p>
               
              </td>
              <td style="border: 1px solid black; padding: 0; padding-bottom: 7%; border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px; ">
                <div style="background-color: #c0c0c0; text-align: center;font-weight: bold;">CGST</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.CGSTAmount !== null && invoiceData?.GSTDetail?.CGSTAmount !== undefined ? invoiceData?.GSTDetail?.CGSTAmount : " - "}</p>
                
              </td>
              <td style="border: 1px solid black; padding: 0; padding-bottom: 7%;  border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px;">
                <div style="background-color: #c0c0c0; text-align: center;font-weight: bold;">IGST  %</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.IGST !== null && invoiceData?.GSTDetail?.IGST !== undefined ? invoiceData?.GSTDetail?.IGST : " - "}</p>
               
              </td>
              <td style="border: 1px solid black; padding: 0; padding-bottom: 7%;  border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px; ">
                <div style="background-color: #c0c0c0; text-align: center;font-weight: bold;">IGST</div>
                <p style="text-align: end; padding-right: 3px;  margin: 5px 0;">${invoiceData?.GSTDetail?.IGSTAmount !== null && invoiceData?.GSTDetail?.IGSTAmount !== undefined ? invoiceData?.GSTDetail?.IGSTAmount : " - "}</p>
               
              </td>
            </tr>
          </table>
        </td>

        <td style=" border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
          <table style="width: 100%;
    border-collapse: collapse;  border: 1px solid #000;">
            <tr>
              <td style="border: 1px solid black; padding-bottom: 28%; border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px;">

                <p style="text-align: end;  margin: 5px 0;">SGST</p>
                <p style="text-align: end;  margin: 5px 0;">CGST</p>
                <p style="text-align: end;  margin: 5px 0;">IGST</p>
                <p style="text-align: end;  margin: 5px 0;">Round Off</p>

              </td>
              <td style="border: 1px solid black; padding-bottom: 28%; border: 1px solid #000;
              padding: 8px;
              text-align: left;
              font-size: 14px;">

                <p style="text-align: end;  margin: 5px 0;">${invoiceData?.GSTDetail?.SGSTAmount !== null && invoiceData?.GSTDetail?.SGSTAmount !== undefined ? invoiceData?.GSTDetail?.SGSTAmount : " - "}</p>
                <p style="text-align: end;  margin: 5px 0;">${invoiceData?.GSTDetail?.CGSTAmount !== null && invoiceData?.GSTDetail?.CGSTAmount !== undefined ? invoiceData?.GSTDetail?.CGSTAmount : " - "}</p>
                <p style="text-align: end;  margin: 5px 0;">${invoiceData?.GSTDetail?.IGSTAmount !== null && invoiceData?.GSTDetail?.IGSTAmount !== undefined ? invoiceData?.GSTDetail?.IGSTAmount : " - "}</p>
                <p style="text-align: end;  margin: 5px 0;">${parseFloat(roundOf.toFixed(2))}</p>
              </td>
            </tr>
          </table>
        </td>


      </tr>
    </table>
    <div style="font-weight: bold; background-color: #c0c0c0;">
      <span>Rupees: ${invoiceData?.GSTDetail?.TotalAmount !== null && invoiceData?.GSTDetail?.TotalAmount !== undefined ? toWords.convert(Math.round((invoiceData?.GSTDetail?.NetToPay + roundOf))) : "-"} Only</span>
      <span style="margin-left: 31%;">NET TO PAY </span>
      <span style="margin-left: 5%;">${invoiceData?.GSTDetail?.NetToPay !== null && invoiceData?.GSTDetail?.NetToPay !== undefined ? Math.round((invoiceData?.GSTDetail?.NetToPay + roundOf)) : "-"}</span>
    </div >
  <table style="width: 100%;
    border-collapse: collapse; ">
    <tr>
      ${type !== "p" ?
      `<td  style="width: 11%; font-size: 10px; border: none;  padding-bottom: 2%; border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
        Subject to RAJKOT
      </td>
      <td style="font-size: 10px; width: 5%; border: none; padding-bottom: 2%; border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
        Jurisdication
      </td>
      <td style="width: 18%; border: none; border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
        <div style="border: 2px solid black; padding: 3px;">
          <p style="font-size: 12px;  margin: 5px 0;">BANK DETAILS</p>
          <p style="font-size: 12px;  margin: 5px 0;">BANK NAME : HDFC BANK</p>
          <p style="font-size: 12px;  margin: 5px 0;">AC NO: 50200073133906</p>
          <p style="font-size: 12px;  margin: 5px 0;">IFSC CODE: HDFC0006286</p>
        </div>
        <p style="text-align: center;  margin: 5px 0;">E.& O.E.</p>
      </td>
      <td style="width: 11%; border: none; border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;"><img width="100%" alt="not found" src=${qrImage}></td>

      <td style="width: 35%; border: none; border: 1px solid #000;
        padding: 8px;
        text-align: left;
        font-size: 14px;">
        <p style="margin-bottom: 10%;  margin: 5px 0; "> <strong>IRN</strong> ed6aa19be8b42f845e9cac4d8bc8e771bb3aa
          98b6e1f2ecdc1008d37d59161cc
        </p>
        <div>
          <span>Ack No 162417790740048</span>
          <span style="margin-left: 10px;">22/07/2024</span>
        </div>
      </td>` : `<td style="
        width: 22%;
         border: none;
        padding: 8px;
        text-align: left;
        font-size: 14px;"></td>`
    }
      <td style="
        width: 22%;
         border: none;
         ${type !== "p" ? " border: 1px solid #000;" : ""}
         padding:12px;
        padding-top: 50px;
         padding-bottom: 50px;
        text-align: right;
        font-size: 14px;">
        <p style="font-weight: bold;  margin: 5px 0;">For, BIOS LAB PRIVATE LIMITED</p>
        <p style="margin-top: 30%;  margin: 5px 0;">Authorized Signatory</p>
      </td>
    </tr>

  </table >
  </div >
</body >

</html > `

  const HandleDownload = () => {

    const tempd: any = document.getElementById("divprint");

    html2canvas(tempd)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const doc = new jsPDF('l', 'mm', [1600, 1100]);
        doc.addImage(imgData, 'PNG', 0, 0, 1600, 1100);
        doc.save(`${invoiceData?.BillDetail?.InvoiceNo}.pdf`);
      });


    //  Alternative code to download Invoice

    /* const doc = new jsPDF('l', 'mm', [1600, 1100]);
     doc.html(tempd, {
       callback: (doc) => {
       doc.save(`${invoiceData?.BillDetail?.InvoiceNo}.pdf`);
       },
       autoPaging: "text",
     }); */

  }

  return <>

    <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>

      <Button onClick={HandleDownload} type="submit" className="ml-2 p-2 my-2">
        Download
      </Button>
    </div>
    <div id="divprint" ref={pdfRef} dangerouslySetInnerHTML={{ __html: temp }} />

  </>
}

export default InvoiceView;