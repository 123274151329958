import React, { useState } from 'react'
import { Action } from '../../../../common/Action';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import DatatableCommon from '../../../../common/DatatableCommon';
import { DeleteModal } from '../../../../common/DeleteModal';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { AddEditDailyAllowance } from './AddEditDailyAllowance';
import { NullHandler } from "../../../../common/NullHandler";

const DailyAllowance = () => {
    const apiname = process.env.REACT_APP_DAILYALLOWANCE_API?.toString();

    const [deleteID, setdeleteID]: any = useState(null);
  
    const [selectedRows, setSelectedRows]: any = useState([]);
  
    const [bool, setbool]: any = useState(null);
  
    const deleteiddetect = (row: any) => {
      setdeleteID(row);
    };
  
    const filterJson = {
      EmployeeCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      EmployeeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      HqTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      HqCalculationTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      Hq: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      ExHqCalculationTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      ExHq: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      OsCalculationTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      Os: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    }

    const columnData = [
      {
        header: "Action",
        sortable: false,
        filter: false,
        body: (e: any) => actionMonthlyAllowanceTemplate(e),
        width:"2rem",
      },
      {
        field: "EmployeeName",
        header: "Employee Name",
        sortable: true,
        bodyClassName: "text-info h6",
        body: null,
        filter:true,
        filterPlaceholder:"Search by employee name",
        width: "10rem",
      },
      {
        field: "EmployeeCode",
        header: "Employee Code",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.EmployeeCode);
        },
        filter:true,
        filterPlaceholder:"Search by employee code",
        width:"5rem",
      },
      {
        field: "HqTypeName",
        header: "Hq Type",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.HqTypeName);
        },
        filter:true,
        filterPlaceholder:"Search by hq type",
        width:"7rem",
      },
      {
        field: "HqCalculationTypeName",
        header: "Hq Calculation Type",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.HqCalculationTypeName);
        },
        filter:true,
        filterPlaceholder:"Search by hq calculation type",
        width:"5rem",
      },
      {
        field: "Hq",
        header: "Hq",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.Hq);
        },
        dataType:"numeric",
        filter:true,
        filterPlaceholder:"Search by hq",
        width:"5rem",
      },
      {
        field: "ExHqCalculationTypeName",
        header: "Ex Hq Calculation Type",
        sortable: true,
         body: (data: any) => {
          return NullHandler(data.ExHqCalculationTypeName);
        },
        filter:true,
        filterPlaceholder:"Search by ex hq calculation type",
        width:"7rem",
      },
      {
        field: "ExHq",
        header: "Ex Hq",
        sortable: true,
         body: (data: any) => {
          return NullHandler(data.ExHq);
        },
        dataType:"numeric",
        filter:true,
        filterPlaceholder:"Search by ex hq",
        width:"5rem",
      },
      {
        field: "OsCalculationTypeName",
        header: "Os Calculation Type",
        sortable: true,
         body: (data: any) => {
          return NullHandler(data.OsCalculationTypeName);
        },
        filter:true,
        filterPlaceholder:"Search by os calculation type",
        width:"7rem",
      },
      {
        field: "Os",
        header: "Os",
        sortable: true,
         body: (data: any) => {
          return NullHandler(data.Os);
        },
        dataType:"numeric",
        filter:true,
        filterPlaceholder:"Search by os",
        width:"5rem",
      },
      
    ];
  
    const [updateId, setupdateId]: any = useState(null);
    const actionMonthlyAllowanceTemplate = (id: any) => {
      return (
        <>
          <Action
            id={id.DailyAllowanceId}
            editModal={() => {
              setupdateId(id.DailyAllowanceId);
            }}
            displayid={deleteiddetect}
          />
        </>
      );
    };

  return (
    <>
     <Helmet>
        <title>Daily Allowance</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Daily Allowance"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          filterJson={filterJson}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditDailyAllowance
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      /> 
    </>
  )
}

export default DailyAllowance
