import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../_helper/Interceptor";
import { Helmet } from "react-helmet";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { ErrorMessage } from "../../../common/ErrorMessage";
import Select from "react-select";
import Textarea from "../../../common/Textarea";
import { toast } from "react-toastify";

export const CommonModel = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  }: any = useForm({
    defaultValues: {
      WorkingWith: "",
      CallTypeId: "",
      CallObjective: "",
      ContactPersonId: "",
      Feedback: "",
      PromotedProducts: [
        {
          ProductId: "",
          Qty: "",
          SampleQty: "",
          Isprescribe: "",
          Discount: "",
        },
      ],
      PromotedGift: [{ GiftId: "", SampleQty: "" }],
    },
  });

  const {
    fields: products,
    append: productadd,
    remove: productremove,
  } = useFieldArray({
    control,
    name: "PromotedProducts",
  });

  const {
    fields: gifts,
    append: giftadd,
    remove: giftremove,
  } = useFieldArray({
    control,
    name: "PromotedGift",
  });

  const [selectedcallType, setselectedcallType]: any = useState({});

  const [selectedcallObjective, setselectedcallObjective]: any = useState({});

  const [selectedjoinworkwith, setselectedjoinworkwith]: any = useState({});

  const [productOptions, setproductOptions] = useState([]);
  const fetchproductOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRODUCT_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.Name,
        value: option?.ProductId,
      };
    });
    setproductOptions(data);
  };

  const [giftOptions, setgiftOptions] = useState([]);
  const [selectedgift, setselectedgift]: any = useState([]);
  const fetchgiftOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GIFTMASTER_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.GiftName,
        value: option?.GiftMasterId,
      };
    });
    setgiftOptions(data);
  };

  const [contactpersonOptions, setcontactpersonOptions] = useState([]);
  const [selectedcontactperson, setselectedcontactperson]: any = useState({});
  const fetchContactperson = async () => {
    let url =
      location.state.state === "Chemist"
        ? `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTCONTACTPERSON_API}?chemistVisitId=${location.state.id}`
        : location.state.state === "Hospital"
          ? `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_HOSPITALCONTACTPERSON_API}?hospitalVistId=${location.state.id}`
          : "";
    console.log(url, "url is this");
    const response = await axiosInstance(url);
    const data = response?.data?.Data?.map((option: any) => {
      return location.state.state === "Chemist"
        ? {
          label: option?.ChemistContactPersonName,
          value: option?.ChemistContactPersonId,
        }
        : location.state.state === "Hospital"
          ? {
            label: option?.HospitalContactPersonName,
            value: option?.HospitalContactPersonId,
          }
          : {};
    });
    setcontactpersonOptions(data);
  };

  useEffect(() => {
    if (
      location.state.state === "Chemist" ||
      location.state.state === "Hospital"
    ) {
      fetchContactperson();
    }
  }, [location]);

  useEffect(() => {
    fetchproductOptions();
    fetchgiftOptions();
  }, []);

  const onSubmit = (data: any) => {

    data.Id = location.state.id;
    if (location.state.state === "Doctor") {
      delete data.ContactPersonId;
    } else if (location.state.state === "Chemist") {
      data.ChemistContactPersonId = Number(data.ContactPersonId);
      delete data.ContactPersonId;
    } else if (location.state.state === "Hospital") {
      data.HospitalContactPersonId = Number(data.ContactPersonId);
      delete data.ContactPersonId;
    }

    data.CallObjective = Number(data.CallObjective);
    data.CallTypeId = Number(data.CallTypeId);
    data.WorkingWith = Number(data.WorkingWith);
    data.Feedback = data.Feedback;

    if (location.state.state === "Chemist") {
      data.ChemistContactPersonId = data.ChemistContactPersonId;
    }
    if (location.state.state === "Hospital") {
      data.HospitalContactPersonId = data.HospitalContactPersonId;
      if (data.HospitalContactPersonId === 0) {
        delete data.HospitalContactPersonId;
      }
    }
    const gift = data.PromotedGift.map((e: any) => {
      return {
        GiftId: e.GiftId,
        SampleQty: Number(e.SampleQty),
      };
    });
    data.PromotedGift = gift;
    const product = data.PromotedProducts.map((e: any) => {
      return {
        ProductId: e.ProductId,
        Qty: Number(e.Qty),
        SampleQty: Number(e.SampleQty),
        Discount: Number(e.Discount),
        Isprescribe: e.Isprescribe === true ? true : false,
      };
    });
    data.PromotedProducts = product;

    axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${location.state.state === "Doctor"
          ? `${process.env.REACT_APP_DOCTORVISIT_API}`
          : location.state.state === "Chemist"
            ? `${process.env.REACT_APP_CHEMISTVISIT_API}`
            : location.state.state === "Hospital"
              ? `${process.env.REACT_APP_HOSPITALVISIT_API}`
              : ""
        }`,
        data
      )
      .then((res) => {
        navigate("/layout/mr/visit");
        toast.success("Visit Added Successfully", { position: "top-right" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Helmet>
        <title>
          {location.state.state === "Doctor"
            ? "Doctor Detail"
            : location.state.state === "Chemist"
              ? "Chemist Detail"
              : location.state.state === "Hospital"
                ? "Hospital Detail"
                : ""}
        </title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>
                {location.state.state === "Doctor"
                  ? "Doctor Detail"
                  : location.state.state === "Chemist"
                    ? "Chemist Detail"
                    : location.state.state === "Hospital"
                      ? "Hospital Detail"
                      : ""}
              </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/layout/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {location.state.state === "Doctor"
                    ? "Doctor Detail"
                    : location.state.state === "Chemist"
                      ? "Chemist Detail"
                      : location.state.state === "Hospital"
                        ? "Hospital Detail"
                        : ""}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <h4>
              {location.state.state === "Doctor"
                ? "Doctor Name:- "
                : location.state.state === "Chemist"
                  ? "Chemist Name:- "
                  : location.state.state === "Hospital"
                    ? "Hospital Name:- "
                    : ""}
              <span className="text-blue">{location.state.name}</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="pd-20 card-box">
        <form
          onSubmit={handleSubmit((data: any) => {
            onSubmit(data);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              label="Call type"
              control={control}
              name="CallTypeId"
              isRequired
              onChange={(e: any) => {
                setselectedcallType(e);
                setValue("CallTypeId", e?.value);
              }}
              value={selectedcallType}
              api={process.env.REACT_APP_CALLTYPE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.CallTypeId?.message}
              rules={{
                required: {
                  value: true,
                  message: "Call Type is required",
                },
              }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Call Objective"
              control={control}
              name="CallObjective"
              isRequired
              onChange={(e: any) => {
                setselectedcallObjective(e);
                setValue("CallObjective", e?.value);
              }}
              value={selectedcallObjective}
              api={process.env.REACT_APP_CALLOBJECTIVE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.CallObjective?.message}
              rules={{
                required: {
                  value: true,
                  message: "Call Objective is required",
                },
              }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Joint Work With"
              control={control}
              name="WorkingWith"
              isRequired
              onChange={(e: any) => {
                setselectedjoinworkwith(e);
                setValue("WorkingWith", e?.value);
              }}
              value={selectedjoinworkwith}
              api={process.env.REACT_APP_GETALLEMPLOYEEBYDESIGNATION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.WorkingWith?.message}
              rules={{
                required: {
                  value: true,
                  message: "Working With is required",
                },
              }}
            />

            {(location.state.state === "Chemist" ||
              location.state.state === "Hospital") && (
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Contact Person <span className="text-danger">*</span>
                    </label>
                    <Select
                      {...register("ContactPersonId")}
                      options={contactpersonOptions}
                      value={selectedcontactperson}
                      onChange={(e: any) => {
                        setselectedcontactperson(e);
                        setValue("ContactPersonId", e?.value);
                      }}
                    />
                    <ErrorMessage message={errors?.ContactPersonId?.message} />
                  </div>
                </div>
              )}
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <h4 className="mt-3">Product</h4>
            </div>
            <div className="col-md-6 text-right">
              <button
                type="button"
                onClick={() => {
                  productadd({
                    ProductId: "",
                    Qty: "",
                    SampleQty: "",
                    Isprescribe: "",
                  });
                }}
                className="btn btn-sm btn-outline-primary mt-4"
              >
                Add Product
              </button>
            </div>
          </div>

          <table className="table table-bordered table-striped">
            <thead>
              <tr className="text-center">
                <th>Product Name</th>
                <th>Product Quantity(Monthly)</th>
                <th>Sample Quantity(Monthly)</th>
                <th>Discount Price</th>
                <th>Is Prescribe</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {products.map((field, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Select
                        {...register(`PromotedProducts.${index}.ProductId`, {
                          required: {
                            value: true,
                            message: "Product is required",
                          },
                        })}
                        options={productOptions}
                        className="z-3"
                        classNamePrefix={"z-3"}
                        onChange={(e: any) => {
                          setValue(
                            `PromotedProducts.${index}.ProductId`,
                            e?.value
                          );
                        }}
                      />
                      <ErrorMessage
                        message={
                          errors?.["PromotedProducts"]?.[index]?.["ProductId"]
                            ?.message
                        }
                      />
                    </td>
                    <td>
                      <div className="mt-3">
                        <Input
                          containerClass="col-lg-12"
                          // label='Order Qty'
                          isRequired
                          type="text"
                          register={{
                            ...register(`PromotedProducts.${index}.Qty`, {
                              required: {
                                value: true,
                                message: "Order Qty is Required",
                              },
                            }),
                          }}
                          errorMessage={
                            errors?.["PromotedProducts"]?.[index]?.["Qty"]
                              ?.message
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="mt-3">
                        <Input
                          containerClass="col-lg-12"
                          // label='Sample Qty'
                          isRequired
                          type="text"
                          register={{
                            ...register(`PromotedProducts.${index}.SampleQty`, {
                              required: {
                                value: true,
                                message: "Sample Qty is Required",
                              },
                            }),
                          }}
                          errorMessage={
                            errors?.["PromotedProducts"]?.[index]?.["SampleQty"]
                              ?.message
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="mt-3">
                        <Input
                          containerClass="col-lg-12"
                          isRequired
                          type="text"
                          register={{
                            ...register(`PromotedProducts.${index}.Discount`, {
                              required: {
                                value: true,
                                message: "Discount is Required",
                              },
                            }),
                          }}
                          errorMessage={
                            errors?.["PromotedProducts"]?.[index]?.["Discount"]
                              ?.message
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox mt-4">
                            <input
                              {...register(
                                `PromotedProducts.${index}.Isprescribe`,
                                {}
                              )}
                              id={`PromotedProducts.${index}.Isprescribe`}
                              type="checkbox"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`PromotedProducts.${index}.Isprescribe`}
                            >
                              Isprescribe
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="col-lg-12 text-center">
                        {products.length !== 1 ? (
                          <button
                            type="button"
                            onClick={() => {
                              productremove(index);
                            }}
                            className="btn btn-sm btn-outline-danger"
                          >
                            Delete Product
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              productremove(index);
                            }}
                            className="btn btn-sm btn-outline-danger"
                            disabled
                          >
                            Delete Product
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="row mb-3">
            <div className="col-md-6">
              <h4 className="mt-3">Gift</h4>
            </div>
            <div className="col-md-6 text-right">
              <button
                type="button"
                onClick={() => {
                  giftadd({ GiftId: "", SampleQty: "" });
                }}
                className="btn btn-sm btn-outline-primary mt-4"
              >
                Add Gift
              </button>
            </div>
          </div>

          <table className="table table-bordered table-striped">
            <thead>
              <tr className="text-center">
                <th>Gift Name</th>
                <th>Sample Qty</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {gifts.map((field, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Select
                        {...register(`PromotedGift.${index}.GiftId`, {
                          required: {
                            value: true,
                            message: "Gift is required",
                          },
                        })}
                        options={giftOptions}
                        className="z-3"
                        classNamePrefix={"z-3"}
                        onChange={(e: any) => {
                          setValue(`PromotedGift.${index}.GiftId`, e?.value);
                        }}
                      />
                      <ErrorMessage
                        message={
                          errors?.["PromotedGift"]?.[index]?.["GiftId"]?.message
                        }
                      />
                    </td>
                    <td>
                      <div className="mt-3">
                        <Input
                          containerClass="col-lg-12"
                          isRequired
                          type="text"
                          register={{
                            ...register(`PromotedGift.${index}.SampleQty`, {
                              required: {
                                value: true,
                                message: "Sample Qty is Required",
                              },
                            }),
                          }}
                          errorMessage={
                            errors?.["PromotedGift"]?.[index]?.["SampleQty"]
                              ?.message
                          }
                        />
                      </div>
                    </td>

                    <td>
                      <div className="col-lg-12 text-center">
                        {gifts.length !== 1 ? (
                          <button
                            type="button"
                            onClick={() => {
                              giftremove(index);
                            }}
                            className="btn btn-sm btn-outline-danger"
                          >
                            Delete Gift
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              giftremove(index);
                            }}
                            className="btn btn-sm btn-outline-danger"
                            disabled
                          >
                            Delete Gift
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Textarea
            containerClass="col-lg-12"
            label="Remarks"
            isRequired
            register={{
              ...register("Feedback", {
                required: { value: true, message: "Remarks is required" },
              }),
            }}
            rows={3}
            errorMessage={errors?.DoctorFeedback?.message}
          />

          <div className="col-md-12 text-center align-self-center mt-3">
            <Link
              to="/layout/mr/visit"
              className="btn btn-sm btn-outline-primary mr-1"
            >
              Back
            </Link>
            <button
              type="submit"
              className="btn btn-sm btn-outline-danger mr-1"
            >
              Close-Visit
            </button>
            {/* <a href="" className="btn btn-sm btn-outline-primary">Show-presentation</a> */}
          </div>
        </form>
      </div>
    </div>
  );
};
