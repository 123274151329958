import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useCreateByID, useFetchAllID } from '../../../../hooks/Api/Api';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spinner } from '../../../../common/Spinner';
import { Input } from '../../../../common/Input';

interface iPropsmodal {
    id?: any;
    setisOPen?: any;
    open?: any;
    fetch?: any;
    defaultValue?: any;
  }

export const AddEditDeviceType = ({
    id,
    setisOPen,
    open,
    fetch,
    defaultValue,
  }: iPropsmodal) => {

    const apiname = process.env.REACT_APP_DEVICETYPE_API?.toString();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        reset,
      }: any = useForm({ mode: "all" });

      useEffect(() => {
        if (defaultValue) {
          setValue("DeviceType1", defaultValue);
        }
      }, [defaultValue]);
    
      const { data, isLoading: deviceTypeLoader }: any = useFetchAllID(
        apiname,
        id === 0 ? undefined : id
      );
    
      useEffect(() => {
        if (id) {
          if (data) {
            reset(data);
          }
        }
      }, [data, id]);
    
      const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
        data: response,
      }: any = useCreateByID(apiname, id === 0 ? undefined : id);
    
      const deviceTypeFormData = (data: any) => {
        for (let prop in data) {
          if(data[prop] === null || data[prop] === undefined || data[prop] === "" || data[prop]?.length == 0){
            delete data[prop]
          }
        }
        let newData: any = {};
        Object.keys(data).forEach(
          (k) =>
            (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
        );
        mutate(newData);
      };
    
      useEffect(() => {
        if (isSuccess) {
          if (id === 0) {
            toast.success("Added Successfully", { position: "top-right" });
            fetch(true, response.data.Data);
          } else {
            toast.success("Updated Successfully", { position: "top-right" });
            fetch(true, response.data.Data);
          }
          setisOPen(false);
        }
    
        if (isError) {
          if (error?.response?.data?.StatusCode !== 400) {
            toast.error(error?.response?.data?.Message, { position: "top-right" });
          } else {
            toast.error("error...", { position: "top-right" });
          }
        }
      }, [isError, isSuccess, id]);

  return (
    <>
    <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Device Type`}
        modal
        style={{ width: "750px" }}
      >
        {!deviceTypeLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(deviceTypeFormData);
              }
            }}
            onSubmit={handleSubmit(deviceTypeFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-12"
                label="Device Type"
                type="text"
                isRequired
                errorMessage={
                  errors?.DeviceType1?.message ||
                  error?.response?.data?.errors?.DeviceType1?.map(
                    (e: any) => e
                  )
                }
                onChange={(e:any) => {setValue("DeviceType1", e.target.value); clearErrors(["DeviceType1"])}}
                register={{
                  ...register("DeviceType1", {
                    required: {
                      value: true,
                      message: "Device Type is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                      "Enter a valid device type (2-50 alphabet characters)",
                    }
                  }),
                }}
              />

<div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  )
}
