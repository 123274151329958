import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Badge } from "primereact/badge";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PaginationServiceByWarehouseStock } from "../../../service/PaginationService";
import { Paginator } from "primereact/paginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { NullHandler } from "../../../common/NullHandler";

const WarehouseStock = () => {
  const [selectedwarehouse, setselectedwarehouse]: any = useState(null);
  const [selectedProduct, setselectedProduct]: any = useState(null);
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);
  const [subexpandedRows, setSubExpandedRows]: any = useState(null);
  const [subpreviousId, setSubPreviousId]: any = useState(1);
  const [Nodata, setNodata] = useState(false);
  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
    paramter: null,
    product: null,
  });

  // useEffect(() => {
  //     loadLazyData();
  // }, [lazyState]);

  // useEffect(() => {
  //   if (selectedwarehouse !== null) {
  //     setlazyState({
  //       ...lazyState,
  //       paramter: selectedwarehouse,
  //     });
  //   }
  // }, [selectedwarehouse]);

  useEffect(() => {
    if (selectedwarehouse !== null) {
      setlazyState((prevState: any) => ({
        ...prevState,
        paramter: selectedwarehouse,
        product: selectedProduct?.value,
        first: 0,
        page: 1,
      }));
    }
  }, [selectedwarehouse, selectedProduct?.value]);

  useEffect(() => {
    if (selectedwarehouse !== null && lazyState?.paramter !== null) {
      loadLazyData();
    }
  }, [lazyState]);

  let networkTimeout: any = null;
  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByWarehouseStock.getData(lazyState)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
          setExpandedRows(null);
          setSubExpandedRows(null);
          if (data?.Data?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows(null);
    setSubExpandedRows(null);
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setExpandedRows(null);
    // setSubExpandedRows(null);
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8 mt-1">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const expandactionTemplate = (data: any) => {
    return data?.warehouseStockSalesDetailsViewModels?.length > 0;
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        {data?.warehouseStockDetailsViewModels?.length > 0 && (
          <DataTable
            value={data.warehouseStockDetailsViewModels}
            size="small"
            expandedRows={subexpandedRows}
            onRowToggle={(e) => {
              console.log("onRowToggle", e);
              let expanded: any = e.data;
              if (Object.keys(e.data).length > 1) {
                delete expanded[subpreviousId];
                setSubExpandedRows(expanded);
              } else {
                setSubExpandedRows(expanded);
              }
              if (Object.keys(e.data).length !== 0) {
                setSubPreviousId(Object.keys(e.data)[0]);
              }
            }}
            rowExpansionTemplate={rowSubExpansionTemplate}
          >
            {/* <Column expander={expandactionTemplate} style={{ width: "2%" }} /> */}
            <Column field="BatchNo" header="Batch"></Column>
            <Column field="Quantity" header="Qty"></Column>
            <Column field="StockType" header="Stock Type"></Column>
            <Column
              field="ManufactureDate"
              header="Mfg"
              body={(data: any) => {
                if (data.ManufactureDate === null) {
                  return "-";
                } else {
                  return (
                    <>
                      <Badge
                        className="m-1"
                        severity={"success"}
                        value={
                          <div
                            style={{
                              display: "inline-block",
                              maxWidth: "150px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                            }}
                            title={moment(data.ManufactureDate).format(
                              "DD MMM, YYYY"
                            )}
                          >
                            {moment(data.ManufactureDate).format(
                              "DD MMM, YYYY"
                            )}
                          </div>
                        }
                      ></Badge>
                    </>
                  );
                }
              }}
            ></Column>
            <Column
              field="ExpiryDate"
              header="Exp"
              body={(data: any) => {
                if (data.ExpiryDate === null) {
                  return "-";
                } else {
                  return (
                    <>
                      <Badge
                        className="m-1"
                        severity={"danger"}
                        value={
                          <div
                            style={{
                              display: "inline-block",
                              maxWidth: "150px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              verticalAlign: "middle",
                            }}
                            title={moment(data.ExpiryDate).format(
                              "DD MMM, YYYY"
                            )}
                          >
                            {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                          </div>
                        }
                      ></Badge>
                    </>
                  );
                }
              }}
            ></Column>
          </DataTable>
        )}
      </div>
    );
  };

  const rowSubExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <DataTable
          value={data.warehouseStockSalesDetailsViewModels}
          size="small"
        >
          <Column field="CustomerName" header="Customer"></Column>
          <Column field="SellQuantity" header="Sell Qty"></Column>
          <Column
            field="SellDate"
            header="Sell Date"
            body={(data: any) => {
              if (data.SellDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"info"}
                      value={
                        <div
                          style={{
                            display: "inline-block",
                            maxWidth: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            verticalAlign: "middle",
                          }}
                          title={moment(data.SellDate).format("DD MMM, YYYY")}
                        >
                          {moment(data.SellDate).format("DD MMM, YYYY")}
                        </div>
                      }
                    ></Badge>
                  </>
                );
              }
            }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Warehouse Stock</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Warehouse Stock</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/Inventory"}>Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Warehouse Stock
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <form>
            <div className="row">
              <ReactSelect
                containerClass={"col-lg-6"}
                isRequired
                label="Warehouse"
                name={"WarehouseId"}
                onChange={(e: any) => {
                  setselectedwarehouse(e);
                  setValue("WarehouseId", e);
                }}
                value={selectedwarehouse}
                api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.WarehouseId?.message}
              />
              <ReactSelect
                containerClass={"col-lg-6"}
                label="Product"
                name={"ProductId"}
                onChange={(e: any) => {
                  setselectedProduct(e);
                  setValue("ProductId", e);
                }}
                value={selectedProduct}
                api={process.env.REACT_APP_PRODUCT_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.ProductId?.message}
              />
            </div>
          </form>
        </div>

        {totalData?.length !== 0 && selectedwarehouse !== null ? (
          <>
            <div className="pd-20 card-box mb-30">
              <div className="row">
                <div className="col-md-12">
                  <DataTable
                    value={totalData}
                    scrollable
                    lazy
                    dataKey="id"
                    showGridlines
                    className="custom-datatable"
                    stripedRows
                    removableSort
                    header={header}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    onSort={OnSortChange}
                    rowHover
                    loading={loading}
                    size="small"
                    emptyMessage="No data found."
                    tableStyle={{ minWidth: "50rem" }}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => {
                      console.log("onRowToggle", e);
                      let expanded: any = e.data;
                      if (Object.keys(e.data).length > 1) {
                        delete expanded[previousId];
                        setExpandedRows(expanded);
                      } else {
                        setExpandedRows(expanded);
                      }
                      if (Object.keys(e.data).length !== 0) {
                        setPreviousId(Object.keys(e.data)[0]);
                      }
                    }}
                    rowExpansionTemplate={rowExpansionTemplate}
                  >
                    <Column expander style={{ width: "2%" }} />
                    <Column field="SrNo" header="#" style={{ width: "3%" }} />
                    <Column
                      field="ProductName"
                      header="Product"
                      sortable
                    ></Column>
                    <Column
                      field="GiftName"
                      header="Gift"
                      sortable
                      body={(e: any) => {
                        return NullHandler(e.GiftName);
                      }}
                    ></Column>
                    <Column
                      field="TotalQty"
                      header="Total Available Qty"
                      sortable
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </>
        ) : (
          Nodata && (
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box text-center">
                <h3 className="text-secondary">No data found.</h3>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default WarehouseStock;
