import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationServiceOfGetAccountsInvoice } from '../../../service/PaginationService';
import { Helmet } from 'react-helmet';
import { NullHandler } from '../../../common/NullHandler';
import { Badge } from 'primereact/badge';
import moment from 'moment';

const GetAccountsInvoice = () => {

  let navigate = useNavigate();

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);
  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfGetAccountsInvoice.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <DataTable value={data.AccountDetails} size="small">
          <Column
            field="Title"
            header="Title"
            body={(e: any) => {
              console.log(e);
              return <div className='d-flex  justify-content-between'>
                <div>{e.Title}</div>
                {
                  e.InvoiceId !== null && e.InvoiceId !== undefined &&
                  <div>
                    <Link to={`/layout/viewinvoice/ai/${e.InvoiceId}`} target="_blank" >
                      <button type="button" className="btn p-0" title="View">
                        <i className="bi bi-receipt text-success p-1" title="Invoice" />
                      </button>
                    </Link></div>
                }
              </div>
            }}
          ></Column>
          <Column field="PaymentCredit" header="Credit Amt"></Column>
          <Column field="PaymentDebit" header="Debit Amt"></Column>
          {/* <Column field="PaymentDue" header="Payment Due"></Column> */}
          <Column
            field="CreatedDate"
            header="Created Date"
            body={(e: any) => {
              if (e.CreatedDate === null) {
                return "-";
              } else {
                return (
                  <>
                    <Badge
                      className="m-1"
                      severity={"success"}
                      value={moment(e.CreatedDate).format("DD/MM/YY")}
                    ></Badge>
                    <Badge
                      className="m-1"
                      severity={"info"}
                      value={moment(e.CreatedDate).format("hh:mm A")}
                      style={{ backgroundColor: "#978EEB" }}
                    ></Badge>
                  </>
                );
              }
            }}
            bodyStyle={{ fontSize: "12px" }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Account Invoice</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Account Invoice</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/Inventory"}>Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Account Invoice
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column expander style={{ width: "5%", textAlign: "center" }} />
                <Column field="SrNo" header="#" style={{ width: "5%" }} />
                <Column
                  field="Name"
                  header="Name"
                  sortable
                  style={{ width: "30%" }}
                ></Column>
                <Column
                  field="PaymentCredit"
                  header="Total Credit Amt"
                  // sortable
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="PaymentDebit"
                  header="Total Debit Amt"
                  // sortable
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="PaymentDue"
                  header="Closing Balance"
                  // sortable
                  style={{ width: "20%" }}
                  body={(e: any) => {
                    return e.PaymentDue > 0
                      ? e.PaymentDue + " (CR)"
                      : Math.abs(e?.PaymentDue) + " (DR)";
                  }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetAccountsInvoice
