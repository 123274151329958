import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import Textarea from "../../../../common/Textarea";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import cameraLogo from "../../../../assets/images/Camera.png";
import { AddEditAchievementTypeModal } from "../../../master/submaster/achievementType/AddEditAchievementTypeModal";
import { Dialog } from "primereact/dialog";
import ButtonCommon from "../../../../common/ButtonCommon";
import { Button } from "primereact/button";
interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export default function AddEditAchievementsModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "AchievementType") {
      // fetchAOITOptions();
      setselectedtype(null);
      resetstate(false);
    }
  }, [refetchData]);
  const [AchievementImageDisplay, setAchievementImageDisplay]: any = useState(cameraLogo);
  const apiname = process.env.REACT_APP_DOCTORACHIEVEMENT_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (id) {
      if (data) {
        setAchievementImageDisplay(data.AchievementImagePath);
        const Date = data?.AchievementDate?.split("T")[0];
        reset(data);
        setValue("AchievementDate", Date);
        // setAchievementImage(data?.AchievementImage);
        if (
          data.AchievementTypeId !== null &&
          data.AchievementTypeId !== undefined
        ) {
          setValue("AchievementTypeId", {
            label: data.AchievementTypeName,
            value: data.AchievementTypeId,
          });
          setselectedtype({
            label: data.AchievementTypeName,
            value: data.AchievementTypeId,
          });
        } else {
          setValue("AchievementTypeId", null);
          setselectedtype(null);
        }
      }
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };
  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = cameraLogo;
  };
  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [AchievementImage, setAchievementImage]: any = useState(null);
  const [selectedtype, setselectedtype]: any = useState(null);

  const [achievement, setachievement]: any = useState(null);
  const [defaultValue, setdefaultValue]: any = useState(null);
  const handleachivementChange = (inputvalue: any) => {
    setachievement(0);
    setdefaultValue(inputvalue);
  };

  const achievementFormData = (form: any) => {

    // console.log(form.AchievementTypeId)
    form.DoctorId = Number(doctorid);
    if (AchievementImage !== null) {
      form.AchievementImage = AchievementImage[0];
    }
    if (typeof form.AchievementTypeId == "object") {

      form.AchievementTypeId = Number(form.AchievementTypeId?.value);
    }
    else {
      form.AchievementTypeId = Number(form.AchievementTypeId);

    }

    for (let prop in form) {
      if (
        form[prop] === null ||
        form[prop] === undefined ||
        form[prop] === "" ||
        form[prop]?.length == 0
      ) {
        delete form[prop];
      }
    }
    console.log(form, "New Value");

    let newData: any = {};
    Object.keys(form).forEach(
      (k) =>
        (newData[k] = typeof form[k] == "string" ? form[k].trim() : form[k])
    );
    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Achievements Details`}
        modal
        style={{ width: "1000px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(achievementFormData);
              }
            }}
            onSubmit={handleSubmit(achievementFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Achievements"
                control={control}
                onDelete={(deletedValue: number) => {
                  deleteFunction("AchievementType", deletedValue);
                }}
                updateid={setachievement}
                name="AchievementTypeId"
                isRequired
                onChange={(e: any) => {
                  console.log(e)
                  if (e?.value !== undefined) {
                    setValue("AchievementTypeId", e?.value);
                    setselectedtype(e);
                  } else {
                    setValue("AchievementTypeId", null);
                    setselectedtype(null);
                  }
                }}
                value={selectedtype}
                keys={{ label: "Value", value: "Id" }}
                api={process.env.REACT_APP_ACHIEVEMENTTYPE_API}
                handleCreate={handleachivementChange}
                errorMessage={
                  errors?.AchievementTypeId?.message ||
                  error?.response?.data?.errors?.AchievementTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Achievement Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Date"
                // type="datetime-local"
                type="date"
                isRequired
                onChange={(e: any) => {
                  setValue("AchievementDate", e.target.value);
                  clearErrors(["AchievementDate"]);
                }}
                errorMessage={
                  errors?.AchievementDate?.message ||
                  error?.response?.data?.errors?.AchievementDate?.map(
                    (e: any) => e
                  ) ||
                  errors?.AchievementDate?.Message
                }
                register={{
                  ...register("AchievementDate", {
                    required: { value: true, message: "Date is required" },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={500}
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                register={{
                  ...register("Remarks"),
                }}
              />

              <div className={"col-lg-4"}>
                <div className="form-group image-upload">
                  <label className="col-form-label">Achievement Image</label>
                  <div>
                    <label style={{ cursor: "pointer" }}>
                      <img src={AchievementImageDisplay} alt="Click Here to add Image" className="uploaded-image" onError={handleError} width={"150px"} height={"150px"} />

                      <input
                        {...register("AchievementImage")}
                        type="file"
                        data-required="image"
                        onChange={(e: any) => {
                          if (e.target.files[0]) {
                            const filePath = URL.createObjectURL(e.target.files[0]);
                            setAchievementImageDisplay(filePath);
                          }
                          setAchievementImage(e.target.files)
                        }
                        }
                        className="form-control image-input"
                        hidden
                        accept=".pdf,image/*"
                      />
                    </label>
                  </div>
                  {
                    AchievementImageDisplay !== cameraLogo &&
                    (AchievementImage == null ? (
                      <>
                        <a href={`${data?.AchievementImagePath}`} target="blank">
                          {data?.AchievementImage}
                        </a>
                        {/* <i className="bi bi-x-circle-fill ml-2" style={{ cursor: "pointer" }} title="Remove Image" onClick={() => {
                          setValue("AchievementImage", null);
                          setValue("AchievementImagePath", null);
                          setAchievementImage(null);
                          setAchievementImageDisplay(cameraLogo);

                        }}></i> */}
                      </>
                    ) : (

                      <p>{AchievementImage[0]?.name}
                        {/* <i className="bi bi-x-circle-fill ml-2" style={{ cursor: "pointer" }} title="Remove Image" onClick={() => {
                          setValue("AchievementImage", null);
                          setValue("AchievementImagePath", null);
                          setAchievementImage(null);
                          setAchievementImageDisplay(cameraLogo);

                        }}></i> */}
                      </p>

                    )
                    )}

                  {!data.AchievementImage && AchievementImage == null && (
                    <ErrorMessage message={errors?.AchievementImage?.message} />
                  )}
                </div>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedtype(null); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
        {achievement !== null && (
          <AddEditAchievementTypeModal
            defaultValue={defaultValue}
            setisOPen={() => {
              return setachievement(null);
            }}
            fetch={(data: boolean, res: any) => {
              if (data) {
                setValue("AchievementTypeId", res.AchievementTypeId);
                setselectedtype({
                  label: res.AchievementTypeName,
                  value: res.AchievementTypeId,
                });
                // fetchAOITOptions();
              }
            }}
            open={achievement !== null}
            id={achievement}
          />
        )}
      </Dialog>
    </>
  );
}
