import React, { useState } from "react";
import { NullHandler } from "../../../common/NullHandler";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import AddEditInventoryCurrency from "./AddEditInventoryCurrency";
import { DeleteModal } from "../../../common/DeleteModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const InventoryCurrency = () => {
  const apiname = process.env.REACT_APP_INVENTORYCURRENCY_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedCurrency, setSelectedCurrency]: any = useState([]);
  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      body: (e: any) => actionCurrencyTemplate(e),
      width: "10%",
    },
    {
      field: "Name",
      header: "Currency",
      bodyClassName: "text-info h6",
      sortable: true,
      body: null,
      width: "30%",
      filter: true,
      filterPlaceholder: "Search by Currency",
    },
    {
      field: "Symbol",
      header: "Symbol",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Symbol);
      },
      width: "30%",
      // filter: true,
      filterPlaceholder: "Search by Symbol",
    },
    {
      field: "Format",
      header: "Format",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Format);
      },
      width: "30%",
      // filter: true,
      filterPlaceholder: "Search by Format",
    },

  ];

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    // Symbol: {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    // },
    // Format: {
    //   operator: FilterOperator.AND,
    //   constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    // },
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionCurrencyTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <>
        <Helmet>
          <title>Currency</title>
        </Helmet>
        <div className="min-height-200px">
          <PageHeader
            headTitle="Currency"
            onClick={() => {
              setupdateId(0);
            }}
            id={0}
            list={true}
            linkflag
            linkflagURL={"/layout/InventoryMaster"}
            linkflagtitle={"Master"}
          />

          <DatatableCommon
            apiname={apiname}
            setselecteditem={setSelectedCurrency}
            selecteditem={selectedCurrency}
            columnData={columnData}
            refetchData={bool}
            setrefetchData={setbool}
            filterJson={filterJson}
          />

          {updateId !== null && (
            <AddEditInventoryCurrency
              fetch={(data: boolean) => {
                if (data) {
                  setbool(true);
                }
              }}
              setisOPen={() => {
                return setupdateId(null);
              }}
              open={updateId !== null}
              id={updateId}
            />
          )}
        </div>

        <DeleteModal
          id={deleteID}
          deleteMessage={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
          apiname={apiname}
          setisOPen={() => setdeleteID(null)}
          open={deleteID != null}
        />
      </>
    </>
  );
};

export default InventoryCurrency;
