import { Helmet } from 'react-helmet'
import { PageHeader } from '../../../common/PageHeader'

const PaymentSalesInvoice = () => {
  return (
    <div>
    <Helmet>
      <title>Payment Sales Invoice</title>
    </Helmet>
    <div className="min-height-200px">
      <PageHeader headTitle="Payment Sales Invoice" list={true} LinkURL={"/layout/inventory/AddPaymentSalesInvoice"} />
    </div>
  </div>
  )
}

export default PaymentSalesInvoice
