import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import AddEditHospitalContactPerson from "./AddEditHospitalContactPerson";
import DatatableCommon from "../../../../common/DatatableCommon";

export const HospitalContactPerson = () => {
  const apiname = process.env.REACT_APP_HOSPITALCONTACTPERSON_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.HospitalContactPersonId;
  });

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistContactPersonTemplate(e),
      sortable: false,
      width: "1vw",
    },
    {
      field: "HospitalContactPersonName",
      header: "Hospital Contact Person",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "HospitalName",
      header: "Hospital Name",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "MobileNo",
      header: "Mobile Number",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "Email",
      header: "Email",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "HospitalContactPersonAddress",
      header: "Address",
      // body: null,
      body: (data: any) => {
        return (
          <p
            title={data.HospitalContactPersonAddress}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              margin: 0,
              padding: 0,
            }}
          >
            {data.HospitalContactPersonAddress === ""
              ? " - "
              : data.HospitalContactPersonAddress}
          </p>
        );
      },
      sortable: true,
      width: "10vw",
    },
  ];

  const actionChemistContactPersonTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.HospitalContactPersonId}
          editModal={() => {
            setupdateId(id.HospitalContactPersonId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <>
      <Helmet>
        <title> Hospital Contact Person </title>
      </Helmet>

      <PageHeader
        headTitle="Hospital Contact Person"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
        linkflag
        linkflagURL={"/layout/hospitalattributes"}
        linkflagtitle={"Hospital Attributes"}
      />

      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          // check={true}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
        />
        {updateId !== null && (
          <AddEditHospitalContactPerson
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
