import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useCreateByID, useFetchAllID } from '../../../../hooks/Api/Api';
import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Spinner } from '../../../../common/Spinner';
import { Input } from '../../../../common/Input';
import { Button } from 'primereact/button';

interface iPropsmodal {
    id?: any;
    setisOPen?: any;
    open?: any;
    fetch?: any;
    defaultValue?: any;
  }

export const AddEditHqType = ({
    id,
    setisOPen,
    open,
    fetch,
    defaultValue,
  }: iPropsmodal) => {

    const apiname = process.env.REACT_APP_HQTYPE_API?.toString();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        reset,
      }: any = useForm({ mode: "all" });
    
      useEffect(() => {
        if (defaultValue) {
          setValue("HqTypeName", defaultValue);
        }
      }, [defaultValue]);
    
      const { data, isLoading: hqTypeLoader }: any = useFetchAllID(
        apiname,
        id === 0 ? undefined : id
      );
    
      useEffect(() => {
        if (data) {
          reset(data);
        }
      }, [data]);
    
      const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
        data: response,
      }: any = useCreateByID(apiname, id === 0 ? undefined : id);
    
      const hqTypeFormData = (data: any) => {
        for (let prop in data) {
          if(data[prop] === null || data[prop] === undefined || data[prop] === "" || data[prop]?.length == 0){
            delete data[prop]
          }
        }
        let newData: any = {};
        Object.keys(data).forEach(
          (k) =>
            (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
        );
        mutate(newData);
      };
    
      useEffect(() => {
        if (isSuccess) {
          if (id === 0) {
            toast.success("Added Successfully", { position: "top-right" });
            fetch(true, response.data.Data);
          } else {
            toast.success("Updated Successfully", { position: "top-right" });
            fetch(true, response.data.Data);
          }
          setisOPen(false);
        }
    
        if (isError) {
          if (error?.response?.data?.StatusCode !== 400) {
            toast.error(error?.response?.data?.Message, { position: "top-right" });
          } else {
            toast.error("error...", { position: "top-right" });
          }
        }
      }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} HQ Type`}
        modal
        style={{ width: "750px" }}
      >
        {!hqTypeLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(hqTypeFormData);
              }
            }}
            onSubmit={handleSubmit(hqTypeFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-12"
                label="HQ Type"
                type="text"
                isRequired
                errorMessage={
                  errors?.HqTypeName?.message ||
                  error?.response?.data?.errors?.HqTypeName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e:any) => {setValue("HqTypeName", e.target.value); clearErrors(["HqTypeName"])}}
                register={{
                  ...register("HqTypeName", {
                    required: {
                      value: true,
                      message: "HQ Type is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid Hq Type (2-50 characters)",
                    }
                  }),
                }}
              />

<div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  )
}
