import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import { Button } from "primereact/button";


interface iPropsmodal {
    id?: any;
    setisOPen?: any;
    open?: any;
    fetch?: any;
    defaultValue?: any;
}
export const AddEditPaymentType = ({
    id,
    setisOPen,
    open,
    fetch
}: iPropsmodal) => {
    const apiname = process.env.REACT_APP_INVENTORYPAYMENTTYPE_API?.toString();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        reset
    }: any = useForm({ mode: "all" });

    const { data, isLoading: SampleLoader }: any = useFetchAllID(
        apiname,
        id === 0 ? undefined : id
    );

    useEffect(() => {

        if (data) {
            reset(data);
        }
    }, [data]);

    const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
        data: response,
    }: any = useCreateByID(apiname, id === 0 ? undefined : id);



    const PaymentTypeFormData = (data: any) => {
        let submitData = {};
        if (id) {
            submitData = {
                PaymentTypeToUpdate: data
            }
        }
        else {

            submitData = {
                PaymentTypeToCreate: data
            }
        }
        mutate(submitData);
    };

    useEffect(() => {
        if (isSuccess) {
            if (id === 0) {
                toast.success("Added Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            } else {
                toast.success("Updated Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            }
            setisOPen(false);
        }

        if (isError) {
            if (error?.response?.data?.StatusCode !== 400) {
                toast.error(error?.response?.data?.Message, { position: "top-right" });
            } else {
                toast.error("error...", { position: "top-right" });
            }
        }
    }, [isError, isSuccess, id]);

    return <>
        <Dialog
            visible={open}
            onHide={() => setisOPen(false)}
            header={`${id === 0 ? "Add" : "Edit"} Payment Type`}
            modal
            style={{ width: "1100px" }}
        >
            {!SampleLoader ? (
                <form onSubmit={handleSubmit(PaymentTypeFormData)}>
                    <div className="row">
                        <Input
                            containerClass="col-lg-12"
                            label="Payment Type  Name"
                            type="text"
                            isRequired
                            onChange={(e: any) => {
                                setValue("Name", e.target.value);
                                clearErrors(["Name"]);
                            }}
                            register={{
                                ...register("Name", {
                                    required: {
                                        value: true,
                                        message: "Payment Type  Name is required",
                                    },

                                }),
                            }}
                            errorMessage={
                                errors?.Name?.message ||
                                error?.response?.data?.errors?.Name?.map(
                                    (e: any) => e
                                )
                            }
                        />
                        <div className="col-lg-12 text-center mt-3">
                            <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                                <span className="p-button-label p-c">Back</span>
                            </Button>

                            {
                                id === 0 && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                                    <span className="p-button-label p-c">Reset</span>
                                </Button>
                            }

                            {isLoading ? (
                                <Button type="submit" outlined disabled className="ml-2">
                                    <span className="p-button-label p-c">
                                        Submit <Spinner width={"1rem"} height={"1rem"} />
                                    </span>
                                </Button>
                            ) : (
                                <Button type="submit" outlined className="ml-2">
                                    <span className="p-button-label p-c">Submit</span>
                                </Button>
                            )}
                        </div>
                    </div>
                </form>
            ) : (
                <div className="pd-20 card-box mb-30 text-center">
                    <Spinner width={"15rem"} height={"15rem"} />
                </div>
            )}
        </Dialog></>;
};


