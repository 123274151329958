import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import DatatableCommon from '../../../../common/DatatableCommon';
import { PageHeader } from '../../../../common/PageHeader';
import { AddEditHqType } from './AddEditHqType';
import { DeleteModal } from '../../../../common/DeleteModal';
import { Action } from '../../../../common/Action';

export const HqType = () => {

  const apiname = process.env.REACT_APP_HQTYPE_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);

  const filterJson = {
    HqTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "5%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionCallTypeTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "HqTypeName",
      header: "Hq Type",
      bodyClassName: "text-info h6",
      filter: true,
      body: null,
      filterPlaceholder: "Search by hq type",
      sortable: true,
      width: "10vw",
    },

  ];

  const actionCallTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.HqTypeId}
          editModal={() => {
            setupdateId(id.HqTypeId)
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };


  return (
    <>
      <Helmet>
        <title>HQ Type</title>
      </Helmet>

      <PageHeader headTitle="HQ Type" onClick={() => { setupdateId(0); }} id={0} list={true} />

      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          filterJson={filterJson}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
        />
        {updateId !== null && (
          <AddEditHqType
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true) } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  )
}
