import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import { DeleteModal } from "../../../common/DeleteModal";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AddEditDivision from "../../master/submaster/division/AddEditDivision";

export const Account = () => {
  const apiname = process.env.REACT_APP_USER_API?.toString();
  const navigate = useNavigate();
  const { id } = useParams();
  const controller = new AbortController();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    clearErrors,
    watch,
  }: any = useForm();

  const { data, isLoading: accountLoader }: any = useFetchAllID(apiname, id, undefined, controller.signal);

  const password = useRef({});
  password.current = watch("Password", "");

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );

  const z = watch("ZoneId");
  const s = watch("StateId");
  const d = watch("RegionId");
  const c = watch("DistrictId");
  const D = watch("DesignationId");

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/mr/admin/accountList");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/mr/admin/accountList");
      }
    }
    if (isError) {
      toast.error("Error....", { position: "top-right" });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        if (data?.divisionLists) {
          let newData: any =
            data?.divisionLists?.map((d: any) => ({
              label: d?.DivisionName,
              value: d?.DivisionId,
            }));
          setselecteddivision([...newData]);
          setValue("DivisionId", newData?.map((data: any) => data.value))
        }


        // setselecteddivision({
        //   label: data.DivisionName,
        //   value: data.DivisionId,
        // });
        setselectedHq({
          label: data.HqName,
          value: data.HqId,
        });
        setselectedDesignation({
          label: data.DesignationName,
          value: data.DesignationId,
        });
        setselectedEmployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        });
        setselectedUser({
          label: data.ReportingSeniorName,
          value: data.ReportingSeniorId,
        });
        setselectedZone({
          label: data.ZoneName,
          value: data.ZoneId,
        });
        setselectedState({
          label: data.StateName,
          value: data.StateId,
        });
        setselectedrigion({
          label: data.RegionName,
          value: data.RegionId,
        });
        setselecteddistrict({
          label: data.DistrictName,
          value: data.DistrictId,
        });
        setselectedcity({
          label: data.CityName,
          value: data.CityId,
        });
        data.UserRoleViewModels = data?.UserRoleViewModels?.map((d: any) => ({
          label: d?.RoleName,
          value: d?.RoleId,
        }));
        setValue("RoleId", data.UserRoleViewModels);
        setselectedRole(data?.UserRoleViewModels);
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  // Division
  const [selecteddivision, setselecteddivision]: any = useState([]);

  const [division, setdivision]: any = useState(null);
  const [defaultValues, setdefaultValue]: any = useState(null);
  const handledivisionchange = (inputvalue: any) => {
    setdivision(0);
    setdefaultValue(inputvalue);
  };

  // Hq
  const [selectedHq, setselectedHq]: any = useState(null);

  // Designation
  const [selectedDesignation, setselectedDesignation]: any = useState(null);

  // Employee
  const [selectedEmployee, setselectedEmployee]: any = useState(null);

  // Role
  const [selectedRole, setselectedRole] = useState([]);

  // User
  const [selectedUser, setselectedUser] = useState({});

  // Zone
  const [selectedZone, setselectedZone]: any = useState(null);

  // State
  const [selectedState, setselectedState] = useState({});

  // Region
  const [selectedrigion, setselectedrigion] = useState({});

  // District
  const [selecteddistrict, setselecteddistrict] = useState({});

  // City
  const [selectedcity, setselectedcity] = useState({});

  const [deleteID, setdeleteID]: any = useState(null);
  const [api, setApi] = useState("");

  const accountFormData = (data: any) => {

    data.RoleId = selectedRole?.map((e: any) => {
      return e?.value;
    });
    console.log(data.RoleId, "data.RoleId");

    delete data.CPassword;
    delete data.StateId;
    delete data.DistrictId;
    mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>{id === undefined ? "Add" : "Edit"} Account </title>
      </Helmet>
      <PageHeader
        headTitle="Account"
        headTitleURL={"/layout/mr/admin/accountList"}
        list={false}
        id={id}
      />
      {!accountLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              accountFormData(productinfo);
            })}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="User Name"
                type="text"
                minLength={2}
                maxLength={50}
                isRequired
                errorMessage={
                  errors?.UserName?.message ||
                  error?.response?.data?.errors?.UserName?.map((e: any) => e)
                }
                onChange={() => clearErrors(["UserName"])}
                register={{
                  ...register("UserName", {
                    required: {
                      value: true,
                      message: "User Name is required",
                    },
                  }),
                }}
              />

              {id === undefined ? (
                <Input
                  containerClass="col-lg-4"
                  label="Password"
                  type="password"
                  minLength={6}
                  maxLength={10}
                  isRequired
                  errorMessage={
                    errors?.Password?.message ||
                    error?.response?.data?.errors?.Password?.map((e: any) => e)
                  }
                  onChange={() => clearErrors(["Password"])}
                  register={{
                    ...register("Password", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      // pattern: {
                      //   value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,10}$/,
                      //   message:
                      //     "Check a password between 8 to 10 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
                      // }
                    }),
                  }}
                />
              ) : (
                ""
              )}

              {id === undefined ? (
                <Input
                  containerClass="col-lg-4"
                  label="Confirm-Password"
                  type="password"
                  minLength={6}
                  maxLength={10}
                  isRequired
                  errorMessage={
                    errors?.CPassword?.message ||
                    error?.response?.data?.errors?.CPassword?.map((e: any) => e)
                  }
                  onChange={() => clearErrors(["CPassword"])}
                  register={{
                    ...register("CPassword", {
                      required: {
                        value: true,
                        message: "Confirm-Password is required",
                      },
                      validate: (value: any) =>
                        value === password.current ||
                        "The password do not match",
                    }),
                  }}
                />
              ) : (
                ""
              )}

              {/* <ReactSelect
                containerClass="col-lg-4"
                label="Division"
                name={"DivisionId"}
                isRequired
                Multi
                onChange={(e: any) => {
                  setselecteddivision(e);
                  setValue("DivisionId", e.map((data: any) => data.value));
                }}
                updateid={setdivision}
                onDelete={(deletedValue: number) => {
                  setdeleteID(deletedValue);
                  setApi("Division");
                }}

                value={selecteddivision}
                control={control}
                handleCreate={handledivisionchange}
                api={process.env.REACT_APP_DIVISION_API}
                keys={{ label: "Value", value: "Id" }}
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={
                  errors?.DivisionId?.message ||
                  error?.response?.data?.errors?.DivisionId?.map((e: any) => e)
                }
              /> */}

              <ReactSelect
                containerClass="col-lg-4"
                label="Hq"
                control={control}
                name="HqId"
                isRequired
                onChange={(e: any) => {
                  setselectedHq(e);
                  setValue("HqId", e?.value);
                }}
                value={selectedHq}
                api={process.env.REACT_APP_HQ_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.HqId?.message ||
                  error?.response?.data?.errors?.HqId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Hq is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Designation"
                control={control}
                name="DesignationId"
                isRequired
                onChange={(e: any) => {
                  setselectedDesignation(e);
                  setValue("DesignationId", e?.value);
                }}
                value={selectedDesignation}
                api={process.env.REACT_APP_DESIGNATION_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DesignationId?.message ||
                  error?.response?.data?.errors?.DesignationId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Designation is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Employee"
                control={control}
                name="EmployeeId"
                isRequired
                onChange={(e: any) => {
                  setselectedEmployee(e);
                  setValue("EmployeeId", e?.value);
                }}
                value={selectedEmployee}
                api={process.env.REACT_APP_EMPLOYEE_WITHOUT_DESIGNATION_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Employee is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Role"
                control={control}
                name="RoleId"
                isRequired
                onChange={(e: any) => {
                  setselectedRole(e);
                  setValue("RoleId", e);
                }}
                Multi
                value={selectedRole}
                api={process.env.REACT_APP_ROLE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.RoleId?.message ||
                  error?.response?.data?.errors?.RoleId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Role is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Reporting Senior"
                control={control}
                name="ReportingSeniorId"
                isRequired
                onChange={(e: any) => {
                  setselectedUser(e);
                  setValue("ReportingSeniorId", e?.value);
                }}
                value={selectedUser}
                api={D && `${process.env.REACT_APP_DESIGNATION_BY_PARENT_API}?designationId=${D}`}
                keys={{ label: "Value", value: "Id" }}
                flag
                Disabled={D ? false : true}
                errorMessage={
                  errors?.ReportingSeniorId?.message ||
                  error?.response?.data?.errors?.ReportingSeniorId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Reporting Senior is required",
                  },
                }}
              />
              <ReactSelect
                containerClass="col-lg-4"
                label="Zone"
                control={control}
                name="ZoneId"
                isRequired
                onChange={(e: any) => {
                  setselectedZone(e);
                  setValue("ZoneId", e?.value);
                }}
                value={selectedZone}
                api={process.env.REACT_APP_ZONE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.ZoneId?.message ||
                  error?.response?.data?.errors?.ZoneId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Zone is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="State"
                control={control}
                name="StateId"
                isRequired
                onChange={(e: any) => {
                  setselectedState(e);
                  setValue("StateId", e?.value);
                }}
                value={selectedState}
                api={z && `${process.env.REACT_APP_STATE_BY_PARENT_IN_STATE_API}?ZoneId=${z}`}
                keys={{ label: "Value", value: "Id" }}
                flag
                Disabled={z ? false : true}
                errorMessage={
                  errors?.StateId?.message ||
                  error?.response?.data?.errors?.StateId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "State is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Region"
                control={control}
                name="RegionId"
                isRequired
                onChange={(e: any) => {
                  setselectedrigion(e);
                  setValue("RegionId", e?.value);
                }}
                value={selectedrigion}
                api={s && `${process.env.REACT_APP_STATE_BY_PARENT_API}?StateId=${s}`}
                keys={{ label: "Value", value: "Id" }}
                flag
                Disabled={s ? false : true}
                errorMessage={
                  errors?.RegionId?.message ||
                  error?.response?.data?.errors?.RegionId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Region is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="District"
                control={control}
                name="DistrictId"
                isRequired
                onChange={(e: any) => {
                  setselecteddistrict(e);
                  setValue("DistrictId", e?.value);
                }}
                value={selecteddistrict}
                api={d && `${process.env.REACT_APP_DISTRICT_BY_PARENT_API}?RegionId=${d}`}
                keys={{ label: "Value", value: "Id" }}
                flag
                Disabled={d ? false : true}
                errorMessage={
                  errors?.DistrictId?.message ||
                  error?.response?.data?.errors?.DistrictId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "District is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="City"
                control={control}
                name="CityId"
                isRequired
                onChange={(e: any) => {
                  setselectedcity(e);
                  setValue("CityId", e?.value);
                }}
                value={selectedcity}
                api={c && `${process.env.REACT_APP_CITY_BY_PARENT_API}?DistrictId=${c}`}
                keys={{ label: "Value", value: "Id" }}
                flag
                Disabled={c ? false : true}
                errorMessage={
                  errors?.CityId?.message ||
                  error?.response?.data?.errors?.CityId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "City is required",
                  },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Link
                  to={"/layout/mr/admin/accountList"}
                  className="btn btn btn-outline-secondary mt-2 mx-2"
                >
                  Back
                </Link>
                {id === undefined ? (
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-2 mx-2"
                    onClick={() => {
                      reset();
                      setselectedDesignation(null);
                      setselectedEmployee(null);
                      setselectedHq(null);
                      setselectedRole([]);
                      setselectedState({});
                      setselectedUser({});
                      setselectedZone(null);
                      setselectedcity({});
                      setselecteddistrict({});
                      setselecteddivision([]);
                      setselectedrigion({});
                    }}
                  >
                    Reset
                  </button>
                ) : (
                  ""
                )}

                {!isLoading ? (
                  <button
                    className="btn btn-outline-primary mt-2 mx-2"
                    type="submit"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary mt-2 mx-2"
                    type="submit"
                    disabled
                  >
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}

      {division !== null && (
        <AddEditDivision
          defaultValue={defaultValues}
          setisOPen={() => {
            return setdivision(null);
          }}
          fetch={(data: boolean, res: any) => {
            if (data) {
              let newData: any =
                res?.divisionLists?.map((d: any) => ({
                  label: d?.DivisionName,
                  value: d?.DivisionId,
                }));
              setselecteddivision([...newData]);
              setValue("DivisionId", newData?.map((data: any) => data.value))
              //data
              // setValue("DivisionId", res.DivisionId);
              // setselecteddivision({
              //   label: res.DivisionName,
              //   value: res.DivisionId,
              // });
              // fetchdivisionOptions();
            }
          }}
          open={division !== null}
          id={division}
        />
      )}

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean, e: any) => {
          if (data) {
            if (e == "Division") {
              // fetchdivisionOptions();
              setselecteddivision([]);
            }
          }
        }}
        apiname={api}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
