import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../_helper/Interceptor";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditChemistEmployeeMap = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_CHEMISTEMPLOYEEMAPPING_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    error,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: employeechemistmap }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error: employeechemistError,
    data: response,
  }: any = useCreateByID(apiname, undefined);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselectedemployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        });


        let newData =
          data?.ChemistList?.map((d: any) => ({
            label: d?.Value,
            value: d?.Id,
          }));
        setselectedchemist([...newData]);
        setValue("ChemistIdList", newData.map((data: any) => data.value))
      }

    }
  }, [data]);

  const [selectedemployee, setselectedemployee]: any = useState(null);
  const [selectedchemist, setselectedchemist]: any = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const doctorchemistFormData = (data: any) => {
    data.EmployeeId = Number(data.EmployeeId);
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (employeechemistError?.response?.data?.StatusCode !== 400) {
        toast.error(employeechemistError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Employee Chemist`}
        modal
        style={{ width: "1250px" }}
      >
        {!employeechemistmap ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(doctorchemistFormData);
              }
            }}
            onSubmit={handleSubmit(doctorchemistFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Employee"
                name={"EmployeeId"}
                isRequired
                Disabled={id > 0 ? true : false}
                onChange={(e: any) => {
                  axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${e?.value}`).then((res) => {

                    const newChemistData = res?.data?.Data?.ChemistList?.map((d: any) => {
                      return {
                        label: d?.Value,
                        value: d?.Id,
                      }
                    });
                    setselectedchemist([...newChemistData]);
                    setValue("ChemistIdList", newChemistData.map((data: any) => data.value))

                  }).catch((err) => { setselectedchemist(null); setValue("ChemistIdList", null) })
                  setselectedemployee(e);
                  setIsEdited(true);
                  setValue("EmployeeId", e?.value);
                }}
                value={selectedemployee}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Employee is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Chemist"
                name={"ChemistIdList"}
                isRequired
                Multi
                onChange={(e: any) => {
                  setselectedchemist(e);
                  setIsEdited(true);
                  setValue("ChemistIdList", e.map((data: any) => {
                    if (typeof data?.value == "object") {
                      return data?.value?.value
                    }
                    else {

                      return data?.value
                    }
                  }));

                }}
                Disabled={selectedemployee === null}
                value={selectedchemist}
                control={control}
                api={process.env.REACT_APP_CHEMIST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.ChemistIdList?.message ||
                  error?.response?.data?.errors?.ChemistIdList?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Chemist is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedchemist([]); setselectedemployee(null) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2" disabled={id && !isEdited}>
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
