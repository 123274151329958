import { useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { Action } from "../../../common/Action";
import { NullHandler } from "../../../common/NullHandler";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const VAUpload = () => {
  const navigate =useNavigate();
  const apiname = process.env.REACT_APP_VAUPLOAD_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setSelectedRow]: any = useState([]);
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const columns = [
    {
      header: "#",
      field: "srNo",
      width: "3%",
    },
    {
      header: "Action",
      body: (e: any) => actioncrmAccountTemplate(e),
      sortable: false,
      width: "5%",
      style: { width: "5%", minWidth: "5%", maxWidth: "5%" }
    },
    {
      field: "VaUploadName",
      header: "VA Upload Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "20%",
      style: { width: "20%", minWidth: "20%", maxWidth: "20%" }
    },
    {
      field: "ProductName",
      header: "Product Name",

      body: (data: any) => {
        return NullHandler(data.ProductName);
      },
      sortable: true,
      width: "10%",
      style: { width: "10%", minWidth: "10%", maxWidth: "10%" }
    },
    {
      field: "MediaName",
      header: "Media Name",

      body: (data: any) => {
        return NullHandler(data.MediaName);
      },
      sortable: true,
      width: "10%",
      style: { width: "10%", minWidth: "10%", maxWidth: "10%" }
    },
    {
      field: "FilePath",
      header: "Image",
      body: (data: any) => {
        const linkStyle = {
          color: '#3b82f6',
          textDecoration: 'none',
          transition: 'color 0.2s ease',
        };
    
        const hoverStyle = {
          color: 'blue',
        };
    
        return data.FilePath ? (
          // <Link
          //  target="_blank"
          //   style={linkStyle}
          //   to={data.FilePath}
          //   onMouseEnter={(e) => (e.currentTarget.style.color = hoverStyle.color)}
          //   onMouseLeave={(e) => (e.currentTarget.style.color = linkStyle.color)}
          // >
          //   {data.FilePath}
          // </Link>
        //   <button className="btn p-0" title="View Image" onClick={()=>navigate(data.FilePath)}>
        //   <i className="bi bi-image"> </i>
        // </button>
        <Link
           target="_blank"
            style={linkStyle}
            to={data.FilePath}
            onMouseEnter={(e) => (e.currentTarget.style.color = hoverStyle.color)}
            onMouseLeave={(e) => (e.currentTarget.style.color = linkStyle.color)}
          >
            {/* <Button className="btn p-0" title="View Image">
                  <i className="bi bi-image"> </i>      
            </Button> */}
{/* <Button variant="outli">
  <i className="bi bi-image"> View Image</i>
</Button> */}
        <button type="button" className="btn btn-outline-secondary">
          <i className="bi bi-image">View Image</i>
        </button>
          </Link>
        ) : (
          NullHandler(data.FilePath)
        );
      },
      width: "12%",
      style: { minWidth: "35%", maxWidth: "35%" }
    },    
    {
      field: "Description",
      header: "Description",

      body: (data: any) => {
        return NullHandler(data.Description);
      },
      sortable: true,
      width: "10%",
      style: { width: "10%", minWidth: "10%", maxWidth: "10%" }
    },
    // {
    //   field: "Url",
    //   header: "Url",

    //   body: (data: any) => {
    //     return NullHandler(data.Url);
    //   },
    //   sortable: true,
    //   width: "10%",
    //   style: { width: "10%", minWidth: "10%", maxWidth: "10%" }
    // },

  ];

  const actioncrmAccountTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.VaUploadId}
          editurl={"layout/master/visualaid/EditVAUpload"}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>VA Upload</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader headTitle="VA Upload" list={true} LinkURL={"/layout/master/visualaid/AddVAUpload"} />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setSelectedRow}
          selecteditem={selectedRow}
        // check={true}
        />
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true) } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </div>
  )
}

export default VAUpload
