import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axiosInstance from "../../_helper/Interceptor";
import { SelectButton } from "primereact/selectbutton";
import Select from "react-select";
import { Spinner } from "../../common/Spinner";
import { Helmet } from "react-helmet";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MainDashboard = () => {
  const [selectedHq, setselectedHq]: any = useState(null);
  const [selectedRegion, setselectedRegion]: any = useState(null);
  const [selected, setSelected]: any = useState(null);
  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  let division: any = localStorage.getItem("Division");
  division = JSON.parse(division || {});

  const [loader, setLoader] = useState(false);

  // First Chart
  let [PrimarySaleGrowth, setPrimarySaleGrowth]: any = useState([]);

  console.log(PrimarySaleGrowth?.SalesTarget, "PrimarySaleGrowth");

  let PrimarySalePer = () => {
    setLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_DASHBOARDGETPRIMARYSECONDARYDATA_API
        }?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`
      )
      .then((res) => {
        setPrimarySaleGrowth(res?.data?.Data[0] || []);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setPrimarySaleGrowth([]);
        setLoader(false);
      });
  };

  const options: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
    // scales: {
    //   x: {
    //     stacked: true,
    //   },
    //   y: {
    //     stacked: true,

    //   },
    // },
  };

  const data = {
    labels: ["", ""],
    datasets: [
      {
        barPercentage: 0.6,
        categoryPercentage: 0.4,
        label: "Target",
        data: [PrimarySaleGrowth?.SalesTarget, PrimarySaleGrowth?.SalesTarget],
        backgroundColor: "pink",
      },
      {
        label: "ach",
        barPercentage: 0.6,
        categoryPercentage: 0.4,
        data: [
          PrimarySaleGrowth?.PrimarySales,
          PrimarySaleGrowth?.SecondarySales,
        ],
        backgroundColor: "#2A9CA2",
      },
      {
        label: "%",
        barPercentage: 0.6,
        categoryPercentage: 0.4,
        data: [
          PrimarySaleGrowth?.PrimarySalesPer,
          PrimarySaleGrowth?.SecondarySalesPer,
        ],
        backgroundColor: "#1AAD78",
      },
    ],
  };

  // Second Chart

  let dropDownArray: any = [
    {
      label: "Average Monthly Sale (AMS)",
      value: "AverageMonthlySale",
    },
    {
      label: "Total Revenue (Annual)",
      value: "TotalRevenue",
    },
  ];
  const [dropdown, setDropdown]: any = useState(dropDownArray[0]);
  const option = ["PRIMARY SALES", "SECONDARY SALES"];
  const [optvalue, setOptValue]: any = useState(option[0]);
  let [PrimaryData, setPrimaryData] = useState([]);
  const [PrimaryDataLoader, setPrimaryDataLoader] = useState(false);
  const getPrimaryData = () => {
    setPrimaryDataLoader(true)
    axiosInstance
      .get(
        `v1/Reporting/Dashboard/${
          optvalue == "PRIMARY SALES"
            ? dropdown == "TotalRevenue"
              ? "GetPrimaryData"
              : "GetPrimaryAmsData"
            : dropdown == "TotalRevenue"
            ? "GetSecondaryData"
            : "GetSecondaryAmsData"
        }?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`
      )
      .then((e: any) => {
        console.log(e.data.Data);
        setPrimaryData(e?.data?.Data || []);
        setPrimaryDataLoader(false);
      })
      .catch((err) => {
        console.log(err, "this is error");
        setPrimaryData([]);
        setPrimaryDataLoader(false);
      });
  };

  const primaryOption: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
    // borderRadius: [
    //   {
    //     topLeft: 25,
    //     topRight: 25,
    //   },
    // ],
  };

  const primaryData = {
    labels: PrimaryData?.map((e: any) => {
      return e?.Quarter;
    }),
    datasets: [
      {
        label: "Primary F.Y 2024",
        data: PrimaryData?.map((e: any) => {
          if (optvalue == "PRIMARY SALES") {
            return e?.PrePrimarySales;
          } else {
            return e?.PrePrimarySales;
          }
        }),
        // backgroundColor: "#344BFD",
        backgroundColor: "#84BDCC",
        barThickness: 50,
      },
      {
        label: "Primary F.Y 2025",
        data: PrimaryData?.map((e: any) => {
          if (optvalue == "PRIMARY SALES") {
            return e?.CurentPrimarySales;
          } else {
            return e?.CurentPrimarySales;
          }
        }),
        // backgroundColor: "#FF9359",
        backgroundColor: "#D8B2D3",
        barThickness: 50,
      },
      {
        label: "Total %",
        data: PrimaryData?.map((e: any) => {
          if (optvalue == "PRIMARY SALES") {
            return e?.Achivement;
          } else {
            return e?.Achivement;
          }
        }),
        // backgroundColor: "#F4A79D",
        backgroundColor: "#A4A49C",
        barThickness: 50,
      },
    ],
  };

  // Third Chart
  const awsoption = ["PRIMARY SALES", "SECONDARY SALES"];
  const [awsoptvalue, setAwsOptValue]: any = useState(option[0]);
  let [amsData, setAmsData]: any = useState(null);
const [amsComparisonDataLoader, setAmsComparisonDataLoader] = useState(false);
  let getAmsComparisonData = () => {
    setAmsComparisonDataLoader(true);
    axiosInstance
      .get(
        `v1/Reporting/Dashboard/${
          awsoptvalue === "PRIMARY SALES"
            ? "GetPrimaryAmsSaleComparison"
            : "GetSecondaryAmsSaleComparison"
        }?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }${selectedHq !== null ? `&HqId=${selectedHq?.value}` : ""}`
      )
      .then((e: any) => {
        console.log(e.data.Data);
        setAmsData(e?.data?.Data[0] || {});
        setAmsComparisonDataLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setAmsData({});
        setAmsComparisonDataLoader(false);
      });
  };

  const amsComparisonOption: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  const amsComparisonData = {
    labels: ["Year 2022-2023", "Year 2024-2025"],
    datasets: [
      {
        label: "Target",
        data: [amsData?.Target, amsData?.PreTarget],
        // backgroundColor: "#6C7BF1",
        backgroundColor: "#27859E",
        barPercentage: 0.6,
        categoryPercentage: 0.4,
      },
      {
        label: "Achievement",
        data: [amsData?.PrimarySales, amsData?.PrePrimarySales],
        // backgroundColor: "#FF9359",
        backgroundColor: "#2A9CA2",
        barPercentage: 0.6,
        categoryPercentage: 0.4,
      },
      {
        label: "%",
        data: [amsData?.Achievement, amsData?.PreAchievement],
        // backgroundColor: "#F4A79D",
        backgroundColor: "#1AAD78",
        barPercentage: 0.6,
        categoryPercentage: 0.4,
      },
    ],
  };

  let [regionData, setRegionData] = useState([]);
const [hqWiseDataLoader, sethqWiseDataLoader] =useState(false);
  let getRegionData = () => {
    sethqWiseDataLoader(true)
    axiosInstance
      .get(
        `v1/Reporting/Dashboard/GetRegionWiseHqSale?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }${selectedRegion !== null ? `&RegionId=${selectedRegion?.value}` : ""}`
      )
      .then((e) => {
        setRegionData(e?.data?.Data);
        sethqWiseDataLoader(false);
      });
  };

  // Forth Chart
  const hqWiseOption: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  const hqWiseData = {
    labels: regionData?.map((e: any) => {
      return e?.HQName;
    }),
    datasets: [
      {
        label: "Year 2024",
        data: regionData?.map((e: any) => {
          return e?.Sales;
        }),
        backgroundColor: "#6E7EA2",
        barThickness: 50,
      },
    ],
  };

  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  let [divId, setDivId]: any = useState(null);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 1;
    const endingYear = currentYear;
    console.log(startingYear, endingYear, "year");
    setDivId({ value: 0, label: "All" });
    setSelected({
      value: `${startingYear}-${endingYear}`,
      label: `${startingYear}-${endingYear}`,
    });
  }, []);

  // Fifth Chart
  const saleoption = ["SALES", "QUANTITY"];
  const [salevalue, setSaleValue]: any = useState(saleoption[0]);

  let [ProductData, setProductData] = useState([]);
const [topProductDataLoader, settopProductDataLoader]= useState(false);
  const gettop10ProductsData = () => {
    settopProductDataLoader(true);
    axiosInstance
      .get(
        `v1/Reporting/Dashboard/${
          salevalue == "SALES"
            ? "GetTop10ProductSalesQtyData"
            : "GetTop10ProductSalesData"
        }?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`
      )
      .then((e: any) => {
        console.log(e.data.Data);
        setProductData(e?.data?.Data || []);
        settopProductDataLoader(false);
      })
      .catch((err) => {
        console.log(err, "this is error");
        setProductData([]);
        settopProductDataLoader(false);
      });
  };

  const topProductOption: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  const topProductData = {
    labels: ProductData.map((e: any) => {
      return e.name;
    }),
    datasets: [
      {
        label: "Product",
        data: ProductData?.map((e: any) => {
          if (salevalue == "SALES") {
            return e.Amount;
          } else {
            return e.Qty;
          }
        }),
        // backgroundColor: "#344BFD",
        backgroundColor: salevalue == "SALES" ? "#B9A37C" : "#E8B252",
        barThickness: 50,
      },
    ],
  };

  let [HqData, setHqData] = useState([]);
const [headQuartersDataLoader, setheadQuartersDataLoader] = useState(false);
  const gettop10HqSalesData = () => {
    setheadQuartersDataLoader(true);
    axiosInstance
      .get(
        `v1/Reporting/Dashboard/GetTop10HqSalesData?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`
      )
      .then((e: any) => {
        console.log(e.data.Data);
        setHqData(e?.data?.Data || []);
        setheadQuartersDataLoader(false);
      })
      .catch((err) => {
        console.log(err, "this is error");
        setHqData([]);
        setheadQuartersDataLoader(false)
      });
  };

  // Finacial Year
  function getCurrentFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    return currentMonth < 3
      ? `${currentYear - 1}-${currentYear}`
      : `${currentYear}-${currentYear + 1}`;
  }

  console.log(selected, "selected");

  useEffect(() => {
    if (selected == null) {
      return;
    }
    let temp = selected?.value;
    let startdate = `${temp?.split("-")[0]}-04-01`;
    let enddate = `${temp?.split("-")[1]}-03-31`;
    setStartDate(startdate);
    setEndDate(enddate);
  }, [selected]);

  function generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;
    const endingYear = currentYear;

    const years = [];
    for (let i = startingYear; i <= endingYear; i++) {
      years.push({
        label: `${i}-${i + 1}`,
        value: `${i}-${i + 1}`,
      });
    }
    return years;
  }

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      getAmsComparisonData();
    }
  }, [startDate, endDate, divId, selectedHq, awsoptvalue]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      getRegionData();
    }
  }, [startDate, endDate, divId, selectedRegion]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      gettop10ProductsData();
    }
  }, [startDate, endDate, divId, salevalue]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      gettop10HqSalesData();
      PrimarySalePer();
    }
  }, [startDate, endDate, divId]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      getPrimaryData();
    }
  }, [dropdown, optvalue, startDate, endDate, divId]);

  // Sixth Chart
  const headQuartersOption: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {},
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  const headQuartersData = {
    labels: HqData?.map((e: any) => {
      return e?.hqName;
    }),

    datasets: [
      {
        label: "Sales %",
        data: HqData.map((e: any) => {
          return e?.Sales;
        }),
        // backgroundColor: "#344BFD",
        backgroundColor: "#529A7B",
        barThickness: 50,
      },
      {
        label: "Achievement %",
        data: HqData.map((e: any) => {
          return e?.Achievement;
        }),
        // backgroundColor: "#FF9359",
        backgroundColor: "#E0D247",
        barThickness: 50,
      },
    ],
  };

  return (
    <div>
<Helmet>
  <title>Dashboard</title>
</Helmet>
      <div> 
        {/* First Chart */}
        <div className="row">
          <ReactSelect
            label="Division"
            containerClass="col-lg-6"
            name={"divisionId"}
            onChange={(e: any) => {
              setDivId(e);
            }}
            value={divId}
            control={control}
            api={process.env.REACT_APP_DIVISIONGETDASHBOARDLOOKUP_API}
            keys={{ label: "Value", value: "Id" }}
            errorMessage={errors?.divisionId?.message}
          />

          <div className="col-lg-6">
            <div className="form-group">
              <label className="col-form-label">
                Financial Year <span className="text-danger">*</span>
              </label>
              <Select
                options={generateFinancialYears()}
                className="z-3"
                isClearable
                classNamePrefix={"z-3"}
                onChange={(e: any) => {
                  setSelected(e);
                }}
                value={selected}
              />
            </div>
          </div>
        </div>

        {/* <div className="row align-center">
        <div className="col-lg-1">
          <label
            className="form-label align-center"
            style={{ fontWeight: "bold", fontSize: "1.0rem" }}
          >
            PERIOD 
          </label>
        </div>
        <div className="col-lg-5">
          <Button label="Q1" severity="secondary" text raised size="small" />
          <Button label="Q2" severity="secondary" text raised size="small" />
          <Button label="Q3" severity="secondary" text raised size="small" />
          <Button label="Q4" severity="secondary" text raised size="small" />
        </div>
      </div> */}

        {/* <div className="row mt-5">
          <div className="col-lg-6 text-center">
            <Button label="PRIMARY SALES" text raised />
          </div>
          <div className="col-lg-6 text-center">
            <Button label="SECONDARY SALES" text raised />
          </div>
        </div> */}


<div className="row text-center mt-5">
          <div className="col">
            <label
              className="form-label align-center"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              PRIMARY SALES
            </label>
          </div>
          <div className="col">
            <label
              className="form-label align-center"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              SECONDARY SALES
            </label>
          </div>
        </div>


        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
               {loader ? <div  style={{position: "absolute", top: "50%", left: "50%" }} > <Spinner/></div> : 
              <Bar height={80} options={options} data={data} />
            }
            </div>
          </div>
        </div>

        {/* Second Chart */}
        <div className="row mx-1">
          <div className="form-group">
            <label className="col-form-label">AMS</label>
            <select
              className="custom-select col-12"
              onChange={(e: any) => {
                setDropdown(e.target.value);
              }}
            >
              {dropDownArray.map((d: any) => {
                return <option value={d.value}>{d.label}</option>;
              })}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="card flex justify-content-center mx-3">
            <SelectButton
              value={optvalue}
              onChange={(e) => setOptValue(e.value)}
              options={option}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
                    {PrimaryDataLoader ? <div  style={{position: "absolute", top: "50%", left: "50%" }} > <Spinner/></div> : 
              <Bar height={80} options={primaryOption} data={primaryData} />
            }
            </div>
          </div>
        </div>

        {/* Third Chart */}
        <div className="row">
          <div className="col-lg-12">
            <label
              className="form-label align-center"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              {" "}
              AMS Comparison{" "}
            </label>
          </div>
        </div>

        <div className="row">
          <ReactSelect
            label="HQ"
            containerClass="col-lg-4"
            name={"divisionId"}
            onChange={(e: any) => {
              setselectedHq(e);
              setValue("divisionId", e?.value);
            }}
            value={selectedHq}
            control={control}
            api={process.env.REACT_APP_HQ_API}
            keys={{ label: "Value", value: "Id" }}
            errorMessage={errors?.divisionId?.message}
          />
        </div>

        <div className="row">
          <div className="card flex justify-content-center mx-3">
            <SelectButton
              value={awsoptvalue}
              onChange={(e) => setAwsOptValue(e.value)}
              options={awsoption}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
               {amsComparisonDataLoader ? <div  style={{position: "absolute", top: "50%", left: "50%" }} > <Spinner/></div> : 
              <Bar
                height={80}
                options={amsComparisonOption}
                data={amsComparisonData}
              />
            }
            </div>
          </div>
        </div>

        {/* Fourth Chart */}
        <div className="row">
          <div className="col-lg-12">
            <label
              className="form-label align-center"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              {" "}
              HQ Wise Sales{" "}
            </label>
          </div>
        </div>

        <div className="row">
          <ReactSelect
            label="Region"
            containerClass="col-lg-4"
            name={"divisionId"}
            onChange={(e: any) => {
              setselectedRegion(e);
              setValue("divisionId", e?.value);
            }}
            value={selectedRegion}
            control={control}
            api={process.env.REACT_APP_REGION_API}
            keys={{ label: "Value", value: "Id" }}
            errorMessage={errors?.divisionId?.message}
          />
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
               {hqWiseDataLoader ? <div  style={{position: "absolute", top: "50%", left: "50%" }} > <Spinner/></div> : 
              <Bar height={80} options={hqWiseOption} data={hqWiseData} />
            }
            </div>
          </div>
        </div>

        {/* Fifth Chart */}
        <div className="row">
          <div className="col-lg-12">
            <label
              className="form-label align-center"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              {" "}
              TOP 10 PRODUCTS{" "}
            </label>
          </div>
        </div>

        <div className="row">
          <div className="card flex justify-content-center mx-3">
            <SelectButton
              value={salevalue}
              onChange={(e) => {
                setSaleValue(e.value);
              }}
              options={saleoption}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {topProductDataLoader ? <div  style={{position: "absolute", top: "50%", left: "50%" }} > <Spinner/></div> :
              <Bar
                height={80}
                options={topProductOption}
                data={topProductData}
              />
              }
            </div>
          </div>
        </div>

        {/* Sixth Chart */}
        <div className="row">
          <div className="col-lg-12">
            <label
              className="form-label align-center"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              TOP 10 PERFORMER HEAD QUATERS
            </label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div
              className="pd-20 card-box mb-30"
              style={{
                position: "relative",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {headQuartersDataLoader ? <div  style={{position: "absolute", top: "50%", left: "50%" }} > <Spinner/></div> :
              <Bar
                height={80}
                options={headQuartersOption}
                data={headQuartersData}
              />
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
