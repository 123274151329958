import React, { useEffect, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import ReactSelect from "../../../../common/ReactSelect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditHqModal = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_HQ_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: hqTypeLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const [selectedRegion, setselectedRegion]: any = useState(null);
  const [selectedHQType, setselectedHQType]: any = useState(null);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselectedRegion({ label: data.Region, value: data.RegionId });
        setselectedHQType({ label: data.HqTypeName, value: data.HQTypeId });
      }
    }
  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const hqTypeFormData = (data: any) => {
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} HQ`}
        modal
        style={{ width: "1100px" }}
      >
        {!hqTypeLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(hqTypeFormData);
              }
            }}
            onSubmit={handleSubmit(hqTypeFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                control={control}
                api={process.env.REACT_APP_HQTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                isRequired
                onChange={(e: any) => {
                  setValue("HQTypeId", e?.value);
                  setselectedHQType(e);
                }}
                value={selectedHQType}
                name="HQTypeId"
                label="Hq Type"
                rules={{
                  required: {
                    value: true,
                    message: "Hq Type is required",
                  },
                }}
                errorMessage={
                  errors?.HQTypeId?.message ||
                  error?.response?.data?.errors?.HQTypeId?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="HQ"
                type="text"
                isRequired
                errorMessage={
                  errors?.HqName?.message ||
                  error?.response?.data?.errors?.HqName?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("HqName", e.target.value);
                  clearErrors(["HqName"]);
                }}
                register={{
                  ...register("HqName", {
                    required: {
                      value: true,
                      message: "HQ is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s'-]{1,50}$/,
                      message:
                        "Enter a valid hq name (1-50 alphabet characters)",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                control={control}
                api={process.env.REACT_APP_REGION_API}
                keys={{ label: "Value", value: "Id" }}
                isRequired
                onChange={(e: any) => {
                  setValue("RegionId", e?.value);
                  setselectedRegion(e);
                }}
                value={selectedRegion}
                name="RegionId"
                label="Region Name"
                rules={{
                  required: {
                    value: true,
                    message: "Region is required",
                  },
                }}
                errorMessage={
                  errors?.RegionId?.message ||
                  error?.response?.data?.errors?.RegionId?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedRegion(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
