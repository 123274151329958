import React, { useEffect, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import ReactSelect from "../../../../common/ReactSelect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { InputMask } from "primereact/inputmask";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export default function AddEditHospitalContactPerson({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_HOSPITALCONTACTPERSON_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const Hcity = watch("HospitalContactPersonCityId");

  const [selectedhospital, setselectedhospital]: any = useState(null);
  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);

  const { data, isLoading: chemistContactPersonLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (
          data.HospitalId !== null &&
          data.HospitalId !== undefined
        ) {
          setValue("HospitalId", {
            label: data.HospitalName,
            value: data.HospitalId,
          });
          setselectedhospital({
            label: data.HospitalName,
          value: data.HospitalId,
          });
        } else {
          setValue("HospitalId", null);
          setselectedhospital(null);
        }

        if (data.HospitalContactPersonCityId !== null) {
          setValue("HospitalContactPersonCityId", {
            label: data.HospitalContactPersonCity,
            value: data.HospitalContactPersonCityId,
          });
          setselectedhcity({
            label: data.HospitalContactPersonCity,
            value: data.HospitalContactPersonCityId,
          });
        } else {
          setValue("HospitalContactPersonCityId", null);
          setselectedhcity(null);
        }

        if (data.HospitalContactPersonPincodeId !== null) {
          setValue("HospitalContactPersonPincodeId", {
            label: data.HospitalContactPersonPincode,
            value: data.HospitalContactPersonPincodeId,
          });
          setselectedhpincode({
            label: data.HospitalContactPersonPincode,
            value: data.HospitalContactPersonPincodeId,
          });
        } else {
          setValue("HospitalContactPersonPincodeId", null);
          setselectedhpincode(null);
        }
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const chemistContactPersonFormData = (data: any) => {
    data.MobileNo = data.MobileNo?.replace(/[^\d]/g, "");

    data.HospitalId = selectedhospital?.value;
    data.HospitalContactPersonCityId = selectedhcity?.value;
    data.HospitalContactPersonPincodeId = selectedhpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Hospital Contact Person`}
        modal
        style={{ width: "1100px" }}
      >
        {!chemistContactPersonLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(chemistContactPersonFormData);
              }
            }}
            onSubmit={handleSubmit(chemistContactPersonFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-3"
                control={control}
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("HospitalId", e?.value);
                    setselectedhospital(e);
                  } else {
                    setValue("HospitalId", null);
                    setselectedhospital(null);
                  }
                }}
                value={selectedhospital}
                api={process.env.REACT_APP_HOSPITAL_API}
                keys={{ label: "Value", value: "Id" }}
                name="HospitalId"
                label="Hospital Name"
                rules={{
                  required: {
                    value: true,
                    message: "Hospital Name is required",
                  },
                }}
                errorMessage={
                  errors?.HospitalId?.message ||
                  error?.response?.data?.errors?.HospitalId?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="Hospital Contact Person Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.HospitalContactPersonName?.message ||
                  error?.response?.data?.errors?.HospitalContactPersonName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("HospitalContactPersonName", e?.target?.value);
                  clearErrors(["HospitalContactPersonName"]);
                }}
                register={{
                  ...register("HospitalContactPersonName", {
                    required: {
                      value: true,
                      message: "Hospital Contact Person Name is required",
                    },
                    pattern: {
                      value: /^.{2,100}$/gm,
                      message:
                        "Enter a valid hospital contact person name (2-100 alphabet characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Email"
                type="text"
                isRequired
                errorMessage={
                  errors?.Email?.message ||
                  error?.response?.data?.errors?.Email?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Email", e.target.value);
                  clearErrors(["Email"]);
                }}
                register={{
                  ...register("Email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                      message: "Email format is Invalid..",
                    },
                  }),
                }}
                maxLength={50}
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">
                    Mobile No. <span className="text-danger">*</span>
                  </label>
                  <InputMask
                    {...register("MobileNo", {
                      required: {
                        value: true,
                        message: "Mobile No. is required",
                      },
                    })}
                    mask="(999) 999-9999"
                    className="form-control"
                    onChange={(e) => {
                      setValue("Mobile", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    message={
                      errors?.MobileNo?.message ||
                      error?.response?.data?.errors?.MobileNo?.map(
                        (e: any) => e
                      )
                    }
                  />
                </div>
              </div>

              <Address
                header="Residential Address"
                control={control}
                address1register={{
                  ...register("HospitalContactPersonAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("HospitalContactPersonAddressLine1", e.target.value);
                  clearErrors(["HospitalContactPersonAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.HospitalContactPersonAddressLine1?.message ||
                  error?.response?.data?.errors?.HospitalContactPersonAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("HospitalContactPersonAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("HospitalContactPersonAddressLine2", e.target.value);
                  clearErrors(["HospitalContactPersonAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.HospitalContactPersonAddressLine2?.message ||
                  error?.response?.data?.errors?.HospitalContactPersonAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                if (e?.value !== undefined) {
                    setValue("HospitalContactPersonCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("HospitalContactPersonCityId", null);
                    setValue("HospitalContactPersonPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.HospitalContactPersonCityId?.message ||
                  error?.response?.data?.errors?.HospitalContactPersonCityId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                selectedCity={selectedhcity}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("HospitalContactPersonPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("HospitalContactPersonPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.HospitalContactPersonPincodeId?.message ||
                  error?.response?.data?.errors?.HospitalContactPersonPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedPincode={selectedhpincode}
                cityKeys={{ label: "Value", value: "Id" }}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityName={"HospitalContactPersonCityId"}
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeApi={
                  selectedhcity !== null ?
                  `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}`
                  : ""
                }
                pincodeName={"HospitalContactPersonPincodeId"}
                pincodeDisable={selectedhcity !== null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedhospital(null);
                      setselectedhpincode({});
                      setselectedhcity(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
