import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Spinner } from "../../common/Spinner";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../common/ReactSelect";
import { Input } from "../../common/Input";

const ChemistVisitRCPAReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const [isLoading, setIsLoading] = useState(false);

  const [selectedEmployee, setSelectedEmployee]: any = useState(null);

  let role = localStorage.getItem("Roles");

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownload = async (data: any) => {
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTVISITDOWNLOADECPAREPORT_API
        }?startDate=${startdate}&endDate=${defaultDate}${data.userId !== undefined ? `&userId=${data.userId}` : ""
        }`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "ChemistVisitRCPAReport.xlsx");
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  // date
  const [startdate, setDate] = useState('');

  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split('T')[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split('T')[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Chemist Visit RCPA Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Chemist Visit RCPA Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Chemist Visit RCPA Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <Input
              containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
              label="Start Date"
              type="date"
              isRequired
              errorMessage={errors?.startDate?.message}
              onChange={(e: any) => { clearErrors(["startDate"]); handleDateChange(e) }}
              // register={{
              //   ...register("startDate", {
              //     required: {
              //       value: true,
              //       message: "Start Date is required",
              //     },
              //   }),
              // }}
              value={startdate}
            />

            <Input
              containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
              label="End Date"
              type="date"
              isRequired
              errorMessage={errors?.endDate?.message}
              onChange={(e: any) => { clearErrors(["endDate"]); handleEndDateChange(e) }}
              value={defaultDate}
            // register={{
            //   ...register("endDate", {
            //     required: {
            //       value: true,
            //       message: "End Date is required",
            //     },
            //   }),
            // }}
            />

            {role !== "MR" && (
              <ReactSelect
                containerClass="col-lg-4"
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                onChange={(e: any) => {
                  setValue("userId", e?.value);
                  setSelectedEmployee(e);
                }}
                value={selectedEmployee}
                name="userId"
                label="User"
              />
            )}
          </div>

          <div className="col-md-12 text-center mt-30">
            {!isLoading ? (
              <button className="btn btn-outline-primary" type="submit">
                Download
              </button>
            ) : (
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled
              >
                Download <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ChemistVisitRCPAReport;
