import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import ReactSelect from "../../../../common/ReactSelect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";
import { InputMask } from "primereact/inputmask";
import { ErrorMessage } from "../../../../common/ErrorMessage";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export default function AddEditChemistContactPersonModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_CHEMISTCONTACTPERSON_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    getValues,
    clearErrors,
  }: any = useForm({
    mode: "all",
  });
  const da = watch();
  console.log(da, "this is form value");

  const Hcity = watch("ChemistContactPersonCityId");

  const [selectedchemist, setselectedchemist]: any = useState(null);
  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);
  console.log(selectedhcity, "setselectedhcity");

  const { data, isLoading: chemistContactPersonLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const [maskvalue, setmaskvalue]: any = useState();

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (data.ChemistId !== null) {
          setValue("ChemistId", {
            label: data.ChemistName, value: data.ChemistId
          });
          setselectedchemist({
            label: data.ChemistName, value: data.ChemistId
          });
        } else {
          setValue("ChemistId", null);
          setselectedchemist(null);
        }

        if (data.ChemistContactPersonCityId !== null) {
          setValue("ChemistContactPersonCityId", {
            label: data.ChemistContactPersonCity,
            value: data.ChemistContactPersonCityId,
          });
          setselectedhcity({
            label: data.ChemistContactPersonCity,
            value: data.ChemistContactPersonCityId,
          });
        } else {
          setValue("ChemistContactPersonCityId", null);
          setselectedhcity(null);
        }

        if (data.ChemistContactPersonPincodeId !== null) {
          setValue("ChemistContactPersonPincodeId", {
            label: data.ChemistContactPersonPincode,
            value: data.ChemistContactPersonPincodeId,
          });
          setselectedhpincode({
            label: data.ChemistContactPersonPincode,
            value: data.ChemistContactPersonPincodeId,
          });
        } else {
          setValue("ChemistContactPersonPincodeId", null);
          setselectedhpincode(null);
        }

        setmaskvalue(data.MobileNo);
      }
    }
  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const chemistContactPersonFormData = (data: any) => {

    data.MobileNo = maskvalue?.replace(/[^\d]/g, "");
    data.ChemistContactPersonCityId = selectedhcity?.value;
    data.ChemistId = selectedchemist?.value?.value;
    data.ChemistContactPersonPincodeId = selectedhpincode?.value;
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Chemist Contact Person`}
        modal
        style={{ width: "1100px" }}
      >
        {!chemistContactPersonLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(chemistContactPersonFormData);
              }
            }}
            onSubmit={handleSubmit(chemistContactPersonFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-3"
                control={control}
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ChemistId", e?.value);
                    setselectedchemist(e);
                  } else {
                    setValue("ChemistId", null);
                    setselectedchemist(null);
                  }
                }}
                value={selectedchemist}
                api={process.env.REACT_APP_CHEMIST_API}
                keys={{ label: "Value", value: "Id" }}
                name="ChemistId"
                label="Chemist Name"
                rules={{
                  required: {
                    value: true,
                    message: "Chemist Name is required",
                  },
                }}
                errorMessage={
                  errors?.ChemistId?.message ||
                  error?.response?.data?.errors?.ChemistId?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Contact Person Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("ChemistContactPersonName", e?.target?.value);
                  clearErrors(["ChemistContactPersonName"]);
                }}
                register={{
                  ...register("ChemistContactPersonName", {
                    required: {
                      value: true,
                      message: "Contact Person Name is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid contact person name (2-50 alphabet characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.ChemistContactPersonName?.message ||
                  error?.response?.data?.errors?.ChemistContactPersonName?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Email"
                type="text"
                errorMessage={
                  errors?.Email?.message ||
                  error?.response?.data?.errors?.Email?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Email", e?.target?.value);
                  clearErrors(["Email"]);
                }}
                register={{
                  ...register("Email", {
                    pattern: {
                      value:
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                      message: "Email format is Invalid..",
                    },
                  }),
                }}
                maxLength={50}
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">Mobile No.</label>
                  <InputMask
                    value={maskvalue}
                    mask="(999) 999-9999"
                    className="form-control"
                    onChange={(e: any) => setmaskvalue(e?.value)}
                  />
                  <ErrorMessage
                    message={
                      errors?.MobileNo?.message ||
                      error?.response?.data?.errors?.MobileNo?.map(
                        (e: any) => e
                      )
                    }
                  />
                </div>
              </div>

              <Address
                header="Residential Address"
                control={control}
                address1register={{
                  ...register("ChemistContactPersonAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue(
                    "ChemistContactPersonAddressLine1",
                    e?.target?.value
                  );
                  clearErrors(["ChemistContactPersonAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.ChemistContactPersonAddressLine1?.message ||
                  error?.response?.data?.errors?.ChemistContactPersonAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("ChemistContactPersonAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue(
                    "ChemistContactPersonAddressLine2",
                    e?.target?.value
                  );
                  clearErrors(["ChemistContactPersonAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.ChemistContactPersonAddressLine2?.message ||
                  error?.response?.data?.errors?.ChemistContactPersonAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ChemistContactPersonCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("ChemistContactPersonCityId", null);
                    setValue("ChemistContactPersonPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address4errorMessage={
                  errors?.ChemistContactPersonCityId?.message ||
                  error?.response?.data?.errors?.ChemistContactPersonCityId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedhcity}
                address5onChange={(e: any) => {
                  console.log(e?.value, "this is pincode value");
                  if (e?.value !== undefined) {
                    setValue("ChemistContactPersonPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("ChemistContactPersonPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.ChemistContactPersonPincodeId?.message ||
                  error?.response?.data?.errors?.ChemistContactPersonPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedPincode={selectedhpincode}
                cityKeys={{ label: "Value", value: "Id" }}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityName={"ChemistContactPersonCityId"}
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeApi={
                  selectedhcity != null
                    ? `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}`
                    : ""
                }
                pincodeName={"ChemistContactPersonPincodeId"}
                pincodeDisable={selectedhcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedchemist(null);
                      setselectedhcity(null);
                      setselectedhpincode({});
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
