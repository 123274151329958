import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Textarea from "../../../common/Textarea";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import ReactSelect from "../../../common/ReactSelect";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Input } from "../../../common/Input";
import { ErrorMessage } from "../../../common/ErrorMessage";
import cameraLogo from "../../../assets/images/Camera.png";

const AddEditVAUpload = () => {
  const navigate = useNavigate();
  const { id }: any = useParams();
  const apiname = process.env.REACT_APP_VAUPLOAD_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: vauploadloader }: any = useFetchAllID(apiname, id);
  const [ImageDisplay, setImageDisplay]: any = useState(cameraLogo);
  useEffect(() => {
    if (id) {
      if (data) {
        setImageDisplay(data.FilePath);
        reset(data);
        setSelectedProduct({
          label: data.ProductName,
          value: data.ProductId,
        });
        setSelectedMediaType({
          label: data.MediaName,
          value: data.MediaTypeId,
        });
      }
    }
  }, [data, id]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );
  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = cameraLogo;
  };
  const [SelectedProduct, setSelectedProduct]: any = useState(null);
  const [SelectedMediaType, setSelectedMediaType]: any = useState(null);

  const [FilePath, setFilePath]: any = useState(null);

  const vAUploadFormData = (form: any) => {
    if (FilePath !== null) {
      form.FilePath = FilePath[0];
    }

    for (let prop in form) {
      if (form[prop] === null || form[prop] === undefined || form[prop] === "" || form[prop]?.length == 0) {
        delete form[prop]
      }
    }
    let newData: any = {};
    Object.keys(form).forEach(
      (k) =>
        (newData[k] = typeof form[k] == "string" ? form[k].trim() : form[k])
    );
    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {

      const formvalue: any = value;

      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        console.log(formvalue, key, "formvalue")
        if (key == "ProductId") {

          formData.append(key, formvalue.value);
        }
        else {

          formData.append(key, formvalue);
        }
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/master/visualaid/VAUpload");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/master/visualaid/VAUpload");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>{id === undefined ? "Add" : "Edit"} VA Upload </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/master/visualaid/VAUpload"}>VA Upload</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {id === undefined ? "Add" : "Edit"} VA Upload
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {!vauploadloader ? (
        <div className="pd-20 card-box mb-30">
          <form>
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Product"
                isRequired
                name={"ProductId"}
                control={control}
                onChange={(e: any) => {
                  setSelectedProduct(e);
                  setValue("ProductId", e?.value);
                }}
                value={SelectedProduct}
                api={process.env.REACT_APP_PRODUCT_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.ProductId?.message}
                rules={{
                  required: { value: true, message: "Product is required" },
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="VA Upload Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.VaUploadName?.message ||
                  error?.response?.data?.errors?.VaUploadName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("VaUploadName", e.target.value); clearErrors(["VaUploadName"]) }}
                register={{
                  ...register("VaUploadName", {
                    required: {
                      value: true,
                      message: "VA Upload Name is required",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Media Type"
                isRequired
                name={"MediaTypeId"}
                control={control}
                onChange={(e: any) => {
                  setSelectedMediaType(e);
                  setValue("MediaTypeId", e?.value);
                }}
                api={process.env.REACT_APP_MEDIATYPE_API}
                keys={{ label: "Value", value: "Id" }}
                value={SelectedMediaType}
                errorMessage={errors?.MediaTypeId?.message}
                rules={{
                  required: { value: true, message: "Media Type is required" },
                }}
              />

              <div className={"col-lg-4"}>
                <div className="form-group image-upload">
                  <label className="col-form-label">
                    Select the File <span className="text-danger">*</span>
                  </label>
                  <div>
                    <label style={{ cursor: "pointer" }}>
                      <img src={ImageDisplay} alt="" className="uploaded-image" onError={handleError} width={"150px"} />
                      <input
                        {...register("FilePath", {
                          required: {
                            value:
                              !data.FilePath &&
                              FilePath?.name == undefined &&
                              true,
                            message: "File Path is required",
                          },
                        })}
                        type="file"
                        data-required="image"
                        onChange={(e: any) => {
                          if (e.target.files[0]) {
                            const filePath = URL.createObjectURL(e.target.files[0]);
                            setImageDisplay(filePath);
                          }
                          setFilePath(e.target.files)
                        }}
                        className="form-control"
                        hidden
                        accept="video/*,image/*"
                      />
                    </label>
                  </div>
                  {
                    ImageDisplay !== cameraLogo &&
                    (FilePath == null ? (
                      <>
                        <a href={`${data?.FilePath}`} target="blank">
                          {data?.FileName}
                        </a>
                        {/* <i className="bi bi-x-circle-fill ml-2" style={{ cursor: "pointer" }} title="Remove Image" onClick={() => {
                          setValue("FilePath", null);
                          setFilePath(null);
                          setImageDisplay(cameraLogo);

                        }}></i> */}
                      </>
                    ) : (
                      <p>{FilePath[0]?.name}
                        {/* <i className="bi bi-x-circle-fill ml-2" style={{ cursor: "pointer" }} title="Remove Image" onClick={() => {
                        setValue("FilePath", null);
                        setFilePath(null);
                        setImageDisplay(cameraLogo);

                      }}></i> */}
                      </p>
                    ))
                  }
                  {!data.FilePath && FilePath == null && (
                    <ErrorMessage message={errors?.FilePath?.message} />
                  )}
                </div>
              </div>

              <Textarea
                containerClass="col-lg-12"
                label="Description"
                maxLength={500}
                register={{
                  ...register("Description"),
                }}
                onChange={(e: any) => { setValue("Description", e.target.value); clearErrors(["Description"]) }}
                errorMessage={
                  errors?.Description?.message ||
                  error?.response?.data?.errors?.Description?.map((e: any) => e)
                }
              />

              <Textarea
                containerClass="col-lg-12"
                label="Web Reference"
                maxLength={1000}
                register={{
                  ...register("Url"),
                }}
                onChange={(e: any) => { setValue("Url", e.target.value); clearErrors(["Url"]) }}
                errorMessage={
                  errors?.Url?.message ||
                  error?.response?.data?.errors?.Url?.map((e: any) => e)
                }
              />
            </div>

            <div className="col-lg-12 text-center">
              <Link
                to={"/layout/master/visualaid/VAUpload"}
                className="btn btn btn-outline-secondary mt-2 mx-2"
              >
                {" "}
                Back{" "}
              </Link>
              {id === undefined ? (
                <button
                  type="button"
                  className="btn btn-outline-danger mt-2 mx-2"
                  onClick={() => {
                    reset();
                    setSelectedProduct(null);
                    setSelectedMediaType(null);
                  }}
                >
                  {" "}
                  Reset{" "}
                </button>
              ) : (
                ""
              )}
              {isLoading ? (
                <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    vAUploadFormData(data);
                  })}
                  className="btn btn-outline-primary mt-2 mx-2"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};

export default AddEditVAUpload;
