import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { PaginationServiceByAdminDoctorAvailibilty } from "../../../service/PaginationService";
import { toast } from "react-toastify";
import axiosInstance from "../../../_helper/Interceptor";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import moment from "moment";

const AdminDoctorAvailibilty = () => {
  const controller = new AbortController();
  const [gridData, setgridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState();

  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  const [Nodata, setNodata] = useState(false);

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: null,
  });

  let networkTimeout: any = null;

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByAdminDoctorAvailibilty.getData(lazyState, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              srNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          setgridData(temp);
          setTotalRecord(data.TotalDataCount);
          setLoading(false);
          if (temp?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    }
  }, [lazyState]);

  const handleApprove = (id: any, status: string) => {
    setLoading(true);
    const approveData = {
      Id: id,
      Status: status,
    };
    axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORAVAILABILITYAPPROVAL_API}/${id}`,
        approveData
      )
      .then((res) => {
        setLoading(false);
        toast.success(
          status === "Approve"
            ? "Approved"
            : "Rejected",
          { position: "top-right" }
        );
        loadLazyData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {e.StatusName == "Approve"
          ? "Approved"
          : e.StatusName == "Reject"
            ? "Rejected"
            : e.StatusName}
      </span>
    );
  };

  const actionFucntion = (e: any) => {
    return e.StatusName == "Pending" ? (
      <>
        <button
          className="btn btn-success btn-sm mr-1"
          onClick={() => {
            handleApprove(e.Id, "Approve");
          }}
        >
          Approve
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleApprove(e.Id, "Reject")}
          type="button"
        >
          Reject
        </button>
      </>
    ) : ("");
  };

  let obj = gridData.find((o: any) => o.StatusName === "Pending");

  return (
    <>
      <Helmet>
        <title>Admin Doctor Availibilty</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Admin Doctor Availibilty</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/approvals"}>Approvals</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Admin Doctor Availibilty
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {gridData.length !== 0 ? (
        <>
          <div className="pd-20 card-box mb-30 table-responsive">
            <DataTable
              value={gridData}
              dataKey="id"
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={totalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              loading={loading}
              onPage={onPageChange}
              removableSort
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              <Column header="#" field="srNo"></Column>
              {obj ? (
                <Column
                  header="Action"
                  body={(e: any) => actionFucntion(e)}
                ></Column>
              ) : null}
              <Column
                field="DoctorName"
                bodyClassName={"text-info h6"}
                header="Doctor Name"
              ></Column>
              <Column
                field="EmployeeName"
                bodyClassName={"text-info h6"}
                header="Employee Name"
              ></Column>
              <Column
                header="Leave Start Date"
                body={(e: any) => {
                  if (e.LeaveStartDate === null) {
                    return "-";
                  } else {
                    return (

                      <Badge
                        className="m-1"
                        severity={"success"}
                        value={moment(e.LeaveStartDate).format(
                          "DD MMM, YYYY"
                        )}
                      ></Badge>
                      // <Badge
                      //   className="m-1"
                      //   severity={"info"}
                      //   value={moment(e.LeaveStartDate).format("hh:mm A")}
                      //   style={{ backgroundColor: "#978EEB" }}
                      // ></Badge>

                    );
                  }
                }}
              ></Column>
              <Column
                header="Leave End Date"
                body={(e: any) => {
                  if (e.LeaveEndDate === null) {
                    return "-";
                  } else {
                    return (

                      <Badge
                        className="m-1"
                        severity={"success"}
                        value={moment(e.LeaveEndDate).format("DD MMM, YYYY")}
                      ></Badge>
                      // <Badge
                      //   className="m-1"
                      //   severity={"info"}
                      //   value={moment(e.LeaveEndDate).format("hh:mm A")}
                      //   style={{ backgroundColor: "#978EEB" }}
                      // ></Badge>

                    );
                  }
                }}
              ></Column>
              <Column
                header="Created On"
                body={(e: any) => {
                  if (e.CreatedOn === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.CreatedOn).format("DD MMM, YYYY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.CreatedOn).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
              ></Column>
              <Column
                header="Status"
                body={(e: any) => statusFunction(e)}
              ></Column>
            </DataTable>
          </div>
        </>
      ) : Nodata === true ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box text-center">
            <h3 className="text-secondary">No data found.</h3>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AdminDoctorAvailibilty;
