import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PaginationServiceByCRMChemistReport,
  PaginationServiceByCRMReport,
} from "../../service/PaginationService";
import { toast } from "react-toastify";
import { Input } from "../../common/Input";
import { Spinner } from "../../common/Spinner";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import moment from "moment";
import { Badge } from "primereact/badge";
import { NullHandler } from "../../common/NullHandler";
import axiosInstance from "../../_helper/Interceptor";

const CRMReportGrid = () => {
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [doctorExpandrow, setDoctorExpandrow]: any = useState(null);
  const [doctorVisitLog, setDoctorVisitLog]: any = useState(null);
  const [productLoading, setProductLoading]: any = useState(false);
  const getQuarterlyDate = (startMonth: any) => {
    const date = new Date(startMonth);
    const newDate = new Date(date.setMonth(date.getMonth() + 3));
    return `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
  };
  const api_data_mapping: any = {
    1: "DoctorList",
    2: "HospitalList",
    3: "ChemistList",
    4: "StockistList",
  };
  useEffect(() => {
    const today = new Date();
    const currentMonth = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}`;
    setStartMonth(currentMonth);
    setEndMonth(getQuarterlyDate(currentMonth));
  }, []);

  const handleStartMonthChange = (event: any) => {
    const newStartMonth = event.target.value;
    setStartMonth(newStartMonth);
    // setEndMonth(getQuarterlyDate(newStartMonth));
  };

  const handleEndMonthChange = (event: any) => {
    const newEndMonth = event.target.value;
    setEndMonth(newEndMonth);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const [specialityIds, setSpecialityIds] = useState(null);
  const [categoryIds, setCategoryIds] = useState(null);
  const [stateIds, setStateIds]: any = useState(null);
  const [hqIds, setHqIds]: any = useState(null);
  const [cityIds, setCityIds] = useState(null);
  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  const [type, setType] = useState("");

  function compareKeys(a: any, b: any, column: any) {
    var aKeys = Object.keys(a).sort();
    return aKeys[0] == b && a[aKeys[0]] == column;
  }
  //   Crm Potential apicall Start
  const [totalRecord, setTotalRecords] = useState();
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    paramter: null,
  });
  const [expandedRows, setExpandedRows]: any = useState(null);

  const onPageChange = (e: any) => {
    console.log(e, "page Change");
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  let networkTimeout: any = null;
  const loadLazyData = (data: any) => {
    console.log(data, "loaded Data");
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByCRMReport.getData(data)
        .then((data) => {
          const temp = data.Data;
          temp.forEach(function (item: any, index: any) {
            item.id = index + 1;
          });
          let temp1 = temp.map((d: any, index: number) => {
            return {
              ...d,
              SrNo:
                data.PageNumber * data.PageSize - data.PageSize + (index + 1),
            };
          });
          console.log(temp1, "dsadasdsdsdasdas");
          settotalData(temp1);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onSubmit = (data: any) => {
    debugger;
    setExpandedRows(null);
    data.StartDate = startMonth + "-01";
    data.EndDate = endMonth + "-01";
    setlazyState({
      ...lazyState,
      paramter: data,
      first: 0,
      page: 1,
      rows: 10,
      sortField: null,
      sortOrder: null,
    });
  };

  useEffect(() => {
    if (lazyState.paramter !== null) {
      loadLazyData(lazyState);
    }
  }, [lazyState]);

  //   Crm Potential apicall Over

  //   chemist apicall Start
  const [subTotalRecord, setSubTotalRecord] = useState(0);
  const [subTotalData, setSubTotalData]: any = useState(null);
  const [subLoading, setSubLoading] = useState(false);
  const [sublazyState, setSublazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    paramter: null,
    title: "",
    apiName: "",
  });

  const [headerArray, setHeaderArray] = useState([]);
  let subNetworkTimeout: any = null;
  const subLoadLazyData = (data: any) => {
    debugger;
    setSubLoading(true);

    if (subNetworkTimeout) {
      clearTimeout(subNetworkTimeout);
    }

    //imitate delay of a backend call
    subNetworkTimeout = setTimeout(() => {
      PaginationServiceByCRMChemistReport.getData(data, sublazyState?.apiName)
        .then((data) => {
          const temp = data.Data;
          if (temp.length == 0) {
            return setSubLoading(false);
          }
          let newFinalArray: any = [];
          let newDataObj: any = {};

          temp.map((data: any) => {
            let objArr = Object.entries(data);
            objArr.map((d: any, index: number) => {
              newDataObj[`${d[0]}`] = typeof d[1] == "object" ? 0 : d[1];
              if (index + 1 == objArr.length) {
                newFinalArray.push(newDataObj);
                newDataObj = {};
              }
            });
          });

          const finalObject: any = Object.entries(newFinalArray[0]).filter(
            (e: any) => {
              return sublazyState?.title == "Visit Report"
                ? e[0] !== "doctorId" && e[0] !== "TotalRecords"
                : e[0] !== "TotalRecords" &&
                    e[0] !== "productId" &&
                    e[0] !== "chemistName" &&
                    e[0] !== "chemistId";
            }
          );
          const transformedArray = finalObject.reduce(
            (acc: any, [key, value]: any) => {
              const baseKey = key.replace(/_F$/, ""); // Remove '_F' from the end of the key
              const isF = key !== baseKey; // Check if it's the original or '_F' version

              if (!acc[baseKey]) {
                acc[baseKey] = {};
              }

              acc[baseKey][isF ? `${key}` : key] = value;

              return acc;
            },
            []
          );

          console.log(transformedArray, "this is my own aniket valo array");

          setHeaderArray(transformedArray);

          setSubTotalData(newFinalArray);
          setSubTotalRecord(data.TotalDataCount);
          setSubLoading(false);
        })
        .catch((error) => {
          setSubLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };
  const getAllMissedAPI = async (data: any) => {
    const date = data.paramter.EndDate.split("-");
    const MonthLastDate = moment([date[0], date[1] - 1])
      .endOf("month")
      .format("YYYY-MM-DD");

    let PayloadData = {
      FromDate: data.paramter.StartDate,
      ToDate: MonthLastDate,
      DoctorId: Number(data.paramter.DoctorIds),
      PageSize: 100,
      PageNumber: 1,
      ReportFor: 1,
    };
    console.log(data, "dataFrom Data");
    setSubLoading(true);
    await axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DCRGETALLADMIN_API}`,
        PayloadData
      )
      .then((res) => {
        console.log(res?.data?.Data.DoctorList, "Api REsponse");

        if (res?.data?.Data.DoctorList !== null) {
          let Temp = res?.data?.Data[api_data_mapping[1]].map(
            (d: any, index: any) => {
              return {
                ...d,
                srNo:
                  res?.data?.PageNumber * res?.data?.PageSize -
                  res?.data?.PageSize +
                  (index + 1),
              };
            }
          );
          console.log(Temp, "Temp Data");
          setSubTotalData(Temp);
          setSubTotalRecord(res.data.TotalDataCount);
        } else {
          setSubTotalData([]);
          setSubTotalRecord(0);
        }

        setSubLoading(false);
      });
  };

  useEffect(() => {
    if (sublazyState.paramter !== null) {
      console.log(sublazyState, "lazystate");
      if (sublazyState.title == "Visit Report") {
        getAllMissedAPI(sublazyState);
      } else {
        subLoadLazyData(sublazyState);
      }
    }
  }, [sublazyState]);

  const onSubPageChange = (e: any) => {
    setSublazyState({
      ...sublazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };
  // chemist apicall Over

  const commonFunction = (rowData: any, type: string) => {
    return (
      <>
        {((type === "Chemist" && rowData.ChemistName !== null) ||
          (type === "Sales" && rowData.ChemistName !== null) ||
          (type === "Visit" && rowData.ChemistName !== null)) && (
          <Badge
            style={{ cursor: "pointer" }}
            severity={
              rowData?.id ==
                (expandedRows !== null ? Object?.keys(expandedRows)[0] : "") &&
              expandedRows[rowData.id] == type
                ? "warning"
                : "info"
            }
            value={
              <div
                style={{
                  display: "inline-block",
                  maxWidth: "150px", // Adjust as needed
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  verticalAlign: "middle",
                }}
                title={
                  type === "Chemist"
                    ? rowData.ChemistName
                    : type === "Sales"
                    ? rowData.Sales
                    : type === "Visit"
                    ? rowData.TotalVisitCount
                    : ""
                } // Tooltip with full name
              >
                {type === "Chemist"
                  ? rowData.ChemistName
                  : type === "Sales"
                  ? rowData.Sales
                  : type === "Visit"
                  ? rowData.TotalVisitCount
                  : ""}
              </div>
            }
            onClick={() => {
              // debugger
              console.log("check", rowData);
              setHeaderArray([]);
              setSubTotalData(null);
              setSubTotalRecord(0);
              let obj: any = {};
              if (
                expandedRows != null &&
                compareKeys(expandedRows, rowData.id, type)
              ) {
                obj = {};
              } else {
                obj[rowData.id] = type;
                const temp: any = {
                  DoctorIds: rowData.DoctorId,
                  StartDate: lazyState.paramter.StartDate,
                  EndDate: lazyState.paramter.EndDate,
                };
                if (type === "Chemist" || type === "Sales") {
                  temp.ChemistId = rowData.ChemistId;
                }
                setSublazyState({
                  first: 0,
                  rows: 10,
                  page: 1,
                  search: "",
                  paramter: temp,
                  title:
                    type === "Chemist"
                      ? rowData?.ChemistName
                      : type === "Sales"
                      ? "Sales Report"
                      : type === "Visit"
                      ? "Visit Report"
                      : "",
                  apiName:
                    type === "Chemist" || type === "Sales"
                      ? "CRMDoctorChemist"
                      : type === "Visit"
                      ? "CRMDoctorVisitReport"
                      : "",
                });
              }
              setExpandedRows(obj);
              console.log(obj, "obj");
            }}
          ></Badge>
        )}
      </>
    );
  };

  const header = (
    <ColumnGroup>
      <Row>
        {Object.entries(headerArray).map((e, i) => {
          {
            if (!e[0].includes("_F")) {
              return e[0].includes("_") ? (
                <Column
                  header={moment(e[0]?.replace("_", " ")).format("MMM-YYYY")}
                  key={i}
                  colSpan={2}
                  align={"center"}
                />
              ) : (
                <Column
                  header={e[0]?.toUpperCase()}
                  key={i}
                  align={"center"}
                  rowSpan={2}
                  field={e[0]}
                />
              );
            }
          }
        })}
        <Column
          header={"Total"}
          key={"Total"}
          colSpan={2}
          style={{
            background: "#00a3ff91",
          }}
          align={"center"}
        />
      </Row>

      <Row>
        {Object.entries(headerArray).map((e) => {
          if (e[0].includes("_")) {
            const temp: any = Object.entries(e[1]).map((d, index) => {
              return d[0].includes("_F") ? (
                <Column
                  header="Free"
                  key={d[0] + index}
                  field={d[0]}
                  align={"center"}
                />
              ) : (
                <Column
                  header="Sales"
                  key={d[0] + index}
                  field={d[0]}
                  align={"center"}
                />
              );
            });
            return temp;
          }
        })}
        <Column
          header="Sales"
          key={"Sales"}
          style={{
            background: "#00a3ff91",
          }}
          align={"center"}
        />
        <Column
          header="Free"
          key={"Free"}
          style={{
            background: "#00a3ff91",
          }}
          align={"center"}
        />
      </Row>
    </ColumnGroup>
  );

  const calculateParameterSum = (dataArray: any, parameter: any) => {
    return dataArray.reduce((sum: any, item: any) => sum + item[parameter], 0);
  };

  const footer = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={2}
          align={"center"}
          style={{
            background: "#00a3ff91",
          }}
          footerStyle={{ textAlign: "right" }}
        />
        {Object.entries(headerArray)?.map((e) => {
          if (e[0].includes("_")) {
            return Object.entries(e[1]).map((d) => {
              return (
                <Column
                  style={{
                    background: "#00a3ff91",
                  }}
                  align={"center"}
                  footer={calculateParameterSum(subTotalData, d[0])}
                />
              );
            });
          }
        })}

        <Column
          align={"center"}
          style={{
            background: "#00a3ff91",
          }}
          footer={() => {
            let totalSalesSum: any = 0;
            subTotalData?.forEach((item: any) => {
              for (const [key, value] of Object.entries(item)) {
                if (key.includes("_") && !key.includes("_F")) {
                  totalSalesSum += value;
                }
              }
            });
            return totalSalesSum;
          }}
        />

        <Column
          align={"center"}
          style={{
            background: "#00a3ff91",
          }}
          footer={() => {
            let totalFreeSum: any = 0;
            subTotalData?.forEach((item: any) => {
              for (const [key, value] of Object.entries(item)) {
                if (key.includes("_F")) {
                  totalFreeSum += value;
                }
              }
            });
            return totalFreeSum;
          }}
        />
      </Row>
    </ColumnGroup>
  );

  const rowExpansionTemplate = () => {
    return (
      <>
        <div className="col-md-12 text-center">
          <h3 className="my-3">{sublazyState.title}</h3>
          <DataTable
            value={subTotalData}
            scrollable
            lazy
            key={"DoctorId"}
            dataKey="DoctorId"
            showGridlines
            className="custom-datatable"
            stripedRows
            paginator
            removableSort
            headerColumnGroup={header}
            footerColumnGroup={footer}
            first={sublazyState.first}
            rows={sublazyState.rows}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={subTotalRecord}
            onPage={onSubPageChange}
            sortField={sublazyState.sortField}
            sortOrder={sublazyState.sortOrder}
            loading={subLoading}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            size="small"
            rowHover
            emptyMessage="No data found."
            tableStyle={{ minWidth: "50rem" }}
          >
            {Object.entries(headerArray).map((e) => {
              if (e[0].includes("_")) {
                const temp: any = Object.entries(e[1]).map((d) => {
                  return (
                    <Column
                      field={d[0]}
                      key={d[0]}
                      style={{
                        background: d[0].includes("_F") ? "#E0FFFF" : "#c6ffd3",
                      }}
                      align={"center"}
                    />
                  );
                });
                return temp;
              } else {
                return (
                  <Column
                    field={e[0]}
                    key={e[0]}
                    frozen
                    align={"center"}
                    header={e[0]}
                  />
                );
              }
            })}

            <Column
              header="Total Sales"
              align={"center"}
              bodyStyle={{
                background: "#00a3ff91",
                fontWeight: "bold",
              }}
              body={(e: any) => {
                let sum = 0;
                for (const key in e) {
                  if (key.includes("_") && !key.includes("_F")) {
                    sum += e[key];
                  }
                }
                return sum;
              }}
            />
            <Column
              header="Total Free"
              align={"center"}
              bodyStyle={{
                background: "#00a3ff91",
                fontWeight: "bold",
              }}
              body={(e: any) => {
                let sum = 0;
                for (const key in e) {
                  if (key.includes("_F")) {
                    sum += e[key];
                  }
                }
                return sum;
              }}
            />
          </DataTable>
        </div>
      </>
    );
  };

  function compareKeys1(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    return aKeys[0] == b;
  }
  let GetVisitTimeLog = (id: any, type: string) => {
    setProductLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_VISITREPORTGETVISITTIMELOG_API
        }?${`DoctorVistId=${id}`}`
      )
      .then((res) => {
        console.log(res.data.Data.doctorVisitReportViewModels, "this is res");
        let temp1 = res.data.Data.doctorVisitReportViewModels.map(
          (d: any, index: any) => {
            return {
              ...d,
              SrNo: index + 1,
            };
          }
        );
        setDoctorVisitLog(temp1);

        setProductLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };
  const doctordata = (id: any) => {
    console.log(id, "DoctorVisitId");
    return (
      <>
        <span className="mr-2">
          <Badge
            onClick={() => {
              let obj: any = {};
              if (
                doctorExpandrow != null &&
                compareKeys1(doctorExpandrow, id.DoctorVisitId)
              ) {
                obj = {};
              } else {
                obj[id.DoctorVisitId] = true;
              }
              setDoctorExpandrow(obj);
              GetVisitTimeLog(id.DoctorVisitId, "doctor");
            }}
            style={{ cursor: "pointer" }}
            severity={"info"}
            value={
              <div
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  verticalAlign: "middle",
                }}
                title={id.UserName} // Tooltip with full name
              >
                {id.UserName}
              </div>
            }
          ></Badge>
        </span>
      </>
    );
  };
  const doctorRowExpansionTemplate = (rowData: any) => {
    return (
      <div>
        <DataTable value={doctorVisitLog} dataKey="id" loading={productLoading}>
          <Column field="SrNo" header="#" />
          <Column
            field="ProductName"
            header="ProductName"
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="Duration"
            header="Duration (seconds)"
            body={(e: any) => {
              var duration = moment?.duration(
                moment(e.EndTime).diff(moment(e.StartTime))
              );
              var hours = duration?.asSeconds();
              return hours;
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
        </DataTable>
      </div>
    );
  };
  const visitrowExpansionTemplate = () => {
    return (
      <>
        <div className="col-md-12 text-center">
          <h3 className="my-3">{sublazyState.title}</h3>
          <DataTable
            value={subTotalData}
            scrollable
            lazy
            key="DoctorVisitId"
            dataKey="DoctorVisitId"
            showGridlines
            className="custom-datatable"
            stripedRows
            removableSort
            first={sublazyState.first}
            rows={sublazyState.rows}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={subTotalRecord}
            onPage={onSubPageChange}
            sortField={sublazyState.sortField}
            sortOrder={sublazyState.sortOrder}
            loading={subLoading}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            size="small"
            rowHover
            emptyMessage="No data found."
            tableStyle={{ minWidth: "50rem" }}
            expandedRows={doctorExpandrow}
            rowExpansionTemplate={doctorRowExpansionTemplate}
          >
            <Column field="srNo" header="#" />
            <Column
              field="UserName"
              header="User Name"
              body={(e: any) => doctordata(e)}
              bodyStyle={{ fontSize: "12px", fontWeight: "bold" }}
            ></Column>
            <Column
              field="CompletedDate"
              header="Completed Date"
              body={(e: any) => {
                if (e.CompletedDate === null) {
                  return "-";
                } else {
                  return (
                    <>
                      <Badge
                        className="m-1"
                        severity={"success"}
                        value={moment(e.CompletedDate).format("DD/MM/YY")}
                      ></Badge>
                      <Badge
                        className="m-1"
                        severity={"info"}
                        value={moment(e.CompletedDate).format("hh:mm A")}
                        style={{ backgroundColor: "#978EEB" }}
                      ></Badge>
                    </>
                  );
                }
              }}
              bodyStyle={{ fontSize: "12px" }}
            ></Column>
          </DataTable>
        </div>
      </>
    );
  };

  const doctorSpecialityCategoryMergeTemplate = (rowData: any) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div>{rowData?.DoctorName?.toUpperCase()}</div>
          <div className="ml-auto">
            {rowData?.CategoryName != null && (
              <Badge
                value={rowData?.CategoryName}
                size="normal"
                style={{
                  backgroundColor: `${
                    rowData?.CategoryName === "A"
                      ? "green"
                      : rowData?.CategoryName === "B"
                      ? "#d4af37"
                      : rowData?.CategoryName === "C"
                      ? "red"
                      : ""
                  }`,
                }}
              />
            )}
          </div>
        </div>
        <div>
          {rowData.SpecialityName != null && (
            <Badge
              value={
                <div
                  style={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    verticalAlign: "middle",
                  }}
                  title={rowData.SpecialityName} // Tooltip with full name
                >
                  {rowData.SpecialityName}
                </div>
              }
              size="normal"
              style={{ backgroundColor: "#978EEB" }}
            />
          )}
        </div>
      </>
    );
  };
  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  return (
    <>
      <Helmet>
        <title>CRM Report</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>CRM Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  CRM Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((productinfo: any) => {
            onSubmit(productinfo);
          })}
        >
          <div className="row">
            <Input
              containerClass="col-lg-4"
              label="Start Month"
              type="month"
              isRequired
              errorMessage={errors?.StartDate?.message}
              onChange={(e: any) => {
                clearErrors(["StartDate"]);
                handleStartMonthChange(e);
              }}
              value={startMonth}
            />

            <Input
              containerClass="col-lg-4"
              label="End Month"
              type="month"
              isRequired
              errorMessage={errors?.EndDate?.message}
              onChange={(e: any) => {
                clearErrors(["EndDate"]);
                handleEndMonthChange(e);
              }}
              value={endMonth}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Doctor"
              control={control}
              name="doctorIds"
              onChange={(e: any) => {
                setselecteddoctor(e);
                setValue("doctorIds", e?.value);
              }}
              value={selecteddoctor}
              api={process.env.REACT_APP_DOCTORDETAIL_API}
              keys={{ label: "Value", value: "Id" }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Speciality"
              name={"specialityIds"}
              onChange={(e: any) => {
                setSpecialityIds(e);
                setValue("specialityIds", e?.value);
              }}
              value={specialityIds}
              api={process.env.REACT_APP_SPECIALITY_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Category"
              name={"categoryIds"}
              onChange={(e: any) => {
                setCategoryIds(e);
                setValue("categoryIds", e?.value);
              }}
              value={categoryIds}
              api={process.env.REACT_APP_CATEGORY_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="State"
              name={"stateIds"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setValue("stateIds", e?.value);
                  setStateIds(e);
                  setCityIds(null);
                  setHqIds(null);
                } else {
                  setValue("cityIds", null);
                  setValue("hqIds", null);
                  setValue("stateIds", null);
                  setStateIds(null);
                  setHqIds(null);
                  setCityIds(null);
                }
              }}
              value={stateIds}
              api={process.env.REACT_APP_STATE_BY_EMP_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="HQ"
              name={"hqIds"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setValue("hqIds", e?.value);
                  setHqIds(e);
                  setCityIds(null);
                } else {
                  setValue("cityIds", null);
                  setValue("hqIds", null);
                  setHqIds(null);
                  setCityIds(null);
                }
              }}
              value={hqIds}
              api={
                stateIds !== null
                  ? `${process.env.REACT_APP_HQ_BY_STATE_API}?StateId=${stateIds?.value}`
                  : ""
              }
              Disabled={stateIds !== null ? false : true}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              flag
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="City"
              name={"cityIds"}
              onChange={(e: any) => {
                setCityIds(e);
                setValue("cityIds", e?.value);
              }}
              value={cityIds}
              api={
                hqIds !== null
                  ? `${process.env.REACT_APP_CITY_BY_EMPLOYEE_REPORTING_CITY_API}?hqId=${hqIds?.value}&stateId=${stateIds?.value}`
                  : ""
              }
              Disabled={hqIds !== null ? false : true}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              flag
            />
          </div>

          <div className="col-md-12 text-center">
            {!loading ? (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            ) : (
              <button type="submit" className="btn btn-primary" disabled>
                Submit{" "}
                {lazyState.sortField === null &&
                  lazyState.sortOrder === null && (
                    <Spinner width={"1rem"} height={"1rem"} />
                  )}
              </button>
            )}
          </div>
        </form>
      </div>
      {totalData !== null && (
        <div className="pd-20 card-box mb-30">
          <DataTable
            value={totalData}
            scrollable
            lazy
            key={"id"}
            dataKey="id"
            showGridlines
            className="custom-datatable"
            stripedRows
            paginator
            removableSort
            first={lazyState.first}
            rows={lazyState.rows}
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRecords={totalRecord}
            onPage={onPageChange}
            loading={loading}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            size="small"
            rowHover
            onSort={OnSortChange}
            sortField={lazyState.sortField}
            sortOrder={lazyState.sortOrder}
            emptyMessage="No data found."
            tableStyle={{ minWidth: "50rem" }}
            expandedRows={expandedRows}
            rowExpansionTemplate={
              sublazyState?.title == "Visit Report"
                ? visitrowExpansionTemplate
                : rowExpansionTemplate
            }
            style={{ cursor: "text" }}
          >
            <Column field="SrNo" header="#" style={{ width: "3%" }} />
            <Column
              field="DoctorName"
              key={"DoctorName"}
              header="Doctor Name"
              style={{ width: "50vW" }}
              // filter={true}
              sortable={true}
              body={doctorSpecialityCategoryMergeTemplate}
            />
            <Column
              field="QualificationName"
              key={"QualificationName"}
              header="Qualification"
              style={{ width: "10vW" }}
              sortable={true}
            />
            <Column
              field="CityName"
              key={"CityName"}
              header="City | Email | Mobile"
              style={{ width: "10vW" }}
              sortable={true}
              body={(data: any) => {
                return `${NullHandler(data.CityName)} | ${NullHandler(
                  data.Mobile
                )} | ${NullHandler(data.Email)}`;
              }}
            />

            <Column
              field="PreferedCallDay"
              key={"PreferedCallDay"}
              header="PreferredCallDay"
              style={{ width: "10vW" }}
            />

            <Column
              field="ChemistName"
              key={"ChemistName"}
              header="Chemist Name"
              style={{ width: "10vW" }}
              body={(e) => commonFunction(e, "Chemist")}
              sortable={true}
            />

            <Column
              field="CRM"
              key={"CRM"}
              header="CRM"
              style={{ width: "10vW" }}
            />
            <Column
              field="Potential"
              key={"Potential"}
              header="Potential"
              style={{ width: "10vW" }}
            />
            <Column
              field="Sales"
              key={"Sales"}
              header="Sales"
              style={{ width: "10vW" }}
              body={(e) => commonFunction(e, "Sales")}
            />
            <Column
              field="TotalVisitCount"
              key={"TotalVisitCount"}
              header="Visit Count"
              style={{ width: "10vW" }}
              body={(e) => commonFunction(e, "Visit")}
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

export default CRMReportGrid;
