import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import axiosInstance from "../../../_helper/Interceptor";
import { Button } from "primereact/button";

export const AddEditRolePermission = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ROLE_API?.toString();

  const { data, isLoading: rolepermissionLoader }: any = useFetchAllID(
    apiname,
    id
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  useEffect(() => {

    if (id) {
      if (data) {
        reset(data);
        data.RolePermissionModel = data?.RolePermissionModel?.map((d: any) => ({
          label: d?.PermissionName,
          value: d?.PermissionId,
        }));
        setValue("PermissionId", data.RolePermissionModel);
        setselectedpermission(data?.RolePermissionModel);
      }
    }
  }, [data, id]);

  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error,
    data: response,
  }: any = useCreateByID(apiname, id);

  const [selectedpermission, setselectedpermission] = useState([]);

  const RolePermissionData = (data: any) => {

    data.PermissionId = selectedpermission?.map((e: any) => {
      return e?.value;
    });
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/admin/permission/rolepermission");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/admin/permission/rolepermission");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Helmet>
        <title>{id === undefined ? "Add" : "Edit"} Role Permission </title>
      </Helmet>

      <PageHeader
        headTitle="Permission"
        headTitleURL={"/layout/admin/permission/rolepermission"}
        list={false}
        id={id}
      />

      {!rolepermissionLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((info: any) => {
              RolePermissionData(info);
            })}
          >
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Role Name"
                type="text"
                maxLength={100}
                isRequired
                errorMessage={
                  errors?.RoleName?.message ||
                  error?.response?.data?.errors?.RoleName?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("RoleName", e.target.value);
                  clearErrors(["RoleName"]);
                }}
                register={{
                  ...register("RoleName", {
                    required: {
                      value: true,
                      message: "Role Name is required",
                    },
                    pattern: {
                      value: /^(?!\s)\w[a-z0-9A-Z\s',-]+(?!\s)$/,
                      message:
                        "Enter only letters and digits, Remove whitespace from both sides of a string",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label={"Permission"}
                isRequired
                name={"PermissionId"}
                onChange={(e: any) => {
                  setValue("PermissionId", e);
                  setselectedpermission(e);
                }}
                value={selectedpermission}
                control={control}
                api={"Permission"}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.PermissionId?.message ||
                  error?.response?.data?.errors?.PermissionId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Permission is required",
                  },
                }}
                Multi
              />

              <div className="col-lg-12 text-center mt-3">
                <Link to={"/layout/admin/permission/rolepermission"}>
                  <Button type="button" severity="secondary" outlined>
                    <span className="p-button-label p-c">Back</span>
                  </Button>
                </Link>

                {
                  id == undefined && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedpermission([]) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};
