import React, { useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { AddEditStockistMap } from "./AddEditStockistMap";
import { useForm } from "react-hook-form";
import ReactSelect from "../../../common/ReactSelect";

export const StockistMap = () => {
  const apiname = process.env.REACT_APP_STOCKISTMAP_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [isSelf, setIsSelf]: any = useState(false);
  const [selectedEmployee, setSelectedEmployee]: any = useState(null)
  const [hqIds, setHqIds]: any = useState(null)
  const [resetPage, setResetPage] = useState(false);
  const user = localStorage.getItem("Roles");
  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedRows, setselectedRows]: any = useState([]);

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "2%",
    },
    {
      header: "Action",
      body: (e: any) => actionStockistMapTemplate(e),
      sortable: false,
      width: "6%",
    },
    {
      field: "EmployeeName",
      header: "Employee",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "StockistList.Value",
      header: "Stockist",
      bodyClassName: "text-success h6",
      body: (e: any) => actionStockistTemplate(e),
      width: "10vw",
    },

  ];
  const actionStockistTemplate = (id: any) => {
    console.log(id, "id")
    return id.StockistList?.length == 0 ? " - " : id.StockistList
      ?.map((ab: any) => {
        return ab.Value;
      })
      .join(", ");
  };
  const [updateId, setupdateId]: any = useState(null);

  const actionStockistMapTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.EmployeeId}
          editModal={() => {
            setupdateId(id.EmployeeId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Stockist Map</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Stockist Map"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/mapping"}
          linkflagtitle={"Mapping"}
        />
        {/* uncomment This Code */}
        {/* {
          user !== "MR" &&
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              label="Employee"
              name={"Id"}
              onChange={(e: any) => {

                if (e === null) {
                  setSelectedEmployee(null);
                  // setbool(true);
                  setResetPage(true)
                }
                else {
                  setIsSelf(false);
                  setSelectedEmployee(e);
                  setResetPage(true)

                }
                // setValue("PermissionId", e?.value);
              }}
              value={selectedEmployee}
              control={control}
              api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
              keys={{ label: "Value", value: "Id" }}

            />
            <ReactSelect
              containerClass="col-lg-4"
              label="HQ"
              name={"hqIds"}
              onChange={(e: any) => {
                setHqIds(e);
                setValue("hqIds", e?.value);
                setResetPage(true)
              }}
              value={hqIds}
              api={process.env.REACT_APP_HQ_API}
              // api={process.env.REACT_APP_HQ_BY_STATE_API}
              // Disabled={stateIds !== null ? false : true}
              keys={{ label: "Value", value: "Id" }}
              control={control}
            // flag
            />
            <div className="col-md-4">
              <div className="custom-control custom-checkbox pt-5">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={(e) => {
                    if (e.target.checked == true) {
                      setSelectedEmployee(null);
                    }
                    setIsSelf(e.target.checked);
                    // setbool(true)
                    setResetPage(true)
                  }}
                  id="New"
                  checked={isSelf}
                />
                <label className="custom-control-label" htmlFor="New">
                  Self
                </label>
              </div>
            </div>
          </div>
        } */}
        {/* end of Uncomment */}
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          selecteditem={selectedRows}
          setselecteditem={setselectedRows}
          employeeId={selectedEmployee?.value}
          resetPage={resetPage}
          cancelReset={() => setResetPage(false)}
          flagIsSelf={isSelf}
        // hqId={hqIds?.value}
        />
        {updateId !== null && (
          <AddEditStockistMap
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};