import { useState } from "react";
import { AddEditLeave } from "./AddEditLeave";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { useLocation, useParams } from "react-router-dom";

export const Leave = () => {
  const { state } = useLocation();
  console.log(state);

  const apiname =
    typeof state == "string"
      ? `${process.env.REACT_APP_LEAVE_API_DATE_WISE}?LeaveDate=${state}`
      : process.env.REACT_APP_LEAVE_API?.toString();
  console.log(apiname, "api")
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  //   useEffect(() => {
  //     if (refetchData == true && api == apiname) {
  //       loadLazyData();
  //     }
  //   }, [refetchData,api]);

  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.LeaveId;
  });

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "UserName",
      header: "Employee Name",
      body: null,
      sortable: true,
      width: "5vw",
    },
    {
      field: "LeaveTypeName",
      header: "Leave Type",
      body: null,
      sortable: true,
      width: "5vw",
    },
    {
      field: "LeaveDayTypeName",
      header: "Leave Day Type Name",
      body: null,
      sortable: true,
      width: "5vw",
    },
    {
      field: "NumberOfDays",
      header: "Number Of Days",
      body: null,
      sortable: true,
      width: "5vw",
    },
    {
      field: "FromDate",
      header: "From Date",
      body: (e: any) => actionStartDateTemplate(e),
      sortable: true,
      width: "5vw",
      dataType: "date"
    },
    {
      field: "ToDate",
      header: "To Date",
      body: (e: any) => actionEndDateTemplate(e),
      sortable: true,
      width: "5vw",
      dataType: "date"
    },
    {
      field: "Reason",
      header: "Reason",
      body: null,
      width: "10vw",
    },
    {
      field: "StatusName",
      header: "Status",
      sortable: true,
      width: "5vw",
      body: (e: any) => statusFunction(e),
    },
  ];

  const [updateId, setupdateId]: any = useState(null);

  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        {id.StatusName != "Approve" && (
          <Action
            id={id.LeaveId}
            editModal={() => {
              setupdateId(id.LeaveId);
            }}
            displayid={setDeleteidtoDelete}
          />
        )}
      </>
    );
  };

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {e.StatusName == "Approve" ? "Approved" : e.StatusName == "Reject" ? "Rejected" : e.StatusName}
      </span>
    );
  };

  const actionStartDateTemplate = (id: any) => {
    return id.FromDate?.split("T")[0];
  };

  const actionEndDateTemplate = (id: any) => {
    return id.ToDate?.split("T")[0];
  };

  return (
    <>
      <Helmet>
        <title>Leave</title>
      </Helmet>
      <div>
        <PageHeader
          headTitle="Leave"
          list={true}
          onClick={() => {
            setupdateId(0);
          }}
          linkflag
          linkflagURL={"/layout/tourprogram"}
          linkflagtitle={"Tour Program"}
        />
        <DatatableCommon
          apiname={apiname}
          isCalenderLeave={typeof state == "string" ? true : false}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
        />
        {updateId !== null && (
          <AddEditLeave
            setisOPen={() => {
              setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
