import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import axiosInstance from "../../../_helper/Interceptor";
import { useFieldArray, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../common/ErrorMessage";
import Select from "react-select";
import { Input } from "../../../common/Input";
import {
  useCreate,
  useCreateByID,
  useFetchAllID,
} from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";

import ReactSelect from "../../../common/ReactSelect";

const AddSRC = () => {
  const { id }: any = useParams();
  const [selectedemployee, setselectedemployee]: any = useState(null);
  const apiname = process.env.REACT_APP_SRC_API?.toString();

  const navigate = useNavigate();
  const role = localStorage.getItem("Roles");
  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues,
    reset,
  }: any = useForm({
    defaultValues: {
      routeCities: [{ StartCity: "", EndCity: "", Kms: "", Os: "" }],
    },
  });

  const ds = watch();
  console.log(ds);

  const fd = watch("routeCities");
  console.log("routeCities", fd);

  const { data, isLoading: SampleLoader }: any = useFetchAllID(apiname, id);

  useEffect(() => {
    if (id) {
      if (data) {
        console.log(data, "this is api data");
        setselectedemployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        })
        setValue("EmployeeIds", data.EmployeeId);
        console.log(data?.srcRouteModel, "data?.srcRouteModel")
        data.srcRouteModel = data?.srcRouteModel?.map((e: any, index: any) => {
          setValue(`routeCities.${index}.EndCity`, {
            label: e.EndCityName,
            value: e.EndCity,
          });
          setValue(`routeCities.${index}.StartCity`, {
            label: e.StartCityName,
            value: e.StartCity,
          });
          setValue(`routeCities.${index}.Kms`, e.Kms);
          setValue(`routeCities.${index}.Os`, e.Os);
          return {
            StartCity: { label: e.StartCityName, value: e.StartCity },
            EndCity: { label: e.EndCityName, value: e.EndCity },
            Kms: Number(e.Kms),
            Os: e.Os,
          };
        });
        setValue("routeCities", data?.srcRouteModel);
        console.log(ds, "This is from api");
      }
    }
  }, [data]);

  const {
    fields: city,
    append,
    remove,
  } = useFieldArray({ control, name: "routeCities" });

  const onSubmit = (form: any) => {

    console.log(form, "dsdsd");
    const test = form?.routeCities?.map((e: any) => {
      console.log(e);
      return {
        StartCity: e.StartCity.value,
        EndCity: e.EndCity.value,
        Kms: Number(e.Kms),
        Os: e.Os === true ? true : false,
      };
    });

    const Finaldata = {
      EmployeeId: selectedemployee?.value,
      routeCities: test,
    };
    const Updatedata = {
      RouteId: data.RouteId,
      routeCities: test,
      EmployeeId: selectedemployee?.value,
    };

    console.log(Finaldata, Updatedata, "sdsd");
    mutate(!id ? Finaldata : Updatedata);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === undefined) {
        toast.success("Added Successfully", { position: "top-right" });
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
      }
      navigate("/layout/master/claim/src");
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);



  return (
    <>
      <Helmet>
        <title>{id == undefined ? "Add" : "Edit"} SRC</title>
      </Helmet>
      <PageHeader
        headTitle="SRC"
        headTitleURL={"/layout/master/claim/src"}
        list={false}
        id={id}
      />

      {!SampleLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((data: any) => {
              onSubmit(data);
            })}
          >
            <div className="row mb-3">
              <div className="col-md-6">
                <h4 className="mt-3">SRC</h4>
              </div>
              <div className="col-md-6 text-right">
                <button
                  type="button"
                  onClick={() => {
                    append({
                      StartCity: ds.routeCities[ds.routeCities.length - 1].EndCity,
                      EndCity: "",
                      Kms: "",
                      Os: "",
                    });
                  }}
                  className="btn btn-sm btn-outline-primary mt-4"
                >
                  Add
                </button>
              </div>
            </div>
            <table className="table table-bordered table-striped ">
              <thead>
                <tr className="text-center">
                  <th>Start City</th>
                  <th>End City</th>
                  <th>Kilometer</th>
                  <th>Night Stand</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {city.map((field, index) => {
                  const singleValue1 = getValues(
                    `routeCities.${index}.StartCity`
                  );
                  const singleValue2 = getValues(
                    `routeCities.${index}.EndCity`
                  );
                  const singleValue = getValues(
                    `routeCities.${index - 1}.EndCity`
                  );
                  return (
                    <>
                      <tr>
                        <td style={{
                          width: "20%"
                        }}>
                          <ReactSelect
                            containerClass="col-lg-12"
                            isRequired
                            name={`routeCities.${index}.StartCity`}
                            value={index !== 0 ? singleValue : singleValue1}
                            onChange={(e: any) => {
                              setValue(`routeCities.${index}.StartCity`, index === 0 ? e : singleValue);
                            }}
                            control={control}
                            api={process.env.REACT_APP_CITY_AND_STATE_API}
                            keys={{ label: "Value", value: "Id" }}
                            errorMessage={
                              errors?.["routeCities"]?.[index]?.["StartCity"]
                                ?.message
                            }
                            rules={{
                              required: {
                                value: true,
                                message: "Start city is required",
                              },
                            }}
                          />
                        </td>
                        <td style={{
                          width: "20%"
                        }}>
                          <ReactSelect
                            containerClass="col-lg-12"
                            isRequired
                            name={`routeCities.${index}.EndCity`}
                            value={singleValue2}
                            onChange={(e: any) => {
                              setValue(`routeCities.${index}.EndCity`, e);
                            }}
                            control={control}
                            api={process.env.REACT_APP_CITY_AND_STATE_API}
                            keys={{ label: "Value", value: "Id" }}
                            errorMessage={
                              errors?.["routeCities"]?.[index]?.["EndCity"]
                                ?.message
                            }
                            rules={{
                              required: {
                                value: true,
                                message: "End city is required",
                              },
                            }}
                          />
                        </td>
                        <td>
                          <div className="mt-3">
                            <Input
                              containerClass="col-lg-12"
                              isRequired
                              type="text"
                              register={{
                                ...register(`routeCities.${index}.Kms`, {
                                  required: {
                                    value: true,
                                    message: "kms is Required",
                                  },
                                  pattern: {
                                    value: /^\d{0,5}$/,
                                    message:
                                      "Enter a valid kms (1-5 digits only)",
                                  },
                                }),
                              }}
                              errorMessage={
                                errors?.["routeCities"]?.[index]?.["Kms"]
                                  ?.message
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="mt-3">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox mt-4">
                                <input
                                  {...register(`routeCities.${index}.Os`, {})}
                                  id={`routeCities.${index}.Os`}
                                  type="checkbox"
                                  className="custom-control-input"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`routeCities.${index}.Os`}
                                >
                                  Night Stay
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-lg-12 text-center">
                            {city.length !== 1 ? (
                              <button
                                type="button"
                                onClick={() => {
                                  remove(index);
                                }}
                                className="btn btn-sm btn-outline-danger"
                              >
                                Delete Data
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  remove(index);
                                }}
                                className="btn btn-sm btn-outline-danger"
                                disabled
                              >
                                Delete Data
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            {role === "MR" ? "" : (
              <ReactSelect
                containerClass="col-lg-4"
                isRequired
                control={control}
                onChange={(e: any) => {
                  setValue("EmployeeIds", e);
                  setselectedemployee(e);
                }}
                value={selectedemployee}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                name="EmployeeIds"
                label="Employee"
                errorMessage={
                  errors?.EmployeeIds?.message ||
                  error?.response?.data?.errors?.EmployeeIds?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Employee is required",
                  },
                }}
              />)}


            {!isLoading ? (
              <div className="col-lg-12 text-center">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            ) : (
              <div className="col-lg-12 text-center">
                <button className="btn btn-primary" type="submit" disabled>
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              </div>
            )}
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};

export default AddSRC;
