import { Link, useNavigate } from 'react-router-dom'
import img from '../../assets/images/forgot-password.png'
import { useForm } from 'react-hook-form';
import { useForgetPassword } from '../../hooks/login/useLoginData';
import { Input } from '../../common/Input';
import { Helmet } from 'react-helmet';
import { Spinner } from '../../common/Spinner';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Title } from '../../common/Title';

const ForgetPassword = () => {
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        reset,
    }: any = useForm();

    const { mutate, isSuccess, isError, error, isLoading }: any = useForgetPassword();

    const onSubmit = (data: any) => {
        mutate(data)
    }

    useEffect(() => {
        if (isSuccess) {
            navigate("/thanks")
        }
        if(isError){
            toast.error("User not found with this email", { position: "top-right" })
        }
    }, [isSuccess,isError])

    return (
        <>
            <Title title='Forgot Password' />
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center" style={{ position: "absolute", top: "0", left: "0", bottom: "0", right: "0" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={img} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="login-box bg-white box-shadow border-radius-10">
                                <div className="login-title">
                                    <h2 className="text-center text-primary">Forgot Password</h2>
                                </div>
                                <h6 className="mb-20">
                                    Enter your email address to reset your password
                                </h6>
                                <form onSubmit={handleSubmit((logininfo: any) => {
                                    onSubmit(logininfo);
                                })}>
                                    <Input
                                        containerClass='col-lg-12 p-0'
                                        type='email'
                                        register={{
                                            ...register("UserName", {
                                                required: {
                                                    value: true,
                                                    message: "This Field is required",
                                                },
                                            })
                                        }}
                                        placeholder='Email'
                                        errorMessage={errors?.UserName?.message}

                                    />
                                    <div className="row align-items-center">
                                        <div className="col-5">
                                            <div className="input-group mb-0">
                                                {
                                                    !isLoading ? <button type='submit' className="btn btn-primary btn-lg btn-block">Submit</button> : <button type='submit' className="btn btn-primary btn-lg btn-block" disabled>Submit <Spinner width={"1rem"} height={"1rem"} /></button>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="font-16 weight-600 text-center" data-color="#707373" >
                                                OR
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="input-group mb-0">
                                                <Link to="/" className="btn btn-outline-primary btn-lg btn-block">Login</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword
