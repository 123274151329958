import React, { useEffect, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useTerritoryByID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import ReactSelect from "../../../../common/ReactSelect";
import axiosInstance from "../../../../_helper/Interceptor";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { da } from "@faker-js/faker";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export default function AddEditCityModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_CITY_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm();

  const { data, isLoading: cityLoader }: any = useTerritoryByID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (
          data.DistrictId !== null &&
          data.DistrictId !== undefined
        ) {
          setValue("DistrictId", {
            label: data.DistrictName,
            value: data.DistrictId,
          });
          setselecteddistrict({
            label: data.DistrictName,
            value: data.DistrictId,
          });
        } else {
          setValue("DistrictId", null);
          setselecteddistrict(null);
        }

        if (
          data.HQId !== null &&
          data.HQId !== undefined
        ) {
          setValue("HQId", {
            label: data.HQName,
          value: data.HQId,
          });
          setselectedhq({
            label: data.HQName,
          value: data.HQId,
          });
        } else {
          setValue("HQId", null);
          setselectedhq(null);
        }

      }
    }
    console.log(data, "sdsds");
  }, [data]);

  const [selecteddistrict, setselecteddistrict]: any = useState(null);

  const [selectedhq, setselectedhq]: any = useState(null);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const cityFormData = (data: any) => {
    data.DistrictId = selecteddistrict?.value;
    data.HQId = selectedhq?.value;
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} City`}
        modal
        style={{ width: "1100px" }}
      >
        {!cityLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(cityFormData);
              }
            }}
            onSubmit={handleSubmit(cityFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-12"
                label="District"
                name={"DistrictId"}

                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DistrictId", e?.value);
                    setselecteddistrict(e);
                  } else {
                    setValue("DistrictId", null);
                    setselecteddistrict(null);
                  }
                }}
                value={selecteddistrict}
                control={control}
                api={process.env.REACT_APP_DISTRICT_API}
                keys={{ label: "Value", value: "Id" }}
                isRequired
                errorMessage={
                  errors?.DistrictId?.message ||
                  error?.response?.data?.errors?.DistrictId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "District is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-12"
                label="HQ"
                name={"HQId"}

                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("HQId", e?.value);
                    setselectedhq(e);
                  } else {
                    setValue("HQId", null);
                    setselectedhq(null);
                  }
                }}
                value={selectedhq}
                control={control}
                api={process.env.REACT_APP_HQ_API}
                keys={{ label: "Value", value: "Id" }}
                isRequired
                errorMessage={
                  errors?.HQId?.message ||
                  error?.response?.data?.errors?.HQId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "HQ is required" },
                }}
              />

              <Input
                containerClass="col-lg-12"
                label="City"
                type="text"
                isRequired
                errorMessage={
                  errors?.CityName?.message ||
                  error?.response?.data?.errors?.CityName?.map((e: any) => e)
                }
                onChange={(e: any) => { setValue("CityName", e.target.value); clearErrors(["CityName"]) }}
                register={{
                  ...register("CityName", {
                    required: {
                      value: true,
                      message: "City Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s'-]{0,50}$/,
                      message:
                        "Enter a valid city name (1-50 alphabet characters only)",
                    },
                  }),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselecteddistrict(null); setselectedhq(null) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
