import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import ReactSelect from "../../../../common/ReactSelect";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { AddEditPriorityTypeModal } from "../../../master/submaster/priorityType/AddEditPriorityTypeModal";
import AddEditSpecialityModal from "../../../master/speciality/AddEditSpecialityModal";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { InputMask } from "primereact/inputmask";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export default function AddEditPatientsReferToModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "PriorityType") {
      // fetchPriorityTypeOptions();
      setselectedprioritytype(null);
      resetstate(false);
    } else if (refetchData == true && api == "Speciality") {
      // fetchSpecialityOptions();
      setselectedspeciality(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname = process.env.REACT_APP_DOCTORPATIENTREFERTO_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );
  useEffect(() => {
    if (data) {
      if (id) {
        const Date = data?.ReferOnDate?.split("T")[0];
        reset(data);
        setValue("ReferOnDate", Date);

        if (data.PatientReferToHospitalCityId !== null) {
          setValue("PatientReferToHospitalCityId", {
            label: data.PatientReferToHospitalCity,
            value: data.PatientReferToHospitalCityId,
          });
          setselectedhcity({
            label: data.PatientReferToHospitalCity,
            value: data.PatientReferToHospitalCityId,
          });
        } else {
          setValue("PatientReferToHospitalCityId", null);
          setselectedhcity(null);
        }
        if (data.PatientReferToHospitalPincodeId !== null) {
          setValue("PatientReferToHospitalPincodeId", {
            label: data.PatientReferToHospitalPincode,
            value: data.PatientReferToHospitalPincodeId,
          });
          setselectedhpincode({
            label: data.PatientReferToHospitalPincode,
            value: data.PatientReferToHospitalPincodeId,
          });
        } else {
          setValue("PatientReferToHospitalPincodeId", null);
          setselectedhpincode(null);
        }

        if (
          data.PatientReferToPriorityTypeId !== null &&
          data.PatientReferToPriorityTypeId !== undefined
        ) {
          setValue("PatientReferToPriorityTypeId", {
            label: data.PatientReferToPriorityType,
            value: data.PatientReferToPriorityTypeId,
          });
          setselectedprioritytype({
            label: data.PatientReferToPriorityType,
            value: data.PatientReferToPriorityTypeId,
          });
        } else {
          setValue("PatientReferToPriorityTypeId", null);
          setselectedprioritytype(null);
        }

        if (
          data.PatientReferToDoctorSpecilityId !== null &&
          data.PatientReferToDoctorSpecilityId !== undefined
        ) {
          setValue("PatientReferToDoctorSpecilityId", {
            label: data.PatientReferToDoctorSpecility,
            value: data.PatientReferToDoctorSpecilityId,
          });
          setselectedspeciality({
            label: data.PatientReferToDoctorSpecility,
            value: data.PatientReferToDoctorSpecilityId,
          });
        } else {
          setValue("PatientReferToDoctorSpecilityId", null);
          setselectedspeciality(null);
        }
 
        setmaskvalue(data.Mobile);
      }
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const Hcity = watch("PatientReferToHospitalCityId");

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedspeciality, setselectedspeciality]: any = useState(null);
  const [selectedprioritytype, setselectedprioritytype]: any = useState(null);
  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);

  const [maskvalue, setmaskvalue]: any = useState();

  const areaofinterestFormData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.Mobile = maskvalue?.replace(/[^\d]/g, "");

    data.PatientReferToDoctorSpecilityId = selectedspeciality?.value;
    data.PatientReferToPriorityTypeId = selectedprioritytype?.value;
    data.PatientReferToHospitalPincodeId = selectedhpincode?.value;
    data.PatientReferToHospitalCityId = selectedhcity?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Patient Refer To`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(areaofinterestFormData);
              }
            }}
            onSubmit={handleSubmit(areaofinterestFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Name of Doctor"
                type="text"
                isRequired
                errorMessage={
                  errors?.PatientReferToDoctorName?.message ||
                  error?.response?.data?.errors?.PatientReferToDoctorName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("PatientReferToDoctorName", e.target.value);
                  clearErrors(["PatientReferToDoctorName"]);
                }}
                register={{
                  ...register("PatientReferToDoctorName", {
                    required: {
                      value: true,
                      message: "Doctor Name is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid doctor name (2-50 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Speciality"
                control={control}
                isRequired
                name="PatientReferToDoctorSpecilityId"
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PatientReferToDoctorSpecilityId", e?.value);
                    setselectedspeciality(e);
                  } else {
                    setValue("PatientReferToDoctorSpecilityId", null);
                    setselectedspeciality(null);
                  }
                }}
                value={selectedspeciality}
                api={process.env.REACT_APP_SPECIALITY_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.PatientReferToDoctorSpecilityId?.message ||
                  error?.response?.data?.errors?.PatientReferToDoctorSpecilityId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Speciality is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Hospital Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.PatientReferToHospitalName?.message ||
                  error?.response?.data?.errors?.PatientReferToHospitalName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("PatientReferToHospitalName", e.target.value);
                  clearErrors(["PatientReferToHospitalName"]);
                }}
                register={{
                  ...register("PatientReferToHospitalName", {
                    required: {
                      value: true,
                      message: "Hospital Name is required",
                    },
                    pattern: {
                      value: /^.{1,100}$/gm,
                      message:
                        "Enter a valid hospital name (1-100 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Priority"
                control={control}
                isRequired
                name="PatientReferToPriorityTypeId"
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PatientReferToPriorityTypeId", e?.value);
                    setselectedprioritytype(e);
                  } else {
                    setValue("PatientReferToPriorityTypeId", null);
                    setselectedprioritytype(null);
                  }
                }}
                value={selectedprioritytype}
                api={process.env.REACT_APP_PRIORITYTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.PatientReferToPriorityTypeId?.message ||
                  error?.response?.data?.errors?.PatientReferToPriorityTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Priority is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Patient Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.PatientName?.message ||
                  error?.response?.data?.errors?.PatientName?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("PatientName", e.target.value);
                  clearErrors(["PatientName"]);
                }}
                register={{
                  ...register("PatientName", {
                    required: {
                      value: true,
                      message: "Patient Name is required",
                    },
                    pattern: {
                      value: /^.{1,50}$/gm,
                      message:
                        "Enter a valid patient name (1-50 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <div className="col-lg-4">
                <div className="form-group">
                  <label className="col-form-label">Mobile No.</label>
                  <InputMask
                    value={maskvalue}
                    mask="(999) 999-9999"
                    className="form-control"
                    onChange={(e: any) => setmaskvalue(e?.value)}
                  />
                  <ErrorMessage
                    message={
                      errors?.Mobile?.message ||
                      error?.response?.data?.errors?.Mobile?.map((e: any) => e)
                    }
                  />
                </div>
              </div>

              <Input
                containerClass="col-lg-4"
                label="Refer Date"
                type="date"
                errorMessage={
                  errors?.ReferOnDate?.message ||
                  error?.response?.data?.errors?.ReferOnDate?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("ReferOnDate", e.target.value);
                  clearErrors(["ReferOnDate"]);
                }}
                register={{
                  ...register("ReferOnDate"),
                }}
              />

              <Address
                control={control}
                header="Address"
                address1register={{
                  ...register("PatientReferToHospitalAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue(
                    "PatientReferToHospitalAddressLine1",
                    e.target.value
                  );
                  clearErrors(["PatientReferToHospitalAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.PatientReferToHospitalAddressLine1?.message ||
                  error?.response?.data?.errors?.PatientReferToHospitalAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("PatientReferToHospitalAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue(
                    "PatientReferToHospitalAddressLine2",
                    e.target.value
                  );
                  clearErrors(["PatientReferToHospitalAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.PatientReferToHospitalAddressLine2?.message ||
                  error?.response?.data?.errors?.PatientReferToHospitalAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PatientReferToHospitalCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("PatientReferToHospitalCityId", null);
                    setValue("PatientReferToHospitalPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.PatientReferToHospitalCityId?.message ||
                  error?.response?.data?.errors?.PatientReferToHospitalCityId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PatientReferToHospitalPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("PatientReferToHospitalPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.PatientReferToHospitalPincodeId?.message ||
                  error?.response?.data?.errors?.PatientReferToHospitalPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedhcity}
                selectedPincode={selectedhpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"PatientReferToHospitalCityId"}
                pincodeApi={
                  selectedhcity != null
                    ? `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}`
                    : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"PatientReferToHospitalPincodeId"}
                pincodeDisable={selectedhcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedhcity(null);
                      setselectedhpincode(null);
                      setselectedprioritytype(null);
                      setselectedspeciality(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
