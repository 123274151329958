import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PaginationServiceByChemistDeletedApproval } from "../../service/PaginationService";
import { toast } from "react-toastify";
import axiosInstance from "../../_helper/Interceptor";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../common/ReactSelect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ApprovalForDeletedChemist = () => {
  const controller = new AbortController();
  const [id, setId] = useState(null);
  const [gridData, setgridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState();

  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  const [selecteduser, setselecteduser]: any = useState(null);
  const [Nodata, setNodata] = useState(false);

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: null,
  });

  let networkTimeout: any = null;

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByChemistDeletedApproval.getData(lazyState, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              srNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          setgridData(temp);
          setTotalRecord(data.TotalDataCount);
          setLoading(false);
          if (temp?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    }
  }, [lazyState]);

  useEffect(() => {
    if (selecteduser !== null) {
      setlazyState({
        ...lazyState,
        paramter: selecteduser,
      });
    }
  }, [selecteduser]);

  console.log(lazyState, "lazyState");

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {e.StatusName == "Approve"
          ? "Approved"
          : e.StatusName == "Reject"
            ? "Rejected"
            : e.StatusName}
      </span>
    );
  };

  const handleApprove = (id: any, status: string) => {
    setLoading(true);
    const approveData = {
      ChemistId: id,
      Status: status,
    };
    axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTDELETEAPPROVAL_API}/${id}`,
        approveData
      )
      .then((res) => {
        setLoading(false);
        toast.success(status === "Approve" ? "Approved" : "Rejected", { position: "top-right" });
        loadLazyData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const actionFucntion = (e: any) => {
    return (
      <>
        <button
          className="btn btn-success btn-sm mr-1"
          onClick={() => {
            handleApprove(e.ChemistId, 'Approve');
          }}
        >
          Approve
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleApprove(e.ChemistId, 'Reject')}
          type="button"
        >
          Reject
        </button>
      </>
    )
  };

  let obj = gridData.find((o: any) => o.StatusName === "Pending");

  return (
    <>
      <Helmet>
        <title>Chemist Deleted Approval</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Chemist Deleted Approval</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/approvals"}>Approvals</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Chemist Deleted Approval
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-12"
              label="User"
              name={"UserId"}
              onChange={(e: any) => {
                setselecteduser(e);
                setValue("UserId", e);
              }}
              value={selecteduser}
              api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.UserId?.message}
            />
          </div>
        </form>
      </div>


      {gridData.length !== 0 ? (
        <>
          <div className="pd-20 card-box mb-30 table-responsive">
            <DataTable
              value={gridData}
              dataKey="id"
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={totalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              loading={loading}
              onPage={onPageChange}
              removableSort
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              <Column header="#" field="srNo"></Column>
              <Column
                header="Action"
                body={(e: any) => actionFucntion(e)}
              ></Column>
              <Column
                field="ChemistName"
                bodyClassName={"text-info h6"}
                header="Chemist Name"
              ></Column>
              <Column
                field="ChemistType"
                bodyClassName={"text-info h6"}
                header="Chemist Type"
              ></Column>
              <Column
                field="EmployeeList.EmployeeName"
                bodyClassName={"text-info h6"}
                header="Employee Name"
                body={(e: any) => {
                  return e.EmployeeList?.map((d: any) => {
                    return d.EmployeeName;
                  }).join(", ");
                }}
              ></Column>


            </DataTable>
          </div>
        </>
      ) : Nodata === true ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box text-center">
            <h3 className="text-secondary">No data found.</h3>
          </div>
        </div>
      ) : (
        ""
      )}

    </>
  );
};

export default ApprovalForDeletedChemist;
