import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import moment from "moment";
import { Helmet } from "react-helmet";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import axiosInstance from "../../../_helper/Interceptor";

export const TourPlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state, "location?.state")
  const apiname = process.env.REACT_APP_TOURPLAN_API?.toString();
  const [IsSelf, setIsSelf]: any = useState(false);

  const [byArea, setByarea] = useState(true);

  const { data: Fetchdata, isLoading: loaderFetcher } = useFetchAllID(
    apiname,
    id
  );

  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedRoute, setSelectedRoute]: any = useState(null);
  const [selectedSubRoute, setselectedSubRoute]: any = useState(null);
  const [selectedevent, setselectedevent]: any = useState(null);

  // useEffect(() => {
  //   
  //   if (id) {
  //     // console.log(Fetchdata.data, "view Data");

  //     const data = Fetchdata;

  //     setSelectedRoute(null);
  //     setselectedSubRoute(null);

  //     const date = moment(data?.TourDate).format("YYYY-MM-DD");
  //     setValue("TourDate", date);
  //     

  //     if (data?.nwDayViewModel == null) {
  //       setIsSelf(false);
  //       if (data?.RouteId !== null) {
  //         setByarea(false);
  //         setSelectedRoute({
  //           label: data?.RouteName,
  //           value: data?.RouteId,
  //         });

  //         setselectedSubRoute({
  //           label: data?.SubRouteName,
  //           value: data?.SubRouteId,
  //         });
  //       } else {
  //         setByarea(true);
  //         const cityData = data?.toutPlanAreaMapViewModels?.map((e: any) => {
  //           return {
  //             label: e?.CityName,
  //             value: e?.CityId,
  //           };
  //         });
  //         setValue("CityId", cityData);
  //         setSelectedCity(cityData);
  //       }
  //       const doctorData =
  //         data?.doctorVisitViewModels.length !== 0
  //           ? data?.doctorVisitViewModels?.map((e: any) => {
  //               // append({
  //               //   DoctorId: { label: e?.DoctorName, value: e?.DoctorId },
  //               //   ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //               //     "HH:mm"
  //               //   ),
  //               // });
  //               return {
  //                 DoctorId: { label: e?.DoctorName, value: e?.DoctorId },
  //                 ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //                   "HH:mm"
  //                 ),
  //               };
  //             })
  //           : [];
  //       setValue("DoctorIds", doctorData);

  //       const chemistData =
  //         data?.chemistVisitViewModels.length !== 0
  //           ? data?.chemistVisitViewModels?.map((e: any) => {
  //               // chemistAppend({
  //               //   ChemistId: { label: e?.ChemistName, value: e?.ChemistId },
  //               //   ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //               //     "HH:mm"
  //               //   ),
  //               // });
  //               return {
  //                 ChemistId: { label: e?.ChemistName, value: e?.ChemistId },
  //                 ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //                   "HH:mm"
  //                 ),
  //               };
  //             })
  //           : [];
  //       setValue("ChemistIds", chemistData);

  //       const stockistData =
  //         data?.stockistVisitViewModels.length !== 0
  //           ? data?.stockistVisitViewModels?.map((e: any) => {
  //               // stockistAppend({
  //               //   StockistId: { label: e?.StockistName, value: e?.StockistId },
  //               //   ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //               //     "HH:mm"
  //               //   ),
  //               // });
  //               return {
  //                 StockistId: { label: e?.StockistName, value: e?.StockistId },
  //                 ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //                   "HH:mm"
  //                 ),
  //               };
  //             })
  //           : [];
  //       setValue("StockistIds", stockistData);

  //       const hospitalData =
  //         data?.hospitalVisitViewModels.length !== 0
  //           ? data?.hospitalVisitViewModels?.map((e: any) => {
  //               // hospitalAppend({
  //               //   HospitalId: { label: e?.HospitalName, value: e?.HospitalId },
  //               //   ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //               //     "HH:mm"
  //               //   ),
  //               // });
  //               return {
  //                 HospitalId: { label: e?.HospitalName, value: e?.HospitalId },
  //                 ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
  //                   "HH:mm"
  //                 ),
  //               };
  //             })
  //           : [];
  //       setValue("HospitalIds", hospitalData);
  //     } else {
  //       setIsSelf(true);
  //       setselectedevent({
  //         label: data?.nwDayViewModel?.EventName,
  //         value: data?.nwDayViewModel?.EventId,
  //       });
  //       setValue("OnTransist", data?.nwDayViewModel?.OnTransist);
  //     }
  //   }
  // }, [Fetchdata]);

  const getbyId = () => {
    axiosInstance.get(`v1/Reporting/TourPlan/${id}`).then((e: any) => {
      console.log(e.data.Data);
      const data = e.data.Data;
      setSelectedRoute(null);
      setselectedSubRoute(null);

      const date = moment(data?.TourDate).format("YYYY-MM-DD");
      setValue("TourDate", date);


      if (data?.nwDayViewModel == null) {
        setIsSelf(false);
        if (data?.RouteId !== null) {
          setByarea(false);
          setSelectedRoute({
            label: data?.RouteName,
            value: data?.RouteId,
          });

          setselectedSubRoute({
            label: data?.SubRouteName,
            value: data?.SubRouteId,
          });
        } else {
          setByarea(true);
          const cityData = data?.toutPlanAreaMapViewModels?.map((e: any) => {
            return {
              label: e?.CityName,
              value: e?.CityId,
            };
          });
          setValue("CityId", cityData);
          setSelectedCity(cityData);
        }

        if (data?.doctorVisitViewModels !== undefined && data?.doctorVisitViewModels !== null) {
          if (data?.doctorVisitViewModels?.length !== 0) {
            const doctorData = data?.doctorVisitViewModels?.map((e: any) => {
              append({
                DoctorId: { label: e?.DoctorName, value: e?.DoctorId },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                  "HH:mm"
                ),
              });
              return {
                DoctorId: { label: e?.DoctorName, value: e?.DoctorId },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                  "HH:mm"
                ),
              };
            })
            setValue("DoctorIds", doctorData);
            setValue("Doctor", doctorData);
          }
          else {
            setValue("DoctorIds", []);
            setValue("Doctor", null);
          }

        }
        else {

          setValue("DoctorIds", []);
          setValue("Doctor", null);
        }

        if (data?.chemistVisitViewModels !== undefined && data?.chemistVisitViewModels !== null) {
          if (data?.chemistVisitViewModels?.length !== 0) {
            const chemistData = data?.chemistVisitViewModels?.map((e: any) => {
              chemistAppend({
                ChemistId: {
                  label: e?.ChemistName,
                  value: {
                    label: e?.ChemistName,
                    value: e?.ChemistId
                  }
                },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                  "HH:mm"
                ),
              });
              return {
                ChemistId: {
                  label: e?.ChemistName, value: {
                    label: e?.ChemistName,
                    value: e?.ChemistId
                  }
                },
                ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                  "HH:mm"
                ),
              };
            })
            setValue("ChemistIds", chemistData);
            setValue("Chemist", chemistData);
          }
          else {
            setValue("ChemistIds", []);
            setValue("Chemist", null);
          }

        }
        else {
          setValue("ChemistIds", []);
          setValue("Chemist", null);
        }

        if (data?.stockistVisitViewModels !== undefined && data?.stockistVisitViewModels !== null) {
          if (data?.stockistVisitViewModels?.length !== 0) {
            const stockistData =
              data?.stockistVisitViewModels?.map((e: any) => {
                stockistAppend({
                  StockistId: { label: e?.StockistName, value: e?.StockistId },
                  ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                    "HH:mm"
                  ),
                });
                return {
                  StockistId: { label: e?.StockistName, value: e?.StockistId },
                  ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                    "HH:mm"
                  ),
                };
              })
            setValue("StockistIds", stockistData);
            setValue("Stockist", stockistData);
          }
          else {
            setValue("StockistIds", []);
            setValue("Stockist", null);
          }

        }
        else {
          setValue("StockistIds", []);
          setValue("Stockist", null);
        }

        if (data?.hospitalVisitViewModels !== undefined && data?.hospitalVisitViewModels !== null) {

          if (data?.hospitalVisitViewModels?.length !== 0) {
            const hospitalData =
              data?.hospitalVisitViewModels?.map((e: any) => {
                hospitalAppend({
                  HospitalId: { label: e?.HospitalName, value: e?.HospitalId },
                  ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                    "HH:mm"
                  ),
                });
                return {
                  HospitalId: { label: e?.HospitalName, value: e?.HospitalId },
                  ExpectedVisitTime: moment(e?.ExpectedVisitTime).format(
                    "HH:mm"
                  ),
                };
              })

            setValue("HospitalIds", hospitalData);
            setValue("Hospital", hospitalData);
          }
          else {
            setValue("HospitalIds", []);
            setValue("Hospital", null);
          }

        }
        else {
          setValue("HospitalIds", []);
          setValue("Hospital", null);
        }

      } else {
        setIsSelf(true);
        setselectedevent({
          label: data?.nwDayViewModel?.EventName,
          value: data?.nwDayViewModel?.EventId,
        });
        setValue("OnTransist", data?.nwDayViewModel?.OnTransist);
      }
    });
  };

  useEffect(() => {
    if (id) {
      getbyId();
    }
  }, [id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
    watch,
    clearErrors
  }: any = useForm({
    mode: "all",
  });
  const transist: any = watch("OnTransist");

  const dat = watch();
  console.log(dat, "this is form");

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "DoctorIds", // Name of your array field
  });

  const {
    fields: chemistField,
    append: chemistAppend,
    remove: chemistRemove,
  } = useFieldArray({
    control,
    name: "ChemistIds", // Name of your array field
  });

  const {
    fields: stockistField,
    append: stockistAppend,
    remove: stockistRemove,
  } = useFieldArray({
    control,
    name: "StockistIds", // Name of your array field
  });

  const {
    fields: hospitalField,
    append: hospitalAppend,
    remove: hospitalRemove,
  } = useFieldArray({
    control,
    name: "HospitalIds", // Name of your array field
  });

  const onSubmit = (data: any) => {
    debugger
    console.log(typeof location?.state, "this is type of")
    if (id !== undefined) {
      data.TourDates = moment(Fetchdata?.data?.TourDate).format("YYYY-MM-DD");
      data.TourPlanId = Number(id);
    } else {
      if (typeof location?.state !== "object") {
        data.TourDates = [location?.state];
      } else {
        data.TourDates = location?.state;
      }
    }

    if (IsSelf) {
      data.IsPlan = false;
    } else {
      data.IsPlan = true;
    }

    if (!IsSelf) {
      if (byArea) {
        delete data.RouteId;
        data.CityId = selectedCity.map((e: any) => {
          return e?.value;
        });
      } else {
        delete data.CityId;
        data.RouteId = selectedRoute?.value;
        data.SubRouteId = String(selectedSubRoute?.value);
      }

      if (data.DoctorIds.length !== 0) {
        data.DoctorIds = data?.DoctorIds?.map((e: any) => {
          return {
            DoctorId:
              typeof e.DoctorId?.value === "object"
                ? e.DoctorId?.value?.value
                : e?.DoctorId?.value,
            ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
          };
        });
      } else {
        delete data.DoctorIds;
      }

      if (data.ChemistIds.length !== 0) {
        data.ChemistIds = data?.ChemistIds?.map((e: any) => {
          return {
            ChemistId: e.ChemistId.value.value,
            ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
          };
        });
      } else {
        delete data.ChemistIds;
      }

      if (data.StockistIds.length !== 0) {
        data.StockistIds = data?.StockistIds?.map((e: any) => {
          return {
            StockistId: e.StockistId.value,
            ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
          };
        });
      } else {
        delete data.StockistIds;
      }

      if (data.HospitalIds.length !== 0) {
        data.HospitalIds = data?.HospitalIds?.map((e: any) => {
          return {
            HospitalId: e.HospitalId.value,
            ExpectedVisitTime: moment(e.ExpectedVisitTime, "HH:mm").format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
          };
        });
      } else {
        delete data.HospitalIds;
      }
    } else {
      data.EventId = selectedevent?.value;
      data.OnTransist = transist;

      if (transist == true) {
        delete data.EventId;
      }
    }

    console.log(data, "data --->");

    if (data.IsPlan == false) {
      delete data.RouteId;
      delete data.SubRouteId;
      delete data.CityId;
      delete data.DoctorIds;
      delete data.ChemistIds;
      delete data.HospitalIds;
      delete data.StockistIds;
    } else {
      delete data.OnTransist;
      delete data.EventId;
    }
    mutate(data);
  };

  const [enotify, setEnotify] = useState(false);
  const [color, setColor] = useState("");
  useEffect(() => {
    if (id) {
      if (isSuccess) {
        toast.success("Update Successfully", { position: "top-right" });
        navigate("/layout/mr/viewTourPlan", {
          state: moment(Fetchdata?.data?.TourDate).format("yyyy-MM-DD"),
        });
      }
    } else {
      if (isSuccess) {
        toast.success("Update Successfully", { position: "top-right" });
        navigate("/layout/mr/calender");
      }
    }
    if (isError) {
      setColor("danger");
      setEnotify(true);
      setTimeout(() => {
        setEnotify(false);
      }, 3000);
    }
    if (isError) {
      if (
        error?.response?.data?.StatusCode !== 400 &&
        error?.response?.data?.Message ==
        "You can not change past date tour plan"
      ) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Helmet>
        <title>Tour Plan</title>
      </Helmet>
      {loaderFetcher ? (
        <div className="card-box">
          <div className="col-md-12 text-center align-item-center">
            <Spinner width={"30rem"} height={"30rem"} />
          </div>
        </div>
      ) : (
        <>
          <div className="page-header">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="title">
                  <h4>
                    Tour Plan -{" "}
                    <span className="text-secondary">
                      {id == undefined
                        ? location?.state == null
                          ? ""
                          : typeof location?.state == "string"
                            ? moment(location?.state).format("DD MMM. YYYY")
                            : `${moment(location?.state[0]).format(
                              "DD MMM. YYYY"
                            )} to ${moment(
                              location?.state[location?.state?.length - 1]
                            ).format("DD MMM. YYYY")}`
                        : moment(Fetchdata?.TourDate?.split("T")[0]).format(
                          "DD MMM. YYYY"
                        )}
                    </span>
                  </h4>
                </div>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={"/layout/dashboard"}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Tour Plan
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="pd-20 card-box mb-30">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="custom-control custom-checkbox pt-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      {...register("IsPlan")}
                      id="IsPlan"
                      onClick={() => {
                        if (IsSelf) {
                          setIsSelf(false);
                        } else {
                          setIsSelf(true);
                        }
                      }}
                      checked={IsSelf}
                    />
                    <label className="custom-control-label" htmlFor="IsPlan">
                      NW Days
                    </label>
                  </div>
                </div>

                {!IsSelf ? (
                  <>
                    <div className="col-md-6">
                      <div className="custom-control custom-radio mb-5">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          className="custom-control-input"
                          checked={byArea}
                          // defaultChecked={Fetchdata?.RouteId == null ? true : false}
                          onChange={() => {
                            setByarea(true);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio1"
                        >
                          By Area
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-radio mb-5">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          className="custom-control-input"
                          checked={!byArea}
                          // defaultChecked={Fetchdata?.RouteId != null ? true : false}
                          onChange={() => {
                            setByarea(false);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio2"
                        >
                          By Route
                        </label>
                      </div>
                    </div>
                    {byArea ? (
                      <ReactSelect
                        containerClass="col-lg-12"
                        control={control}
                        label={"City"}
                        Multi
                        onChange={(e: any) => {
                          setValue("CityId", e);
                          setSelectedCity(e);
                        }}
                        value={selectedCity}
                        api={process.env.REACT_APP_GETMRCITY_API}
                        keys={{ label: "Value", value: "Id" }}
                        name={"CityId"}
                        errorMessage={errors?.CityId?.message}
                      />
                    ) : (
                      <>
                        <ReactSelect
                          isRequired
                          containerClass="col-lg-12"
                          control={control}
                          label={"Route"}
                          onChange={(e: any) => {
                            setValue("RouteId", e);
                            setSelectedRoute(e);
                          }}
                          value={selectedRoute}
                          api={process.env.REACT_APP_SRC_API}
                          keys={{ label: "Value", value: "Id" }}
                          name={"RouteId"}
                          rules={{
                            required: { value: true, message: "Route is required" },
                          }}
                          errorMessage={errors?.RouteId?.message}
                        />

                        <ReactSelect
                          containerClass="col-lg-12"
                          control={control}
                          label={"Sub Route"}
                          onChange={(e: any) => {
                            setValue("SubRouteId", e);
                            setselectedSubRoute(e);
                          }}
                          value={selectedSubRoute}
                          api={
                            selectedRoute !== null &&
                              selectedRoute.value !== undefined
                              ? `Reporting/Src/GetSrcSubRouteBySrcRouteId/${selectedRoute.value}`
                              : ""
                          }
                          keys={{ label: "Value", value: "Id" }}
                          Disabled={selectedRoute !== null ? false : true}
                          name={"SubRouteId"}
                          errorMessage={errors?.SubRouteId?.message}
                        />
                      </>
                    )}
                    <div className="col-lg-3">
                      <div className="col-md-12 d-flex align-items-center">
                        <div className="col-md-6 text-left pl-0">
                          <h6>Doctor</h6>
                        </div>
                        <div className="col-md-6 text-right ">
                          <button
                            type="button"
                            onClick={() => {
                              append({
                                DoctorId: "",
                                ExpectedVisitTime: "",
                              });
                            }}
                            className="btn btn-sm btn-outline-primary "
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      {fields?.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="row">
                              <ReactSelect

                                isRequired
                                label="Doctor"
                                containerClass="col-lg-12"
                                name={`DoctorIds.${index}.DoctorId`}
                                value={getValues(`DoctorIds.${index}.DoctorId`)}
                                onChange={(e: any) => {
                                  setValue(`DoctorIds.${index}.DoctorId`, e);
                                  setValue("Doctor", e);
                                  clearErrors([`DoctorIds.${index}.DoctorId`])
                                }}
                                control={control}
                                api={process.env.REACT_APP_DOCTORDETAIL_API}
                                keys={{ label: "Value", value: "Id" }}
                                errorMessage={
                                  errors?.["DoctorIds"]?.[index]?.["DoctorId"]
                                    ?.message
                                }
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Doctor  is required",
                                  },
                                }}
                              />

                              <Input
                                isRequired
                                containerClass="col-lg-12"
                                label="Expected Visit Time"
                                type="time"
                                errorMessage={
                                  errors?.["DoctorIds"]?.[index]?.[
                                    "ExpectedVisitTime"
                                  ]?.message
                                }
                                onChange={(e: any) => {
                                  console.log(e.target.value, "dateTime");
                                  setValue(
                                    `DoctorIds.${index}.ExpectedVisitTime`,
                                    e.target.value
                                  );
                                }}
                                // register={{
                                //   ...register(
                                //     `DoctorIds.${index}.ExpectedVisitTime`
                                //   ),
                                // }}
                                register={{
                                  ...register(
                                    `DoctorIds.${index}.ExpectedVisitTime`,
                                    {
                                      required: {
                                        value: true,
                                        message:
                                          "Expected Visit Time Is Required ",
                                      },
                                    }
                                  ),
                                }}
                              />
                              <div className="col-lg-12 text-center ">
                                <button
                                  type="button"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                  className="btn btn-sm btn-outline-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="col-md-3">
                      <div className="col-md-12 d-flex align-items-center">
                        <div className="col-md-6 text-left pl-0">
                          <h6>Chemist</h6>
                        </div>
                        <div className="col-md-6 text-right ">
                          <button
                            type="button"
                            onClick={() => {
                              chemistAppend({
                                ChemistId: "",
                                ExpectedVisitTime: "",
                              });
                            }}
                            className="btn btn-sm btn-outline-primary "
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      {chemistField.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="row">
                              <ReactSelect
                                register={{
                                  ...register(`ChemistIds.${index}.ChemistId`, {
                                    required: {
                                      value: true,
                                      message: "Chemist  is required",
                                    },
                                  }),
                                }}
                                isRequired
                                label="Chemist"
                                containerClass="col-lg-12"
                                control={control}
                                onChange={(e: any) => {
                                  setValue(`ChemistIds.${index}.ChemistId`, e);
                                  setValue("Chemist", e);
                                  clearErrors([`ChemistIds.${index}.ChemistId`])
                                }}
                                value={getValues(
                                  `ChemistIds.${index}.ChemistId`
                                )}
                                api={process.env.REACT_APP_CHEMIST_API}
                                keys={{ label: "Value", value: "Id" }}
                                name="Chemist"
                                errorMessage={
                                  errors?.["ChemistIds"]?.[index]?.["ChemistId"]
                                    ?.message
                                }
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Chemist  is required",
                                  },
                                }}
                              />


                              <Input
                                containerClass="col-lg-12"
                                label="Expected Visit Time"
                                type="time"
                                onChange={(e: any) => {
                                  setValue(
                                    `ChemistIds.${index}.ExpectedVisitTime`,
                                    e.target.value
                                  );
                                }}
                                errorMessage={
                                  errors?.["ChemistIds"]?.[index]?.[
                                    "ExpectedVisitTime"
                                  ]?.message
                                }
                                register={{
                                  ...register(
                                    `ChemistIds.${index}.ExpectedVisitTime`,
                                    {
                                      required: {
                                        value: true,
                                        message:
                                          " Expected Visit Time Is Required ",
                                      },
                                    }
                                  ),
                                }}
                              />
                              <div className="col-lg-12 text-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    chemistRemove(index);
                                  }}
                                  className="btn btn-sm btn-outline-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="col-md-3">
                      <div className="col-md-12 d-flex align-items-center">
                        <div className="col-md-6 text-left pl-0">
                          <h6>Stockist</h6>
                        </div>
                        <div className="col-md-6 text-right ">
                          <button
                            type="button"
                            onClick={() => {
                              stockistAppend({
                                StockistId: "",
                                ExpectedVisitTime: "",
                              });
                            }}
                            className="btn btn-sm btn-outline-primary "
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      {stockistField?.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="row">
                              <ReactSelect
                                register={{
                                  ...register(`StockistIds.${index}.StockistId`, {
                                    required: {
                                      value: true,
                                      message: "Stockist  is required",
                                    },
                                  }),
                                }}
                                label="Stockist"
                                containerClass="col-lg-12"
                                control={control}
                                onChange={(e: any) => {
                                  setValue(
                                    `StockistIds.${index}.StockistId`,
                                    e
                                  );
                                  setValue("Stockist", e);
                                  clearErrors([`StockistIds.${index}.StockistId`])
                                }}
                                value={getValues(
                                  `StockistIds.${index}.StockistId`
                                )}
                                api={process.env.REACT_APP_STOCKIST_API}
                                keys={{ label: "Value", value: "Id" }}
                                name="Stockist"
                                errorMessage={
                                  errors?.["StockistIds"]?.[index]?.["StockistId"]
                                    ?.message
                                }
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Stockist  is required",
                                  },
                                }}
                              />

                              <Input
                                containerClass="col-lg-12"
                                label="Expected Visit Time"
                                type="time"
                                onChange={(e: any) => {
                                  setValue(
                                    `StockistIds.${index}.ExpectedVisitTime`,
                                    e.target.value
                                  );

                                }}
                                errorMessage={
                                  errors?.["StockistIds"]?.[index]?.[
                                    "ExpectedVisitTime"
                                  ]?.message
                                }
                                register={{
                                  ...register(
                                    `StockistIds.${index}.ExpectedVisitTime`,
                                    {
                                      required: {
                                        value: true,
                                        message:
                                          " Expected Visit Time Is Required ",
                                      },
                                    }
                                  ),
                                }}
                              />
                              <div className="col-lg-12 text-center ">
                                <button
                                  type="button"
                                  onClick={() => {
                                    stockistRemove(index);
                                  }}
                                  className="btn btn-sm btn-outline-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="col-md-3">
                      <div className="col-md-12 d-flex align-items-center">
                        <div className="col-md-6 text-left pl-0">
                          <h6>Hospital</h6>
                        </div>
                        <div className="col-md-6 text-right ">
                          <button
                            type="button"
                            onClick={() => {
                              hospitalAppend({
                                HospitalId: "",
                                ExpectedVisitTime: "",
                              });
                            }}
                            className="btn btn-sm btn-outline-primary "
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      {hospitalField.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="row">
                              <ReactSelect
                                isRequired
                                register={{
                                  ...register(`HospitalIds.${index}.HospitalId`, {
                                    required: {
                                      value: true,
                                      message: "Hospital  is required",
                                    },
                                  }),
                                }}
                                label="Hospital"
                                containerClass="col-lg-12"
                                control={control}
                                onChange={(e: any) => {
                                  setValue(
                                    `HospitalIds.${index}.HospitalId`,
                                    e
                                  );
                                  setValue("Hospital", e);
                                  clearErrors([`HospitalIds.${index}.HospitalId`])
                                }}
                                value={getValues(
                                  `HospitalIds.${index}.HospitalId`
                                )}
                                api={process.env.REACT_APP_HOSPITAL_API}
                                keys={{ label: "Value", value: "Id" }}
                                name="Hospital"
                                errorMessage={
                                  errors?.["HospitalIds"]?.[index]?.["HospitalId"]
                                    ?.message
                                }
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Hospital  is required",
                                  },
                                }}
                              />

                              <Input
                                containerClass="col-lg-12"
                                label="Expected Visit Time"
                                type="time"
                                onChange={(e: any) => {
                                  setValue(
                                    `HospitalIds.${index}.ExpectedVisitTime`,
                                    e.target.value
                                  );

                                }}
                                errorMessage={
                                  errors?.["HospitalIds"]?.[index]?.[
                                    "ExpectedVisitTime"
                                  ]?.message
                                }
                                register={{
                                  ...register(
                                    `HospitalIds.${index}.ExpectedVisitTime`,
                                    {
                                      required: {
                                        value: true,
                                        message:
                                          "Expected Visit Time Is Required ",
                                      },
                                    }
                                  ),
                                }}
                              />
                              <div className="col-lg-12 text-center">
                                <button
                                  type="button"
                                  onClick={() => {
                                    hospitalRemove(index);
                                  }}
                                  className="btn btn-sm btn-outline-danger"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custom-control custom-checkbox pt-2">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              {...register("OnTransist")}
                              id="OnTransist"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="OnTransist"
                            >
                              On Transist
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ReactSelect
                      containerClass="col-lg-12"
                      label={"Event"}
                      isRequired={!transist}
                      name={"EventId"}
                      onChange={(e: any) => {
                        setselectedevent(e);
                        setValue("EventId", e?.value);
                      }}
                      value={selectedevent}
                      control={control}
                      api={process.env.REACT_APP_EVENT_API}
                      keys={{ label: "Value", value: "Id" }}
                      Disabled={transist ? true : false}
                      errorMessage={
                        errors?.EventId?.message ||
                        error?.response?.data?.errors?.EventId?.map(
                          (e: any) => e
                        )
                      }
                      rules={{
                        required: {
                          value: !transist,
                          message: "Event is required",
                        },
                      }}
                    />
                  </>
                )}

                <div className="col-md-12 text-center mt-5">
                  <Link
                    to="/layout/mr/calender"
                    className="btn btn-sm btn-secondary mr-1"
                  >
                    Back
                  </Link>
                  <button type="submit" className="btn btn-primary btn-sm">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
