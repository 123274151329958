import React from "react";
import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditPatient } from "./AddEditPatient";
import DatatableCommon from "../../../common/DatatableCommon";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";
import moment from 'moment'
import { Badge } from "primereact/badge";

export const Patient = () => {
  const apiname = process.env.REACT_APP_PATIENT_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionPatientTemplate(e),
      width: "2vw",
    },
    {
      field: "Name",
      header: "Name",
      sortable: true,
      bodyClassName: "text-info h6",
      filter: true,
      filterPlaceholder: "Search by Name",
      body: null,
      width: "10vw",
    },
    {
      field: "Gender",
      header: "Gender",
      sortable: true,
      body: null,
      width: "10vw",
    },
    {
      field: "DateOfBirth",
      header: "DateOfBirth",
      body: (e: any) => {

        // NullHandler(e.DateOfBirth == null ? "" : moment(e.DateOfBirth).format("DD MMM, YYYY"))
        if (e.DateOfBirth === null) {
          return "-";
        } else {
          return (
            <>
              <Badge
                className="m-1"
                severity={"success"}
                value={moment(e.DateOfBirth).format("DD MMM, YYYY")}
              ></Badge>

            </>
          );
        }



      },
      sortable: true,
      width: "10vw",
      dataType: "date"
    },
    {
      field: "Mobile",
      header: "Mobile",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Mobile);
      },
      width: "10vw",
    },
    {
      field: "Email",
      header: "Email",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Email);
      },
      width: "10vw",
    },
    {
      field: "BloodGroup",
      header: "BloodGroup",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.BloodGroup);
      },
      width: "10vw",
    },
    {
      field: "Address",
      header: "Address",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Address);
      },
      width: "10vw",
    },

  ];

  const actionBirthDateTemplate = (id: any) => {
    return id?.DateOfBirth?.split("T")[0];
  };

  const [updateId, setupdateId]: any = useState(null);

  const actionPatientTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Patient</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Patient"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          filterJson={filterJson}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditPatient
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
