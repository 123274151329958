import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";

const LateDCRSubmissionReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset
  }: any = useForm();

  const role = localStorage.getItem("Roles");

  const [selectedEmployee, setSelectedEmployee]: any = useState([]);
  const [loading, setLoading] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };


  const handle = async (data: any) => {
    const employee = data.EmployeeIds?.map((e: any) => `EmployeeIds=${e?.value}`);
    setLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL
        }${process.env.REACT_APP_LATEDCRSUBMISSIONREPORT_API}?StartDate=${startdate}&EndDate=${defaultDate
        }${employee == undefined
          ? ""
          : `&${String(employee)?.replaceAll(",", "&")}`
        }`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "LateDCRSubmissionReport.xlsx");
        setLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
        setSelectedEmployee([]);
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setLoading(false);
      });
  };

  // date
  const [startdate, setDate] = useState('');

  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split('T')[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split('T')[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  return (
    <div>
      <Helmet>
        <title>Late DCR Submission Report</title>
      </Helmet>
      <div className="col-md-12 col-sm-12 mb-30">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Late DCR Submission Report</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/layout/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/layout/reports">Reports</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Late DCR Submission Report
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box">
          <form
            onSubmit={handleSubmit((data: any) => {
              handle(data);
            })}
          >
            <div className="row">
              <Input
                containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
                label="Start Date"
                type="date"
                isRequired
                errorMessage={errors?.StartDate?.message}
                // register={{
                //   ...register("StartDate", {
                //     required: {
                //       value: true,
                //       message: "Start Date is required",
                //     },
                //   }),
                // }}
                value={startdate}
                onChange={handleDateChange}
              />

              <Input
                containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
                label="End Date"
                type="date"
                isRequired
                errorMessage={errors?.EndDate?.message}
                value={defaultDate}
                onChange={handleEndDateChange}
              // register={{
              //   ...register("EndDate", {
              //     required: {
              //       value: true,
              //       message: "End Date is required",
              //     },
              //   }),
              // }}
              />

              {role !== "MR" && (
                <ReactSelect
                  containerClass="col-lg-4"
                  control={control}
                  api={process.env.REACT_APP_EMPLOYEE_API}
                  keys={{ label: "Value", value: "Id" }}
                  onChange={(e: any) => {
                    setValue("EmployeeIds", e);
                    setSelectedEmployee(e);
                  }}
                  value={selectedEmployee}
                  name="EmployeeIds"
                  label="Employee Name"
                  Multi
                />
              )}
            </div>
            <div className="col-lg-12 text-center mt-3">
              {!loading ? (
                <button className="btn btn-primary" type="submit">
                  Download
                </button>
              ) : (
                <button className="btn btn-primary" type="submit" disabled>
                  Download <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LateDCRSubmissionReport;
