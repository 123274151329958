import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";
import moment from "moment";

const DoctorVisitFrequencyReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const [isLoading, setIsLoading] = useState(false);

  const [selectedEmployee, setSelectedEmployee]: any = useState(null);

  let role = localStorage.getItem("Roles");

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownload = async (data: any) => {
    debugger
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORVISITDOWNLOADVISITFREQUENCY_API
        }?date=${startmonth}-01${data.employeeId !== undefined ? `&employeeId=${data.employeeId}` : ""
        }`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "DoctorVisitFrequencyReport.xlsx");
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const [startmonth, setstartmonth] = useState('');

  useEffect(() => {
    const today = new Date();
    const currentMonth = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`;
    setstartmonth(currentMonth);
  }, []);

  const handleDateChange = (event: any) => {
    setstartmonth(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Doctor Visit Frequency Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Doctor Visit Frequency Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Doctor Visit Frequency Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <Input
              containerClass={role === "MR" ? "col-lg-12" : "col-lg-6"}
              label="Month"
              type="month"
              isRequired
              errorMessage={errors?.date?.message}
              onChange={(e: any) => { clearErrors(["date"]); handleDateChange(e) }}
              value={startmonth}
            // register={{
            //   ...register("date", {
            //     required: {
            //       value: true,
            //       message: "Month is required",
            //     },
            //   }),
            // }}
            />

            {role !== "MR" && (
              <ReactSelect
                containerClass="col-lg-6"
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                onChange={(e: any) => {
                  setValue("employeeId", e?.value);
                  setSelectedEmployee(e);
                }}
                value={selectedEmployee}
                name="employeeId"
                label="Employee Name"
              />
            )}
          </div>

          <div className="col-md-12 text-center mt-30">
            {!isLoading ? (
              <button className="btn btn-outline-primary" type="submit">
                Download
              </button>
            ) : (
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled
              >
                Download <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default DoctorVisitFrequencyReport;
