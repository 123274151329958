import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchDoctorID } from "../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import cameraLogo from "../../assets/images/Camera.png";
import { Spinner } from "../../common/Spinner";
import { ErrorMessage } from "../../common/ErrorMessage";
import { Address } from "../../common/Address";
import { InputMask } from "primereact/inputmask";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  parameter?: boolean;
}

export const AddEditDoctor = ({
  id,
  setisOPen,
  open,
  fetch,
  parameter = false,
}: iPropsmodal) => {
  const controller = new AbortController();
  const apiname = process.env.REACT_APP_DOCTORDETAIL_API?.toString();

  const [PancardImage, setpanCardImage]: any = useState(null);
  const [RegistrationCertificate, setregistrationImage]: any = useState(null);
  const [DoctorImage, setDoctorImage]: any = useState(null);
  const [PancardImageDisplay, setpanCardImageDisplay]: any = useState(cameraLogo);
  const [RegistrationCertificateDisplay, setregistrationImageDisplay]: any = useState(cameraLogo);
  const [DoctorImageDisplay, setDoctorImageDisplay]: any = useState(cameraLogo);
  const [doctorChanged, setDoctorChanged]: any = useState(false);
  const [pancardChanged, setpancardChanged]: any = useState(false);
  const [registerChanged, setregisterChanged]: any = useState(false);
  const [visitingCardImageDisplay, setVisitingCardImageDisplay]: any = useState(cameraLogo);
  const [visitingCardImage, setVisitingCardImage]: any = useState(null);
  const [visitingCardChanged, setVisitingCardChanged]: any = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({
    mode: "all",
  });
  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = cameraLogo;
  };
  const Hcity = watch("HospitalCityId");
  const Rcity = watch("ResidencialCityId");

  const dailyvalue = useRef({});
  dailyvalue.current = watch("PatientsDaily", "");

  const weeklyvalue = useRef({});
  weeklyvalue.current = watch("PatientsWeekly", "");

  let { data, isLoading }: any = useFetchDoctorID(
    apiname,
    id === 0 ? undefined : id,
    controller.signal
  );

  const Speciality = watch("SpecialityList");

  useEffect(() => {
    if (id) {
      if (data) {

        if (doctorChanged == false && (selectedcategory !== null || DoctorImageDisplay === cameraLogo)) {

          setDoctorImageDisplay(data.DoctorImagePath);
        }

        if (pancardChanged == false && (selectedcategory !== null || PancardImageDisplay === cameraLogo)) {
          setpanCardImageDisplay(data.PancardImagePath);
        }
        if (registerChanged == false && (selectedcategory !== null || RegistrationCertificateDisplay === cameraLogo)) {
          setregistrationImageDisplay(data.RegistrationCertificatePath);
        }
        if (visitingCardChanged == false && (selectedcategory !== null || visitingCardImageDisplay === cameraLogo)) {
          setVisitingCardImageDisplay(data.VisitingCardImagePath);
        }
        if (data.HospitalRoleTypeId == null || data.HospitalRoleTypeId == undefined) {
          delete data.HospitalRoleTypeId;
          delete data.HospitalRoleName;
          setselectedrole(null);
        }
        const dateUpdate = data?.DateOfBirth?.split("T")[0];
        const dataA = data?.DateOfAnniversary?.split("T")[0];
        // let newData: any = {};
        // for (let key in data) {
        //   if (key.trim() === "PancardImage") {
        //   } else if (key.trim() === "RegistrationCertificate") {
        //   } else {
        //     newData[`${key}`] = data[key];
        //   }
        // }
        // reset(newData);

        reset(data);
        setValue("DateOfBirth", dateUpdate);
        setValue("DateOfAnniversary", dataA);

        if (data.ResidencialCityId !== null) {
          setValue("ResidencialCityId", {
            label: data.ResidencialCity,
            value: data.ResidencialCityId,
          });
          setselectedRcity({
            label: data.ResidencialCity,
            value: data.ResidencialCityId,
          });
        } else {
          setValue("ResidencialCityId", null);
          setselectedRcity(null);
        }
        if (data.ResidencialPincodeId !== null) {
          setValue("ResidencialPincodeId", {
            label: data.ResidencialPincode,
            value: data.ResidencialPincodeId,
          });
          setselectedRpincode({
            label: data.ResidencialPincode,
            value: data.ResidencialPincodeId,
          });
        } else {
          setValue("ResidencialPincodeId", null);
          setselectedRpincode(null);
        }

        if (data.HospitalCityId !== null) {
          setValue("HospitalCityId", {
            label: data.HospitalCity,
            value: data.HospitalCityId,
          });
          setselectedhcity({
            label: data.HospitalCity,
            value: data.HospitalCityId,
          });
        } else {
          setValue("HospitalCityId", null);
          setselectedhcity(null);
        }
        if (data.HospitalPincodeId !== null) {
          setValue("HospitalPincodeId", {
            label: data.HospitalPincode,
            value: data.HospitalPincodeId,
          });
          setselectedhpincode({
            label: data.HospitalPincode,
            value: data.HospitalPincodeId,
          });
        } else {
          setValue("HospitalPincodeId", null);
          setselectedhpincode(null);
        }

        setselectedcategory({
          label: data.CategoryName,
          value: data.CategoryId,
        });
        if (data.HospitalRoleTypeId !== null && data.HospitalRoleTypeId !== undefined && data.HospitalRoleName !== null && data.HospitalRoleName !== undefined) {

          setselectedrole({
            label: data.HospitalRoleName,
            value: data.HospitalRoleTypeId,
          });

        }
        let SpecialityList = data?.SpecialityList?.map((d: any) => ({
          label: d.SpecialityName,
          value: d.SpecialityId,
        }));
        setValue("SpecialityList", SpecialityList);
        setselectedspeciality(SpecialityList);

        let QualificationList = data?.QualificationList?.map((d: any) => ({
          label: d.QualificationName,
          value: d.QualificationId,
        }));
        setValue("QualificationList", QualificationList);
        setselectedqualification(QualificationList);

        let EmployeeList = data?.EmployeeList?.map((d: any) => ({
          label: d.EmployeeName,
          value: d.EmployeeId,
        }));
        setValue("EmployeeIds", EmployeeList);
        setselectedemployee(EmployeeList);

        let ProductList = data?.ProductList?.map((d: any) => ({
          label: d.ProductName,
          value: d.ProductId,
        }));
        setValue("ProductIds", ProductList);
        setselectedproduct(ProductList);
      }
    }
    return () => {
      controller.abort();
    }
  }, [data, id]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: doctorLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedRcity, setselectedRcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);
  const [selectedRpincode, setselectedRpincode]: any = useState(null);

  const [selectedcategory, setselectedcategory]: any = useState(null);

  const [selectedrole, setselectedrole]: any = useState(null);

  const [selectedspeciality, setselectedspeciality]: any = useState([]);
  const [selectedqualification, setselectedqualification]: any = useState([]);

  const [selectedemployee, setselectedemployee]: any = useState([]);
  const [selectedproduct, setselectedproduct]: any = useState([]);

  const doctorFormData = async (form: any) => {
    debugger
    console.log(form, "form Data");
    data.Mobile = data.Mobile?.replace(/[^\d]/g, "");

    form.IsUnlinked = parameter;

    form.HospitalCityId = selectedhcity?.value;
    form.ResidencialCityId = selectedRcity?.value;
    form.HospitalPincodeId = selectedhpincode?.value;
    form.ResidencialPincodeId = selectedRpincode?.value;

    if (PancardImage !== null) {
      form.PancardImage = PancardImage[0];
    }
    if (RegistrationCertificate !== null) {
      form.RegistrationCertificate = RegistrationCertificate[0];
    }
    if (DoctorImage !== null) {
      if (typeof DoctorImage == "object") {

        form.DoctorImage = DoctorImage[0];
      }
      else {
        form.DoctorImage = DoctorImage;
      }
    }
    if (visitingCardImage !== null) {
      if (typeof visitingCardImage == "object") {

        form.VisitingCardImage = visitingCardImage[0];
      }
      else {
        form.VisitingCardImage = visitingCardImage;
      }
    }

    if (id != null) {
      delete form.EmployeeList;
    }

    for (let prop in form) {
      if (
        form[prop] === null ||
        form[prop] === undefined ||
        form[prop] === "" ||
        form[prop]?.length == 0
      ) {
        delete form[prop];
      }
    }

    let newData: any = {};
    Object.keys(form).forEach(
      (k) =>
        (newData[k] = typeof form[k] == "string" ? form[k].trim() : form[k])
    );

    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        if (key !== "DoctorImage" && key !== "RegistrationCertificate" && key !== "PancardImage" && key !== "VisitingCardImage") {
          newData[key].map((e: any, i: number) => {
            if (typeof e?.value !== "object") {
              formData.append(`${key}[${i}]`, e?.value)
            } else {
              formData.append(`${key}[${i}]`, e?.value?.value)
            }
          })
        }
        else {
          formData.append(key, formvalue);
        }

      }
      //       else if (key == "SpecialityList") {
      //   newData.SpecialityList.map((e: any, index: any) => {
      //     formData.append("SpecialityList[" + index + "]", e?.value);
      //   });
      // } else if (key == "QualificationList") {
      //   newData.QualificationList.map((e: any, index: any) => {
      //     formData.append("QualificationList[" + index + "]", e?.value);
      //   });
      // } else if (key == "ProductIds") {
      //   newData.ProductIds.map((e: any, index: any) => {

      //     if (typeof e?.value == "object") {

      //       formData.append("ProductIds[" + index + "]", e?.value?.value);
      //     }
      //     else {

      //       formData.append("ProductIds[" + index + "]", e?.value);
      //     }
      //   });
      // } else if (key == "EmployeeIds") {
      //   newData.EmployeeIds.map((e: any, index: any) => {
      //     formData.append("EmployeeIds[" + index + "]", e?.value);
      //   });
      // } 
      // else {
      //   formData.append(key, formvalue);
      // }
    }
    mutate(formData);

  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        reset();
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        reset();
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Doctor`}
        modal
        style={{ width: "1600px" }}
      >
        {!isLoading ? (
          <div className="pd-20 card-box mb-30">
            <form
              onSubmit={handleSubmit((productinfo: any) => {
                doctorFormData(productinfo);
              })}
            >
              <div className="row">
                <Input
                  containerClass="col-lg-4"
                  label="Doctor Name"
                  type="text"
                  isRequired
                  errorMessage={
                    errors?.DoctorName?.message ||
                    error?.response?.data?.errors?.DoctorName?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("DoctorName", e.target.value);
                    clearErrors(["DoctorName"]);
                  }}
                  register={{
                    ...register("DoctorName", {
                      required: {
                        value: true,
                        message: "Doctor Name is required",
                      },
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid chemistname (1-50 alphabet characters or special characters)",
                      },
                    }),
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  control={control}
                  isRequired
                  api={process.env.REACT_APP_CATEGORY_API}
                  keys={{ label: "Value", value: "Id" }}
                  onChange={(e: any) => {
                    setValue("CategoryId", e?.value);
                    setselectedcategory(e);
                  }}
                  value={selectedcategory}
                  name="CategoryId"
                  label="Category"
                  rules={{
                    required: { value: true, message: "Category is required" },
                  }}
                  errorMessage={
                    errors?.CategoryId?.message ||
                    error?.response?.data?.errors?.CategoryId?.map(
                      (e: any) => e
                    )
                  }
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  control={control}
                  isRequired
                  Multi={true}
                  onChange={(e: any) => {
                    setValue("SpecialityList", e);
                    setselectedspeciality(e);
                  }}
                  value={selectedspeciality}
                  api={process.env.REACT_APP_SPECIALITY_API}
                  keys={{ label: "Value", value: "Id" }}
                  name="SpecialityList"
                  label="Speciality"
                  rules={{
                    required: {
                      value: true,
                      message: "Speciality is required.",
                    },
                  }}
                  errorMessage={
                    errors?.SpecialityList?.message ||
                    error?.response?.data?.errors?.SpecialityList?.map(
                      (e: any) => e
                    )
                  }
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  control={control}
                  Multi={true}
                  onChange={(e: any) => {
                    setValue("QualificationList", e);
                    setselectedqualification(e);
                  }}
                  value={selectedqualification}
                  api={process.env.REACT_APP_QUALIFICATION_API}
                  keys={{ label: "Value", value: "Id" }}
                  name="QualificationList"
                  label="Qualifications"
                  errorMessage={
                    errors?.QualificationList?.message ||
                    error?.response?.data?.errors?.QualificationList?.map(
                      (e: any) => e
                    )
                  }
                />

                {localStorage.getItem("Roles") !== "MR" && (
                  <ReactSelect
                    containerClass="col-lg-4"
                    control={control}
                    Multi={true}
                    onChange={(e: any) => {
                      setValue("EmployeeIds", e);
                      setselectedemployee(e);
                    }}
                    value={selectedemployee}
                    api={process.env.REACT_APP_EMPLOYEE_API}
                    keys={{ label: "Value", value: "Id" }}
                    name="EmployeeIds"
                    label="Employee"
                    errorMessage={
                      errors?.EmployeeIds?.message ||
                      error?.response?.data?.errors?.EmployeeIds?.map(
                        (e: any) => e
                      )
                    }
                  />
                )}

                <ReactSelect
                  containerClass="col-lg-4"
                  control={control}
                  Multi={true}
                  onChange={(e: any) => {
                    setValue("ProductIds", e);
                    setselectedproduct(e);
                  }}
                  value={selectedproduct}
                  api={process.env.REACT_APP_PRODUCT_API}
                  keys={{ label: "Value", value: "Id" }}
                  name="ProductIds"
                  label="Product"
                  errorMessage={
                    errors?.ProductIds?.message ||
                    error?.response?.data?.errors?.ProductIds?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Hospital Name"
                  type="text"
                  // isRequired
                  errorMessage={
                    errors?.HospitalName?.message ||
                    error?.response?.data?.errors?.HospitalName?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("HospitalName", e.target.value);
                    clearErrors(["HospitalName"]);
                  }}
                  register={{
                    ...register("HospitalName", {
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid hospital name (1-50 characters only)",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Email"
                  type="email"
                  maxLength={50}
                  errorMessage={
                    errors?.Email?.message ||
                    error?.response?.data?.errors?.Email?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    setValue("Email", e.target.value);
                    clearErrors(["Email"]);
                  }}
                  register={{
                    ...register("Email", {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                        message: "Email format is Invalid..",
                      },
                    }),
                  }}
                />

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                    <InputMask
                      {...register("Mobile", {
                        required: {
                          value: true,
                          message: "Mobile No is required",
                        },
                      })}
                      mask="(999) 999-9999"
                      className="form-control"
                      onChange={(e) => {
                        setValue("Mobile", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      message={
                        errors?.Mobile?.message ||
                        error?.response?.data?.errors?.Mobile?.map(
                          (e: any) => e
                        )
                      }
                    />
                  </div>
                </div>

                <Input
                  containerClass="col-lg-4"
                  label="Phone No"
                  // isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("Phone", e.target.value);
                    clearErrors(["Phone"]);
                  }}
                  register={{
                    ...register("Phone", {
                      pattern: {
                        value: /^\d{6,10}$/,
                        message: "Enter a valid phone no. (6-10 digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.Phone?.message ||
                    error?.response?.data?.errors?.Phone?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="WebSite"
                  type="text"
                  errorMessage={
                    errors?.WebSite?.message ||
                    error?.response?.data?.errors?.WebSite?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    setValue("WebSite", e.target.value);
                    clearErrors(["WebSite"]);
                  }}
                  register={{
                    ...register("WebSite"),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="DOB"
                  type="date"
                  errorMessage={
                    errors?.DateOfBirth?.message ||
                    error?.response?.data?.errors?.DateOfBirth?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("DateOfBirth", e.target.value);
                    clearErrors(["DateOfBirth"]);
                  }}
                  register={{
                    ...register("DateOfBirth"),
                  }}
                // max={new Date().toISOString().split("T")[0]}
                />

                <Input
                  containerClass="col-lg-4"
                  label="DOA"
                  type="date"
                  errorMessage={
                    errors?.DateOfAnniversary?.message ||
                    error?.response?.data?.errors?.DateOfAnniversary?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("DateOfAnniversary", e.target.value);
                    clearErrors(["DateOfAnniversary"]);
                  }}
                  register={{
                    ...register("DateOfAnniversary"),
                  }}
                // max={new Date().toISOString().split("T")[0]}
                />

                <Input
                  containerClass="col-lg-4"
                  label="PanCard Number"
                  type="text"
                  errorMessage={
                    errors?.PanCardNumber?.message ||
                    error?.response?.data?.errors?.PanCardNumber?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("PanCardNumber", e.target.value);
                    clearErrors(["PanCardNumber"]);
                  }}
                  register={{
                    ...register("PanCardNumber", {
                      pattern: {
                        value: /^\w[A-Z0-9]{9}$/,
                        message:
                          "Enter a valid pancard no. (10 alphanumeric characters only)",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Registration Number"
                  type="text"
                  errorMessage={
                    errors?.RegistrationNumber?.message ||
                    error?.response?.data?.errors?.RegistrationNumber?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("RegistrationNumber", e.target.value);
                    clearErrors(["RegistrationNumber"]);
                  }}
                  register={{
                    ...register("RegistrationNumber", {
                      pattern: {
                        value: /^\d{10}$/,
                        message:
                          "Enter a valid registration no. (10 digits only)",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Software Name"
                  type="text"
                  maxLength={50}
                  errorMessage={
                    errors?.SoftwareName?.message ||
                    error?.response?.data?.errors?.SoftwareName?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("SoftwareName", e.target.value);
                    clearErrors(["SoftwareName"]);
                  }}
                  register={{
                    ...register("SoftwareName"),
                  }}
                />

                {/* Done */}
                <ReactSelect
                  containerClass="col-lg-4"
                  control={control}
                  api={process.env.REACT_APP_HOSPITALROLETYPE_API}
                  keys={{ label: "Value", value: "Id" }}
                  onChange={(e: any) => {
                    if (e === null) {
                      setValue("HospitalRoleTypeId", null);
                      setselectedrole(null);
                    }
                    else {
                      setValue("HospitalRoleTypeId", e?.value);
                      setselectedrole(e);
                    }

                  }}
                  value={selectedrole}
                  name="HospitalRoleTypeId"
                  label="Role in Hospital"
                  errorMessage={
                    errors?.HospitalRoleTypeId?.message ||
                    error?.response?.data?.errors?.HospitalRoleTypeId?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Patients Daily"
                  type="text"
                  errorMessage={
                    errors?.PatientsDaily?.message ||
                    error?.response?.data?.errors?.PatientsDaily?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("PatientsDaily", e.target.value);
                    clearErrors(["PatientsDaily"]);
                  }}
                  maxLength={7}
                  register={{
                    ...register("PatientsDaily", {
                      pattern: {
                        value: /^\d{0,7}$/,
                        message:
                          "Enter a valid daily patient (1-7 digits only)",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Patients Weekly"
                  type="text"
                  errorMessage={
                    errors?.PatientsWeekly?.message ||
                    error?.response?.data?.errors?.PatientsWeekly?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("PatientsWeekly", e.target.value);
                    clearErrors(["PatientsWeekly"]);
                  }}
                  register={{
                    ...register("PatientsWeekly", {
                      pattern: {
                        value: /^\d{0,7}$/,
                        message:
                          "Enter a valid weekly patient (1-7 digits only)",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Patients Monthly"
                  type="text"
                  errorMessage={
                    errors?.PatientsMonthly?.message ||
                    error?.response?.data?.errors?.PatientsMonthly?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("PatientsMonthly", e.target.value);
                    clearErrors(["PatientsMonthly"]);
                  }}
                  register={{
                    ...register("PatientsMonthly", {
                      pattern: {
                        value: /^\d{0,7}$/,
                        message:
                          "Enter a valid monthly patient (1-7 digits only)",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="Preferred Call Time"
                  type="text"
                  errorMessage={
                    errors?.PreferedCallTime?.message ||
                    error?.response?.data?.errors?.PreferedCallTime?.map(
                      (e: any) => e
                    )
                  }
                  onChange={(e: any) => {
                    setValue("PreferedCallTime", e.target.value);
                    clearErrors(["PreferedCallTime"]);
                  }}
                  maxLength={50}
                  register={{
                    ...register("PreferedCallTime"),
                  }}
                />

                <div className={"col-lg-4"}>
                  <div className="form-group image-upload">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="col-form-label"
                    >
                      Pancard Image
                    </label>
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <img
                          src={PancardImageDisplay}
                          alt="Click Here To add Image"
                          className="uploaded-image"
                          onError={handleError} width={"150px"} height={"150px"}
                        />
                        <input
                          type="file"
                          {...register("PancardImage")}
                          // onChange={(e: any) => {
                          //   if (e.target.files[0]) {
                          //     const filePath = URL.createObjectURL(e.target.files[0]);
                          //     setpanCardImageDisplay(filePath);
                          //   }
                          //   setpanCardImage(e?.target?.files);
                          // }

                          // }
                          onChange={(e) => {
                            setpancardChanged(true);
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              const filePath = URL.createObjectURL(file);
                              setpanCardImageDisplay(filePath);
                              console.log([file], "pancard Image");
                              setpanCardImage([file]);
                              setValue("PancardImage", file);
                            }
                          }}
                          className="form-control"
                          hidden
                          accept=".pdf,image/*"
                        />
                      </label>
                    </div>
                    {
                      data?.PancardImagePath !== undefined && data?.PancardImagePath !== null &&
                      <Link
                        to={`${data?.PancardImagePath}`}
                      >
                        <button type="button" className="btn btn-outline-secondary">
                          <i className="bi bi-image">View Image</i>
                        </button>

                      </Link>
                    }

                    {/* {PancardImage == null ? (
                      <a href={`${data?.PancardImagePath}`} target="blank">
                        {data?.PancardImage}
                      </a>
                    ) : (
                      <p>{PancardImage[0]?.name}</p>
                    )} */}
                    {!data.PancardImage && PancardImage == null && (
                      <ErrorMessage message={errors?.PancardImage?.message} />
                    )}
                  </div>
                </div>

                <div className={"col-lg-4"}>
                  <div className="form-group image-upload">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="col-form-label"
                    >
                      Registration Certificate{" "}
                    </label>
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <img
                          src={RegistrationCertificateDisplay}
                          alt="Click Here to Add Image"
                          className="uploaded-image"
                          onError={handleError} width={"150px"} height={"150px"}
                        />
                        <input
                          type="file"
                          {...register("RegistrationCertificate")}
                          // onChange={(e: any) => {
                          //   if (e.target.files[0]) {
                          //     const filePath = URL.createObjectURL(e.target.files[0]);
                          //     setregistrationImageDisplay(filePath);
                          //   }
                          //   setregistrationImage(e.target.files);
                          // }}
                          onChange={(e) => {
                            setregisterChanged(true);
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              const filePath = URL.createObjectURL(file);
                              setregistrationImageDisplay(filePath);
                              setregistrationImage([file]);
                              setValue("RegistrationCertificate", file);
                            }
                          }}
                          className="form-control"
                          hidden
                          accept=".pdf,image/*"
                        />
                      </label>
                    </div>
                    {
                      data?.RegistrationCertificatePath !== undefined && data?.RegistrationCertificatePath !== null &&
                      <Link
                        to={`${data?.RegistrationCertificatePath}`}
                      >
                        <button type="button" className="btn btn-outline-secondary">
                          <i className="bi bi-image">View Image</i>
                        </button>

                      </Link>
                    }
                    {/* {RegistrationCertificate == null ? (
                      <a
                        href={`${data?.RegistrationCertificatePath}`}
                        target="blank"
                      >
                        {data?.RegistrationCertificate}
                      </a>
                    ) : (
                      <p>{RegistrationCertificate[0]?.name}</p>
                    )} */}
                    {!data.RegistrationCertificate &&
                      RegistrationCertificate == null && (
                        <ErrorMessage
                          message={errors?.RegistrationCertificate?.message}
                        />
                      )}
                  </div>
                </div>

                <div className={"col-lg-4"}>
                  <div className="form-group image-upload">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="col-form-label"
                    >
                      Doctor Image
                    </label>
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <img
                          src={DoctorImageDisplay}
                          alt=""
                          className="uploaded-image"
                          onError={handleError} width={"150px"} height={"150px"}
                        />
                        <input
                          type="file"
                          {...register("DoctorImage")}

                          onChange={(e) => {
                            setDoctorChanged(true)
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              console.log(file, "file is here");
                              const filePath = URL.createObjectURL(file);
                              setDoctorImageDisplay(filePath);
                              setDoctorImage([file]);
                              setValue("DoctorImage", file);
                            }
                          }}
                          className="form-control"
                          hidden
                          accept=".pdf,image/*"
                        />
                      </label>
                    </div>
                    {
                      data?.DoctorImagePath !== undefined && data?.DoctorImagePath !== null &&
                      <Link
                        to={`${data?.DoctorImagePath}`}
                      >
                        <button type="button" className="btn btn-outline-secondary">
                          <i className="bi bi-image">View Image</i>
                        </button>

                      </Link>
                    }
                    {/* {DoctorImage == null ? (
                      <a href={`${data?.DoctorImagePath}`} target="blank">
                        {data?.DoctorImage}
                      </a>
                    ) : (
                      <p>{DoctorImage[0]?.name}</p>
                    )} */}
                    {!data.DoctorImage && DoctorImage == null && (
                      <ErrorMessage message={errors?.DoctorImage?.message} />
                    )}
                  </div>
                </div>
                <div className={"col-lg-4"}>
                  <div className="form-group image-upload">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="col-form-label"
                    >
                      Visiting Card Image
                    </label>
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <img
                          src={visitingCardImageDisplay}
                          alt=""
                          className="uploaded-image"
                          onError={handleError} width={"150px"} height={"150px"}
                        />
                        <input
                          type="file"
                          {...register("VisitingCardImage")}

                          onChange={(e) => {
                            setVisitingCardChanged(true)
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              console.log(file, "file is here");
                              const filePath = URL.createObjectURL(file);
                              setVisitingCardImageDisplay(filePath);
                              setVisitingCardImage([file]);
                              setValue("VisitingCardImage", file);
                            }
                          }}
                          className="form-control"
                          hidden
                          accept=".pdf,image/*"
                        />
                      </label>
                    </div>
                    {
                      data?.VisitingCardImagePath !== undefined && data?.VisitingCardImagePath !== null &&
                      <Link
                        to={`${data?.VisitingCardImagePath}`}
                      >
                        <button type="button" className="btn btn-outline-secondary">
                          <i className="bi bi-image">View Image</i>
                        </button>

                      </Link>
                    }
                    {/* {visitingCardImage == null ? (
                      <a href={`${data?.VisitingCardImagePath}`} target="blank">
                        {data?.VisitingCardImage}
                      </a>
                    ) : (
                      <p>{visitingCardImage[0]?.name}</p>
                    )} */}
                    {!data.VisitingCardImage && visitingCardImage == null && (
                      <ErrorMessage message={errors?.VisitingCardImage?.message} />
                    )}
                  </div>
                </div>

                <Address
                  header="Hospital Address"
                  control={control}
                  address1register={{
                    ...register("HospitalAddressLine1", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message:
                          "Enter a valid Address(2-250 alphanumeric characters)",
                      },
                    }),
                  }}
                  address1onChange={(e: any) => {
                    setValue("HospitalAddressLine1", e.target.value);
                    clearErrors(["HospitalAddressLine1"]);
                  }}
                  address1errorMessage={
                    errors?.HospitalAddressLine1?.message ||
                    error?.response?.data?.errors?.HospitalAddressLine1?.map(
                      (e: any) => e
                    )
                  }
                  address2register={{
                    ...register("HospitalAddressLine2", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message:
                          "Enter a valid Area(2-250 alphanumeric characters)",
                      },
                    }),
                  }}
                  address2onChange={(e: any) => {
                    setValue("HospitalAddressLine2", e.target.value);
                    clearErrors(["HospitalAddressLine2"]);
                  }}
                  address2errorMessage={
                    errors?.HospitalAddressLine2?.message ||
                    error?.response?.data?.errors?.HospitalAddressLine2?.map(
                      (e: any) => e
                    )
                  }
                  address4onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("HospitalCityId", e?.value);
                      setselectedhcity(e);
                      setselectedhpincode({});
                    } else {
                      setValue("HospitalCityId", null);
                      setValue("HospitalPincodeId", null);
                      setselectedhcity(null);
                      setselectedhpincode(null);
                    }
                  }}
                  address4errorMessage={
                    errors?.HospitalCityId?.message ||
                    error?.response?.data?.errors?.HospitalCityId?.map(
                      (e: any) => e
                    )
                  }
                  selectedCity={selectedhcity}
                  address5onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("HospitalPincodeId", e?.value);
                      setselectedhpincode(e);
                    } else {
                      setValue("HospitalPincodeId", null);
                      setselectedhpincode(null);
                    }
                  }}
                  address5errorMessage={
                    errors?.HospitalPincodeId?.message ||
                    error?.response?.data?.errors?.HospitalPincodeId?.map(
                      (e: any) => e
                    )
                  }
                  selectedPincode={selectedhpincode}
                  cityKeys={{ label: "Value", value: "Id" }}
                  cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                  cityrules={{
                    required: { value: true, message: "Please select city" },
                  }}
                  cityName={"HospitalCityId"}
                  pincodeKeys={{ label: "Value", value: "Id" }}
                  pincodeApi={
                    selectedhcity != null ?
                      `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}` : ""
                  }
                  pincodeName={"HospitalPincodeId"}
                  pincodeDisable={selectedhcity != null ? false : true}
                  pincoderules={{
                    required: { value: true, message: "Pincode is required" },
                  }}
                />

                <Address
                  header="Residential Address"
                  control={control}
                  address1register={{
                    ...register("ResidencialAddressLine1", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message:
                          "Enter a valid Address(2-250 alphanumeric characters)",
                      },
                    }),
                  }}
                  address1onChange={(e: any) => {
                    setValue("ResidencialAddressLine1", e.target.value);
                    clearErrors(["ResidencialAddressLine1"]);
                  }}
                  address1errorMessage={
                    errors?.ResidencialAddressLine1?.message ||
                    error?.response?.data?.errors?.ResidencialAddressLine1?.map(
                      (e: any) => e
                    )
                  }
                  address2register={{
                    ...register("ResidencialAddressLine2", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message:
                          "Enter a valid Area(2-250 alphanumeric characters)",
                      },
                    }),
                  }}
                  address2onChange={(e: any) => {
                    setValue("ResidencialAddressLine2", e.target.value);
                    clearErrors(["ResidencialAddressLine2"]);
                  }}
                  address2errorMessage={
                    errors?.ResidencialAddressLine2?.message ||
                    error?.response?.data?.errors?.ResidencialAddressLine2?.map(
                      (e: any) => e
                    )
                  }
                  address4onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("ResidencialCityId", e?.value);
                      setselectedRcity(e);
                      setselectedRpincode({});
                    } else {
                      setValue("ResidencialCityId", null);
                      setValue("ResidencialPincodeId", null);
                      setselectedRcity(null);
                      setselectedRpincode(null);
                    }
                  }}
                  address4errorMessage={
                    errors?.ResidencialCityId?.message ||
                    error?.response?.data?.errors?.ResidencialCityId?.map(
                      (e: any) => e
                    )
                  }
                  selectedCity={selectedRcity}
                  address5onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("ResidencialPincodeId", e?.value);
                      setselectedRpincode(e);
                    } else {
                      setValue("ResidencialPincodeId", null);
                      setselectedRpincode(null);
                    }
                  }}
                  address5errorMessage={
                    errors?.ResidencialPincodeId?.message ||
                    error?.response?.data?.errors?.ResidencialPincodeId?.map(
                      (e: any) => e
                    )
                  }
                  cityrules={{
                    required: { value: true, message: "Please select city" },
                  }}
                  selectedPincode={selectedRpincode}
                  cityKeys={{ label: "Value", value: "Id" }}
                  cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                  cityName={"ResidencialCityId"}
                  pincodeKeys={{ label: "Value", value: "Id" }}
                  pincodeApi={
                    selectedRcity != null ?
                      `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedRcity?.value}` : ""
                  }
                  pincodeName={"ResidencialPincodeId"}
                  pincodeDisable={selectedRcity != null ? false : true}
                  pincoderules={{
                    required: { value: true, message: "Pincode is required" },
                  }}
                />

                <div className="col-lg-12 text-center mt-3">
                  <Button
                    type="button"
                    severity="secondary"
                    outlined
                    onClick={() => setisOPen(false)}
                  >
                    <span className="p-button-label p-c">Back</span>
                  </Button>

                  {id === 0 && (
                    <Button
                      type="reset"
                      severity="danger"
                      onClick={() => {
                        reset();
                        setselectedcategory(null);
                        setselectedspeciality([]);
                        setselectedqualification([]);
                        setselectedemployee([]);
                        setselectedproduct([]);
                        setselectedrole(null);
                        setselectedRcity(null);
                        setselectedRpincode({});
                        setselectedhcity(null);
                        setselectedhpincode({});
                        setpanCardImage(null);
                        setregistrationImage(null);
                      }}
                      outlined
                      className="ml-2"
                    >
                      <span className="p-button-label p-c">Reset</span>
                    </Button>
                  )}

                  {doctorLoader ? (
                    <Button type="submit" outlined disabled className="ml-2">
                      <span className="p-button-label p-c">
                        Submit <Spinner width={"1rem"} height={"1rem"} />
                      </span>
                    </Button>
                  ) : (
                    <Button type="submit" outlined className="ml-2">
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
