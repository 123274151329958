import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import AddEditGiftMasterModal from "./AddEditGiftMasterModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { InputSwitch } from "primereact/inputswitch";
import { NullHandler } from "../../../common/NullHandler";
import { Header } from "../../../Layout/subComponents/Header";

export default function GiftMaster() {
  const apiname = process.env.REACT_APP_GIFTMASTER_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedgiftmaster, setselectedgiftmaster]: any = useState([]);
  const multi = selectedgiftmaster?.map((e: any) => {
    return e.GiftMasterId;
  });
  useEffect(() => { }, [selectedgiftmaster]);

  const columns = [
    {
      header: "#",
      field: "srNo",
      width: "2rem"
    },
    {
      header: "Action",
      body: (e: any) => actionGiftMasterTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "GiftName",
      header: "Gift Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "5rem",
    },
    {
      field: "GiftSmscode",
      header: "Gift SMS Code",
      body: (data: any) => {
        return NullHandler(data.GiftSmscode);
      },
      sortable: true,
      width: "5rem",
    },
    {
      field: "GiftPrice",
      header: "Gift Price",
      body: (data: any) => {
        return NullHandler(data.GiftPrice);
      },
      sortable: true,
      width: "5rem",
    },
    {
      field: "DivisionName",
      header: "Division",
      body: (data: any) => {
        return NullHandler(data.DivisionName);
      },
      sortable: true,
      width: "5rem",
    },
    {
      field: "DoctorGift",
      header: "Doctor Gift",
      body: (e: any) => actionTemplate(e),
      sortable: true,
      width: "5rem",
    },
    {
      field: "ChemistGift",
      header: "Chemist Gift",
      body: (e: any) => chemistTemplate(e),
      sortable: true,
      width: "5rem",
    },
    {
      field: "StockistGift",
      header: "Stockist Gift",
      body: (e: any) => stockistTemplate(e),
      sortable: true,
      width: "5rem",
    },
    {
      field: "EmployeeGift",
      header: "Employee Gift",
      body: (e: any) => employeeTemplate(e),
      sortable: true,
      width: "5rem",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.DoctorGift} />
      </>
    );
  };

  const chemistTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.ChemistGift} />
      </>
    );
  };

  const stockistTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.StockistGift} />
      </>
    );
  };

  const employeeTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.EmployeeGift} />
      </>
    );
  };

  const actionGiftMasterTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.GiftMasterId}
          editModal={() => {
            setupdateId(id.GiftMasterId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Gift Master</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Gift Master"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedgiftmaster?.length === 0 ? null : multi);
            }
          }}
          selecteditem={selectedgiftmaster}
          setselecteditem={setselectedgiftmaster}
        />
        {updateId !== null && (
          <AddEditGiftMasterModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
