import { useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../common/Action";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { Button } from "primereact/button";
import { Link, useParams } from "react-router-dom";
import { NullHandler } from "../../../common/NullHandler";

export const AccountList = () => {
  const apiname = process.env.REACT_APP_USER_API?.toString()
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => { return e.EmployeeId })
  // const setDeleteidtoDelete = (id: any) => {
  //   setdeleteID(id)
  // }

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      header: "Action",
      body: (e: any) => actionMRTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "DesignationName",
      header: "Designation Name",
      body: (data: any) => {
        return NullHandler(data.DesignationName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "DistrictName",
      header: "District Name",
      body: (data: any) => {
        return NullHandler(data.DistrictName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "HqName",
      header: "Hq Name",
      body: (data: any) => {
        return NullHandler(data.HqName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "UserRoleViewModels.RoleName",
      header: "Role Name",
      body: (e: any) => actionRoleTemplate(e),
      width: "10vw",
    },
    {
      field: "ReportingSeniorName",
      header: "Reporting Senior Name",
      body: (data: any) => {
        return NullHandler(data.ReportingSeniorName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "ZoneName",
      header: "Zone Name",
      body: (data: any) => {
        return NullHandler(data.ZoneName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "RegionName",
      header: "Region Name",
      body: (data: any) => {
        return NullHandler(data.RegionName);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "CityName",
      header: "City Name",
      body: (data: any) => {
        return NullHandler(data.CityName);
      },
      sortable: true,
      width: "10vw",
    },

  ];

  const actionRoleTemplate = (id: any) => {
    return id.UserRoleViewModels.length !== 0 ? id.UserRoleViewModels?.map((ab: any) => { return ab.RoleName }).join(", ") : " - "
  }

  const actionMRTemplate = (id: any) => {
    return (
      <>
        <div>
          <Link to={`/layout/mr/admin/editaccount/${id.UserId}`}>
            <Button
              type="button"
              icon="pi pi-pencil"
              className="mr-1"
              style={{ width: "2.5rem", height: "2.5rem" }}
              severity="secondary"
              rounded
            ></Button></Link>
        </div>
      </>
    )
  };

  return (
    <>
      <Helmet>
        <title> Account List </title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader headTitle="Account List" list={true} LinkURL={"/layout/mr/admin/addaccount"}
          linkflag
          linkflagURL={"/layout/adminpanel"}
          linkflagtitle={"Admin Panel"} />
        <DatatableCommon
          apiname={apiname}
          selecteditem={selectedRow}
          setselecteditem={setselectedRow}
          multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) } }}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
        />
      </div>


      {/* <DeleteModal id={deleteID} deleteMessage={(data: boolean) => { if (data) { setbool(true) } }} apiname={apiname} setisOPen={() => setdeleteID(null)} open={deleteID != null} /> */}
    </>
  );
};
