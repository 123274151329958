import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { Spinner } from "../../../common/Spinner";
import { Input } from "../../../common/Input";
import { Dialog } from "primereact/dialog";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
const AddEditInventoryCurrency = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_INVENTORYCURRENCY_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const CurrencyFormData = (data: any) => {
    let submitData = {};
    if (id) {
      submitData = {
        CurrencyToUpdate: data,
      };
    } else {
      submitData = {
        CurrencyToCreate: data,
      };
    }
    mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Currency`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form onSubmit={handleSubmit(CurrencyFormData)}>
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Currency"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Name", e.target.value);
                  clearErrors(["Name"]);
                }}
                register={{
                  ...register("Name", {
                    required: {
                      value: true,
                      message: "Currency is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-4"
                label="Symbol"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Symbol", e.target.value);
                  clearErrors(["Symbol"]);
                }}
                register={{
                  ...register("Symbol", {
                    required: {
                      value: true,
                      message: "Symbol is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Symbol?.message ||
                  error?.response?.data?.errors?.Symbol?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-4"
                label="Format"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Format", e.target.value);
                  clearErrors(["Format"]);
                }}
                register={{
                  ...register("Format", {
                    required: {
                      value: true,
                      message: "Format is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Format?.message ||
                  error?.response?.data?.errors?.Format?.map((e: any) => e)
                }
              />
              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => reset()}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditInventoryCurrency;
