import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import { Button } from "primereact/button";
import { Address } from "../../../common/Address";
interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
export const AddEditCustomer = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_INVENTORYCUSTOMER_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    watch,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const [selectedCompany, setSelectedCompany]: any = useState();
  const [selectedFssAiExpiryDate, setSelectedFssAiExpiryDate]: any = useState();
  const [selectedDlNo1ExpiryDate, setSelectedDlNo1ExpiryDate]: any = useState();
  const [selectedDlNo2ExpiryDate, setSelectedDlNo2ExpiryDate]: any = useState();
  const [selectedNrxExpiryDate, setSelectedNrxExpiryDate]: any = useState();
  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedpincode, setselectedpincode]: any = useState(null);
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);
  const Hcity = watch("CityId");
  useEffect(() => {
    if (id) {
      setValue("FssAiExpiryDate", data?.FssAiExpiryDate?.split("T")[0]);
      setValue("DlNo1ExpiryDate", data?.DlNo1ExpiryDate?.split("T")[0]);
      setValue("DlNo2ExpiryDate", data?.DlNo2ExpiryDate?.split("T")[0]);
      setValue("NrxExpiryDate", data?.NrxExpiryDate?.split("T")[0]);
      if (data) {
        if (data.CompanyId !== null) {
          setSelectedCompany({
            label: data.CompanyName,
            value: data.CompanyId,
          });
          setValue("CompanyId", data.CompanyId);
        } else {
          setValue("CompanyId", null);
          setSelectedCompany(null);
        }

        if (data.CityId !== null) {
          setselectedcity({
            label: data.City,
            value: data.CityId,
          });
          setValue("CityId", data.CityId);
        } else {
          setValue("CityId", null);
          setselectedcity(null);
        }

        if (data.PincodeId !== null) {
          setselectedpincode({
            label: data.PinCode,
            value: data.PincodeId,
          });
          setValue("PincodeId", data.PincodeId);
        } else {
          setValue("PincodeId", null);
          setselectedpincode(null);
        }
      }
    }
  }, [id, data]);

  const CustomerFormData = (data: any) => {
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }
    const tempData = { ...data };
    delete tempData.AddressLine1;
    delete tempData.AddressLine2;
    delete tempData.CityId;
    delete tempData.PincodeId;
    let submitData = {};
    if (id) {
      submitData = {
        CustomerToUpdate: tempData,
        AddressLine1: data.AddressLine1,
        AddressLine2: data.AddressLine2,
        CityId: data.CityId,
        PincodeId: data.PincodeId,
      };
    } else {
      submitData = {
        CustomerToCreate: tempData,
        AddressLine1: data.AddressLine1,
        AddressLine2: data.AddressLine2,
        CityId: data.CityId,
        PincodeId: data.PincodeId,
      };
    }

    mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Customer`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form onSubmit={handleSubmit(CustomerFormData)}>
            <div className="row">
              <Input
                containerClass="col-lg-3"
                label="Customer Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Name", e.target.value);
                  clearErrors(["Name"]);
                }}
                register={{
                  ...register("Name", {
                    required: {
                      value: true,
                      message: "Customer Name is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="GR Number"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("GRNumber", e.target.value);
                  clearErrors(["GRNumber"]);
                }}
                register={{
                  ...register("GRNumber", {
                    required: {
                      value: true,
                      message: "GR Number Is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.GRNumber?.message ||
                  error?.response?.data?.errors?.GRNumber?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="GST No"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Gstno", e.target.value);
                  clearErrors(["Gstno"]);
                }}
                register={{
                  ...register("Gstno", {
                    required: {
                      value: true,
                      message: "GST No is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Gstno?.message ||
                  error?.response?.data?.errors?.Gstno?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-3"
                isRequired
                label="Company"
                name={"CompanyId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("CompanyId", e?.value);
                    setSelectedCompany(e);
                  } else {
                    setValue("CompanyId", null);
                    setSelectedCompany(null);
                  }
                }}
                value={selectedCompany}
                control={control}
                api="Reporting/Company?pageNumber=1&pageSize=10"
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.CompanyId?.message}
                rules={{
                  required: { value: true, message: "Company is required" },
                }}
              />
              <Input
                containerClass="col-lg-3"
                label="FSSAI"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("FssAi", e.target.value);
                  clearErrors(["FssAi"]);
                }}
                register={{
                  ...register("FssAi", {
                    required: {
                      value: true,
                      message: "FSSAI Is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.FssAi?.message ||
                  error?.response?.data?.errors?.FssAi?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="DL No 1"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("DlNo1", e.target.value);
                  clearErrors(["DlNo1"]);
                }}
                register={{
                  ...register("DlNo1", {
                    required: {
                      value: true,
                      message: "DL Number Is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.DlNo1?.message ||
                  error?.response?.data?.errors?.DlNo1?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="DL No 2"
                type="text"
                onChange={(e: any) => {
                  setValue("DlNo2", e.target.value);
                  clearErrors(["DlNo2"]);
                }}
                register={{
                  ...register("DlNo2"),
                }}
                errorMessage={
                  errors?.DlNo2?.message ||
                  error?.response?.data?.errors?.DlNo2?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="NRX"
                type="text"
                maxLength={10}
                onChange={(e: any) => {
                  setValue("Nrx", e.target.value);
                  clearErrors(["Nrx"]);
                }}
                register={{
                  ...register("Nrx"),
                }}
                errorMessage={
                  errors?.Nrx?.message ||
                  error?.response?.data?.errors?.Nrx?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="FSSAI Expiry Date"
                type="date"
                errorMessage={
                  errors?.FssAiExpiryDate?.message ||
                  error?.response?.data?.errors?.FssAiExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("FssAiExpiryDate", e.target.value);
                  setSelectedFssAiExpiryDate(e.target.value);

                  clearErrors(["FssAiExpiryDate"]);
                }}
                register={{
                  ...register("FssAiExpiryDate"),
                }}
                value={selectedFssAiExpiryDate}
              // max={new Date().toISOString().split("T")[0]}
              />
              <Input
                containerClass="col-lg-3"
                label="DL No1 Expiry Date"
                type="date"
                errorMessage={
                  errors?.DlNo1ExpiryDate?.message ||
                  error?.response?.data?.errors?.DlNo1ExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DlNo1ExpiryDate", e.target.value);
                  setSelectedDlNo1ExpiryDate(e.target.value);
                  clearErrors(["DlNo1ExpiryDate"]);
                }}
                register={{
                  ...register("DlNo1ExpiryDate"),
                }}
                value={selectedDlNo1ExpiryDate}
              // max={new Date().toISOString().split("T")[0]}
              />
              <Input
                containerClass="col-lg-3"
                label="DL No2 Expiry Date"
                type="date"
                errorMessage={
                  errors?.DlNo2ExpiryDate?.message ||
                  error?.response?.data?.errors?.DlNo2ExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DlNo2ExpiryDate", e.target.value);
                  setSelectedDlNo2ExpiryDate(e.target.value);
                  clearErrors(["DlNo2ExpiryDate"]);
                }}
                register={{
                  ...register("DlNo2ExpiryDate"),
                }}
                value={selectedDlNo2ExpiryDate}
              // max={new Date().toISOString().split("T")[0]}
              />
              <Input
                containerClass="col-lg-3"
                label="NRX Expiry Date"
                type="date"
                errorMessage={
                  errors?.NrxExpiryDate?.message ||
                  error?.response?.data?.errors?.NrxExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("NrxExpiryDate", e.target.value);
                  setSelectedNrxExpiryDate(e.target.value);
                  clearErrors(["NrxExpiryDate"]);
                }}
                register={{
                  ...register("NrxExpiryDate"),
                }}
                value={selectedNrxExpiryDate}
              // max={new Date().toISOString().split("T")[0]}
              />
              <Input
                containerClass="col-lg-3"
                label="Phone No"
                type="text"
                onChange={(e: any) => {
                  setValue("Phone", e.target.value);
                  clearErrors(["Phone"]);
                }}
                register={{
                  ...register("Phone", {
                    pattern: {
                      value: /^\d{6,10}$/,
                      message: "Enter a valid phone no. (6-10 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Phone?.message ||
                  error?.response?.data?.errors?.Phone?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="Discount"
                type="text"
                onChange={(e: any) => {
                  setValue("Discount", e.target.value);
                  clearErrors(["Discount"]);
                }}
                register={{
                  ...register("Discount"),
                }}
                errorMessage={
                  errors?.Discount?.message ||
                  error?.response?.data?.errors?.Discount?.map((e: any) => e)
                }
              />
              <Address
                requiredflag
                header="Customer Address"
                control={control}
                address1register={{
                  ...register("AddressLine1", {
                    required: {
                      value: true,
                      message: "Address Line 1 is required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid Address(2-250 characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("AddressLine1", e.target.value);
                  clearErrors(["AddressLine1"]);
                }}
                address1errorMessage={
                  errors?.AddressLine1?.message ||
                  error?.response?.data?.errors?.AddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("AddressLine2", {
                    required: {
                      value: true,
                      message: "Area is required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid Area(2-250 characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("AddressLine2", e.target.value);
                  clearErrors(["AddressLine2"]);
                }}
                address2errorMessage={
                  errors?.AddressLine2?.message ||
                  error?.response?.data?.errors?.AddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("CityId", e?.value);
                    setselectedcity(e);
                    setselectedpincode({});
                  } else {
                    setValue("CityId", null);
                    setValue("PincodeId", null);
                    setselectedcity(null);
                    setselectedpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.CityId?.message ||
                  error?.response?.data?.errors?.CityId?.map((e: any) => e)
                }
                selectedCity={selectedcity}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {

                    setValue("PincodeId", e?.value);
                    setselectedpincode(e);
                  }
                  else {
                    setValue("PincodeId", null);
                    setselectedpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.PincodeId?.message ||
                  error?.response?.data?.errors?.PincodeId?.map((e: any) => e)
                }
                selectedPincode={selectedpincode}
                cityKeys={{ label: "Value", value: "Id" }}
                cityApi={"Reporting/city/GetAllCityAndState"}
                cityFlag={true}
                cityName={"CityId"}
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeApi={
                  selectedcity !== null && selectedcity?.value !== undefined
                    ? `Reporting/Pincode/GetAllPincodeByParent?CityId=${selectedcity?.value}`
                    : ""
                }
                pincodeName={"PincodeId"}
                pincodeDisable={selectedcity !== null ? false : true}
                pincoderules={{
                  required: {
                    value: true,
                    message: "Please select pincode",
                  },
                }}
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
              />
              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedpincode(null);
                      setselectedcity(null);
                      // setSelectedHSN(null);
                      setSelectedNrxExpiryDate(null);
                      setSelectedDlNo2ExpiryDate(null);
                      setSelectedDlNo1ExpiryDate(null);
                      setSelectedFssAiExpiryDate(null);
                      setSelectedCompany(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
