import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Input } from "../../../common/Input";
import { useForm } from "react-hook-form";

interface iPropsmoadl {
  data?: any;
  setisOPen?: any;
  open?: any;
  onSave?: any;
}

export const EditForm = ({ data, setisOPen, open, onSave }: iPropsmoadl) => {
  const [dynamicForm, setDynaminForm] = useState([]);
  const { register, handleSubmit, reset }: any = useForm({ mode: "all" });

  useEffect(() => {
    if (data) {
      reset(data);
    }
    const temp: any = [];
    for (const property in data) {
      temp.push({
        label: property.replace(/([A-Z])/g, " $1").trim(),
        name: property,
        value: data[property],
      });
    }
    setDynaminForm(temp);

    console.log(temp, "Temp data");
  }, [data]);

  const handle = (data: any) => {
    onSave(data);
  };

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header="Edit Form"
        style={{ width: "1100px" }}
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setisOPen(false)}
            />
            <Button
              label="Save "
              type="submit"
              icon="pi pi-check"
              className="p-button-text"
              onClick={handleSubmit(handle)}
            />
          </div>
        }
      >
        <form onSubmit={handleSubmit(handle)}>
          <div className="row col-md-12">
            {dynamicForm.map((e: any,index) => {
              return (
                <>
                {
                  (e?.label !== "id" && e?.label !== "Date" ) &&
                  <Input
                    containerClass="col-lg-4"
                    label={e?.label}
                    key={index}
                    type="text"
                    register={{
                      ...register(e?.name),
                    }}
                  />
                }
                </>
              );
            })}

          </div>
        </form>
      </Dialog>
    </>
  );
};
