import React from "react";
import AdminPanelCard from "../common/AdminPanelCard";
import DoctorImg from "../assets/images/Vector (1).png";
import ChemistImg from "../assets/images/Group 5252.png";
import StockistImg from "../assets/images/Group (1).png";
import HospitalImg from "../assets/images/homehospital.png";
import ProductImg from "../assets/images/product-svgrepo-com.png";
import EmployeeImg from "../assets/images/Group.png";
import AccountListImg from "../assets/images/accounting-svgrepo-com.png";
import { Helmet } from "react-helmet";

export const AdminPanel = () => {
  const role = localStorage.getItem("Roles");

  let admincard = [
    {
      name: "Doctor",
      link: "/layout/doctor",
      img: DoctorImg,
    },
    {
      name: "Chemist",
      link: "/layout/master/chemist",
      img: ChemistImg,
    },
    {
      name: "Hospital",
      link: "/layout/submaster/hospital",
      img: HospitalImg,
    },
    {
      name: "Stockist",
      link: "/layout/master/general/Stockiest",
      img: StockistImg,
    },
    {
      name: "Product",
      link: "/layout/Product",
      img: ProductImg,
    },
    {
      name: "Employee(MR)",
      link: "/layout/mr",
      img: EmployeeImg,
    },
  ];

  if (role !== "MR") {
    admincard.push({
      name: "Account List",
      link: "/layout/mr/admin/accountList",
      img: AccountListImg,
    });
  }

  return (
    <>
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard
              name={card?.name}
              link={card?.link}
              imageSrc={card?.img}
            />
          </div>
        ))}
      </div>
    </>
  );
};
