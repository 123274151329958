import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Spinner } from "../../common/Spinner";
import { useForm } from "react-hook-form";
import ReactSelect from "../../common/ReactSelect";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Accordion, AccordionTab } from "primereact/accordion";

export const ThreePReport = () => {
  const controller = new AbortController();
  const {
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  const role = localStorage.getItem("Roles");

  const [totalData, settotalData]: any = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selecteduser, setselecteduser]: any = useState(null);
  const [selecteddate, setselecteddate]: any = useState(null);
  useEffect(() => {
    return () => {

      controller.abort();
    }
  }, [])

  const P3ReportData = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PTHREEREPORT_API
        }?Self=${selecteduser !== null ? false : true}${selecteddate !== null ? `&dateFilterId=${selecteddate?.value}` : ""
        }${selecteduser !== null ? `&UserId=${selecteduser?.value}` : ""}`, { signal: controller.signal }
      )
      .then((res) => {
        console.log(res.data.Data, "res.data.Data");
        const temp = res.data.Data;

        console.log(temp, "tempData");
        setMenuCollaps(null);
        settotalData(temp);
        setIsLoading(false);
      })
      .catch((error) => {
        if (controller.signal.reason.code !== 20) {

          setIsLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });

        }

      });
  };

  useEffect(() => {
    P3ReportData();
  }, [selecteddate, selecteduser]);

  // const [activeIndex, setActiveIndex] = useState(null);

  // const onTabChange = (e:any, tabIndex:any) => {
  //   setActiveIndex(e.index === activeIndex ? null : tabIndex);
  // };

  const [menuCollaps, setMenuCollaps] = useState(null);
  const onTabChange = (e: any, tabIndex: any) => {
    setMenuCollaps(menuCollaps === tabIndex ? null : tabIndex);
  };
  return (
    <>
      <Helmet>
        <title>3P Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>3P Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  3P Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <ReactSelect
              containerClass={role === "MR" ? "col-lg-12" : "col-lg-6"}
              label="Period"
              name={"dateFilterId"}
              onChange={(e: any) => {
                setselecteddate(e);
                console.log(e, "dateFilterId");
                setValue("dateFilterId", e);
              }}
              // value={selecteddate === null ? {} : selecteddate}
              value={selecteddate}
              api={process.env.REACT_APP_DATEFILTER_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.dateFilterId?.message}
            />

            {role !== "MR" && (
              <ReactSelect
                containerClass="col-lg-6"
                label="User"
                name={"UserId"}
                onChange={(e: any) => {
                  setselecteduser(e);
                  setValue("UserId", e);
                }}
                // value={selecteduser === null ? {} : selecteduser}
                value={selecteduser}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.UserId?.message}
              />
            )}
          </div>
        </form>
      </div>

      <div className="pd-20 card-box mb-30">
        {!isLoading ? (
          <div>
            {totalData?.map((d: any, index: any) => {
              return (
                <div>
                  <Accordion
                    activeIndex={menuCollaps == d.UserId ? 0 : null}
                    onTabChange={(e) => onTabChange(e, d.UserId)}
                  >
                    <AccordionTab header={d.UserName}>
                      <div className="d-flex flex-column">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead style={{ backgroundColor: 'lightGray' }}>
                              <tr>
                                <th style={{ width: "50px" }}>#</th>
                                <th>PARTICULARS</th>
                                {d?.P3Reports?.map((e: any) => {
                                  return <th>{e?.Month}</th>;
                                })}
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DAYS IN MONTH
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDaysInMonth}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDaysInMonth,
                                    0
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. OF FIELD WORKING DAYS
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.NoOfFieldWorkingDays}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.NoOfFieldWorkingDays,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>3</td>
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. OF FIELD DAYS WORKED
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.NoOfFieldWorked}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.NoOfFieldWorked,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>4</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. OF DAYS ON LEAVE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.NoOfDaysOnLeave}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.NoOfDaysOnLeave,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>5</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. OF SUNDAYS / HOLIDAYS
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.NoOfSundaysHolidays}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.NoOfSundaysHolidays,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>6</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TRANSIT IF ANY / OTHERS
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TransitIfAnyOthers}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TransitIfAnyOthers,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>7</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. OF DAYS IN MEETING / CAMP
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.NoOfDaysInCamp}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.NoOfDaysInCamp,
                                    0
                                  )}
                                </td>
                              </tr>



                              <tr>
                                <td>8</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. OF JOINT WORKING DAYS
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.NoOfJointWorkingDays}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.NoOfJointWorkingDays,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>9</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTORS IN THE LIST
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctorsInList}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctorsInList,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>10</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DR. VISITED AT LEAST ONCE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctorsVisitedAtLeastOnce}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.TotalNoOfDoctorsVisitedAtLeastOnce,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>11</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTORS CALL REPORTED (A+B+C
                                  CLASS)
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctorsCallReported}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctorsCallReported,
                                    0
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>12</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTOR JOINT WORK WITH
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctorJointWorkWith}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctorJointWorkWith,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>13</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  PER DAY CALL AVERAGE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.PerDayCallAverage == 10 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.PerDayCallAverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.PerDayCallAverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.PerDayCallAverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>14</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  % COVERAGE  "TOTAL"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.Coverage > 70 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.Coverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.Coverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Coverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>15</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTOR IN "A"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNumberOfDoctorIn_A}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNumberOfDoctorIn_A,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>16</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTORS VISITED IN "A" CLASS
                                  AT LEAST ONCE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_AVisitedAtLeastOnce}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.TotalNoOfDoctors_AVisitedAtLeastOnce,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>17</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTORS MISSED "A"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_AMissed}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_AMissed,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>18</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF "A CLASS" VISITS EXPECTED{" "}
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_AVisitExpected}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.TotalNoOfDoctors_AVisitExpected,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>19</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF "A CLASS" CALL DONE / MADE{" "}
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_ACallDone}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_ACallDone,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>20</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. "A" CALLS DOCTORS VISITED ≥ 3 TIMES
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.No_ACallsDoctorsVisitedMoreThen3Times}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.No_ACallsDoctorsVisitedMoreThen3Times,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>21</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  "A" CLASS DR CALL PLAN COMPLIANCE - CPC
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.Doctor_A_CPC}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_A_CPC,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>22</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  "A" CLASS DR FREQUENCY
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.Doctor_A_Frequency === 100 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.Doctor_A_Frequency}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.Doctor_A_Frequency}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_A_Frequency,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>23</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  % COVERAGE  "A"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.Doctor_A_Coverage > 70 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.Doctor_A_Coverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.Doctor_A_Coverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_A_Coverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>24</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTOR IN "B"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNumberOfDoctorIn_B}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNumberOfDoctorIn_B,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>25</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTORS VISITED IN "B" CLASS
                                  AT LEAST ONCE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_BVisitedAtLeastOnce}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.TotalNoOfDoctors_BVisitedAtLeastOnce,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>26</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTORS MISSED "B"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_BMissed}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_BMissed,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>27</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF "B CLASS" VISITS EXPECTED{" "}
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_BVisitExpected}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.TotalNoOfDoctors_BVisitExpected,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>28</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF "B CLASS" CALL DONE / MADE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_BCallDone}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_BCallDone,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>29</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. "B" CALLS DOCTORS VISITED ≥ 2 TIMES
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.No_BCallsDoctorsVisitedMoreThen2Times}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum +
                                      item.No_BCallsDoctorsVisitedMoreThen2Times,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>30</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  "B" CLASS DR CALL PLAN COMPLIANCE - CPC
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.Doctor_B_CPC}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_B_CPC,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>31</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  "B" CLASS DR FREQUENCY
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.Doctor_B_Frequency == 100 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                          }}
                                        >
                                          {e?.Doctor_B_Frequency}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.Doctor_B_Frequency}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_B_Frequency,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>32</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  % COVERAGE  "B"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.Doctor_B_Coverage > 70 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.Doctor_B_Coverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.Doctor_B_Coverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_B_Coverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>33</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF DOCTOR IN "C"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNumberOfDoctorIn_C}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNumberOfDoctorIn_C,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>34</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {/* TOTAL NO OF DOCTORS " C CLASS" VISITED AT LEAST ONCE
                                </td> */}
                                  TOTAL NO. OF DOCTORS VISITED IN "C" CLASS AT LEAST ONCE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_CVisitedAtLeastOnce}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_CVisitedAtLeastOnce,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>35</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {/* TOTAL NO OF DOCTORS " C CLASS" MISSED
                                </td> */}
                                  TOTAL NO. OF DOCTORS MISSED "C"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_CMissed}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_CMissed,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>36</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {/* TOTAL NO OF DOCTORS " C CLASS" VISIT EXPECTED
                                </td> */}
                                  TOTAL NO. OF "C CLASS" VISITS EXPECTED
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_CVisitExpected}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_CVisitExpected,
                                    0
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>37</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {/* TOTAL NO OF DOCTORS " C CLASS" CALL DONE
                                </td> */}
                                  TOTAL NO. OF "C CLASS" CALL DONE / MADE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfDoctors_CCallDone}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfDoctors_CCallDone,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>38</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  NO. "C" CALLS DOCTORS VISITED ≥ 2 TIMES
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.No_CCallsDoctorsVisitedMoreThen2Times}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.No_CCallsDoctorsVisitedMoreThen2Times,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>39</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  "C" CLASS DR CALL PLAN COMPLIANCE - CPC
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.Doctor_C_CPC}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_C_CPC,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>40</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  "C" CLASS DR FREQUENCY
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.Doctor_C_Frequency}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_C_Frequency,
                                    0
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>41</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  % COVERAGE "C"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.Doctor_C_Coverage > 70 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.Doctor_C_Coverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.Doctor_C_Coverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.Doctor_C_Coverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>42</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF CHEMIST CALL REPORTED
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfChemistCallReported}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfChemistCallReported,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>43</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  CHEMIST CALL AVERAGE
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.ChemistCallAverage == 10 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.ChemistCallAverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.ChemistCallAverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.ChemistCallAverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>44</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  % COVERAGE "CHEMIST"
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <>
                                      {e.ChemistCoverage > 70 ? (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            backgroundColor: "green",
                                            color: "white",
                                          }}
                                        >
                                          {e?.ChemistCoverage}
                                        </td>
                                      ) : (
                                        <td
                                          style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {e?.ChemistCoverage}
                                        </td>
                                      )}
                                    </>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.ChemistCoverage,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr>

                              <tr>
                                <td>45</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. OF CME ( SPECIFIED IN REMARKS )
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfCME}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfCME,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>46</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  TOTAL NO. CAMP ( SPECIFIED IN REMARKS )
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.TotalNoOfCAMP}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.TotalNoOfCAMP,
                                    0
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>47</td>
                                <td

                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  OTHER ACTIVITY ( SPECIFIED IN REMARKS )
                                </td>
                                {d?.P3Reports?.map((e: any) => {
                                  return (
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {e?.OtherActivity}
                                    </td>
                                  );
                                })}
                                <td
                                  style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                  {d?.P3Reports.reduce(
                                    (sum: any, item: any) =>
                                      sum + item.OtherActivity,
                                    0
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion >
                </div >
              );
            })}
          </div >
        ) : (
          <div className="col-md-12 text-center">
            <Spinner width={"10rem"} height={"10rem"} />
          </div>
        )}
      </div >
    </>
  );
};
