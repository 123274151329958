import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'


interface iPropsmoadl {
    data?: any;
    setisOPen?: any;
    open?: any;
    response?:any
  }

const ErrorModal = ({ data, setisOPen, open, response }: iPropsmoadl) => {
    const [dynamindata, setDynamindata] = useState([]);
    useEffect(() => {
        if (data) {
        }
        const temp: any = [];
        for (const property in data) {
          temp.push({
            label: property.replace(/([A-Z])/g, " $1").trim(),
            name: property,
            value: data[property],
          });
        }
        setDynamindata(temp);
    
        console.log(temp, "Temp data");
      }, [data]);
  return (
    <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header="Error"
        style={{ width: "500px" }}
        modal
        footer={
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => {
                setisOPen(false)
                response(false)
            }}
            />
            <Button
              label="Yes"
              type="submit"
              icon="pi pi-check"
              className="p-button-text"
              onClick={()=>{
                response(true)
              }}
            />
          </div>
        }
      >
        <div className='col-md-12'>
            <div className='table-responsive'>
                <table className='table table-bordered table-striped'>
                    <tr>
                        <th>Stockist Name</th>
                        <td>{data?.StockistName}</td>
                    </tr>
                    <tr>
                        <th>Chemist Count</th>
                        <td>{data?.ChemistCount}</td>
                    </tr>
                    <tr>
                        <th>Product Count</th>
                        <td>{data?.ProductCount}</td>
                    </tr>
                </table>
            </div>
        </div>
      </Dialog>
  )
}

export default ErrorModal
