import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  const role = localStorage.getItem("Roles");

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>

      {role === "BDM" ||
      role === "ASM" ||
      role === "RSM" ||
      role === "ZSM" ||
      role === "admin" ||
      role === "MR" ? (
        ""
      ) : (
        <div className="pd-20 card-box mb-30">
          <div className="login-title text-center">
            <img
              src="https://bioslab.co.in/images/logo.svg"
              width="165"
              height="42"
              alt="Bios"
            />
          </div>
        </div>
      )}

      {role === "BDM" ||
      role === "ASM" ||
      role === "RSM" ||
      role === "ZSM" ||
      role === "admin" ||
      role === "MR" ? (
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Privacy Policy</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/layout/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Privacy Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="pd-20 card-box mb-30">
        <div style={{ padding: 20 }}>
          <h3 className="mb-20">
            <u>
              Privacy Policy for <i>BiosLab</i>
            </u>
          </h3>

          <p>
            This privacy policy governs your use of the software applications
            (BiosLab) for mobile devices that was hosted at NIC e-Gov Mobile App
            Store in Google Play. The Applications mainly provide eGovernance
            Services delivery and intends to provide better citizen services by
            the government. The contents published on these Applications were
            provided by the concerned Ministries/Departments of Government of
            India or the allied government establishment. This information
            provided through these applications may not have any legal sanctity
            and are for general reference only, unless otherwise specified.
            However, every effort has been made to provide accurate and reliable
            information through these applications. Users are advised to verify
            the correctness of the facts published here from the concerned
            authorities. Neither National Informatics Centre nor Government of
            India and its allied establishments will not be responsible for the
            accuracy and correctness of the contents available in these
            applications.
          </p>
          <p>
            <strong>User Provided Information: </strong>
          </p>
          <p>
            The Applications may obtain the information you provide when you
            download and register the Application. Registration is optional.
            However, please keep in mind that you may not be able to use some of
            the features offered by an Application unless you register.
          </p>
          <p>
            When you register and use the Application, you generally provide (a)
            your name, email address, age, user name, password and other
            registration information; (b) download or use applications from us;
            (c) information you provide when you contact us for help; (d) credit
            card information for use of the service, and; (e) information you
            enter into our system when using the Application, such as contact
            information and other details.
          </p>
          <p>
            The information you provided may be used to contact you from time to
            time to provide you with important information and required notices.
          </p>
          <p>
            <strong>Automatically Collected Information : </strong>
          </p>
          <p>
            In addition, the Application may collect certain information
            automatically, including, but not limited to, the type of mobile
            device you use, your mobile devices unique device ID, the IP address
            of your mobile device, your mobile operating system, the type of
            mobile Internet browsers you use, and information about the way you
            use the Application.
          </p>
          <p>
            When you visit the mobile application, it may use GPS technology (or
            other similar technology) to determine your current location in
            order to determine the city you are located within and display a
            location map. The location information may be sent to authorities
            for taking necessary actions and making policy decisions.
          </p>
          <p>
            If you do not want the app to use your location for the purposes set
            forth above, you should turn off the location services for the
            mobile application located in your account settings or in your
            mobile phone settings and/or within the mobile application. However,
            if the service provided by the Application requires the location
            services using GPS technology, such services offered by the
            application will not be available for you.
          </p>
          <p>
            <strong>
              We may disclose User provided and Automatically Collected
              Information:
            </strong>
          </p>
          <ul>
            <li>
              as required by law, such as to comply with a subpoena, or similar
              legal process;
            </li>
            <li>
              when we believe in good faith that disclosure is necessary to
              protect our rights, protect your safety or the safety of others,
              investigate fraud, or respond to a government request;
            </li>
            <li>
              with our trusted services providers who work on our behalf, do not
              have an independent use of the information we disclose to them,
              and have agreed to adhere to the rules set forth in this privacy
              statement.
            </li>
          </ul>
          <p>
            {" "}
            You can stop all collection of information by the Application easily
            by uninstalling the Application. You may use the standard uninstall
            processes as may be available as part of your mobile device or via
            the mobile application marketplace or network.
          </p>
          <p>
            <strong>Data Retention Policy, Managing Your Information</strong>
          </p>
          <p>
            We will retain User provided data for as long as you use the
            Application and for a reasonable time thereafter. We will retain
            Automatically Collected information also for a reasonable period of
            time depending on the nature of application and thereafter may store
            it in aggregate. Please note that some or all of the User Provided
            Data may be required in order for the Application to function
            properly.
          </p>
          <p>
            <strong>Misuse by Non Targeted Users</strong>
          </p>
          <p>
            All mobile apps are meant for use by the targeted audience only.
            Misuse by non-targeted users should be prevented by owner of the
            mobile.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            We are concerned about safeguarding the confidentiality of your
            information. We provide physical, electronic, and procedural
            safeguards to protect information we process and maintain. For
            example, we limit access to this information to authorized employees
            and contractors who need to know that information in order to
            operate, develop or improve our Application. Please be aware that,
            although we endeavour to provide reasonable security for information
            we process and maintain, no security system can prevent all
            potential security breaches.
          </p>
          <p>
            <strong>Changes</strong>
          </p>
          <p>
            This Privacy Policy may be updated from time to time for any reason.
            We will notify you of any changes to our Privacy Policy by posting
            the new Privacy Policy here. You are advised to consult this Privacy
            Policy regularly for any changes, as continued use is deemed
            approval of all changes. You can check the history of this policy by
            clicking here.
          </p>
          <p>
            <strong>Your Consent</strong>
          </p>
          <p>
            By using the Application, you are consenting to our processing of
            your information as set forth in this Privacy Policy now and as
            amended by us.
          </p>
          <p>
            <strong>Contact us</strong>
          </p>
          <p>
            If you have any questions regarding privacy while using the
            Application, or have questions about our practices, please contact
            us via email at <b>aisante.bharat@gmail.com</b>
          </p>
        </div>
      </div>
    </>
  );
};
