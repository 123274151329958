import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PaginationServiceOfSampleRequest } from "../../../service/PaginationService";
import { Badge } from "primereact/badge";
import moment from "moment";
import AddSampleRequestModal from "./AddSampleRequestModal";

const SampleRequest = () => {
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfSampleRequest.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const actionNameTemplate = (id: any) => {
    let gift = id?.GiftName == null ? "" : `${id?.GiftName}`;
    let product = id?.ProductName == null ? "" : `${id?.ProductName}`;

    return product + " " + gift || " - ";
  };

  const [updateId, setupdateId]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  useEffect(() => {
    if (bool) {
      loadLazyData();
    }
  }, [bool]);

  return (
    <div>
      <Helmet>
        <title>Sample Request</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Sample Request"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/Inventory"}
          linkflagtitle={"Inventory"}
        />

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="SrNo" header="#" style={{ width: "4%" }} />
                <Column
                  field="ProductName"
                  header="Product/Gift"
                  body={actionNameTemplate}
                  style={{ width: "40%" }}
                ></Column>
                <Column
                  field="Qty"
                  header="Qty"
                  style={{ width: "10%" }}
                ></Column>
                <Column
                  field="RequestedByName"
                  header="Requested By"
                  style={{ width: "30%" }}
                ></Column>
                <Column
                  field="RequestedDate"
                  header="Requested Date"
                  alignHeader={"center"}
                  style={{ width: "16%", textAlign: "center" }}
                  body={(e: any) => {
                    if (e.RequestedDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"success"}
                            value={moment(e.RequestedDate).format(
                              "DD MMM, YYYY"
                            )}
                          ></Badge>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={moment(e.RequestedDate).format("hh:mm A")}
                            style={{ backgroundColor: "#978EEB" }}
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>

        {updateId !== null && (
          <AddSampleRequestModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>
    </div>
  );
};

export default SampleRequest;
