import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { DailyPlanRejectModal } from "../../../common/DailyPlanRejectModal";
import { useForm } from "react-hook-form";
import { PaginationServiceByAdminDailyTourPlan } from "../../../service/PaginationService";
import { ErrorMessage } from "../../../common/ErrorMessage";
import ReactSelect from "../../../common/ReactSelect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Badge } from "primereact/badge";
import { Spinner } from "../../../common/Spinner";

export const AdminDailyPlan = () => {
  const controller = new AbortController();
  const [id, setId] = useState(null);
  const [approveId, setapproveId] = useState(null);
  const [status, setStatus]: any = useState(7);
  const [bool, setbool]: any = useState(null);

  const [selecteduser, setselecteduser]: any = useState(null);
  const [data, setData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [Nodata, setNodata] = useState(false);

  const [loader, setLoader] = useState(false);

  const adminapiname =
    process.env.REACT_APP_DAILYTOURPLANAPPROVAL_API?.toString();

  const { mutate, isSuccess } = useCreateByID(adminapiname, approveId);

  const [sortBy, setSortBy]: any = useState({
    sortField: null,
    sortOrder: null,
  });

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: null,
  });
  useEffect(() => {
    tourPlanApprovalChange(7, 0)
  }, [])
  const tourPlanApprovalChange = (statusParams: any, userparams: any) => {
    let Temp: any = {};

    if (statusParams === 0) {
      if (status !== null || status !== undefined) {
        Temp["StatusId"] = status;
      }
      else {
        Temp["StatusId"] = null;
      }
    }
    else {
      Temp["StatusId"] = statusParams;
    }
    if (userparams === 0) {
      if (selecteduser !== null || selecteduser !== undefined) {
        Temp["UserId"] = selecteduser;
      }
      else {
        Temp["UserId"] = null;
      }
    }
    else {
      Temp["UserId"] = userparams;
    }



    console.log(Temp, "temp")


    setlazyState({
      ...lazyState,
      paramter: Temp,
    });



  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  let networkTimeout: any = null;

  useEffect(() => {
    if (lazyState.paramter !== null) {
      loadLazyData();
    }
    return () => {
      controller.abort();
    }
  }, [lazyState, sortBy]);

  const loadLazyData = () => {
    setLoader(true);
    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByAdminDailyTourPlan.getData(lazyState, sortBy, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              srNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });

          console.log(temp, "temp");
          setData(temp);
          setTotalRecord(data.TotalDataCount);
          if (temp?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  const OnSortChange = (e: any) => {
    setSortBy({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const finalData = {
    DailyTourPlanId: "",
    Status: "Reject",
    Remark: "",
  };

  const handleApprove = (id: any) => {
    const approveData = {
      DailyTourPlanId: id,
      Status: "Approve",
      Remark: "",
    };
    console.log(approveData, "approveData");
    mutate(approveData);
  };

  useEffect(() => {
    if (approveId != null) {
      handleApprove(approveId);
    }
  }, [approveId]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Approved Successfull", { position: "top-right" });
      setbool(true);
      loadLazyData();
    }
  }, [isSuccess]);

  const doctorFunction = (e: any) => {
    return e.doctorVisitViewModels.map((ak: any, i: any) => {
      return (
        <span style={{ display: "block" }}>
          <strong>{`${i + 1})  `}</strong>
          {ak.DoctorName}
        </span>
      );
    });
  };

  const chemistFunction = (e: any) => {
    return e.chemistVisitViewModels.map((ak: any, i: any) => {
      return (
        <span style={{ display: "block" }}>
          <strong>{`${i + 1})  `}</strong>
          {ak.ChemistName}
        </span>
      );
    });
  };

  const hospitalFunction = (e: any) => {
    return e.hospitalVisitViewModels.map((ak: any, i: any) => {
      return (
        <span style={{ display: "block" }}>
          <strong>{`${i + 1})  `}</strong>
          {ak.HospitalName}
        </span>
      );
    });
  };

  const stockistFunction = (e: any) => {
    return e.stockistVisitViewModels.map((ak: any, i: any) => {
      return (
        <span style={{ display: "block" }}>
          <strong>{`${i + 1})  `}</strong>
          {ak.StockistName}
        </span>
      );
    });
  };

  const actionDateTemplate = (id: any) => {
    // return moment(id.TourDate).format("DD MMM, YYYY hh:mm A");
    if (id.TourDate === null) {
      return "-";
    } else {
      return (
        <>
          <Badge
            className="m-1"
            severity={"success"}
            value={moment(id.TourDate).format("DD MMM, YYYY")}
          ></Badge>
          <Badge
            className="m-1"
            severity={"info"}
            value={moment(id.TourDate).format("hh:mm A")}
            style={{ backgroundColor: "#978EEB" }}
          ></Badge>
        </>
      );
    }
  };

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {/* {e.StatusName} */}
        {e.StatusName == "Approve"
          ? "Approved"
          : e.StatusName == "Reject"
            ? "Rejected"
            : e.StatusName}
      </span>
    );
  };

  const actionFunction = (e: any) => {
    return e.StatusName == "Pending" ? (
      <>
        <button
          className="btn btn-success btn-sm mr-1"
          onClick={() => {
            setapproveId(e.DailyTourPlanId);
          }}
        >
          Approve
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => setId(e.DailyTourPlanId)}
          type="button"
        >
          Reject
        </button>
      </>
    ) : (
      ""
    );
  };

  let obj = data.find((o: any) => o.StatusName === "Pending");

  return (
    <>
      <Helmet>
        <title>Admin Daily Plan</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Admin Daily Plan</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/approvals"}>Approvals</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Admin Daily Plan
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <form>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Status <span className="text-danger">*</span>
                  </label>
                  <select
                    className="custom-select col-12"
                    {...register("StatusId", {
                      required: {
                        value: true,
                        message: "Status is required",
                      },
                      onchange,
                    })}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      tourPlanApprovalChange(e.target.value, 0);
                    }}
                  >
                    <option value={2}>Approved</option>
                    <option value={3}>Rejected</option>
                    <option value={7} selected>Pending</option>
                  </select>
                </div>
                <ErrorMessage message={errors?.StatusId?.message} />
              </div>

              <ReactSelect
                containerClass="col-lg-6"
                label="User"
                name={"UserId"}
                onChange={(e: any) => {
                  setselecteduser(e);
                  setValue("UserId", e);
                  tourPlanApprovalChange(0, e);
                }}
                value={selecteduser}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.UserId?.message}
              />

            </div>
          </form>
        </div>
      </div>

      {data?.length !== 0 ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box">
            <DataTable
              value={data}
              dataKey="id"
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={totalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onPageChange}
              removableSort
              onSort={OnSortChange}
              sortField={sortBy.sortField}
              sortOrder={sortBy.sortOrder}
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              loading={loader}
            >
              <Column header="#" field="srNo"></Column>
              <Column
                header="MR Name"
                field="MrName"
                body={null}
                bodyClassName={"text-info h6"}
                sortable
              ></Column>
              <Column
                field="TourDate"
                header="Tour Date"
                sortable
                body={(e: any) => actionDateTemplate(e)}
              ></Column>
              <Column
                field="doctorVisitViewModels.DoctorName"
                header="Doctor"
                body={(e: any) => doctorFunction(e)}
              ></Column>
              <Column
                field="chemistVisitViewModels.ChemistName"
                header="Chemist"
                body={(e: any) => chemistFunction(e)}
              ></Column>
              <Column
                field="stockistVisitViewModels.StockistName"
                header="Stockist"
                body={(e: any) => stockistFunction(e)}
              ></Column>
              <Column
                field="hospitalVisitViewModels.HospitalName"
                header="Hospital"
                body={(e: any) => hospitalFunction(e)}
              ></Column>
              <Column
                field="StatusName"
                header="Status"
                body={(e: any) => statusFunction(e)}
              ></Column>
              {obj ? (
                <Column
                  header="Action"
                  body={(e: any) => actionFunction(e)}
                ></Column>
              ) : null}
            </DataTable>
          </div>
        </div>
      ) : Nodata === true ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box text-center">
            <h3 className="text-secondary">No data found.</h3>
          </div>
        </div>
      ) : (
        ""
      )}

      <DailyPlanRejectModal
        id={id}
        jsonData={finalData}
        setisOPen={() => setId(null)}
        open={id != null}
        adminapiname={adminapiname}
      />
    </>
  );
};
