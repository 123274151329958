import { DeleteModal } from "../../../../common/DeleteModal";
import AddEditHospitalRoleType from "./AddEditHospitalRoleType";
import { PageHeader } from "../../../../common/PageHeader";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { useEffect, useState } from "react";
import DatatableCommon from "../../../../common/DatatableCommon";

const HospitalRoleType = () => {
  const apiname = process.env.REACT_APP_HOSPITALROLETYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [updateId, setupdateId]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.HospitalRoleTypeId;
  });
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionHospitalRoleTypeTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "HospitalRoleTypeName",
      header: "Hospital Role Type Name",
      body: null,
      bodyClassName: "text-info h6",
      sortable: true,
      width: "10vw",
    },
  ];

  const actionHospitalRoleTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.HospitalRoleTypeId}
          editModal={() => {
            setupdateId(id.HospitalRoleTypeId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Hospital Role Type</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Hospital Role Type"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/doctorattributes"}
          linkflagtitle={"Doctor Attributes"}
        />
        <div className="pd-20 card-box mb-30">
          <div className="col-md-12">
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              // check={true}
              refetchData={bool}
              setrefetchData={setbool}
              setselecteditem={setselectedRow}
              selecteditem={selectedRow}
              multiDelete={(e: boolean) => {
                if (e) {
                  setdeleteID(selectedRow?.length === 0 ? null : multi);
                }
              }}
            />

            {updateId !== null && (
              <AddEditHospitalRoleType
                setisOPen={() => {
                  return setupdateId(null);
                }}
                fetch={(data: boolean) => {
                  if (data) {
                    setbool(true);
                  }
                }}
                open={updateId !== null}
                id={updateId}
              />
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default HospitalRoleType;
