import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import axiosInstance from "../../../_helper/Interceptor";
import Textarea from "../../../common/Textarea";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { AddEditLeaveTypeModal } from "../../master/submaster/leaveType/AddEditLeaveTypeModal";
import ButtonCommon from "../../../common/ButtonCommon";
import { Spinner } from "../../../common/Spinner";
import { Button } from "primereact/button";
import { ErrorMessage } from "../../../common/ErrorMessage";


interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditLeave = ({ id, setisOPen, open, fetch }: iPropsmodal) => {
  const apiname = process.env.REACT_APP_LEAVE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
    watch,
    getValues
  }: any = useForm({ mode: "all" });

  const da = watch();

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const { data, isLoading: fetchLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        console.log(data, "data");
        setValue("FromDate", data.FromDate?.split("T")[0]);
        setValue("ToDate", data.ToDate?.split("T")[0]);
        setselectedleave({
          label: data.LeaveTypeName,
          value: data.LeaveTypeId,
        });

        setValue("LeaveDayTypeId", String(data.LeaveDayTypeId));
      }

    }
  }, [data]);

  const [selectedleave, setselectedleave]: any = useState(null);
  const [leaveDay, setLeaveDay]: any = useState(false)
  const [leave, setleave]: any = useState(null);
  const [defaultValue, setdefaultValue]: any = useState(null);
  const handleleaveChange = (inputvalue: any) => {
    setleave(0);
    setdefaultValue(inputvalue);
  };

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  const leaveFormData = (data: any) => {
    if (data.LeaveDayTypeId === null) {
      setLeaveDay(true)
    }
    else {
      let newData: any = {};
      Object.keys(data).forEach(
        (k) =>
          (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
      );
      mutate(newData);
    }
    // data.LeaveTypeId = data?.LeaveTypeId.value

  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Leave Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Leave Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  useEffect(() => { }, []);

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title">{id === 0 ? "Apply" : "Edit"} Leave</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {!fetchLoader ? (
          <div className="modal-body">
            <form
              onSubmit={handleSubmit((productinfo: any) => {
                leaveFormData(productinfo);
              })}
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="custom-control custom-radio mb-5">
                    <input
                      type="radio"
                      id="C1"
                      {...register("LeaveDayTypeId")}
                      value={1}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="C1">
                      Half Day
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="custom-control custom-radio mb-5">
                    <input
                      type="radio"
                      id="C2"
                      {...register("LeaveDayTypeId")}
                      value={2}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="C2">
                      Full Day
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="custom-control custom-radio mb-5">
                    <input
                      type="radio"
                      id="C3"
                      {...register("LeaveDayTypeId")}
                      value={3}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="C3">
                      Multiple Days
                    </label>

                  </div>


                </div>
                {
                  (leaveDay && getValues("LeaveDayTypeId") === null) &&
                  <div className="col-lg-12 text-center">
                    <ErrorMessage message={"Select Any One From Above"} />
                  </div>

                }


                <ReactSelect
                  containerClass="col-lg-4"
                  label="Leave Type"
                  control={control}
                  name="LeaveTypeId"
                  isRequired

                  onChange={(e: any) => {
                    setselectedleave(e);
                    setValue("LeaveTypeId", e?.value);
                  }}
                  value={selectedleave}
                  handleCreate={handleleaveChange}
                  api={process.env.REACT_APP_LEAVETYPE_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.LeaveTypeId?.message ||
                    error?.response?.data?.errors?.LeaveTypeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Leave Type is required",
                    },
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="From Date"
                  type="date"
                  isRequired
                  errorMessage={
                    errors?.FromDate?.message ||
                    error?.response?.data?.errors?.FromDate?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    if (da.LeaveDayTypeId !== "3") {
                      setValue("ToDate", e.target.value);
                    }
                    clearErrors(["FromDate"]);
                  }}
                  register={{
                    ...register("FromDate", {
                      required: {
                        value: true,
                        message: "From Date is required",
                      },
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="To Date"
                  type="date"
                  isRequired
                  disabled={da.LeaveDayTypeId !== "3" ? true : false}
                  errorMessage={
                    errors?.ToDate?.message ||
                    error?.response?.data?.errors?.ToDate?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    setValue("ToDate", e.target.value);
                    clearErrors(["ToDate"]);
                  }}
                  register={{
                    ...register("ToDate", {
                      required: {
                        value: true,
                        message: "To Date is required",
                      },
                    }),
                  }}
                />

                <Textarea
                  containerClass="col-lg-12"
                  label="Reason"
                  isRequired
                  maxLength={500}
                  errorMessage={
                    errors?.Reason?.message ||
                    error?.response?.data?.errors?.Reason?.map((e: any) => e)
                  }
                  onChange={(e: any) => {
                    setValue("Reason", e.target.value);
                    clearErrors(["Reason"]);
                  }}
                  register={{
                    ...register("Reason", {
                      required: { value: true, message: "Reason is required" },
                      pattern: {
                        value:
                          /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                        message:
                          "Enter Valid Reason and Remove whitespace from both sides of a string",
                      },
                    }),
                  }}
                />

                <div className="col-lg-12 text-center mt-3">
                  <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                    <span className="p-button-label p-c">Back</span>
                  </Button>

                  {
                    id === 0 && <Button type="reset" severity="danger" onClick={() => {
                      reset(); setselectedleave(null);
                    }} outlined className="ml-2">
                      <span className="p-button-label p-c">Reset</span>
                    </Button>
                  }

                  {isLoading ? (
                    <Button type="submit" outlined disabled className="ml-2">
                      <span className="p-button-label p-c">
                        Submit <Spinner width={"1rem"} height={"1rem"} />
                      </span>
                    </Button>
                  ) : (
                    <Button type="submit" outlined className="ml-2">
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>

            {leave !== null && (
              <AddEditLeaveTypeModal
                defaultValue={defaultValue}
                setisOPen={() => {
                  return setleave(null);
                }}
                fetch={(data: boolean, res: any) => {
                  if (data) {
                    setValue("LeaveTypeId", res.LeaveTypeId);
                    setselectedleave({
                      label: res.LeaveTypeName,
                      value: res.LeaveTypeId,
                    });
                    // fetchleaveOptions();
                  }
                }}
                open={leave !== null}
                id={leave}
              />
            )}
          </div>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Modal>
    </>
  );
};
