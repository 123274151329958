import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "../../common/Input";
import Textarea from "../../common/Textarea";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axiosInstance from "../../_helper/Interceptor";
import { useCreateByID, useFetchAllID } from "../../hooks/Api/Api";

const ViewNotification = () => {
  const { id }: any = useParams();

  const apiname = process.env.REACT_APP_NOTIFICATION_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  let { data }: any = useFetchAllID(apiname, id);

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
      }
      onSubmit()
    }
  }, [data, id]);

  const onSubmit = () => {
    axiosInstance.post(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GETNOTIFICATIONISDELIVERED_API}`,{
        NotificationId:id
    }).then((e:any)=>{
        console.log(e)
    }).catch((error)=>{
        console.log(error)
    })
  }

  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>View Notification </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/Notification"}>Notification</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Notification
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <Input
              containerClass="col-lg-12"
              label="Title"
              type="text"
              maxLength={100}
              isRequired
              onChange={() => clearErrors(["Title"])}
              register={{
                ...register("Title", {
                  required: {
                    value: true,
                    message: "Title is required",
                  },
                  pattern: {
                    value: /^.{2,50}$/gm,
                    message:
                      "Enter a valid title (1-50 alphabet characters or special characters)",
                  },
                }),
              }}
            />

            <Textarea
              containerClass="col-lg-12"
              label="Message"
              rows={1}
              isRequired
              onChange={() => clearErrors(["Description"])}
              register={{
                ...register("Message", {
                  required: { value: true, message: "Message is required" },
                  pattern: {
                    value: /^.{2,500}$/gm,
                    message:
                      "Enter a valid message (1-500 alphabet characters or special characters)",
                  },
                }),
              }}
            />
                
            {/* <div className="col-lg-12 form-group">
              <label className="col-form-label">Body</label>
            </div> */}
            <div className="col-lg-12" dangerouslySetInnerHTML={{__html:data?.Body}}></div>

          </div>
        </form>
      </div>
    </>
  );
};

export default ViewNotification;
