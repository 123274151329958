import { FilterMatchMode, FilterOperator } from "primereact/api";
import React, { useState } from "react";
import { NullHandler } from "../../common/NullHandler";
import { Action } from "../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../common/PageHeader";
import DatatableCommon from "../../common/DatatableCommon";
import moment from "moment";
import { Badge } from "primereact/badge";
import { DeleteModal } from "../../common/DeleteModal";
import AddEditDoctorAvailability from "./AddEditDoctorAvailability";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";

const DoctorAvailability = () => {
  const apiname = process.env.REACT_APP_DOCTORAVAILABILITY_API?.toString();

  const [bool, setbool]: any = useState(null);

  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedRows, setselectedRows]: any = useState([]);

  const user = localStorage.getItem("Roles");
  console.log(user, "user");

  const filterJson = {
    EmployeeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DoctorName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Action",
      body: (e: any) => actionTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      bodyClassName: "text-info h6",
      filter: true,
      body: null,
      sortable: true,
      filterPlaceholder: "Search by name",
      width: "10vw",
    },
    {
      field: "DoctorName",
      header: "Doctor Name",
      bodyClassName: "text-info h6",
      filter: true,
      body: null,
      sortable: true,
      filterPlaceholder: "Search by name",
      width: "10vw",
    },
    {
      field: "LeaveStartDate",
      header: "Leave Start Date",
      bodyClassName: "text-info h6",
      dataType: "date",
      body: (data: any) => {
        if (data.LeaveStartDate === null) {
          return "-";
        } else {
          return (
            <>
              <Badge
                className="m-1"
                severity={"success"}
                value={
                  <div
                    style={{
                      display: "inline-block",
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      verticalAlign: "middle",
                    }}
                    title={moment(data.LeaveStartDate).format("DD MMM, YYYY")}
                  >
                    {moment(data.LeaveStartDate).format("DD MMM, YYYY")}
                  </div>
                }
              ></Badge>
            </>
          );
        }
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "LeaveEndDate",
      header: "Leave End Date",
      bodyClassName: "text-info h6",
      dataType: "date",
      body: (data: any) => {
        if (data.LeaveEndDate === null) {
          return "-";
        } else {
          return (
            <>
              <Badge
                className="m-1"
                style={{ backgroundColor: "#978EEB" }}
                value={
                  <div
                    style={{
                      display: "inline-block",
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      verticalAlign: "middle",
                    }}
                    title={moment(data.LeaveEndDate).format("DD MMM, YYYY")}
                  >
                    {moment(data.LeaveEndDate).format("DD MMM, YYYY")}
                  </div>
                }
              ></Badge>
            </>
          );
        }
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "StatusName",
      header: "Status",
      sortable: true,
      width: "5vw",
      body: (e: any) => statusFunction(e),
    },
  ];

  const [updateId, setupdateId]: any = useState(null);

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {e.StatusName == "Approve" ? "Approved" : e.StatusName == "Reject" ? "Rejected" : e.StatusName}
      </span>
    );
  };

  const handle = async (params: any, pra: any) => {
    const data = {
      Id: params,
      Status: pra,
    };
    await axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORAVAILABILITYAPPROVAL_API}/${params}`,
        data
      )
      .then(() => {
        toast.success(
          pra === "Approve" ? `Approved` : `Rejected`,
          { position: "top-right" }
        );
        setbool(true);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const actionTemplate = (id: any) => {
    return (
      <>
        {id.StatusName != "Approve" && (
          <Action
            id={id.Id}
            editModal={() => {
              setupdateId(id.Id);
            }}
            displayid={setDeleteidtoDelete}
          />
        )}
      </>
    )
  };

  return (
    <div>
      <Helmet>
        <title>Doctor Availability</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Doctor Availability"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/tourprogram"}
          linkflagtitle={"Tour Program"}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          filterJson={filterJson}
          refetchData={bool}
          setrefetchData={setbool}
          selecteditem={selectedRows}
          setselecteditem={setselectedRows}
        />
        {updateId !== null && (
          <AddEditDoctorAvailability
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </div>
  );
};

export default DoctorAvailability;
