import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";
import { useEffect, useState } from "react";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";

export const TargetAchievement = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  }: any = useForm({ mode: "all" });

  const [isLoading, setIsLoading] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  // Product
  const [selectedproduct, setselectedproduct] = useState([]);

  // Division
  const [selecteddivision, setselecteddivision] = useState([]);

  // Zone
  const [selectedZone, setselectedZone] = useState([]);
  const zoneid = selectedZone?.map((e: any) => `id=${e?.value}`);

  // State
  const [selectedState, setselectedState] = useState([]);
  const stateid = selectedState?.map((e: any) => `id=${e?.value}`);

  // Region
  const [selectedrigion, setselectedrigion] = useState([]);
  const regionid = selectedrigion?.map((e: any) => `regionId=${e?.value}`);

  // Chemist
  const [selectedchemist, setselectedchemist]: any = useState([]);

  const handleDownload = async (data: any) => {
    const product = data.ProductId?.map((e: any) => `ProductId=${e?.value}`);
    const division = data.DivisionId?.map((e: any) => `DivisionIds=${e?.value}`);
    const Zone = data.ZoneId?.map((e: any) => `ZoneIds=${e?.value}`);
    const State = data.StateId?.map((e: any) => `StateIds=${e?.value}`);
    const Region = data.RegionId?.map((e: any) => `RegionIds=${e?.value}`);
    const chemist = data.ChemistId?.map((e: any) => `ChemistIds=${e?.value}`);

    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOWNLOADTARGETACHIEVEMENT_API}${product == undefined ? "" : `?${String(product)?.replaceAll(",", "&")}`}${division == undefined ? "" : `&${String(division)?.replaceAll(",", "&")}`}${Region == undefined ? "" : `&${String(Region)?.replaceAll(",", "&")}`}${State == undefined ? "" : `&${String(State)?.replaceAll(",", "&")}`}${Zone == undefined ? "" : `&${String(Zone)?.replaceAll(",", "&")}`}${chemist == undefined ? "" : `&${String(chemist)?.replaceAll(",", "&")}`}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "TargetAchievementReport.xlsx");
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
      })
      .catch((error) => {
        // toast.error(`${error.message}`, { position: "top-right" });
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Target Achievement</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Target Achievement</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Target Achievement
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              label="Product"

              name={"ProductId"}
              Multi
              onChange={(e: any) => {
                setselectedproduct(e);
                setValue("ProductId", e);
              }}
              value={selectedproduct}
              control={control}
              api={process.env.REACT_APP_PRODUCT_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ProductId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Division"

              name={"DivisionId"}
              Multi
              onChange={(e: any) => {
                setselecteddivision(e);
                setValue("DivisionId", e);
              }}
              value={selecteddivision}
              control={control}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.DivisionId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Zone"
              control={control}
              name="ZoneId"
              Multi
              onChange={(e: any) => {
                setselectedZone(e);
                setValue("ZoneId", e);
                setselectedState([]);
                setselectedrigion([]);
                setselectedchemist([]);
              }}
              value={selectedZone}
              api={process.env.REACT_APP_ZONE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ZoneId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="State"
              control={control}
              name="StateId"
              Multi
              onChange={(e: any) => {
                setselectedState(e);
                setValue("StateId", e);
                setselectedrigion([]);
                setselectedchemist([]);
              }}
              value={selectedState}
              api={`${process.env.REACT_APP_STATE_MULTIPLEZONE_API}?${String(
                zoneid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              Disabled={String(zoneid)?.replaceAll(",", "&") ? false : true}
              errorMessage={errors?.StateId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Region"
              control={control}
              name="RegionId"
              Multi
              onChange={(e: any) => {
                setselectedrigion(e);
                setValue("RegionId", e);
                setselectedchemist([]);
              }}
              value={selectedrigion}
              api={`${process.env.REACT_APP_REGION_MULTIPLESTATE_API}?${String(
                stateid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              Disabled={String(stateid)?.replaceAll(",", "&") ? false : true}
              errorMessage={errors?.RegionId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Chemist"
              control={control}
              name="ChemistId"
              Multi
              onChange={(e: any) => {
                setselectedchemist(e);
                setValue("ChemistId", e);
              }}
              value={selectedchemist}
              api={`${process.env.REACT_APP_CHEMISTBYREGION_API}?${String(regionid)?.replaceAll(
                ",",
                "&"
              )}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              Disabled={String(regionid)?.replaceAll(",", "&") ? false : true}
              errorMessage={errors?.ChemistId?.message}
            />
          </div>

          <div className="col-md-12 text-center mt-30">
            {!isLoading ? (
              <button className="btn btn-outline-primary" type="submit">
                Download
              </button>
            ) : (
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled
              >
                Download <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
