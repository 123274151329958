import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PaginationServiceOfSalesInvoice } from "../../../service/PaginationService";
import { Badge } from "primereact/badge";
import moment from "moment";
import { NullHandler } from "../../../common/NullHandler";

const SalesInvoice = () => {
  let navigate = useNavigate();

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);
  const [subexpandedRows, setSubExpandedRows]: any = useState(null);
  const [subpreviousId, setSubPreviousId]: any = useState(1);

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfSalesInvoice.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        {data?.ProductDetails?.length > 0 && (
          <Accordion>
            <AccordionTab header="Sales Detail">
              <DataTable value={data.ProductDetails} size="small">
                <Column field="ProductName" header="Product"></Column>
                <Column field="BatchNo" header="Batch"></Column>
                <Column field="Quantity" header="Qty"></Column>
                <Column field="FreeQuantity" header="Free Qty"></Column>
                <Column field="SalePrice" header="Sale Price"></Column>
                <Column field="MRP" header="MRP"></Column>
                <Column field="Discount" header="Discount %"></Column>
                <Column field="GSTPer" header="GST %"></Column>
                <Column field="Gstamount" header="GST Amt"></Column>
                <Column field="BillAmount" header="Bill Amt"></Column>
                <Column
                  field="ManufactureDate"
                  header="Mfg"
                  body={(data: any) => {
                    if (data.ManufactureDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"success"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="ExpiryDate"
                  header="Exp"
                  body={(data: any) => {
                    if (data.ExpiryDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"danger"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ExpiryDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}

        {data?.Payments?.length > 0 && (
          <Accordion>
            <AccordionTab header="Payment">
              <DataTable value={data.Payments} size="small">
                <Column
                  field="PaymentMethodName"
                  header="Payment Method"
                ></Column>
                <Column field="Amount" header="Amount"></Column>
                <Column
                  field="Notes"
                  header="Notes"
                  body={(data: any) => {
                    return (
                      <p
                        title={data.Notes}
                        style={{
                          width: "100px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {data.Notes === "" ? " - " : data.Notes}
                      </p>
                    );
                  }}
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}

        {data?.SalesReturnInvoiceViews?.length > 0 && (
          <Accordion>
            <AccordionTab header="Sales Return">
              <DataTable
                value={data.SalesReturnInvoiceViews}
                size="small"
                expandedRows={subexpandedRows}
                dataKey="Id"
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[subpreviousId];
                    setSubExpandedRows(expanded);
                  } else {
                    setSubExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setSubPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowSubExpansionTemplate}
              >
                <Column expander style={{ width: "2rem" }} />
                <Column
                  field="InvoiceNumber"
                  header="Invoice No"
                  body={(e: any) => {
                    return (
                      <Link
                        to={`/layout/viewinvoice/s/${e.InvoiceId}`}
                        target="_blank"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        {e.InvoiceNumber}
                      </Link>
                    );
                  }}
                ></Column>
                <Column field="ReturnTypeName" header="Return Type"></Column>
                <Column field="ExtraCharges" header="Extra Charges"></Column>
                <Column field="TotalAmount" header="Total Amt"></Column>
                <Column field="Discount" header="Discount %"></Column>
                <Column field="TotalDiscount" header="Total Discount"></Column>
                <Column field="TotalGstamount" header="Total GST Amt"></Column>
                <Column
                  field="TotalBillAmount"
                  header="Total Bill Amt"
                ></Column>
                <Column
                  field="CreatedDate"
                  header="Invoice Date"
                  body={(data: any) => {
                    if (data.CreatedDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="Note"
                  header="Note"
                  body={(data: any) => {
                    return (
                      <p
                        title={data.Note}
                        style={{
                          width: "100px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {data.Note === "" ? " - " : data.Note}
                      </p>
                    );
                  }}
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}
      </div>
    );
  };

  const rowSubExpansionTemplate = (data: any) => {
    return (
      <DataTable value={data.SalesReturnInvoiceDetails} size="small">
        <Column field="ProductName" header="Product"></Column>
        <Column field="BatchNo" header="Batch"></Column>
        <Column field="Quantity" header="Qty"></Column>
        <Column field="SalePrice" header="Sale Price"></Column>
        <Column field="MRP" header="MRP"></Column>
        <Column field="Discount" header="Discount %"></Column>
        <Column field="GSTPer" header="GST %"></Column>
        <Column field="Gstamount" header="GST Amt"></Column>
        <Column field="BillAmount" header="Bill Amt"></Column>
        <Column
          field="ManufactureDate"
          header="Mfg"
          body={(data: any) => {
            if (data.ManufactureDate === null) {
              return "-";
            } else {
              return (
                <>
                  <Badge
                    className="m-1"
                    severity={"success"}
                    value={
                      <div
                        style={{
                          display: "inline-block",
                          maxWidth: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: "middle",
                        }}
                        title={moment(data.ManufactureDate).format(
                          "DD MMM, YYYY"
                        )}
                      >
                        {moment(data.ManufactureDate).format("DD MMM, YYYY")}
                      </div>
                    }
                  ></Badge>
                </>
              );
            }
          }}
        ></Column>
        <Column
          field="ExpiryDate"
          header="Exp"
          body={(data: any) => {
            if (data.ExpiryDate === null) {
              return "-";
            } else {
              return (
                <>
                  <Badge
                    className="m-1"
                    severity={"danger"}
                    value={
                      <div
                        style={{
                          display: "inline-block",
                          maxWidth: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: "middle",
                        }}
                        title={moment(data.ExpiryDate).format("DD MMM, YYYY")}
                      >
                        {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                      </div>
                    }
                  ></Badge>
                </>
              );
            }
          }}
        ></Column>
      </DataTable>
    );
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const nameDisplay = (e: any) => {
    let chemist = e?.ChemistName == null ? "" : `Chemist : ${e?.ChemistName}`;
    let stockist =
      e?.StockistName == null ? "" : `Stockist : ${e?.StockistName}`;
    let doctor = e?.DoctorName == null ? "" : `Doctor : ${e?.DoctorName}`;
    let customer =
      e?.CustomerName == null ? "" : `Customer : ${e?.CustomerName}`;

    return chemist + "" + stockist + "" + doctor + "" + customer;
  };

  return (
    <div>
      <Helmet>
        <title>Sales Invoice</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Sales Invoice"
          list={true}
          LinkURL={"/layout/inventory/AddSalesInvoice"}
          linkflag
          linkflagURL={"/layout/Inventory"}
          linkflagtitle={"Inventory"}
        />

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column expander style={{ width: "2rem" }} />
                <Column field="SrNo" header="#" style={{ width: "3rem" }} />
                <Column
                  header="Name"
                  body={nameDisplay}
                ></Column>
                <Column
                  field="WarehouseName"
                  header="Warehouse"
                  sortable
                ></Column>
               
                <Column
                  field="InvoiceNumber"
                  header="Invoice No"
                  body={(e: any) => {
                    return (
                      <Link
                        to={`/layout/viewinvoice/s/${e.InvoiceId}`}
                        target="_blank"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        {e.InvoiceNumber}
                      </Link>
                    );
                  }}
                ></Column>
                <Column field="Discount" header="Discount %"></Column>
                <Column field="ExtraCharges" header="Extra Charges"></Column>
                <Column field="TotalAmount" header="Total Amt"></Column>
                <Column field="TotalDiscount" header="Total Discount"></Column>
                <Column field="TotalGstamount" header="Total GST Amt"></Column>
                <Column
                  field="TotalBillAmount"
                  header="Total Bill Amt"
                ></Column>
                <Column
                  field="CreatedDate"
                  header="Invoice Date"
                  body={(data: any) => {
                    if (data.CreatedDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="Note"
                  header="Note"
                  body={(data: any) => {
                    return (
                      <p
                        title={data.Note}
                        style={{
                          width: "100px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {data.Note === "" ? " - " : data.Note}
                      </p>
                    );
                  }}
                ></Column>
                <Column
                  header="Action"
                  body={(e: any) => {
                    return (
                      <div className="d-flex">
                        {e.IsReturnApplicable && (
                          <button
                            type="button"
                            className="btn p-0"
                            title="Sales Return"
                          >
                            <i
                              className="fa fa-exchange text-primary"
                              aria-hidden="true"
                              onClick={() => {
                                navigate(
                                  `/layout/inventory/AddSalesReturn/${e.Id}`
                                );
                              }}
                            ></i>
                          </button>
                        )}
                      </div>
                    );
                  }}
                  style={{ width: "3rem", textAlign: "center" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesInvoice;
