import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PaginationServiceByChemist,
  PaginationServiceByChemistServices,
} from "../../service/PaginationService";
import { toast } from "react-toastify";
import axiosInstance from "../../_helper/Interceptor";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import AddEditChemist from "../master/chemist/AddEditChemist";
import { Spinner } from "../../common/Spinner";
import AddProductMap from "./AddProductMap";
import { NullHandler } from "../../common/NullHandler";
import { Badge } from "primereact/badge";
import { Helmet } from "react-helmet";
import { Paginator } from "primereact/paginator";

const ChemistMap = () => {
  const controller = new AbortController();
  const [updateId, setupdateId]: any = useState(null);

  const [productId, setproductId]: any = useState(null);

  const [bool, setbool] = useState(false);

  const [totalRecord, setTotalRecords] = useState();
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });

  const [subLazyState, setSubLazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: "",
  });

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    }
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByChemist.getData(lazyState, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  useEffect(() => {
    if (subLazyState.paramter !== "") {
      loadsubLazyData();
    }
    return () => {
      controller.abort();
    }
  }, [subLazyState]);

  const [data, setData] = useState([{}]);
  const [subLoading, setSubLoading] = useState(false);
  const [subTotalRecord, setsubTotalRecord] = useState();

  const loadsubLazyData = () => {
    // 
    setSubLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByChemistServices.getData(subLazyState, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          setData(temp);
          setsubTotalRecord(data.TotalDataCount);
          setSubLoading(false);
        })
        .catch((error) => {
          if (controller.signal.reason.code !== 20) {

            setSubLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  function compareKeys(a: any, b: any) {
    // 
    var aKeys = Object.keys(a).sort();
    console.log(aKeys, "aKeys");
    return aKeys[0] == b;
  }

  const [expandedRows, setExpandedRows]: any = useState(null);
  const [subExpandedRows, setSubExpandedRows]: any = useState(null);

  const tempFunction = (rowData: any) => {
    return (
      <Badge
        style={{ cursor: "pointer" }}
        severity={
          rowData?.id ==
            (expandedRows !== null ? Object?.keys(expandedRows)[0] : "")
            ? "warning"
            : "info"
        }
        value={rowData.StockistName}
        onClick={() => {
          let obj: any = {};
          // 
          if (expandedRows != null && compareKeys(expandedRows, rowData.id)) {
            console.log("true");
            obj = {};
          } else {
            console.log("False");
            obj[rowData.id] = true;
          }
          setData([]);
          setSubExpandedRows(null);
          setSubLazyState({
            first: 0,
            rows: 10,
            page: 1,
            search: "",
            paramter: rowData.StockistName,
          });
          console.log(obj, "this is name data");
          // fetchData(rowData.StockistName);
          setExpandedRows(obj);
        }}
      ></Badge>
    );
  };

  const [isDataFound, setIsDataFound] = useState(false);
  const [defaultData, setDefaultData]: any = useState();
  const [state, setState]: any = useState({});
  const [errData, setErrData]: any = useState({});
  const [load, setLoad] = useState(false);

  const onSubmit = (e: any) => {
    // 
    setLoad(true);
    setSelectedProduct(null)
    axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP}MatchChemistNames`,
        e
      )
      .then((data: any) => {
        setLoad(false);

        let temp_data = data.data.data;

        const temp = temp_data?.suggestions.map((e: any, i: any) => {
          return { ...e, id: i + 1 };
        });

        console.log(temp, "this is final datra here");

        temp.push({
          id: 0,
          chemistId: 0,
          chemistName: "Other",
          doctorName: null,
          hospitalName: null,
          addressLine1: null,
          addressLine2: null,
          addressLine3: null,
          cityName: null,
          phoneNo: null,
          mobileNo: null,
          pincode: null,
          isActive: true,
        });

        const finaArray = {
          chemistName: temp_data.chemistName,
          stockistId: temp_data.stockistId,
          stockistName: temp_data.stockistName,
          suggestions: temp,
        };

        setState(finaArray);
        setIsDataFound(true);
      })
      .catch((err: any) => {
        setState({});
        setErrData(err.response.data.data);
        setLoad(false);
        setIsDataFound(false);
      });
  };

  const subTempFunction = (rowData: any) => {

    return (
      <Badge
        style={{ cursor: "pointer" }}
        severity={
          rowData?.id ==
            (subExpandedRows !== null ? Object?.keys(subExpandedRows)[0] : "")
            ? "warning"
            : "info"
        }
        value={rowData.chemistName}
        onClick={() => {
          setValue("DegreeTypeId", null);
          setselecteddegree(null);
          clearErrors(["DegreeTypeId"])
          // 
          setDefaultData(rowData);
          let obj: any = {};
          if (
            subExpandedRows != null &&
            compareKeys(subExpandedRows, rowData.id)
          ) {
            obj = {};
          } else {
            obj[rowData.id] = true;
            onSubmit(rowData);
          }
          console.log(rowData, "this is name data");

          setSubExpandedRows(obj);
        }}
      ></Badge>
    );
  };

  const onSubPageChange = (e: any) => {
    setSubExpandedRows({});
    setSubLazyState({
      ...subLazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    clearErrors
  }: any = useForm({ mode: "all" });

  console.log(errors, "this is error");

  const [selecteddegree, setselecteddegree]: any = useState(null);
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [productErrorData, setProductErrorData]: any = useState([]);

  const onUpdate = () => {

    // 
    console.log(errData, "errData");
    if (selectedProduct !== null || selecteddegree !== null) {
      const temp = {
        stockistId:
          state?.stockistId === undefined
            ? errData.stockistId
            : state?.stockistId,
        stockistName:
          state?.stockistName === undefined
            ? errData.stockistName
            : state?.stockistName,
        chemistName:
          state?.chemistName === undefined
            ? errData.chemistName
            : state?.chemistName,
        chemistId:
          selectedProduct?.chemistName === "Other" ||
            selectedProduct?.chemistId === undefined
            ? selecteddegree?.value?.value
            : selectedProduct?.chemistId,
      };
      axiosInstance
        .post(
          `${process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP}UpdateChemistName`,
          temp
        )
        .then((e) => {
          setSubExpandedRows({});
          loadsubLazyData();
          setselecteddegree({});
        })
        .catch((err) => {
          setproductId(0);
          setProductErrorData(err.response.data.data);
        });
    }
  };

  const subRowExpansionTemplate = () => {
    return (
      <>
        {!load ? (
          <div className="col-md-12 text-center">
            <h3>
              {!isDataFound
                ? "No Suggestions found Please Select Manually"
                : "Suggestions found"}
            </h3>

            {isDataFound && (

              <DataTable
                // paginator
                // rows={10}
                // rowsPerPageOptions={[10, 20, 50, 100]}
                value={state?.suggestions}
                selectionMode={"radiobutton"}
                selection={selectedProduct}
                onSelectionChange={(e) => {
                  console.log(e?.value, "onSelectionChange");
                  setSelectedProduct(e?.value);
                }}
                dataKey="id"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  selectionMode="single"
                  headerStyle={{ width: "3rem" }}
                ></Column>
                <Column field="chemistName" header="Chemist Name"></Column>
                <Column field="doctorName" header="Doctor Name"></Column>
                <Column field="hospitalName" header="Hospital Name"></Column>
                <Column field="phoneNo" header="Phone No"></Column>
                <Column field="mobileNo" header="Mobile No"></Column>
                <Column
                  header="Address"
                  body={(e: any) => {
                    return `${e.addressLine1 === null ? "" : `${e.addressLine1}, `
                      }${e.addressLine2 === null ? "" : `${e.addressLine2}, `}${e.addressLine3 === null ? "" : `${e.addressLine3}, `
                      }${e.cityName === null ? "" : `${e.cityName},`}${e.pincode === null ? "" : `${e.pincode}`
                      }`;
                  }}
                ></Column>
              </DataTable>

            )}

            {!isDataFound || selectedProduct?.chemistName === "Other" ? (
              <ReactSelect
                containerClass="col-lg-12"
                label="Chemist"
                control={control}
                refetch={bool}
                setRefecth={setbool}
                name="DegreeTypeId"
                isRequired
                onChange={(e: any) => {
                  console.log(e)
                  setValue("DegreeTypeId", e?.value);
                  setselecteddegree(e);
                }}
                rules={{
                  required: {
                    value: true,
                    message: "Please select Chmeist ",
                  },
                }}
                errorMessage={errors?.DegreeTypeId?.message}
                value={selecteddegree}
                api={process.env.REACT_APP_CHEMIST_API}
                keys={{ label: "Value", value: "Id" }}
              />
            ) : (
              ""
            )}

            <div className="mt-3">
              <button
                className="btn btn-primary"
                onClick={handleSubmit(onUpdate)}
              >
                Update
              </button>
              <button
                className="btn btn-secondary ml-2"
                onClick={() => {
                  setupdateId(0);
                }}
              >
                Create
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 text-center">
            <Spinner width={"5rem"} height={"5rem"} />
          </div>
        )}
      </>
    );
  };

  const rowExpansionTemplate = () => {
    return (
      <>

        <div className="p-3">
          <DataTable
            value={data}
            scrollable
            lazy
            dataKey="id"
            className="custom-datatable"
            showGridlines
            first={subLazyState.first}
            rows={subLazyState.rows}
            totalRecords={subTotalRecord}
            rowsPerPageOptions={[10, 20, 50, 100]}
            loading={subLoading}
            size="small"
            rowHover
            onPage={onSubPageChange}
            emptyMessage="No data found."
            tableStyle={{ minWidth: "50rem" }}
            stripedRows
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            expandedRows={subExpandedRows}
            rowExpansionTemplate={subRowExpansionTemplate}
          >
            <Column field="SrNo" header="#" />
            <Column
              key={"ChemistName"}
              field="chemistName"
              header="Chemist Name"
              body={subTempFunction}
            ></Column>
            <Column
              key={"StockistName"}
              field="stockistName"
              header="Stockist Name"
            ></Column>
          </DataTable>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Chemist Map</title>
      </Helmet>
      <div className="min-height-200px">
        <DataTable
          value={totalData}
          scrollable
          lazy
          dataKey="id"
          showGridlines
          className="custom-datatable"
          stripedRows
          paginator
          removableSort
          first={lazyState.first}
          rows={lazyState.rows}
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRecords={totalRecord}
          onPage={onPageChange}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          loading={loading}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          size="small"
          rowHover
          emptyMessage="No data found."
          tableStyle={{ minWidth: "50rem" }}
          expandedRows={expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column field="SrNo" header="#" />
          <Column
            key={"StockistName"}
            field="StockistName"
            header="Stockist Name"
            body={tempFunction}
          ></Column>
          <Column
            key={"CityName"}
            field="CityName"
            header="City Name"
            body={(e) => {
              return NullHandler(e.CityName);
            }}
          ></Column>
          <Column
            key={"EmployeeName"}
            field="EmployeeName"
            header="Employee Name"
            body={(e) => {
              return NullHandler(e.EmployeeName);
            }}
          ></Column>
          <Column
            key={"ContactPerson"}
            field="ContactPerson"
            header="Contact Person Name"
            body={(e) => {
              return NullHandler(e.ContactPerson);
            }}
          ></Column>
        </DataTable>
      </div>

      {updateId !== null && (
        <AddEditChemist
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
          defaultValue={defaultData?.chemistName}
          // cityDrop
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          id={updateId}
        />
      )}

      {productId !== null && (
        <AddProductMap
          data={productErrorData}
          setisOPen={() => {
            return setproductId(null);
          }}
          fetch={(data: boolean) => {
            // 
            console.log(data);
            onUpdate();
          }}
          open={productId !== null}
        />
      )}
    </>
  );
};

export default ChemistMap;
