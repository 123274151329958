import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

const AddEditSpecialityModal = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_SPECIALITY_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: specialityLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (defaultValue) {
      setValue("SpecialityName", defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        let editSpecialityQulificationModel =
          data?.specialityQulificationModel?.map((d: any) => ({
            label: d?.QualificationName,
            value: d?.QualificationId,
          }));
        setValue("Qualifications", editSpecialityQulificationModel);
        setselectedqualification(editSpecialityQulificationModel);
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedqualification, setselectedqualification] = useState([]);

  const specialityFormData = (data: any) => {
    data.Qualifications = selectedqualification?.map((e: any) => {
      return e?.value;
    });
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Speciality`}
        modal
        style={{ width: "750px" }}
      >
        {!specialityLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(specialityFormData);
              }
            }}
            onSubmit={handleSubmit(specialityFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-12"
                label="Name"
                type="text"
                isRequired
                onChange={(e: any) => { setValue("SpecialityName", e.target.value); clearErrors(["SpecialityName"]) }}
                register={{
                  ...register("SpecialityName", {
                    required: {
                      value: true,
                      message: "Speciality Name is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid speciality name (1-50 alphabet characters or special characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.SpecialityName?.message ||
                  error?.response?.data?.errors?.SpecialityName?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-12"
                label="Remarks"
                type="text"
                register={{
                  ...register("Remarks"),
                }}
                maxLength={500}
                onChange={(e: any) => { setValue("Remarks", e.target.value); clearErrors(["Remarks"]) }}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-12"
                label={"Map Qualifications"}
                isRequired

                name={"Qualifications"}
                onChange={(e: any) => {
                  setValue("Qualifications", e);
                  setselectedqualification(e);
                }}
                value={selectedqualification}
                control={control}
                api={process.env.REACT_APP_QUALIFICATION_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.Qualifications?.message ||
                  error?.response?.data?.errors?.Qualifications?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Qualifications is required",
                  },
                }}
                Multi
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedqualification([]); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditSpecialityModal;
