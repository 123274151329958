import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Layout } from "./Layout/Layout";
import { Auth, Loginauth } from "./routes/Auth";
import { Login } from "./pages/Login/Login";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ForgetPassword from "./pages/resetPassword/ForgetPassword";
import RedirectPage from "./pages/resetPassword/RedirectPage";
import { PrivacyPolicy } from "./pages/resetPassword/PrivacyPolicy";
import { createContext, useState } from "react";

const queryclient = new QueryClient();

const LoaderContext = createContext({ loading: false, setloader: (value: any) => { } });
function App() {
  const [loading, setloading] = useState(false);
  return (
    <>
      <QueryClientProvider client={queryclient}>
        <BrowserRouter>
          <LoaderContext.Provider value={{ loading: loading, setloader: (value: any) => { setloading(value) } }}>
            <Routes>
              <Route path="/" element={<Loginauth />}>
                <Route path="/" element={<Login />} />
              </Route>
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/thanks" element={<RedirectPage />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/resetPassword/:id" element={<ResetPassword />} />
              <Route path="/" element={<Auth />}>
                <Route path="/layout/*" element={<Layout />} />
              </Route>
            </Routes>
          </LoaderContext.Provider>
        </BrowserRouter>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}

export default App;
export { LoaderContext }

