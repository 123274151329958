import { useEffect } from "react";
import { Input } from "../../common/Input";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../hooks/Api/Api";
import { Modal } from "react-bootstrap";
import ButtonCommon from "../../common/ButtonCommon";
import { Spinner } from "../../common/Spinner";
import { useChangePassword } from "../../hooks/login/useLoginData";

interface iPropsmodal {
    setisOPen?: any;
    open?: any;
}

const ChangePassword = ({
    setisOPen,
    open
}: iPropsmodal) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        clearErrors,
        reset
    }: any = useForm({ mode: "all" });

    const handleClose = () => {
        if (open == true) {
            setisOPen(false);
        }
    };

    const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
    }: any = useChangePassword();

    const onChange = (e: any) => {
        return true;
    };

    const changePassworddata = (data: any) => {
        debugger
        console.log(data)
        // let newData: any = {}
        // Object.keys(data).forEach(k => newData[k] = typeof data[k] == 'string' ? data[k].trim() : data[k]);
        mutate(data);
    };

    // Success & Error Messages
    useEffect(() => {
        if (isSuccess) {
            toast.success("Password Changed Successfull", { position: "top-right" });
            setisOPen(false);
        }

        if (isError) {
            if (error?.response?.data?.StatusCode !== 400) {
                toast.error(error?.response?.data?.Message, { position: "top-right" });
            } else {
                toast.error("error...", { position: "top-right" });
            }
        }
    }, [isError, isSuccess]);

    return (
        <>
            <Modal
                show={open}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                key={"ChangePassword"}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Change Password{" "}
                    </h5>
                    <button type="button" className="close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="pd-20 card-box mb-30">
                    <form
                        onSubmit={handleSubmit((info: any) => {
                            changePassworddata(info);
                        })}
                    >
                        <div className="row">
                            <Input
                                containerClass="col-lg-12"
                                label="Current Password"
                                isRequired
                                type="password"
                                register={{
                                    ...register("CurrentPassword", {
                                        required: {
                                            value: true,
                                            message: "Current Password is required",
                                        }
                                    }),
                                }}
                                onChange={() => clearErrors(["CurrentPassword"])}
                                errorMessage={errors?.CurrentPassword?.message || error?.response?.data?.errors?.CurrentPassword?.map((e: any) => e)}
                            />

                            <Input
                                containerClass="col-lg-12"
                                label="New Password"
                                isRequired
                                type="password"
                                register={{
                                    ...register("NewPassword", {
                                        required: {
                                            value: true,
                                            message: "New Password is required",
                                        },
                                        onChange,
                                    }),
                                }}
                                onChange={() => clearErrors(["NewPassword"])}
                                errorMessage={errors?.NewPassword?.message || error?.response?.data?.errors?.NewPassword?.map((e: any) => e)}
                            />

                            <Input
                                containerClass="col-lg-12"
                                label="Conform Password"
                                isRequired
                                type="password"
                                register={{
                                    ...register("ConfirmPassword", {
                                        required: {
                                            value: true,
                                            message: "Conform Password is required",
                                        }
                                    }),
                                }}
                                onChange={() => clearErrors(["ConfirmPassword"])}
                                errorMessage={errors?.ConfirmPassword?.message || error?.response?.data?.errors?.ConfirmPassword?.map((e: any) => e)}
                            />


                            <div className="col-lg-12 text-center">
                                <button type="button" className="btn btn-outline-secondary mr-2" onClick={handleClose}>
                                    Cancel
                                </button>
                                {isLoading ? (
                                    <button
                                        type="submit"
                                        className="btn btn-outline-primary"
                                        disabled
                                    >
                                        Save <Spinner width={"1rem"} height={"1rem"} />
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-outline-primary">
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default ChangePassword
