import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import AddEditCFAModal from "./AddEditCFAModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { NullHandler } from "../../../common/NullHandler";


export const CFA = () => {

  const apiname = process.env.REACT_APP_CFA_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [selectedCFA, setSelectedCFA]: any = useState([]);
  const multi = selectedCFA?.map((e: any) => { return e.CfaId })

  const filterJson = {
    Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Place: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    ContactPerson: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Address1: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Address2: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Address3: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    PinCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    State: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    MobileNo1: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    MobileNo2: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DLno1: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DLno2: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Gst: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Fssai: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    NRX: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Other: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      body: (e: any) => actionCFATemplate(e),
      width: "7rem",
    },
    {
      field: "Name",
      header: "Name",
      bodyClassName: "text-info h6",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by name",
      body: null,
      width: "9rem",
    },
    {
      field: "Place",
      header: "Place",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by place",
      body: (data: any) => {
        return NullHandler(data.Place);
      },
      width: "7rem",
    },
    {
      field: "ContactPerson",
      header: "Contact Person",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by contact person",
      body: (data: any) => {
        return NullHandler(data.ContactPerson);
      },
      width: "12rem",
    },
    {
      field: "MobileNo1",
      header: "Mobile No.1",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by mobile no.1",
      body: (data: any) => {
        return NullHandler(data.MobileNo1);
      },
      width: "12rem",
    },
    {
      field: "MobileNo2",
      header: "Mobile No.2",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by mobile no.2",
      body: (data: any) => {
        return NullHandler(data.MobileNo2);
      },
      width: "11rem",
    },
    {
      field: "Phone",
      header: "Phone No",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by phone no.",
      body: (data: any) => {
        return NullHandler(data.Phone);
      },
      width: "11rem",
    },
    {
      field: "Email",
      header: "Email",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by email",
      body: (data: any) => {
        return NullHandler(data.Email);
      },
      width: "10rem",
    },
    {
      field: "Address1",
      header: "Address 1",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by address1",
      body: (data: any) => {
        return NullHandler(data.Address1);
      },
      width: "11rem",
    },
    {
      field: "Address2",
      header: "Address 2",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by address2",
      body: (data: any) => {
        return NullHandler(data.Address2);
      },
      width: "11rem",
    },

    {
      field: "State",
      header: "State",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by state",
      body: (data: any) => {
        return NullHandler(data.State);
      },
      width: "7rem",
    },
    {
      field: "PinCode",
      header: "Pincode",
      filter: true,
      sortable: true,
      filterPlaceholder: "Search by pincode",
      body: (data: any) => {
        return NullHandler(data.PinCode);
      },
      width: "7rem",
    },

    // {
    //   field: "DLno1",
    //   header: "DLNo1",
    //   filter: true,
    //   sortable: true,
    //   filterPlaceholder:"Search by dlno1",
    //   body: null,
    //   width: "7rem",
    // },
    // {
    //   field: "DLno2",
    //   header: "DLNo2",
    //   filter: true,
    //   sortable: true,
    //   filterPlaceholder:"Search by dlno2",
    //   body: null,
    //   width: "7rem",
    // },
    // {
    //   field: "Gst",
    //   header: "GST",
    //   filter: true,
    //   sortable: true,
    //   filterPlaceholder:"Search by gst",
    //   body: null,
    //   width: "7rem",
    // },
    // {
    //   field: "Fssai",
    //   header: "Fssai",
    //   filter: true,
    //   sortable: true,
    //   filterPlaceholder:"Search by fssai",
    //   body: null,
    //   width: "7rem",
    // },
    // {
    //   field: "NRX",
    //   header: "NRX",
    //   filter: true,
    //   sortable: true,
    //   filterPlaceholder:"Search by nrx",
    //   body: null,
    //   width: "7rem",
    // },
    // {
    //   field: "Other",
    //   header: "Other",
    //   filter: true,
    //   sortable: true,
    //   filterPlaceholder:"Search by other",
    //   body: null,
    //   width: "7rem",
    // },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionCFATemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.CfaId}
          editModal={() => {
            setupdateId(id.CfaId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>CFA</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="CFA"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          filterJson={filterJson}
          multiDelete={(data: boolean) => { if (data) { setdeleteID(selectedCFA?.length === 0 ? null : multi) } }}
          setselecteditem={setSelectedCFA}
          selecteditem={selectedCFA}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditCFAModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true) } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
