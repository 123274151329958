import { AddEditDoctor } from "../pages/doctor/AddEditDoctor";
import { Doctor } from "../pages/doctor/Doctor";
import { DoctorDetails } from "../pages/doctor/subComponents/DoctorDetails";
import { AddEditCFA } from "../pages/master/CFA/AddEditCFA";
import { CFA } from "../pages/master/CFA/CFA";
import { Category } from "../pages/master/category/Category";
import ChemistCategory from "../pages/master/chemistCategory/ChemistCategory";
import CompetitorMst from "../pages/master/competitorMst/CompetitorMst";
import GiftMaster from "../pages/master/giftMaster/GiftMaster";
import Holiday from "../pages/master/holiday/Holiday";
import Qualification from "../pages/master/qualification/Qualification";
import AddEditSpeciality from "../pages/master/speciality/AddEditSpeciality";
import Speciality from "../pages/master/speciality/Speciality";
import Stockiest from "../pages/master/stockiest/Stockiest";
import AddEditProduct from "../pages/product/AddEditProduct";
import Product from "../pages/product/Product";
import DrugHead from "../pages/product/productMasters/drugHead/DrugHead";
import ProductClass from "../pages/product/productMasters/productClass/ProductClass";
import ProductGroup from "../pages/product/productMasters/productGroup/ProductGroup";
import ProductLine from "../pages/product/productMasters/productLine/ProductLine";
import ProductType from "../pages/product/productMasters/productType/ProductType";
import { Header } from "./subComponents/Header";
import { Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import DoctorView from "../pages/doctor/subComponents/DoctorView";
import { Leave } from "../pages/mr/leave/Leave";
import BirthdayAnniversary from "../pages/mr/birthday&anniversary/BirthdayAnniversary";
import Expense from "../pages/mr/expense/Expense";
import RCPA from "../pages/mr/rcpa/RCPA";
import { TourPlan } from "../pages/mr/tourPlan/TourPlan";
import DCR from "../pages/mr/DCR/DCR";
import DoctorModel from "../pages/mr/DCR/DoctorModel";
import ChmesitModel from "../pages/mr/DCR/ChmesitModel";
import HospitalModel from "../pages/mr/DCR/HospitalModel";
import ShowTourPlan from "../pages/mr/admin/ShowTourPlan";
import { Calender } from "../pages/mr/tourPlan/Calender";
import ViewTourPlan from "../pages/mr/tourPlan/ViewTourPlan";
import AdminLeave from "../pages/mr/admin/AdminLeave";
import { AdminRoute, MrManagerRoute, MRRoute } from "../routes/Auth";
import { PrimarySale } from "../pages/upload/primarySale/PrimarySale";
import { SecondarySale } from "../pages/upload/secondarySale/SecondarySale";
import { PrimaryVsSecondary } from "../pages/report/PrimaryVsSecondary";
import { MR } from "../pages/mr/MR";
import { AddEditMR } from "../pages/mr/AddEditMR";
import { Geography } from "../pages/master/geography/Geography";
import { Country } from "../pages/master/geography/country/country";
import { Zone } from "../pages/master/geography/zone/Zone";
import { State } from "../pages/master/geography/state/State";
import { Region } from "../pages/master/geography/region/Region";
import District from "../pages/master/geography/district/District";
import City from "../pages/master/geography/city/City";
import Pincode from "../pages/master/geography/pincode/Pincode";
import PackingType from "../pages/product/productMasters/packingType/PackingType";
import AchievementType from "../pages/master/submaster/achievementType/AchievementType";
import CallObjective from "../pages/master/submaster/callObjective/CallObjective";
import { CallType } from "../pages/master/submaster/callType/CallType";
import { ChemistContactPerson } from "../pages/master/submaster/chemistContactPerson/ChemistContactPerson";
import { ConferenceAndWebinarAttendedType } from "../pages/master/submaster/conferenceAndWebinarAttendedType/ConferenceAndWebinarAttendedType";
import { ConsultingFeeType } from "../pages/master/submaster/consultingFeeType/ConsultingFeeType";
import { DoctorAreaOfInterestType } from "../pages/master/submaster/doctorAreaOfInterestType/DoctorAreaOfInterestType";
import { ExpenseType } from "../pages/master/submaster/expenseType/ExpenseType";
import { AdminDCR } from "../pages/mr/admin/AdminDCR";
import { HospitalContactPerson } from "../pages/master/submaster/hospitalContactPerson/HospitalContactPerson";
import { Hospital } from "../pages/master/submaster/hospital/Hospital";
import HospitalRoleType from "../pages/master/submaster/hospitalRoleType/HospitalRoleType";
import LeaveType from "../pages/master/submaster/leaveType/LeaveType";
import { PriorityType } from "../pages/master/submaster/priorityType/PriorityType";
import { RecursiveType } from "../pages/master/submaster/recursiveType/RecursiveType";
import { RelationType } from "../pages/master/submaster/relationType/RelationType";
import { ConferenceAndWebinarSubjectType } from "../pages/master/submaster/conferenceAndWebinarSubjectType/ConferenceAndWebinarSubjectType";
import { DoctorDegreeType } from "../pages/master/submaster/doctorDegreeType/DoctorDegreeType";
import { DoctorGuidelinesType } from "../pages/master/submaster/doctorGuidelinesType/DoctorGuidelinesType";
import { EntertainmentActivityType } from "../pages/master/submaster/entertainmentActivityType/EntertainmentActivityType";
import { EntertainmentType } from "../pages/master/submaster/entertainmentType/EntertainmentType";
import { Hq } from "../pages/master/submaster/hq/Hq";
import StockistType from "../pages/master/submaster/stockistType/StockistType";
import Division from "../pages/master/submaster/division/Division";
import EventPartnerType from "../pages/master/submaster/eventPartnerType/EventPartnerType";
import PTRPTSSetting from "../pages/product/productMasters/ptrptssetting/PTRPTSSetting";
import CompetitorProduct from "../pages/master/competitorProduct/CompetitorProduct";
import AddEditCompetitorProduct from "../pages/master/competitorProduct/AddEditCompetitorProduct";
import Chemist from "../pages/master/chemist/Chemist";
import AddSRC from "../pages/master/SRC/AddSRC";
import { AdminExpense } from "../pages/mr/admin/AdminExpense";
import { SRC } from "../pages/master/SRC/SRC";
import { TargetSale } from "../pages/upload/targetSale/TargetSale";
import { CRM } from "../pages/report/CRM";
import { PartyWiseReport } from "../pages/report/PartyWiseReport";
import { SecondaryReport } from "../pages/report/SecondaryReport";
import { Account } from "../pages/mr/account/Account";
import DirectClubGroup from "../pages/master/submaster/directClubGroup/DirectClubGroup";
import { AccountList } from "../pages/mr/account/AccountList";
import CRMAccount from "../pages/crm/account/CRMAccount";
import AddEditCRMAccount from "../pages/crm/account/AddEditCRMAccount";
import CRMReport from "../pages/crm/report/CRMReport";
import AddCredit from "../pages/crm/report/AddCredit";
import ViewReport from "../pages/crm/report/ViewReport";
import CreditType from "../pages/master/submaster/creditType/CreditType";
import Credit from "../pages/crm/report/Credit";
import VendorServiceType from "../pages/master/submaster/vendorServiceType/VendorServiceType";
import AddEditVAUpload from "../pages/master/visualAid/AddEditVAUpload";
import VAUpload from "../pages/master/visualAid/VAUpload";
import MediaType from "../pages/master/submaster/mediaType/MediaType";
import { ChemistType } from "../pages/master/submaster/chemistType/ChemistType";
import { AdminCRM } from "../pages/report/AdminCRm";
import { AttendedAs } from "../pages/master/submaster/attendedAs/AttendedAs";
import { Presentation } from "../pages/master/visualAid/subcomponent/presentation/Presentation";
import { Permission } from "../pages/admin/permission/Permission";
import { AddEditPermission } from "../pages/admin/permission/AddEditPermission";
import { RolePermission } from "../pages/admin/rolePermission/RolePermission";
import { AddEditRolePermission } from "../pages/admin/rolePermission/AddEditRolePermission";
import { PermissionAction } from "../pages/admin/permissionAction/PermissionAction";
import { AddEditPermissionAction } from "../pages/admin/permissionAction/AddEditPermissionAction";
import { TargetAchievement } from "../pages/report/TargetAchievement";
import { AdminDailyPlan } from "../pages/mr/admin/AdminDailyPlan";
import { CommonModel } from "../pages/mr/DCR/CommonModel";
import { ChemistVisit } from "../pages/mr/DCR/ChemistVisit";
import { StockistMap } from "../pages/master/stockistMap/StockistMap";
import { DoctorChemistMap } from "../pages/master/doctorChemistMap/DoctorChemistMap";
import { DoctorProductMap } from "../pages/master/doctorProductMap/DoctorProductMap";
import { Announcement } from "../pages/master/announcement/Announcement";
import { AddEditAnnouncement } from "../pages/master/announcement/AddEditAnnouncement";
import { VisitUpload } from "../pages/upload/visit Upload/VisitUpload";
import { ChemistEmployeeMap } from "../pages/master/chemistEmployeeMap/ChemistEmployeeMap";
import { DeviceType } from "../pages/master/submaster/deviceType/DeviceType";
import { DeviceDetail } from "../pages/master/deviceDetail/DeviceDetail";
import { EmployeeDeviceMap } from "../pages/master/employeeDeviceMap/EmployeeDeviceMap";
import AdminLockUnlock from "../pages/mr/admin/AdminLockUnlock";
import { GiftSampleUpload } from "../pages/upload/giftSampleUpload/GiftSampleUpload";
import { GiftUpload } from "../pages/upload/giftUpload/GiftUpload";
import { SampleUpload } from "../pages/upload/sampleUpload/SampleUpload";
import Email from "../pages/email/Email";
import ViewNotification from "../pages/notification/ViewNotification";
import Notification from "../pages/notification/Notification";
import { SRCUpload } from "../pages/upload/srcUpload/SRCUpload";
import EmailDetail from "../pages/email/subComponents/EmailDetail";
import SendDetail from "../pages/email/subComponents/SendDetail";
import DraftDetail from "../pages/email/subComponents/DraftDetail";
import { Vendor } from "../pages/vendor/Vendor";
import TravelAllowance from "../pages/master/allowances/travelAllowance/TravelAllowance";
import MonthlyAllowance from "../pages/master/allowances/monthlyAllowance/MonthlyAllowance";
import DailyAllowance from "../pages/master/allowances/dailyAllowance/DailyAllowance";
import NoWorkingAllowance from "../pages/master/allowances/noWorkingAllowance/NoWorkingAllowance";
import TravellingAllowance from "../pages/upload/travellingAllowance/TravellingAllowance";
import MonthlyAllowanceUpload from "../pages/upload/monthlyAllowance/MonthlyAllowanceUpload";
import DailyAllowanceUpload from "../pages/upload/dailyAllowanceUpload/DailyAllowanceUpload";
import NoWorkingAllowanceUpload from "../pages/upload/noWorkingAllowanceUpload/NoWorkingAllowanceUpload";
import { CalculationType } from "../pages/master/submaster/calculationType/CalculationType";
import { AllowanceType } from "../pages/master/submaster/allowanceType/AllowanceType";
import { HqType } from "../pages/master/submaster/hqType/HqType";
import { ThreePReport } from "../pages/report/ThreePReport";
import ChemistMap from "../pages/chemist/ChemistMap";
import { SalarySlip } from "../pages/SalarySlip";
import VisitReport from "../pages/report/VisitReport";
import PartyWiseSale from "../pages/upload/FileUpload/PartyWiseSale";
import StockistSale from "../pages/upload/FileUpload/StockistSale";
import Event from "../pages/eventManagement/Event/Event";
import Contact from "../pages/eventManagement/Contact/Contact";
import Attendee from "../pages/eventManagement/Attendee/Attendee";
import JWRObservation from "../pages/eventManagement/JWRObservation/JWRObservation";
import EventType from "../pages/eventManagement/EventType/EventType";
import { AttendeeType } from "../pages/eventManagement/AttendeeType/AttendeeType";
import { AttendanceStatus } from "../pages/eventManagement/AttendanceStatus/AttendanceStatus";
import NwDays from "../pages/eventManagement/NwDays/NwDays";
import ProductMap from "../pages/product/ProductMap";
import { EventAttendance } from "../pages/eventManagement/EventAttendance/EventAttendance";
import JWRObservationReport from "../pages/report/JWRObservation";
import CRMReportGrid from "../pages/report/CRMReport";
import { AddEditEmployeeJWRObservation } from "../pages/eventManagement/EmployeeJWRObservation/AddEditEmployeeJWRObservation";
import { Patient } from "../pages/eventManagement/Patient/Patient";
import { EventsEmployeeMap } from "../pages/eventManagement/EventsEmployeeMap/EventsEmployeeMap";
import AdminTourPlan from "../pages/report/AdminTourPlan";
import { UpdateSecondaryData } from "../pages/report/UpdateSecondaryData";
import { UpdatePrimaryData } from "../pages/report/UpdatePrimaryData";
import { RouteEmployeeMapping } from "../pages/master/routeEmployeeMapping/RouteEmployeeMapping";
import { TargetUpload } from "../pages/upload/targetUpload/TargetUpload";
import DailyTourPlan from "../pages/mr/dailyTourPlan/DailyTourPlan";
import AddEditDailyTourPlan from "../pages/mr/dailyTourPlan/AddEditDailyTourPlan";
import DetailDashboard from "../pages/dashboard/DetailDashboard";
import DoctorVisitCountReport from "../pages/report/DoctorVisitCountReport";
import { NotFound } from "../pages/NotFound";
import { PrivacyPolicy } from "../pages/resetPassword/PrivacyPolicy";
import LeaveCalendar from "../pages/mr/leaveCalendar/LeaveCalendar";
import ManageCredit from "../pages/crm/manageCredit/ManageCredit";
import AddEditManageCredit from "../pages/crm/manageCredit/AddEditManageCredit";
import MainDashboard from "../pages/dashboard/MainDashboard";
import { Mapping } from "../pages/Mapping";
import { Approvals } from "../pages/Approvals";
import { AdminPanel } from "../pages/AdminPanel";
import { TourProgram } from "../pages/TourProgram";
import { Reports } from "../pages/Reports";
import { Attendance } from "../pages/Attendance";
import { TodayActivity } from "../pages/TodayActivity";
import { SideBar2 } from "./subComponents/SideBar2";
import { AllGeography } from "../pages/geography/Geography";
import { Attributes } from "../pages/Attributes";
import { DoctorAttributes } from "../pages/DoctorAttributes";
import { ChemistAttributes } from "../pages/ChemistAttributes";
import { HospitalAttribute } from "../pages/HospitalAttribute";
import { StockistAttribute } from "../pages/StockistAttribute";
import { ProductAttribute } from "../pages/ProductAttribute";
import { EmployeeAttribute } from "../pages/EmployeeAttribute";
import { EventAttribute } from "../pages/EventAttribute";
import InventoryVendor from "../pages/Inventory/Vendor/InventoryVendor";
import InventoryCurrency from "../pages/Inventory/Currency/InventoryCurrency";
import HSNType from "../pages/Inventory/HSNType/HSNType";
import InventoryPaymentMethod from "../pages/Inventory/PaymentMethod/InventoryPaymentMethod";
import { Customer } from "../pages/Inventory/Customer/Customer";
import { Company } from "../pages/Inventory/Company/Company";
import { WareHouse } from "../pages/Inventory/WareHouse/WareHouse";
import { Hsn } from "../pages/Inventory/Hsn/Hsn";
import { ReturnType } from "../pages/Inventory/ReturnType/ReturnType";
import { PaymentType } from "../pages/Inventory/PaymentType/PaymentType";
import { InvoiceType } from "../pages/Inventory/InvoiceType/InvoiceType";
import { SampleGrid } from "../pages/upload/sampleUpload/SampleGrid";
import { GiftGrid } from "../pages/upload/giftUpload/GiftGrid";
import SecondaryProductMap from "../pages/product/SecondaryProductMap";
import ChemistVisitRCPAReport from "../pages/report/ChemistVisitRCPAReport";
import DoctorVisitFrequencyReport from "../pages/report/DoctorVisitFrequencyReport";
import { useContext } from "react";
import { LoaderContext } from "../App";
import { Spinner } from "../common/Spinner";
import DoctorVisitCoverageReport from "../pages/report/DoctorVisitCoverageReport";
import ApprovalForDeleted from "../pages/approvalForDeleted/ApprovalForDeleted";
import ApprovalForDeletedChemist from "../pages/approvalForDeleted/ApprovalForDeletedChemist";
import ApprovalForDeletedStockist from "../pages/approvalForDeleted/ApprovalForDeletedStockist";
import PurchaseInvoice from "../pages/Inventory/PurchaseInvoice/PurchaseInvoice";
import ChemistVisitFrequencyReport from "../pages/report/ChemistVisitFrequencyReport";
import LateDCRSubmissionReport from "../pages/report/LateDCRSubmissionReport";
import DoctorAvailability from "../pages/doctorAvailability/DoctorAvailability";
import AdminDoctorAvailibilty from "../pages/mr/admin/AdminDoctorAvailibilty";
import AddEditPurchaseInvoice from "../pages/Inventory/PurchaseInvoice/AddEditPurchaseInvoice";
import ImageSelector from "../pages/Inventory/PurchaseInvoice/TestQRDemo";
import SalesInvoice from "../pages/Inventory/SalesInvoice/SalesInvoice";
import AddEditSalesInvoice from "../pages/Inventory/SalesInvoice/AddEditSalesInvoice";
import PaymentPurchaseInvoice from "../pages/Inventory/PaymentPurchaseInvoice/PaymentPurchaseInvoice";
import AddPaymentPurchaseInvoice from "../pages/Inventory/PaymentPurchaseInvoice/AddPaymentPurchaseInvoice";
import PaymentSalesInvoice from "../pages/Inventory/PaymentSalesInvoice/PaymentSalesInvoice";
import AddPaymentSalesInvoice from "../pages/Inventory/PaymentSalesInvoice/AddPaymentSalesInvoice";
import PaymentReturnInvoice from "../pages/Inventory/PaymentReturnInvoice/PaymentReturnInvoice";
import AddPaymentReturnInvoice from "../pages/Inventory/PaymentReturnInvoice/AddPaymentReturnInvoice";
import SalesReturnInvoice from "../pages/Inventory/SalesReturnInvoice/SalesReturnInvoice";
import AddSalesReturnInvoice from "../pages/Inventory/SalesReturnInvoice/AddSalesReturnInvoice";
import PurchaseReturnInvoice from "../pages/Inventory/PurchaseReturnInvoice/PurchaseReturnInvoice";
import AddPurchaseReturnInvoice from "../pages/Inventory/PurchaseReturnInvoice/AddPurchaseReturnInvoice";
import AddPaymentInvoice from "../pages/Inventory/PaymentInvoice/AddPaymentInvoice";
import PaymentInvoice from "../pages/Inventory/PaymentInvoice/PaymentInvoice";
import WarehouseStockTransfer from "../pages/Inventory/WarehouseStockTransfer/WarehouseStockTransfer";
import AddWarehouseStockTransfer from "../pages/Inventory/WarehouseStockTransfer/AddWarehouseStockTransfer";
import VendorCreditNote from "../pages/Inventory/VendorCreditNote/VendorCreditNote";
import CustomerCreditNote from "../pages/Inventory/CustomerCreditNote/CustomerCreditNote";
import Invoice from "../pages/Inventory/Invoice/Invoice";
import InvoiceView from "../pages/Inventory/InvoiceView";
import GetAccountsInvoice from "../pages/Inventory/Invoice/GetAccountsInvoice";
import InventoryAttribute from "../pages/InventoryAttribute";
import { Inventory } from "../pages/Inventory";
import { InventoryMaster } from "../pages/InventoryMaster";
import WarehouseStock from "../pages/Inventory/WarehouseStock/WarehouseStock";
import AddVendorCreditNote from "../pages/Inventory/VendorCreditNote/AddVendorCreditNote";
import WarehouseProductHistory from "../pages/Inventory/WarehouseStock/WarehouseProductHistory";
import IssuedSample from "../pages/Inventory/IssuedSample";

// const CustomerCreditNote = lazy(
//   () => import("../pages/Inventory/CustomerCreditNote/CustomerCreditNote")
// );
import SampleRequest from "../pages/Inventory/SampleRequest/SampleRequest";
import AddSampleRequest from "../pages/Inventory/SampleRequest/AddSampleRequest";
import SFC from "../pages/SFC/SFC";
import DoctorBulkUpload from "../pages/upload/doctorBulkUpload/DoctorBulkUpload";
import ChemistBulkUpload from "../pages/upload/chemistBulkUpload/ChemistBulkUpload";
import HospitalBulkUpload from "../pages/upload/hospitalBulkUpload/HospitalBulkUpload";
import StockistBulkUpload from "../pages/upload/stockistBulkUpload/StockistBulkUpload";

export const Layout = () => {
  let [openMenu, setOpenMenu] = useState(true);
  const [broken, setBroken] = useState(false);
  const [toggled, setToggled] = useState(false);
  const role = localStorage.getItem("Roles");
  const fullScreenLoader = useContext(LoaderContext);

  return (
    <>
      {fullScreenLoader.loading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "9998",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      <div className="d-flex">
        <SideBar2
          openMenu={openMenu}
          onBreakPoint={setBroken}
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
        />
        <div className="w-100">
          <Header
            onChangeMenu={() => {
              setOpenMenu(!openMenu);
              document
                .getElementsByTagName("body")[0]
                .classList.toggle("sidebar-shrink");
            }}
            broken={broken}
            setToggled={setToggled}
            toggled={toggled}
          />
          <div
            className="w-100"
            style={
              !broken ? { paddingLeft: openMenu ? "280px" : "60px" } : undefined
            }
          >
            <div className="w-100 mt-5 pt-5">
              <div className="pd-ltr-20 xs-pd-20-10">
                <Routes>
                  {/* <Route path="detaildashboard" element={<Dashboard />} /> */}

                  {/* Dashboard */}
                  <Route path="dashboard" element={<DetailDashboard />} />

                  {role === "admin" && (
                    <Route path="maindashboard" element={<MainDashboard />} />
                  )}

                  {/* Admin Panel */}
                  <Route path="adminPanel" element={<AdminPanel />} />
                  {/* <Route path="Adddoctor" element={<AddEditDoctor />} />
                  <Route path="Editdoctor/:id" element={<AddEditDoctor />} /> */}
                  <Route path="crm/crmaccount" element={<CRMAccount />} />
                  <Route
                    path="crm/Addcrmaccount"
                    element={<AddEditCRMAccount />}
                  />
                  <Route
                    path="crm/Editcrmaccount/:id"
                    element={<AddEditCRMAccount />}
                  />
                  <Route path="crm/crmreport" element={<CRMReport />} />
                  <Route path="crm/ViewReport" element={<ViewReport />} />
                  <Route path="crm/managecredit" element={<ManageCredit />} />
                  <Route
                    path="crm/Addmanagecredit"
                    element={<AddEditManageCredit />}
                  />
                  <Route
                    path="crm/Editmanagecredit/:id"
                    element={<AddEditManageCredit />}
                  />
                  <Route path="crm/AddCredit" element={<AddCredit />} />
                  <Route path="crm/Credit" element={<Credit />} />
                  <Route path="master/chemist" element={<Chemist />} />
                  <Route path="doctor" element={<Doctor />} />
                  <Route
                    path="master/general/Stockiest"
                    element={<Stockiest />}
                  />
                  <Route path="submaster/hospital" element={<Hospital />} />
                  <Route path="Product" element={<Product />} />
                  {/* <Route path="AddProduct" element={<AddEditProduct />} />
                  <Route path="Editproduct/:id" element={<AddEditProduct />} /> */}
                  <Route
                    path="master/RCPA/CompetitorsProduct"
                    element={<CompetitorProduct />}
                  />
                  <Route path="MR" element={<MR />} />
                  <Route path="AddMR" element={<AddEditMR />} />
                  <Route path="EditMR/:id" element={<AddEditMR />} />
                  {role !== "MR" && (
                    <>
                      <Route
                        path="mr/admin/accountList"
                        element={<AccountList />}
                      />
                      <Route path="mr/admin/addaccount" element={<Account />} />
                      <Route
                        path="mr/admin/editaccount/:id"
                        element={<Account />}
                      />
                    </>
                  )}
                  <Route
                    path="doctorDetail/:doctorid"
                    element={<DoctorDetails />}
                  />
                  <Route path="doctorView/:doctorid" element={<DoctorView />} />

                  {/* Tour Program  */}
                  <Route path="tourprogram" element={<TourProgram />} />
                  {role !== "MR" && (
                    <Route
                      path="AddEmployeeJWRObservation"
                      element={<AddEditEmployeeJWRObservation />}
                    />
                  )}
                  <Route
                    path="doctoravailability"
                    element={<DoctorAvailability />}
                  />
                  {role !== "admin" && (
                    <>
                      <Route
                        path="mr/admin/dailyTouPlan"
                        element={<DailyTourPlan />}
                      />
                      <Route
                        path="mr/admin/addDailyTouPlan"
                        element={<AddEditDailyTourPlan />}
                      />
                      <Route
                        path="mr/admin/editDailyTouPlan/:id"
                        element={<AddEditDailyTourPlan />}
                      />
                      <Route path="mr/calender" element={<Calender />} />
                      <Route path="mr/tourplan" element={<TourPlan />} />
                      <Route
                        path="mr/edittourplan/:id"
                        element={<TourPlan />}
                      />
                      <Route
                        path="mr/viewTourPlan"
                        element={<ViewTourPlan />}
                      />
                      <Route path="mr/leave" element={<Leave />} />
                      <Route
                        path="mr/leavecalender"
                        element={<LeaveCalendar />}
                      />
                      <Route
                        path="Attendance/todayActivity"
                        element={<TodayActivity />}
                      />
                      <Route path="Attendance" element={<Attendance />} />
                      <Route path="mr/expense" element={<Expense />} />
                      <Route
                        path="eventmanagement/nwdays"
                        element={<NwDays />}
                      />
                    </>
                  )}

                  {/* Approvals */}
                  {role !== "MR" && (
                    <>
                      <Route path="Approvals" element={<Approvals />} />
                      <Route
                        path="mr/admin/tourplan"
                        element={<AdminTourPlan />}
                      />
                      <Route
                        path="mr/admin/dailyplan"
                        element={<AdminDailyPlan />}
                      />
                      <Route
                        path="mr/admin/adminLeave"
                        element={<AdminLeave />}
                      />
                      <Route
                        path="mr/admin/adminExpense"
                        element={<AdminExpense />}
                      />
                      <Route
                        path="mr/admin/adminlockunlock"
                        element={<AdminLockUnlock />}
                      />
                      <Route
                        path="mr/admin/adminDoctorAvailibilty"
                        element={<AdminDoctorAvailibilty />}
                      />
                      <Route
                        path="approvalForDeleted/ApprovalForDeleted"
                        element={<ApprovalForDeleted />}
                      />
                      <Route
                        path="approvalForDeleted/ApprovalForDeletedChemist"
                        element={<ApprovalForDeletedChemist />}
                      />
                      <Route
                        path="approvalForDeleted/ApprovalForDeletedStockist"
                        element={<ApprovalForDeletedStockist />}
                      />
                      <Route
                        path="mr/admin/showtourplan/:id/:name"
                        element={<ShowTourPlan />}
                      />
                    </>
                  )}

                  {/* Mapping */}
                  <Route path="mapping" element={<Mapping />} />
                  <Route
                    path="master/mastermapping/doctorchemistmap"
                    element={<DoctorChemistMap />}
                  />
                  <Route
                    path="master/mastermapping/doctorproductmap"
                    element={<DoctorProductMap />}
                  />
                  <Route
                    path="eventmanagement/eventsemployeemap"
                    element={<EventsEmployeeMap />}
                  />
                  {role !== "MR" && (
                    <>
                      <Route
                        path="master/mastermapping/employeechemistmap"
                        element={<ChemistEmployeeMap />}
                      />
                      <Route
                        path="master/mastermapping/stockistmap"
                        element={<StockistMap />}
                      />
                      <Route
                        path="master/mastermapping/employeedevicemap"
                        element={<EmployeeDeviceMap />}
                      />
                    </>
                  )}

                  {/* Reports */}
                  <Route path="reports" element={<Reports />} />
                  <Route path="report/admincrm" element={<AdminCRM />} />
                  <Route path="report/crm" element={<CRM />} />
                  <Route
                    path="report/targetachievementreport"
                    element={<TargetAchievement />}
                  />
                  <Route
                    path="report/threepreport"
                    element={<ThreePReport />}
                  />
                  <Route path="report/visitReport" element={<VisitReport />} />
                  <Route
                    path="JWRObservation"
                    element={<JWRObservationReport />}
                  />
                  <Route
                    path="eventmanagement/jwrobservation"
                    element={<JWRObservation />}
                  />
                  <Route path="CRMReportGrid" element={<CRMReportGrid />} />
                  <Route
                    path="report/doctorvisitcount"
                    element={<DoctorVisitCountReport />}
                  />
                  <Route path="mr/admin/adminDCR" element={<AdminDCR />} />
                  <Route
                    path="report/chemistvisitrcpa"
                    element={<ChemistVisitRCPAReport />}
                  />
                  <Route
                    path="report/doctorvisitfrequency"
                    element={<DoctorVisitFrequencyReport />}
                  />
                  <Route
                    path="report/chemistvisitfrequency"
                    element={<ChemistVisitFrequencyReport />}
                  />
                  <Route
                    path="report/doctorvisitcoverage"
                    element={<DoctorVisitCoverageReport />}
                  />
                  <Route
                    path="report/lateDCRSubmissionReport"
                    element={<LateDCRSubmissionReport />}
                  />

                  {/* Events */}
                  <Route path="eventmanagement/event" element={<Event />} />
                  <Route path="eventmanagement/contact" element={<Contact />} />
                  <Route path="eventmanagement/patient" element={<Patient />} />
                  <Route
                    path="eventmanagement/eventattendance"
                    element={<EventAttendance />}
                  />
                  <Route
                    path="eventmanagement/attendee"
                    element={<Attendee />}
                  />
                  <Route path="submaster/attendedAs" element={<AttendedAs />} />

                  {/* Upload */}
                  <Route path="DoctorBulkUpload" element={<DoctorBulkUpload />} />
                  <Route path="ChemistBulkUpload" element={<ChemistBulkUpload />} />
                  <Route path="HospitalBulkUpload" element={<HospitalBulkUpload />} />
                  <Route path="StockistBulkUpload" element={<StockistBulkUpload />} />
                  <Route path="StockistSale" element={<StockistSale />} />
                  <Route path="PartyWiseSale" element={<PartyWiseSale />} />
                  <Route path="upload/visitupload" element={<VisitUpload />} />
                  <Route
                    path="upload/giftsampleupload"
                    element={<GiftSampleUpload />}
                  />
                  <Route path="upload/giftupload" element={<GiftUpload />} />
                  <Route path="upload/giftGrid" element={<GiftGrid />} />
                  <Route
                    path="master/general/GiftMaster"
                    element={<GiftMaster />}
                  />
                  <Route
                    path="upload/sampleupload"
                    element={<SampleUpload />}
                  />
                  <Route path="upload/sampleGrid" element={<SampleGrid />} />
                  <Route
                    path="upload/travellingallowance"
                    element={<TravellingAllowance />}
                  />
                  <Route
                    path="upload/monthlyallowanceupload"
                    element={<MonthlyAllowanceUpload />}
                  />
                  <Route
                    path="upload/dailyallowanceupload"
                    element={<DailyAllowanceUpload />}
                  />
                  <Route
                    path="upload/noworkingallowanceupload"
                    element={<NoWorkingAllowanceUpload />}
                  />
                  {role !== "MR" && (
                    <>
                      <Route path="master/claim/src" element={<SRC />} />
                      <Route path="master/claim/Addsrc" element={<AddSRC />} />
                      <Route
                        path="master/claim/Editsrc/:id"
                        element={<AddSRC />}
                      />
                      <Route
                        path="mr/birthdayanniversary"
                        element={<BirthdayAnniversary />}
                      />
                    </>
                  )}
                  {role === "admin" && (
                    <>
                      <Route
                        path="upload/targetUpload"
                        element={<TargetUpload />}
                      />
                      <Route
                        path="master/visualaid/VAUpload"
                        element={<VAUpload />}
                      />
                      <Route
                        path="master/visualaid/AddVAUpload"
                        element={<AddEditVAUpload />}
                      />
                      <Route
                        path="master/visualaid/EditVAUpload/:id"
                        element={<AddEditVAUpload />}
                      />
                    </>
                  )}
                  {/* SFC */}
                  <Route path="SFC" element={<SFC />} />

                  {/* Warehouse */}
                  <Route path="master/general/CFA" element={<CFA />} />

                  {/* Holiday */}
                  <Route path="master/general/Holiday" element={<Holiday />} />

                  {/* General */}
                  <Route path="submaster/creditType" element={<CreditType />} />
                  <Route
                    path="submaster/callObjective"
                    element={<CallObjective />}
                  />
                  <Route path="submaster/callType" element={<CallType />} />
                  <Route
                    path="submaster/priorityType"
                    element={<PriorityType />}
                  />
                  <Route
                    path="submaster/recursiveType"
                    element={<RecursiveType />}
                  />
                  <Route path="submaster/hqtype" element={<HqType />} />
                  <Route
                    path="master/visualaid/subcomponent/presentation/Presentation"
                    element={<Presentation />}
                  />
                  {/* Communication */}
                  <Route path="email" element={<Email />} />
                  <Route path="emailDetail/:id" element={<EmailDetail />} />
                  <Route path="sendDetail/:id" element={<SendDetail />} />
                  <Route path="draftDetail/:id" element={<DraftDetail />} />
                  {role === "admin" && (
                    <>
                      <Route
                        path="master/Announcement/announcement"
                        element={<Announcement />}
                      />
                      <Route
                        path="master/Announcement/addannouncement"
                        element={<AddEditAnnouncement />}
                      />
                    </>
                  )}
                  {/* Geography */}
                  <Route path="master/geography" element={<Geography />} />
                  <Route path="geography/country" element={<Country />} />
                  <Route path="geography/zone" element={<Zone />} />
                  <Route path="geography/state" element={<State />} />
                  <Route path="geography/region" element={<Region />} />
                  <Route path="geography/district" element={<District />} />
                  <Route path="geography/city" element={<City />} />
                  <Route path="geography/pincode" element={<Pincode />} />
                  {/* Vendor */}
                  <Route path="vendor" element={<Vendor />} />

                  {/* Other */}
                  <Route
                    path="SecondaryProductMap"
                    element={<SecondaryProductMap />}
                  />
                  <Route path="ChemistMap" element={<ChemistMap />} />
                  {/* Primary Vs Secondary */}
                  <Route
                    path="report/updatePrimaryData"
                    element={<UpdatePrimaryData />}
                  />
                  <Route
                    path="report/updateSecondaryData"
                    element={<UpdateSecondaryData />}
                  />

                  {/* Attributes */}
                  {role === "admin" && (
                    <>
                      <Route
                        path="doctorattributes"
                        element={<DoctorAttributes />}
                      />
                      <Route
                        path="master/doctor/Category"
                        element={<Category />}
                      />
                      <Route
                        path="master/doctor/Speciality"
                        element={<Speciality />}
                      />
                      <Route
                        path="master/doctor/Qualification"
                        element={<Qualification />}
                      />
                      <Route
                        path="submaster/doctorDegreeType"
                        element={<DoctorDegreeType />}
                      />
                      <Route
                        path="submaster/doctorGuidelinesType"
                        element={<DoctorGuidelinesType />}
                      />
                      <Route
                        path="submaster/entertainmentType"
                        element={<EntertainmentType />}
                      />
                      <Route
                        path="submaster/entertainmentActivityType"
                        element={<EntertainmentActivityType />}
                      />
                      <Route
                        path="submaster/hospitalRoleType"
                        element={<HospitalRoleType />}
                      />
                      <Route
                        path="submaster/doctorAreaOfInterestType"
                        element={<DoctorAreaOfInterestType />}
                      />
                      <Route
                        path="submaster/vendorservicetype"
                        element={<VendorServiceType />}
                      />
                      <Route
                        path="submaster/achievementType"
                        element={<AchievementType />}
                      />
                      <Route
                        path="submaster/consultingFeeType"
                        element={<ConsultingFeeType />}
                      />
                      <Route
                        path="submaster/conferenceAndWebinarAttendedType"
                        element={<ConferenceAndWebinarAttendedType />}
                      />
                      <Route
                        path="submaster/conferenceAndWebinarSubjectType"
                        element={<ConferenceAndWebinarSubjectType />}
                      />
                      <Route
                        path="submaster/relationType"
                        element={<RelationType />}
                      />
                      <Route
                        path="chemistattributes"
                        element={<ChemistAttributes />}
                      />
                      <Route
                        path="master/chemist/ChemistCategory"
                        element={<ChemistCategory />}
                      />
                      <Route
                        path="submaster/chemistContactPerson"
                        element={<ChemistContactPerson />}
                      />
                      <Route
                        path="submaster/chemistType"
                        element={<ChemistType />}
                      />
                      <Route
                        path="hospitalattributes"
                        element={<HospitalAttribute />}
                      />
                      <Route
                        path="submaster/hospitalContactPerson"
                        element={<HospitalContactPerson />}
                      />
                      <Route
                        path="stockistattributes"
                        element={<StockistAttribute />}
                      />
                      <Route
                        path="submaster/stockistType"
                        element={<StockistType />}
                      />
                      <Route
                        path="productattributes"
                        element={<ProductAttribute />}
                      />
                      <Route
                        path="master/product/DrugHead"
                        element={<DrugHead />}
                      />
                      <Route
                        path="master/product/ProductLine"
                        element={<ProductLine />}
                      />
                      <Route
                        path="master/product/ProductClass"
                        element={<ProductClass />}
                      />
                      <Route
                        path="master/product/ProductGroup"
                        element={<ProductGroup />}
                      />
                      <Route
                        path="master/product/ProductType"
                        element={<ProductType />}
                      />
                      <Route
                        path="master/product/PTRPTSSetting"
                        element={<PTRPTSSetting />}
                      />
                      <Route
                        path="master/product/packingtype"
                        element={<PackingType />}
                      />
                      <Route path="submaster/division" element={<Division />} />
                      <Route
                        path="submaster/mediaType"
                        element={<MediaType />}
                      />
                      <Route path="submaster/hq" element={<Hq />} />
                      <Route
                        path="employeeattributes"
                        element={<EmployeeAttribute />}
                      />
                      <Route
                        path="submaster/allowancetype"
                        element={<AllowanceType />}
                      />
                      <Route
                        path="submaster/eventpartnertype"
                        element={<EventPartnerType />}
                      />
                      <Route
                        path="submaster/deviceType"
                        element={<DeviceType />}
                      />
                      <Route
                        path="master/deviceDetail"
                        element={<DeviceDetail />}
                      />
                      <Route
                        path="submaster/calculationtype"
                        element={<CalculationType />}
                      />
                      <Route
                        path="submaster/expenseType"
                        element={<ExpenseType />}
                      />
                      <Route
                        path="submaster/leaveType"
                        element={<LeaveType />}
                      />
                      <Route
                        path="master/RCPA/CompetitorMst"
                        element={<CompetitorMst />}
                      />
                      <Route
                        path="eventattributes"
                        element={<EventAttribute />}
                      />
                      <Route
                        path="eventmanagement/eventtype"
                        element={<EventType />}
                      />
                      <Route
                        path="eventmanagement/attendeetype"
                        element={<AttendeeType />}
                      />
                      <Route
                        path="eventmanagement/attendeestatus"
                        element={<AttendanceStatus />}
                      />
                      <Route
                        path="inventoryattributes"
                        element={<InventoryAttribute />}
                      />
                      <Route path="inventory/hsntype" element={<HSNType />} />
                      <Route
                        path="inventory/InvoiceType"
                        element={<InvoiceType />}
                      />
                      <Route
                        path="inventory/PaymentType"
                        element={<PaymentType />}
                      />
                      <Route
                        path="inventory/ReturnType"
                        element={<ReturnType />}
                      />
                      <Route path="submaster/hq" element={<Hq />} />
                      <Route path="attributes" element={<Attributes />} />
                    </>
                  )}

                  {/* Inventory */}
                  <Route path="Inventory" element={<Inventory />} />
                  <Route path="InventoryMaster" element={<InventoryMaster />} />
                  <Route path="inventory/company" element={<Company />} />
                  <Route path="inventory/customer" element={<Customer />} />
                  <Route path="inventory/warehouse" element={<WareHouse />} />
                  <Route path="inventory/hsn" element={<Hsn />} />
                  <Route
                    path="inventory/currency"
                    element={<InventoryCurrency />}
                  />
                  <Route
                    path="inventory/paymentmethod"
                    element={<InventoryPaymentMethod />}
                  />
                  <Route
                    path="inventory/vendor"
                    element={<InventoryVendor />}
                  />
                  <Route path="inventory/Invoice" element={<Invoice />} />
                  <Route
                    path="inventory/GetAccountsInvoice"
                    element={<GetAccountsInvoice />}
                  />
                  <Route
                    path="inventory/PurchaseInvoice"
                    element={<PurchaseInvoice />}
                  />
                  <Route
                    path="inventory/AddPurchaseInvoice"
                    element={<AddEditPurchaseInvoice />}
                  />
                  <Route
                    path="inventory/AddPurchaseReturn/:id"
                    element={<AddEditPurchaseInvoice />}
                  />
                  <Route
                    path="inventory/SalesInvoice"
                    element={<SalesInvoice />}
                  />
                  <Route
                    path="inventory/AddSalesInvoice"
                    element={<AddEditSalesInvoice />}
                  />
                  <Route
                    path="inventory/AddSalesReturn/:id"
                    element={<AddEditSalesInvoice />}
                  />
                  <Route
                    path="inventory/VendorCreditNote"
                    element={<VendorCreditNote />}
                  />
                  <Route
                    path="inventory/AddVendorCreditNote"
                    element={<AddVendorCreditNote />}
                  />
                  <Route
                    path="inventory/CustomerCreditNote"
                    element={<CustomerCreditNote />}
                  />

                  {/* <Route
                    path="inventory/CustomerCreditNote"
                    element={
                      <Suspense fallback={<>Loading ....</>}>
                        <CustomerCreditNote />
                      </Suspense>
                    }
                  /> */}

                  <Route
                    path="inventory/SalesReturnInvoice"
                    element={<SalesReturnInvoice />}
                  />
                  <Route
                    path="inventory/PurchaseReturnInvoice"
                    element={<PurchaseReturnInvoice />}
                  />
                  <Route
                    path="inventory/WarehouseStock"
                    element={<WarehouseStock />}
                  />
                  <Route
                    path="inventory/WarehouseProductHistory"
                    element={<WarehouseProductHistory />}
                  />
                  <Route
                    path="inventory/WarehouseProductHistory/:id"
                    element={<WarehouseProductHistory />}
                  />
                  <Route
                    path="inventory/AddPaymentInvoice"
                    element={<AddPaymentInvoice />}
                  />
                  <Route
                    path="viewinvoice/:type/:id"
                    element={<InvoiceView />}
                  />
                  <Route
                    path="inventory/IssuedSample"
                    element={<IssuedSample />}
                  />

                  <Route path="*" element={<NotFound />} />
                  <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="Notification" element={<Notification />} />
                  <Route
                    path="ViewNotification/:id"
                    element={<ViewNotification />}
                  />

                  {/* Permission */}
                  <Route path="/" element={<AdminRoute />}>
                    <Route
                      path="admin/permission/permission"
                      element={<Permission />}
                    />
                    <Route
                      path="admin/permission/addpermission"
                      element={<AddEditPermission />}
                    />
                    <Route
                      path="admin/permission/editpermission/:id"
                      element={<AddEditPermission />}
                    />
                    <Route
                      path="admin/permission/rolepermission"
                      element={<RolePermission />}
                    />
                    <Route
                      path="admin/permission/addrolepermission"
                      element={<AddEditRolePermission />}
                    />
                    <Route
                      path="admin/permission/editrolepermission/:id"
                      element={<AddEditRolePermission />}
                    />
                    <Route
                      path="admin/permission/permissionaction"
                      element={<PermissionAction />}
                    />
                    <Route
                      path="admin/permission/addpermissionaction"
                      element={<AddEditPermissionAction />}
                    />
                    <Route
                      path="admin/permission/editpermissionaction/:id"
                      element={<AddEditPermissionAction />}
                    />
                  </Route>

                  <Route
                    path="submaster/directClubGroup"
                    element={<DirectClubGroup />}
                  />
                  <Route path="upload/primarySale" element={<PrimarySale />} />
                  <Route
                    path="upload/secondarySale"
                    element={<SecondarySale />}
                  />
                  <Route path="upload/targetSale" element={<TargetSale />} />
                  <Route path="upload/srcupload" element={<SRCUpload />} />
                  <Route
                    path="report/primaryvssecondary"
                    element={<PrimaryVsSecondary />}
                  />
                  <Route
                    path="report/partywise"
                    element={<PartyWiseReport />}
                  />
                  <Route
                    path="report/stockistreport"
                    element={<SecondaryReport />}
                  />
                  <Route path="mr/viewDCR" element={<AdminDCR />} />
                  <Route path="mr/rcpa" element={<RCPA />} />
                  <Route path="mr/visit" element={<DCR />} />
                  <Route path="mr/visitForm" element={<CommonModel />} />
                  <Route
                    path="mr/ChemistvisitForm"
                    element={<ChemistVisit />}
                  />
                  <Route path="mr/visit/doctor" element={<DoctorModel />} />
                  <Route path="mr/visit/hospital" element={<HospitalModel />} />
                  <Route path="mr/visit/chemist" element={<ChmesitModel />} />
                  <Route
                    path="master/general/AddCFA"
                    element={<AddEditCFA />}
                  />
                  <Route
                    path="master/general/EditCFA/:id"
                    element={<AddEditCFA />}
                  />
                  <Route
                    path="master/allowance/travellingallowance"
                    element={<TravelAllowance />}
                  />
                  <Route
                    path="master/allowance/monthlyallowance"
                    element={<MonthlyAllowance />}
                  />
                  <Route
                    path="master/allowance/dailyallowance"
                    element={<DailyAllowance />}
                  />
                  <Route
                    path="master/allowance/noworkingallowance"
                    element={<NoWorkingAllowance />}
                  />
                  <Route
                    path="master/mastermapping/routeemployeemapping"
                    element={<RouteEmployeeMapping />}
                  />
                  <Route
                    path="master/RCPA/AddCompetitorsProduct"
                    element={<AddEditCompetitorProduct />}
                  />
                  <Route
                    path="master/RCPA/EditCompetitorsProduct/:id"
                    element={<AddEditCompetitorProduct />}
                  />
                  <Route
                    path="master/doctor/AddSpeciality"
                    element={<AddEditSpeciality />}
                  />
                  <Route
                    path="master/doctor/EditSpeciality/:id"
                    element={<AddEditSpeciality />}
                  />
                  <Route path="ProductMap" element={<ProductMap />} />
                  <Route path="SalarySlip" element={<SalarySlip />} />

                  <Route path="inventory/Customer" element={<Customer />} />
                  <Route path="Geography" element={<AllGeography />} />
                  <Route path="imageselector" element={<ImageSelector />} />
                  <Route
                    path="inventory/PaymentPurchaseInvoice"
                    element={<PaymentPurchaseInvoice />}
                  />
                  <Route
                    path="inventory/AddPaymentPurchaseInvoice"
                    element={<AddPaymentPurchaseInvoice />}
                  />
                  <Route
                    path="inventory/PaymentSalesInvoice"
                    element={<PaymentSalesInvoice />}
                  />
                  <Route
                    path="inventory/AddPaymentSalesInvoice"
                    element={<AddPaymentSalesInvoice />}
                  />
                  <Route
                    path="inventory/PaymentReturnInvoice"
                    element={<PaymentReturnInvoice />}
                  />
                  <Route
                    path="inventory/AddPaymentReturnInvoice"
                    element={<AddPaymentReturnInvoice />}
                  />
                  <Route
                    path="inventory/PaymentInvoice"
                    element={<PaymentInvoice />}
                  />
                  <Route
                    path="inventory/WarehouseStockTransfer"
                    element={<WarehouseStockTransfer />}
                  />
                  <Route
                    path="inventory/AddWarehouseStockTransfer"
                    element={<AddWarehouseStockTransfer />}
                  />
                  <Route
                    path="inventory/AddSalesReturnInvoice"
                    element={<AddSalesReturnInvoice />}
                  />
                  <Route
                    path="inventory/AddPurchaseReturnInvoice"
                    element={<AddPurchaseReturnInvoice />}
                  />
                  <Route
                    path="inventory/AddSampleRequest"
                    element={<AddSampleRequest />}
                  />
                  <Route
                    path="inventory/sampleRequest"
                    element={<SampleRequest />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
