import { useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import AddEditJWRObservation from "./AddEditJWRObservation";
import { DeleteModal } from "../../../common/DeleteModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Badge } from "primereact/badge";

const JWRObservation = () => {
  const apiname = process.env.REACT_APP_JWROBSERVATION_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const [bool, setbool]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [updateId, setupdateId]: any = useState(null);

  const filterJson = {
    Title: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    MaxScore: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ExpectedScore: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    OrderNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "2%",
    },
    {
      header: "Action",
      sortable: false,
      // frozen: true,
      filter: false,
      body: (e: any) => actionJWRObservationTemplate(e),
      width: "6%",
    },
    {
      field: "Title",
      header: "Title",
      // frozen: true,
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by title",
      body: null,
      width: "10vw",
    },
    {
      field: "Guideline",
      header: "GuideLine",
      body: null,
      width: "10vw",
    },
    {
      field: "MaxScore",
      header: "Max Score",
      sortable: true,
      body: (id: any) => {
        return (
          <Badge
            value={id.MaxScore}
            size="normal"
            severity={id.MaxScore === id.ExpectedScore ? "success" : "danger"}
          ></Badge>
        );
      },
      filterPlaceholder: "Search by max score",
      dataType: "numeric",
      filter: true,
      width: "10vw",
    },
    {
      field: "ExpectedScore",
      header: "Expected Score",
      sortable: true,
      body: (id: any) => {
        return (
          <Badge
            value={id.ExpectedScore}
            size="normal"
            severity={"info"}
          ></Badge>
        );
      },
      filterPlaceholder: "Search by expected score",
      dataType: "numeric",
      filter: true,
      width: "10vw",
    },
    {
      field: "OrderNo",
      header: "Order No",
      sortable: true,
      body: null,
      filterPlaceholder: "Search by order no.",
      dataType: "numeric",
      filter: true,
      width: "10vw",
    },
  ];

  const actionJWRObservationTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>JWR Observation</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="JWR Observation"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          filterJson={filterJson}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditJWRObservation
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default JWRObservation;
