import { Navigate, Outlet } from "react-router-dom";

export const Auth = () => {
  const token = localStorage.getItem("Access");
  return token ? <Outlet /> : <Navigate to="/" />;
};

export const Loginauth = () => {
  const token = localStorage.getItem("Access");
  return !token ? <Outlet /> : <Navigate to="/layout/dashboard" />;
};

export const MRRoute = () => {
  const role = localStorage.getItem("Roles") === "MR";
  return role ? <Outlet /> : <Navigate to="/" />;
};

export const MrManagerRoute = () => {
  const role = localStorage.getItem("Roles") === "BDM" 
  || localStorage.getItem("Roles") === "ASM" 
  || localStorage.getItem("Roles") === "RSM" 
  || localStorage.getItem("Roles") === "ZSM";
  return role ? <Outlet /> : <Navigate to="/" />;
};

export const AdminRoute = () => {
  const role = localStorage.getItem("Roles") === "admin";
  return role ? <Outlet /> : <Navigate to="/" />;
};