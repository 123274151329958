import { useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import AddEditSpecialityModal from "./AddEditSpecialityModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { NullHandler } from "../../../common/NullHandler";

export default function Speciality() {
  const apiname = process.env.REACT_APP_SPECIALITY_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [deleteID, setdeleteID]: any = useState(null);
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedspeciality, setselectedspeciality]: any = useState([]);
  const multi = selectedspeciality?.map((e: any) => {
    return e.SpecialityId;
  });

  const [updateId, setupdateId]: any = useState(null);
  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3%",
    },
    {
      header: "Action",
      body: (e: any) => actionSpecialityTemplate(e),
      sortable: false,
      width: "7%",
    },
    {
      field: "SpecialityName",
      header: "Speciality Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "Remarks",
      header: "Remarks",
      body: (data: any) => {
        return NullHandler(data.Remarks);
      },
      width: "10vw",
    },
    {
      field: "specialityQulificationModel.QualificationName",
      header: "Qualification",
      body: (e: any) => actionQualificationTemplate(e),
      sortable: false,
      width: "10vw",
    },

  ];

  const actionQualificationTemplate = (id: any) => {
    return id.specialityQulificationModel?.length == 0 ? " - " : id.specialityQulificationModel
      ?.map((ab: any) => {
        return ab.QualificationName;
      })
      .join(", ");
  };

  const actionSpecialityTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.SpecialityId}
          editModal={() => {
            setupdateId(id.SpecialityId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Speciality</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Speciality"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/doctorattributes"}
          linkflagtitle={"Doctor Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          selecteditem={selectedspeciality}
          setselecteditem={setselectedspeciality}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedspeciality?.length === 0 ? null : multi);
            }
          }}
        />

        {updateId !== null && (
          <AddEditSpecialityModal
            setisOPen={() => {
              return setupdateId(null);
            }}
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
