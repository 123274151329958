import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { DeleteModal } from "../../../common/DeleteModal";
import { PageHeader } from "../../../common/PageHeader";
import AddEditHolidayModal from "./AddEditHolidayModal";
import DatatableCommon from "../../../common/DatatableCommon";
import moment from "moment";
import { NullHandler } from "../../../common/NullHandler";
import { Badge } from "primereact/badge";

export default function Holiday() {
  const apiname = process.env.REACT_APP_HOLYDAY_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [deleteID, setdeleteID]: any = useState(null);
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };
  const [selectedholiday, setselectedholiday]: any = useState([]);
  const multi = selectedholiday?.map((e: any) => {
    return e.HolidayId;
  });

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      header: "Action",
      body: (e: any) => actionHolidayTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "HolidayName",
      header: "Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "State",
      header: "State",
      body: (data: any) => {
        return NullHandler(data.State);
      },
      sortable: true,
      width: "10vw",
    },
    {
      field: "HolidayType",
      header: "Type",
      body: (data: any) => {
        return NullHandler(data.HolidayType);
      },
      sortable: true,
      width: "10vw",
    },

    {
      field: "Reason",
      header: "Reason",
      body: (data: any) => {
        return NullHandler(data.Reason);
      },
      width: "10vw",
    },
    {
      field: "Date",
      header: "Date",
      body: (e: any) =>
      // NullHandler(e.Date == null ? "" : moment(e.Date).format("DD MMM, YYYY")),
      {
        if (e.Date === null) {
          return "-";
        } else {
          return (
            <>
              <Badge
                className="m-1"
                severity={"success"}
                value={moment(e.Date).format("DD MMM, YYYY")}
              ></Badge>

            </>
          );
        }
      },
      sortable: true,
      width: "10vw",
      dataType: "date"
    },
  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionHolidayTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.HolidayId}
          editModal={() => {
            setupdateId(id.HolidayId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  const actionDateTemplate = (id: any) => {
    console.log(id, "Datetime");
    return moment(id.Date).format("YYYY-MM-DD HH:mm:ss");
  };

  return (
    <>
      <Helmet>
        <title>Holiday</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Holiday"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedholiday}
          selecteditem={selectedholiday}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedholiday?.length === 0 ? null : multi);
            }
          }}
        />

        {updateId !== null && (
          <AddEditHolidayModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
