import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ChangePassword from "../../pages/Login/ChangePassword";
import axiosInstance from "../../_helper/Interceptor";
import user from "../../assets/images/3.png";

export const Header = ({ onChangeMenu, broken, setToggled, toggled }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const [state, setstate] = useState(false);

  const [notifications, setNotifications]: any = useState([]);

  let getData = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_NOTIFICATIONGETUSER_API}`
      )
      .then((res) => {
        setNotifications(res.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="header">
      <div className="header-left">
        {broken ? (
          <div
            onClick={() => setToggled(!toggled)}
            className="flex justify-content-center align-items-center"
          >
            <div className="menu-icon bi bi-list"></div>
          </div>
        ) : (
          <div
            onClick={() => onChangeMenu()}
            className="flex justify-content-center align-items-center"
          >
            <div className="menu-icon bi bi-list"></div>
          </div>
        )}
      </div>
      <div className="header-right">
        <div className="user-notification">
          <div className="dropdown"  >
            <a
              className="dropdown-toggle no-arrow"
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <i className="icon-copy dw dw-notification"></i>
              <span className="badge notification-active"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-right overflow-auto" >
              <div className="notification-list mx-h-350 customscroll ">
                <ul>
                  {notifications?.TotalDataCount !== 0 ? (
                    <>
                      {notifications?.Data?.map(
                        (notification: any, index: any) => {
                          return (
                            <li key={index}>
                              <Link
                                className="notificationStyle p-2 m-1"
                                to={`/layout/Notification`}
                              >
                                <h3 style={{padding:'0', margin:"0"}}>{notification.Title}</h3>
                                <p style={{padding:'0', margin:"0"}}>{notification?.Message}</p>
                              </Link>
                            </li>
                          );
                        }
                      )}
                      {notifications?.TotalDataCount > 10 && (
                        <li className="text-center">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              navigate("/layout/Notification");
                            }}
                          >
                            View All
                          </button>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className="text-center p-2">
                      <h6 className="pt-2">No Notification Found !!!!</h6>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="user-info-dropdown">
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <span className="user-icon">
                <img src={user} alt="" />
              </span>
              <span className="user-name">{localStorage.getItem("User")}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => setstate(true)}
              >
                <i className="dw dw-settings2"></i> Change Password
              </Link>
              <a className="dropdown-item" href="#">
                <i className="dw dw-help"></i> Help
              </a>
              <span className="logHover">
              <a className="dropdown-item logHo"onClick={handleLogOut}>
                <i className="dw dw-logout"></i> <span>Log Out</span> 
              </a>
              </span>
            </div>
          </div>
        </div>
        <div className="github-link">
          <a href="https://github.com/dropways/deskapp" target="_blank">
            <img src="vendors/images/github.svg" alt="" />
          </a>
        </div>
      </div>

      <ChangePassword open={state} setisOPen={setstate} />
    </div>
  );
};
