import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { toast } from "react-toastify";
import { PaginationServiceOfWarehouseStockTransfer } from "../../../service/PaginationService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";

interface iPropstable {
  resetPage?: any;
}

const WarehouseStockTransfer = ({ resetPage = false }: iPropstable) => {
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    debugger;
    console.log(lazyState, "in load");
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfWarehouseStockTransfer.getData(
        lazyState,
        controller.signal
      )
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <DataTable value={data.warehouseTransferProductViewModels}>
            <Column field="ProductName" header="Product Name" />
            <Column field="Qty" header="Quantity" />
          </DataTable>
        </div>
      </div>
    );
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  return (
    <div>
      <Helmet>
        <title>Warehouse Stock Transfer</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Warehouse Stock Transfer"
          list={true}
          LinkURL={"/layout/inventory/AddWarehouseStockTransfer"}
        />

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="normal"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                expandedRows={expandedRows}
                // onRowToggle={(e: any) => setExpandedRows(e.data)}
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column expander style={{ width: "5rem" }} />
                <Column field="SrNo" header="#" />
                <Column
                  field="FromWarehouseName"
                  header="From Warehouse Name"
                  sortable={true}
                ></Column>
                <Column
                  field="ToWarehouseName"
                  header="To Warehouse Name"
                  sortable={true}
                ></Column>
                <Column field="CreatedByName" header="Created By"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseStockTransfer;
