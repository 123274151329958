import React, { useState } from 'react'
import { Action } from '../../../../common/Action';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import DatatableCommon from '../../../../common/DatatableCommon';
import AddEditCreditType from './AddEditCreditType';
import { DeleteModal } from '../../../../common/DeleteModal';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

const CreditType = () => {
  const apiname = process.env.REACT_APP_CREDITTYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);

  const filterJson = {
    CreditTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width:'5%'
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionCallTypeTemplate(e),
      sortable: false,
      width:'30%'
    },
    {
      field: "CreditTypeName",
      header: "Credit Type",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by credit type",
      width: "10vw",
    },

  ];

  const actionCallTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.CreditTypeId}
          editModal={() => {
            setupdateId(id.CreditTypeId)
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <div>
      <Helmet>
        <title> Credit Type </title>
      </Helmet>

      <PageHeader headTitle="Credit Type" onClick={() => { setupdateId(0); }} id={0} list={true} />

      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          filterJson={filterJson}
        />
        {updateId !== null && (
          <AddEditCreditType
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true) } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </div>
  )
}

export default CreditType
