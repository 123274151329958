import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../../common/PageHeader";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import ReactSelect from "../../../common/ReactSelect";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Button } from "primereact/button";

export const AddEditPermissionAction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ACTIONPERMISSION_API?.toString();

  const { data, isLoading: actionpermissionLoader }: any = useFetchAllID(
    apiname,
    id
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        setselectedPermission({
          label: data.PermissionName,
          value: data.PermissionId,
        });
        setselectedController({
          label: data.ControllerName,
          value: data.ControllerId,
        });
        setselectedAction({
          label: data.ActionName,
          value: data.ActionId,
        });
      }
    }
  }, [data, id]);

  const [selectedPermission, setselectedPermission] = useState({});
  const [selectedController, setselectedController] = useState({});
  const [selectedAction, setselectedAction] = useState({});

  const { mutate, isSuccess, isError, isLoading, error }: any = useCreateByID(
    apiname,
    id
  );

  const permissionActionFormData = (data: any) => {
    mutate(data);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/admin/permission/permissionaction");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/admin/permission/permissionaction");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Helmet>
        <title>{id === undefined ? "Add" : "Edit"} Permission Action </title>
      </Helmet>

      <PageHeader
        headTitle="Permission Action"
        headTitleURL={"/layout/admin/permission/permissionaction"}
        list={false}
        id={id}
      />

      {!actionpermissionLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((info: any) => {
              permissionActionFormData(info);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Permission"
                name={"PermissionId"}
                isRequired
                onChange={(e: any) => {
                  setselectedPermission(e);
                  setValue("PermissionId", e?.value);
                }}
                value={selectedPermission}
                control={control}
                api={"Permission"}
                keys={{label :"Value", value: "Id"}}
                errorMessage={
                  errors?.PermissionId?.message ||
                  error?.response?.data?.errors?.PermissionId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: { value: true, message: "Permission is required." },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Controller"
                name={"ControllerId"}
                isRequired
                onChange={(e: any) => {
                  setselectedController(e);
                  setValue("ControllerId", e?.value);
                }}
                value={selectedController}
                control={control}
                api={"Controller"}
                keys={{label :"Value", value: "Id"}}
                errorMessage={
                  errors?.ControllerId?.message ||
                  error?.response?.data?.errors?.ControllerId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: { value: true, message: "Controller is required." },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Action"
                name={"ActionId"}
                isRequired
                onChange={(e: any) => {
                  setselectedAction(e);
                  setValue("ActionId", e?.value);
                }}
                value={selectedAction}
                control={control}
                api={"Action"}
                keys={{label :"Value", value: "Id"}}
                errorMessage={
                  errors?.ActionId?.message ||
                  error?.response?.data?.errors?.ActionId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Action is required." },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Link to={"/layout/admin/permission/permissionaction"}>
                  <Button type="button" severity="secondary" outlined>
                    <span className="p-button-label p-c">Back</span>
                  </Button>
                </Link>

                {
                  id == undefined  && 
                  <Button type="reset" severity="danger" onClick={() => {reset(); setselectedAction({}); setselectedController({}); setselectedPermission({})}} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};
