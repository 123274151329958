import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Input } from "../../../../common/Input";
import ReactSelect from "../../../../common/ReactSelect";
import { Spinner } from "../../../../common/Spinner";
import Textarea from "../../../../common/Textarea";
import { InputMask } from "primereact/inputmask";
import { ErrorMessage } from "../../../../common/ErrorMessage";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export const AddEditVendorService = ({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) => {
  useEffect(() => {
    if (refetchData === true && api === "VendorServiceType") {
      setSelectedVendorType(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname = process.env.REACT_APP_VENDORSERVICE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (id) {
      if (data) {
        const servicedate = data?.ServiceDateTime?.split("T")[0];
        reset(data);
        setValue("ServiceDateTime", servicedate);

        if (
          data.VendorServiceTypeId !== null &&
          data.VendorServiceTypeId !== undefined
        ) {
          setValue("VendorServiceTypeId", {
            label: data.VendorServiceTypeName,
            value: data.VendorServiceTypeId,
          });
          setSelectedVendorType({
            label: data.VendorServiceTypeName,
            value: data.VendorServiceTypeId,
          });
        } else {
          setValue("VendorServiceTypeId", null);
          setSelectedVendorType(null);
        }

      }
    }
  }, [data, id]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: vendorLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [SelectedVendorType, setSelectedVendorType]: any = useState(null);

  const VendorServiceFormData = (data: any) => {

    data.DoctorId = Number(doctorid);
    data.VendorServiceTypeId = SelectedVendorType?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Doctor Vendor`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              VendorServiceFormData(productinfo);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Vendor Service Type"
                isRequired
                name={"VendorServiceTypeId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("VendorServiceTypeId", e?.value);
                    setSelectedVendorType(e);
                  } else {
                    setValue("VendorServiceTypeId", null);
                    setSelectedVendorType(null);
                  }
                }}
                value={SelectedVendorType}
                control={control}
                api={process.env.REACT_APP_VENDORSERVICETYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.VendorServiceTypeId?.message ||
                  error?.response?.data?.errors?.VendorServiceTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: { value: true, message: "Vendor Type is required" },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Head Of Service"
                type="text"
                isRequired
                errorMessage={
                  errors?.HeadOfService?.message ||
                  error?.response?.data?.errors?.HeadOfService?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("HeadOfService", e.target.value);
                  clearErrors(["HeadOfService"]);
                }}
                register={{
                  ...register("HeadOfService", {
                    required: {
                      value: true,
                      message: "Head Of Service is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid head of service (1-50 characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Phone No"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("PhoneNumber", e.target.value);
                  clearErrors(["PhoneNumber"]);
                }}
                register={{
                  ...register("PhoneNumber", {
                    required: {
                      value: true,
                      message: "Phone No is required",
                    },
                    pattern: {
                      value: /^\d{6,10}$/,
                      message: "Enter a valid phone no. (6-10 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.PhoneNumber?.message ||
                  error?.response?.data?.errors?.PhoneNumber?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Service Date-Time"
                type="date"
                isRequired
                errorMessage={
                  errors?.ServiceDateTime?.message ||
                  error?.response?.data?.errors?.ServiceDateTime?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ServiceDateTime", e.target.value);
                  clearErrors(["ServiceDateTime"]);
                }}
                register={{
                  ...register("ServiceDateTime", {
                    required: {
                      value: true,
                      message: "Service Date-Time is required",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Note"
                type="text"
                isRequired
                errorMessage={
                  errors?.Note?.message ||
                  error?.response?.data?.errors?.Note?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Note", e.target.value);
                  clearErrors(["Note"]);
                }}
                register={{
                  ...register("Note", {
                    required: {
                      value: true,
                      message: "Note is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message: "Enter valid notes (1-50 characters)",
                    },
                  }),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setSelectedVendorType(null); }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {vendorLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
