import React, { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import { AddEditConferencesSpeakerParticipantsModal } from "./AddEditConferencesSpeakerParticipantsModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const ConferencesSpeakerParticipants = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname =
    process.env.REACT_APP_DOCTORCONFERENCESANDWEBINAR_API?.toString();

  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);

  const filterJson = {
    ConferencesAndWebinarSubjectTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConferencesAndWebinarDays: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConferencesAndWebinarSubjectTopic: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConferencesAndWebinarAttendedAsId: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConferencesAndWebinarVenue: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConferencesAndWebinarNoOfAttendee: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ConferencesAndWebinarTravelAmount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ConferencesAndWebinarTravelArrangedBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConferencesAndWebinarAccommodationAmount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ConferencesAndWebinarAccommodationArrangedBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    }
  };


  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "ConferencesAndWebinarSubjectTypeName",
      header: "Subject Type",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by subject type name",
    },
    {
      field: "ConferencesAndWebinarStartDate",
      header: "Start Date",
      body: (e: any) => actionDateTemplate(e),
      sortable: true,
      dataType: "date"
    },
    {
      field: "ConferencesAndWebinarEndDate",
      header: "End Date",
      body: (e: any) => actionEndDateTemplate(e),
      sortable: true,
      dataType: "date"
    },
    {
      field: "ConferencesAndWebinarDays",
      header: "Days",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by days",
    },
    {
      field: "ConferencesAndWebinarSubjectTopic",
      header: "Subject / Topic",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by subject topic",
    },
    {
      field: "ConferencesAndWebinarAttendedAs",
      header: "Attended As",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by attended as",
    },
    {
      field: "ConferencesAndWebinarVenue",
      header: "Venue",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by venue",
    },
    {
      field: "ConferencesAndWebinarNoOfAttendee",
      header: "No of Attendee",
      body: null,
      sortable: true,
      filter: true,
      dataType: "numeric",
      filterPlaceholder: "Search by no of attendee",
    },
    {
      field: "ConferencesAndWebinarTravelAmount",
      header: "Travel Amount",
      body: null,
      sortable: true,
      filter: true,
      dataType: "numeric",
      filterPlaceholder: "Search by travel amount",
    },
    {
      field: "ConferencesAndWebinarTravelArrangedBy",
      header: "Travel Arranged by",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by travel arranged by",
    },
    {
      field: "ConferencesAndWebinarAccommodationAmount",
      header: "Accommodation Amount",
      body: null,
      sortable: true,
      filter: true,
      dataType: "numeric",
      filterPlaceholder: "Search by amount",
    },
    {
      field: "ConferencesAndWebinarAccommodationArrangedBy",
      header: "Accommodation Arranged By",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by accommodation arranged by",
    },
    {
      field: "Remarks",
      header: "Remarks",
      body: null,
      sortable: true,
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionDateTemplate = (id: any) => {
    return id.ConferencesAndWebinarStartDate?.split("T")[0];
  };

  const actionEndDateTemplate = (id: any) => {
    return id.ConferencesAndWebinarEndDate?.split("T")[0];
  };

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ConferencesAndWebinarId}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.ConferencesAndWebinarId);
          }}
          onClick={() => modelFunction(apiname, id.ConferencesAndWebinarId)}
        />
      </>
    );
  };

  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);
  return (
    <>
      <Accordion>
        <AccordionTab header="Conferences - Speaker / Participants">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Conferences Speaker / Participants`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              selecteditem={selectedRow}
              setselecteditem={setselectedRow}
              filterJson={filterJson}
              pdfTitle="Conferences Speaker Participants"
            />
          </div>
        </AccordionTab>
      </Accordion>

      {updateId !== null && (
        <AddEditConferencesSpeakerParticipantsModal
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
