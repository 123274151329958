import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner } from "../../../common/Spinner";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { validateHeaderName } from "http";
import { LoaderContext } from "../../../App";

export const TargetUpload = () => {
  const fullScreenLoader = useContext(LoaderContext);
  const apiname = process.env.REACT_APP_TARGETUPLOAD_API?.toString();

  const {
    register,
    handleSubmit,
    reset: downloadReset,
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  const {
    register: uploadregister,
    handleSubmit: uploadhandlesubmit,
    formState: { errors: uploaderrors },
    reset,
  }: any = useForm({ mode: "all" });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedHQ, setselectedHQ]: any = useState(null);
  const [selectedDivision, setselectedDivision]: any = useState(null);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: targetuploadloading,
  }: any = useCreateByID(apiname);

  const handleDownload = async (data: any) => {

    console.log(data, "data");
    data.startDate = `${data.date}-01`;
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_TARGETUPLOADDOWNLOADBLANK_API}?hqId=${selectedHQ?.value
        }&divisionId=${selectedDivision?.value}&date=${data.startDate
        }&grothPer=${Number(data.grothPer)}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "Target.xlsx");
        downloadReset();
        setIsLoading(false);
        toast.success(`Download With Data Successfull`, {
          position: "top-right",
        });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const handleUpload = (data: any) => {

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(`File Uploaded Successfull`, { position: "top-right" });
      reset();
    }
    if (isError) {
      toast.error(`${error?.response?.data?.Message}`, {
        position: "top-right",
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    if (targetuploadloading) {
      fullScreenLoader.setloader(true)
    }
    else {
      fullScreenLoader.setloader(false)
    }
  }, [targetuploadloading])
  return (
    <>
      <Helmet>
        <title>Target Upload</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Target Upload</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Target Upload
                </li>
              </ol>
            </nav>
          </div>
          {/* <div className="col-md-6">
        {!isLoad ? (
          <button
            className="btn btn-outline-primary pull-right"
            type="button"
            onClick={handleDownloadBlank}
          >
            Download Blank
          </button>
        ) : (
          <button
            className="btn btn-outline-primary pull-right"
            type="button"
            onClick={handleDownloadBlank}
            disabled
          >
            Download Blank <Spinner width={"1rem"} height={"1rem"} />
          </button>
        )}
      </div> */}
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        {/* <div className="title mb-20">
          <h5 className="text-blue">Download Blank</h5>
        </div> */}

        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="HQ"
              name={"hqId"}
              isRequired
              onChange={(e: any) => {
                setselectedHQ(e);
                setValue("hqId", e?.value);
              }}
              value={selectedHQ}
              control={control}
              api={process.env.REACT_APP_HQ_API}
              keys={{ label: "Value", value: "Id" }}
              rules={{
                required: { value: true, message: "HQ is required." },
              }}
              errorMessage={errors?.hqId?.message}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Division"
              name={"divisionId"}
              onChange={(e: any) => {
                setselectedDivision(e);
                setValue("divisionId", e?.value);
              }}
              value={selectedDivision}
              control={control}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.divisionId?.message}
            />

            <Input
              containerClass="col-lg-6"
              label="Month"
              type="month"
              isRequired
              errorMessage={errors?.date?.message}
              register={{
                ...register("date", {
                  required: {
                    value: true,
                    message: "Month is required",
                  },
                }),
              }}
            />

            <Input
              containerClass="col-lg-6"
              label="Growth Percentage"
              type="number"
              isRequired
              errorMessage={errors?.grothPer?.message}
              register={{
                ...register("grothPer", {
                  required: {
                    value: true,
                    message: "Growth Percentage is required",
                  },
                }),
              }}
            />

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    handleDownload(data);
                  })}
                >
                  Download
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    handleDownload(data);
                  })}
                  disabled
                >
                  Download <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Upload Excel</h5>
        </div>
        <form
          onSubmit={uploadhandlesubmit((data: any) => {
            handleUpload(data);
          })}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Select File <span className="text-danger">*</span>
                </label>
                <input
                  {...uploadregister("TargetUploadExcel", {
                    required: {
                      value: true,
                      message: "Please Upload Excel",
                    },
                  })}
                  type="file"
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <ErrorMessage
                  message={uploaderrors?.TargetUploadExcel?.message}
                />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!targetuploadloading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Uploading...
                  {/* <Spinner width={"1rem"} height={"1rem"} /> */}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
