import { useState } from 'react'
import { DeleteModal } from '../../../../common/DeleteModal';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import { Action } from '../../../../common/Action';
import AddEditPTRPTSSetting from './AddEditPTRPTSSetting';
import DatatableCommon from '../../../../common/DatatableCommon';
import { NullHandler } from "../../../../common/NullHandler";

const PTRPTSSetting = () => {
    const apiname = process.env.REACT_APP_PTRPTSSETTING_API?.toString();
    const [deleteID, setdeleteID]: any = useState(null);
    const [bool, setbool]: any = useState(null);
    const [updateId, setupdateId]: any = useState(null);
    const [selectedRow, setselectedRow]: any = useState([])
    const multi = selectedRow?.map((e: any) => { return e.PTrptssettingId })

    const deleteiddetect = (row: any) => {
        setdeleteID(row);
    };

    const columnData = [
        {
            field: "srNo",
            header: "#",
            width: "5%",
        },
        {
            header: "Action",
            body: (e: any) => actionPTRPTSTemplate(e),
            sortable: false,
            width: "10%",
        },
        {
            field: "PTrptssettingName",
            header: "PTR PTS Name",
            body: null,
            bodyClassName: "text-info h6",
            sortable: true,
            width: "10vw",
        },
        {
            field: "MedicalMarginNumber",
            header: "Medical Margin Number",
            body: (data: any) => {
                return NullHandler(data.MedicalMarginNumber);
            },
            sortable: true,
            width: "10vw",
        },
        {
            field: "AgeneyMargin",
            header: "Agency Margin",
            body: (data: any) => {
                return NullHandler(data.AgeneyMargin);
            },
            sortable: true,
            width: "10vw",
        },

    ];

    const actionPTRPTSTemplate = (id: any) => {
        return (
            <>
                <Action
                    id={id.PTrptssettingId}
                    editModal={() => {
                        setupdateId(id.PTrptssettingId);
                    }}
                    displayid={deleteiddetect}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>PTR PTS Setting</title>
            </Helmet>
            <div className="min-height-200px">
                <PageHeader
                    headTitle="PTR PTS Setting"
                    onClick={() => {
                        setupdateId(0);
                    }}
                    id={0}
                    list={true}
                    linkflag
                    linkflagURL={"/layout/productattributes"}
                    linkflagtitle={"Product Attributes"}
                />

                <DatatableCommon
                    apiname={apiname}
                    columnData={columnData}
                    refetchData={bool}
                    setrefetchData={setbool}
                    // check={true}
                    selecteditem={selectedRow}
                    setselecteditem={setselectedRow}
                    multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) } }}
                />

                {updateId !== null && (
                    <AddEditPTRPTSSetting
                        setisOPen={() => {
                            return setupdateId(null);
                        }}
                        fetch={(data: boolean) => {
                            if (data) {
                                setbool(true);
                            }
                        }}
                        open={updateId !== null}
                        id={updateId}
                    />
                )}
            </div>

            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => { if (data) { setbool(true); } }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />
        </>
    )
}

export default PTRPTSSetting
