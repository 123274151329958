import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export const AddEditDeviceDetail = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_DEVICEDETAIL_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    reset,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: deviceDetailLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (
          data.DeviceTypeId !== null &&
          data.DeviceTypeId !== undefined
        ) {
          setValue("DeviceTypeId", data.DeviceTypeId);
          setselecteddevicedetail({
            label: data.DeviceType,
            value: data.DeviceTypeId,
          });
        } else {
          setValue("DeviceTypeId", null);
          setselecteddevicedetail(null);
        }

      }

    }
  }, [data]);

  const [selecteddevicedetail, setselecteddevicedetail]: any = useState(null);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const deviceDetailFormData = (data: any) => {
    data.DeviceTypeId = Number(data?.DeviceTypeId);

    for (let prop in data) {
      if (data[prop] === null || data[prop] === undefined || data[prop] === "" || data[prop]?.length == 0) {
        delete data[prop]
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }
    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Device Detail`}
        modal
        style={{ width: "1100px" }}
      >
        {!deviceDetailLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(deviceDetailFormData);
              }
            }}
            onSubmit={handleSubmit(deviceDetailFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Brand"
                isRequired
                type="text"
                onChange={(e: any) => { setValue("Brand", e.target.value); clearErrors(["Brand"]) }}
                register={{
                  ...register("Brand", {
                    required: {
                      value: true,
                      message: "Brand is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid brand (1-50 alphabet characters only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Brand?.message ||
                  error?.response?.data?.errors?.Brand?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Model"
                isRequired
                type="text"
                onChange={(e: any) => { setValue("Model", e.target.value); clearErrors(["Model"]) }}
                register={{
                  ...register("Model", {
                    required: {
                      value: true,
                      message: "Model is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid Model (1-50 alphanumeric characters only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Model?.message ||
                  error?.response?.data?.errors?.Model?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="DeviceType"
                name={"DeviceTypeId"}
                isRequired

                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DeviceTypeId", e?.value);
                    setselecteddevicedetail(e);
                  } else {
                    setValue("DeviceTypeId", null);
                    setselecteddevicedetail(null);
                  }
                }}
                value={selecteddevicedetail}
                control={control}
                api={process.env.REACT_APP_DEVICETYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DeviceTypeId?.message ||
                  error?.response?.data?.errors?.DeviceTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: { value: true, message: "Device Type is required" },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Storage"
                isRequired
                type="text"
                onChange={(e: any) => { setValue("Storage", e.target.value); clearErrors(["Storage"]) }}
                register={{
                  ...register("Storage", {
                    required: {
                      value: true,
                      message: "Storage is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z0-9\s',-]{0,50}$/,
                      message:
                        "Enter a valid Storage (1-50 alphanumeric characters only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Storage?.message ||
                  error?.response?.data?.errors?.Storage?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="IEMI No."
                isRequired
                type="text"
                onChange={(e: any) => { setValue("Iemino", e.target.value); clearErrors(["Iemino"]) }}
                register={{
                  ...register("Iemino", {
                    required: {
                      value: true,
                      message: "IEMI No. is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z0-9\s'-,]{0,50}$/,
                      message:
                        "Enter a valid IEMI No. (1-50 alphanumeric characters only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Iemino?.message ||
                  error?.response?.data?.errors?.Iemino?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselecteddevicedetail(null) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
