import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetchAllID } from "../../../hooks/Api/Api";
import { Spinner } from "../../../common/Spinner";

const SendDetail = () => {
  const { id } = useParams();
  let { data, isLoading }: any = useFetchAllID("Reporting/Email", id);
  return (
    <>
      <Link to={"/layout/email"} className="btn btn-primary btn-sm mb-3">
        Back To Sent Items
      </Link>
      <div className="email-detail-container">
        {!isLoading ? (
          <>
            <div className="email-header">
              <div className="email-sender">
                {data?.EmailToViewModels.map((e: any) => e.ToUserName).join(
                  ", "
                )}
              </div>
              <div className="email-subject">{data?.Subject}</div>
            </div>
            <div
              className="email-body"
              dangerouslySetInnerHTML={{ __html: data?.Message }}
            />
            {data?.EmailAttchmentViewModels &&
              data?.EmailAttchmentViewModels.length > 0 && (
                <div className="email-attachments">
                  <h5>Attachments:</h5>
                  <ul>
                    {data?.EmailAttchmentViewModels.map(
                      (attachment: any, index: any) => (
                        <li key={index}>
                          <a href={attachment.FilePath} target="_blank">
                            {attachment.FileName}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
          </>
        ) : (
          <Spinner width={"15rem"} height={"15rem"} />
        )}
      </div>
    </>
  );
};

export default SendDetail;
