import React, { useState } from "react";
import { Action } from "../../../../common/Action";
import AddEditDivision from "./AddEditDivision";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import { Helmet } from "react-helmet";
import DatatableCommon from "../../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../../common/NullHandler";

const Division = () => {
  const apiname = process.env.REACT_APP_DIVISION_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => { return e.DivisionId })

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const filterJson = {
    DivisionName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    DivisionCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionDivisionTemplate(e),
      sortable: false,
      width: "10%",
    },
    {
      field: "DivisionName",
      header: "Division Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by division name",
      width: "10vw",
    },
    {
      field: "DivisionCode",
      header: "Division Code",
      body: (data: any) => {
        return NullHandler(data.DivisionCode);
      },
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by division code",
      width: "10vw",
    },

  ];

  const actionDivisionTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.DivisionId}
          editModal={() => {
            setupdateId(id.DivisionId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Division</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Division"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/productattributes"}
          linkflagtitle={"Product Attributes"}
        />
        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DatatableCommon
                apiname={apiname}
                columnData={columns}
                refetchData={bool}
                // check={true}
                filterJson={filterJson}
                setrefetchData={setbool}
                setselecteditem={setselectedRow}
                selecteditem={selectedRow}
                multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedRow?.length === 0 ? null : multi) } }}
              />
              {updateId !== null && (
                <AddEditDivision
                  setisOPen={() => {
                    return setupdateId(null);
                  }}
                  fetch={(data: boolean) => {
                    if (data) {
                      setbool(true);
                    }
                  }}
                  open={updateId !== null}
                  id={updateId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default Division;
