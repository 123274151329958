import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { Action } from "../../../common/Action";
import { NullHandler } from "../../../common/NullHandler";


export const Permission = () => {
  const apiname = process.env.REACT_APP_PERMISSION_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
      setdeleteID(id);
  };

  const columns = [
    {
      header: "Action",
      body: (e: any) => actionPermissionTemplate(e),
      sortable: false,
      width:"1vw",
  },
    {
        field: "Permission1",
        header: "Permission",
        body: (data: any) => {
        return NullHandler(data.Permission1);
      },
        sortable: true,
        width:"10vw",
    },
    {
        field: "Description",
        header: "Description",
        body: (data: any) => {
        return NullHandler(data.Description);
      },
        sortable: true,
        width:"10vw",
    },
   
];

const actionPermissionTemplate = (id: any) => {
    return (
        <>
            <Action
                id={id.PermissionId}
                editurl={"layout/admin/permission/editpermission"}
                displayid={setDeleteidtoDelete}
            />
        </>
    );
};


  return (
    <>
      <Helmet>
        <title>Permission</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Permission"
          list={true}
          LinkURL={"/layout/admin/permission/addpermission"}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          // check={true}
        />
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
