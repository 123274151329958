import { useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditHsn } from "./AddEditHsn";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from 'primereact/api';



export const Hsn = () => {
    const apiname = process.env.REACT_APP_INVENTARYHSN_API?.toString();
    const [deleteID, setdeleteID]: any = useState(null);

    const [selectedHsn, setSelectedHsn]: any = useState([]);
    const multi = selectedHsn?.map((e: any) => {
        return e.Id;
    });


    const [bool, setbool]: any = useState(null);

    const deleteiddetect = (row: any) => {
        setdeleteID(row);
    };

    const columnData = [
        {
            field: "srNo",
            header: "#",
            width: "3rem"

        },
        {
            header: "Action",
            sortable: false,
            filter: false,
            body: (e: any) => actionHsnTemplate(e),
            width: "1vw",
        },

        {
            field: "Name",
            header: "HSN Name",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by HSN ",
            body: null,
            width: "10vw",
        },
        {
            field: "Sgst",
            header: "SGST",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by SGST ",
            body: null,
            width: "10vw",
        },
        {
            field: "Cgst",
            header: "CGST",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by CGST ",
            body: null,
            width: "10vw",
        },
        {
            field: "Igst",
            header: "IGST",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by IGST ",
            body: null,
            width: "10vw",
        },
        {
            field: "HSNTypeName",
            header: "Hsn Type",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by Hsn Type ",
            body: null,
            width: "10vw",
        },
        {
            field: "Uqcunit",
            header: "UQC Unit",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by UQC Unit ",
            body: null,
            width: "10vw",
        },



    ];

    const filterJson = {
        Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        Igst: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        Cgst: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        Sgst: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        Uqcunit: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        HSNTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    }

    const [updateId, setupdateId]: any = useState(null);
    const actionHsnTemplate = (id: any) => {
        return (
            <>
                <Action
                    id={id.Id}
                    editModal={() => {
                        setupdateId(id.Id);
                    }}
                    displayid={deleteiddetect}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>HSN</title>
            </Helmet>
            <div className="min-height-200px">
                <PageHeader
                    headTitle="HSN"
                    onClick={() => {
                        setupdateId(0);
                    }}
                    id={0}
                    list={true}
                    linkflag
                    linkflagURL={"/layout/InventoryMaster"}
                    linkflagtitle={"Master"}
                />

                <DatatableCommon

                    apiname={apiname}
                    multiDelete={(data: boolean) => {
                        if (data) {
                            setdeleteID(selectedHsn?.length === 0 ? null : multi);
                        }
                    }}

                    filterJson={filterJson}
                    setselecteditem={setSelectedHsn}
                    selecteditem={selectedHsn}
                    columnData={columnData}
                    refetchData={bool}
                    setrefetchData={setbool}

                />



                {updateId !== null && (
                    <AddEditHsn
                        fetch={(data: boolean) => {
                            if (data) {
                                setbool(true);
                            }
                        }}
                        setisOPen={() => {
                            return setupdateId(null);
                        }}
                        open={updateId !== null}
                        id={updateId}
                    />
                )}
            </div>

            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => {
                    if (data) {
                        setbool(true);
                    }
                }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />
        </>
    );
};
