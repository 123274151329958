import { useNavigate, useParams } from 'react-router-dom'
import img from '../../assets/images/forgot-password.png'
import { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../_helper/Interceptor'
import { Input } from '../../common/Input'
import { useForm } from 'react-hook-form'
import { useResetPassword } from '../../hooks/login/useLoginData'
import { toast } from 'react-toastify'
import { Spinner } from '../../common/Spinner'
import { Helmet } from 'react-helmet'
const ResetPassword = () => {
    const { id } = useParams()
    const [state, setState] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        const data = { Token: id }
        axiosInstance.post(`${process.env.REACT_APP_BASEURL_MAIN}Account/forgot-password-check`, data).then((res) => {
        }).catch((error) => {
            setState(true)
        })
    }, [id])

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch
    }: any = useForm();

    const password = useRef({});
    password.current = watch("NewPassword", "");

    const { mutate, isSuccess, isError, error, isLoading, data }: any = useResetPassword();

    const onSubmit = (data: any) => {
        // delete data.ConfirmPassword;
        data.Token = id
        mutate(data)
    }

    useEffect(() => {
        if (isSuccess) {
            toast.success("Password Changed", { position: "top-right" })
            navigate("/", { state: data.data.Message })
        }
        if (isError) {
            toast.error("Error...", { position: "top-right" })
        }
    }, [isSuccess, isError])
    return (
        <>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center" style={{ position: "absolute", top: "0", left: "0", bottom: "0", right: "0" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={img} />
                        </div>
                        <div className="col-md-6">

                            <div className="login-box bg-white box-shadow border-radius-10">
                                <div className="login-title">
                                    <h2 className="text-center text-primary">Reset Password</h2>
                                </div>
                                {
                                    !state ? (<>
                                        <h6 className="mb-20">Enter your new password, confirm and submit</h6>
                                        <form onSubmit={handleSubmit((logininfo: any) => {
                                            onSubmit(logininfo);
                                        })}>
                                            <Input
                                                containerClass="col-lg-12 p-0"
                                                label="New Password"
                                                type="password"
                                                placeholder="********"
                                                minLength={8}
                                                maxLength={10}
                                                isRequired
                                                errorMessage={
                                                    errors?.NewPassword?.message ||
                                                    error?.response?.data?.errors?.NewPassword?.map((e: any) => e)
                                                }
                                                onChange={() => clearErrors(["NewPassword"])}
                                                register={{
                                                    ...register("NewPassword", {
                                                        required: {
                                                            value: true,
                                                            message: "This Field is required",
                                                        },
                                                        pattern: {
                                                            value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,10}$/,
                                                            message:
                                                              "Check a password between 8 to 10 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
                                                          },
                                                    }),
                                                }}
                                            />

                                            <Input
                                                containerClass="col-lg-12 p-0"
                                                label="Confirm-Password"
                                                type="password"
                                                minLength={8}
                                                maxLength={10}
                                                placeholder="********"
                                                isRequired
                                                errorMessage={errors?.ConfirmPassword?.message}
                                                onChange={() => clearErrors(["ConfirmPassword"])}
                                                register={{
                                                    ...register("ConfirmPassword", {
                                                        required: {
                                                            value: true,
                                                            message: "This Field is required",
                                                        },
                                                        validate: (value: any) =>
                                                            value === password.current ||
                                                            "The password do not match",
                                                    }),
                                                }}
                                            />


                                            <div className='col-lg-12'>
                                                {
                                                    !isLoading ? <button type='submit' className="btn btn-primary btn-lg btn-block">Submit</button> : <button type='submit' className="btn btn-primary btn-lg btn-block" disabled>Submit <Spinner width={"1rem"} height={"1rem"} /></button>
                                                }
                                            </div>
                                        </form>
                                    </>)
                                        : <div className='text-center'><h3 className="mb-20 text-success">Link has been expired!!!</h3></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
