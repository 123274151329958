import { useQuery } from 'react-query'
import axiosInstance from '../../../_helper/Interceptor'

const fetchgeography = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GEOGRAPHYMAPPING_API}`)
    return response.data.Data
}

const fetchCountry = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_COUNTRY_API}`)
    return response.data.Data
}

const fetchZone = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_ZONE_API}`)
    return response.data.Data
}

const fetchState = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STATE_API}`)
    return response.data.Data
}

const fetchRegion = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REGION_API}`)
    return response.data.Data
}

const fetchDistrict = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DISTRICT_API}`)
    return response.data.Data
}

const fetchCity = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CITY_API}`)
    return response.data.Data
}

const fetchPincode = async () => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PINCODE_API}`)
    return response.data.Data
}

export const fetchZoneByID = async (id: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_ZONE_API}/${id}`)
    return response.data.Data
}

export const fetchStateByID = async (id: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STATE_API}/${id}`)
    return response.data.Data
}

export const fetchRegionByID = async (id: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REGION_API}/${id}`)
    return response.data.Data
}

export const fetchDistrictByID = async (id: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DISTRICT_API}/${id}`)
    return response.data.Data
}

export const fetchCityByID = async (id: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CITY_API}/${id}`)
    return response.data.Data
}

export const fetchPincodeByID = async (id: any) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PINCODE_API}/${id}`)
    return response.data.Data
}


export const fetchPincodeByState = async (id: any) => {
    if(id === 0 || id === undefined || null){
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PARENT_PINCODE_API}?StateId=${id}`)
    return response.data.Data
}

export const fetchPincodeByCity = async (id: any) => {
    if(id === 0 || id === undefined || null){
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${id}`)
    return response.data.Data
}

export const useGeographyFetch = () => {
    return useQuery("geography", fetchgeography)
}

export const useCountryFetch = () => {
    return useQuery("EditCountry", fetchCountry)
}

export const useZoneFetch = () => {
    return useQuery("EditZone", fetchZone)
}

export const useStateFetch = () => {
    return useQuery("EditState", fetchState)
}

export const useRegionFetch = () => {
    return useQuery("EditRegion", fetchRegion)
}

export const useDistrictFetch = () => {
    return useQuery("EditDistrict", fetchDistrict)
}

export const useCityFetch = () => {
    return useQuery("EditCity", fetchCity)
}

export const usePincodeFetch = () => {
    return useQuery("EditPincode", fetchPincode)
}


//RCPA
export const fetchDoctorByZone = async (id: any) => {
    if(id === 0 || id === undefined || null){
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REPORTINGRCPAGETDOCTOR_API}?zoneId=${id}`)
    return response.data.Data
}


export const fetchChemistByZone = async (id: any) => {
    if(id === 0 || id === undefined || null){
        return {}
    }
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REPORTINGRCPAGETCHEMIST_API}?zoneId=${id}`)
    return response.data.Data
}

