import { useEffect, useState } from "react";
import axiosInstance from "../../../_helper/Interceptor";
import docimage from "../../../assets/images/photo1.jpg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";

const BirthdayAnniversary = () => {
  const controller = new AbortController();
  const [details, setDetails] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [anniversaries, setAnniversaries] = useState([]);
  const [others, setOthers] = useState([]);
  const [selecteduser, setselecteduser]: any = useState(null);
  const [IsSelf, setIsSelf] = useState(false);

  const role = localStorage.getItem("Roles");

  const [loading, setLoading] = useState(false);
  const [searchloading, setSearchLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_BIRTHDAYANDANNIVERSARIES_API}?isSelf=false`, { signal: controller.signal }
      )
      .then((e) => {
        const data = e.data.Data;
        setDetails(data);
        filterData(data);
        setLoading(false);
      })
      .catch((error) => {
        if (controller.signal.reason.code !== 20) {

          toast.error(`${error.message}`, { position: "top-right" });
          setLoading(false);
          // toast.error(`${error.message}`, { position: "top-right" });
        }
        else {

          setLoading(false);
        }
      });
    return () => {
      controller.abort();
    }
  }, []);

  useEffect(() => {
    if (IsSelf) {
      setselecteduser({});
    }
  }, [IsSelf]);

  const handleSearch = async (e: any) => {
    setSearchLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_BIRTHDAYANDANNIVERSARIES_API
        }${IsSelf ? `?isSelf=true` : `?isSelf=false`}${selecteduser !== null && !IsSelf
          ? `&userId=${selecteduser?.value}`
          : ""
        }`
      )
      .then((res) => {
        const data = res.data.Data;
        setDetails(data);
        filterData(data);
        setSearchLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const filterData = (data: any) => {
    const birthdays = data.filter((item: any) => item.Occasion === "Birth Day");
    const anniversaries = data.filter(
      (item: any) => item.Occasion === "Anniversary Day"
    );
    const others = data.filter(
      (item: any) =>
        item.Occasion !== "Birth Day" && item.Occasion !== "Anniversary Day"
    );
    setBirthdays(birthdays);
    setAnniversaries(anniversaries);
    setOthers(others);
  };

  return (
    <>
      <Helmet>
        <title>Birthday & Anniversary</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Birthday & Anniversary</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/layout/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Birthday & Anniversary
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {role !== "MR" && (
        <div className="pd-20 card-box mb-30">
          <div className="clearfix mb-30">
            <div className="pull-left">
              <h4 className="text-blue h4">Birthday & Anniversary</h4>
            </div>
          </div>
          <form
            onSubmit={handleSubmit((data) => {
              handleSearch(data);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="User"
                name={"userId"}
                onChange={(e: any) => {
                  setselecteduser(e);
                  setValue("userId", e);
                }}
                value={selecteduser}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                Disabled={IsSelf ? true : false}
                keys={{ label: "Value", value: "Id" }}
                control={control}
              />

              <div
                className="col-lg-1 text-center"
                style={{ marginTop: "40px" }}
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    {...register("isSelf")}
                    id="IsSelf"
                    onClick={() => {
                      setIsSelf(!IsSelf);
                      setselecteduser(null);
                    }}
                    checked={IsSelf}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="IsSelf"
                    style={{ fontWeight: "bold" }}
                  >
                    Is Self
                  </label>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                {!searchloading ? (
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary" disabled>
                    Search <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}

      <div className="pd-20 card-box mb-30">
        {!loading ? (
          <>
            <div className="user-list mt-30">
              {details.length !== 0 ? (
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="text-center">Birthday</h3>
                    <ul>
                      {birthdays?.map((e: any) => (
                        <li className="d-flex mt-30" key={e.Id}>
                          <div className="col-3 text-center">
                            <div className="avatar flex-shrink-0">
                              <img
                                src={docimage}
                                className="border-radius-100 box-shadow"
                                width={70}
                                height={70}
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="font-14">
                              Doctor Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.DoctorName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              Category Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.CategoryName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              Speciality Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.SpecialityName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              City Name :
                              <span className="weight-600"> {e.CityName} </span>
                            </div>
                            <div className="font-14">
                              Date :
                              <span className="weight-600">
                                {" "}
                                {e.Date.split("T")[0]}{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h3 className="text-center">Anniversary</h3>
                    <ul>
                      {anniversaries?.map((e: any) => (
                        <li className="d-flex mt-30" key={e.Id}>
                          <div className="col-3 text-center">
                            <div className="avatar flex-shrink-0">
                              <img
                                src={docimage}
                                className="border-radius-100 box-shadow"
                                width={70}
                                height={70}
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="font-14">
                              Doctor Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.DoctorName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              Category Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.CategoryName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              Speciality Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.SpecialityName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              City Name :
                              <span className="weight-600"> {e.CityName} </span>
                            </div>

                            <div className="font-14">
                              Date :
                              <span className="weight-600">
                                {" "}
                                {e.Date.split("T")[0]}{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h3 className="text-center">Other</h3>
                    <ul>
                      {others?.map((e: any) => (
                        <li className="d-flex mt-30" key={e.Id}>
                          <div className="col-3 text-center">
                            <div className="avatar flex-shrink-0">
                              <img
                                src={docimage}
                                className="border-radius-100 box-shadow"
                                width={70}
                                height={70}
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="font-14">
                              Doctor Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.DoctorName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              Category Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.CategoryName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              Speciality Name :
                              <span className="weight-600 text-blue">
                                {" "}
                                {e.SpecialityName}{" "}
                              </span>
                            </div>
                            <div className="font-14">
                              City Name :
                              <span className="weight-600"> {e.CityName} </span>
                            </div>
                            <div className="font-14">
                              Occasion :
                              <span className="weight-600"> {e.Occasion} </span>
                            </div>
                            <div className="font-14">
                              Date :
                              <span className="weight-600">
                                {" "}
                                {e.Date.split("T")[0]}{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <h2 className="text-center align-items-center">
                  No Data Found
                </h2>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-12 text-center">
            <Spinner width={"10rem"} height={"10rem"} />
          </div>
        )}
      </div>
    </>
  );
};

export default BirthdayAnniversary;
