import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaginationServiceOfInvoice } from "../../../service/PaginationService";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { NullHandler } from "../../../common/NullHandler";
import { Helmet } from "react-helmet";

const Invoice = () => {
  let navigate = useNavigate();

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);
  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfInvoice.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <DataTable value={data.Payments} size="small">
          <Column field="PaymentMethodName" header="Payment Method"></Column>
          <Column field="InvoiceNumber" header="Invoice No"></Column>
          <Column field="Amount" header="Amount"></Column>
          <Column
            field="Notes"
            header="Notes"
            body={(data: any) => {
              return (
                <p
                  title={data.Notes}
                  style={{
                    width: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {data.Notes === "" ? " - " : data.Notes}
                </p>
              );
            }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const handleModalOpen = (e: any, i: any, n: any, closingBalance: any) => {
    navigate("/layout/inventory/AddPaymentInvoice", {
      state: { name: e, id: i, state: n, closingBalance: closingBalance },
    });
  };

  const allowExpansion = (rowData: any) => {
    return rowData?.Payments?.length > 0;
  };

  return (
    <div>
      <Helmet>
        <title>Invoice</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Invoice</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/Inventory"}>Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Invoice
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                {totalData?.some(allowExpansion) && (
                  <Column
                    expander={allowExpansion}
                    style={{ width: "2%", textAlign: "center" }}
                  />
                )}
                <Column field="SrNo" header="#" style={{ width: "5%" }} />
                <Column
                  field="InvoiceTypeName"
                  header="Invoice Type"
                  sortable
                  body={(data: any) => {
                    return NullHandler(data.InvoiceTypeName);
                  }}
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  style={{ width: "15%" }}
                  field="InvoiceNumber"
                  header="Invoice No"
                  body={(e: any) => {
                    return (
                      <Link
                        to={`/layout/viewinvoice/i/${e.Id}`}
                        target="_blank"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        {e.InvoiceNumber}
                      </Link>
                    );
                  }}
                ></Column>
                <Column
                  field="InvoiceAmount"
                  header="Invoice Amt"
                  sortable
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="PaymentDue"
                  header="Closing Balance"
                  style={{ width: "15%" }}
                  body={(e: any) => {
                    return e.PaymentDue > 0
                      ? e.PaymentDue + " (CR)"
                      : Math.abs(e?.PaymentDue) + " (DR)";
                  }}
                ></Column>
                <Column
                  header="Action"
                  alignHeader={"center"}
                  body={(e: any) => {
                    return (
                      <div className="d-flex" style={{ justifyContent: "center" }}>
                        {e.IsPaymentApplicable === true &&
                          e.InvoiceTypeId !== 3 &&
                          e.InvoiceTypeId !== 4 && (
                            <button
                              type="button"
                              className="btn p-0"
                              title="Payment"
                            >
                              <i
                                className="pi pi-wallet text-primary"
                                aria-hidden="true"
                                onClick={() => {
                                  handleModalOpen(
                                    e.InvoiceTypeName,
                                    e.InvoiceNumber,
                                    e.Id,
                                    e.PaymentDue
                                  );
                                }}
                              ></i>
                            </button>
                          )}
                      </div>
                    );
                  }}
                  style={{ width: "10%", textAlign: "center" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
