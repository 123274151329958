import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";

interface iPropsmodal {
    id?: any;
    setisOPen?: any;
    open?: any;
    fetch?: any;
    defaultValue?: any;
}

export const AddEditWareHouseModel = ({
    id,
    setisOPen,
    open,
    fetch,
    defaultValue,
}: iPropsmodal) => {
    useEffect(() => {
        if (defaultValue) {
            setValue("Name", defaultValue);
        }
    }, []);

    const apiname = process.env.REACT_APP_INVENTORYWAREHOUSE_API?.toString();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        clearErrors,
        reset,
    }: any = useForm({ mode: "all" });

    const { data, isLoading: SampleLoader }: any = useFetchAllID(
        apiname,
        id === 0 ? undefined : id
    );

    useEffect(() => {
        if (data) {
            reset(data);
        }

    }, [data]);

    const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
        data: response,
    }: any = useCreateByID(apiname, id === 0 ? undefined : id);
    const [selectedCompany, setSelectedCompany]: any = useState(null);
    const warehouseFormData = (data: any) => {
        for (let prop in data) {
            if (
                data[prop] === null ||
                data[prop] === undefined ||
                data[prop] === "" ||
                data[prop]?.length == 0
            ) {
                delete data[prop];
            }
        }

        let newData: any = {};

        Object.keys(data).forEach(
            (k) =>
                (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
        );
        let submitData = {};
        if (id) {
            submitData = {
                WarehouseToUpdate: newData
            }

        }
        else {
            submitData = {
                WarehouseToCreate: newData
            }

        }
        mutate(submitData);

    };

    useEffect(() => {

        if (isSuccess) {
            if (id === 0) {
                toast.success("Added Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            } else {
                toast.success("Updated Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            }
            setisOPen(false);
        }

        if (isError) {
            if (error?.response?.data?.StatusCode !== 400) {
                toast.error(error?.response?.data?.Message, { position: "top-right" });
            } else {
                toast.error("error...", { position: "top-right" });
            }
        }
    }, [isError, isSuccess, id]);
    useEffect(() => {
        if (id) {
            // setSelectedCompany(data?.CompanyId);

            if (data) {

                if (data.CompanyName !== null && data.CompanyName !== undefined && data.CompanyId !== null && data.CompanyId !== undefined) {
                    setSelectedCompany({
                        label: data.CompanyName,
                        value: data.CompanyId,
                    });
                    setValue("CompanyId", data?.CompanyId)
                }
                else {
                    setSelectedCompany(null);
                    setValue("CompanyId", null)
                }

            }
        }
    }, [data, id])

    return (
        <>
            <Dialog
                visible={open}
                onHide={() => setisOPen(false)}
                header={`${id === 0 ? "Add" : "Edit"} Warehouse`}
                modal
                style={{ width: "1100px" }}
            >
                {!SampleLoader ? (


                    <form onSubmit={handleSubmit(warehouseFormData)}>
                        <div className="row">
                            <Input

                                containerClass="col-lg-6"
                                label="Warehouse Name"
                                type="text"
                                isRequired
                                onChange={(e: any) => {
                                    setValue("Name", e.target.value);
                                    clearErrors(["Name"]);
                                }}
                                register={{
                                    ...register("Name", {
                                        required: {
                                            value: true,
                                            message: "Warehouse Name is required",
                                        },

                                    }),
                                }}
                                errorMessage={
                                    errors?.Name?.message ||
                                    error?.response?.data?.errors?.Name?.map(
                                        (e: any) => e
                                    )
                                }
                            />
                            {/* <ReactSelect

                                containerClass="col-lg-4"
                                label="Address"
                                name={"AddressId"}
                                onChange={(e: any) => {
                                    setValue("AddressId", e);
                                }}
                                control={control}
                                // api="Reporting/Employee/GetAllEmployeeWithUserId"
                                keys={{ label: "Value", value: "Id" }}
                                errorMessage={errors?.AddressId?.message}
                            /> */}


                            <ReactSelect
                                containerClass="col-lg-6"
                                isRequired
                                label="Company"
                                name={"CompanyId"}
                                onChange={(e: any) => {
                                    console.log(e);
                                    if (e !== null) {
                                        setValue("CompanyId", e?.value);
                                        setSelectedCompany(e);
                                    }
                                    else {

                                        setValue("CompanyId", null);
                                        setSelectedCompany(null);

                                    }

                                }}
                                value={selectedCompany}
                                control={control}
                                api="Reporting/Company?pageNumber=1&pageSize=10"
                                keys={{ label: "Value", value: "Id" }}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Company is required",
                                    },
                                }}
                                errorMessage={errors?.CompanyId?.message}
                            />


                            <div className="col-lg-12 text-center mt-3">
                                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                                    <span className="p-button-label p-c">Back</span>
                                </Button>

                                {
                                    id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setSelectedCompany(null); setValue("CompanyId", null); }} outlined className="ml-2">
                                        <span className="p-button-label p-c">Reset</span>
                                    </Button>
                                }

                                {isLoading ? (
                                    <Button type="submit" outlined disabled className="ml-2">
                                        <span className="p-button-label p-c">
                                            Submit <Spinner width={"1rem"} height={"1rem"} />
                                        </span>
                                    </Button>
                                ) : (
                                    <Button type="submit" outlined className="ml-2">
                                        <span className="p-button-label p-c">Submit</span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="pd-20 card-box mb-30 text-center">
                        <Spinner width={"15rem"} height={"15rem"} />
                    </div>
                )}
            </Dialog>
        </>
    );
};
