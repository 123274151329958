import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}
export const AddEditEventsEmployeeMap = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_EVENTSEMPLOYEEMAP_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    control,
  }: any = useForm({ mode: "all" });
  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const [selectedEventName, setSelectedEventName]: any = useState(null);
  const [selectedEmployeeName, setSelectedEmployeeName]: any = useState(null);
  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (data.EventId !== null && data.EventId !== undefined) {
          setValue("EventId", {
            label: data.EventName,
            value: data.EventId,
          });
          setSelectedEventName({
            label: data.EventName,
            value: data.EventId,
          });
        } else {
          setValue("EventId", null);
          setSelectedEventName(null);
        }

        if (data.EmployeeId !== null && data.EmployeeId !== undefined) {
          setValue("EmployeeId", {
            label: data.EmployeeName,
            value: data.EmployeeId,
          });
          setSelectedEmployeeName({
            label: data.EmployeeName,
            value: data.EmployeeId,
          });
        } else {
          setValue("EmployeeId", null);
          setSelectedEmployeeName(null);
        }
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const EventsEmployeeMapFormData = (data: any) => {
    data.EventId = selectedEventName?.value;
    data.EmployeeId = selectedEmployeeName?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};

    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );

    mutate(newData);
  };
  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Events Employee Map`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(EventsEmployeeMapFormData);
              }
            }}
            onSubmit={handleSubmit(EventsEmployeeMapFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Event Name"
                name={"EventId"}
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("EventId", e?.value);
                    setSelectedEventName(e);
                  } else {
                    setValue("EventId", null);
                    setSelectedEventName(null);
                  }
                }}
                value={selectedEventName}
                control={control}
                api={process.env.REACT_APP_EVENT_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EventId?.message ||
                  error?.response?.data?.errors?.EventId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Event Name is required" },
                }}
              />
              <ReactSelect
                containerClass="col-lg-6"
                label="Employee Name"
                name={"EmployeeId"}
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("EmployeeId", e?.value);
                    setSelectedEmployeeName(e);
                  } else {
                    setValue("EmployeeId", null);
                    setSelectedEmployeeName(null);
                  }
                }}
                value={selectedEmployeeName}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Employee Name is required",
                  },
                }}
              />
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="custom-control custom-checkbox pt-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        {...register("IsLead")}
                        id="IsLead"
                      />
                      <label className="custom-control-label" htmlFor="IsLead">
                        IsLead
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setSelectedEmployeeName(null);
                      setSelectedEventName(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
