import React from 'react';
import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditEventType } from './AddEditEventType';
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from 'primereact/api';


const EventType = () => {
    const apiname = process.env.REACT_APP_EVENTTYPE_API?.toString();
    const [deleteID, setdeleteID]: any = useState(null);

    const [selectedRows, setSelectedRows]: any = useState([]);

    const [bool, setbool]: any = useState(null);

    const deleteiddetect = (row: any) => {
        setdeleteID(row);
    };
    const columnData = [
        {
            field: "srNo",
            header: "#",
            width: "5%",
        },
        {
            header: "Action",
            sortable: false,
            filter: false,
            body: (e: any) => actionEventTypeTemplate(e),
            width: "30%",
        },
        {
            field: "Name",
            header: "Event Type Name",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by Name",
            body: null,
            width: "10vw",
        },

    ];

    const filterJson = {
        Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    }
    const [updateId, setupdateId]: any = useState(null);
    const actionEventTypeTemplate = (id: any) => {

        console.log("ID", id.Id)
        return (
            <>
                <Action
                    id={id.Id}
                    editModal={() => {
                        setupdateId(id.Id);
                    }}
                    displayid={deleteiddetect}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>Event Type</title>
            </Helmet>

            <div className="min-height-200px">
                <PageHeader
                    headTitle="Event Type"
                    onClick={() => {
                        setupdateId(0);
                    }}
                    id={0}
                    list={true}
                    linkflag
                    linkflagURL={"/layout/eventattributes"}
                    linkflagtitle={"Event Attributes"}
                />

                <DatatableCommon
                    apiname={apiname}
                    filterJson={filterJson}
                    setselecteditem={setSelectedRows}
                    selecteditem={selectedRows}
                    columnData={columnData}
                    refetchData={bool}
                    setrefetchData={setbool}
                />

                {updateId !== null && (
                    <AddEditEventType
                        fetch={(data: boolean) => {
                            if (data) {
                                setbool(true);
                            }
                        }}
                        setisOPen={() => {
                            return setupdateId(null);
                        }}
                        open={updateId !== null}
                        id={updateId}
                    />
                )}
            </div>

            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => {
                    if (data) {
                        setbool(true);
                    }
                }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />

        </>
    )

}

export default EventType;