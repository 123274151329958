import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { NullHandler } from "../../common/NullHandler";
import ReactSelect from "../../common/ReactSelect";
import axiosInstance from "../../_helper/Interceptor";
import { Badge } from "primereact/badge";
import {
  PaginationServiceByChemist,
  PaginationServiceByProduct,
  PaginationServiceBySecondaryProductServices,
} from "../../service/PaginationService";
import { toast } from "react-toastify";
import { Spinner } from "../../common/Spinner";
import { useForm } from "react-hook-form";
import { error } from "console";
import { Helmet } from "react-helmet";

const SecondaryProductMap = () => {
  const controller = new AbortController();
  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ mode: "all" });

  const [stockistData, setStockistData]: any = useState({});

  const [totalRecord, setTotalRecords] = useState();
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });
  const [expandedRows, setExpandedRows]: any = useState(null);

  function compareKeys(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    console.log(aKeys, "aKeys");
    return aKeys[0] == b;
  }

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByProduct.getData(lazyState, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  const [totalSubRecord, setTotalSubRecord] = useState();
  const [totalSubData, settotalSubData]: any = useState(null);
  const [subLoading, setSubLoading] = useState(false);
  const [lazySubState, setlazySubState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: "",
  });
  const [expandedSubRows, setExpandedSubRows]: any = useState(null);

  useEffect(() => {
    if (lazySubState.paramter !== "") {
      loadSubLazyData();
    }
    return () => {
      controller.abort();
    };
  }, [lazySubState]);

  let networkSubTimeout: any = null;

  const loadSubLazyData = () => {
    //
    setSubLoading(true);

    if (networkSubTimeout) {
      clearTimeout(networkSubTimeout);
    }

    //imitate delay of a backend call
    networkSubTimeout = setTimeout(() => {
      PaginationServiceBySecondaryProductServices.getData(
        lazySubState,
        controller.signal
      )
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalSubData(temp);
          setTotalSubRecord(data.TotalDataCount);
          setSubLoading(false);
        })
        .catch((error) => {
          if (controller.signal.reason.code !== 20) {
            setSubLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onSubPageChange = (e: any) => {
    setExpandedSubRows({});
    setlazySubState({
      ...lazySubState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  const [load, setLoad] = useState(false);
  const [isDataFound, setIsDataFound] = useState(false);
  const [state, setState]: any = useState(null);
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [selected, setSelected]: any = useState(null);

  const onSubmit = (e: any) => {
    //
    setLoad(true);
    // https://doctoexcel.aisante.in/doc/
    // https://qadoctoexcel.aisante.in/doc/

    // https://qachemistmap.aisante.in/
    axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP}MatchProductNames`,
        e
      )
      .then((data: any) => {
        setLoad(false);
        let temp_data = data.data.data;
        temp_data?.suggestions.push({
          divisionId: 0,
          name: "Other",
          productId: 0,
        });
        setState(temp_data);
        setSelectedProduct(null);
        setIsDataFound(true);
      })
      .catch((err: any) => {
        setState(null);
        setLoad(false);
        setIsDataFound(false);
      });
  };

  const tempFunction = (rowData: any) => {
    return (
      <Badge
        style={{ cursor: "pointer" }}
        severity={
          rowData?.id ==
          (expandedRows !== null ? Object?.keys(expandedRows)[0] : "")
            ? "warning"
            : "info"
        }
        value={rowData.StockistName}
        onClick={() => {
          let obj: any = {};
          if (expandedRows != null && compareKeys(expandedRows, rowData.id)) {
            obj = {};
          } else {
            obj[rowData.id] = true;
          }
          setStockistData(rowData);
          settotalSubData([]);
          setExpandedSubRows(null);
          setlazySubState({
            first: 0,
            rows: 10,
            page: 1,
            search: "",
            paramter: rowData.StockistName,
          });
          setExpandedRows(obj);
        }}
      ></Badge>
      // <a
      //   key={"id-1"}
      //   // className="anchor"

      //   // style={{ color: "red", cursor: "pointer" }}

      //   style={{
      //     color: `${rowData?.id ==
      //       (expandedRows !== null
      //         && Object?.keys(expandedRows)[0]
      //       )
      //       ? "blue"
      //       : "red"

      //       }`,
      //     cursor: "pointer "
      //   }}
      // >

      // </a>
    );
  };

  const [productName, setProductName] = useState();

  const tempSubFunction = (rowData: any) => {
    return (
      <Badge
        style={{ cursor: "pointer" }}
        severity={
          rowData?.id ==
          (expandedSubRows !== null ? Object?.keys(expandedSubRows)[0] : "")
            ? "warning"
            : "info"
        }
        value={rowData.productName}
        onClick={() => {
          //
          let obj: any = {};
          if (
            expandedSubRows != null &&
            compareKeys(expandedSubRows, rowData.id)
          ) {
            obj = {};
          } else {
            obj[rowData.id] = true;
            onSubmit(rowData);
            setProductName(rowData.productName);
          }
          setExpandedSubRows(obj);
        }}
      ></Badge>
      // <a
      //   key={"id-1"}

      //   // style={{ color: "red" }}
      //   style={{
      //     color: `${rowData?.id ==
      //       (expandedSubRows !== null
      //         && Object?.keys(expandedSubRows)[0]
      //       )
      //       ? "blue"
      //       : "red"

      //       }`, cursor: "pointer"
      //   }}
      // >

      // </a>
    );
  };

  const onUpdate = () => {
    const temp = {
      stockistId: stockistData?.StockistId,
      stockistName: stockistData?.StockistName,
      productName: productName,
      MatchingProductId:
        selectedProduct.productId !== 0
          ? selectedProduct.productId
          : selected.value,
    };

    console.log(temp, "Temp Data");

    axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL_PYTHON_CHEMISTMAP}UpdateProductName`,
        temp
      )
      .then((e) => {
        console.log(e, "dksnds");
        setExpandedSubRows({});
        loadSubLazyData();
      })
      .catch((err: any) => {
        console.log(err, "Error");
      });
  };

  const subRowExpansionTemplate = () => {
    return (
      <>
        <div className="p-3">
          {!load ? (
            <div className="col-md-12 text-center">
              <h3 className="mb-2">
                {!isDataFound
                  ? "No Suggestions found Please Select Manually"
                  : "Suggestions found"}
              </h3>

              {isDataFound && (
                <DataTable
                  // paginator
                  // rows={10}
                  // rowsPerPageOptions={[10, 20, 50, 100]}
                  value={state?.suggestions}
                  selectionMode={"radiobutton"}
                  selection={selectedProduct}
                  onSelectionChange={(e) => {
                    console.log(e);
                    setSelectedProduct(e?.value);
                  }}
                  dataKey="productId"
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column
                    selectionMode="single"
                    headerStyle={{ width: "3rem" }}
                  ></Column>

                  <Column field="name" header="Product Name"></Column>
                </DataTable>
              )}

              {(!isDataFound || selectedProduct?.name === "Other") && (
                <ReactSelect
                  containerClass="col-lg-12"
                  label="Product"
                  control={control}
                  // useCustomOption
                  flag
                  name="DegreeTypeId"
                  isRequired
                  onChange={(e: any) => {
                    setValue("DegreeTypeId", e?.value);
                    setSelected(e);
                  }}
                  value={selected}
                  api={process.env.REACT_APP_PRODUCT_API}
                  keys={{
                    label: "Name",
                    value: "ProductId",
                  }}
                  errorMessage={errors?.DegreeTypeId?.message}
                  rules={{
                    required: {
                      value: true,
                      message: "Please Select Product",
                    },
                  }}
                />
              )}

              <div className="mt-3">
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(onUpdate)}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <div className="col-md-12 text-center">
              <Spinner width={"5rem"} height={"5rem"} />
            </div>
          )}
        </div>
      </>
    );
  };

  const rowExpansionTemplate = () => {
    return (
      <>
        <div className="p-3">
          <DataTable
            value={totalSubData}
            scrollable
            lazy
            dataKey="id"
            className="custom-datatable"
            showGridlines
            first={lazySubState.first}
            rows={lazySubState.rows}
            totalRecords={totalSubRecord}
            rowsPerPageOptions={[10, 20, 50, 100]}
            loading={subLoading}
            size="small"
            rowHover
            onPage={onSubPageChange}
            emptyMessage="No data found."
            tableStyle={{ minWidth: "50rem" }}
            stripedRows
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            expandedRows={expandedSubRows}
            rowExpansionTemplate={subRowExpansionTemplate}
          >
            <Column field="SrNo" header="#" style={{ width: "5%" }} />
            <Column
              key={"ProductName"}
              field="productName"
              header="Product Name"
              body={tempSubFunction}
            ></Column>
          </DataTable>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Secondary Product Map</title>
      </Helmet>
      <div className="min-height-200px">
        <DataTable
          value={totalData}
          scrollable
          lazy
          dataKey="id"
          showGridlines
          className="custom-datatable"
          stripedRows
          paginator
          removableSort
          first={lazyState.first}
          rows={lazyState.rows}
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRecords={totalRecord}
          onPage={onPageChange}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          loading={loading}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          size="small"
          rowHover
          emptyMessage="No data found."
          tableStyle={{ minWidth: "50rem" }}
          expandedRows={expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column field="SrNo" header="#" />
          <Column
            key={"StockistName"}
            field="StockistName"
            header="Stockist Name"
            body={tempFunction}
          ></Column>

          <Column
            key={"CityName"}
            field="CityName"
            header="City Name"
            body={(e) => {
              return NullHandler(e.CityName);
            }}
          ></Column>
          <Column
            key={"EmployeeName"}
            field="EmployeeName"
            header="Employee Name"
            body={(e) => {
              return NullHandler(e.EmployeeName);
            }}
          ></Column>
          <Column
            key={"ContactPerson"}
            field="ContactPerson"
            header="Contact Person Name"
            body={(e) => {
              return NullHandler(e.ContactPerson);
            }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default SecondaryProductMap;
