import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PaginationServiceOfVendorCreditNote } from "../../../service/PaginationService";
import { Paginator } from "primereact/paginator";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PageHeader } from "../../../common/PageHeader";

const VendorCreditNote = () => {
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfVendorCreditNote.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller?.signal?.reason?.code == 20) {
            console.log('Request Canceled', error)
          }
          else {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3 text-center">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const [updateId, setupdateId]: any = useState(null);

  return (
    <div>
      <Helmet>
        <title>Vendor Credit Note</title>
      </Helmet>

      <div className="min-height-200px">
      <PageHeader
          headTitle="Vendor Credit Note"
          list={true}
          LinkURL={"/layout/inventory/AddVendorCreditNote"}
          linkflag
          linkflagURL={"/layout/Inventory"}
          linkflagtitle={"Inventory"}
        />

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="id"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="SrNo" header="#" style={{ width: "3rem" }} />
                <Column
                  field="Name"
                  header="Name"
                  sortable
                  style={{ width: "15rem" }}
                ></Column>
                <Column
                  field="CreditNoteNumber"
                  header="Credit Note No"
                  sortable
                  style={{ width: "15rem" }}
                ></Column>
                <Column
                  field="InvoiceNumber"
                  header="Purchase Return No"
                  sortable
                  style={{ width: "15rem" }}
                ></Column>
                <Column
                  field="ReturnType"
                  header="Return Type"
                  style={{ width: "15rem" }}
                ></Column>
                <Column
                  field="CreditNoteAmount"
                  header="Credit Note Amt"
                  style={{ width: "15rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorCreditNote;
