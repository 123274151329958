import { useEffect, useRef, useState } from "react";
import { Input } from "../../../common/Input";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
import { Spinner } from "../../../common/Spinner";
import { Helmet } from "react-helmet";
import ReactSelect from "../../../common/ReactSelect";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import moment from "moment";
import { Button } from "primereact/button";
import DCRActionModal from "../../../common/DCRActionModal";

enum Type {
  Doctor = 1,
  Hospital = 2,
  Chemist = 3,
  Stockist = 4,
}

export const AdminDCR = () => {
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [gridData, setgridData]: any = useState();
  const [reportFor, setReportFor]: any = useState(1);
  const [id, setId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  }: any = useForm();

  const role = localStorage.getItem("Roles");

  const user: any = watch("Self");
  const [selecteduser, setselecteduser]: any = useState(null);
  const api_data_mapping: any = {
    1: "DoctorList",
    2: "HospitalList",
    3: "ChemistList",
    4: "StockistList",
  };

  const [subLazyState, setSubLazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
  });

  const [subLoading, setSubLoading] = useState(false);
  const [subTotalRecord, setsubTotalRecord] = useState();
  const [apiData, setApiData]: any = useState({});
  const [Nodata, setNodata] = useState(false);
  const [doctorVisitLog, setDoctorVisitLog]: any = useState(null);
  const [chemistVisitLog, setChemistVisitLog]: any = useState(null);
  const [hospitalVisitLog, setHospitalVisitLog]: any = useState(null);

  const onSubPageChange = (e: any) => {
    console.log(e, "error");
    setSubLazyState({
      ...subLazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });

    setApiData({
      ...apiData,
      PageNumber: e.page + 1,
      PageSize: e.rows,
    });
  };

  const getAllMissedAPI = async () => {
    // setLoading(true);
    debugger;
    await axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DCRGETALLADMIN_API}`,
        apiData
      )
      .then((res) => {
        setLoading(false);
        let Temp = res?.data?.Data[api_data_mapping[reportFor]].map(
          (d: any, index: any) => {
            return {
              ...d,
              srNo:
                res?.data?.PageNumber * res?.data?.PageSize -
                res?.data?.PageSize +
                (index + 1),
            };
          }
        );
        setgridData(Temp);
        setsubTotalRecord(res.data.TotalDataCount);
        if (res.data.Data[api_data_mapping[reportFor]].length === 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }
        console.log(gridData, "Data Final");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllMissedAPI();
  }, [apiData]);

  const handle = async (e: any) => {
    setSubLazyState({
      ...subLazyState,
      first: 0,
      rows: 10,
      page: 1,
    })
    setHospitalExpandrow(null);
    setChemistExpandrow(null);
    setDoctorExpandrow(null);
    setcompetitorsubexpandedrows(null);

    setLoading(true);

    if (user == true) {
      delete e.UserId;
    }

    let data = {
      FromDate: startdate,
      ToDate: defaultDate,
      ReportTypeId: Number(e.ReportTypeId),
      ReportFor: Number(e.ReportFor),
      DoctorId: Number(e?.DoctorId?.value),
      UserId: selecteduser !== null ? selecteduser?.value : null,
      PageSize: 10,
      PageNumber: 1,
      Self: e.Self,
    };

    setApiData(data);
    setNoData(true);
  };

  // To GetVisitTimeLog For doctor
  let GetVisitTimeLog = (id: any, type: string) => {
    setSubLoading(true);
    if (type === "chemist") {
      axiosInstance
        .get(
          `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTVISITGETBYCHEMISTVISITID_API}/${id}`
        )
        .then((res) => {
          setChemistVisitLog(res.data.Data);
          setSubLoading(false);
        });
      return;
    } else {
      axiosInstance
        .get(
          `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_VISITREPORTGETVISITTIMELOG_API
          }?${type == "doctor"
            ? `DoctorVistId=${id}`
            : type == "chemist"
              ? `ChemistVistId=${id}`
              : `HospitalVistId=${id}`
          }`
        )
        .then((res) => {
          console.log(res.data.Data.doctorVisitReportViewModels, "this is res");
          let temp1 = res.data.Data.doctorVisitReportViewModels.map(
            (d: any, index: any) => {
              return {
                ...d,
                SrNo: index + 1,
              };
            }
          );
          setDoctorVisitLog(temp1);

          let temp = res.data.Data.hospitalVisitReportViewModels.map(
            (d: any, index: any) => {
              return {
                ...d,
                SrNo: index + 1,
              };
            }
          );
          setHospitalVisitLog(temp);
          setSubLoading(false);
        })
        .catch((err: any) => {
          setSubLoading(false);
        });
    }
  };

  const doctordata = (id: any) => {
    return (
      <div className="d-flex justify-content-around row">
        <div style={{ float: "left" }} className="col-1">
          <Badge severity={"danger"} value={id.CategoryName}></Badge>
        </div>
        <div className="col-6" >
          <Badge
            onClick={() => {
              let obj: any = {};
              if (
                doctorExpandrow != null &&
                compareKeys(doctorExpandrow, id.DoctorVisitId)
              ) {
                obj = {};
              } else {
                obj[id.DoctorVisitId] = true;
              }
              setDoctorExpandrow(obj);
              GetVisitTimeLog(id.DoctorVisitId, "doctor");
            }}
            style={{ cursor: "pointer" }}
            severity={"info"}
            value={
              <div
                style={{
                  display: "inline-block",
                  maxWidth: "250px", // Adjust as needed
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  verticalAlign: "middle",
                }}
                title={id.DoctorName} // Tooltip with full name
              >
                {id.DoctorName}
              </div>
            }
          ></Badge>
        </div>

        <div style={{ float: "right" }} className="col-5">

          <Badge
            severity={"success"}
            value={
              <div
                style={{
                  display: "inline-block",
                  maxWidth: "150px", // Adjust as needed
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  verticalAlign: "middle",
                }}
                title={
                  id.SpecialityList.length === 0
                    ? ""
                    : id.SpecialityList.map((e: any) => {
                      return e.SpecialityName;
                    })?.join(" , ")
                } // Tooltip with full name
              >
                {
                  id.SpecialityList.length === 0
                    ? ""
                    : id.SpecialityList.map((e: any) => {
                      return e.SpecialityName;
                    })?.join(" , ")
                }
              </div>
            }
          ></Badge>
        </div>




      </div>
    );
  };

  const chemistdata = (id: any) => {
    return (
      <>
        <span className="mr-2">
          <Badge
            onClick={() => {
              let obj: any = {};
              if (
                chemistExpandrow != null &&
                compareKeys(chemistExpandrow, id.ChemistVisitId)
              ) {
                obj = {};
              } else {
                obj[id.ChemistVisitId] = true;
              }
              setChemistExpandrow(obj);
              GetVisitTimeLog(id.ChemistVisitId, "chemist");
            }}
            style={{ cursor: "pointer" }}
            severity={"info"}
            value={id.ChemistName}
          ></Badge>
        </span>
      </>
    );
  };

  const hospitaltdata = (id: any) => {
    return (
      <>
        <span className="mr-2">
          <Badge
            onClick={() => {
              let obj: any = {};
              if (
                hospitalExpandrow != null &&
                compareKeys(hospitalExpandrow, id.HospitalVisitId)
              ) {
                obj = {};
              } else {
                obj[id.HospitalVisitId] = true;
              }
              setHospitalExpandrow(obj);
              GetVisitTimeLog(id.HospitalVisitId, "");
            }}
            style={{ cursor: "pointer" }}
            severity={"info"}
            value={id.HospitalName}
          ></Badge>
        </span>
      </>
    );
  };

  function compareKeys(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    return aKeys[0] == b;
  }

  useEffect(() => {
    if (user == true) {
      setselecteduser(null);
    }
  }, [user]);

  const [competitorsubexpandedrows, setcompetitorsubexpandedrows]: any =
    useState(null);
  const [doctorExpandrow, setDoctorExpandrow]: any = useState(null);
  const [chemistExpandrow, setChemistExpandrow]: any = useState(null);
  const [hospitalExpandrow, setHospitalExpandrow]: any = useState(false);

  const doctorRowExpansionTemplate = (rowData: any) => {
    return (
      <div>
        <DataTable value={doctorVisitLog} dataKey="id" loading={subLoading}>
          <Column field="SrNo" header="#" />
          <Column
            field="ProductName"
            header="ProductName"
            bodyStyle={{ fontSize: "12px" }}
            style={{ width: "5%" }}
          />
          <Column
            field="StartTime"
            header="StartTime"
            body={(e: any) => {
              return moment(e.StartTime).format("DD/MM/YY hh:mm:ss A");
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="EndTime"
            header="EndTime"
            body={(e: any) => {
              return moment(e.EndTime).format("DD/MM/YY hh:mm:ss A");
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="Duration"
            header="Duration (seconds)"
            body={(e: any) => {
              var duration = moment?.duration(
                moment(e.EndTime).diff(moment(e.StartTime))
              );
              var hours = duration?.asSeconds();
              return hours;
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
        </DataTable>
      </div>
    );
  };

  const chemistRowExpansionTemplate = (rowData: any) => {
    return (
      <div>
        {chemistVisitLog?.LinkedDoctorList?.length > 0 && (
          <DataTable
            value={chemistVisitLog?.LinkedDoctorList}
            className="m-2"
            loading={subLoading}
          >
            <Column
              field="Value"
              header="Linked Doctor List"
              bodyStyle={{ fontSize: "12px" }}
            />
          </DataTable>
        )}
        {chemistVisitLog?.UnlinkedDoctorList?.length > 0 && (
          <DataTable
            value={chemistVisitLog?.UnlinkedDoctorList}
            className="m-2"
            loading={subLoading}
          >
            <Column
              field="Value"
              header="Unlinked Doctor List"
              bodyStyle={{ fontSize: "12px" }}
              style={{ width: "5%" }}
            />
          </DataTable>
        )}
        {chemistVisitLog?.RcpaOurProductList?.length > 0 && (
          <DataTable
            value={chemistVisitLog?.RcpaOurProductList}
            dataKey="OurCompetingProductName"
            expandedRows={competitorsubexpandedrows}
            rowExpansionTemplate={CompetitorrowExpansionTemplate}
            className="m-2"
            loading={subLoading}
          >
            <Column
              field="OurCompetingProductName"
              header="Our Competitor Product Name"
              bodyStyle={{ fontSize: "12px" }}
              body={(e: any) => {
                return (
                  <Badge
                    style={{ cursor: "pointer" }}
                    severity={"info"}
                    value={e.OurCompetingProductName}
                    onClick={() => {
                      console.log("calling", e);
                      let obj: any = {};
                      if (
                        competitorsubexpandedrows != null &&
                        compareKeys(
                          competitorsubexpandedrows,
                          e?.OurCompetingProductName
                        )
                      ) {
                        obj = {};
                      } else {
                        obj[e?.OurCompetingProductName] = true;
                      }
                      setcompetitorsubexpandedrows(obj);
                    }}
                  ></Badge>
                );
              }}
            />
            <Column
              field="Price"
              header="Price"
              bodyStyle={{ fontSize: "12px" }}
            />
            <Column
              field="OurCompetingProductSellQty"
              header="Our Competing Product Sell Qty"
              bodyStyle={{ fontSize: "12px" }}
            />
            <Column
              field="DailyRx"
              header="Daily Rx"
              bodyStyle={{ fontSize: "12px" }}
            />
            <Column
              field="WeeklyRx"
              header="Weekly Rx"
              bodyStyle={{ fontSize: "12px" }}
            />
            <Column
              field="MonthlyRx"
              header="Monthly Rx"
              bodyStyle={{ fontSize: "12px" }}
            />
          </DataTable>
        )}

        {chemistVisitLog?.PromotedGiftList?.length > 0 && (
          <DataTable
            value={chemistVisitLog?.PromotedGiftList}
            className="m-2"
            loading={subLoading}
          >
            <Column
              field="GiftName"
              header="Promoted Gift Name"
              bodyStyle={{ fontSize: "12px" }}
            />
            <Column
              field="SampleQty"
              header="Sample Qty"
              bodyStyle={{ fontSize: "12px" }}
            />
          </DataTable>
        )}
      </div>
    );
  };

  const hospitalRowExpansionTemplate = (rowData: any) => {
    return (
      <div>
        <DataTable value={hospitalVisitLog} dataKey="id" loading={subLoading}>
          <Column field="SrNo" header="#" style={{ width: "5%" }} />
          <Column
            field="ProductName"
            header="Product Name"
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="StartTime"
            header="Start Time"
            body={(e: any) => {
              return moment(e.StartTime).format("DD/MM/YY hh:mm:ss A");
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="EndTime"
            header="End Time"
            body={(e: any) => {
              return moment(e.EndTime).format("DD/MM/YY hh:mm:ss A");
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="Duration"
            header="Duration (seconds)"
            body={(e: any) => {
              var duration = moment?.duration(
                moment(e.EndTime).diff(moment(e.StartTime))
              );
              var hours = duration?.asSeconds();
              return hours;
            }}
            bodyStyle={{ fontSize: "12px" }}
          />
        </DataTable>
      </div>
    );
  };

  const CompetitorrowExpansionTemplate = (rowData: any) => {
    console.log("rowdata", rowData);
    return (
      <div>
        <DataTable value={rowData?.CompetitorProducts}>
          <Column
            field="CompetitorProductName"
            header="Competitor Product Name"
            bodyStyle={{ fontSize: "12px" }}
          />
          <Column
            field="CompetitorProductSellQty"
            header="Competitor Product Sell Qty"
            bodyStyle={{ fontSize: "12px" }}
          />
        </DataTable>
      </div>
    );
  };

  // date
  const [startdate, setDate] = useState("");

  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split("T")[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split("T")[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  const [type, setType]: any = useState<Type>(Type.Doctor);

  const actionBodyTemplate = (e: any) => {
    console.log(e.CompletedDate);
    let dateToCheck = moment(e.CompletedDate);
    let today = moment();
    let ValidateDay: boolean = dateToCheck.isSame(today, 'day')
    if (ValidateDay) {
      return <div>
        <button className="btn p-0">
          <i
            className="pi pi-eye mr-1 text-primary"
            onClick={() => {
              setType(Type.Doctor);
              setId(e.DoctorVisitId);
            }}
          ></i>
        </button>
      </div>
    }
  };

  return (
    <>
      <Helmet>
        <title>View DCR</title>
      </Helmet>
      <div className="col-md-12 col-sm-12 mb-30">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>DCR Report</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/layout/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/layout/reports">Reports</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    DCR Report
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box">
          <form
            onSubmit={handleSubmit((data: any) => {
              handle(data);
            })}
          >
            <div className="row">
              <div className={role === "MR" ? "col-lg-6" : "col-lg-4"}>
                <div className="form-group">
                  <label className="col-form-label">
                    Report Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="custom-select col-12"
                    {...register("ReportTypeId", {
                      required: {
                        value: true,
                        message: "Report Type is required",
                      },
                      onchange,
                    })}
                  >
                    <option value={1}>Visit</option>
                    <option value={2}>Cancel</option>
                    <option value={3}>Missed</option>
                  </select>
                </div>
                <ErrorMessage message={errors?.ReportTypeId?.message} />
              </div>

              <Input
                containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
                label="From Date"
                type="date"
                isRequired
                errorMessage={errors?.formDate?.message}
                value={startdate}
                onChange={handleDateChange}
              />

              {role !== "MR" && (
                <ReactSelect
                  containerClass="col-lg-4"
                  label="User"
                  control={control}
                  name="UserId"
                  onChange={(e: any) => {
                    setselecteduser(e);
                    setValue("UserId", e?.value);
                  }}
                  value={selecteduser === null ? null : selecteduser}
                  api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                  keys={{ label: "Value", value: "Id" }}
                  Disabled={user ? true : false}
                  errorMessage={errors?.UserId?.message}
                />
              )}

              <div className={role === "MR" ? "col-lg-6" : "col-lg-4"}>
                <div className="form-group">
                  <label className="col-form-label">
                    Report For <span className="text-danger">*</span>
                  </label>
                  <select
                    className="custom-select col-12"
                    {...register("ReportFor", {
                      required: {
                        value: true,
                        message: "Report For is required",
                      },
                    })}
                    onChange={(e) => {
                      setValue("ReportFor", e.target.value, {
                        shouldValidate: true,
                      });
                      setReportFor(Number(e.target.value));
                      setgridData([]);
                    }}
                  >
                    <option value={1}>Doctor</option>
                    <option value={2}>Hospital</option>
                    <option value={3}>Chemist</option>
                    <option value={4}>Stockist</option>
                  </select>
                </div>
                <ErrorMessage message={errors?.ReportFor?.message} />
              </div>

              <Input
                containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
                label="To Date"
                type="date"
                isRequired
                errorMessage={errors?.toDate?.message}
                value={defaultDate}
                onChange={handleEndDateChange}
              />

              {role !== "MR" && (
                <div className="col-lg-4 mt-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="custom-control custom-checkbox pt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          {...register("Self")}
                          id="Self"
                        />
                        <label className="custom-control-label" htmlFor="Self">
                          Self
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-12 text-center mt-3">
                {!loading ? (
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit" disabled>
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      {reportFor == 1 && gridData?.length > 0 ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box">
            <DataTable
              value={gridData}
              dataKey="DoctorVisitId"
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={subLazyState.first}
              rows={subLazyState.rows}
              totalRecords={subTotalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onSubPageChange}
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              key="DoctorVisitId"
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              expandedRows={doctorExpandrow}
              rowExpansionTemplate={doctorRowExpansionTemplate}
            >
              <Column field="srNo" header="#" />
              <Column
                field="UserName"
                header="User Name"
                body={null}
                bodyStyle={{ fontSize: "12px", fontWeight: "bold" }}
              ></Column>

              <Column
                header="Doctor Name"
                field="DoctorName"
                body={(e: any) => doctordata(e)}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="City"
                header="City"
                body={(e: any) => {
                  return e.City === null ? "-" : e.City;
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="TourDate"
                header="Tour Date"
                body={(e: any) => {
                  if (e.TourDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.TourDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.TourDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="CompletedDate"
                header="Completed Date"
                body={(e: any) => {
                  if (e.CompletedDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.CompletedDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.CompletedDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="ExpectedVisitTime"
                header="Expected Visit Time"
                body={(e: any) => {
                  if (e.ExpectedVisitTime === null) {
                    return "-";
                  } else {
                    // moment(e.ExpectedVisitTime).format(
                    //   "DD/MM/YY hh:mm A")
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.ExpectedVisitTime).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.ExpectedVisitTime).format("hh:mm A")}
                          // style={{ backgroundColor: "red" }}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              {role == "MR" && (
                <Column header="Action" body={actionBodyTemplate}></Column>
              )}
            </DataTable>
          </div>
        </div>
      ) : reportFor == 2 && gridData?.length > 0 ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box">
            <DataTable
              value={gridData}
              dataKey="HospitalVisitId"
              key={"HospitalVisitId"}
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={subLazyState.first}
              rows={subLazyState.rows}
              totalRecords={subTotalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onSubPageChange}
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              expandedRows={hospitalExpandrow}
              rowExpansionTemplate={hospitalRowExpansionTemplate}
            >
              <Column field="srNo" header="#" />
              <Column
                field="UserName"
                header="User Name"
                body={null}
                bodyStyle={{ fontSize: "12px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="HospitalName"
                header="Hospital Name"
                body={(e: any) => hospitaltdata(e)}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="City"
                header="City"
                body={(e: any) => {
                  return e.City === null ? "-" : e.City;
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="TourDate"
                header="Tour Date"
                // body={(e: any) => {
                //   return e.TourDate === null
                //     ? "-"
                //     : moment(e.TourDate).format("DD/MM/YY hh:mm A");
                // }}
                body={(e: any) => {
                  if (e.TourDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.TourDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.TourDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="CompletedDate"
                header="Completed Date"
                // body={(e: any) => {
                //   return e.CompletedDate === null
                //     ? "-"
                //     : moment(e.CompletedDate).format("DD/MM/YY hh:mm A");
                // }}
                body={(e: any) => {
                  if (e.CompletedDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.CompletedDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.CompletedDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="ExpectedVisitTime"
                header="Expected Visit Time"
                body={(e: any) => {
                  if (e.ExpectedVisitTime === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.ExpectedVisitTime).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.ExpectedVisitTime).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }

                  // e.ExpectedVisitTime === null
                  //   ? "-"
                  //   : moment(e.ExpectedVisitTime).format(
                  //     "DD/MM/YY hh:mm A"
                  //   );
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      ) : reportFor == 3 && gridData?.length > 0 ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box">
            <DataTable
              value={gridData}
              dataKey="ChemistVisitId"
              key={"ChemistVisitId"}
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={subLazyState.first}
              rows={subLazyState.rows}
              totalRecords={subTotalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onSubPageChange}
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              expandedRows={chemistExpandrow}
              rowExpansionTemplate={chemistRowExpansionTemplate}
            >
              <Column field="srNo" header="#" />
              <Column
                field="UserName"
                header="User Name"
                body={null}
                bodyStyle={{ fontSize: "12px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="ChemistName"
                header="Chemist Name"
                body={(e: any) => chemistdata(e)}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="City"
                header="City"
                body={(e: any) => {
                  return e.City === null ? "-" : e.City;
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="TourDate"
                header="Tour Date"
                body={(e: any) => {
                  if (e.TourDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.TourDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.TourDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="CompletedDate"
                header="Completed Date"
                body={(e: any) => {
                  if (e.CompletedDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.CompletedDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.CompletedDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="ExpectedVisitTime"
                header="Expected Visit Time"
                body={(e: any) => {
                  if (e.ExpectedVisitTime === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.ExpectedVisitTime).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          severity={"info"}
                          value={moment(e.ExpectedVisitTime).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      ) : reportFor == 4 && gridData?.length > 0 ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box">
            <DataTable
              value={gridData}
              dataKey="id"
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={subLazyState.first}
              rows={subLazyState.rows}
              totalRecords={subTotalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onSubPageChange}
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              <Column field="srNo" header="#" />
              <Column
                field="UserName"
                header="User Name"
                body={null}
                bodyStyle={{ fontSize: "12px", fontWeight: "bold" }}
              ></Column>
              <Column
                field="StockistName"
                header="Stockist Name"
                body={null}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="City"
                header="City"
                body={(e: any) => {
                  return e.City === null ? "-" : e.City;
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="TourDate"
                header="Tour Date"
                body={(e: any) => {
                  if (e.TourDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.TourDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          value={moment(e.TourDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="CompletedDate"
                header="Completed Date"
                body={(e: any) => {
                  if (e.CompletedDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.CompletedDate).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          value={moment(e.CompletedDate).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="ExpectedVisitTime"
                header="Expected Visit Time"
                body={(e: any) => {
                  if (e.ExpectedVisitTime === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={moment(e.ExpectedVisitTime).format("DD/MM/YY")}
                        ></Badge>
                        <Badge
                          className="m-1"
                          value={moment(e.ExpectedVisitTime).format("hh:mm A")}
                          style={{ backgroundColor: "#978EEB" }}
                        ></Badge>
                      </>
                    );
                  }
                }}
                bodyStyle={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      ) : (
        Nodata && (
          <div className="col-md-12 col-sm-12 mb-30">
            <div className="pd-20 card-box text-center">
              <h3 className="text-secondary">No data found.</h3>
            </div>
          </div>
        )
      )}

      <DCRActionModal
        id={id}
        setisOPen={() => {
          setId(null);
          setType(null);
        }}
        open={id != null}
        type={type}
      />
    </>
  );
};
