import React, { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddEditConsultingFeesMRModal from "./AddEditConsultingFeesMRModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const ConsultingFeesMR = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname =
    process.env.REACT_APP_DOCTORCONSULTINFFEESFORMR_API?.toString();

  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);

  const filterJson = {
    ConsultingFeesForMrtype: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ConsultingFeesForMramount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ConsultingFeesForMrvalidUpto: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    }
  };


  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "ConsultingFeesForMrtype",
      header: "Consulting Type",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by consulting type"
    },
    {
      field: "ConsultingFeesForMramount",
      header: "Amount",
      body: null,
      sortable: true,
      filter: true,
      dataType: "numeric",
      filterPlaceholder: "Search by amount"
    },
    {
      field: "ConsultingFeesForMrvalidUpto",
      header: "Valid Upto",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by valid up to"
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ConsultingFeesForMrid}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.ConsultingFeesForMrid);
          }}
          onClick={() => modelFunction(apiname, id.ConsultingFeesForMrid)}
        />
      </>
    );
  };

  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);

  return (
    <>
      <Accordion>
        <AccordionTab header="Consulting Fees (MR)">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Consulting Fees (MR)`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              setselecteditem={setselectedRow}
              selecteditem={selectedRow}
              filterJson={filterJson}
              pdfTitle="ConsultingFeesMR"
            />
          </div>
        </AccordionTab>
      </Accordion>
      {updateId !== null && (
        <AddEditConsultingFeesMRModal
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
