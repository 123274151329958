import React from 'react';
import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditEventsEmployeeMap } from './AddEditEventsEmployeeMap';
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputSwitch } from 'primereact/inputswitch';
import { NullHandler } from "../../../common/NullHandler";


export const EventsEmployeeMap = () => {

    const apiname = process.env.REACT_APP_EVENTSEMPLOYEEMAP_API?.toString();
    const [deleteID, setdeleteID]: any = useState(null);

    const [selectedRows, setSelectedRows]: any = useState([]);

    const [bool, setbool]: any = useState(null);

    const deleteiddetect = (row: any) => {
        setdeleteID(row);
    };
    const columnData = [
        {
            field: "srNo",
            header: "#",
            // frozen: true,
            width: "3%",
        },
        {
            header: "Action",
            sortable: false,
            filter: false,
            body: (e: any) => actionEventsEmployeeMapTemplate(e),
            width: "6%",
        },
        {
            field: "EventName",
            header: "Event Name",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by Event Name",
            body: null,
            width: "10vw",
        },
        {
            field: "EmployeeName",
            header: "Employee Name",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by Employee Name",
            body: (data: any) => {
                return NullHandler(data.EmployeeName);
            },
            width: "10vw",
        },
        {
            field: "IsLead",
            header: "IsLead",
            sortable: true,
            // filter: true,
            // filterPlaceholder: "Search by Employee Name",
            // body: null,
            body: (e: any) => actionTemplate(e),
            width: "10vw",
        },

    ];




    const filterJson = {
        EventName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        EmployeeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    }
    const actionTemplate = (id: any) => {
        return (
            <>
                <InputSwitch checked={id.IsLead} />
            </>
        );
    };
    const [updateId, setupdateId]: any = useState(null);
    const actionEventsEmployeeMapTemplate = (id: any) => {

        console.log("ID", id.Id)
        return (
            <>
                <Action
                    id={id.Id}
                    editModal={() => {
                        setupdateId(id.Id);
                    }}
                    displayid={deleteiddetect}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>Events Employee Map</title>
            </Helmet>


            <div className="min-height-200px">
                <PageHeader
                    headTitle="Events Employee Map"
                    onClick={() => {
                        setupdateId(0);
                    }}
                    id={0}
                    list={true}
                />

                <DatatableCommon
                    apiname={apiname}
                    filterJson={filterJson}
                    setselecteditem={setSelectedRows}
                    selecteditem={selectedRows}
                    columnData={columnData}
                    refetchData={bool}
                    setrefetchData={setbool}
                />

                {updateId !== null && (
                    <AddEditEventsEmployeeMap
                        fetch={(data: boolean) => {
                            if (data) {
                                setbool(true);
                            }
                        }}
                        setisOPen={() => {
                            return setupdateId(null);
                        }}
                        open={updateId !== null}
                        id={updateId}
                    />
                )}
            </div>
            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => {
                    if (data) {
                        setbool(true);
                    }
                }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />


        </>
    )

}