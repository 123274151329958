import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import AddEditCompetitorMstModal from "./AddEditCompetitorMstModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";


export default function CompetitorMst() {
  const apiname = process.env.REACT_APP_COMPETITORMST_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [selectedcompetitormst, setselectedcompetitormst]: any = useState([]);
  const multi = selectedcompetitormst?.map((e: any) => {
    return e.CompetitorId;
  });
  useEffect(() => { }, [selectedcompetitormst]);

  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const filterJson = {
    CompetitorName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Location: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionCompetitorMstTemplate(e),
      sortable: false,
      width: "10%",
    },
    {
      field: "CompetitorName",
      header: "Competitor Name",
      bodyClassName: "text-info h6",
      filter: true,
      body: null,
      sortable: true,
      filterPlaceholder: "Search by competitor name",
      width: "10vw",
    },
    {
      field: "Location",
      header: "Location",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.Location);
      },
      filterPlaceholder: "Search by location",
      sortable: true,
      width: "10vw",
    },
    {
      field: "Description",
      header: "Description",
      body: (data: any) => {
        return NullHandler(data.Description);
      },
      width: "10vw",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);
  const actionCompetitorMstTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.CompetitorId}
          editModal={() => {
            setupdateId(id.CompetitorId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Competitor Master</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Competitor Master"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/employeeattributes"}
          linkflagtitle={"Employee Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          refetchData={bool}
          filterJson={filterJson}
          columnData={columns}
          setrefetchData={setbool}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedcompetitormst?.length === 0 ? null : multi);
            }
          }}
          selecteditem={selectedcompetitormst}
          setselecteditem={setselectedcompetitormst}
        />

        {updateId !== null && (
          <AddEditCompetitorMstModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
