import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import axiosInstance from "../../../_helper/Interceptor";
import { useForm } from "react-hook-form";

export const Geography = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
        watch,
      }: any = useForm();
 

 
  return (
    <>
      <Helmet>
        <title> Geography </title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Geography </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Geography
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Division is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
            <div className="col-lg-4 row">
            <ReactSelect
              containerClass="col-md-10"
              control={control}
              isRequired
              options={[]}
              name="CountryId"
              label="Country"
              rules={{
                required: { value: true, message: "Country is required" },
              }}
              errorMessage={errors?.CountryId?.message}
            />
            <div className="col-md-2 align-self-center">
              <button className="btn btn-primary btn-sm"><span className="micon bi bi-plus"></span></button>
            </div>

            </div>
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Category is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Category is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Category is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Category is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Category is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
            <ReactSelect
              containerClass="col-lg-4"
              control={control}
              isRequired
              options={[]}
              name="DivisionId"
              label="Division"
              rules={{
                required: { value: true, message: "Category is required" },
              }}
              errorMessage={errors?.DivisionId?.message}
            />
          </div>
        </form>
      </div>
    </>
  );
};
