import axiosInstance from "../_helper/Interceptor";
import Modal from "react-bootstrap/Modal";
import Textarea from "./Textarea";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../hooks/Api/Api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Input } from "./Input";

interface iPropsmoadl {
  id?: any;
  deleteMessage?: any;
  apiname?: string;
  setisOPen?: any;
  open?: any;
  refetch?: any;
  status?: any;
}

export const ApprovalModal = ({
  id,
  apiname,
  setisOPen,
  open,
  refetch,
  status,
}: iPropsmoadl) => {
  const { mutate, isSuccess, isError, error }: any = useCreateByID(apiname);

  const rejectData = (data: any) => {

    const Finaldata = {
      MonthlyTourPlanUnlockId: id,
      Status: "Approve",
      Hours: Number(data.Hr),
    };
    const FinalJson = {
      MonthlyTourPlanUnlockId: id,
      Status: "Reject",
      Remark: data.Remark,
    };

    mutate(status === "Approve" ? Finaldata : FinalJson);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      if (status === "Approve") {
        toast.success("Approve Successfull", { position: "top-right" });
      } else {
        toast.success("Reject Successfull", { position: "top-right" });
      }
      refetch(true);
    }
  }, [isSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-body">
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              rejectData(productinfo);
            })}
          >
            {status === "Reject" ? (
              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                rows={2}
                isRequired
                maxLength={50}
                errorMessage={errors?.Remark?.message}
                register={{
                  ...register("Remark", {
                    // required: {
                    //   value: true,
                    //   message: "Remarks is required",
                    // },
                    pattern: {
                      value:
                        /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                      message:
                        "Enter Valid Remarks and Remove whitespace from both sides of a string",
                    },
                  }),
                }}
              />
            ) : (
              <Input
                containerClass="col-lg-12"
                label="Hour"
                isRequired
                type="text"
                register={{
                  ...register("Hr", {
                    // required: {
                    //   value: true,
                    //   message: "Hr is required",
                    // },
                  }),
                }}
                onChange={() => clearErrors(["Hr"])}
                errorMessage={
                  errors?.Hr?.message ||
                  error?.response?.data?.errors?.Hr?.map((e: any) => e)
                }
              />
            )}

            <div className="col-lg-12 text-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
