import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";
import { ErrorMessage } from "../../common/ErrorMessage";
import Select from "react-select";

export const AdminCRM = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  }: any = useForm({ mode: "all" });

  const d = watch();
  console.log(d, "watch data");

  const [isLoading, setIsLoading] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownload = async (data: any) => {

    const division = data.DivisionId?.map(
      (e: any) => `DivisionIds=${e?.value}`
    );
    const Zone = data.ZoneId?.map((e: any) => `ZoneIds=${e?.value}`);
    const State = data.StateId?.map((e: any) => `StateIds=${e?.value}`);
    const Region = data.RegionId?.map((e: any) => `RegionIds=${e?.value}`);
    const chemist = data.ChemistId?.map((e: any) => `ChemistIds=${e?.value}`);
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORDETAILGETCRMREPORT_API}?FromDate=${startdate
        }&ToDate=${defaultDate}&flag=${data.flag}${data.DoctorId == undefined ? "" : `&DoctorId=${data.DoctorId.value}`
        }${division == undefined
          ? ""
          : `&${String(division)?.replaceAll(",", "&")}`
        }
        ${Region == undefined ? "" : `&${String(Region)?.replaceAll(",", "&")}`}
        ${State == undefined ? "" : `&${String(State)?.replaceAll(",", "&")}`}
        ${Zone == undefined ? "" : `&${String(Zone)?.replaceAll(",", "&")}`}
        ${chemist == undefined
          ? ""
          : `&${String(chemist)?.replaceAll(",", "&")}`
        }`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "CRM.xlsx");
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
        setselecteddoctor(null);
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  // Division
  const [selecteddivision, setselecteddivision] = useState([]);

  // Zone
  const [selectedZone, setselectedZone] = useState([]);
  const zoneid = selectedZone?.map((e: any) => `id=${e?.value}`);

  // State
  const [selectedState, setselectedState] = useState([]);
  const stateid = selectedState?.map((e: any) => `id=${e?.value}`);

  // Region
  const [selectedrigion, setselectedrigion] = useState([]);
  const regionid = selectedrigion?.map((e: any) => `regionId=${e?.value}`);

  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  // Chemist
  const [selectedchemist, setselectedchemist]: any = useState([]);

  // date
  const [startdate, setDate] = useState('');

  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split('T')[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split('T')[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };


  return (
    <>
      <Helmet>
        <title>CRM</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>CRM</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  CRM
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="clearfix mb-30">
          <div className="pull-left">
            <h4 className="text-blue h4">CRM</h4>
          </div>
        </div>
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="col-form-label">
                  Report Type <span className="text-danger">*</span>
                </label>
                <select
                  className="custom-select col-12"
                  {...register("flag", {
                    required: {
                      value: true,
                      message: "Report Type is required",
                    },
                    onchange,
                  })}
                >
                  <option value={"chemist"}>Chemist</option>
                  <option value={"product"}>Product</option>
                  <option value={"doctor"}>Doctor</option>
                  <option value={"crm"}>Doctor Account</option>
                </select>
              </div>
              <ErrorMessage message={errors?.flag?.message} />
            </div>

            <Input
              containerClass="col-lg-4"
              label="From Date"
              type="date"
              isRequired
              errorMessage={errors?.FromDate?.message}
              // register={{
              //   ...register("FromDate", {
              //     required: {
              //       value: true,
              //       message: "From Month is required",
              //     },
              //   }),
              // }}
              value={startdate}
              onChange={handleDateChange}
            />

            <Input
              containerClass="col-lg-4"
              label="To Date"
              type="date"
              isRequired
              errorMessage={errors?.ToDate?.message}
              // register={{
              //   ...register("ToDate", {
              //     required: {
              //       value: true,
              //       message: "To Month is required",
              //     },
              //   }),
              // }}
              value={defaultDate}
              onChange={handleEndDateChange}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Doctor"
              control={control}
              name="DoctorId"
              onChange={(e: any) => {
                setselecteddoctor(e);
                setValue("DoctorId", e?.value);
              }}
              value={selecteddoctor}
              api={process.env.REACT_APP_DOCTORDETAIL_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.DoctorId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Division"
              name={"DivisionId"}
              Multi
              onChange={(e: any) => {
                setselecteddivision(e);
                setValue("DivisionId", e);
              }}
              value={selecteddivision}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.DivisionId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Zone"
              control={control}
              name="ZoneId"
              Multi
              onChange={(e: any) => {
                setselectedState([]);
                setValue("StateId", null);
                setselectedZone(e);
                setValue("ZoneId", e);
              }}
              value={selectedZone}
              api={process.env.REACT_APP_ZONE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ZoneId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="State"
              control={control}
              name="StateId"
              Multi
              onChange={(e: any) => {
                setselectedState(e);
                setValue("StateId", e);
              }}
              value={selectedState}
              api={`${process.env.REACT_APP_STATE_MULTIPLEZONE_API}?${String(
                zoneid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              errorMessage={errors?.StateId?.message}
              Disabled={String(zoneid)?.replaceAll(",", "&") ? false : true}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Region"
              control={control}
              name="RegionId"
              Multi
              onChange={(e: any) => {
                setselectedrigion(e);
                setValue("RegionId", e);
              }}
              value={selectedrigion}
              api={`${process.env.REACT_APP_REGION_MULTIPLESTATE_API}?${String(
                stateid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              errorMessage={errors?.RegionId?.message}
              Disabled={String(stateid)?.replaceAll(",", "&") ? false : true}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Chemist"
              control={control}
              name="ChemistId"
              Multi
              onChange={(e: any) => {
                setselectedchemist(e);
                setValue("ChemistId", e);
              }}
              api={`${process.env.REACT_APP_CHEMISTBYREGION_API}?${String(regionid)?.replaceAll(
                ",",
                "&"
              )}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              value={selectedchemist}
              errorMessage={errors?.ChemistId?.message}
              Disabled={String(regionid)?.replaceAll(",", "&") ? false : true}
            />

            {/* <div className="col-lg-12">
              <div className="form-group">
                <label className="col-form-label">
                  Report Type <span className="text-danger">*</span>
                </label>
                <div className="d-flex ms-2 align-items-baseline mt-2">
                  <div className="custom-control custom-radio mb-5">
                    <input
                      id="chemist"
                      {...register("flag")}
                      type="radio"
                      className="custom-control-input"
                      value={"chemist"}
                    />
                    <label className="custom-control-label" htmlFor="chemist">
                      chemist
                    </label>
                  </div>
                  <div className="custom-control custom-radio mb-5">
                    <input
                      id="product"
                      {...register("flag")}
                      className="custom-control-input"
                      type="radio"
                      value={"product"}
                    />
                    <label className="custom-control-label" htmlFor="product">
                      product
                    </label>
                  </div>
                  <div className="custom-control custom-radio mb-5">
                    <input
                      id="doctor"
                      {...register("flag")}
                      className="custom-control-input"
                      type="radio"
                      value={"doctor"}
                    />
                    <label className="custom-control-label" htmlFor="doctor">
                      Doctor
                    </label>
                  </div>
                  <div className="custom-control custom-radio mb-5">
                    <input
                      id="crm"
                      {...register("flag")}
                      className="custom-control-input"
                      type="radio"
                      value={"crm"}
                    />
                    <label className="custom-control-label" htmlFor="crm">
                      Doctor Account
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="col-md-12 text-center mt-30">
            {!isLoading ? (
              <button className="btn btn-outline-primary" type="submit">
                Download
              </button>
            ) : (
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled
              >
                Download <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
