import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import axiosInstance from "../../_helper/Interceptor";
import { useCreateByID, useFetchDoctorID } from "../../hooks/Api/Api";
import { toast } from "react-toastify";
import { fetchPincodeByCity } from "../../hooks/master/geography/useGeography";
import { Spinner } from "../../common/Spinner";
import { Button } from "../../common/Button";
import { ErrorMessage } from "../../common/ErrorMessage";
import { Address } from "../../common/Address";
import { InputMask } from "primereact/inputmask";
import { Message } from "primereact/message";

export const AddEditMR = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString();
  const controller = new AbortController();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });
  let da = watch();
  console.log(da, "thios is form data");

  const Hcity = watch("CommunicationCityId");
  const Rcity = watch("PermanentCityId");

  const { data, isLoading }: any = useFetchDoctorID(apiname, id, controller.signal);
  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        const dateUpdate = data?.AnniversaryDate?.split("T")[0];
        setValue("AnniversaryDate", dateUpdate);

        const dateUpdate1 = data?.BirthDate?.split("T")[0];
        setValue("BirthDate", dateUpdate1);

        const dateUpdate2 = data?.ConformationDate?.split("T")[0];
        setValue("ConformationDate", dateUpdate2);

        const dateUpdate3 = data?.DemotionDate?.split("T")[0];
        setValue("DemotionDate", dateUpdate3);

        const dateUpdate4 = data?.JoiningDate?.split("T")[0];
        setValue("JoiningDate", dateUpdate4);

        const dateUpdate5 = data?.LastWorkingDate?.split("T")[0];
        setValue("LastWorkingDate", dateUpdate5);

        const dateUpdate6 = data?.PromotionDate?.split("T")[0];
        setValue("PromotionDate", dateUpdate6);

        let divisionLists = data?.divisionLists?.map((d: any) => ({
          label: d?.DivisionName,
          value: d?.DivisionId,
        }));
        setValue("DivisionIds", divisionLists);
        setselecteddivision(divisionLists);
        if (data.CommunicationPincodeId !== null) {
          setValue("CommunicationPincodeId", {
            label: data.CommunicationPincode,
            value: data.CommunicationPincodeId,
          });
          setselectedhpincode({
            label: data.CommunicationPincode,
            value: data.CommunicationPincodeId,
          });
        } else {
          setValue("CommunicationPincodeId", null);
          setselectedhpincode(null);
        }

        if (data.PermanentPincodeId !== null) {
          setValue("PermanentPincodeId", {
            label: data.PermanentPincode,
            value: data.PermanentPincodeId,
          });
          setselectedRpincode({
            label: data.PermanentPincode,
            value: data.PermanentPincodeId,
          });
        } else {
          setValue("PermanentPincodeId", null);
          setselectedRpincode(null);
        }

        if (data.CommunicationCityId !== null) {
          setValue("CommunicationCityId", {
            label: data.CommunicationCity,
            value: data.CommunicationCityId,
          });
          setselectedhcity({
            label: data.CommunicationCity,
            value: data.CommunicationCityId,
          });
        } else {
          setValue("CommunicationCityId", null);
          setselectedhcity(null);
        }

        if (data.PermanentCityId !== null) {
          setValue("PermanentCityId", {
            label: data.PermanentCity,
            value: data.PermanentCityId,
          });
          setselectedRcity({
            label: data.PermanentCity,
            value: data.PermanentCityId,
          });
        } else {
          setValue("PermanentCityId", null);
          setselectedRcity(null);
        }
      }
    }
    return () => {
      controller.abort();
    }
  }, [data, id]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: mrLoader,
  }: any = useCreateByID(apiname, id);

  const [selecteddivision, setselecteddivision] = useState([]);
  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedRcity, setselectedRcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);
  const [selectedRpincode, setselectedRpincode]: any = useState(null);

  const mrFormData = (data: any) => {

    data.Mobile = data.Mobile?.replace(/[^\d]/g, "");
    data.Phone = data.Phone?.replace(/[^\d]/g, "");

    if (id !== 0) {
      delete data.divisionLists;
      delete data.CreatedById;
      delete data.CreatedBy;
    }

    data.CommunicationCityId = selectedhcity?.value;
    data.PermanentCityId = selectedRcity?.value;
    data.CommunicationPincodeId = selectedhpincode?.value;
    data.PermanentPincodeId = selectedRpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );

    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else if (key == "DivisionIds") {
        newData.DivisionIds.map((e: any, index: any) => {
          formData.append("DivisionIds[" + index + "]", e?.value);
        });
      } else {
        formData.append(key, formvalue[0]);
      }
    }
    mutate(formData);
  };

  useEffect(() => {

    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/mr");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/mr");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }

    }
  }, [isError, isSuccess]);
  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>{`${id === undefined ? "Add" : "Edit"} MR`}</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/mr"}>MR</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {`${id === undefined ? "Add" : "Edit"} MR`}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {!isLoading ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              mrFormData(productinfo);
            })}
          >
            <div className="row">
              <Input
                containerClass="col-lg-3"
                label="Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Name", e.target.value);
                  clearErrors(["Name"]);
                }}
                register={{
                  ...register("Name", {
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                    pattern: {
                      value: /^\w[A-Za-z\s,-.']{1,50}$/,
                      message:
                        "Enter a valid name (1-50 alphabet characters or special characters (a-zA-Z'-.,))",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-3"
                label={"Division"}
                name={"DivisionIds"}
                onChange={(e: any) => {
                  setselecteddivision(e);
                  setValue("DivisionIds", e);
                }}
                isRequired
                value={selecteddivision}
                control={control}
                api={process.env.REACT_APP_DIVISION_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DivisionIds?.message ||
                  error?.response?.data?.errors?.DivisionIds?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Division is required",
                  },
                }}
                Multi
              />

              <Input
                containerClass="col-lg-3"
                label="Reference"
                type="text"
                errorMessage={
                  errors?.Reference?.message ||
                  error?.response?.data?.errors?.Reference?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Reference", e.target.value);
                  clearErrors(["Reference"]);
                }}
                register={{
                  ...register(
                    "Reference"
                    // {
                    //   pattern: {
                    //     value: /^\w[a-zA-Z0-9\s'-.]{1,20}$/,
                    //     message:
                    //       "Enter a valid reference (1-50 alphanumeric characters (a-zA-Z0-9'-.))",
                    //   },
                    // }
                  ),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Phone No"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Phone", e.target.value);
                  clearErrors(["Phone"]);
                }}
                register={{
                  ...register("Phone", {
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Enter a valid phone no. (10 digits only)",
                    },
                    required: {
                      value: true,
                      message: "Phone Number Is Required"
                    }
                  }),
                }}
                errorMessage={
                  errors?.Phone?.message ||
                  error?.response?.data?.errors?.Phone?.map((e: any) => e)
                }

              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">
                    Mobile No. <span className="text-danger">*</span>
                  </label>
                  <InputMask
                    {...register("Mobile", {
                      required: {
                        value: true,
                        message: "Mobile No. is required",
                      },
                    })}
                    onChange={(e) => {
                      setValue("Mobile", e.target.value);
                    }}
                    mask="(999) 999-9999"
                    className="form-control"
                  />
                  <ErrorMessage
                    message={
                      errors?.Mobile?.message ||
                      error?.response?.data?.errors?.Mobile?.map((e: any) => e)
                    }
                  />
                </div>
              </div>

              <Input
                containerClass="col-lg-3"
                label="Email"
                type="email"
                isRequired
                errorMessage={
                  errors?.Email?.message ||
                  error?.response?.data?.errors?.Email?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Email", e.target.value);
                  clearErrors(["Email"]);
                }}
                register={{
                  ...register("Email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                      message: "Email format is Invalid...",
                    },
                  }),
                }}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Previous Experience"
                type="text"
                errorMessage={
                  errors?.PreviousExperience?.message ||
                  error?.response?.data?.errors?.PreviousExperience?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("PreviousExperience", e.target.value);
                  clearErrors(["PreviousExperience"]);
                }}
                register={{
                  ...register("PreviousExperience", {
                    // pattern: {
                    //   value: /^\w[0-9A-Za-z\s'-]{1,20}$/,
                    //   message:
                    //     "Enter a valid reference (1-20 alphanumeric characters (0-9A-Za-z'-))",
                    // },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Passport"
                type="text"
                errorMessage={
                  errors?.Passport?.message ||
                  error?.response?.data?.errors?.Passport?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Passport", e.target.value);
                  clearErrors(["Passport"]);
                }}
                register={{
                  ...register("Passport", {
                    pattern: {
                      value: /^\w[0-9A-Z]{7,11}$/,
                      message:
                        "Enter a valid passport (8-12 alphanumeric characters (0-9A-Z))",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Pan Card Number"
                type="text"
                errorMessage={
                  errors?.PanNo?.message ||
                  error?.response?.data?.errors?.PanNo?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("PanNo", e.target.value);
                  clearErrors(["PanNo"]);
                }}
                register={{
                  ...register("PanNo", {
                    pattern: {
                      value: /^\w[0-9A-Z]{9}$/,
                      message:
                        "Enter a valid panno. (10 alphanumeric characters (0-9A-Z))",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="PF No."
                type="text"
                errorMessage={
                  errors?.PfNo?.message ||
                  error?.response?.data?.errors?.PfNo?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("PfNo", e.target.value);
                  clearErrors(["PfNo"]);
                }}
                register={{
                  ...register("PfNo", {
                    pattern: {
                      value: /^\d{22}$/,
                      message: "Enter a valid pf no. (22 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="UanNo"
                type="text"
                errorMessage={
                  errors?.UanNo?.message ||
                  error?.response?.data?.errors?.UanNo?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("UanNo", e.target.value);
                  clearErrors(["UanNo"]);
                }}
                register={{
                  ...register("UanNo", {
                    pattern: {
                      value: /^\d{12}$/,
                      message: "Enter a valid uan no. (12 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Blood Group"
                type="text"
                errorMessage={
                  errors?.BloodGroup?.message ||
                  error?.response?.data?.errors?.BloodGroup?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("BloodGroup", e.target.value);
                  clearErrors(["BloodGroup"]);
                }}
                register={{
                  ...register("BloodGroup"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Religion"
                type="text"
                errorMessage={
                  errors?.Religion?.message ||
                  error?.response?.data?.errors?.Religion?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Religion", e.target.value);
                  clearErrors(["Religion"]);
                }}
                register={{
                  ...register("Religion"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Birth Date"
                type="date"
                errorMessage={
                  errors?.BirthDate?.message ||
                  error?.response?.data?.errors?.BirthDate?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("BirthDate", e.target.value);
                  clearErrors(["BirthDate"]);
                }}
                register={{
                  ...register("BirthDate"),
                }}
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">Marital Status</label>
                  <select
                    className="custom-select col-12"
                    {...register("MaritalStatus")}
                  >
                    <option value={""}>Select Maritial Status</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </select>
                </div>
                <ErrorMessage
                  message={
                    errors?.MaritalStatus?.message ||
                    error?.response?.data?.errors?.MaritalStatus?.map(
                      (e: any) => e
                    )
                  }
                />
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">Gender</label>
                  <select
                    className="custom-select col-12"
                    {...register("Gender")}
                  >
                    <option value={""}>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <ErrorMessage
                  message={
                    errors?.Gender?.message ||
                    error?.response?.data?.errors?.Gender?.map((e: any) => e)
                  }
                />
              </div>

              <Input
                containerClass="col-lg-3"
                label="Anniversary Date"
                type="date"
                errorMessage={
                  errors?.AnniversaryDate?.message ||
                  error?.response?.data?.errors?.AnniversaryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AnniversaryDate", e.target.value);
                  clearErrors(["AnniversaryDate"]);
                }}
                register={{
                  ...register("AnniversaryDate"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Joining Date"
                type="date"
                errorMessage={
                  errors?.JoiningDate?.message ||
                  error?.response?.data?.errors?.JoiningDate?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("JoiningDate", e.target.value);
                  clearErrors(["JoiningDate"]);
                }}
                register={{
                  ...register("JoiningDate"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Last Working Date"
                type="date"
                errorMessage={
                  errors?.LastWorkingDate?.message ||
                  error?.response?.data?.errors?.LastWorkingDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("LastWorkingDate", e.target.value);
                  clearErrors(["LastWorkingDate"]);
                }}
                register={{
                  ...register("LastWorkingDate"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Bank IFSC code"
                type="text"
                errorMessage={
                  errors?.BankIfsccode?.message ||
                  error?.response?.data?.errors?.BankIfsccode?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("BankIfsccode", e.target.value);
                  clearErrors(["BankIfsccode"]);
                }}
                register={{
                  ...register("BankIfsccode", {
                    pattern: {
                      value: /^\w[A-Z0-9]{10}$/,
                      message:
                        "Enter a valid ifsc code (11 alphanumeric characters (A-Z0-9))",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Salary Bank Name"
                type="text"
                errorMessage={
                  errors?.SalaryBankName?.message ||
                  error?.response?.data?.errors?.SalaryBankName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SalaryBankName", e.target.value);
                  clearErrors(["SalaryBankName"]);
                }}
                register={{
                  ...register("SalaryBankName", {
                    pattern: {
                      value: /^\w[A-Za-z0-9\s'-.]{1,50}$/,
                      message:
                        "Enter a valid chemistname (1-50 alphanumeric characters or special characters (A-Za-z0-9'-.))",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Bank Account No."
                type="text"
                errorMessage={
                  errors?.BankAccountNo?.message ||
                  error?.response?.data?.errors?.BankAccountNo?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("BankAccountNo", e.target.value);
                  clearErrors(["BankAccountNo"]);
                }}
                register={{
                  ...register("BankAccountNo", {
                    pattern: {
                      value: /^\d{11,16}$/,
                      message: "Enter a valid account no. (11-16 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Name As Bank Registered"
                type="text"
                errorMessage={
                  errors?.NameAsBankRegistered?.message ||
                  error?.response?.data?.errors?.NameAsBankRegistered?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("NameAsBankRegistered", e.target.value);
                  clearErrors(["NameAsBankRegistered"]);
                }}
                register={{
                  ...register("NameAsBankRegistered", {
                    pattern: {
                      value: /^\w[A-Za-z\s'-.]{1,50}$/,
                      message:
                        "Enter a valid name as bank registered (1-50 alphabet characters or special characters (A-Za-z'-.))",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Aadhar Card Number"
                type="text"
                errorMessage={
                  errors?.AadharCardNo?.message ||
                  error?.response?.data?.errors?.AadharCardNo?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AadharCardNo", e.target.value);
                  clearErrors(["AadharCardNo"]);
                }}
                register={{
                  ...register("AadharCardNo", {
                    pattern: {
                      value: /^\d{12}$/,
                      message:
                        "Enter a valid adhar card number (12 digits only)",
                    },
                  }),
                }}
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">
                    Driving License Number
                  </label>
                  <InputMask
                    {...register("DrivingLicenseNo")}
                    onChange={(e) =>
                      setValue("DrivingLicenseNo", e.target.value)
                    }
                    mask="9999 99999999999"
                    className="form-control"
                  />
                  <ErrorMessage
                    message={
                      errors?.DrivingLicenseNo?.message ||
                      error?.response?.data?.errors?.DrivingLicenseNo?.map(
                        (e: any) => e
                      )
                    }
                  />
                </div>
              </div>

              <Input
                containerClass="col-lg-3"
                label="Expense Bank Name"
                type="text"
                errorMessage={
                  errors?.ExpenseBankName?.message ||
                  error?.response?.data?.errors?.ExpenseBankName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ExpenseBankName", e.target.value);
                  clearErrors(["ExpenseBankName"]);
                }}
                register={{
                  ...register("ExpenseBankName", {
                    pattern: {
                      value: /^\w[A-Za-z0-9\s'-.]{1,50}$/,
                      message:
                        "Enter a valid expense bank name (1-50 alphabet characters or special characters (A-Za-z0-9'-.))",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Expense Bank Account Number"
                type="text"
                errorMessage={
                  errors?.ExpenseBankAcno?.message ||
                  error?.response?.data?.errors?.ExpenseBankAcno?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ExpenseBankAcno", e.target.value);
                  clearErrors(["ExpenseBankAcno"]);
                }}
                register={{
                  ...register("ExpenseBankAcno", {
                    pattern: {
                      value: /^\d{11,16}$/,
                      message: "Enter a valid account no. (11-16 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Promotion Date"
                type="date"
                errorMessage={
                  errors?.PromotionDate?.message ||
                  error?.response?.data?.errors?.PromotionDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("PromotionDate", e.target.value);
                  clearErrors(["PromotionDate"]);
                }}
                register={{
                  ...register("PromotionDate"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Demotion Date"
                type="date"
                errorMessage={
                  errors?.DemotionDate?.message ||
                  error?.response?.data?.errors?.DemotionDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DemotionDate", e.target.value);
                  clearErrors(["DemotionDate"]);
                }}
                register={{
                  ...register("DemotionDate"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Conformation Date"
                type="date"
                errorMessage={
                  errors?.ConformationDate?.message ||
                  error?.response?.data?.errors?.ConformationDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ConformationDate", e.target.value);
                  clearErrors(["ConformationDate"]);
                }}
                register={{
                  ...register("ConformationDate"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Metro"
                type="text"
                errorMessage={
                  errors?.Metro?.message ||
                  error?.response?.data?.errors?.Metro?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Metro", e.target.value);
                  clearErrors(["Metro"]);
                }}
                register={{
                  ...register("Metro"),
                }}
                minLength={2}
                maxLength={50}
              />

              <div className="col-lg-3">
                <div className="form-group">
                  <label className="col-form-label">Appointment Letter</label>
                  <select
                    {...register("AppointmentLetter")}
                    className="form-control"
                  >
                    <option value={""}>Select Options</option>
                    <option value={"true"}>True</option>
                    <option value={"false"}>false</option>
                  </select>
                </div>
              </div>

              <Input
                containerClass="col-lg-3"
                label="Education"
                type="text"
                errorMessage={
                  errors?.Education?.message ||
                  error?.response?.data?.errors?.Education?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Education", e.target.value);
                  clearErrors(["Education"]);
                }}
                register={{
                  ...register("Education"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Father/Husband Name"
                type="text"
                errorMessage={
                  errors?.FatherOrHusbandName?.message ||
                  error?.response?.data?.errors?.FatherOrHusbandName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("FatherOrHusbandName", e.target.value);
                  clearErrors(["FatherOrHusbandName"]);
                }}
                register={{
                  ...register("FatherOrHusbandName", {
                    pattern: {
                      value: /^\w[a-zA-Z\s']{1,50}$/,
                      message:
                        "Enter a valid father or husband name (1-50 alphabet characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Father/Husband Contact Number"
                type="text"
                errorMessage={
                  errors?.FatherOrHusbandContactNo?.message ||
                  error?.response?.data?.errors?.FatherOrHusbandContactNo?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("FatherOrHusbandContactNo", e.target.value);
                  clearErrors(["FatherOrHusbandContactNo"]);
                }}
                register={{
                  ...register("FatherOrHusbandContactNo", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid father or husband contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Abha Number"
                type="text"
                errorMessage={
                  errors?.AbhaNumber?.message ||
                  error?.response?.data?.errors?.AbhaNumber?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("AbhaNumber", e.target.value);
                  clearErrors(["AbhaNumber"]);
                }}
                register={{
                  ...register("AbhaNumber", {
                    pattern: {
                      value: /^\d{14}$/,
                      message: "Enter a valid abha number. (14 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Aayushman Card Number"
                type="text"
                errorMessage={
                  errors?.AayushmanCardNo?.message ||
                  error?.response?.data?.errors?.AayushmanCardNo?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AayushmanCardNo", e.target.value);
                  clearErrors(["AayushmanCardNo"]);
                }}
                register={{
                  ...register("AayushmanCardNo"),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Spouse Name"
                type="text"
                errorMessage={
                  errors?.SpouseName?.message ||
                  error?.response?.data?.errors?.SpouseName?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("SpouseName", e.target.value);
                  clearErrors(["SpouseName"]);
                }}
                register={{
                  ...register("SpouseName"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Spouse Contact Number"
                type="text"
                errorMessage={
                  errors?.SpouseContactNumber?.message ||
                  error?.response?.data?.errors?.SpouseContactNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SpouseContactNumber", e.target.value);
                  clearErrors(["SpouseContactNumber"]);
                }}
                register={{
                  ...register("SpouseContactNumber", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid spouse contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Son Name"
                type="text"
                errorMessage={
                  errors?.SonName?.message ||
                  error?.response?.data?.errors?.SonName?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("SonName", e.target.value);
                  clearErrors(["SonName"]);
                }}
                register={{
                  ...register("SonName"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Son Contact Number"
                type="text"
                errorMessage={
                  errors?.SonContactNumber?.message ||
                  error?.response?.data?.errors?.SonContactNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SonContactNumber", e.target.value);
                  clearErrors(["SonContactNumber"]);
                }}
                register={{
                  ...register("SonContactNumber", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid son contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Daughter Name"
                type="text"
                errorMessage={
                  errors?.DaughterName?.message ||
                  error?.response?.data?.errors?.DaughterName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DaughterName", e.target.value);
                  clearErrors(["DaughterName"]);
                }}
                register={{
                  ...register("DaughterName"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Daughter Contact Number"
                type="text"
                errorMessage={
                  errors?.DaughterContactNumber?.message ||
                  error?.response?.data?.errors?.DaughterContactNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DaughterContactNumber", e.target.value);
                  clearErrors(["DaughterContactNumber"]);
                }}
                register={{
                  ...register("DaughterContactNumber", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid daughter contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Skills"
                type="text"
                errorMessage={
                  errors?.Skills?.message ||
                  error?.response?.data?.errors?.Skills?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Skills", e.target.value);
                  clearErrors(["Skills"]);
                }}
                register={{
                  ...register("Skills"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Area Of Development"
                type="text"
                errorMessage={
                  errors?.AreaOfDevelopment?.message ||
                  error?.response?.data?.errors?.AreaOfDevelopment?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("AreaOfDevelopment", e.target.value);
                  clearErrors(["AreaOfDevelopment"]);
                }}
                register={{
                  ...register("AreaOfDevelopment"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Short Term Goal"
                type="text"
                errorMessage={
                  errors?.ShortTeamGoal?.message ||
                  error?.response?.data?.errors?.ShortTeamGoal?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ShortTeamGoal", e.target.value);
                  clearErrors(["ShortTeamGoal"]);
                }}
                register={{
                  ...register("ShortTeamGoal"),
                }}
                minLength={2}
                maxLength={100}
              />

              <Input
                containerClass="col-lg-3"
                label="Long Term Goal"
                type="text"
                errorMessage={
                  errors?.LongTeamGoal?.message ||
                  error?.response?.data?.errors?.LongTeamGoal?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("LongTeamGoal", e.target.value);
                  clearErrors(["LongTeamGoal"]);
                }}
                register={{
                  ...register("LongTeamGoal"),
                }}
                minLength={2}
                maxLength={100}
              />

              <Input
                containerClass="col-lg-3"
                label="Investments"
                type="text"
                errorMessage={
                  errors?.Investments?.message ||
                  error?.response?.data?.errors?.Investments?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Investments", e.target.value);
                  clearErrors(["Investments"]);
                }}
                register={{
                  ...register("Investments"),
                }}
                minLength={2}
                maxLength={100}
              />

              <Input
                containerClass="col-lg-3"
                label="Liabilities"
                type="text"
                errorMessage={
                  errors?.Liabilities?.message ||
                  error?.response?.data?.errors?.Liabilities?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Liabilities", e.target.value);
                  clearErrors(["Liabilities"]);
                }}
                register={{
                  ...register("Liabilities"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Reference Name 1"
                type="text"
                errorMessage={
                  errors?.ReferenceName1?.message ||
                  error?.response?.data?.errors?.ReferenceName1?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ReferenceName1", e.target.value);
                  clearErrors(["ReferenceName1"]);
                }}
                register={{
                  ...register("ReferenceName1"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Reference Name 1 Contact Number"
                type="text"
                errorMessage={
                  errors?.ReferenceName1ContactNumber?.message ||
                  error?.response?.data?.errors?.ReferenceName1ContactNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ReferenceName1ContactNumber", e.target.value);
                  clearErrors(["ReferenceName1ContactNumber"]);
                }}
                register={{
                  ...register("ReferenceName1ContactNumber", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid reference name1 contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Reference Name 2"
                type="text"
                errorMessage={
                  errors?.ReferenceName2?.message ||
                  error?.response?.data?.errors?.ReferenceName2?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ReferenceName2", e.target.value);
                  clearErrors(["ReferenceName2"]);
                }}
                register={{
                  ...register("ReferenceName2"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Reference Name 2 Contact Number"
                type="text"
                errorMessage={
                  errors?.ReferenceName2ContactNumber?.message ||
                  error?.response?.data?.errors?.ReferenceName2ContactNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ReferenceName2ContactNumber", e.target.value);
                  clearErrors(["ReferenceName2ContactNumber"]);
                }}
                register={{
                  ...register("ReferenceName2ContactNumber", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid reference name2 contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Reference Name 3"
                type="text"
                errorMessage={
                  errors?.ReferenceName3?.message ||
                  error?.response?.data?.errors?.ReferenceName3?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ReferenceName3", e.target.value);
                  clearErrors(["ReferenceName3"]);
                }}
                register={{
                  ...register("ReferenceName3"),
                }}
                minLength={2}
                maxLength={50}
              />

              <Input
                containerClass="col-lg-3"
                label="Reference Name 3 Contact Number"
                type="text"
                errorMessage={
                  errors?.ReferenceName3ContactNumber?.message ||
                  error?.response?.data?.errors?.ReferenceName3ContactNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ReferenceName3ContactNumber", e.target.value);
                  clearErrors(["ReferenceName3ContactNumber"]);
                }}
                register={{
                  ...register("ReferenceName3ContactNumber", {
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid reference name3 contact number. (10 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Experinces"
                type="text"
                errorMessage={
                  errors?.Experinces?.message ||
                  error?.response?.data?.errors?.Experinces?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Experinces", e.target.value);
                  clearErrors(["Experinces"]);
                }}
                register={{
                  ...register("Experinces"),
                }}
                minLength={2}
                maxLength={25}
              />

              <Address
                header="Communication Address"
                control={control}
                address1register={{
                  ...register("CommunicationAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("CommunicationAddressLine1", e.target.value);
                  clearErrors(["CommunicationAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.CommunicationAddressLine1?.message ||
                  error?.response?.data?.errors?.CommunicationAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("CommunicationAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("CommunicationAddressLine2", e.target.value);
                  clearErrors(["CommunicationAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.CommunicationAddressLine2?.message ||
                  error?.response?.data?.errors?.CommunicationAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("CommunicationCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("CommunicationCityId", null);
                    setValue("CommunicationPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.CommunicationCityId?.message ||
                  error?.response?.data?.errors?.CommunicationCityId?.map(
                    (e: any) => e
                  )
                }
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("CommunicationPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("CommunicationPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.CommunicationPincodeId?.message ||
                  error?.response?.data?.errors?.CommunicationPincodeId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                selectedCity={selectedhcity}
                selectedPincode={selectedhpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"CommunicationCityId"}
                pincodeApi={
                  selectedhcity != null ?
                    `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}`
                    : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"CommunicationPincodeId"}
                pincodeDisable={Hcity ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <Address
                header="Permanent Address"
                control={control}
                address1register={{
                  ...register("PermanentAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("PermanentAddressLine1", e.target.value);
                  clearErrors(["PermanentAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.PermanentAddressLine1?.message ||
                  error?.response?.data?.errors?.PermanentAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("PermanentAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("PermanentAddressLine2", e.target.value);
                  clearErrors(["PermanentAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.PermanentAddressLine2?.message ||
                  error?.response?.data?.errors?.PermanentAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PermanentCityId", e?.value);
                    setselectedRcity(e);
                    setselectedRpincode({});
                  } else {
                    setValue("PermanentCityId", null);
                    setValue("PermanentPincodeId", null);
                    setselectedRcity(null);
                    setselectedRpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.PermanentCityId?.message ||
                  error?.response?.data?.errors?.PermanentCityId?.map(
                    (e: any) => e
                  )
                }
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PermanentPincodeId", e?.value);
                    setselectedRpincode(e);
                  } else {
                    setValue("PermanentPincodeId", null);
                    setselectedRpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.PermanentPincodeId?.message ||
                  error?.response?.data?.errors?.PermanentPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedRcity}
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                selectedPincode={selectedRpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"PermanentCityId"}
                pincodeApi={
                  selectedRcity != null ?
                    `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedRcity?.value}`
                    : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"PermanentPincodeId"}
                pincodeDisable={Rcity ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />
              <div className="col-lg-12 text-center mt-3">
                <Link
                  to={"/layout/mr"}
                  className="btn btn btn-outline-secondary mt-2 mx-2"
                >
                  Back
                </Link>
                {id === undefined ? (
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-2 mx-2"
                    onClick={() => {
                      reset();
                      setselectedRpincode({});
                      setselectedRcity(null);
                      setselectedhpincode({});
                      setselectedhcity(null);
                      setselecteddivision([]);
                    }}
                  >
                    Reset
                  </button>
                ) : (
                  ""
                )}
                {mrLoader ? (
                  <button
                    className="btn btn-outline-primary mt-2 mx-2"
                    disabled
                  >
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                ) : (
                  <Button
                    type="submit"
                    onclick={handleSubmit((productinfo: any) => {
                      mrFormData(productinfo);
                    })}
                    color="primary"
                    text="Submit"
                  ></Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};
