import { ErrorMessage } from "./ErrorMessage";
import { Controller } from "react-hook-form";
import "./Select.css";
import Select from "react-select";
import axiosInstance from "../_helper/Interceptor";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

interface iPropsselect {
  label?: string;
  isRequired?: boolean;
  register?: object;
  errorMessage?: string;
  containerClass?: string;
  control?: any;
  options?: any;
  name?: any;
  rules?: {};
  Multi?: boolean;
  value?: any;
  onChange?: any;
  handleCreate?: any;
  component?: any;
  updateid?: any;
  useCustomOption?: boolean;
  onDelete?: any;
  onInputChange?: any;
  keys?: any;
  api?: string;
  Disabled?: boolean;
  flag?: boolean;
  refetch?: boolean;
  setRefecth?: any;
  apiString?: any;
  getallData?: any;
  salesFlag?: boolean;
  placeholder?: string;
  defaultValue?: any;
  othervalue?: any;
}

const StockTypeEnum: any = {
  1: "Purchase",
  2: "Sales",
  3: "Return_Goods",
  4: "Expiry",
  5: "Free_Goods",
  6: "Sampling",
};

export default function ReactSelect({
  containerClass,
  label,
  isRequired,
  control,
  options,
  errorMessage,
  onChange,
  name,
  rules,
  Multi = false,
  value,
  handleCreate,
  useCustomOption,
  keys = { label: "", value: "" },
  api,
  Disabled = false,
  flag = false,
  refetch = false,
  setRefecth,
  apiString = process.env.REACT_APP_BASEURL,
  placeholder = "Select",
  defaultValue,
  // getallData = () => {},
  salesFlag = false,
}: iPropsselect) {
  useEffect(() => {
    if (refetch === true) {
      fetchData(controller.signal);
      setRefecth(false);
    }
  }, [refetch]);

  const customFilterOption = (option: any, rawInput: any) => {
    return true; // Disable automatic sorting, return true to show all options
  };

  const controller = new AbortController();

  const [optionsData, setOptionsData]: any = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);


  if (api?.includes(`${process.env.REACT_APP_WAREHOUSESTOCKTRANSFER_API}`)) {
    console.log("tr", api)
  } else {
    console.log("false", api)
  }



  const fetchData = async (signal: any) => {

    setLoader(true);
    if (api) {
      await axiosInstance
        .get(
          `${apiString}${api}${api?.includes("?") ? "&" : "?"
          }pageNumber=1&pageSize=${pageSize}${inputValue !== "" ? `&search=${inputValue}` : ""
          }`,
          { signal }
        )
        .then((res) => {
          console.log("then called");
          setLoader(false);
          // getallData(res?.data?.Data)
          const data = res?.data?.Data.map((option: any) => {
            const temp = Object.keys(option).includes("OtherColumnJSON");
            if (temp) {
              if (api === `${process.env.REACT_APP_PTRPTSSETTING_API}`) {
                const temp = JSON.parse(option.OtherColumnJSON);
                return {
                  label: option[keys.label],
                  value: {
                    MedicalMarginNumber: temp.MedicalMarginNumber,
                    AgeneyMargin: temp.AgeneyMargin,
                    PTrptssettingId: option.Id,
                  },
                };
              } else {
                if (api === `${process.env.REACT_APP_DOCTORDETAIL_API}`) {
                  const temp = Object.keys(option).includes("OtherColumnJSON");
                  if (temp) {
                    const otherColumnData = JSON.parse(option.OtherColumnJSON);
                    const label = `${option?.Value} (${otherColumnData.CategoryName
                      }) ${otherColumnData.HospitalCityName === null
                        ? ""
                        : `(${otherColumnData.HospitalCityName})`
                      }`;
                    const specialityName = otherColumnData?.SpecialityList?.map(
                      (speciality: any) => speciality?.SpecialityName
                    ).join(", ");

                    return {
                      label: option?.Value,
                      value: {
                        label: label,
                        specialityName: specialityName,
                        value: option?.Id,
                      },
                    };
                  }
                } else if (api === `${process.env.REACT_APP_CHEMIST_API}`) {
                  const temp = Object.keys(option).includes("OtherColumnJSON");
                  if (temp) {
                    const otherColumnData = JSON.parse(option.OtherColumnJSON);
                    const label = `${option?.Value} ${otherColumnData.CityName === null
                      ? ""
                      : `(${otherColumnData.CityName})`
                      }  ${otherColumnData.AddressLine === null
                        ? ""
                        : `(${otherColumnData.AddressLine})`
                      }`;

                    return {
                      label: option?.Value,
                      value: {
                        label: label,
                        value: option?.Id,
                      },
                    };
                  }
                } else if (api?.includes(`${process.env.REACT_APP_WAREHOUSESTOCKTRANSFERGETALLBATCH_API}`)) {
                  const temp = Object.keys(option).includes("OtherColumnJSON");
                  if (temp) {
                    const otherColumnData = JSON.parse(option.OtherColumnJSON);
                    const label = `${option?.Value} - (${moment(otherColumnData.ExpiryDate).format("DD-MM-YYYY")})`;
                    const quantity = `${otherColumnData.Quantity}`;
                    const expirydate = `${otherColumnData.ExpiryDate}`;
                    const manufacturedate = `${otherColumnData.ManufactureDate}`;
                    const MRP = `${otherColumnData.MRP}`;
                    const PurchasePrice = `${otherColumnData.PurchasePrice}`;
                    const StockType = `${StockTypeEnum[otherColumnData.StockType]}`
                    const PTR = `${otherColumnData.Ptr}`
                    const PTS = `${otherColumnData.Pts}`
                    return {
                      label: option?.Value,
                      value: {
                        label: label,
                        quantity: quantity,
                        expirydate: expirydate,
                        manufacturedate: manufacturedate,
                        MRP: MRP,
                        PurchasePrice: PurchasePrice,
                        StockType: StockType,
                        value: option?.Id,
                        PTR: PTR,
                        PTS: PTS
                      },
                    };
                  }
                }
                else if (api === `${process.env.REACT_APP_PRODUCT_API}`) {
                  const otherColumnData = JSON.parse(option.OtherColumnJSON);
                  const label = `${option?.Value}`;
                  const imagepath = `${otherColumnData.QRImagePath}`;
                  const QRCodeName = `${otherColumnData.QRCodeName}`;
                  return {
                    label: option?.Value,
                    value: {
                      label: label,
                      imagepath: imagepath,
                      value: option?.Id,
                      QRCodeName: QRCodeName,
                    },
                  };
                }
                else {
                  return {
                    label: option?.Value,
                    value: option?.Id,
                  };
                }
              }
            } else {
              if (!salesFlag) {
                return {
                  label: option[keys?.label],
                  value: option[keys?.value],
                };
              } else {
                return {
                  label: option[keys?.label],
                  value: {
                    value: option[keys?.value],
                    stockist: option?.ref_stockies_name,
                  },
                };
              }
            }
          });

          setOptionsData(data);
          setTotalData(res?.data?.Data.length);
        })
        .catch((error: any) => {
          console.log(controller, "controller");
          setLoader(false);
          if (controller?.signal?.reason?.code == 20) {
            console.log("Request Canceled", error);
          } else {
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }
  };

  const CustomOption = ({ innerProps, data, isHovered }: any) => (
    <div
      {...innerProps}
      style={{
        backgroundColor: isHovered ? "#deebff" : "transparent",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          margin: "10px",
          padding: "3px",
        }}
      >
        <div>{data?.value?.label}</div>
        <div>{data?.value?.specialityName}</div>
      </div>
    </div>
  );

  const onDropDownScroll = () => {
    if (pageSize == totalData) {
      setPageSize(pageSize + 10);
    }
  };

  useEffect(() => {
    fetchData(controller.signal);
    return () => {
      controller.abort();
    };
  }, [api, pageSize, Disabled, inputValue]);

  return (
    <div className={containerClass}>
      <div className="form-group">
        {label && (
          <label className="col-form-label">
            {label} {isRequired && <span className="text-danger">*</span>}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <>
              <Select
                isMulti={Multi}
                options={optionsData || options}
                isLoading={!Disabled && loader}
                filterOption={customFilterOption}
                menuPosition="fixed"
                onInputChange={(e) => {
                  setPageSize(10);
                  setInputValue(e);
                }}
                components={
                  api === `${process.env.REACT_APP_DOCTORDETAIL_API}` ||
                    api === `${process.env.REACT_APP_CHEMIST_API}`
                    || api?.includes(`${process.env.REACT_APP_WAREHOUSESTOCKTRANSFERGETALLBATCH_API}`)
                    ? {
                      Option: (props) => (
                        <CustomOption
                          {...props}
                          isHovered={props.isFocused}
                        />
                      ),
                    }
                    : undefined
                }
                placeholder={placeholder}
                isClearable={true}
                onChange={(e) => {
                  onChange(e);
                  if (e !== null) {
                    if (e.length % 5 == 0) {
                      setPageSize(pageSize + 5);
                    }
                  }
                }}
                defaultValue={defaultValue}
                value={value || ""}
                isDisabled={Disabled}
                onMenuScrollToBottom={onDropDownScroll}
              />
            </>
          )}
        />
        {/* {(typeof value === "object" || value == undefined || value == null) &&
          Object?.values(value || {})?.every(
            (x) => x === null || x === "" || x === undefined
          ) ? (
          <ErrorMessage message={errorMessage} />
        ) : (
          ""
        )} */}
        {errorMessage !== undefined && <ErrorMessage message={errorMessage} />}
      </div>
    </div>
  );
}
