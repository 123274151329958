import { useState } from 'react'
import { Action } from '../../../../common/Action';
import { Helmet } from 'react-helmet';
import { DeleteModal } from '../../../../common/DeleteModal';
import { PageHeader } from '../../../../common/PageHeader';
import AddEditProductGroupModal from './AddEditProductGroupModal';
import { InputSwitch } from 'primereact/inputswitch';
import DatatableCommon from '../../../../common/DatatableCommon';

export default function ProductGroup() {

  const apiname = process.env.REACT_APP_PRODUCTGROUP_API?.toString()
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [updateId, setupdateId]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row)
  }

  const [selectedproductgroup, setselectedproductgroup]: any = useState([]);
  const multi = selectedproductgroup?.map((e: any) => { return e.ProductGroupId })

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionProductGroupTemplate(e),
      sortable: false,
      width: "10%",
    },
    {
      field: "ProductGroupName",
      header: "Product Group Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "ProductGroupCode",
      header: "Product Group Code",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "IsCompetitorProduct",
      header: "Is Competitor Product",
      body: (e: any) => actionTemplate(e),
      sortable: true,
      width: "10vw",
    },

  ];

  const actionTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.IsCompetitorProduct} />
      </>
    );
  };

  const actionProductGroupTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ProductGroupId}
          editModal={() => {
            setupdateId(id.ProductGroupId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> Product Group </title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Product Group"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/productattributes"}
          linkflagtitle={"Product Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          columnData={columnData}
          refetchData={bool}
          // check={true} 
          setrefetchData={setbool}
          setselecteditem={setselectedproductgroup}
          selecteditem={selectedproductgroup}
          multiDelete={(e: boolean) => { if (e) { setdeleteID(selectedproductgroup?.length === 0 ? null : multi) } }}
        />

        {updateId !== null && (
          <AddEditProductGroupModal
            setisOPen={() => {
              return setupdateId(null);
            }}
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>


      <DeleteModal id={deleteID} deleteMessage={(data: boolean) => { if (data) { setbool(true); } }} apiname={apiname} setisOPen={() => setdeleteID(null)} open={deleteID != null} />
    </>
  )
}
