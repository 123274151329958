import React, { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Helmet } from "react-helmet";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import AddEditDrugHead from "./AddEditDrugHeadModal";
import { InputSwitch } from "primereact/inputswitch";
import DatatableCommon from "../../../../common/DatatableCommon";

export default function DrugHead() {

  const apiname = process.env.REACT_APP_DRUGHEAD_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [selecteddrug, setselecteddrug]: any = useState([]);
  const multi = selecteddrug?.map((e: any) => { return e.DrugHeadId })

  const [bool, setbool]: any = useState(null);

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionDrugHeadTemplate(e),
      sortable: false,
      width: "10%",
    },
    {
      field: "DrugHeadName",
      header: "Drug Head Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "IsCompetitorProduct",
      header: "Is Competitor Product",
      body: (e: any) => actionTemplate(e),
      sortable: true,
      width: "10vw",
    },

  ];

  const actionTemplate = (id: any) => {
    return (
      <>
        <InputSwitch checked={id.IsCompetitorProduct} />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionDrugHeadTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.DrugHeadId}
          editModal={() => {
            setupdateId(id.DrugHeadId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Drug Head</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Drug Head"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/productattributes"}
          linkflagtitle={"Product Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          selecteditem={selecteddrug}
          setselecteditem={setselecteddrug}
          // check={true}
          multiDelete={(e: boolean) => { if (e) { setdeleteID(selecteddrug?.length === 0 ? null : multi) } }}
        />

        {updateId !== null && (
          <AddEditDrugHead
            setisOPen={() => {
              return setupdateId(null);
            }}
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}

      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true); } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
