import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import axiosInstance from "../../../_helper/Interceptor";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditDoctorProductMap = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_DOCTORPRODUCTMAP_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
    error,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: doctorproductmap }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error: doctorproductError,
    data: response,
  }: any = useCreateByID(apiname, undefined);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselecteddoctor({
          label: data.Doctor,
          value: data.DoctorId,
        });

        let newData =
          data?.ProductList?.map((d: any) => ({
            label: d?.Value,
            value: d?.Id,
          }));
        setselectedproduct([...newData]);
        setValue("ProductIdList", newData.map((data: any) => data.value))
      }


    }
  }, [data]);

  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  const [selectedproduct, setselectedproduct]: any = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const doctorproductFormData = (submitData: any) => {


    submitData.DoctorId = typeof submitData.DoctorId === "object" ? Number(submitData.DoctorId.value) : Number(submitData.DoctorId);
    mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (doctorproductError?.response?.data?.StatusCode !== 400) {
        toast.error(doctorproductError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Doctor Product`}
        modal
        style={{ width: "1250px" }}
      >
        {!doctorproductmap ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(doctorproductFormData);
              }
            }}
            onSubmit={handleSubmit(doctorproductFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Doctor"
                name={"DoctorId"}
                isRequired
                Disabled={id ? true : false}
                onChange={(e: any) => {

                  axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${e?.value.value}`).then((res) => {

                    const newProductData = res?.data?.Data?.ProductList?.map((d: any) => {
                      return {
                        label: d?.Value,
                        value: d?.Id,
                      }
                    });
                    setselectedproduct([...newProductData]);
                    setValue("ProductIdList", newProductData.map((data: any) => data.value))

                  }).catch((err) => { setselectedproduct(null); setValue("ProductIdList", null) })
                  setselecteddoctor(e);
                  setValue("DoctorId", e?.value);
                  setIsEdited(true);
                }}
                value={selecteddoctor}
                control={control}
                api={process.env.REACT_APP_DOCTORDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DoctorId?.message ||
                  error?.response?.data?.errors?.DoctorId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Doctor is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Product"
                name={"ProductIdList"}
                isRequired
                Disabled={selecteddoctor === null}
                onChange={(e: any) => {
                  console.log(e.map((data: any) => data.value))
                  setselectedproduct(e);
                  setIsEdited(true);
                  setValue("ProductIdList", e.map((data: any) => {
                    if (typeof data?.value == "object") {
                      return data?.value?.value
                    }
                    else {

                      return data?.value
                    }
                  }
                  ));

                }}
                value={selectedproduct}
                control={control}
                Multi
                api={process.env.REACT_APP_PRODUCT_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.ProductIdList?.message ||
                  error?.response?.data?.errors?.ProductIdList?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Product is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselecteddoctor(null); setselectedproduct([]) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) :
                  (
                    <Button type="submit" outlined className="ml-2" disabled={id && !isEdited}>
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
