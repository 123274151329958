import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import axiosInstance from "../../../_helper/Interceptor";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditEmployeeDeviceMap = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_EMPLOYEEDEVICEMAPPING_API?.toString();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    error,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: employeeDeviceMap }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error: employeeDeviceMapError,
    data: response,
  }: any = useCreateByID(apiname, undefined);

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselectedemployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        });
        let newData =
          data?.DeviceList?.map((d: any) => ({
            label: d?.Value,
            value: d?.Id,
          }));
        setselecteddevice([...newData]);
        setValue("DeviceIdList", newData.map((data: any) => data.value))
      }

    }
  }, [data]);

  const [selecteddevice, setselecteddevice]: any = useState([]);

  const [selectedemployee, setselectedemployee]: any = useState(null);

  const employeeDeviceFormData = (data: any) => {
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (employeeDeviceMapError?.response?.data?.StatusCode !== 400) {
        toast.error(employeeDeviceMapError?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Employee Device Map`}
        modal
        style={{ width: "1250px" }}
      >
        {!employeeDeviceMap ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(employeeDeviceFormData);
              }
            }}
            onSubmit={handleSubmit(employeeDeviceFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Employee"
                name={"EmployeeId"}
                isRequired
                onChange={(e: any) => {
                  axiosInstance.get(`${process.env.REACT_APP_BASEURL}${apiname}/${e?.value}`).then((res) => {
                    const newDeviceData = res?.data?.Data?.DeviceList?.map((d: any) => {
                      return {
                        label: d?.Value,
                        value: d?.Id,
                      }
                    });
                    setselecteddevice([...newDeviceData]);
                    setValue("DeviceIdList", newDeviceData.map((data: any) => data.value))

                  }).catch((err) => { setselecteddevice(null); setValue("DeviceIdList", null) })
                  setselectedemployee(e);
                  setValue("EmployeeId", e?.value);
                }}
                value={selectedemployee}
                control={control}
                Disabled={id !== 0 ? true : false}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Employee is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Device"
                name={"DeviceIdList"}
                isRequired
                Multi
                Disabled={selectedemployee === null}
                onChange={(e: any) => {
                  setselecteddevice(e);
                  setValue("DeviceIdList", e);

                  setValue("DeviceIdList", e.map((data: any) => data.value));
                }}
                value={selecteddevice}
                control={control}
                api={process.env.REACT_APP_DEVICEDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DeviceIdList?.message ||
                  error?.response?.data?.errors?.DeviceIdList?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Device is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselecteddevice([]); setselectedemployee(null) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
