import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { PaginationServiceByAdminTourPlan } from "../../service/PaginationService";
import { ErrorMessage } from "../../common/ErrorMessage";
import ReactSelect from "../../common/ReactSelect";
import moment from "moment";
import { Badge } from "primereact/badge";
import { Spinner } from "../../common/Spinner";

const AdminTourPlan = () => {
  const controller = new AbortController();
  const [selecteduser, setselecteduser]: any = useState();
  const [status, setStatus]: any = useState(7);
  const [data, setData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [loading, setLoading] = useState(false);
  const [Nodata, setNodata] = useState(false);
  const [sortBy, setSortBy]: any = useState({
    sortField: null,
    sortOrder: null,
  });
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  }: any = useForm({ mode: "all" });

  useEffect(() => {
    tourPlanApprovalChange(7, 0)

  }, [])

  const form = watch();

  // useEffect(()=>{
  //   console.log(form)
  //   setlazyState({
  //     ...lazyState,
  //     paramter: form,
  //   });
  // },[form])

  const statusOption = [
    { label: "Approved", value: "2" },
    { label: "Rejected", value: "3" },
    { label: "Pending", value: "7" },
  ];
  let networkTimeout: any = null;

  useEffect(() => {
    if (lazyState.paramter !== null) {
      loadLazyData();
    }
    return () => {
      controller.abort();
    }
  }, [lazyState, sortBy]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByAdminTourPlan.getData(lazyState, sortBy, controller.signal)
        .then((data) => {
          debugger;
          const temp = data.Data.map((e: any, i: any) => {
            // return { ...e, id: i + 1 };
            return {
              ...e,
              id: i + 1,
              srNo: data.PageSize * data.PageNumber - data.PageNumber + (i + 1),
            };
          });

          console.log(temp, "temp");
          setData(temp);
          setTotalRecord(data.TotalDataCount);
          setLoading(false);
          if (temp?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  const OnSortChange = (e: any) => {
    setSortBy({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };
  const tourPlanApprovalChange = (statusParams: any, userparams: any) => {

    console.log("parameters", userparams)
    let Temp: any = {};

    if (statusParams === 0) {
      if (status !== null || status !== undefined) {
        Temp["Status"] = status;
      }
      else {
        Temp["Status"] = null;
      }
    }
    else {
      Temp["Status"] = statusParams;
    }
    if (userparams === 0) {
      if (selecteduser !== null || selecteduser !== undefined) {
        Temp["UserId"] = selecteduser;
      }
      else {
        Temp["UserId"] = null;
      }
    }
    else {
      Temp["UserId"] = userparams;
    }



    console.log(Temp, "temp")


    setlazyState({
      ...lazyState,
      paramter: Temp,
    });



  }


  const actionTemplate = (id: any) => {
    return (
      <>
        <Link
          to={`/layout/mr/admin/showtourplan/${id.MtpId}/${id.Status == "Pending" ? "P" : "A"
            }`}
        // className="btn btn-secondary"
        >
          {/* {" "}
          Show Tour Plan{" "} */}
          <button className="btn p-0" title="Show Tour Plan">
            <i className="pi pi-eye mr-1 text-primary"></i>
          </button>
        </Link>
      </>
    );
  };

  const actionDateTemplate = (id: any) => {
    // return moment(id.mtpDate).format("DD MMM, YYYY hh:mm A");
    if (id.mtpDate === null) {
      return "-";
    } else {
      return (
        <>
          <Badge
            className="m-1"
            severity={"success"}
            value={moment(id.CreatedOn).format("DD MMM, YYYY")}
          ></Badge>
          <Badge
            className="m-1"
            severity={"info"}
            value={moment(id.CreatedOn).format("hh:mm A")}
            style={{ backgroundColor: "#978EEB" }}
          ></Badge>
        </>
      );
    }
  };

  const actionUpdateDateTemplate = (id: any) => {
    // return moment(id.UpdatedOn).format("DD MMM, YYYY hh:mm A");

    if (id.UpdatedOn === null) {
      return "-";
    } else {
      return (
        <>
          <Badge
            className="m-1"
            severity={"success"}
            value={moment(id.UpdatedOn).format("DD MMM, YYYY")}
          ></Badge>
          <Badge
            className="m-1"
            severity={"info"}
            value={moment(id.UpdatedOn).format("hh:mm A")}
            style={{ backgroundColor: "#978EEB" }}
          ></Badge>
        </>
      );
    }
  };

  let obj = data.find((o: any) => o.Status !== "Pending");

  return (
    <>
      <Helmet>
        <title>Admin Tour Plan</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Admin Tour Plan</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/approvals"}>Approvals</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Admin Tour Plan
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="col-form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <select
                  className="custom-select col-12"
                  {...register("Status", {
                    required: {
                      value: true,
                      message: "Status is required",
                    },
                    onchange,
                  })}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    tourPlanApprovalChange(e.target.value, 0);
                  }}
                >
                  <option value={2}>Approved</option>
                  <option value={3}>Rejected</option>
                  <option value={7} selected>Pending</option>
                </select>
              </div>
            </div>

            <ReactSelect
              containerClass="col-lg-6"
              label="User"
              name={"UserId"}
              onChange={(e: any) => {
                console.log(e)
                setselecteduser(e);
                setValue("UserId", e);
                tourPlanApprovalChange(0, e);
              }}
              value={selecteduser}
              api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.UserId?.message}
            />

          </div>
        </form >
      </div >
      {
        data.length !== 0 ? (
          <div className="col-md-12 col-sm-12 mb-30">
            <div className="pd-20 card-box">
              <DataTable
                value={data}
                dataKey="id"
                className="custom-datatable"
                showGridlines
                size="small"
                rowHover
                scrollable
                lazy
                first={lazyState.first}
                rows={lazyState.rows}
                totalRecords={totalRecord}
                rowsPerPageOptions={[10, 20, 50, 100]}
                onPage={onPageChange}
                removableSort
                onSort={OnSortChange}
                sortField={sortBy.sortField}
                sortOrder={sortBy.sortOrder}
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                stripedRows
                paginator
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                loading={loading}
              >
                <Column
                  header="#"
                  field="srNo"
                  style={{ width: "3%", textAlign: "center" }}
                ></Column>
                <Column
                  header="Action"
                  body={(e: any) => actionTemplate(e)}
                  style={{ width: "5%", textAlign: "center" }}
                ></Column>
                <Column
                  header="Submitted By"
                  field="SubmitedBy"
                  body={null}
                  sortable
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="CreatedOn"
                  header="Submitted On"
                  sortable
                  body={(e: any) => actionDateTemplate(e)}
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="Status"
                  header="Status"
                  body={(e) => {
                    return e.Status == "Approve" ? "Approved" : e.Status == "Reject" ? "Rejected" : e.Status;
                  }}
                  style={{ textAlign: "center", width: "8%" }}
                ></Column>
                <Column
                  field="UpdatedBy"
                  header="Updated By"
                  sortable
                  body={null}
                  style={{ width: "25%" }}
                ></Column>

                {obj ? (
                  <Column
                    field="UpdatedOn"
                    header="Updated On"
                    sortable
                    body={(e: any) => actionUpdateDateTemplate(e)}
                    style={{ width: "15%" }}
                  ></Column>
                ) : null}
              </DataTable>
            </div>
          </div>
        ) : Nodata === true ? (
          <div className="col-md-12 col-sm-12 mb-30">
            <div className="pd-20 card-box text-center">
              <h3 className="text-secondary">No data found.</h3>
            </div>
          </div>
        ) : (
          ""
        )
      }
    </>
  );
};

export default AdminTourPlan;
