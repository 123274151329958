import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Input } from "../../../../common/Input";
import ReactSelect from "../../../../common/ReactSelect";
import Textarea from "../../../../common/Textarea";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}
export const AddEditCollegeMateAlumniDetailsModal = ({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) => {
  useEffect(() => {
    if (refetchData === true && api === "Speciality") {
      setselectedspeciality(null);
      resetstate(false);
    }
  }, [refetchData]);

  const apiname = process.env.REACT_APP_DOCTORCOLLEAGUESDETAIL_API?.toString();

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        console.log(data);
        const Date = data?.ColleagueDateOfBirth?.split("T")[0];
        reset(data);
        setValue("ColleagueDateOfBirth", Date);

        if (data.ColleagueSpecialityId !== null) {
          setValue("ColleagueSpecialityId", {
            label: data.ColleagueSpeciality,
          value: data.ColleagueSpecialityId,
          });
          setselectedspeciality({
            label: data.ColleagueSpeciality,
          value: data.ColleagueSpecialityId,
          });
        } else {
          setValue("ColleagueSpecialityId", null);
          setselectedspeciality(null);
        }

        if (data.ColleagueCollegeAddressCityId !== null) {
          setValue("ColleagueCollegeAddressCityId", {
            label: data.ColleagueCollegeAddressCityName,
            value: data.ColleagueCollegeAddressCityId,
          });
          setselectedccity({
            label: data.ColleagueCollegeAddressCityName,
            value: data.ColleagueCollegeAddressCityId,
          });
        } else {
          setValue("ColleagueCollegeAddressCityId", null);
          setselectedccity(null);
        }

        if (data.ColleagueCollegeAddressPincodeId !== null) {
          setValue("ColleagueCollegeAddressPincodeId", {
            label: data.ColleagueCollegeAddressPincodeName,
            value: data.ColleagueCollegeAddressPincodeId,
          });
          setselectedcpincode({
            label: data.ColleagueCollegeAddressPincodeName,
            value: data.ColleagueCollegeAddressPincodeId,
          });
        } else {
          setValue("ColleagueCollegeAddressPincodeId", null);
          setselectedcpincode(null);
        }

        if (data.ColleagueHospitalAddressCityId !== null) {
          setValue("ColleagueHospitalAddressCityId", {
            label: data.ColleagueHospitalAddressCityName,
            value: data.ColleagueHospitalAddressCityId,
          });
          setselectedhcity({
            label: data.ColleagueHospitalAddressCityName,
            value: data.ColleagueHospitalAddressCityId,
          });
        } else {
          setValue("ColleagueHospitalAddressCityId", null);
          setselectedhcity(null);
        }

        if (data.ColleagueHospitalAddressPincodeId !== null) {
          setValue("ColleagueHospitalAddressPincodeId", {
            label: data.ColleagueHospitalAddressPincodeName,
            value: data.ColleagueHospitalAddressPincodeId,
          });
          setselectedhpincode({
            label: data.ColleagueHospitalAddressPincodeName,
            value: data.ColleagueHospitalAddressPincodeId,
          });
        } else {
          setValue("ColleagueHospitalAddressPincodeId", null);
          setselectedhpincode(null);
        }

      }
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const Hcity = watch("ColleagueHospitalAddressCityId");
  const Ccity = watch("ColleagueCollegeAddressCityId");

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedspeciality, setselectedspeciality]: any = useState(null);

  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);
  const [selectedccity, setselectedccity]: any = useState(null);
  const [selectedcpincode, setselectedcpincode]: any = useState(null);

  const collegemateFormData = (data: any) => {
    data.DoctorId = Number(doctorid);

    data.ColleagueSpecialityId = selectedspeciality?.value;
    data.ColleagueHospitalAddressCityId = selectedhcity?.value;
    data.ColleagueHospitalAddressPincodeId = selectedhpincode?.value;
    data.ColleagueCollegeAddressCityId = selectedccity?.value;
    data.ColleagueCollegeAddressPincodeId = selectedcpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} College Mate / Alumni Details`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(collegemateFormData);
              }
            }}
            onSubmit={handleSubmit(collegemateFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.ColleagueName?.message ||
                  error?.response?.data?.errors?.ColleagueName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ColleagueName", e.target.value);
                  clearErrors(["ColleagueName"]);
                }}
                register={{
                  ...register("ColleagueName", {
                    required: { value: true, message: "Name is required" },
                    pattern: {
                      value: /^.{1,50}$/gm,
                      message: "Enter a valid colleague name (1-50 characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="DOB"
                type="date"
                isRequired
                errorMessage={
                  errors?.ColleagueDateOfBirth?.message ||
                  error?.response?.data?.errors?.ColleagueDateOfBirth?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ColleagueDateOfBirth", e.target.value);
                  clearErrors(["ColleagueDateOfBirth"]);
                }}
                register={{
                  ...register("ColleagueDateOfBirth", {
                    required: {
                      value: true,
                      message: "Date Of Birth is required",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Speciality"
                control={control}
                name="ColleagueSpecialityId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ColleagueSpecialityId", e?.value);
                    setselectedspeciality(e);
                  } else {
                    setValue("ColleagueSpecialityId", null);
                    setselectedspeciality(null);
                  }
                }}
                value={selectedspeciality}
                api={process.env.REACT_APP_SPECIALITY_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.ColleagueSpecialityId?.message ||
                  error?.response?.data?.errors?.ColleagueSpecialityId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Speciality is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Hospital Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.ColleagueHospitalName?.message ||
                  error?.response?.data?.errors?.ColleagueHospitalName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ColleagueHospitalName", e.target.value);
                  clearErrors(["ColleagueHospitalName"]);
                }}
                register={{
                  ...register("ColleagueHospitalName", {
                    required: {
                      value: true,
                      message: "Hospital Name is required",
                    },
                    pattern: {
                      value: /^.{2,100}$/gm,
                      message: "Enter a valid hospital name (2-100 characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="College Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.ColleagueCollegeName?.message ||
                  error?.response?.data?.errors?.ColleagueCollegeName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ColleagueCollegeName", e.target.value);
                  clearErrors(["ColleagueCollegeName"]);
                }}
                register={{
                  ...register("ColleagueCollegeName", {
                    required: {
                      value: true,
                      message: "College Name is required",
                    },
                    pattern: {
                      value: /^.{2,100}$/gm,
                      message: "Enter a valid college name (2-100 characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Passout Year"
                type="text"
                isRequired
                errorMessage={
                  errors?.ColleaguePassOutYear?.message ||
                  error?.response?.data?.errors?.ColleaguePassOutYear?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ColleaguePassOutYear", e.target.value);
                  clearErrors(["ColleaguePassOutYear"]);
                }}
                register={{
                  ...register("ColleaguePassOutYear", {
                    required: {
                      value: true,
                      message: "PassOut Year is required",
                    },
                    pattern: {
                      value: /^\d{4}$/,
                      message: "Enter a valid passout year (4 digits only)",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={500}
                isRequired
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                register={{
                  ...register("Remarks", {
                    required: {
                      value: true,
                      message: "Remarks is required",
                    },
                  }),
                }}
              />

              <Address
                control={control}
                header="Hospital Address"
                address1register={{
                  ...register("ColleagueHospitalAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("ColleagueHospitalAddressLine1", e.target.value);
                  clearErrors(["ColleagueHospitalAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.ColleagueHospitalAddressLine1?.message ||
                  error?.response?.data?.errors?.ColleagueHospitalAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("ColleagueHospitalAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("ColleagueHospitalAddressLine2", e.target.value);
                  clearErrors(["ColleagueHospitalAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.ColleagueHospitalAddressLine2?.message ||
                  error?.response?.data?.errors?.ColleagueHospitalAddressLine2?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ColleagueHospitalAddressCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("ColleagueHospitalAddressCityId", null);
                    setValue("ColleagueHospitalAddressPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.ColleagueHospitalAddressCityId?.message ||
                  error?.response?.data?.errors?.ColleagueHospitalAddressCityId?.map(
                    (e: any) => e
                  )
                }
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ColleagueHospitalAddressPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("ColleagueHospitalAddressPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.ColleagueHospitalAddressPincodeId?.message ||
                  error?.response?.data?.errors?.ColleagueHospitalAddressPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedhcity}
                selectedPincode={selectedhpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"ColleagueHospitalAddressCityId"}
                pincodeApi={
                  selectedhcity != null ?
                  `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}` : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"ColleagueHospitalAddressPincodeId"}
                pincodeDisable={selectedhcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <Address
                control={control}
                header="College Address"
                address1register={{
                  ...register("ColleagueCollegeAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("ColleagueCollegeAddressLine1", e.target.value);
                  clearErrors(["ColleagueCollegeAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.ColleagueCollegeAddressLine1?.message ||
                  error?.response?.data?.errors?.ColleagueCollegeAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("ColleagueCollegeAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("ColleagueCollegeAddressLine2", e.target.value);
                  clearErrors(["ColleagueCollegeAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.ColleagueCollegeAddressLine2?.message ||
                  error?.response?.data?.errors?.ColleagueCollegeAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ColleagueCollegeAddressCityId", e?.value);
                    setselectedccity(e);
                    setselectedcpincode({});
                  } else {
                    setValue("ColleagueCollegeAddressCityId", null);
                    setValue("ColleagueCollegeAddressPincodeId", null);
                    setselectedccity(null);
                    setselectedcpincode(null);
                  }
                }}
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address4errorMessage={
                  errors?.ColleagueCollegeAddressCityId?.message ||
                  error?.response?.data?.errors?.ColleagueCollegeAddressCityId?.map(
                    (e: any) => e
                  )
                }
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("ColleagueCollegeAddressPincodeId", e?.value);
                    setselectedcpincode(e);
                  } else {
                    setValue("ColleagueCollegeAddressPincodeId", null);
                    setselectedcpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.ColleagueCollegeAddressPincodeId?.message ||
                  error?.response?.data?.errors?.ColleagueCollegeAddressPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedccity}
                selectedPincode={selectedcpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"ColleagueCollegeAddressCityId"}
                pincodeApi={
                  selectedccity != null ?
                  `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedccity?.value}` : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"ColleagueCollegeAddressPincodeId"}
                pincodeDisable={selectedccity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedspeciality(null);
                      setselectedccity(null);
                      setselectedcpincode(null);
                      setselectedhcity(null);
                      setselectedhpincode(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
