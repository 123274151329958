import React, { useState } from "react";
import { Helmet } from "react-helmet";
import DatatableCommon from "../../../common/DatatableCommon";
import { PageHeader } from "../../../common/PageHeader";
import { Action } from "../../../common/Action";
import AddEditEvent from "./AddEditEvent";
import { DeleteModal } from "../../../common/DeleteModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import moment from "moment";
import { Badge } from "primereact/badge";

const Event = () => {
  const apiname = process.env.REACT_APP_EVENT_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedRows, setSelectedRows]: any = useState([]);

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [updateId, setupdateId]: any = useState(null);

  const filterJson = {
    Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    EventTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
  }

  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      // frozen: true,
      body: (e: any) => actionEventTemplate(e),
      width: "2vw",
    },
    {
      field: "Name",
      header: "Name",
      // frozen: true,
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by name",
      body: null,
      width: "10vw",
    },
    {
      field: "EventTypeName",
      header: "Event Type Name",
      filterPlaceholder: "Search by event type name",
      sortable: true,
      filter: true,
      body: null,
      width: "10vw",
    },
    {
      field: "EventStartDate",
      header: "Start Date",
      sortable: true,
      body: (e: any) => startDate(e),
      width: "10vw",
      dataType: "date"
    },
    {
      field: "EventEndDate",
      header: "End Date",
      sortable: true,
      body: (e: any) => endDate(e),
      width: "10vw",
      dataType: "date"

    },
    {
      field: "Address",
      header: "Address",
      sortable: true,
      // body: null,
      body: (data: any) => {
        return <p title={data.Address} style={{
          width: "200px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}>{data.Address === "" ? " - " : data.Address}</p>;
      },
      width: "10vw",
    },

  ];

  const startDate = (id: any) => {
    // return moment(id.EventStartDate).format("DD MMM,YYYY");
    if (id.EventStartDate === null) {
      return "-";
    } else {
      return (
        <>
          <Badge
            className="m-1"
            severity={"success"}
            value={moment(id.EventStartDate).format("DD MMM, YYYY")}
          ></Badge>

        </>
      );
    }
  };

  const endDate = (id: any) => {
    // return moment(id.EventEndDate).format("DD MMM,YYYY");
    if (id.EventEndDate === null) {
      return "-";
    } else {
      return (
        <>
          <Badge
            className="m-1"
            severity={"success"}
            value={moment(id.EventEndDate).format("DD MMM, YYYY")}
          ></Badge>

        </>
      );
    }
  };

  const actionEventTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Event</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Event"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditEvent
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default Event;
