import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useCreateByID } from "../../../hooks/Api/Api";
import axiosInstance from "../../../_helper/Interceptor";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { EditForm } from "./EditForm";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ErrorModal from "./ErrorModal";
import { LoaderContext } from "../../../App";

const StockistSale = () => {
  const fullScreenLoader = useContext(LoaderContext);
  const navigate = useNavigate();
  const [stockistAll, setStockistAll] = useState([]);
  const [selectedStokist, setSelectedStokist]: any = useState(null);
  const [responseData, setResponseData]: any = useState(null);
  const [editItem, setEditItem]: any = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  }: any = useForm({ mode: "all" });

  const { mutate, isSuccess, isError, error, isLoading, data }: any =
    useCreateByID(`${process.env.REACT_APP_PRIMARYSALECHECKSTOCKISTSALESDATA_API}`);

  const {
    mutate: uploadJson,
    isSuccess: uploadSuccess,
    isError: uploadError,
    error: uploadERR,
    isLoading: uploadLoading,
    data: uploadData,
  }: any = useCreateByID(`${process.env.REACT_APP_PRIMARYSALECREATECHECKEDSTOCKISTSALES_API}`);


  const handleDownload = async (data: any) => {
    setResponseData(null)

    debugger
    console.log(selectedStokist, "selectedStokist")
    data.date = `${data.date}-01`;
    data.StockistName = data.stockistId.stockist
    data.stockistId = data.stockistId.value
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      const temp = data?.data?.Data?.StockistSaleDataCheckingModels.map(
        (e: any, i: any) => ({
          ...e,
          id: i + 1,
        })
      );

      const temp_array = {
        PrimarySalesMasterId: data?.data?.Data.PrimarySalesMasterId,
        StockistSaleDataCheckingModels: temp,
      };
      console.log(temp_array, "temp data");
      setResponseData(temp_array);
    }
    if (isError) {
      toast.error(error.response.data.Message, {
        position: "top-right",
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    if (isLoading) {
      fullScreenLoader.setloader(true)
    }
    else {
      fullScreenLoader.setloader(false)
    }
  }, [isLoading])
  useEffect(() => {
    if (uploadSuccess) {
      toast.success("Stockist data added successfully", {
        position: "top-right",
      });
      reset();
      setSelectedStokist(null);
      setResponseData([]);
    }
    if (uploadError) {
      if (uploadERR.response.data.StatusCode == 500) {
        const temp = JSON.parse(uploadERR?.response?.data?.Message);
        setErrorMessage(temp);
      } else {
        toast.error(uploadERR?.response?.data?.Message, {
          position: "top-right",
        });
      }
    }
  }, [uploadSuccess, uploadError]);

  const handleDelete = (id: any) => {
    debugger
    console.log(id)
    const newData = responseData?.StockistSaleDataCheckingModels?.filter((item: any) => item?.id !== id);
    console.log(newData, "New Data")
    const dataDelete = {
      PrimarySalesMasterId: responseData.PrimarySalesMasterId,
      StockistSaleDataCheckingModels: newData,
    };
    setResponseData(dataDelete);
  };

  const handleEdit = (item: any) => {
    setEditItem(item);
  };

  const handleSave = (editItem: any) => {
    const newData: any = responseData?.StockistSaleDataCheckingModels.map(
      (item: any) => (item.id === editItem.id ? editItem : item)
    );
    const datasave = {
      PrimarySalesMasterId: responseData.PrimarySalesMasterId,
      StockistSaleDataCheckingModels: newData,
    };
    setResponseData(datasave);
    setEditItem(null);
  };

  const onSubmit = () => {
    debugger
    const temp_data = {
      PrimarySalesMasterId: responseData?.PrimarySalesMasterId,
      stockistSaleDataCheckingModel: responseData?.StockistSaleDataCheckingModels,
      Forcefully: false,
    };
    uploadJson(temp_data);
  };

  const columns = [
    {
      header: "#",
      field: "id",
      width: "1vw"
    },
    {
      header: "Product Name",
      field: "ProductName",
      width: "10vw",
      body: null,
    },
    {
      header: "Stockist Name",
      field: "StockistName",
      width: "10vw",
      body: null,
    },
    {
      header: "Division Name",
      field: "DivisionName",
      width: "10vw",
      body: null,
    },
    {
      header: "Opening Qty",
      field: "OpeningQty",
      width: "5vw",
      body: null,
    },
    {
      header: "Purchase Qty",
      field: "PurchaseQty",
      width: "5vw",
      body: null,
    },
    {
      header: "Sales Qty",
      field: "SalesQty",
      width: "5vw",
      body: null,
    },
    {
      header: "Closing Qty",
      field: "ClosingQty",
      width: "5vw",
      body: null,
    },
    {
      header: "Free Strips",
      field: "FreeStrips",
      width: "5vw",
      body: null,
    },
    {
      header: "Action",
      width: "8vw",
      body: (e: any) => {
        return (
          <>
            <button
              className="btn btn-secondary btn-sm mr-2"
              type="button"
              onClick={() => handleEdit(e)}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => handleDelete(e?.id)}
            >
              Delete
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Stockist Sales</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Stockist Sales</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Stockist Sales
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Upload Excel</h5>
        </div>
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <Input
              containerClass="col-lg-6"
              label="Month"
              type="month"
              isRequired
              errorMessage={errors?.date?.message}
              register={{
                ...register("date", {
                  required: {
                    value: true,
                    message: "From Month is required",
                  },
                }),
              }}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Stockist"
              isRequired
              name={"stockistId"}
              onChange={(e: any) => {
                setSelectedStokist(e);
                setValue("stockistId", e?.value);
              }}
              salesFlag
              api={process.env.REACT_APP_GETSTOCKIES_API}
              apiString={process.env.REACT_APP_BASEURL_PYTHON_DOCTOEXCEL}
              // apiString={"https://doctoexcel.aisante.in/"}
              keys={{ label: "stockies", value: "id" }}
              value={selectedStokist}
              control={control}
              errorMessage={errors?.stockistId?.message}
              rules={{
                required: { value: true, message: "Stockist is required" },
              }}
            />

            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Select File <span className="text-danger">*</span>
                </label>
                <input
                  {...register("VisitUploadChemistExcel", {
                    required: {
                      value: true,
                      message: "Please Upload Excel",
                    },
                  })}
                  type="file"
                  className="form-control"
                />
                <ErrorMessage
                  message={errors?.VisitUploadChemistExcel?.message}
                />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button className="btn btn-outline-primary mx-2" type="submit">
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  disabled
                >
                  Uploading...
                  {/* <Spinner width={"1rem"} height={"1rem"} /> */}
                </button>
              )}
            </div>

            {responseData !== null && (
              <>
                <div className="col-md-12 mt-3">
                  <DataTable
                    value={responseData?.StockistSaleDataCheckingModels}
                    paginator
                    rows={10}
                    showGridlines
                    rowHover
                    stripedRows
                    size="small"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    {columns.map((e: any) => {
                      return (
                        <Column
                          field={e?.field}
                          header={e?.header}
                          body={e?.body}
                          style={{ width: e?.width }}
                        ></Column>
                      );
                    })}
                  </DataTable>
                </div>

                <div className="col-md-12 text-center">
                  {!uploadLoading ? (
                    <button
                      className="btn btn-outline-primary mx-2"
                      type="submit"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-primary mx-2"
                      type="submit"
                      disabled
                    >
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </form>
      </div>

      <EditForm
        open={editItem !== null}
        setisOPen={() => setEditItem(null)}
        data={editItem}
        onSave={handleSave}
      />

      <ErrorModal
        open={errorMessage !== null}
        setisOPen={() => setErrorMessage}
        data={errorMessage}
        response={(data: boolean) => {
          setErrorMessage(null);
          setResponseData([]);
          reset();
          setSelectedStokist({});
          if (data) {
            navigate("/layout/chemistMap");
          }
        }}
      />
    </>
  );
};

export default StockistSale;
