import React, { useState } from 'react';
import Select from 'react-select';
import QR from "../../../assets/images/qr.png";

const options :any = [
  { value: 'cat', label: 'Cat', imageUrl: QR },
  { value: 'dog', label: 'Dog', imageUrl: QR },
  { value: 'bird', label: 'Bird', imageUrl: QR }
];

const ImageSelector = () => {
  const [selectedOption, setSelectedOption] :any = useState(null);

  const handleChange = (selectedOption:any) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
      {selectedOption && (
        <div style={{ marginTop: '20px' }}>
          <h2>{selectedOption.label}</h2>
          <img
            src={selectedOption.imageUrl}
            alt={selectedOption.label}
            style={{ width: '200px', height: '200px' }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageSelector;


// const encodeFileToBase64 = (file: any) => {
//   return new Promise((resolve, reject) => {
//     const reader: any = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result.split(",")[1]);
//     reader.onerror = (error: any) => reject(error);
//   });
// };

// const preparePayload = async (files: any) => {
//   const filesArray = [];
//   for (let i = 0; i < files.length; i++) {
//     const encodedFile = await encodeFileToBase64(files[i]);
//     filesArray.push({
//       filename: files[i].name,
//       content: encodedFile,
//     });
//   }
//   return filesArray;
// };

// const [fileData, setFileData] = useState([]);

// const handleUpload = async () => {
//   const payload: any = await preparePayload(files);
//   setFileData(payload);
// };

// const [files, setFiles] = useState([]);

// const handleFileChange = (e: any) => {
//   setFiles(e.target.files);
// };


{/* <input type="file" multiple onChange={handleFileChange} />
          <button onClick={handleUpload}>Upload</button>



          {
            fileData.length !== 0 && fileData.map((e:any)=>{
              return <FileDisplay filename={e.filename} content={e.content} />
            })
          } */}