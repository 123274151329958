import { useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditWareHouseModel } from "./AddEditWareHouseModel";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { NullHandler } from "../../../common/NullHandler";


export const WareHouse = () => {
    const apiname = process.env.REACT_APP_INVENTORYWAREHOUSE_API?.toString();
    const [deleteID, setdeleteID]: any = useState(null);

    const [selectedWareHouse, setSelectedWareHouse]: any = useState([]);
    const multi = selectedWareHouse?.map((e: any) => {
        return e.Id;
    });


    const [bool, setbool]: any = useState(null);

    const deleteiddetect = (row: any) => {
        setdeleteID(row);
    };

    const columnData = [
        {
            header: "#",
            field: "srNo",
            width: "2rem"
        },
        {
            header: "Action",
            sortable: false,
            filter: false,
            body: (e: any) => actionWareHouseTemplate(e),
            width: "1vw",
        },
        {
            field: "Name",
            header: "Warehouse Name",
            bodyClassName: "text-info h6",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by Warehouse name",
            body: null,
            width: "10vw",
        },

        // {
        //     field: "AddressId",
        //     header: "Address",
        //     filterPlaceholder: "Search by Address",
        //     sortable: true,
        //     // dataType: "numeric",
        //     filter: true,
        //     body: (data: any) => {
        //         return NullHandler(data.AddressId);
        //     },
        //     width: "10vw",
        // },
        {
            field: "CompanyName",
            header: "Company",
            filterPlaceholder: "Search by Company Name",
            sortable: true,
            // dataType: "numeric",
            filter: true,
            body: (data: any) => {
                return NullHandler(data.CompanyName);
            },
            width: "10vw",
        },

    ];

    const filterJson = {
        Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        CompanyName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
    }

    const [updateId, setupdateId]: any = useState(null);
    const actionWareHouseTemplate = (id: any) => {
        return (
            <>
                <Action
                    id={id.Id}
                    editModal={() => {
                        setupdateId(id.Id);
                    }}
                    displayid={deleteiddetect}
                />
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>Warehouse</title>
            </Helmet>
            <div className="min-height-200px">
                <PageHeader
                    headTitle="Warehouse"
                    onClick={() => {
                        setupdateId(0);
                    }}
                    id={0}
                    list={true}
                    linkflag
                    linkflagURL={"/layout/InventoryMaster"}
                    linkflagtitle={"Master"}
                />

                <DatatableCommon

                    apiname={apiname}
                    multiDelete={(data: boolean) => {
                        if (data) {
                            setdeleteID(selectedWareHouse?.length === 0 ? null : multi);
                        }
                    }}

                    filterJson={filterJson}
                    setselecteditem={setSelectedWareHouse}
                    selecteditem={selectedWareHouse}
                    columnData={columnData}
                    refetchData={bool}
                    setrefetchData={setbool}

                />



                {updateId !== null && (
                    <AddEditWareHouseModel
                        fetch={(data: boolean) => {
                            if (data) {
                                setbool(true);
                            }
                        }}
                        setisOPen={() => {
                            return setupdateId(null);
                        }}
                        open={updateId !== null}
                        id={updateId}
                    />
                )}
            </div>

            <DeleteModal
                id={deleteID}
                deleteMessage={(data: boolean) => {
                    if (data) {
                        setbool(true);
                    }
                }}
                apiname={apiname}
                setisOPen={() => setdeleteID(null)}
                open={deleteID != null}
            />
        </>
    );
};
