import AdminPanelCard from "../common/AdminPanelCard";
import CRMImg from "../assets/images/Group 5250.png";
import Threep from "../assets/images/9041268_file_earmark_bar_graph_fill_icon 1 (1).png";
import visitReportImag from "../assets/images/Vector (2).png";
import jwrImg from "../assets/images/data.png";
import visitcoutImg from "../assets/images/Group (5).png";
import dcrImg from "../assets/images/Group (6).png";
import targetImg from "../assets/images/target-svgrepo-com.png";
import { Helmet } from "react-helmet";

export const Reports = () => {
  const role : any = localStorage.getItem("Roles");

  let admincard = [
    {
      name: "CRM",
      link: role == "admin" ? "/layout/report/admincrm" : "/layout/report/crm",
      img: CRMImg,
    },
    {
      name: "Target Achievement",
      link: "/layout/report/targetachievementreport",
      img: targetImg,
    },
    {
      name: "3P",
      link: "/layout/report/threepreport",
      img: Threep,
    },
    {
      name: "Visit Reports",
      link: "/layout/report/visitReport",
      img: visitReportImag,
    },
    {
      name: "JWR Observation",
      link: "/layout/JWRObservation",
      img: jwrImg,
    },
    {
      name: "CRM (bi)",
      link: "/layout/CRMReportGrid",
      img: CRMImg,
    },
    {
      name: "Doctor Visit Count Report",
      link: "/layout/report/doctorvisitcount",
      img: visitcoutImg,
    },
    {
      name: "DCR",
      link: "/layout/mr/admin/adminDCR",
      img: dcrImg,
    },
    {
      name: "RCPA Report",
      link: "/layout/report/chemistvisitrcpa",
      img: visitcoutImg,
    },
    {
      name: "Doctor Visit Frequency Report",
      link: "/layout/report/doctorvisitfrequency",
      img: visitcoutImg,
    },
    {
      name: "Chemist Visit Frequency Report",
      link: "/layout/report/chemistvisitfrequency",
      img: visitcoutImg,
    },
    {
      name: "Doctor Visit Coverage Report",
      link: "/layout/report/doctorvisitcoverage",
      img: visitcoutImg,
    },
    {
      name: "Late DCR Submission Report",
      link: "/layout/report/lateDCRSubmissionReport",
      img: dcrImg,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard
              name={card?.name}
              link={card?.link}
              imageSrc={card?.img}
            />
          </div>
        ))}
      </div>
    </>
  );
};
