import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { useEffect, useState } from "react";
import DashboardIcon from "../../assets/images/home-1_svgrepo.com.png";
import AdminPanalImg from "../../assets/images/user_456283 1.png";
import TourProgramImg from "../../assets/images/tourProgram.png";
import AprovelsImg from "../../assets/images/aprovelse.png";
import MappingImg from "../../assets/images/mapping.png";
import ReportImg from "../../assets/images/reports.png";
import AttendenceImg from "../../assets/images/attendence.png";
import ReminderEventImg from "../../assets/images/reminds.png";
import SampleManagementImg from "../../assets/images/samplemanage.png";
import GiftManagementImg from "../../assets/images/Group (7).png";
import GuideImg from "../../assets/images/Frame.png";
import UploadImg from "../../assets/images/upload.png";
import WareHouseImg from "../../assets/images/warehouse.png";
import HolidayImg from "../../assets/images/holiday.png";
import GeographyImg from "../../assets/images/globe-3-512.png";
import Other from "../../assets/images/icons8-other-64.png";
import AttributesImage from "../../assets/images/attributes.png";

export function SideBar2({ openMenu, onBreakPoint, onBackdropClick, toggled }) {
  const location = useLocation();
  const [menuCollaps, setMenuCollaps] = useState([]);

  const ToggleMenu = (Name) => {
    if (Name[1] === undefined) {
      if (Name[0] === menuCollaps[0]) {
        setMenuCollaps([]);
      } else {
        setMenuCollaps(Name);
      }
    } else {
      if (Name[0] === menuCollaps[0] && Name[1] === menuCollaps[1]) {
        setMenuCollaps([menuCollaps[0]]);
      } else {
        setMenuCollaps(Name);
      }
    }
  };

  const menuItemStyle = {
    "&:hover": {
      backgroundColor: "#142127db",
      color: "#FFFFFF",
    },
  };
  const role = localStorage.getItem("Roles");
  return (
    <Sidebar
      collapsed={!openMenu}
      width="280px"
      // className="sidebar"
      toggled={toggled}
      onBackdropClick={onBackdropClick}
      onBreakPoint={onBreakPoint}
      style={{
        height: "100vh",
        position: "fixed",
        zIndex: 1000,
        fontWeight: 400,
        fontSize: "15px",
      }}
      breakPoint="md"
      backgroundColor="#142127 !important"
      collapsedWidth="60px"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/* <div style={{ display: "flex", alignItems: "center" }}>BiosLAb</div> */}
        <div style={{ flex: 1, marginBottom: "32px" }}>
          <div className="sidebar-menu">
            <Menu
              menuItemStyles={{
                button: ({ active }) => {
                  return {
                    color: active ? "#142127db" : "#FFFFFF",
                    backgroundColor: active ? "#BDCFC5" : "#142127db",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    padding: "20px 20px 20px 9px",
                    // borderRadius: active && "0px 10px 10px 0px",
                    transition: "ease .1s",
                    "& img": {
                      filter: active && "invert(1)",
                    },
                    "&:hover": {
                      backgroundColor: "#DEE7E2",
                      color: "#142127db",
                      // borderRadius: "0px 10px 10px 0px",

                      "& img": {
                        filter: "invert(1)",
                      },
                    },
                  };
                }
              }



              }
            >
              <div
                className="brand-logo"
                style={{ marginLeft: openMenu ? "20px" : -17 }}
              >
                <Link to={"/layout/dashboard"}>
                  <img
                    src={openMenu ? logo : "/logo-white.png"}
                    alt=""
                    className="light-logo"
                  />
                </Link>
                {/* <div className="close-sidebar" data-toggle="left-sidebar-close">
                  <i className="ion-close-round"></i>
                </div> */}
              </div>

              {/* {role === "admin" && (
                <Link to="/" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-house"></span>}
                  >
                    Dashboard
                  </MenuItem>
                </Link>
              )} */}

              {role === "admin" && (
                <Link to="/layout/maindashboard">
                  <MenuItem
                    active={location.pathname === "/layout/maindashboard"}
                    rootStyles={menuItemStyle}
                    icon={
                      <img
                        src={DashboardIcon}
                        className="menu-icon"
                        style={{ height: "25px", width: "25px" }}
                      />
                    }
                  >
                    Dashboard
                  </MenuItem>
                </Link>
              )}
              {/* <Link to="/layout/detaildashboard" className="text-white"> */}
              <Link to="/">
                <MenuItem
                  active={location.pathname === "/layout/dashboard"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={DashboardIcon}
                      className="menu-icon"
                      style={{ height: "25px", width: "25px" }}
                    />
                  }
                >
                  Analytic dashboard
                </MenuItem>
              </Link>
              <Link to="/layout/adminpanel" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/adminpanel"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={AdminPanalImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  Admin Panel
                </MenuItem>
              </Link>

              <Link to="/layout/tourprogram" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/tourprogram"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={TourProgramImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  Tour program
                </MenuItem>
              </Link>

              {role !== "MR" && (
                <Link to="/layout/approvals" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/approvals"}
                    rootStyles={menuItemStyle}
                    icon={
                      <img
                        src={AprovelsImg}
                        className="menu-icon"
                        style={{ height: "20px", width: "20px" }}
                      />
                    }
                  >
                    Approvals
                  </MenuItem>
                </Link>
              )}

              <Link to="/layout/mapping" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/mapping"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={MappingImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  Mapping
                </MenuItem>
              </Link>

              {/* Chemist Menu Start*/}
              <Link to="/layout/reports" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/reports"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={ReportImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  Reports
                </MenuItem>
              </Link>

              {/* {role !== "MR" && (
                <Link to="/layout/Attendance" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/Attendance"}
                    rootStyles={menuItemStyle}
                    icon={
                      <img
                        src={AttendenceImg}
                        className="menu-icon"
                        style={{ height: "20px", width: "20px" }}
                      />
                    }
                  >
                    Attendance
                  </MenuItem>
                </Link>
              )} */}

              {/* <Link to="/layout/eventmanagement/event" className="text-white">
                <MenuItem
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={ReminderEventImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  reminders / events
                </MenuItem>
              </Link> */}

              <SubMenu
                active={!openMenu && menuCollaps[0] === "Event"}
                onClick={() => ToggleMenu(["Event"])}
                label="Event"
                rootStyles={menuItemStyle}
                icon={
                  <img
                    src={ReminderEventImg}
                    className="menu-icon"
                    style={{ height: "19px", width: "19px" }}
                  />
                }
                open={menuCollaps[0] === "Event"}
              >
                <Link to="/layout/eventmanagement/event" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/eventmanagement/event"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Events
                  </MenuItem>
                </Link>

                <Link
                  to="/layout/eventmanagement/contact"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/eventmanagement/contact"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Networking Contact
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/eventmanagement/patient"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/eventmanagement/patient"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Patient
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/eventmanagement/eventattendance"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/eventmanagement/eventattendance"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Event Attendance
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/eventmanagement/attendee"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/eventmanagement/attendee"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Attendees
                  </MenuItem>
                </Link>

                <Link to="/layout/submaster/attendedAs" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/submaster/attendedAs"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Attended As
                  </MenuItem>
                </Link>

                {role !== "MR" && (
                  <Link
                    to="/layout/eventmanagement/eventsemployeemap"
                    className="text-white"
                  >
                    <MenuItem
                      active={location.pathname == "/layout/eventmanagement/eventsemployeemap"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Employee Map
                    </MenuItem>
                  </Link>
                )}
              </SubMenu>

              <SubMenu
                active={!openMenu && menuCollaps[0] === "Upload"}
                onClick={() => ToggleMenu(["Upload"])}
                label="Upload"
                rootStyles={menuItemStyle}
                icon={
                  <img
                    src={UploadImg}
                    className="menu-icon"
                    style={{ height: "20px", width: "20px" }}
                  />
                }
                open={menuCollaps[0] === "Upload"}
              >
                <Link to="/layout/StockistSale" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/StockistSale"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Stockist Sale
                  </MenuItem>
                </Link>

                <Link to="/layout/PartyWiseSale" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/PartyWiseSale"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Party-wise Sales
                  </MenuItem>
                </Link>
                <Link to="/layout/upload/visitupload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/upload/visitupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Visit
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/upload/giftsampleupload"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/upload/giftsampleupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Gift & Sample
                  </MenuItem>
                </Link>
                <Link to="/layout/upload/giftupload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/upload/giftupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Gift
                  </MenuItem>
                </Link>
                <Link to="/layout/upload/sampleupload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/upload/sampleupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Sample
                  </MenuItem>
                </Link>
                {role !== "MR" && (
                  <Link to="/layout/master/claim/src" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/master/claim/src"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      SRC
                    </MenuItem>
                  </Link>
                )}
                <Link
                  to="/layout/upload/travellingallowance"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/upload/travellingallowance"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Travelling
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/upload/monthlyallowanceupload"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/upload/monthlyallowanceupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Monthly
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/upload/dailyallowanceupload"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/upload/dailyallowanceupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Daily
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/upload/noworkingallowanceupload"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/upload/noworkingallowanceupload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    NWA
                  </MenuItem>
                </Link>
                <Link to="/layout/DoctorBulkUpload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/DoctorBulkUpload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Doctor Bulk Upload
                  </MenuItem>
                </Link>
                <Link to="/layout/ChemistBulkUpload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/ChemistBulkUpload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Chemist Bulk Upload
                  </MenuItem>
                </Link>
                <Link to="/layout/StockistBulkUpload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/StockistBulkUpload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Stockist Bulk Upload
                  </MenuItem>
                </Link>
                <Link to="/layout/HospitalBulkUpload" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/HospitalBulkUpload"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Hospital Bulk Upload
                  </MenuItem>
                </Link>

                {role === "admin" && (
                  <>
                    <Link
                      to="/layout/upload/targetUpload"
                      className="text-white"
                    >
                      <MenuItem
                        active={location.pathname == "/layout/upload/targetUpload"}
                        rootStyles={menuItemStyle}
                        icon={<span className="micon bi bi-dash"></span>}
                      >
                        Target upload
                      </MenuItem>
                    </Link>
                    <Link
                      to="/layout/master/visualaid/VAUpload"
                      className="text-white"
                    >
                      <MenuItem
                        active={location.pathname == "/layout/master/visualaid/VAUpload"}
                        rootStyles={menuItemStyle}
                        icon={<span className="micon bi bi-dash"></span>}
                      >
                        VA Upload
                      </MenuItem>
                    </Link>
                  </>
                )}
                {(role === "BDM" ||
                  role === "ASM" ||
                  role === "RSM" ||
                  role === "ZSM" ||
                  role === "admin") && (
                    <Link
                      to="/layout/mr/birthdayanniversary"
                      className="text-white"
                    >
                      <MenuItem
                        active={location.pathname == "/layout/mr/birthdayanniversary"}
                        rootStyles={menuItemStyle}
                        icon={<span className="micon bi bi-dash"></span>}
                      >
                        Birthday & Anniversary
                      </MenuItem>
                    </Link>
                  )}
              </SubMenu>

              {role === "admin" && (
                <Link to="/layout/SFC" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/SFC"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={ReportImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  SFC
                </MenuItem>
              </Link>
              )}
              

              {/* <Link to="/layout/master/general/CFA" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/master/general/CFA"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={WareHouseImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  WareHouse
                </MenuItem>
              </Link> */}
              <Link to="master/general/Holiday" className="text-white">
                <MenuItem
                  active={location.pathname == "master/general/Holiday"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={HolidayImg}
                      className="menu-icon"
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                >
                  Holidays
                </MenuItem>
              </Link>

              <SubMenu
                active={!openMenu && menuCollaps[0] === "General"}
                onClick={() => ToggleMenu(["General"])}
                label="General"
                rootStyles={menuItemStyle}
                icon={
                  <img
                    src={GeographyImg}
                    className="menu-icon"
                    style={{ height: "23px", width: "23px" }}
                  />
                }
                open={menuCollaps[0] === "General"}
              >
                <Link to="/layout/submaster/creditType" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/submaster/creditType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Credit Type
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/submaster/callObjective"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/submaster/callObjective"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Call Objective
                  </MenuItem>
                </Link>
                <Link to="/layout/submaster/callType" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/submaster/callType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Call Type
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/submaster/priorityType"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/submaster/priorityType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Priority Type
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/submaster/recursiveType"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/submaster/recursiveType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Recursive Type
                  </MenuItem>
                </Link>
                <Link to="/layout/submaster/hqtype" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/submaster/hqtype"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    HQ Type
                  </MenuItem>
                </Link>
                <Link
                  to="/layout/master/visualaid/subcomponent/presentation/Presentation"
                  className="text-white"
                >
                  <MenuItem
                    active={location.pathname == "/layout/master/visualaid/subcomponent/presentation/Presentation"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Presentation
                  </MenuItem>
                </Link>
                <Link to="/layout/master/general/CFA" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/master/general/CFA"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    CFA
                  </MenuItem>
                </Link>
                <SubMenu
                  active={!openMenu && menuCollaps[1] === "Communication"}
                  onClick={() => ToggleMenu(["General", "Communication"])}
                  label="Communication"
                  rootStyles={menuItemStyle}
                  open={
                    menuCollaps[0] === "General" &&
                    menuCollaps[1] === "Communication"
                  }
                >
                  {role === "admin" && (
                    <Link
                      to="/layout/master/Announcement/announcement"
                      className="text-white"
                    >
                      <MenuItem
                        active={location.pathname == "/layout/master/Announcement/announcement"}
                        rootStyles={menuItemStyle}
                        icon={<span className="micon bi bi-dash"></span>}
                      >
                        Announcement
                      </MenuItem>
                    </Link>
                  )}
                  <Link to="/layout/email" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/email"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Email
                    </MenuItem>
                  </Link>
                </SubMenu>
                <SubMenu
                  active={!openMenu && menuCollaps[1] === "Geography"}
                  onClick={() => ToggleMenu(["General", "Geography"])}
                  label="Geography"
                  rootStyles={menuItemStyle}
                  open={
                    menuCollaps[0] === "General" &&
                    menuCollaps[1] === "Geography"
                  }
                >
                  {/* <Link to="Geography" className="text-white">
                    <MenuItem
                      active={active == "Geography"}
                      onClick={() => setActive("Geography")}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Geography
                    </MenuItem>
                  </Link> */}
                  <Link to="/layout/geography/country" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/country"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Country
                    </MenuItem>
                  </Link>
                  <Link to="/layout/geography/zone" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/zone"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Zone
                    </MenuItem>
                  </Link>
                  <Link to="/layout/geography/state" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/state"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      State
                    </MenuItem>
                  </Link>
                  <Link to="/layout/geography/region" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/region"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Region
                    </MenuItem>
                  </Link>
                  <Link to="/layout/geography/district" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/district"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      District
                    </MenuItem>
                  </Link>
                  <Link to="/layout/geography/city" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/city"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      City
                    </MenuItem>
                  </Link>
                  <Link to="/layout/geography/pincode" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/geography/pincode"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Pin Code
                    </MenuItem>
                  </Link>
                  <Link to="/layout/submaster/hq" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/submaster/hq"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      HQ
                    </MenuItem>
                  </Link>
                </SubMenu>
                <SubMenu
                  active={!openMenu && menuCollaps[1] === "Vendor"}
                  onClick={() => ToggleMenu(["General", "Vendor"])}
                  label="Vendor"
                  rootStyles={menuItemStyle}
                  open={
                    menuCollaps[0] === "General" && menuCollaps[1] === "Vendor"
                  }
                >
                  <Link to="/layout/vendor" className="text-white">
                    <MenuItem
                      active={location.pathname == "/layout/vendor"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Vendors
                    </MenuItem>
                  </Link>
                </SubMenu>
              </SubMenu>

              <SubMenu
                active={!openMenu && menuCollaps[0] === "Other"}
                onClick={() => ToggleMenu(["Other"])}
                label="Other"
                rootStyles={menuItemStyle}
                icon={
                  <img
                    src={Other}
                    className="menu-icon"
                    style={{ height: "25px", width: "25px" }}
                  />
                }
                open={menuCollaps[0] === "Other"}
              >
                {/* <Link to="/layout/ProductMap" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Primary Product Map
                  </MenuItem>
                </Link> */}
                <Link to="/layout/SecondaryProductMap" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/SecondaryProductMap"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Secondary Product Map
                  </MenuItem>
                </Link>
                <Link to="/layout/ChemistMap" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/ChemistMap"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Stockist Chemist Map
                  </MenuItem>
                </Link>
                <SubMenu
                  active={!openMenu && menuCollaps[1] === "Primary Vs Secondary"}
                  onClick={() => ToggleMenu(["Other", "Primary Vs Secondary"])}
                  label="Primary Vs Secondary"
                  rootStyles={menuItemStyle}
                  open={
                    menuCollaps[0] === "Other" &&
                    menuCollaps[1] === "Primary Vs Secondary"
                  }
                >
                  <Link
                    to="/layout/report/updatePrimaryData"
                    className="text-white"
                  >
                    <MenuItem
                      active={location.pathname == "/layout/report/updatePrimaryData"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Update Primary Sale
                    </MenuItem>
                  </Link>
                  <Link
                    to="/layout/report/updateSecondaryData"
                    className="text-white"
                  >
                    <MenuItem
                      active={location.pathname == "/layout/report/updateSecondaryData"}
                      rootStyles={menuItemStyle}
                      icon={<span className="micon bi bi-dash"></span>}
                    >
                      Update Secondary Sale
                    </MenuItem>
                  </Link>
                </SubMenu>
              </SubMenu>

              {role === "admin" && (
                <Link to="/layout/attributes" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/attributes"}
                    rootStyles={menuItemStyle}
                    icon={
                      <img
                        src={AttributesImage}
                        className="menu-icon"
                        style={{ height: "22px", width: "22px" }}
                      />
                    }
                  >
                    Attributes
                  </MenuItem>
                </Link>
              )}

              <Link to="/layout/Inventory" className="text-white">
                <MenuItem
                  active={location.pathname == "/layout/Inventory"}
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={AttributesImage}
                      className="menu-icon"
                      style={{ height: "22px", width: "22px" }}
                    />
                  }
                >
                  Inventory
                </MenuItem>
              </Link>




              {/* <SubMenu
                active={!openMenu && menuCollaps[0] === "Inventory"}
                onClick={() => ToggleMenu(["Inventory"])}
                label="Inventory"
                rootStyles={menuItemStyle}
                icon={
                  <img
                    src={ReminderEventImg}
                    className="menu-icon"
                    style={{ height: "20px", width: "20px" }}
                  />
                }
                open={menuCollaps[0] === "Inventory"}
              >
                <Link to="/layout/inventory/company" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/company"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Company
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/currency" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/currency"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Currency
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/customer" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/customer"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Customer
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/hsn" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/hsn"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Hsn
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/hsntype" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/hsntype"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Hsn Type
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/InvoiceType" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/InvoiceType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Invoice Type
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/paymentmethod" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/paymentmethod"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Payment method
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/PaymentType" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/PaymentType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Payment Type
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/warehouse" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/warehouse"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Warehouse
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/vendor" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/vendor"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Vendor
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/ReturnType" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/ReturnType"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Return Type
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/Invoice" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/Invoice"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Invoice
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/GetAccountsInvoice" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/GetAccountsInvoice"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Account Invoice
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/PurchaseInvoice" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/PurchaseInvoice"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Purchase Invoice
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/SalesInvoice" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/SalesInvoice"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Sales Invoice
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/PaymentPurchaseInvoice" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Payment Purchase Invoice
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/PaymentSalesInvoice" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Payment Sales Invoice
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/PaymentReturnInvoice" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Payment Return Invoice
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/PaymentInvoice" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Payment Invoice
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/VendorCreditNote" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/VendorCreditNote"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Vendor Credit Note
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/CustomerCreditNote" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/CustomerCreditNote"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Customer Credit Note
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/SalesReturnInvoice" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/SalesReturnInvoice"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Sales Return Invoice
                  </MenuItem>
                </Link>

                <Link to="/layout/inventory/PurchaseReturnInvoice" className="text-white">
                  <MenuItem
                    active={location.pathname == "/layout/inventory/PurchaseReturnInvoice"}
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Purchase Return Invoice
                  </MenuItem>
                </Link> */}

              {/* <Link to="/layout/inventory/WarehouseStockTransfer" className="text-white">
                  <MenuItem
                    rootStyles={menuItemStyle}
                    icon={<span className="micon bi bi-dash"></span>}
                  >
                    Warehouse Stock Transfer
                  </MenuItem>
                </Link> */}

              {/* </SubMenu> */}
              <Link to="https://lms.aisante.in/" className="text-white" target="_blank">
                <MenuItem
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={GuideImg}
                      className="menu-icon"
                      style={{ height: "25px", width: "25px" }}
                    />
                  }
                >
                  Learning Module
                </MenuItem>
              </Link>
              <Link to="https://hrms.aisante.in/" className="text-white" target="_blank">
                <MenuItem
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={GuideImg}
                      className="menu-icon"
                      style={{ height: "25px", width: "25px" }}
                    />
                  }
                >
                  HRMS
                </MenuItem>
              </Link>
              <Link to="" className="text-white">
                <MenuItem
                  rootStyles={menuItemStyle}
                  icon={
                    <img
                      src={GuideImg}
                      className="menu-icon"
                      style={{ height: "25px", width: "25px" }}
                    />
                  }
                >
                  guide
                </MenuItem>
              </Link>
            </Menu>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
