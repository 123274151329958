import React, { useEffect, useState } from "react";
import { ExpenseRejectModal } from "../../../common/ExpenseRejectModal";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../../_helper/Interceptor";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "../../../common/ErrorMessage";
import ReactSelect from "../../../common/ReactSelect";
import { PaginationServiceByAdminExpense } from "../../../service/PaginationService";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge } from "primereact/badge";
import { Spinner } from "../../../common/Spinner";

export const AdminExpense = () => {
  const [id, setId] = useState(null);
  const controller = new AbortController();
  const [status, setStatus]: any = useState(7);

  const [gridData, setgridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState();

  const apiname = process.env.REACT_APP_EXPENSEAPPROVAL_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  }: any = useForm({ mode: "all" });

  const [selecteduser, setselecteduser]: any = useState(null);
  const [Nodata, setNodata] = useState(false);

  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    paramter: null,
  });
  useEffect(() => {
    tourPlanApprovalChange(7, 0)
  }, [])
  const tourPlanApprovalChange = (statusParams: any, userparams: any) => {
    let Temp: any = {};

    if (statusParams === 0) {
      if (status !== null || status !== undefined) {
        Temp["statusId"] = status;
      }
      else {
        Temp["statusId"] = null;
      }
    }
    else {
      Temp["statusId"] = statusParams;
    }
    if (userparams === 0) {
      if (selecteduser !== null || selecteduser !== undefined) {
        Temp["UserId"] = selecteduser;
      }
      else {
        Temp["UserId"] = null;
      }
    }
    else {
      Temp["UserId"] = userparams;
    }



    console.log(Temp, "temp")


    setlazyState({
      ...lazyState,
      paramter: Temp,
    });



  }
  let networkTimeout: any = null;

  useEffect(() => {
    if (lazyState.paramter !== null) {
      loadLazyData();
    }
    return () => {
      controller.abort();
    }
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceByAdminExpense.getData(lazyState, controller.signal)
        .then((data) => {
          const temp = data.Data.map((e: any, i: any) => {
            // return { ...e, id: i + 1 };
            return {
              ...e,
              id: i + 1,
              srNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          setgridData(temp);
          setTotalRecord(data.TotalDataCount);
          setLoading(false);
          if (temp?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      page: e.page + 1,
      first: e.first,
      rows: e.rows,
    });
  };

  const adminexpenseFormData = (data: any) => {
    console.log(data, "dsdsds");
    setlazyState({
      ...lazyState,
      paramter: data,
    });
  };

  const finalData = {
    ExpenseId: "",
    Status: "Reject",
    Remark: "",
  };

  const handleApprove = (id: any) => {
    setLoading(true);
    const approveData = {
      ExpenseId: id,
      Status: "Approve",
      Remark: "",
    };
    axiosInstance
      .put(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_EXPENSEAPPROVAL_API}/${id}`,
        approveData
      )
      .then((res) => {
        setLoading(false);
        toast.success("Approved Successfull", { position: "top-right" });
        loadLazyData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {e.StatusName == "Approve"
          ? "Approved"
          : e.StatusName == "Reject"
            ? "Rejected"
            : e.StatusName}
        {/* {e.StatusName} */}
      </span>
    );
  };

  const actionFucntion = (e: any) => {
    return e.StatusName == "Pending" ? (
      <>
        <button
          className="btn btn-success btn-sm mr-1"
          onClick={() => {
            handleApprove(e.ExpenseId);
          }}
        >
          Approve
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => setId(e.ExpenseId)}
          type="button"
        >
          Reject
        </button>
      </>
    ) : (
      ""
    );
  };

  let obj = gridData.find((o: any) => o.StatusName === "Pending");

  return (
    <>
      <Helmet>
        <title>Admin Expense</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Expense</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/approvals"}>Approvals</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Expense
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            adminexpenseFormData(data);
          })}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label className="col-form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <select
                  className="custom-select col-12"
                  {...register("statusId", {
                    required: {
                      value: true,
                      message: "Status is required",
                    },
                    onchange,
                  })}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    tourPlanApprovalChange(e.target.value, 0);
                  }}
                >
                  <option value={2}>Approved</option>
                  <option value={3}>Rejected</option>
                  <option value={7} selected >Pending</option>
                </select>
              </div>
              <ErrorMessage message={errors?.statusId?.message} />
            </div>

            <ReactSelect
              containerClass="col-lg-6"
              label="User"
              name={"UserId"}
              onChange={(e: any) => {
                setselecteduser(e);
                setValue("UserId", e);
                tourPlanApprovalChange(0, e);
              }}
              value={selecteduser}
              api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.UserId?.message}
            />

          </div>
        </form>
      </div>

      {gridData.length !== 0 ? (
        <>
          <div className="pd-20 card-box mb-30 table-responsive">
            <DataTable
              value={gridData}
              dataKey="id"
              className="custom-datatable"
              showGridlines
              size="small"
              rowHover
              scrollable
              lazy
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={totalRecord}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onPageChange}
              removableSort
              emptyMessage="No data found."
              tableStyle={{ minWidth: "50rem" }}
              stripedRows
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              loading={loading}
            >
              <Column header="#" field="srNo"></Column>
              {obj ? (
                <Column
                  header="Action"
                  body={(e: any) => actionFucntion(e)}
                  style={{ width: "14%" }}
                ></Column>
              ) : null}
              <Column
                field="EmployeeName"
                bodyClassName={"text-info h6"}
                header="Employee Name"
              ></Column>
              <Column
                header="Expense Date"
                body={(e: any) =>
                // moment(e.ExpenseDate).format("DD MMM, YYYY hh:mm A")
                {
                  if (e.ExpenseDate === null) {
                    return "-";
                  } else {
                    return (

                      <Badge
                        className="m-1"
                        severity={"success"}
                        value={moment(e.ExpenseDate).format("DD MMM, YYYY")}
                      ></Badge>
                      // <Badge
                      //   className="m-1"
                      //   severity={"info"}
                      //   value={moment(e.ExpenseDate).format("hh:mm A")}
                      //   style={{ backgroundColor: "#978EEB" }}
                      // ></Badge>

                    );
                  }
                }
                }
              ></Column>
              <Column
                field="ExpenseTypeName"
                header="Expense Type Name"
              ></Column>
              <Column field="Note" header="Note"></Column>
              <Column field="Amount" header="Amount"></Column>
              <Column
                header="Status"
                body={(e: any) => statusFunction(e)}
              ></Column>
            </DataTable>
          </div>
        </>
      ) : Nodata === true ? (
        <div className="col-md-12 col-sm-12 mb-30">
          <div className="pd-20 card-box text-center">
            <h3 className="text-secondary">No data found.</h3>
          </div>
        </div>
      ) : (
        ""
      )}

      <ExpenseRejectModal
        id={id}
        jsonData={finalData}
        setisOPen={() => setId(null)}
        open={id != null}
        apiname={apiname}
      />
    </>
  );
};
