import { Helmet } from "react-helmet";
import AdminPanelCard from "../common/AdminPanelCard";
import { Link } from "react-router-dom";

const InventoryAttribute = () => {
  let admincard = [
    {
      name: "Hsn Type",
      link: "/layout/inventory/hsntype",
    },
    {
      name: "Invoice Type",
      link: "/layout/inventory/InvoiceType",
    },
    {
      name: "Payment Type",
      link: "/layout/inventory/PaymentType",
    },
    {
      name: "Return Type",
      link: "/layout/inventory/ReturnType",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Inventory Attribute</title>
      </Helmet>
      <div className="d-flex row ml-4 admin-panel">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Inventory Attributes</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/attributes"}>Attributes</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Inventory Attributes
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard name={card?.name} link={card?.link} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InventoryAttribute;
