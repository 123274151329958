import { Link, useParams } from "react-router-dom";
import { useDoctorFetchAllID } from "../../../hooks/Api/Api";
import "../subComponents/doc.css";
import photo from "../../../assets/images/usert.png";
import { Spinner } from "../../../common/Spinner";

const DoctorView = () => {
  const { doctorid } = useParams();
  const apiname = process.env.REACT_APP_GETALLDETAILS_API?.toString();
  const { data, isLoading } = useDoctorFetchAllID(apiname, doctorid);
  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Profile</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {!isLoading ? (
        <>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-30">
              <div className="pd-20 card-box height-100-p">
                <div className="profile-photo">
                  <a
                    href="modal"
                    data-toggle="modal"
                    data-target="#modal"
                    className="edit-avatar"
                  >
                    <i className="fa fa-pencil"></i>
                  </a>
                  <img src={photo} alt="" className="avatar-photo" />
                  <div
                    className="modal fade"
                    id="modal"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body pd-5">
                          <div className="img-container">
                            <img id="image" src={photo} alt="Picture" />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <input
                            type="submit"
                            value="Update"
                            className="btn btn-primary"
                          />
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="text-center h5 mb-0">{data?.DoctorName}</h5>
                <p className="text-center text-muted font-14">
                  {data?.SpecialityList[0].SpecialityName}
                </p>
                <div className="profile-info">
                  <h5 className="mb-20 h3 text-blue">Basic Information</h5>
                  <ul>
                    <li className="d-flex">
                      <span className="mr-2">Category :</span> {data?.CategoryName}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">Hospital Name :</span>
                      {data?.HospitalName}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">Email Address :</span>
                      {data?.Email}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">Website :</span>
                      {data?.WebSite}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">DOB :</span>
                      {data?.DateOfBirth?.split("T")[0]}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">PanCard Number :</span>
                      {data?.PanCardNumber}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">Registration Number :</span>
                      {data?.RegistrationNumber}
                    </li>
                    <li className="d-flex">
                      <span className="mr-2">Role in Hospital :</span>
                      {data?.HospitalRoleName}
                    </li>
                    <li className="d-block">
                      <span className="mr-2 ">Hospital Address :</span>
                      <span className="text-dark"> {data?.HospitalAddress}</span>

                    </li>
                    <li className="d-block">
                      <span className="mr-2">Residential Address :</span>
                      <span className="text-dark"> {data?.ResidencialAddress}</span>

                    </li>
                  </ul>
                </div>
                <div className="profile-skills">
                  <h5 className="mb-20 h3 text-blue">Degree</h5>
                  <div className="d-flex">
                    {data?.DoctorDegreeList?.map((e: any) => {
                      return (
                        <>
                          <span className="badge badge-pill badge-success">
                            {e.DegreeTypeName}
                          </span>
                        </>
                      );
                    })}
                    {/* <span className="badge badge-pill badge-success ml-2">MS</span>
                                <span className="badge badge-pill badge-success ml-2">MD</span>
                                <span className="badge badge-pill badge-success ml-2">BHMS</span>
                                <span className="badge badge-pill badge-success ml-2">BAMS</span> */}
                  </div>
                </div>
                <div className="profile-social">
                  <h5 className="mb-20 h3 text-blue">Achievements</h5>
                  <ul className="list-group">
                    {data?.DoctorAchievementList?.map((e: any) => {
                      return (
                        <>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            {e.AchievementTypeName}
                            <span className="badge badge-primary badge-pill">
                              {e.AchievementDate?.split("T")[0]}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-30">
              <div className="card-box height-100-p overflow-hidden">
                <div className="profile-tab height-100-p mt-30 pl-3">
                  <div className="tab">
                    <div className="row clearfix">
                      <div className="col-md-4 col-sm-12 d-flex">
                        <ul
                          className="nav flex-column vtabs nav-pills "
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue active"
                              data-toggle="tab"
                              href="#social"
                              role="tab"
                              aria-selected="true"
                            >
                              Social Activities
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue"
                              data-toggle="tab"
                              href="#weekendactivity"
                              role="tab"
                              aria-selected="false"
                            >
                              Weekend/Vacation Activity
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#conferences"
                              role="tab"
                              aria-selected="false"
                            >
                              Conferences-Speakers/Participants
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#family"
                              role="tab"
                              aria-selected="false"
                            >
                              Family/Friends
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#guidlines"
                              role="tab"
                              aria-selected="false"
                            >
                              Guidelines Follow
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#collegemate"
                              role="tab"
                              aria-selected="false"
                            >
                              College Mate/Alumni
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#associatedHospitals"
                              role="tab"
                              aria-selected="false"
                            >
                              Associated Hospital
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#referto"
                              role="tab"
                              aria-selected="false"
                            >
                              Patients Refer To
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#refersource"
                              role="tab"
                              aria-selected="false"
                            >
                              Patients Refer Source
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#consultingfees"
                              role="tab"
                              aria-selected="false"
                            >
                              Consulting Fees(Clinic)
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#consultingfeesmr"
                              role="tab"
                              aria-selected="false"
                            >
                              Consulting Fees(MR)
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#medical"
                              role="tab"
                              aria-selected="false"
                            >
                              Medical Advisory/Speaker{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-blue "
                              data-toggle="tab"
                              href="#equipments"
                              role="tab"
                              aria-selected="false"
                            >
                              Procedural Equipments{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-8 col-sm-12">
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="social"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorSocialActivityList?.map((e: any) => {
                                    return (
                                      <>
                                        <div className="profile-edit-list row ">
                                          <div className="col-md-12">
                                            <div className="form-group d-flex flex-wrap">
                                              <label className="col-form-label text-blue mr-2">
                                                Type of Activity :{" "}
                                              </label>
                                              <label className="col-form-label">
                                                {e.TypeOfSocialActivity}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group d-flex flex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Event Partner :{" "}
                                              </label>
                                              <label className="col-form-label">
                                                {
                                                  e.SocialActivityEventPartnerTypeName
                                                }
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group d-flex flex-wrap">
                                              <label className="col-form-label text-blue mr-2">
                                                Sponsers :{" "}
                                              </label>
                                              <label className="col-form-label">
                                                {e.SocialActivitySponsors}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group d-flex flex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Venue :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.SocialActivityVenue}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group d-flex flex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Description :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.SocialActivityDescription}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group d-flex flex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Remarks :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.Remarks}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="weekendactivity"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorWeekendAndVacationActivityList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Entertainment :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.EntertainmentType}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Activity Type :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.WeekendAndVacationActivityType}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Place :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.WeekendAndVacationActivityPlace
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Preferred Date :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.WeekendAndVacationActivityPreferredDateTime?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="conferences"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorConferencesAndWebinarList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Subject Type :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ConferencesAndWebinarSubjectTypeName
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Subject Topic :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ConferencesAndWebinarSubjectTopic
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Days :{" "}
                                                </label>
                                                <label className="col-form-label">
                                                  {e.ConferencesAndWebinarDays}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap flex-wrap">
                                                <label className="col-form-label text-blue mr-2">
                                                  StartDate-EndDate :{" "}
                                                </label>
                                                <label className="col-form-label">
                                                  {
                                                    e.ConferencesAndWebinarStartDate?.split(
                                                      "T"
                                                    )[0]
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    e.ConferencesAndWebinarEndDate?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label mr-2 text-blue">
                                                  Venue :{" "}
                                                </label>
                                                <label className="col-form-label">
                                                  {e.ConferencesAndWebinarVenue}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Travel Amount :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ConferencesAndWebinarTravelAmount
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label text-blue mr-2">
                                                  Travel ArrangedBy :{" "}
                                                </label>
                                                <label className="col-form-label">
                                                  {
                                                    e.ConferencesAndWebinarTravelArrangedBy
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="family"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorFamilyAndFriendDetailsList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Name :{" "}
                                                </label>
                                                <label className="col-form-label">
                                                  {e.FamilyAndFriendName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Relation :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.FamilyAndFriendRelationName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  DOB :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.FamilyAndFriendDateOfBirth?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  DOA :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.FamilyAndFriendDateOfAnniversary?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="guidlines"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorGuidelinesFollowedList.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Guidelines Follow :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.GuidelinesType}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="collegemate"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorColleaguesDetailList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ColleagueCollegeName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  DOB :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ColleagueDateOfBirth?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Speciality :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ColleagueSpeciality}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Hospital Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ColleagueHospitalName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  College Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ColleagueCollegeName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  PassOut Year :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ColleaguePassOutYear}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="associatedHospitals"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorAssociatedHospitalList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Name of Hospital :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.AssociatedHospitalName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group d-flex flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Monthly :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.AssociatedHospitalMonthly}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Weekly :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.AssociatedHospitalWeekly}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Daily :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.AssociatedHospitalDaily}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Address :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.AssociatedHospitalAddress}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="referto"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorPatientReferToList?.map((e: any) => {
                                    return (
                                      <>
                                        <div className="profile-edit-list row">
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Name of Doctor :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.PatientReferToDoctorName}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Speciality :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.PatientReferToDoctorSpecility}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Hospital Name :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.PatientReferToHospitalName}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Priority :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.PatientReferToPriorityType}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Patient Name :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.PatientName}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Refer Date :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.ReferOnDate?.split("T")[0]}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-group fleflex-wrap">
                                              <label className="col-form-label  text-blue mr-2">
                                                Address :{" "}
                                              </label>
                                              <label className="col-form-label ">
                                                {e.PatientReferToHospitalAddress}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="refersource"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorPatientReferencesSourceList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Name of Doctor :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.PatientReferencesSourceDoctorName
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Speciality :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.PatientReferencesSourceDoctorSpecility
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Hospital Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.PatientReferencesSourceHospitalName
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Priority :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.PatientReferencesSourcePriorityType
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Patient Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.PatientName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Refer Date :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ReferOnDate?.split("T")[0]}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Address :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.PatientReferencesSourceHospitalAddress
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="consultingfees"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorConsultingFeesForPatientList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Type :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ConsultingFeesForPatientType}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Amount :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ConsultingFeesForPatienAmount}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  StartDate-EndDate :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ConsultingFeesForPatienStartDate?.split(
                                                      "T"
                                                    )[0]
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    e.ConsultingFeesForPatienEndDate?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Duration :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ConsultingFeesForPatienDuration
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="consultingfeesmr"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorConsultingFeesForMRList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Type :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ConsultingFeesForMrtype}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Amount :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ConsultingFeesForMramount}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Valid UpTo :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ConsultingFeesForMrvalidUpto}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="medical"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorMedicalAdvisoryOrSpeakerList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Purpose :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.MedicalAdvisoryOrSpeakerPurpose
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Amount :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.MedicalAdvisoryOrSpeakerAmount}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Company Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.MedicalAdvisoryOrSpeakerCompanyName
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Business Value :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.MedicalAdvisoryOrSpeakerBussinessValue
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group fleflex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  StartDate-EndDate :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.MedicalAdvisoryOrSpeakerStartDate?.split(
                                                      "T"
                                                    )[0]
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    e.MedicalAdvisoryOrSpeakerEndDate?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Address :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.MedicalAdvisoryOrSpeakerAddress
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="equipments"
                            role="tabpanel"
                          >
                            <div className="docscroller">
                              <div className="profile-setting">
                                <form>
                                  {data?.DoctorProceduralEquipmentList?.map(
                                    (e: any) => {
                                      return (
                                        <>
                                          <div className="profile-edit-list row">
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Name Of equipments :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ProceduralEquipmentName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Vendor Name :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ProceduralEquipmentVendorName}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Installation Date :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {
                                                    e.ProceduralEquipmentInstallationDate?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Price :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.ProceduralEquipmentPrice}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-12">
                                              <div className="form-group flex-wrap">
                                                <label className="col-form-label  text-blue mr-2">
                                                  Remarks :{" "}
                                                </label>
                                                <label className="col-form-label ">
                                                  {e.Remarks}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    }
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}


    </>
  );
};

export default DoctorView;
