import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import ReactSelect from "../../../common/ReactSelect";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Input } from "../../../common/Input";
import axiosInstance from "../../../_helper/Interceptor";

const AddWarehouseStockTransfer = () => {
  const apiname = process.env.REACT_APP_WAREHOUSESTOCKTRANSFER_API?.toString();

  const navigate = useNavigate();

  const [selectedwarehouse, setselectedwarehouse]: any = useState();
  const [selectedToWarehouse, setSelectedToWarehouse]: any = useState();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  }: any = useForm();

  //   Warehouse Transfer Product
  const [warehouseStockTransferArray, setWarehouseStockTransferArray]: any =
    useState([]);
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [EditIndex, setEditIndex]: any = useState(null);

  const {
    register: stockTransferRegister,
    handleSubmit: stockTransferHandleSubmit,
    formState: { errors: stockTransferErrors },
    setValue: stockTransferSetValue,
    control: stockTransferControl,
    reset: stockTransferReset,
    clearErrors: stockTransferClearErrors,
  }: any = useForm();

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname);

  const onWarehouseStockTransferSubmit = (data: any) => {
    if (EditIndex !== null) {
      let updateData = warehouseStockTransferArray.map((e: any, i: any) => {
        data.Id = i + 1;
        return i == EditIndex ? data : e;
      });

      setWarehouseStockTransferArray(updateData);
      setEditIndex(null);
    } else {
      data.Id = warehouseStockTransferArray.length + 1;
      setWarehouseStockTransferArray((prevData: any) => [...prevData, data]);
    }

    stockTransferReset({
      ProductId: setSelectedProduct({}),
      Qty: null,
    });
  };

  const onWarehouseTransferProductsEdit = (index: any) => {
    let item = warehouseStockTransferArray[index - 1];
    stockTransferSetValue("ProductId", item?.ProductId);
    setSelectedProduct(item?.ProductId);
    stockTransferSetValue("Qty", item?.Qty);
    setEditIndex(index - 1);
  };

  const onWarehouseTransferProductsDelete = (index: any) => {
    setWarehouseStockTransferArray(
      warehouseStockTransferArray.filter((e: any) => e.Id != index)
    );
  };

  const stockTransferAction = (id: any) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-secondary btn-sm mr-2"
          onClick={() => onWarehouseTransferProductsEdit(id.Id)}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => onWarehouseTransferProductsDelete(id.Id)}
        >
          Delete
        </button>
      </>
    );
  };

  const onStockTransferSubmit = (data: any) => {
    debugger;
    let submitData = {};

    let dataFirst = { ...data };

    let transferArray = warehouseStockTransferArray.map((e: any) => {
      return { ...e, ProductId: e?.ProductId?.value };
    });

    submitData = {
      WarehouseStockTransferToCreate: dataFirst,
      WarehouseTransferProductsToCreate: transferArray,
    };

    mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/inventory/WarehouseStockTransfer");
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);


  useEffect(() => {
    console.log(selectedProduct, "fdfsd");
    if (selectedProduct !== null) {
      stockTransferSetValue("batchNo", selectedProduct?.value?.batchNo);
    }
  }, [selectedProduct]);

  return (
    <>
      <Helmet>
        <title>Add Warehouse Stock Transfer</title>
      </Helmet>

      <PageHeader
        headTitle="Warehouse Stock Transfer"
        headTitleURL={"/layout/inventory/WarehouseStockTransfer"}
        list={false}
      />

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onStockTransferSubmit)}>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="From Warehouse"
              name={"FromWarehouseId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setselectedwarehouse(e);
                  setValue("FromWarehouseId", e?.value);
                } else {
                  setselectedwarehouse(null);
                  setValue("FromWarehouseId", null);
                }
              }}
              value={selectedwarehouse}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.FromWarehouseId?.message}
              isRequired
              rules={{
                required: {
                  value: true,
                  message: "From Warehouse is required",
                },
              }}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="To Warehouse"
              name={"ToWarehouseId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setSelectedToWarehouse(e);
                  setValue("ToWarehouseId", e?.value);
                } else {
                  setSelectedToWarehouse(null);
                  setValue("ToWarehouseId", null);
                }
              }}
              value={selectedToWarehouse}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ToWarehouseId?.message}
              isRequired
              rules={{
                required: {
                  value: true,
                  message: "To Warehouse is required",
                },
              }}
            />
          </div>

          <Accordion>
            <AccordionTab header={"Warehouse Transfer Products"}>
              <div className="row">
                <div className="col-md-12 text-right">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary mt-1"
                    onClick={stockTransferHandleSubmit(
                      onWarehouseStockTransferSubmit
                    )}
                  >
                    {EditIndex != null ? "Update" : "Add"}
                  </button>
                </div>
              </div>

              <div className="row">
                <ReactSelect
                  containerClass="col-lg-3"
                  control={stockTransferControl}
                  isRequired
                  api={
                    selectedwarehouse != null
                      ? `${process.env.REACT_APP_WAREHOUSESTOCKTRANSFER_API}?warehouseId=${selectedwarehouse?.value}`
                      : ""
                  }
                  onChange={(e: any) => {
                    stockTransferSetValue("ProductId", e?.value);
                    setSelectedProduct(e);
                  }}
                  value={selectedProduct}
                  name="ProductId"
                  label="Product"
                  rules={{
                    required: {
                      value: true,
                      message: "Product is required",
                    },
                  }}
                  errorMessage={stockTransferErrors?.ProductId?.message}
                  Disabled={selectedwarehouse != null ? false : true}
                />

                {/* <Input
                  containerClass="col-lg-3"
                  label="Batch No."
                  type="text"
                  disabled
                  value={selectedProduct?.value?.batchNo}
                /> */}


<Input
                  isRequired
                  containerClass="col-lg-3"
                  label="Batch No."
                  type="text"
                  onChange={(e: any) => {
                    stockTransferSetValue("batchNo", e.target.value);
                    stockTransferClearErrors(["batchNo"]);
                  }}
                  disabled
                  register={{
                    ...stockTransferRegister(`batchNo`, {
                      required: {
                        value: true,
                        message: "batchNo is required",
                      },
                    }),
                  }}
                  errorMessage={stockTransferErrors?.batchNo?.message}
                />

                <Input
                  isRequired
                  containerClass="col-lg-3"
                  label="Qty"
                  type="text"
                  onChange={(e: any) => {
                    stockTransferSetValue("Qty", e.target.value);
                    stockTransferClearErrors(["Qty"]);
                  }}
                  register={{
                    ...stockTransferRegister(`Qty`, {
                      required: {
                        value: true,
                        message: "Qty is required",
                      },
                    }),
                  }}
                  errorMessage={stockTransferErrors?.Qty?.message}
                />

                <Input
                  isRequired
                  containerClass="col-lg-3"
                  label="Sample Qty"
                  type="text"
                  onChange={(e: any) => {
                    stockTransferSetValue("SampleQty", e.target.value);
                    stockTransferClearErrors(["SampleQty"]);
                  }}
                  register={{
                    ...stockTransferRegister(`SampleQty`, {
                      required: {
                        value: true,
                        message: "Sample Qty is required",
                      },
                    }),
                  }}
                  errorMessage={stockTransferErrors?.SampleQty?.message}
                />
              </div>

              {warehouseStockTransferArray?.length > 0 && (
                <>
                  <DataTable
                    dataKey="id"
                    stripedRows
                    value={warehouseStockTransferArray}
                    showGridlines
                    size="small"
                  >
                    <Column
                      field="ProductId"
                      header="Product"
                      body={(e: any) => {
                        let temp = e?.ProductId?.label;
                        return temp;
                      }}
                    />
                    <Column
                      field="Qty"
                      header="Qty"
                      style={{ width: "300px" }}
                    />
                    <Column
                      header="Action"
                      style={{ width: "300px" }}
                      body={stockTransferAction}
                    />
                  </DataTable>
                </>
              )}
            </AccordionTab>
          </Accordion>

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/inventory/WarehouseStockTransfer"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>

            <button
              type="button"
              className="btn btn-outline-danger mt-2 mx-2"
              onClick={() => {
                reset();
              }}
            >
              Reset
            </button>
            {isLoading ? (
              <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                Submit <Spinner width={"1rem"} height={"1rem"} />
              </button>
            ) : (
              <Button type="submit" color="primary" text="Submit"></Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default AddWarehouseStockTransfer;
