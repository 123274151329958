import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactSelect from "../../common/ReactSelect";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import { Spinner } from "../../common/Spinner";
import { toast } from "react-toastify";
import axiosInstance from "../../_helper/Interceptor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

export const UpdateSecondaryData = () => {
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState([]);

  const [expandedRows, setExpandedRows]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  }: any = useForm();

  const [selectedstockist, setselectedstockist]: any = useState(null);

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Stockist Name",
      field: "StockistName",
      width: "8vw",
      body: null,
    },
    {
      header: "Original File Path",
      field: "OriginalFilePath",
      width: "10vw",
      body: (e: any) => originalPathFunction(e),
    },
    {
      header: "Transform File Path",
      field: "TransformFilePath",
      width: "10vw",
      body: (e: any) => transformPathFunction(e),
    },
    {
      header: "Month-Year",
      field: "Month-Year",
      width: "8vw",
      body: (e: any) => monthYearFunction(e),
    },
    {
      header: "Details",
      field: "Details",
      width: "8vw",
      body: (e: any) => detailFunction(e),
    },
  ];

  const subColumns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Division Name",
      field: "DivisionName",
      width: "7vw",
      body: null,
    },
    {
      header: "Product Name",
      field: "ProductName",
      width: "8vw",
      body: null,
    },
    {
      header: "Chemist Name",
      field: "ChemistName",
      width: "10vw",
      body: null,
    },
    {
      header: "Stockist Name",
      field: "StockistName",
      width: "10vw",
      body: null,
    },
    {
      header: "Sell Qty",
      field: "SellStrip",
      width: "4vw",
      body: null,
    },
    {
      header: "Free Qty",
      field: "FreeSrip",
      width: "4vw",
      body: null,
    },
    {
      header: "Expired Qty",
      field: "ExpiredQty",
      width: "4vw",
      body: null,
    },
    {
      header: "Return Qty",
      field: "ReturnQty",
      width: "4vw",
      body: null,
    },
    {
      header: "Created By",
      field: "CreatedBy",
      width: "5vw",
      body: null,
    },
    {
      header: "Created On",
      field: "CreatedOn",
      width: "8vw",
      body: (e: any) => {
        return e.CreatedOn === null
          ? "-"
          : moment(e.CreatedOn).format("DD/MM/YY hh:mm A");
      },
    },
  ];

  const originalPathFunction = (e: any) => {
    return (
      <a
        target="_blank"
        href={`${process.env.REACT_APP_BASEURL_DOWNLOAD}media/UploadExcel/StockistRcpaExcels/Original/${e.OriginalFilePath}`}
      >
        {e.OriginalFilePath}
      </a>
    );
  };

  const transformPathFunction = (e: any) => {
    return (
      <a
        target="_blank"
        href={`${process.env.REACT_APP_BASEURL_DOWNLOAD}media/UploadExcel/StockistRcpaExcels/Transform/${e.TransformFilePath}`}
      >
        {e.TransformFilePath}
      </a>
    );
  };

  const monthYearFunction = (e: any) => {
    console.log(e.Month, e.Year, "===>");
    return `${moment().month(e.Month - 1).format("MMMM")}-${e.Year}`;
  };

  function compareKeys(a: any, b: any) {
    var aKeys = Object.keys(a).sort();
    console.log(aKeys, "aKeys");
    return aKeys[0] == b;
  }

  const [subData, setSubData]: any = useState(null);

  const tempFunction = (rowData: any) => {
    let obj: any = {};
    if (expandedRows != null && compareKeys(expandedRows, rowData.Id)) {
      console.log("true");
      obj = {};
      setSubData(null);
    } else {
      console.log("False");
      obj[rowData.Id] = true;
      getSubGridData(rowData.Id);
    }
    setExpandedRows(obj);
  };

  let [subLoading, setSubLoading] = useState(false);

  const getSubGridData = async (id: any) => {
    setSubLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKISTRCPAGETSTOCKISTRCPA_API}?id=${id}`
      )
      .then((e) => {
        console.log(e.data.Data);
        setSubLoading(false);
        setSubData(e.data.Data);
      })
      .catch((err: any) => {
        console.log(err);
        setSubLoading(false);
      });
  };

  const detailFunction = (e: any) => {
    return (
      <button
        onClick={() => tempFunction(e)}
        className="btn btn-outline-secondary btn-sm"
      >
        View Detail
      </button>
    );
  };

  const onSubmit = async (stockist: any, date: any) => {

    setLoading(true);

    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL
        }Reporting/StockistRcpa/GetSecondarySaleMasterData${stockist !== null ? `?stockistId=${stockist?.value}` : ""
        }${date !== undefined && date !== "" ? `&month=${date}-01` : ""}`, { signal: controller.signal }
      )
      .then((res) => {
        setLoading(false);
        let Temp = res.data.Data.map((d: any, i: any) => {
          return {
            ...d,
            srNo: i + 1
          }
        })
        setGeneratedData(Temp);
      })
      .catch((error) => {
        if (controller.signal.reason.code !== 20) {

          setLoading(false);
          toast.error(error.message, { position: "top-right" });
        }
      });
  };

  const onSubmitData = (data: any) => {
    onSubmit(selectedstockist, data.month);
  };

  useEffect(() => {
    onSubmit(selectedstockist, "");
    return () => {
      controller.abort();
    }
  }, []);

  const rowExpansionTemplate = () => {
    const ErrorData = subData?.stockistRcpaRemarksVerifications.map((d: any, i: any) => {
      return {
        ...d,
        srNo: i + 1
      }
    })
    const SuccessData = subData?.StockistRcpaVerifications.map((d: any, i: any) => {
      return {
        ...d,
        srNo: i + 1
      }
    })
    return (
      <>
        <div className="p-3 text-center">
          <h3 className="mb-3">Error Data</h3>
          <DataTable
            value={ErrorData}
            paginator
            dataKey="Id"
            rows={10}
            showGridlines
            rowHover
            stripedRows
            size="small"
            loading={subLoading}
            rowsPerPageOptions={[10, 20, 50, 100]}
            tableStyle={{ minWidth: "50rem" }}
          >
            {subColumns.map((e: any) => {
              return (
                <Column
                  field={e?.field}
                  header={e?.header}
                  body={e?.body}
                  style={{ minWidth: e?.width }}
                  headerStyle={{ backgroundColor: "rgba(255, 99, 71, 0.4)" }}
                ></Column>
              );
            })}
          </DataTable>
        </div>

        <div className="p-3 text-center">
          <h3 className="mb-3">Success Data</h3>
          <DataTable
            value={SuccessData}
            paginator
            dataKey="Id"
            rows={10}
            showGridlines
            rowHover
            stripedRows
            size="small"
            loading={subLoading}
            rowsPerPageOptions={[10, 20, 50, 100]}
            tableStyle={{ minWidth: "50rem" }}
          >
            {subColumns.map((e: any) => {
              return (
                <Column
                  field={e?.field}
                  header={e?.header}
                  body={e?.body}
                  style={{ minWidth: e?.width }}
                  headerStyle={{ backgroundColor: "palegreen" }}
                ></Column>
              );
            })}
          </DataTable>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Update Secondary Data</title>
      </Helmet>

      <div className="col-md-12 col-sm-12 mb-30">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Update Secondary Data</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/layout/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Update Secondary Data
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((data: any) => {
              onSubmitData(data);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Stockist"
                control={control}
                name="stockistId"
                onChange={(e: any) => {
                  setselectedstockist(e);
                  setValue("stockistId", e?.value);
                }}
                value={selectedstockist}
                api={process.env.REACT_APP_STOCKIST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.stockistId?.message}
              />

              <Input
                containerClass="col-lg-6"
                label="Date"
                type="month"
                errorMessage={errors?.month?.message}
                register={{
                  ...register("month"),
                }}
              />
            </div>
            <div className="col-lg-12 text-center mt-3">
              {!loading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Submit
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </form>
        </div>

        <div className="pd-20 card-box mb-30 text-center">
          <div className="col-md-12 mt-3">
            <DataTable
              value={generatedData}
              paginator
              dataKey="Id"
              rows={10}
              showGridlines
              rowHover
              stripedRows
              size="small"
              rowsPerPageOptions={[10, 20, 50, 100]}
              tableStyle={{ minWidth: "50rem" }}
              expandedRows={expandedRows}
              rowExpansionTemplate={rowExpansionTemplate}
            >
              {columns.map((e: any) => {
                return (
                  <Column
                    field={e?.field}
                    header={e?.header}
                    body={e?.body}
                    style={{ minWidth: e?.width }}
                    headerStyle={{ backgroundColor: "powderblue" }}
                  ></Column>
                );
              })}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};
