import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Input } from "../../../../common/Input";
import ReactSelect from "../../../../common/ReactSelect";
import Textarea from "../../../../common/Textarea";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}

export default function AddEditSocialActivitiesCSRModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  useEffect(() => {
    if (refetchData == true && api == "EventPartnerType") {
      // fetchEventPartnerOptions();
      setselectedeventpartner(null);
      resetstate(false);
    }
  }, [refetchData]);
  const apiname = process.env.REACT_APP_DOCTORSOCIALACTIVITY_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        console.log(data);
        reset(data);
      
        if (data.SocialActivityEventPartnerTypeId !== null) {
          setValue("SocialActivityEventPartnerTypeId", {
            label: data.SocialActivityEventPartnerTypeName,
            value: data.SocialActivityEventPartnerTypeId,
          });
          setselectedeventpartner({
            label: data.SocialActivityEventPartnerTypeName,
            value: data.SocialActivityEventPartnerTypeId,
          });
        } else {
          setValue("SocialActivityEventPartnerTypeId", null);
          setselectedeventpartner(null);
        }

        if (data.SocialActivityCityId !== null) {
          setValue("SocialActivityCityId", {
            label: data.SocialActivityCity,
            value: data.SocialActivityCityId,
          });
          setselectedhcity({
            label: data.SocialActivityCity,
            value: data.SocialActivityCityId,
          });
        } else {
          setValue("SocialActivityCityId", null);
          setselectedhcity(null);
        }

        if (data.SocialActivityPincodeId !== null) {
          setValue("SocialActivityPincodeId", {
            label: data.SocialActivityPincode,
            value: data.SocialActivityPincodeId,
          });
          setselectedhpincode({
            label: data.SocialActivityPincode,
            value: data.SocialActivityPincodeId,
          });
        } else {
          setValue("SocialActivityPincodeId", null);
          setselectedhpincode(null);
        }

      }
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const Hcity = watch("SocialActivityCityId");

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedeventpartner, setselectedeventpartner]: any = useState(null);
  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);

  const areaofinterestFormData = (data: any) => {
    data.DoctorId = Number(doctorid);

    data.SocialActivityEventPartnerTypeId = selectedeventpartner?.value;
    data.SocialActivityCityId = selectedhcity?.value;
    data.SocialActivityPincodeId = selectedhpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Social Activities CSR`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(areaofinterestFormData);
              }
            }}
            onSubmit={handleSubmit(areaofinterestFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Type Of Activity"
                type="text"
                isRequired
                errorMessage={
                  errors?.TypeOfSocialActivity?.message ||
                  error?.response?.data?.errors?.TypeOfSocialActivity?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("TypeOfSocialActivity", e.target.value);
                  clearErrors(["TypeOfSocialActivity"]);
                }}
                register={{
                  ...register("TypeOfSocialActivity", {
                    required: {
                      value: true,
                      message: "Type of Activity is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid type of social activity (2-50 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Event Partner (Trust / NGO / Hospital etc)"
                control={control}
                name="SocialActivityEventPartnerTypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("SocialActivityEventPartnerTypeId", e?.value);
                    setselectedeventpartner(e);
                  } else {
                    setValue("SocialActivityEventPartnerTypeId", null);
                    setselectedeventpartner(null);
                  }
                }}
                value={selectedeventpartner}
                api={process.env.REACT_APP_EVENTPARTNERTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.SocialActivityEventPartnerTypeId?.message ||
                  error?.response?.data?.errors?.SocialActivityEventPartnerTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Event Partner Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="No. of Participants"
                type="text"
                isRequired
                errorMessage={
                  errors?.SocialActivityNoOfParticipants?.message ||
                  error?.response?.data?.errors?.SocialActivityNoOfParticipants?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SocialActivityNoOfParticipants", e.target.value);
                  clearErrors(["SocialActivityNoOfParticipants"]);
                }}
                register={{
                  ...register("SocialActivityNoOfParticipants", {
                    required: {
                      value: true,
                      message: "No. of Participants is required",
                    },
                    pattern: {
                      value: /^\d{1,7}$/,
                      message:
                        "Enter a valid no. of participants (1-7 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Venue"
                type="text"
                isRequired
                errorMessage={
                  errors?.SocialActivityVenue?.message ||
                  error?.response?.data?.errors?.SocialActivityVenue?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SocialActivityVenue", e.target.value);
                  clearErrors(["SocialActivityVenue"]);
                }}
                register={{
                  ...register("SocialActivityVenue", {
                    required: { value: true, message: "Venue is required" },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Venue(2-250 alphanumeric characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Amount Spent (INR)"
                type="text"
                maxLength={7}
                isRequired
                errorMessage={
                  errors?.SocialActivityAmountSpent?.message ||
                  error?.response?.data?.errors?.SocialActivityAmountSpent?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SocialActivityAmountSpent", e.target.value);
                  clearErrors(["SocialActivityAmountSpent"]);
                }}
                register={{
                  ...register("SocialActivityAmountSpent", {
                    required: {
                      value: true,
                      message: "Amount Spent is required",
                    },
                    pattern: {
                      value: /^\d{1,7}$/,
                      message: "Enter a valid amount (1-7 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Sponsors"
                type="text"
                maxLength={50}
                isRequired
                errorMessage={
                  errors?.SocialActivitySponsors?.message ||
                  error?.response?.data?.errors?.SocialActivitySponsors?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SocialActivitySponsors", e.target.value);
                  clearErrors(["SocialActivitySponsors"]);
                }}
                register={{
                  ...register("SocialActivitySponsors", {
                    required: {
                      value: true,
                      message: "Sponsors is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z0-9\s']{1,50}$/,
                      message:
                        "Enter a valid sponsors(2-50 alphabet characters)",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                maxLength={500}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                register={{
                  ...register("Remarks"),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Description"
                isRequired
                errorMessage={
                  errors?.SocialActivityDescription?.message ||
                  error?.response?.data?.errors?.SocialActivityDescription?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("SocialActivityDescription", e.target.value);
                  clearErrors(["SocialActivityDescription"]);
                }}
                register={{
                  ...register("SocialActivityDescription", {
                    required: {
                      value: true,
                      message: "Description is required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid discription (2-250 characters)",
                    },
                  }),
                }}
              />

              <Address
                control={control}
                header="Address"
                address1register={{
                  ...register("SocialActivityAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("SocialActivityAddressLine1", e.target.value);
                  clearErrors(["SocialActivityAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.SocialActivityAddressLine1?.message ||
                  error?.response?.data?.errors?.SocialActivityAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("SocialActivityAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("SocialActivityAddressLine2", e.target.value);
                  clearErrors(["SocialActivityAddressLine2"]);
                }}
                address2errorMessage={
                  errors?.SocialActivityAddressLine2?.message ||
                  error?.response?.data?.errors?.SocialActivityAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("SocialActivityCityId", e?.value);
                    setselectedhcity(e);
                    setselectedhpincode({});
                  } else {
                    setValue("SocialActivityCityId", null);
                    setValue("SocialActivityPincodeId", null);
                    setselectedhcity(null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.SocialActivityCityId?.message ||
                  error?.response?.data?.errors?.SocialActivityCityId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("SocialActivityPincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("SocialActivityPincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.SocialActivityPincodeId?.message ||
                  error?.response?.data?.errors?.SocialActivityPincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedhcity}
                selectedPincode={selectedhpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"SocialActivityCityId"}
                pincodeApi={
                  selectedhcity != null ?
                  `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}`
                  :""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"SocialActivityPincodeId"}
                pincodeDisable={ selectedhcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedeventpartner(null);
                      setselectedhcity(null);
                      setselectedhpincode(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
