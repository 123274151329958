import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import axiosInstance from "../../../_helper/Interceptor";
import { fetchPincodeByState } from "../../../hooks/master/geography/useGeography";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/Input";
import { Button } from "../../../common/Button";
import ReactSelect from "../../../common/ReactSelect";
import { Helmet } from "react-helmet";
import { Alert } from "../../../common/Alert";
import { Spinner } from "../../../common/Spinner";
import { PageHeader } from "../../../common/PageHeader";

export const AddEditCFA = () => {
  const apiname = process.env.REACT_APP_CFA_API?.toString();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading: CFALoader }: any = useFetchAllID(apiname, id);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  }: any = useForm();

  const formd = watch("StateId");
  useEffect(() => {
    if (data) {
      reset(data);
      setselectedstate({ label: data.State, value: data.StateId });
      setselectedpincode({ label: data.PinCode, value: data.PinCodeId });
    }
  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );

  const [statecodeOptions, setstatecodeOptions] = useState([]);
  const [selectedstate, setselectedstate] = useState({});
  const fetchState = async () => {
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STATE_API}`)
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.StateName,
            value: option?.StateId,
          };
        });
        setstatecodeOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };
  useEffect(() => {
    fetchState();
  }, []);

  const [pincodeOptions, setpincodeOptions] = useState([]);
  const [selectedpincode, setselectedpincode] = useState({});
  const fetchingpincodes = async () => {
    if (formd) {
      await fetchPincodeByState(formd)
        .then((res) => {
          const data = res.map((option: any) => {
            return {
              label: option.PincodeName,
              value: option.PincodeId,
            };
          });
          setpincodeOptions(data);
        })
        .catch((error) => {
          toast.error(`${error.message}`, { position: "top-right" });
        });
    }
  };
  useEffect(() => {
    fetchingpincodes();
    if (data) {
      if (data?.StateId !== formd) {
        setValue("PinCodeId", {});
      }
    }
  }, [formd]);

  const cfaFormData = (data: any) => {
    mutate(data);
  };

  // Success & Error Messages
  const [enotify, setEnotify] = useState(false);
  const [color, setColor] = useState("");
  if (id === undefined) {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/master/general/CFA");
    }
  } else {
    if (isSuccess) {
      toast.success("Updated Successfully", { position: "top-right" });
      navigate("/layout/master/general/CFA");
    }
  }
  useEffect(() => {
    if (isError) {
      setColor("danger");
      setEnotify(true);
      setTimeout(() => {
        setEnotify(false);
      }, 3000);
    }
  }, [isError]);

  return (
    <>
      <Helmet>
        <title>{id === undefined ? "Add" : "Edit"} CFA</title>
      </Helmet>

      <PageHeader
        headTitle="CFA"
        headTitleURL={"/layout/master/general/CFA"}
        list={false}
        id={id}
      />

      {!CFALoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((cfainfo: any) => {
              cfaFormData(cfainfo);
            })}
          >
            {enotify && (
              <Alert color={color} message={error?.response?.data?.Message} />
            )}
            <div className="row">
              <Input
                containerClass="col-lg-3"
                label="Name"
                type="text"
                isRequired
                register={{
                  ...register("Name", {
                    required: { value: true, message: "Name is required" },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z\s']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                  }),
                }}
                // maxLength={30}
                errorMessage={errors?.Name?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Code"
                isRequired
                type="text"
                register={{
                  ...register("Code", {
                    required: { value: true, message: "Code is required" },
                    // pattern: {
                    //   value: /^(?!\s)\d+(?!\s)$/,
                    //   message:
                    //     "Enter only digits and required 5 digits or Remove spaces from front",
                    // },
                  }),
                }}
                // minLength={5}
                // maxLength={5}
                errorMessage={errors?.Code?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Place"
                isRequired
                type="text"
                register={{
                  ...register("Place", {
                    required: { value: true, message: "Place is required" },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z\s']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                  }),
                }}
                // maxLength={30}
                errorMessage={errors?.Place?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Contact Person"
                isRequired
                type="text"
                register={{
                  ...register("ContactPerson", {
                    required: {
                      value: true,
                      message: "Contact Person is required",
                    },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z\s']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                  }),
                }}
                // maxLength={30}
                errorMessage={errors?.ContactPerson?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Address1"
                isRequired
                type="text"
                register={{
                  ...register("Address1", {
                    required: { value: true, message: "Address1 is required" },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z0-9\s,\-']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                  }),
                }}
                // maxLength={250}
                errorMessage={errors?.Address1?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Address2"
                isRequired
                type="text"
                register={{
                  ...register("Address2", {
                    required: { value: true, message: "Address2 is required" },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z0-9\s,\-']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                  }),
                }}
                // maxLength={250}
                errorMessage={errors?.Address2?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Address3"
                isRequired
                type="text"
                register={{
                  ...register("Address3", {
                    required: { value: true, message: "Address3 is required" },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z0-9\s,\-']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                  }),
                }}
                // maxLength={250}
                errorMessage={errors?.Address3?.message}
              />

              <ReactSelect
                containerClass="col-lg-3"
                label="State"
                isRequired
                name={"StateId"}
                onChange={(e: any) => {
                  setValue("StateId", e?.value);
                  setselectedstate(e);
                  setselectedpincode({});
                }}
                value={selectedstate}
                control={control}
                options={statecodeOptions}
                errorMessage={errors?.StateId?.message}
                rules={{
                  required: { value: true, message: "State is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-3"
                label="Pincode"
                isRequired
                name={"PinCodeId"}
                onChange={(e: any) => {
                  setValue("PinCodeId", e?.value);
                  setselectedpincode(e);
                }}
                value={selectedpincode}
                control={control}
                options={pincodeOptions}
                errorMessage={errors?.PinCodeId?.message}
                rules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Mobile No."
                isRequired
                type="text"
                register={{
                  ...register("MobileNo", {
                    required: {
                      value: true,
                      message: "Mobile No. is required",
                    },
                    // pattern: {
                    //   value: /^(?!\s)\d+(?!\s)$/,
                    //   message:
                    //     "Enter only digits and required 10 digits or Remove spaces from front",
                    // },
                  }),
                }}
                // maxLength={10}
                // minLength={10}
                errorMessage={errors?.MobileNo?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Phone"
                isRequired
                type="text"
                register={{
                  ...register("Phone", {
                    required: { value: true, message: "Phone is required" },
                    // pattern: {
                    //   value: /^(?!\s)\d+(?!\s)$/,
                    //   message:
                    //     "Enter only digits and required 10 digits or Remove spaces from front",
                    // },
                  }),
                }}
                // maxLength={10}
                // minLength={10}
                errorMessage={errors?.Phone?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Email"
                isRequired
                type="email"
                register={{
                  ...register("Email", {
                    required: { value: true, message: "Email is required" },
                    // pattern: {
                    //   value:
                    //     /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                    //   message: "Email format is Invalid..",
                    // },
                  }),
                }}
                // maxLength={50}
                errorMessage={errors?.Email?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="DLNo1"
                isRequired
                type="text"
                register={{
                  ...register("DLno1", {
                    required: { value: true, message: "DLNo1 is required" },
                    // pattern: {
                    //   value: /^(?!\s)\d{4}\s\d{11}$/,
                    //   message:
                    //     "Enter only digits, required 16 digits and Space after 4 digits",
                    // },
                  }),
                }}
                // maxLength={16}
                // minLength={16}
                errorMessage={errors?.DLno1?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="DLNo2"
                isRequired
                type="text"
                register={{
                  ...register("DLno2", {
                    required: { value: true, message: "DLNo2 is required" },
                    // pattern: {
                    //   value: /^(?!\s)\d{4}\s\d{11}$/,
                    //   message:
                    //     "Enter only digits, required 16 digits and Space after 4 digits",
                    // },
                  }),
                }}
                // maxLength={16}
                // minLength={16}
                errorMessage={errors?.DLno2?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="GST"
                isRequired
                type="text"
                register={{
                  ...register("Gst", {
                    required: { value: true, message: "GST is required" },
                    // pattern: {
                    //   value: /^(?!\s)[A-Z0-9]+(?!\s)$/,
                    //   message:
                    //     "Enter capital characters and required 15 characters or digits only and should be 15 characters",
                    // },
                  }),
                }}
                // maxLength={15}
                // minLength={15}
                errorMessage={errors?.Gst?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="FSSAI"
                isRequired
                type="text"
                register={{
                  ...register("Fssai", {
                    required: { value: true, message: "FSSAI is required" },
                    // pattern: {
                    //   value: /^(?!\s)[0-9]+(?!\s)$/,
                    //   message:
                    //     "Enter digits only, required 14 digits and should be 14 characters",
                    // },
                  }),
                }}
                // maxLength={14}
                // minLength={14}
                errorMessage={errors?.Fssai?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="NRX"
                isRequired
                type="text"
                register={{
                  ...register("NRX", {
                    required: { value: true, message: "NRX is required" },
                  }),
                }}
                errorMessage={errors?.NRX?.message}
              />

              <Input
                containerClass="col-lg-3"
                label="Other"
                isRequired
                type="text"
                register={{
                  ...register("Other", {
                    required: { value: true, message: "Other is required" },
                  }),
                }}
                errorMessage={errors?.Other?.message}
              />

              <div className="col-lg-12 text-center mt-3">
                <Link
                  to={"/layout/master/general/CFA"}
                  className="btn btn btn-outline-secondary mt-2 mx-2"
                >
                  {" "}
                  Back{" "}
                </Link>
                {id === undefined ? (
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-2 mx-2"
                    onClick={() => reset()}
                  >
                    {" "}
                    Reset{" "}
                  </button>
                ) : (
                  ""
                )}
                {isLoading ? (
                  <button
                    className="btn btn-outline-primary mt-2 mx-2"
                    disabled
                  >
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                ) : (
                  <Button type="submit" color="primary" text="Submit"></Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};
