import React, { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { DeleteModal } from "../../../common/DeleteModal";
import { AddEditEmployeeDeviceMap } from "./AddEditEmployeeDeviceMap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { toast } from "react-toastify";
import { PaginationServiceOfEmployeeDeviceMap } from "../../../service/PaginationService";
import { NullHandler } from "../../../common/NullHandler";
import { Badge } from "primereact/badge";
import moment from "moment";

export const EmployeeDeviceMap = () => {
  const apiname = process.env.REACT_APP_EMPLOYEEDEVICEMAPPING_API?.toString();
  const [bool, setbool]: any = useState(null);
  const controller = new AbortController();
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [Nodata, setNodata] = useState(false);
  const [totalRecord, setTotalRecords] = useState(0);
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    search: "",
    sortField: null,
    sortOrder: null,
  });

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfEmployeeDeviceMap.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
          if (temp?.length === 0) {
            setNodata(true);
          } else {
            setNodata(false);
          }
        })
        .catch((error: any) => {
          if (controller.signal.reason.code !== 20) {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setExpandedRows({});
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8 mt-1">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const [updateId, setupdateId]: any = useState(null);

  const actionEmployeeDeviceTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.EmployeeId}
          editModal={() => {
            setupdateId(id.EmployeeId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  const allowExpansion = (rowData: any) => {
    return rowData?.EmployeeDevices?.length > 0;
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <h6 className="mb-3">Employee Devices</h6>
        <DataTable
          scrollable
          showGridlines
          value={data?.EmployeeDevices}
          size="small"
          className="custom-datatable"
          stripedRows
          dataKey="DeviceDetailId"
        >
          <Column
            field="Brand"
            header="Brand"
            body={(data: any) => {
              return NullHandler(data?.Brand);
            }}
          ></Column>
          <Column field="DeviceType" header="Device Type"></Column>
          <Column
            field="Iemino"
            header="IEMI No"
            body={(data: any) => {
              return NullHandler(data?.Iemino);
            }}
          ></Column>
          <Column field="Model" header="Model"></Column>
          <Column field="Storage" header="Storage"></Column>
        </DataTable>
      </div>
    );
  };
 
  useEffect(() => {
    if (bool) {
      loadLazyData();
    }
  }, [bool]);

  return (
    <>
      <Helmet>
        <title>Employee Device Map</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Employee Device Map"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/mapping"}
          linkflagtitle={"Mapping"}
        />

        <div className="pd-20 card-box mb-30">
          <>
            <div className="p-0">
              <DataTable
                scrollable
                showGridlines
                value={totalData}
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                className="custom-datatable"
                stripedRows
                expandedRows={expandedRows}
                dataKey="EmployeeId"
                onRowToggle={(e) => {
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                {totalData?.some(allowExpansion) && (
                  <Column
                    expander={allowExpansion}
                    style={{ width: "2%", textAlign: "center" }}
                  />
                )}
                <Column
                  field="Action"
                  header="Action"
                  body={(e: any) => actionEmployeeDeviceTemplate(e)}
                  style={{ width: "10%" }}
                ></Column>
                <Column
                  field="EmployeeName"
                  header="Employee"
                  style={{ width: "90%" }}
                ></Column>
              </DataTable>
            </div>
          </>
        </div>

        {updateId !== null && (
          <AddEditEmployeeDeviceMap
            setisOPen={() => {
              return setupdateId(null);
            }}
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
