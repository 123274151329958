import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import axiosInstance from "../../../_helper/Interceptor";
import { Spinner } from "../../../common/Spinner";
import { LoaderContext } from "../../../App";

export const VisitUpload = () => {
  const fullScreenLoader = useContext(LoaderContext);
  const apiname = process.env.REACT_APP_DAILYTOURPLANBULKUPLOAD_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  }: any = useForm({ mode: "all" });


  const {
    mutate,
    isSuccess,
    isError,
    error,

    isLoading: uploadLoading,
  }: any = useCreateByID(apiname);

  const [isLoading, setIsLoading] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownloadblank = async (data: any) => {
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DAILYTOURPLANGETDOWNLOADBLANKDAILYTOURPLANEXCEL_API}?Flag=${data.Flag}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(
          response.data,
          data.Flag === "DoctorVisit"
            ? `DoctorvisitWithoutData.xlsx`
            : data.Flag === "ChemistVisit"
              ? "ChemistvisitWithoutData.xlsx"
              : data.Flag === "HospitalVisit"
                ? "HospitalvisitWithoutData.xlsx"
                : ""
        );
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const handleUpload = async (data: any) => {
    debugger
    if (data.VisitUploadChemistExcel == undefined || data?.VisitUploadChemistExcel?.length < 1) {
      // setError("VisitUploadChemistExcel")
      setError('VisitUploadChemistExcel', { type: 'custom', message: 'Please Select File' });
    }
    else {

      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {

        const formvalue: any = value;
        if (typeof value !== "object") {
          formData.append(key, formvalue);
        } else {
          formData.append(key, formvalue[0]);
        }
      }
      mutate(formData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(`File Uploaded Successfull`, { position: "top-right" });
      reset();
    }
    if (isError) {
      toast.error(`${error?.response?.data?.Message}`, {
        position: "top-right",
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {

    if (uploadLoading) {
      fullScreenLoader.setloader(true)
    }
    else {
      fullScreenLoader.setloader(false)
    }
  }, [uploadLoading])
  return (
    <>
      <Helmet>
        <title>Visit Upload</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Visit Upload</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Visit Upload
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Download</h5>
        </div>
        <form>
          <div className="row">
            <div className="col-lg-4">
              <div className="custom-control custom-radio mb-5">
                <input
                  type="radio"
                  id="DoctorVisit"
                  {...register("Flag", {
                    required: {
                      value: true,
                      message: "Select Any One from Above"
                    }
                  })}
                  value={"DoctorVisit"}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="DoctorVisit">
                  Doctor Visit
                </label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="custom-control custom-radio mb-5">
                <input
                  type="radio"
                  id="ChemistVisit"
                  {...register("Flag", {
                    required: {
                      value: true,
                      message: "Select Any One from Above"
                    }
                  })}
                  value={"ChemistVisit"}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="ChemistVisit">
                  Chemist Visit
                </label>
              </div>
              <ErrorMessage

                message={errors?.Flag?.message}
              />
            </div>
            <div className="col-lg-4">
              <div className="custom-control custom-radio mb-5">
                <input
                  type="radio"
                  id="HospitalVisit"
                  {...register("Flag", {
                    required: {
                      value: true,
                      message: "Select Any One from Above"
                    }
                  })}
                  value={"HospitalVisit"}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="HospitalVisit">
                  Hospital
                </label>
              </div>
            </div>




            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">Select File</label>
                <input
                  {...register("VisitUploadChemistExcel")}
                  type="file"
                  className="form-control"
                />
                <ErrorMessage
                  message={errors?.VisitUploadChemistExcel?.message}
                />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button
                  className="btn btn-outline-secondary mx-2"
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    handleDownloadblank(data);
                  })}
                >
                  Download
                </button>
              ) : (
                <button
                  className="btn btn-outline-secondary mx-2"
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    handleDownloadblank(data);
                  })}
                  disabled
                >
                  Download <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}

              {!uploadLoading ? (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    handleUpload(data);
                  })}
                >
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={handleSubmit((data: any) => {
                    handleUpload(data);
                  })}
                  disabled
                >
                  Uploading...
                  {/* <Spinner width={"1rem"} height={"1rem"} /> */}
                </button>
              )}
            </div>

          </div>

        </form>
      </div>
    </>
  );
};
