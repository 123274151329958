import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useCreateByID } from "../../../hooks/Api/Api";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { Spinner } from "../../../common/Spinner";

export const PrimarySale = () => {
  const apiname = process.env.REACT_APP_PRIMARYSALE_API?.toString();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  }: any = useForm({ mode: "all" });
  const {
    register: uploadRegister,
    handleSubmit: uploadSubmit,
    formState: { errors: uploadErrors },
    reset: uploadReset,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: primaryLoading,
  }: any = useCreateByID(apiname);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const [selecteddivision, setselecteddivision] = useState({});

  const [selectedzone, setselectedzone] = useState({});

  const handleDownloadBlank = async () => {
    setIsLoad(true);
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRIMARYSALESDOWNLOADBLANK_API}`, {
        responseType: "blob",
      })
      .then((response) => {
        saveFile(response.data, "PrimarySalesDocument.xlsx");
        setIsLoad(false);
        toast.success(`Download Blank Successfull`, { position: "top-right" });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoad(false);
      });
  };

  const handleDownload = async (data: any) => {

    console.log(data.DivId, "dsd");
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRIMARYSALESDOWNLOADWITHDATA_API}?divisionId=${data.DivId[0].value}&zoneId=${data.ZoneId}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "PrimarySalesWithData.xlsx");
        reset();
        setIsLoading(false);
        toast.success(`Download With Data Successfull`, {
          position: "top-right",
        });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const handleUpload = (data: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(`File Uploaded Successfull`, { position: "top-right" });
      uploadReset();
    }
    if (isError) {
      toast.error(`${error?.response?.data?.Message}`, {
        position: "top-right",
      });
    }
  }, [isSuccess, isError]);

  const [selectedOptions, setSelectedOptions]: any = useState([]);
  return (
    <>
      <Helmet>
        <title>Primary Sales</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Primary Sales</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Primary Sales
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6">
            {!isLoad ? (
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={handleDownloadBlank}
              >
                Download Blank
              </button>
            ) : (
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={handleDownloadBlank}
                disabled
              >
                Download Blank <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Download Excel</h5>
        </div>

        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="Division"
              isRequired

              name={"DivId"}
              onChange={(e: any) => {
                setselecteddivision(e);
                setValue("DivId", e?.value);
              }}
              value={selecteddivision}
              control={control}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.DivId?.message}
              rules={{
                required: { value: true, message: "Division is required" },
              }}
            />

            {/* <CheckboxReactSelect
              containerClass="col-lg-6"
              label="Division"
              control={control}
              isRequired
              name={"DivId"}
              options={divisionOptions}
              multi
              value={selectedOptions}
              onInputChange={(e:any)=>setInputValue(e)}
              onChange={(e: any) => {
                setValue("DivId", e);
                setSelectedOptions(e);
              }}
              errorMessage={errors?.DivId?.message}
              rules={{
                required: { value: true, message: "Division is required" },
              }}
            /> */}

            <ReactSelect
              containerClass="col-lg-6"
              label="Zone"
              isRequired

              name={"ZoneId"}
              onChange={(e: any) => {
                setselectedzone(e);
                setValue("ZoneId", e?.value);
              }}
              value={selectedzone}
              control={control}
              api={process.env.REACT_APP_ZONE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ZoneId?.message}
              rules={{
                required: { value: true, message: "Zone is required" },
              }}
            />

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button className="btn btn-outline-primary mx-2" type="submit">
                  Download
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  disabled
                >
                  Download <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Upload Excel</h5>
        </div>
        <form
          onSubmit={uploadSubmit((data: any) => {
            handleUpload(data);
          })}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Select File <span className="text-danger">*</span>
                </label>
                <input
                  {...uploadRegister("PrimarySaleExcel", {
                    required: {
                      value: true,
                      message: "Please Upload Excel",
                    },
                  })}
                  type="file"
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <ErrorMessage
                  message={uploadErrors?.PrimarySaleExcel?.message}
                />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!primaryLoading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Upload <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
