import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useCreateByID, useFetchAllID } from '../../../hooks/Api/Api';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import { Spinner } from '../../../common/Spinner';
import { Dialog } from 'primereact/dialog';
import ReactSelect from '../../../common/ReactSelect';

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditRouteEmployeeMapping = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {

  const apiname = process.env.REACT_APP_ROUTEEMPLOYEEMAPPING_API?.toString();

  const [selectedroute, setselectedroute] = useState({});

  const [selectedemployee, setselectedemployee] = useState({});

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      reset(data);
      setselectedroute({
        label: data.RouteName,
        value: data.RouteId,
      });
      setselectedemployee({
        label: data.EmployeeName,
        value: data.EmployeeId,
      });
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const employeerouteFormData = (data: any) => {
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, {
          position: "top-right",
        });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);


  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Employee Route Map`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form onSubmit={handleSubmit(employeerouteFormData)}>
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label="Employee"
                name={"EmployeeId"}
                isRequired
                onChange={(e: any) => {
                  setselectedemployee(e);
                  setValue("EmployeeId", e?.value);
                }}
                value={selectedemployee}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Employee is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label="Route"
                name={"RouteId"}
                isRequired

                onChange={(e: any) => {
                  setselectedroute(e);
                  setValue("RouteId", e?.value);
                }}
                value={selectedroute}
                control={control}
                api={process.env.REACT_APP_SRC_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.RouteId?.message ||
                  error?.response?.data?.errors?.RouteId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Route is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setselectedroute({}); setselectedemployee({}) }} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  )
}
