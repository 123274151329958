import { useEffect, useState } from "react";
import axiosInstance from "../../../_helper/Interceptor";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../../common/Button";
import { Helmet } from "react-helmet";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Alert } from "../../../common/Alert";
import { Spinner } from "../../../common/Spinner";
import { PageHeader } from "../../../common/PageHeader";

export default function AddEditSpeciality() {
  const apiname = process.env.REACT_APP_SPECIALITY_API?.toString();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading: specialityLoader }: any = useFetchAllID(apiname, id);
  useEffect(() => {
    if (data) {
      console.log(data, "data");
      reset(data);
      data.specialityQulificationModel = data?.specialityQulificationModel?.map(
        (d: any) => ({
          label: d?.QualificationName,
          value: d?.QualificationId,
        })
      );
      setselectedqualification(data?.specialityQulificationModel);
      setValue("Qualifications", "0");
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  }: any = useForm({ mode: "all" });
  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id
  );

  const onChange = (e: any) => {
    console.log(e.isActive);
    return true;
  };

  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedqualification, setselectedqualification] = useState([]);
  const fetchQualificationOptions = async () => {
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_QUALIFICATION_API}`)
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.QualificationName,
            value: option?.QualificationId,
          };
        });
        setQualificationOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    fetchQualificationOptions();
  }, []);

  const specialityFormData = (data: any) => {
    data.Qualifications = selectedqualification
      ?.map((e: any) => {
        return e?.value;
      })
      .toString();
    data.Qualifications = String(data?.Qualifications);
    mutate(data);
  };

  const [enotify, setEnotify] = useState(false);
  const [color, setColor] = useState("");
  if (id === undefined) {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/master/doctor/Speciality");
    }
  } else {
    if (isSuccess) {
      toast.success("Updated Successfully", { position: "top-right" });
      navigate("/layout/master/doctor/Speciality");
    }
  }

  useEffect(() => {
    if (isError) {
      setColor("danger");
      setEnotify(true);
      setTimeout(() => {
        setEnotify(false);
      }, 3000);
    }
  }, [isError]);

  return (
    <>
      <Helmet>
        <title>{id == undefined ? "Add" : "Edit"} Speciality</title>
      </Helmet>
      <PageHeader
        headTitle="Speciality"
        headTitleURL={"/layout/master/doctor/Speciality"}
        list={false}
        id={id}
      />

      {!specialityLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((specialityinfo: any) => {
              specialityFormData(specialityinfo);
            })}
          >
            {enotify && (
              <Alert color={color} message={error?.response?.data?.Message} />
            )}
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Speciality Name"
                isRequired
                type="text"
                register={{
                  ...register("SpecialityName", {
                    required: {
                      value: true,
                      message: "Speciality is required",
                    },
                    // pattern: {
                    //   value: /^(?!\s)\w[a-zA-Z\s']+(?!\s)$/,
                    //   message: "Enter only letters and Remove whitespace from both sides of a string",
                    // },
                    onChange,
                  }),
                }}
                // maxLength={30}
                errorMessage={errors?.SpecialityName?.message}
              />

              <Input
                containerClass="col-lg-6"
                label="Remarks"
                isRequired
                type="text"
                register={{
                  ...register("Remarks", {
                    required: { value: true, message: "Remarks is required" },
                    // pattern: {
                    //   value:
                    //     /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                    //   message:
                    //     "Enter Valid Remarks and Remove whitespace from both sides of a string",
                    // },
                    onChange,
                  }),
                }}
                // maxLength={30}
                errorMessage={errors?.Remarks?.message}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label={"Map Qualifications"}
                isRequired
                name={"Qualifications"}
                onChange={(e: any) => {
                  setValue("Qualifications", e[0]?.value);
                  setselectedqualification(e);
                }}
                value={selectedqualification}
                control={control}
                options={qualificationOptions}
                errorMessage={errors?.Qualifications?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Qualifications is required",
                  },
                }}
                Multi
              />

              <div className="col-lg-12 text-center">
                <Link
                  to={"/layout/master/doctor/Speciality"}
                  className="btn btn btn-outline-secondary mt-2 mx-2"
                >
                  {" "}
                  Back{" "}
                </Link>
                {id === undefined ? (
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-2 mx-2"
                    onClick={() => reset()}
                  >
                    {" "}
                    Reset{" "}
                  </button>
                ) : (
                  ""
                )}
                {isLoading ? (
                  <button
                    className="btn btn-outline-primary mt-2 mx-2"
                    disabled
                  >
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                ) : (
                  <Button type="submit" color="primary" text="Submit"></Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
}
