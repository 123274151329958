import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import Textarea from "../../common/Textarea";
import { Spinner } from "../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import cameraLogo from "../../assets/images/Camera.png";
import { ErrorMessage } from "../../common/ErrorMessage";
interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export default function AddEditProduct({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const [QRImageDisplay, setQrImageDisplay]: any = useState(cameraLogo);
  const apiname = process.env.REACT_APP_PRODUCT_API?.toString();
  const [QRImage, setQRImage]: any = useState();
  const { data, isLoading: productLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (id) {
      reset(data);
      if (data) {
        setQrImageDisplay(data.QRCodePath);
        console.log(data)
        setGST(data.GST);
        setMRP(data.Mrp);
        setselectedPTRPTSSettingOptions({
          label: data.PTrptssettingName,
          value: {
            MedicalMarginNumber: data.MedicalMarginNumber,
            AgeneyMargin: data.AgeneyMargin,
            PTrptssettingId: data.PTrptssettingId,
          },
        });

        if (data.DrugHeadId !== null && data.DrugHeadId !== undefined) {
          setValue("DrugHeadId", {
            label: data.DrugHead, value: data.DrugHeadId
          });
          setselectedDrugHead({
            label: data.DrugHead, value: data.DrugHeadId
          });
          setValue("DrugHeadId", data.DrugHeadId);
        } else {
          setValue("DrugHeadId", null);
          setselectedDrugHead(null);
        }

        if (data.ProductLineId !== null && data.ProductLineId !== undefined) {
          setValue("ProductLineId", data.ProductLineId);
          setselectedProductLine({
            label: data.ProductLine,
            value: data.ProductLineId,
          });
        } else {
          setValue("ProductLineId", null);
          setselectedProductLine(null);
        }

        if (data.ProductClassId !== null && data.ProductClassId !== undefined) {
          setValue("ProductClassId", data.ProductClassId);
          setselectedproductClass({
            label: data.ProductClass,
            value: data.ProductClassId,
          });
        } else {
          setValue("ProductClassId", null);
          setselectedproductClass(null);
        }

        if (data.ProductGroupId !== null && data.ProductGroupId !== undefined) {
          setValue("ProductGroupId", data.ProductGroupId);
          setselectedproductGroup({
            label: data.ProductGroup,
            value: data.ProductGroupId,
          });
        } else {
          setValue("ProductGroupId", null);
          setselectedproductGroup(null);
        }

        if (data.ProductTypeId !== null && data.ProductTypeId !== undefined) {
          setValue("ProductTypeId", data.ProductTypeId);
          setselectedproductType({
            label: data.ProductType,
            value: data.ProductTypeId,
          });
        } else {
          setValue("ProductTypeId", null);
          setselectedproductType(null);
        }

        if (data.DivisionId !== null && data.DivisionId !== undefined) {
          setValue("DivisionId", data.DivisionId);
          setselecteddivision({
            label: data.DivisionName,
            value: data.DivisionId,
          });
        } else {
          setValue("DivisionId", null);
          setselecteddivision(null);
        }

        if (data.PackingTypeId !== null && data.PackingTypeId !== undefined) {
          setValue("PackingTypeId", data.PackingTypeId);
          setselectedpackingtype({
            label: data.PackingTypeName,
            value: data.PackingTypeId,
          });
        } else {
          setValue("PackingTypeId", null);
          setselectedpackingtype(null);
        }
      }
    }
  }, [data]);
  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = cameraLogo;
  };

  const [selectedPTRPTSSettingOptions, setselectedPTRPTSSettingOptions]: any =
    useState(null);
  const [selecteddivision, setselecteddivision]: any = useState(null);
  const [selectedDrugHead, setselectedDrugHead]: any = useState(null);
  const [selectedProductLine, setselectedProductLine]: any = useState(null);
  const [selectedproductGroup, setselectedproductGroup]: any = useState(null);
  const [selectedproductClass, setselectedproductClass]: any = useState(null);
  const [selectedproductType, setselectedproductType]: any = useState(null);
  const [selectedpackingtype, setselectedpackingtype]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const form = watch();
  console.log(form, "form");

  const {
    mutate: productForm,
    isSuccess,
    isError: errorProduct,
    error,
    isLoading,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const productFormData = (data: any) => {
    if (QRImage !== null) {
      data.QRCodeImage = QRImage;
    }

    data.IsCompetitorProduct = false;

    data.PackingTypeId = Number(data.PackingTypeId);
    data.DrugHeadId = Number(data.DrugHeadId);
    data.ProductLineId = Number(data.ProductLineId);
    data.ProductClassId = Number(data.ProductClassId);
    data.ProductGroupId = Number(data.ProductGroupId);
    data.ProductTypeId = Number(data.ProductTypeId);
    data.DivisionId = Number(data.DivisionId);
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    console.log(data, "Product Data");
    if (typeof data.PTrptssettingId === "object") {
      data.PTrptssettingId = data.PTrptssettingId.PTrptssettingId;
    }
    data.GST = Number(data.GST);
    data.Mrp = Number(data.Mrp);
    data.Pts = Number(data.Pts);
    data.Ptr = Number(data.Ptr);

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );

    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        if (formvalue !== null) {
          formData.append(key, formvalue);

        }
      } else {
        formData.append(key, formvalue[0])
      }
    }
    productForm(formData);
  };

  // Success & Error Messages
  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (errorProduct) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [errorProduct, isSuccess, id]);

  const [GST, setGST]: any = useState();
  const [mrp, setMRP]: any = useState();

  const handlePTSPTRFunction = () => {
    if (mrp !== "") {
      const MRP1 = Number(mrp);

      const gst = (MRP1 + (MRP1 * Number(GST)) / 100) / 100;

      const name =
        (MRP1 -
          (MRP1 * selectedPTRPTSSettingOptions?.value?.MedicalMarginNumber) /
          100) /
        100;
      const name2 =
        (MRP1 -
          (MRP1 * selectedPTRPTSSettingOptions?.value?.MedicalMarginNumber) /
          100 -
          ((MRP1 -
            (MRP1 * selectedPTRPTSSettingOptions?.value?.MedicalMarginNumber) /
            100) *
            selectedPTRPTSSettingOptions?.value?.AgeneyMargin) /
          100) /
        100;

      if (GST !== 1) {
        const PTR = MRP1 * name;
        const PTS = MRP1 * name2;
        setValue("Pts", (PTS / gst).toFixed(2));
        setValue("Ptr", (PTR / gst).toFixed(2));
      }
    } else {
      setValue("Pts", "0.00");
      setValue("Ptr", "0.00");
    }
  };

  useEffect(() => {
    if (
      selectedPTRPTSSettingOptions == null ||
      GST == "" ||
      GST == undefined ||
      mrp == "" ||
      mrp == undefined
    ) {
      setValue("Pts", "0.00");
      setValue("Ptr", "0.00");
    } else {
      handlePTSPTRFunction();
    }
  }, [selectedPTRPTSSettingOptions, GST, mrp]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Product`}
        modal
        style={{ width: "1500px" }}
      >
        {!productLoading ? (
          <div className="pd-20 card-box mb-30">
            <form
              onSubmit={handleSubmit((pinfo: any) => {
                productFormData(pinfo);
              })}
            >
              <div className="row">
                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Division"}
                  isRequired
                  name={"DivisionId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("DivisionId", e?.value);
                      setselecteddivision(e);
                    } else {
                      setValue("DivisionId", null);
                      setselecteddivision(null);
                    }
                  }}
                  value={selecteddivision}
                  control={control}
                  api={process.env.REACT_APP_DIVISION_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.DivisionId?.message ||
                    error?.response?.data?.errors?.DivisionId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: { value: true, message: "Division is required" },
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Drug Head"}
                  isRequired
                  name={"DrugHeadId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("DrugHeadId", e?.value);
                      setselectedDrugHead(e);
                    } else {
                      setValue("DrugHeadId", null);
                      setselectedDrugHead(null);
                    }
                  }}
                  value={selectedDrugHead}
                  control={control}
                  flag
                  api={`${process.env.REACT_APP_DRUGHEAD_API}?isCompetitorProduct=false`}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.DrugHeadId?.message ||
                    error?.response?.data?.errors?.DrugHeadId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: { value: true, message: "Drug Head is required" },
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Product Line"}
                  isRequired
                  name={"ProductLineId"}
                  onChange={(e: any) => {
                    if (e === null) {
                      setselectedProductLine(null);
                      setValue("ProductLineId", null);
                    } else {
                      setselectedProductLine(e);
                      setValue("ProductLineId", e?.value);
                    }
                  }}
                  value={selectedProductLine}
                  control={control}
                  api={`${process.env.REACT_APP_PRODUCTLINE_API}?isCompetitorProduct=false`}
                  keys={{ label: "Value", value: "Id" }}
                  flag
                  errorMessage={
                    errors?.ProductLineId?.message ||
                    error?.response?.data?.errors?.ProductLineId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Product Line is required",
                    },
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Product Group"}
                  isRequired
                  name={"ProductGroupId"}
                  onChange={(e: any) => {
                    if (e === null) {
                      setselectedproductGroup(null);
                      setValue("ProductGroupId", null);
                    } else {
                      setselectedproductGroup(e);
                      setValue("ProductGroupId", e?.value);
                    }
                  }}
                  value={selectedproductGroup}
                  control={control}
                  api={`${process.env.REACT_APP_PRODUCTGROUP_API}?isCompetitorProduct=false`}
                  keys={{ label: "Value", value: "Id" }}
                  flag
                  errorMessage={
                    errors?.ProductGroupId?.message ||
                    error?.response?.data?.errors?.ProductGroupId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Product Group is required",
                    },
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Product Class"}
                  isRequired
                  name={"ProductClassId"}
                  onChange={(e: any) => {
                    if (e === null) {
                      setselectedproductClass(null);
                      setValue("ProductClassId", null);
                    } else {
                      setselectedproductClass(e);
                      setValue("ProductClassId", e?.value);
                    }
                  }}
                  value={selectedproductClass}
                  control={control}
                  api={`${process.env.REACT_APP_PRODUCTCLASS_API}?isCompetitorProduct=false`}
                  keys={{ label: "Value", value: "Id" }}
                  flag
                  errorMessage={
                    errors?.ProductClassId?.message ||
                    error?.response?.data?.errors?.ProductClassId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Product Class is required",
                    },
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Product Type"}
                  isRequired
                  name={"ProductTypeId"}
                  onChange={(e: any) => {
                    if (e === null) {
                      setselectedproductType(null);
                      setValue("ProductTypeId", null);
                    } else {
                      setselectedproductType(e);
                      setValue("ProductTypeId", e?.value);
                    }
                  }}
                  value={selectedproductType}
                  control={control}
                  api={`${process.env.REACT_APP_PRODUCTTYPE_API}?isCompetitorProduct=false`}
                  keys={{ label: "Value", value: "Id" }}
                  flag
                  errorMessage={
                    errors?.ProductTypeId?.message ||
                    error?.response?.data?.errors?.ProductTypeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Product Type is required",
                    },
                  }}
                />

                <Input
                  containerClass="col-lg-3"
                  label="Short Name"
                  isRequired
                  type="text"
                  register={{
                    ...register("ShortName", {
                      required: {
                        value: true,
                        message: "Short Name is required",
                      },
                      pattern: {
                        value: /^\w[a-zA-Z0-9\s',-.]{2,30}$/,
                        message:
                          "Enter a valid short name (2-30 alphanumeric characters or special characters (a-zA-Z0-9'-,.))",
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    setValue("ShortName", e.target.value);
                    clearErrors(["ShortName"]);
                  }}
                  errorMessage={
                    errors?.ShortName?.message ||
                    error?.response?.data?.errors?.ShortName?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-3"
                  label="Name"
                  isRequired
                  type="text"
                  register={{
                    ...register("Name", {
                      required: { value: true, message: "Name is required" },
                      pattern: {
                        value: /^\w[a-zA-Z0-9\s',-.]{2,50}$/,
                        message:
                          "Enter a valid name (2-50 alphanumeric characters or special characters (a-zA-Z0-9'-.,))",
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    setValue("Name", e.target.value);
                    clearErrors(["Name"]);
                  }}
                  errorMessage={
                    errors?.Name?.message ||
                    error?.response?.data?.errors?.Name?.map((e: any) => e)
                  }
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"Packing Type"}
                  isRequired
                  name={"PackingTypeId"}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("PackingTypeId", e?.value);
                      setselectedpackingtype(e);
                    } else {
                      setValue("PackingTypeId", null);
                      setselectedpackingtype(null);
                    }
                  }}
                  value={selectedpackingtype}
                  control={control}
                  api={`${process.env.REACT_APP_PACKINGTYPE_API}?isCompetitorProduct=false`}
                  keys={{ label: "Value", value: "Id" }}
                  flag
                  errorMessage={
                    errors?.PackingTypeId?.message ||
                    error?.response?.data?.errors?.PackingTypeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Packing Type is required",
                    },
                  }}
                />

                <Input
                  containerClass="col-lg-3"
                  label="Code Ref 1"
                  type="text"
                  register={{
                    ...register("CodeRef1"),
                  }}
                  onChange={(e: any) => {
                    setValue("CodeRef1", e.target.value);
                    clearErrors(["CodeRef1"]);
                  }}
                  errorMessage={
                    errors?.CodeRef1?.message ||
                    error?.response?.data?.errors?.CodeRef1?.map((e: any) => e)
                  }
                />

                <div className="col-lg-3">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="custom-control custom-checkbox pt-5">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          {...register("New")}
                          id="New"
                        />
                        <label className="custom-control-label" htmlFor="New">
                          New
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="custom-control custom-checkbox pt-5">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          {...register("OnHold")}
                          id="Hold"
                        />
                        <label className="custom-control-label ml-3" htmlFor="Hold">
                          Upcoming
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox pt-5">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          {...register("KeyProduct")}
                          id="KeyProduct"
                        />
                        <label
                          className="custom-control-label ml-2"
                          htmlFor="KeyProduct"
                        >
                          Key Product
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <Input
                  containerClass="col-lg-3"
                  label="HSN Code"
                  isRequired
                  type="text"
                  register={{
                    ...register("HsnCode", {
                      required: {
                        value: true,
                        message: "HSN Code is required",
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    setValue("HsnCode", e.target.value);
                    clearErrors(["HsnCode"]);
                  }}
                  errorMessage={
                    errors?.HsnCode?.message ||
                    error?.response?.data?.errors?.HsnCode?.map((e: any) => e)
                  }
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label={"PTS AND PTR"}
                  isRequired
                  name={"PTrptssettingId"}
                  control={control}
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("PTrptssettingId", e?.value);
                      setselectedPTRPTSSettingOptions(e);
                    } else {
                      setValue("PTrptssettingId", null);
                      setselectedPTRPTSSettingOptions(null);
                    }
                  }}
                  value={selectedPTRPTSSettingOptions}
                  api={process.env.REACT_APP_PTRPTSSETTING_API}
                  keys={{
                    label: "Value",
                    value: {
                      MedicalMarginNumber: "MedicalMarginNumber",
                      AgeneyMargin: "AgeneyMargin",
                      PTrptssettingId: "PTrptssettingId",
                    },
                  }}
                  errorMessage={
                    errors?.PTrptssettingId?.message ||
                    error?.response?.data?.errors?.PTrptssettingId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "PTS And PTR is required",
                    },
                  }}
                />

                <Input
                  containerClass="col-lg-3"
                  label="GST %"
                  isRequired
                  min={0}
                  type="number"
                  onChange={(e: any) => {
                    setGST(e.target.value);
                    clearErrors(["GST"]);
                  }}
                  register={{
                    ...register("GST", {
                      required: { value: true, message: "GST is required" },
                    }),
                  }}
                  errorMessage={
                    errors?.GST?.message ||
                    error?.response?.data?.errors?.GST?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-3"
                  label="MRP"
                  min={0}
                  isRequired
                  type="number"
                  onChange={(e: any) => {
                    setMRP(e.target.value);
                    clearErrors(["Mrp"]);
                  }}
                  register={{
                    ...register("Mrp", {
                      required: { value: true, message: "MRP is required" }
                    }),
                  }}
                  errorMessage={
                    errors?.Mrp?.message ||
                    error?.response?.data?.errors?.Mrp?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-3"
                  label="PTR"
                  isRequired
                  type="text"
                  disabled={true}
                  register={{
                    ...register("Ptr"),
                  }}
                  onChange={(e: any) => {
                    setValue("Ptr", e.target.value);
                    clearErrors(["Ptr"]);
                  }}
                  errorMessage={
                    errors?.Ptr?.message ||
                    error?.response?.data?.errors?.Ptr?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-3"
                  label="PTS"
                  isRequired
                  type="text"
                  disabled={true}
                  register={{
                    ...register("Pts"),
                  }}
                  onChange={(e: any) => {
                    setValue("Pts", e.target.value);
                    clearErrors(["Pts"]);
                  }}
                  errorMessage={
                    errors?.Pts?.message ||
                    error?.response?.data?.errors?.Pts?.map((e: any) => e)
                  }
                />
                <div className={"col-lg-3"}>
                  <div className="form-group image-upload">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="col-form-label"
                    >
                      QR Image
                    </label>
                    <div>
                      <label style={{ cursor: "pointer" }}>
                        <img
                          // src={cameraLogo}
                          src={QRImageDisplay}
                          alt="Click Here to add Image"
                          className="uploaded-image"
                          width={"150px"}
                          height={"150px"}
                          // onerror="this.onerror=null; this.src='fallback-image.png';"
                          onError={handleError}
                        />

                        <input
                          type="file"
                          {...register("QRCodeImage")}
                          onChange={(e: any) => {
                            console.log(e.target.files[0], "e is here")
                            if (e.target.files[0]) {
                              const filePath = URL.createObjectURL(e.target.files[0]);
                              console.log('Selected file path:', filePath);
                              setQrImageDisplay(filePath);
                            }
                            setQRImage(e.target.files);

                          }}
                          className="form-control"
                          hidden
                          accept=".pdf,image/*"
                        />
                      </label>
                    </div>
                    {QRImage == null ? (
                      <a href={`${data?.QRCodePath}`} target="blank">
                        {data?.QRCode}
                      </a>
                    ) : (
                      <p>{QRImage[0]?.name}</p>
                    )}
                    {!data.QRCodePath && QRImage == null && (
                      <ErrorMessage message={errors?.QRCodePath?.message} />
                    )}
                  </div>
                </div>
                <Textarea
                  containerClass="col-lg-12"
                  label="Content"
                  isRequired
                  register={{
                    ...register("Contents", {
                      required: { value: true, message: "Content is required" },
                    }),
                  }}
                  style={{ height: "75px" }}
                  onChange={(e: any) => {
                    setValue("Contents", e.target.value);
                    clearErrors(["Contents"]);
                  }}
                  errorMessage={
                    errors?.Contents?.message ||
                    error?.response?.data?.errors?.Contents?.map((e: any) => e)
                  }
                />

                <div className="col-lg-12 text-center mt-3">
                  <Button
                    type="button"
                    severity="secondary"
                    outlined
                    onClick={() => setisOPen(false)}
                  >
                    <span className="p-button-label p-c">Back</span>
                  </Button>

                  {id === 0 && (
                    <Button
                      type="reset"
                      severity="danger"
                      onClick={() => {
                        reset();
                        setselectedDrugHead(null);
                        setselectedPTRPTSSettingOptions(null);
                        setselectedProductLine(null);
                        setselecteddivision(null);
                        setselectedpackingtype(null);
                        setselectedproductGroup(null);
                        setselectedproductClass(null);
                        setselectedproductType(null);
                      }}
                      outlined
                      className="ml-2"
                    >
                      <span className="p-button-label p-c">Reset</span>
                    </Button>
                  )}

                  {isLoading ? (
                    <Button type="submit" outlined disabled className="ml-2">
                      <span className="p-button-label p-c">
                        Submit <Spinner width={"1rem"} height={"1rem"} />
                      </span>
                    </Button>
                  ) : (
                    <Button type="submit" outlined className="ml-2">
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
