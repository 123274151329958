import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import Textarea from "../../../common/Textarea";

export default function AddEditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const apiname = process.env.REACT_APP_PRODUCT_API?.toString();
  const apiname1 = process.env.REACT_APP_COMPETITORSPRODUCT_API?.toString();
  const controller = new AbortController();
  const { data, isLoading: productLoading }: any = useFetchAllID(apiname1, id, undefined, controller.signal);

  useEffect(() => {
    if (id) {
      reset(data);
      if (data) {
        setGST(data.GST);
        setMRP(data.Mrp);
      
        setselectedDrugHead({ label: data.DrugHead, value: data.DrugHeadId });
        setselectedProductLine({
          label: data.ProductLine,
          value: data.ProductLineId,
        });
        setselectedproductClass({
          label: data.ProductClass,
          value: data.ProductClassId,
        });
        setselectedproductGroup({
          label: data.ProductGroup,
          value: data.ProductGroupId,
        });
        setselectedproductType({
          label: data.ProductType,
          value: data.ProductTypeId,
        });
        setselectedPTRPTSSettingOptions({
          label: data.PTrptssettingName,
          value: {
            MedicalMarginNumber: data.MedicalMarginNumber,
            AgeneyMargin: data.AgeneyMargin,
            PTrptssettingId: data.PTrptssettingId,
          },
        });
        setselecteddivision({
          label: data.DivisionName,
          value: data.DivisionId,
        });
        setselectedpackingtype({
          label: data.PackingTypeName,
          value: data.PackingTypeId,
        });
        setselectedproduct({
          label: data.OurCompetingProductName,
          value: data.OurCompetingProduct,
        });
        setValue("OurProductId", data.OurCompetingProduct);
        setselectedcompMst({
          label: data.CompetitorName,
          value: data.CompetitorId,
        });
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const [selectedPTRPTSSettingOptions, setselectedPTRPTSSettingOptions]: any =
    useState(null);

  const [selectedcompMst, setselectedcompMst]: any = useState(null);
  const [selectedproduct, setselectedproduct]: any = useState(null);
  const [selecteddivision, setselecteddivision]: any = useState(null);
  const [selectedDrugHead, setselectedDrugHead]: any = useState(null);
  const [selectedProductLine, setselectedProductLine]: any = useState(null);
  const [selectedproductGroup, setselectedproductGroup]: any = useState(null);
  const [selectedproductClass, setselectedproductClass]: any = useState(null);
  const [selectedproductType, setselectedproductType]: any = useState(null);
  const [selectedpackingtype, setselectedpackingtype]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate: productForm,
    isSuccess,
    isError: errorProduct,
    error,
    isLoading,
  }: any = useCreateByID(apiname, id);

  const productFormData = (data: any) => {
    data.IsCompetitorProduct = true;
    if (typeof data?.OurProductId === "object") {
      data.OurProductId = Number(data?.OurProductId?.value);
    }
    if (id !== undefined) {
      data.ProductId = data?.CompetitorsProductId;
      delete data?.CompetitorsProductId;
    }
    if (typeof data.PTrptssettingId === "object") {
      data.PTrptssettingId = data?.PTrptssettingId?.PTrptssettingId;
    }
    data.GST = Number(data.GST);
    data.Mrp = Number(data.Mrp);
    data.Pts = Number(data.Pts);
    data.Ptr = Number(data.Ptr);

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    
    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        if (formvalue !== null) {
          formData.append(key, formvalue);
        }
      } else {
        formData.append(key, formvalue)
      }
    }
    productForm(formData);

  };

  // Success & Error Messages
  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/master/RCPA/CompetitorsProduct");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/master/RCPA/CompetitorsProduct");
      }
    }

    if (errorProduct) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [errorProduct, isSuccess]);

  const [GST, setGST]: any = useState();
  const [mrp, setMRP]: any = useState();

  const handlePTSPTRFunction = () => {
    if (mrp !== "") {
      const MRP1 = Number(mrp);
      const gst = (MRP1 + (MRP1 * Number(GST)) / 100) / 100;
      const name =
        (MRP1 -
          (MRP1 * selectedPTRPTSSettingOptions?.value?.MedicalMarginNumber) /
          100) /
        100;
      const name2 =
        (MRP1 -
          (MRP1 * selectedPTRPTSSettingOptions?.value?.MedicalMarginNumber) /
          100 -
          ((MRP1 -
            (MRP1 * selectedPTRPTSSettingOptions?.value?.MedicalMarginNumber) /
            100) *
            selectedPTRPTSSettingOptions?.value?.AgeneyMargin) /
          100) /
        100;

      if (GST !== 1) {
        const PTR = MRP1 * name;
        const PTS = MRP1 * name2;
        setValue("Pts", (PTS / gst).toFixed(2));
        setValue("Ptr", (PTR / gst).toFixed(2));
      }
    } else {
      setValue("Pts", "0.00");
      setValue("Ptr", "0.00");
    }
  };

  useEffect(() => {
    if (
      selectedPTRPTSSettingOptions == null ||
      GST == "" ||
      GST == undefined ||
      mrp == "" ||
      mrp == undefined
    ) {
      setValue("Pts", "0.00");
      setValue("Ptr", "0.00");
    } else {
      handlePTSPTRFunction();
    }
  }, [selectedPTRPTSSettingOptions, GST, mrp]);

  return (
    <>
      <Helmet>
        <title>{id === undefined ? "Add" : "Edit"} Competitor Product </title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>{id === undefined ? "Add" : "Edit"} Competitor Product </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/master/RCPA/CompetitorsProduct"}>
                    Competitor Product{" "}
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {id === undefined ? "Add" : "Edit"} Competitor Product
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {!productLoading ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((pinfo: any) => {
              productFormData(pinfo);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label={"Competitor Name"}
                isRequired
                name={"CompetitorId"}
                onChange={(e: any) => {
                  setselectedcompMst(e);
                  setValue("CompetitorId", e?.value);
                }}
                value={selectedcompMst}

                control={control}
                api={process.env.REACT_APP_COMPETITORMST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.CompetitorId?.message ||
                  error?.response?.data?.errors?.CompetitorId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Competitor Name is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Our Product"}
                isRequired

                name={"OurProductId"}
                onChange={(e: any) => {
                  console.log(e?.value, "e");
                  setselectedproduct(e);
                  setValue("OurProductId", e?.value);
                }}
                value={selectedproduct}
                control={control}
                api={process.env.REACT_APP_PRODUCT_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.OurProductId?.message ||
                  error?.response?.data?.errors?.OurProductId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Product is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Division"}
                isRequired

                name={"DivisionId"}
                onChange={(e: any) => {
                  setselecteddivision(e);
                  setValue("DivisionId", e?.value);
                }}
                value={selecteddivision}
                control={control}
                api={process.env.REACT_APP_DIVISION_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DivisionId?.message ||
                  error?.response?.data?.errors?.DivisionId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Drug Head"}
                isRequired
                name={"DrugHeadId"}
                onChange={(e: any) => {
                  setselectedDrugHead(e);
                  setValue("DrugHeadId", e?.value);
                }}
                value={selectedDrugHead}
                control={control}
                flag
                api={`${process.env.REACT_APP_DRUGHEAD_API}?isCompetitorProduct=true`}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DrugHeadId?.message ||
                  error?.response?.data?.errors?.DrugHeadId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Drug Head is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Product Line"}
                isRequired
                name={"ProductLineId"}
                onChange={(e: any) => {
                  setselectedProductLine(e);
                  setValue("ProductLineId", e?.value);
                }}
                value={selectedProductLine}
                control={control}
                api={`${process.env.REACT_APP_PRODUCTLINE_API}?isCompetitorProduct=true`}
                keys={{ label: "Value", value: "Id" }}
                flag
                errorMessage={
                  errors?.ProductLineId?.message ||
                  error?.response?.data?.errors?.ProductLineId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Product Line is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Product Group"}
                isRequired
                name={"ProductGroupId"}
                onChange={(e: any) => {
                  setselectedproductGroup(e);
                  setValue("ProductGroupId", e?.value);
                }}
                value={selectedproductGroup}
                control={control}
                api={`${process.env.REACT_APP_PRODUCTGROUP_API}?isCompetitorProduct=true`}
                keys={{ label: "Value", value: "Id" }}
                flag
                errorMessage={
                  errors?.ProductGroupId?.message ||
                  error?.response?.data?.errors?.ProductGroupId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Product Group is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Product Class"}
                isRequired
                name={"ProductClassId"}
                onChange={(e: any) => {
                  setselectedproductClass(e);
                  setValue("ProductClassId", e?.value);
                }}
                value={selectedproductClass}
                control={control}
                api={`${process.env.REACT_APP_PRODUCTCLASS_API}?isCompetitorProduct=true`}
                keys={{ label: "Value", value: "Id" }}
                flag
                errorMessage={
                  errors?.ProductClassId?.message ||
                  error?.response?.data?.errors?.ProductClassId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Product Class is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Product Type"}
                isRequired
                name={"ProductTypeId"}
                onChange={(e: any) => {
                  setselectedproductType(e);
                  setValue("ProductTypeId", e?.value);
                }}
                value={selectedproductType}
                control={control}
                api={`${process.env.REACT_APP_PRODUCTTYPE_API}?isCompetitorProduct=true`}
                keys={{ label: "Value", value: "Id" }}
                flag
                errorMessage={
                  errors?.ProductTypeId?.message ||
                  error?.response?.data?.errors?.ProductTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Product Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Short Name"
                isRequired
                type="text"
                register={{
                  ...register("ShortName", {
                    required: {
                      value: true,
                      message: "Short Name is required",
                    },
                    pattern: {
                      value: /^.{2,30}$/gm,
                      message:
                        "Enter a valid short name (2-30 alphanumeric characters or special characters)",
                    },
                  }),
                }}
                onChange={(e: any) => { setValue("ShortName", e.target.value); clearErrors(["ShortName"]) }}
                errorMessage={
                  errors?.ShortName?.message ||
                  error?.response?.data?.errors?.ShortName?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Name"
                isRequired
                type="text"
                register={{
                  ...register("Name", {
                    required: { value: true, message: "Name is required" },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid name (2-50 alphanumeric characters or special characters)",
                    },
                  }),
                }}
                onChange={(e: any) => { setValue("Name", e.target.value); clearErrors(["Name"]) }}
                errorMessage={
                  errors?.Name?.message ||
                  error?.response?.data?.errors?.Name?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Packing Type"}
                isRequired
                name={"PackingTypeId"}
                onChange={(e: any) => {
                  setselectedpackingtype(e);
                  setValue("PackingTypeId", e?.value);
                }}
                value={selectedpackingtype}
                control={control}
                api={`${process.env.REACT_APP_PACKINGTYPE_API}?isCompetitorProduct=true`}
                keys={{ label: "Value", value: "Id" }}
                flag
                errorMessage={
                  errors?.PackingTypeId?.message ||
                  error?.response?.data?.errors?.PackingTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Packing Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Code Ref 1"
                type="text"
                register={{
                  ...register("CodeRef1", {
                    pattern: {
                      value: /^\w[A-Za-z0-9]{2,5}$/,
                      message:
                        "Enter a valid code ref (2-5 alphanumeric characters)",
                    },
                  }),
                }}
                onChange={(e: any) => { setValue("CodeRef1", e.target.value); clearErrors(["CodeRef1"]) }}
                errorMessage={
                  errors?.CodeRef1?.message ||
                  error?.response?.data?.errors?.CodeRef1?.map((e: any) => e)
                }
              />

              <div className="col-lg-4">
                <div className="row">
                  <div className="col-md-4">
                    <div className="custom-control custom-checkbox pt-5">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        {...register("New")}
                        id="New"
                      />
                      <label className="custom-control-label" htmlFor="New">
                        New
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="custom-control custom-checkbox pt-5">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        {...register("OnHold")}
                        id="Hold"
                      />
                      <label className="custom-control-label" htmlFor="Hold">
                        Upcoming
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="custom-control custom-checkbox pt-5">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        {...register("KeyProduct")}
                        id="KeyProduct"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="KeyProduct"
                      >
                        Key Product
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <Input
                containerClass="col-lg-4"
                label="HSN Code"
                isRequired
                type="text"
                register={{
                  ...register("HsnCode", {
                    required: { value: true, message: "HSN Code is required" },
                    pattern: {
                      value: /^[A-Z0-9.%]{6,8}$/,
                      message:
                        "Enter a valid hsncode (6-8 alphanumeric characters and special characters (A-Z0-9.%))",
                    },
                  }),
                }}
                onChange={(e: any) => { setValue("HsnCode", e.target.value); clearErrors(["HsnCode"]) }}
                errorMessage={
                  errors?.HsnCode?.message ||
                  error?.response?.data?.errors?.HsnCode?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"PTS AND PTR"}
                isRequired

                name={"PTrptssettingId"}
                control={control}
                onChange={(e: any) => {
                  setselectedPTRPTSSettingOptions(e);
                  setValue("PTrptssettingId", e?.value);
                }}
                value={selectedPTRPTSSettingOptions}
                api={process.env.REACT_APP_PTRPTSSETTING_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.PTrptssettingId?.message ||
                  error?.response?.data?.errors?.PTrptssettingId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: { value: true, message: "PTS & PTR is required" },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="GST"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setGST(e.target.value);
                  clearErrors(["GST"]);
                  setValue("GST", e.target.value)
                }}
                register={{
                  ...register("GST", {
                    required: { value: true, message: "GST is required" },
                  }),
                }}
                errorMessage={
                  errors?.GST?.message ||
                  error?.response?.data?.errors?.GST?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="MRP"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setMRP(e.target.value);
                  setValue("Mrp", e.target.value)
                  clearErrors(["Mrp"]);
                }}
                register={{
                  ...register("Mrp", {
                    required: { value: true, message: "MRP is required" },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message: "Enter a valid MRP (1-7 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Mrp?.message ||
                  error?.response?.data?.errors?.Mrp?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="PTR"
                isRequired
                type="text"
                disabled={true}
                register={{
                  ...register("Ptr"),
                }}
                onChange={(e: any) => { setValue("Ptr", e.target.value); clearErrors(["Ptr"]) }}
                errorMessage={
                  errors?.Ptr?.message ||
                  error?.response?.data?.errors?.Ptr?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="PTS"
                isRequired
                type="text"
                disabled={true}
                register={{
                  ...register("Pts"),
                }}
                onChange={(e: any) => { setValue("Pts", e.target.value); clearErrors(["Pts"]) }}
                errorMessage={
                  errors?.Pts?.message ||
                  error?.response?.data?.errors?.Pts?.map((e: any) => e)
                }
              />

              <Textarea
                containerClass="col-lg-12"
                label="Content"
                isRequired
                register={{
                  ...register("Contents", {
                    required: { value: true, message: "Content is required" },
                  }),
                }}
                maxLength={500}
                onChange={(e: any) => { setValue("Contents", e.target.value); clearErrors(["Contents"]) }}
                errorMessage={
                  errors?.Contents?.message ||
                  error?.response?.data?.errors?.Contents?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center">
                <Link
                  to={"/layout/master/RCPA/CompetitorsProduct"}
                  className="btn btn btn-outline-secondary mt-2 mx-2"
                >
                  {" "}
                  Back{" "}
                </Link>
                {id === undefined ? (
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-2 mx-2"
                    onClick={() => {
                      reset();
                      setselectedDrugHead(null);
                      setselectedPTRPTSSettingOptions(null);
                      setselectedProductLine(null);
                      setselecteddivision(null);
                      setselectedcompMst(null);
                      setselectedpackingtype(null);
                      setselectedproduct(null);
                      setselectedproductClass(null);
                      setselectedproductGroup(null);
                      setselectedproductType(null);
                    }}
                  >
                    {" "}
                    Reset{" "}
                  </button>
                ) : (
                  ""
                )}
                {isLoading ? (
                  <button
                    className="btn btn-outline-primary mt-2 mx-2"
                    disabled
                  >
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 mx-2"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
}
