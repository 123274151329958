import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { Address } from "../../../common/Address";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: string;
  cityDrop?: boolean;
  parameter?: boolean;
}
const AddEditChemist = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
  cityDrop = false,
  parameter = false,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_CHEMIST_API?.toString();
  const controller = new AbortController();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm();

  const { data, isLoading: chemistLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    undefined,
    controller.signal
  );

  const role = localStorage.getItem("Roles");

  const Hcity = watch("CityId");
  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        let DoctorList = data?.DoctorList?.map((e: any) => ({
          label: e?.DoctorName,
          value: e?.DoctorId,
        }));
        setValue("DoctorIds", DoctorList);
        setselecteddoctor(DoctorList);

        let EmployeeList = data?.EmployeeList?.map((e: any) => ({
          label: e?.EmployeeName,
          value: e?.EmployeeId,
        }));
        setValue("EmployeeIds", EmployeeList);
        setselectedemployee(EmployeeList);
      }
      if (data.StockistId !== null && data.StockistId !== undefined) {
        if (selectedstockist === null) {
          setselectedstockist({
            label: data.StockistName,
            value: data.StockistId,
          });
        }
      }

      if (data.CategoryId !== null && data.CategoryId !== undefined) {
        setValue("CategoryId", data.CategoryId);
        setselectedCategory({
          label: data.CategoryName,
          value: data.CategoryId,
        });
      } else {
        setValue("CategoryId", null);
        setselectedCategory(null);
      }

      if (data.ChemistTypeId !== null && data.ChemistTypeId !== undefined) {
        if (selectedchemist === null && data.ChemistTypeId !== undefined) {
          setselectedchemist({
            label: data.ChemistType,
            value: data.ChemistTypeId,
          });
          setValue("ChemistTypeId", data.ChemistTypeId);
        }
      } else {
        setValue("ChemistTypeId", null);
        setselectedchemist(null);
      }

      if (data.CityId !== null && data.CityId !== undefined) {
        setValue("CityId", {
          label: data.City,
          value: data.CityId,
        });
        setselectedcity({
          label: data.City,
          value: data.CityId,
        });
      } else {
        setValue("CityId", null);
        setselectedcity(null);
      }
      if (data.PincodeId !== null && data.PincodeId !== undefined) {
        setValue("PincodeId", {
          label: data.Pincode,
          value: data.PincodeId,
        });
        setselectedpincode({
          label: data.Pincode,
          value: data.PincodeId,
        });
      } else {
        setValue("PincodeId", null);
        setselectedpincode(null);
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const [selectedstockist, setselectedstockist]: any = useState(null);
  const [selectedchemist, setselectedchemist]: any = useState(null);
  const [selectedCategory, setselectedCategory]: any = useState(null);
  const [selectedemployee, setselectedemployee] = useState([]);
  const [selecteddoctor, setselecteddoctor] = useState([]);
  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedpincode, setselectedpincode]: any = useState(null);

  const chemistFormData = (data: any) => {

    data.IsUnlinked = parameter;
    data.CityId = selectedcity?.value;
    data.PincodeId = selectedpincode?.value;
    data.EmployeeIds = data?.EmployeeIds?.map((e: any) => {
      return e?.value;
    });

    data.DoctorIds = data.DoctorIds?.map((e: any) => {

      console.log(e?.value.value, "fddff---->");
      if (typeof e?.value === "object") {
        return e?.value.value;
      } else {
        return e?.value;
      }
    });

    data.MobileNo = data.MobileNo?.replace(/[^\d]/g, "");

    if (
      data.AddressLine1 === null ||
      data.AddressLine1 === undefined ||
      data.AddressLine1 === ""
    ) {
      delete data.AddressLine1;
    }

    if (
      data.AddressLine2 === null ||
      data.AddressLine2 === undefined ||
      data.AddressLine2 === ""
    ) {
      delete data.AddressLine2;
    }

    if (data.CityId === null || data.CityId === undefined) {
      delete data.CityId;
    }

    if (data.PincodeId === null || data.PincodeId === undefined) {
      delete data.PincodeId;
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }
    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  const formElement = document.querySelectorAll("input");

  useEffect(() => {
    if (formElement.length !== 0) {
      if (defaultValue) {
        setValue("ChemistName", defaultValue);
      }
    }
  }, [formElement]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Chemist`}
        modal
        style={{ width: "1100px" }}
      >
        {!chemistLoader ? (
          <div className="pd-20 card-box mb-30">
            <form
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.key === "NumpadEnter") {
                  handleSubmit(chemistFormData);
                }
              }}
              onSubmit={handleSubmit(chemistFormData)}
            >
              <div className="row">
                <Input
                  containerClass="col-lg-4"
                  label="Chemist Name"
                  type="text"
                  isRequired
                  onChange={(e: any) => {
                    setValue("ChemistName", e.target.value);
                    clearErrors(["ChemistName"]);
                  }}
                  register={{
                    ...register("ChemistName", {
                      required: {
                        value: true,
                        message: "Chemist Name is required",
                      },
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid chemistname (1-50 alphabet characters or special characters)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.ChemistName?.message ||
                    error?.response?.data?.errors?.ChemistName?.map(
                      (e: any) => e
                    )
                  }
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  label="Chemist Type"
                  control={control}
                  name="ChemistTypeId"
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("ChemistTypeId", e?.value);
                      setselectedchemist(e);
                    } else {
                      setValue("ChemistTypeId", null);
                      setselectedchemist(null);
                    }
                  }}
                  isRequired
                  value={selectedchemist}
                  api={process.env.REACT_APP_CHEMISTTYPE_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.ChemistTypeId?.message ||
                    error?.response?.data?.errors?.ChemistTypeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Chemist Type is required",
                    },
                  }}
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  label="Category"
                  control={control}
                  name="CategoryId"
                  onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("CategoryId", e?.value);
                      setselectedCategory(e);
                    } else {
                      setValue("CategoryId", null);
                      setselectedCategory(null);
                    }
                  }}
                  isRequired
                  value={selectedCategory}
                  api={process.env.REACT_APP_CATEGORY_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.CategoryId?.message ||
                    error?.response?.data?.errors?.CategoryId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Category is required",
                    },
                  }}
                />

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                    <InputMask
                      {...register("MobileNo", {
                        required: {
                          value: true,
                          message: "Mobile No is required",
                        },
                      })}
                      mask="(999) 999-9999"
                      className="form-control"
                      onChange={(e) => {
                        setValue("MobileNo", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      message={
                        errors?.MobileNo?.message ||
                        error?.response?.data?.errors?.MobileNo?.map(
                          (e: any) => e
                        )
                      }
                    />
                  </div>
                </div>

                <Input
                  containerClass="col-lg-4"
                  label="Phone No"
                  type="text"
                  onChange={(e: any) => {
                    setValue("PhoneNo", e.target.value);
                    clearErrors(["PhoneNo"]);
                  }}
                  register={{
                    ...register("PhoneNo", {
                      pattern: {
                        value: /^\d{6,10}$/,
                        message: "Enter a valid phone no. (6-10 digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.PhoneNo?.message ||
                    error?.response?.data?.errors?.PhoneNo?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Potential Business"
                  type="text"
                  onChange={(e: any) => {
                    setValue("PotentialBusiness", e.target.value);
                    clearErrors(["PotentialBusiness"]);
                  }}
                  register={{
                    ...register("PotentialBusiness"),
                  }}
                  errorMessage={
                    errors?.PotentialBusiness?.message ||
                    error?.response?.data?.errors?.PotentialBusiness?.map(
                      (e: any) => e
                    )
                  }
                />

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">DL No1</label>
                    <InputMask
                      {...register("DlNo1")}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      mask="9999 99999999999"
                      className="form-control"
                    />
                    <ErrorMessage
                      message={
                        errors?.DlNo1?.message ||
                        error?.response?.data?.errors?.DlNo1?.map((e: any) => e)
                      }
                    />
                  </div>
                </div>

                <div className="col-lg -4">
                  <div className="form-group">
                    <label className="col-form-label">DL No2</label>
                    <InputMask
                      {...register("DlNo2")}
                      onChange={(e) => setValue(e.target.value)}
                      mask="9999 99999999999"
                      className="form-control"
                    />
                    <ErrorMessage
                      message={
                        errors?.DlNo2?.message ||
                        error?.response?.data?.errors?.DlNo2?.map((e: any) => e)
                      }
                    />
                  </div>
                </div>

                <Input
                  containerClass="col-lg-4"
                  label="Email"
                  type="text"
                  register={{
                    ...register("Email", {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                        message: "Email format is Invalid..",
                      },
                    }),
                  }}
                  maxLength={50}
                  onChange={(e: any) => {
                    setValue("Email", e.target.value);
                    clearErrors(["Email"]);
                  }}
                  errorMessage={
                    errors?.Email?.message ||
                    error?.response?.data?.errors?.Email?.map((e: any) => e)
                  }
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  label="Stockist"
                  control={control}
                  name="StockistId"
                  onChange={(e: any) => {
                    console.log(e);
                    setValue("StockistId", e?.value);
                    setselectedstockist(e);
                  }}
                  value={selectedstockist}
                  api={process.env.REACT_APP_STOCKIST_API}
                  keys={{ label: "Value", value: "Id" }}
                />

                <Input
                  containerClass="col-lg-4"
                  label="GstNo"
                  type="text"
                  onChange={(e: any) => {
                    setValue("GstNo", e.target.value);
                    clearErrors(["GstNo"]);
                  }}
                  register={{
                    ...register("GstNo", {
                      pattern: {
                        value: /^[A-Z0-9]{15}$/,
                        message:
                          "Enter a valid GstNo (15 Capital letters & digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.GstNo?.message ||
                    error?.response?.data?.errors?.GstNo?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="FSSAI"
                  type="text"
                  onChange={(e: any) => {
                    setValue("FssAi", e.target.value);
                    clearErrors(["FssAi"]);
                  }}
                  register={{
                    ...register("FssAi", {
                      pattern: {
                        value: /^[0-9]{14}$/,
                        message: "Enter a valid FSSAI (14 digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.FssAi?.message ||
                    error?.response?.data?.errors?.FssAi?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="NRX"
                  type="text"
                  maxLength={10}
                  onChange={(e: any) => {
                    setValue("Nrx", e.target.value);
                    clearErrors(["Nrx"]);
                  }}
                  register={{
                    ...register("Nrx"),
                  }}
                  errorMessage={
                    errors?.Nrx?.message ||
                    error?.response?.data?.errors?.Nrx?.map((e: any) => e)
                  }
                />

                {role !== "MR" && (
                  <ReactSelect
                    containerClass="col-lg-4"
                    label={"Employee"}
                    name={"EmployeeIds"}
                    onChange={(e: any) => {
                      setValue("EmployeeIds", e);
                      setselectedemployee(e);
                    }}
                    value={selectedemployee}
                    control={control}
                    api={process.env.REACT_APP_EMPLOYEE_API}
                    keys={{ label: "Value", value: "Id" }}
                    errorMessage={
                      errors?.EmployeeIds?.message ||
                      error?.response?.data?.errors?.EmployeeIds?.map(
                        (e: any) => e
                      )
                    }
                    Multi
                  />
                )}

                <ReactSelect
                  containerClass="col-lg-4"
                  label={"Doctor"}
                  name={"DoctorIds"}
                  onChange={(e: any) => {
                    setValue("DoctorIds", e);
                    setselecteddoctor(e);
                  }}
                  value={selecteddoctor}
                  control={control}
                  api={process.env.REACT_APP_DOCTORDETAIL_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.DoctorIds?.message ||
                    error?.response?.data?.errors?.DoctorIds?.map((e: any) => e)
                  }
                  Multi
                />

                <Address
                  header="Chemist Address"
                  control={control}
                  address1register={{
                    ...register("AddressLine1", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message: "Enter a valid Address(2-250 characters)",
                      },
                    }),
                  }}
                  address1onChange={(e: any) => {
                    setValue("AddressLine1", e.target.value);
                    clearErrors(["AddressLine1"]);
                  }}
                  address1errorMessage={
                    errors?.AddressLine1?.message ||
                    error?.response?.data?.errors?.AddressLine1?.map(
                      (e: any) => e
                    )
                  }
                  address2register={{
                    ...register("AddressLine2", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message: "Enter a valid Area(2-250 characters)",
                      },
                    }),
                  }}
                  address2onChange={(e: any) => {
                    setValue("AddressLine2", e.target.value);
                    clearErrors(["AddressLine2"]);
                  }}
                  address2errorMessage={
                    errors?.AddressLine2?.message ||
                    error?.response?.data?.errors?.AddressLine2?.map(
                      (e: any) => e
                    )
                  }
                  address4onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("CityId", e?.value);
                      setselectedcity(e);
                      setselectedpincode({});
                    } else {
                      setValue("CityId", null);
                      setValue("PincodeId", null);
                      setselectedcity(null);
                      setselectedpincode(null);
                    }
                  }}
                  address4errorMessage={
                    errors?.CityId?.message ||
                    error?.response?.data?.errors?.CityId?.map((e: any) => e)
                  }
                  cityrules={{
                    required: { value: true, message: "Please select city" },
                  }}
                  selectedCity={selectedcity}
                  address5onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("PincodeId", e?.value);
                      setselectedpincode(e);
                    } else {
                      setValue("PincodeId", null);
                      setselectedpincode(null);
                    }
                  }}
                  address5errorMessage={
                    errors?.PincodeId?.message ||
                    error?.response?.data?.errors?.PincodeId?.map((e: any) => e)
                  }
                  selectedPincode={selectedpincode}
                  cityKeys={{ label: "Value", value: "Id" }}
                  cityApi={
                    !cityDrop
                      ? `${process.env.REACT_APP_CITY_AND_STATE_API}`
                      : `${process.env.REACT_APP_CITY_AND_STATE_API}?underHQ=true`
                  }
                  cityFlag={true}
                  cityName={"CityId"}
                  pincodeKeys={{ label: "Value", value: "Id" }}
                  pincodeApi={
                    selectedcity !== null && selectedcity?.value !== undefined
                      ? `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedcity?.value}`
                      : ""
                  }
                  pincodeName={"PincodeId"}
                  pincodeDisable={selectedcity !== null ? false : true}
                  pincoderules={{
                    required: { value: true, message: "Pincode is required" },
                  }}
                />

                <div className="col-lg-12 text-center mt-3">
                  <Button
                    type="button"
                    severity="secondary"
                    outlined
                    onClick={() => setisOPen(false)}
                  >
                    <span className="p-button-label p-c">Back</span>
                  </Button>

                  {id === 0 && (
                    <Button
                      type="reset"
                      severity="danger"
                      onClick={() => {
                        reset();
                        setselectedchemist(null);
                        setselectedCategory(null);
                        setselectedcity(null);
                        setselectedpincode({});
                        setselectedstockist(null);
                        setselectedemployee([]);
                        setselecteddoctor([]);
                      }}
                      outlined
                      className="ml-2"
                    >
                      <span className="p-button-label p-c">Reset</span>
                    </Button>
                  )}

                  {isLoading ? (
                    <Button type="submit" outlined disabled className="ml-2">
                      <span className="p-button-label p-c">
                        Submit <Spinner width={"1rem"} height={"1rem"} />
                      </span>
                    </Button>
                  ) : (
                    <Button type="submit" outlined className="ml-2">
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditChemist;
