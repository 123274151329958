import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditCategoryModal } from "./AddEditCategoryModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";

export const Category = () => {
  const apiname = process.env.REACT_APP_CATEGORY_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedCategory, setSelectedCategory]: any = useState([]);
  const multi = selectedCategory?.map((e: any) => {
    return e.CategoryId;
  });
  useEffect(() => {
    // console.log(multi, "smultiCategory")
    // console.log(typeof (multi), "typeof")
    // console.log(selectedCategory.length)
  }, [selectedCategory]);

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };


  const filterMatchModeOptions = {
    'equals': 'Equals',
    'contains': 'Contains',
    'startsWith': 'Starts With',
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "3%",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionCategoryTemplate(e),
      width: "7%",
    },
    {
      field: "CategoryName",
      header: "Category Name",
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by category name",

      body: null,
      width: "10vw",
    },
    {
      field: "CategoryOrder",
      header: "Category Order",
      filterPlaceholder: "Search by category order",
      sortable: true,
      dataType: "numeric",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.CategoryOrder);
      },
      width: "10vw",
    },
    {
      field: "ExpectedVisit",
      header: "Expected Visit",
      // filterPlaceholder:"Search by category order",
      sortable: true,
      // dataType:"numeric",
      // filter: true,
      body: (data: any) => {
        return NullHandler(data.ExpectedVisit);
      },
      width: "10vw",
    },
  ];

  const filterJson = {
    CategoryName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CategoryOrder: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.CategoryId}
          editModal={() => {
            setupdateId(id.CategoryId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Category</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Category"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/doctorattributes"}
          linkflagtitle={"Doctor Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedCategory?.length === 0 ? null : multi);
            }
          }}
          filterJson={filterJson}
          setselecteditem={setSelectedCategory}
          selecteditem={selectedCategory}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />
    
        {updateId !== null && (
          <AddEditCategoryModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
