import AdminPanelCard from "../common/AdminPanelCard";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const ChemistAttributes = () => {
  let admincard = [
    {
      name: "Category",
      link: "/layout/master/chemist/ChemistCategory",
    },
    {
      name: "Contact Person",
      link: "/layout/submaster/chemistContactPerson",
    },
    {
      name: "Type",
      link: "/layout/submaster/chemistType",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Chemist Attributes</title>
      </Helmet>
      <div className="d-flex row ml-4 admin-panel">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Chemist Attributes</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/attributes"}>Attributes</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Chemist Attributes
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard name={card?.name} link={card?.link} />
          </div>
        ))}
      </div>
    </>
  );
};
