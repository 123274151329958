import { useEffect } from "react";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

const AddEditDoctorBankDetailsModal = ({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_DOCTORBANKDETAILS_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
  }: any = useForm({ mode: "all" });

  const bankDetailsForm = (data: any) => {
    data.DoctorId = Number(doctorid);

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Doctor Bank Details`}
        style={{ width: "1000px" }}
        modal
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(bankDetailsForm);
              }
            }}
            onSubmit={handleSubmit(bankDetailsForm)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Bank Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("BankName", e.target.value);
                  clearErrors(["BankName"]);
                }}
                register={{
                  ...register("BankName", {
                    required: {
                      value: true,
                      message: "Bank Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s']{1,50}$/,
                      message:
                        "Enter a valid bank name (1-50 alphabet characters)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.BankName?.message ||
                  error?.response?.data?.errors?.BankName?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Account No."
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("AccountNo", e.target.value);
                  clearErrors(["AccountNo"]);
                }}
                register={{
                  ...register("AccountNo", {
                    required: {
                      value: true,
                      message: "Account No. is required",
                    },
                    pattern: {
                      value: /^\d{11,16}$/,
                      message: "Enter a valid account no. (11-16 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.AccountNo?.message ||
                  error?.response?.data?.errors?.AccountNo?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="IFSC Code"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("IFsccode", e.target.value);
                  clearErrors(["IFsccode"]);
                }}
                register={{
                  ...register("IFsccode", {
                    required: {
                      value: true,
                      message: "IFSC Code is required",
                    },
                    pattern: {
                      value: /^\w[A-Z0-9]{10}$/,
                      message:
                        "Enter a valid ifsc code (11 alphanumeric characters (A-Z0-9))",
                    },
                  }),
                }}
                errorMessage={
                  errors?.IFsccode?.message ||
                  error?.response?.data?.errors?.IFsccode?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-4"
                label="Account Holder Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("AccountHolderName", e.target.value);
                  clearErrors(["AccountHolderName"]);
                }}
                register={{
                  ...register("AccountHolderName", {
                    required: {
                      value: true,
                      message: "Account Holder Name is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s']{1,50}$/,
                      message:
                        "Enter a valid account holder name (1-50 alphabet characters)",
                    },
                  }),
                }}
                minLength={2}
                maxLength={50}
                errorMessage={
                  errors?.AccountHolderName?.message ||
                  error?.response?.data?.errors?.AccountHolderName?.map(
                    (e: any) => e
                  )
                }
              />

<div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <Spinner width={"15rem"} height={"15rem"} />
        )}
      </Dialog>
    </>
  );
};

export default AddEditDoctorBankDetailsModal;
