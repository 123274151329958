import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import ReactSelect from "../../../common/ReactSelect";
import PaymentInvoice from "../../../common/PaymentInvoice";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";

const AddPaymentSalesInvoice = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const apiname =
    process.env.REACT_APP_INVENTORYPURCHASEINVOICE_API?.toString();

  const [selectedInvoiceType, setSelectedInvoiceType]: any = useState(null);
  const [selectedInvoice, setSelectedInvoice]: any = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  }: any = useForm();

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname);

  const [paymentArray, setPaymentArray]: any = useState([]);

  // Payment Sales Invoice
  const onPaymentSalesSubmit = (data: any) => {
    let submitData = {};

    let dataFirst = { ...data };

    let payment = paymentArray.map((e: any) => {
      return { ...e, PaymentMethodId: e?.PaymentMethodId?.value };
    });

    submitData = {
      PurchaseInvoiceToCreate: dataFirst,
      Payments: payment,
    };

    mutate(submitData);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/inventory/PaymentSalesInvoice");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);
  return (
    <div>
      <Helmet>
        <title>Add Payment Sales Invoice</title>
      </Helmet>
      <PageHeader
        headTitle="Payment Sales Invoice"
        headTitleURL={"/layout/inventory/SalesInvoice"}
        list={false}
      />

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onPaymentSalesSubmit)}>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="Invoice Type"
              name={"InvoiceTypeId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setSelectedInvoiceType(e);
                  setValue("InvoiceTypeId", e?.value);
                } else {
                  setSelectedInvoiceType(null);
                  setValue("InvoiceTypeId", null);
                }
              }}
              value={selectedInvoiceType}
              control={control}
              api={process.env.REACT_APP_INVENTORYVENDOR_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.InvoiceTypeId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Invoice Type is required" },
              }}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Invoice"
              name={"InvoiceId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setSelectedInvoice(e);
                  setValue("InvoiceId", e?.value);
                } else {
                  setSelectedInvoice(null);
                  setValue("InvoiceId", null);
                }
              }}
              value={selectedInvoice}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.InvoiceId?.message}
              isRequired
              rules={{
                required: { value: true, message: "Invoice is required" },
              }}
            />
          </div>

          {/* Payment Details */}
          <PaymentInvoice
            paymentArray={paymentArray}
            setPaymentArray={setPaymentArray}
          />

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/inventory/PaymentSalesInvoice"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>

            {id === undefined ? (
              <button
                type="button"
                className="btn btn-outline-danger mt-2 mx-2"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </button>
            ) : (
              ""
            )}
            {isLoading ? (
              <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                Submit <Spinner width={"1rem"} height={"1rem"} />
              </button>
            ) : (
              <Button type="submit" color="primary" text="Submit"></Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPaymentSalesInvoice;
