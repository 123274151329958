import React, { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import DatatableCommon from "../../../../common/DatatableCommon";
import { AddEditSpecialDay } from "./AddEditSpecialDay";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const SpecialDay = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname = process.env.REACT_APP_SPECIALDAY_API?.toString();

  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionSpecialDayTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "Doctor",
      header: "Doctor",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
    },
    {
      field: "SpecialDayName",
      header: "Special Day Name",
      body: null,
      sortable: true,
    },
    {
      field: "Date",
      header: "Date",
      body: (e: any) => actionDateTemplate(e),
      sortable: true,
      dataType: "date"
    },

  ];

  const [updateId, setupdateId]: any = useState(null);
  const actionDateTemplate = (id: any) => {
    return id.Date?.split("T")[0];
  };

  const actionSpecialDayTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.SpecialDayId}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.SpecialDayId);
          }}
          onClick={() => modelFunction(apiname, id.SpecialDayId)}
        />
      </>
    );
  };

  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);

  return (
    <>
      <Accordion>
        <AccordionTab header="Special Day">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Special Day`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              selecteditem={selectedRow}
              setselecteditem={setselectedRow}
              pdfTitle="Special Day"
            />
          </div>
        </AccordionTab>
      </Accordion>
      {updateId !== null && (
        <AddEditSpecialDay
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
