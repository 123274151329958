import React, { useState } from 'react'
import { Action } from '../../../common/Action';
import { Helmet } from 'react-helmet';
import DatatableCommon from '../../../common/DatatableCommon';
import { PageHeader } from '../../../common/PageHeader';
import { AddEditEmployeeDeviceMap } from '../employeeDeviceMap/AddEditEmployeeDeviceMap';
import { DeleteModal } from '../../../common/DeleteModal';
import { AddEditRouteEmployeeMapping } from './AddEditRouteEmployeeMapping';

export const RouteEmployeeMapping = () => {
    const apiname = process.env.REACT_APP_ROUTEEMPLOYEEMAPPING_API?.toString();
    const [bool, setbool]: any = useState(null);

    const [deleteID, setdeleteID]: any = useState();
    const setDeleteidtoDelete = (id: any) => {
      setdeleteID(id);
    };

    const [selectedRow, setselectedRow]: any = useState([]);

    const columns = [
        {
          header: "Action",
          body: (e: any) => actionEmployeeRouteTemplate(e),
          sortable: false,
          width:"1vw",
        },
          {
            field: "RouteName",
            header: "Route Name",
            bodyClassName: "text-info h6",
            body: null,
            sortable: true,
            width:"10vw",
          },
          {
            field: "EmployeeName",
            header: "Employee Name",
            bodyClassName: "text-success h6",
            body: null,
            sortable: true,
            width:"10vw",
          }
        ];

        const [updateId, setupdateId]: any = useState(null);
    
        const actionEmployeeRouteTemplate = (id: any) => {
          return (
            <>
              <Action
                id={id.Id}
                editModal={() => {
                  setupdateId(id.Id);
                }}
                displayid={setDeleteidtoDelete}
              />
            </>
          );
        };
      

  return (
    <>
      <Helmet>
        <title>Employee Route Map</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Employee Route Map"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
        />
        {updateId !== null && (
          <AddEditRouteEmployeeMapping
          setisOPen={() => {
            return setupdateId(null);
          }}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
          open={updateId !== null}
          id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />

    </>
  )
}
