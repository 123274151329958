import React, { useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { NullHandler } from "../../../common/NullHandler";


export const RolePermission = () => {
  const apiname = process.env.REACT_APP_ROLE_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const columns = [
    {
      header: "Action",
      body: (e: any) => actionRolePermissionTemplate(e),
      sortable: false,
      width:"1vw",
    },
    {
      field: "RoleName",
      header: "Role Name",
      body: null,
      sortable: true,
    },
    {
      field: "RolePermissionModel.PermissionName",
      header: "Role Permission",
      body: (e: any) => actionPermissionTemplate(e),
      sortable: true,
    },
   
  ];

  const  actionPermissionTemplate = (id: any) => {
    return id.RolePermissionModel.length !==0 ? id.RolePermissionModel?.map((ab: any) => { return ab.PermissionName }).join(", ") : " - "
}

  const actionRolePermissionTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.RoleId}
          editurl={"layout/admin/permission/editrolepermission"}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Role Permission</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Role Permission"
          list={true}
          LinkURL={"/layout/admin/permission/addrolepermission"}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          // check={true}
        />
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
