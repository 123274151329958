import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Input } from "../../../common/Input";
import { Button } from "primereact/button";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

const AddSampleRequestModal = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) => {
  const apiname =
    process.env.REACT_APP_SAMPLEEMPLOYEE_CREATESAMPLEREQUEST_API?.toString();
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname);

  const onSubmit = (data: any) => {
    data.ProductId = Number(data.ProductId?.value?.value);
    data.Qty = Number(data.Qty);
    // const formdata = new FormData();
    // formdata.append("ProductId", data.ProductId);
    // formdata.append("Qty", data.Qty);
    // mutate(formdata);

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );

    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <div>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={"Add Sample Request"}
        modal
        style={{ width: "1100px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              control={control}
              isRequired
              api={process.env.REACT_APP_PRODUCT_API}
              keys={{ label: "Value", value: "Id" }}
              onChange={(e: any) => {
                setValue("ProductId", e);
                setSelectedProduct(e);
              }}
              value={selectedProduct}
              name="ProductId"
              label="Product"
              rules={{
                required: {
                  value: true,
                  message: "Product is required",
                },
              }}
              errorMessage={errors?.ProductId?.message}
            />

            <Input
              containerClass="col-lg-6"
              label="Quantity"
              type="text"
              isRequired
              onChange={(e: any) => {
                setValue("Qty", e.target.value);
                clearErrors(["Qty"]);
              }}
              register={{
                ...register("Quantity", {
                  required: {
                    value: true,
                    message: "Quantity is Required",
                  },
                }),
              }}
              errorMessage={
                errors?.Quantity?.message ||
                error?.response?.data?.errors?.Quantity?.map((e: any) => e)
              }
            />
          </div>
          <div className="col-lg-12 text-center mt-3">
            <Button
              type="button"
              severity="secondary"
              outlined
              onClick={() => setisOPen(false)}
            >
              <span className="p-button-label p-c">Back</span>
            </Button>

            {id === 0 && (
              <Button
                type="reset"
                severity="danger"
                onClick={() => {
                  reset();
                  setSelectedProduct(null);
                }}
                outlined
                className="ml-2"
              >
                <span className="p-button-label p-c">Reset</span>
              </Button>
            )}

            {isLoading ? (
              <Button type="submit" outlined disabled className="ml-2">
                <span className="p-button-label p-c">
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </span>
              </Button>
            ) : (
              <Button type="submit" outlined className="ml-2">
                <span className="p-button-label p-c">Submit</span>
              </Button>
            )}
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddSampleRequestModal;
