import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Spinner } from "../../../../common/Spinner";
import { Button } from "primereact/button";
import ReactSelect from "../../../../common/ReactSelect";
import { Input } from "../../../../common/Input";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

const AddEditMonthlyAllowance = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_MONTHLYALLOWANCE_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const role = localStorage.getItem("Roles");

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setselectedemployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        });
        setselectedCalculationType({
          label: data.CalculationTypeName,
          value: data.CalculationTypeId,
        });
        setselectedAllowanceType({
          label: data.AllowancesTypeName,
          value: data.AllowancesTypeId,
        });
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(
    id === 0 ? `${apiname}/Create` : apiname,
    id === 0 ? undefined : id
  );

  const [selectedemployee, setselectedemployee]: any = useState(null);
  const [selectedCalculationType, setselectedCalculationType]: any =
    useState(null);
  const [selectedAllowanceType, setselectedAllowanceType]: any = useState(null);

  const monthlyallowanceFormData = (data: any) => {
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    console.log(newData, "newData");
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Monthly Allowance`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(monthlyallowanceFormData);
              }
            }}
            onSubmit={handleSubmit(monthlyallowanceFormData)}
          >
            <div className="row">
              {role !== "MR" && (
                <ReactSelect
                  containerClass="col-lg-4"
                  label="Employee"
                  name={"EmployeeId"}
                  isRequired
                  onChange={(e: any) => {
                    setselectedemployee(e);
                    setValue("EmployeeId", e?.value);
                  }}
                  value={selectedemployee}
                  control={control}
                  api={process.env.REACT_APP_EMPLOYEE_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.EmployeeId?.message ||
                    error?.response?.data?.errors?.EmployeeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: { value: true, message: "Employee is required" },
                  }}
                />
              )}

              <ReactSelect
                containerClass="col-lg-4"
                label="Allowances Type"
                name={"AllowancesTypeId"}
                isRequired
                onChange={(e: any) => {
                  setselectedAllowanceType(e);
                  setValue("AllowancesTypeId", e?.value);
                }}
                value={selectedAllowanceType}
                control={control}
                api={process.env.REACT_APP_ALLOWANCETYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.AllowancesTypeId?.message ||
                  error?.response?.data?.errors?.AllowancesTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Allowance Type is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Calculation Type"
                name={"CalculationTypeId"}
                isRequired
                onChange={(e: any) => {
                  setselectedCalculationType(e);
                  setValue("CalculationTypeId", e?.value);
                }}
                value={selectedCalculationType}
                control={control}
                api={process.env.REACT_APP_CALCULATIONTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.CalculationTypeId?.message ||
                  error?.response?.data?.errors?.CalculationTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Calculation Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Criteria"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Criteria", e.target.value);
                  clearErrors(["Criteria"]);
                }}
                register={{
                  ...register("Criteria", {
                    required: {
                      value: true,
                      message: "Criteria is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Criteria?.message ||
                  error?.response?.data?.errors?.Criteria?.map((e: any) => e)
                }
                maxLength={10}
              />

              <Input
                containerClass="col-lg-4"
                label="Dr Visit Criteria Days"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("DrVisitCriteriaDays", e.target.value);
                  clearErrors(["DrVisitCriteriaDays"]);
                }}
                register={{
                  ...register("DrVisitCriteriaDays", {
                    required: {
                      value: true,
                      message: "Dr Visit Criteria Days is required",
                    },
                    pattern: {
                      value: /^\d{0,7}$/,
                      message:
                        "Enter a valid dr. visit criteria days (1-7 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.DrVisitCriteriaDays?.message ||
                  error?.response?.data?.errors?.DrVisitCriteriaDays?.map(
                    (e: any) => e
                  )
                }
              />

              <div className="col-lg-4">
                <div className="row">
                  <div className="col-md-4">
                    <div className="custom-control custom-checkbox pt-5">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        {...register("ProRata")}
                        id="ProRata"
                      />
                      <label className="custom-control-label" htmlFor="ProRata">
                        Pro Rate
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="custom-control custom-checkbox pt-5">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        {...register("ConsiderVisitCriteria")}
                        id="ConsiderVisitCriteria"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="ConsiderVisitCriteria"
                      >
                        Consider Visit Criteria
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedAllowanceType(null);
                      setselectedCalculationType(null);
                      setselectedemployee(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditMonthlyAllowance;
