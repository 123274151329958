import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import PaymentInvoice from "../../../common/PaymentInvoice";
import PurchaseSaleInvoiceCommon from "../../../common/PurchaseSaleInvoiceCommon";
import Textarea from "../../../common/Textarea";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import Select from "react-select";

let options: any = [
  {
    value: "Doctor",
    label: "Doctor",
  },
  {
    value: "Chemist",
    label: "Chemist",
  },
  {
    value: "Stockist",
    label: "Stockist",
  },
  {
    value: "Customer",
    label: "Customer",
  },
];

const AddEditSalesInvoice = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_INVENTORYSALESINVOICE_API?.toString();
  const apiname2 = process.env.REACT_APP_SALESRETURNINVOICE_API?.toString();

  let [AlertIndex, SetAlertIndex] = useState(false)


  const [selectedwarehouse, setselectedwarehouse]: any = useState(null);
  const [selectedChemist, setSelectedChemist]: any = useState(null);
  const [selectedDoctor, setSelectedDoctor]: any = useState(null);
  const [selectedStockist, setSelectedStockist]: any = useState(null);
  const [selectedCustomer, setSelectedCustomer]: any = useState(null);
  const [selectReturnType, setselectedReturnType]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    reset,
  }: any = useForm();

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (id) {
      if (data) {

        setselectedwarehouse({
          label: data?.WarehouseName,
          value: data?.WarehouseId,
        });
        setValue("WarehouseId", data.WarehouseId);

        if (data.CustomerId !== null) {
          setFirstDropdownValue({
            label: "Customer",
            value: "Customer",
          });
          setSelectedCustomer({
            label: data.CustomerName,
            value: data.CustomerId,
          });
          setValue("CustomerId", data.CustomerId);
        }

        if (data.ChemistId !== null) {
          setFirstDropdownValue({
            label: "Chemist",
            value: "Chemist",
          });

          setSelectedChemist({
            label: data.ChemistName,
            value: data.ChemistId,
          });
          setValue("ChemistId", data.ChemistId);
        }

        if (data.DoctorId !== null) {
          setFirstDropdownValue({
            label: "Doctor",
            value: "Doctor",
          });

          setSelectedDoctor({
            label: data.DoctorName,
            value: data.DoctorId,
          });
          setValue("DoctorId", data.DoctorId);
        }

        if (data.StockistId !== null) {
          setFirstDropdownValue({
            label: "Stockist",
            value: "Stockist",
          });

          setSelectedStockist({
            label: data.StockistName,
            value: data.StockistId,
          });
          setValue("StockistId", data.StockistId);
        }

        let purchaseData = data?.ProductDetails?.map((e: any, i: number) => {
          return {
            ProductId: {
              label: e.ProductName,
              value: e.ProductId,
            },
            Quantity: e.Quantity,
            BatchNo: e.BatchNo,
            SampleQuantity: e.SampleQuantity,
            ExpiryDate: e.ExpiryDate,
            ManufactureDate: e.ManufactureDate,
            Discount: e.Discount,
            MRP: e.MRP,
            PurchasePrice: e.PurchasePrice,
            // ReturnableQuantity:e.ReturnableQuantity,
            FreeQty: e.FreeQuantity,
            SalePrice: e.SalePrice,
            No: i + 1,
          };
        });

        setSalesDetailArray(purchaseData);
      }
    }

  }, [data]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    id == undefined ? apiname : apiname2
  );

  const [paymentArray, setPaymentArray]: any = useState([]);
  const [salesDetailArray, setSalesDetailArray]: any = useState([]);
  useEffect(() => {
    debugger;
    if (salesDetailArray.length == 0) {
      SetAlertIndex(true);
    } else {
      SetAlertIndex(false);
    }
  }, [salesDetailArray]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleTabChange = (accordion: any, e: any) => {
    if (accordion === activeAccordion && e.index === activeIndex) {
      setActiveAccordion(null);
      setActiveIndex(null);
    } else {
      setActiveAccordion(accordion);
      setActiveIndex(e.index);
    }
  };

  // Sales Invoice
  const onSalesSubmit = (data: any) => {
    debugger;
    let submitData = {};
    if (firstDropdownValue?.value === "Chemist") {
      delete data.StockistId;
      delete data.CustomerId;
      delete data.DoctorId;
    } else if (firstDropdownValue?.value === "Stockist") {
      delete data.ChemistId;
      delete data.CustomerId;
      delete data.DoctorId;
    } else if (firstDropdownValue?.value === "Customer") {
      delete data.ChemistId;
      delete data.DoctorId;
      delete data.StockistId;
    } else if (firstDropdownValue?.value === "Doctor") {
      delete data.ChemistId;
      delete data.StockistId;
      delete data.CustomerId;
    } else {
      delete data.ChemistId;
      delete data.StockistId;
      delete data.CustomerId;
      delete data.DoctorId;
    }

    debugger;
    let dataFirst = {};
    if (id === undefined) {
      dataFirst = {
        ...data,
        ChemistId: data.ChemistId?.value,
        DoctorId: data.DoctorId?.value,
        Discount: Number(data?.Discount),
        ExtraCharges: Number(data?.ExtraCharges),
      };
    } else {
      dataFirst = {
        ...data,
        ChemistId: data?.ChemistId,
        DoctorId: data?.DoctorId,
        Discount: Number(data.Discount),
        ExtraCharges: Number(data.ExtraCharges),
        SalesInvoiceId: Number(id),
      };
    }

    let salesArray = salesDetailArray.map((e: any) => {
      return {
        ...e,
        ProductId: e?.ProductId?.value,
        BatchNo: id === undefined ? e.BatchNo.label : e.BatchNo,
        Discount: Number(e.Discount),
        FreeQty: Number(e.FreeQty),
        MRP: Number(e.MRP),
        Quantity: Number(e.Quantity),
        SalePrice: Number(e.SalePrice),
      };
    });

    let payment = paymentArray.map((e: any) => {
      return {
        ...e,
        PaymentMethodId: e?.PaymentMethodId?.value,
        Amount: Number(e.Amount),
      };
    });

    if (id === undefined) {
      submitData = {
        SalesInvoiceToCreate: dataFirst,
        SalesInvoiceDetails: salesArray,
        Payments: payment,
      };
    } else {
      submitData = {
        SalesReturnInvoiceToCreate: dataFirst,
        SalesReturnInvoiceDetails: salesArray,
      };
    }

    if (!AlertIndex) {
      mutate(submitData);
    }
    // else {
    //   toast.error('There are some unsaved changes, please save it before you submit.', { position: "top-right" });
    // }


    else {
      if (salesDetailArray.length !== 0) {
        toast.error(
          "There are some unsaved changes, please save it before you submit.",
          { position: "top-right" }
        );
      } else {
        toast.error("You must Add Atleast One product", {
          position: "top-right",
        });
      }
    }




  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/inventory/SalesInvoice");
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  const [firstDropdownValue, setFirstDropdownValue]: any = useState(null);
  const handleFirstDropdownChange = (e: any) => {
    setFirstDropdownValue(e);
  };

  return (
    <div>
      <Helmet>
        <title>
          {id == undefined ? "Add Sales Invoice" : "Add Sales Return Invoice"}{" "}
        </title>
      </Helmet>

      <PageHeader
        headTitle={id == undefined ? "Sales Invoice" : "Sales Return Invoice"}
        headTitleURL={"/layout/inventory/SalesInvoice"}
        list={false}
      />

      <div className="pd-20 card-box mb-30">
        <form onSubmit={handleSubmit(onSalesSubmit)}>
          <div className="row">
            {id !== undefined && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Return Type"
                name={"ReturnTypeId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setselectedReturnType(e);
                    setValue("ReturnTypeId", e?.value);
                  } else {
                    setselectedReturnType(null);
                    setValue("ReturnTypeId", null);
                  }
                }}
                value={selectReturnType}
                control={control}
                api={process.env.REACT_APP_INVENTORYRETURNTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.ReturnTypeId?.message}
                isRequired
                rules={{
                  required: { value: true, message: "Return Type is required" },
                }}
              />
            )}

            <div className="col-lg-3">
              <div className="form-group">
                <label className="col-form-label">
                  Party <span className="text-danger">*</span>
                </label>
                <Select
                  options={options}
                  className="z-3"
                  isClearable
                  classNamePrefix={"z-3"}
                  onChange={(e: any) =>
                    handleFirstDropdownChange(e)
                  }
                  value={firstDropdownValue}
                  isDisabled={id !== undefined}
                />
              </div>
            </div>

            {firstDropdownValue?.value === "Doctor" && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Doctor"
                name={"DoctorId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setSelectedDoctor(e);
                    setValue("DoctorId", e?.value);
                  } else {
                    setSelectedDoctor(null);
                    setValue("DoctorId", null);
                  }
                }}
                value={selectedDoctor}
                control={control}
                api={process.env.REACT_APP_DOCTORDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.DoctorId?.message}
                isRequired
                Disabled={id !== undefined}
                rules={{
                  required: { value: true, message: "Doctor is required" },
                }}
              />
            )}

            {firstDropdownValue?.value === "Chemist" && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Chemist"
                name={"ChemistId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setSelectedChemist(e);
                    setValue("ChemistId", e?.value);
                  } else {
                    setSelectedChemist(null);
                    setValue("ChemistId", null);
                  }
                }}
                value={selectedChemist}
                control={control}
                api={process.env.REACT_APP_CHEMIST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.ChemistId?.message}
                isRequired
                Disabled={id !== undefined}
                rules={{
                  required: { value: true, message: "Chemist is required" },
                }}
              />
            )}

            {firstDropdownValue?.value === "Stockist" && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Stockist"
                name={"StockistId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setSelectedStockist(e);
                    setValue("StockistId", e?.value);
                  } else {
                    setSelectedStockist(null);
                    setValue("StockistId", null);
                  }
                }}
                value={selectedStockist}
                control={control}
                api={process.env.REACT_APP_STOCKIST_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.StockistId?.message}
                isRequired
                Disabled={id !== undefined}
                rules={{
                  required: { value: true, message: "Stockist is required" },
                }}
              />
            )}

            {firstDropdownValue?.value === "Customer" && (
              <ReactSelect
                containerClass="col-lg-3"
                label="Customer"
                name={"CustomerId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setSelectedCustomer(e);
                    setValue("CustomerId", e?.value);
                  } else {
                    setSelectedCustomer(null);
                    setValue("CustomerId", null);
                  }
                }}
                value={selectedCustomer}
                control={control}
                api={process.env.REACT_APP_INVENTORYCUSTOMER_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.CustomerId?.message}
                isRequired
                Disabled={id !== undefined}
                rules={{
                  required: { value: true, message: "Customer is required" },
                }}
              />
            )}

            <ReactSelect
              containerClass="col-lg-3"
              label="Warehouse"
              name={"WarehouseId"}
              onChange={(e: any) => {
                if (e?.value !== undefined) {
                  setselectedwarehouse(e);
                  setValue("WarehouseId", e?.value);
                } else {
                  setselectedwarehouse(null);
                  setValue("WarehouseId", null);
                }
              }}
              value={selectedwarehouse}
              control={control}
              api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.WarehouseId?.message}
              isRequired
              Disabled={id !== undefined}
              rules={{
                required: { value: true, message: "Warehouse is required" },
              }}
            />

            <Input
              containerClass="col-lg-3"
              label="Extra Charges"
              type="text"
              onChange={(e: any) => {
                setValue("ExtraCharges", e.target.value);
                clearErrors(["ExtraCharges"]);
              }}
              register={{
                ...register("ExtraCharges"),
              }}
              errorMessage={
                errors?.ExtraCharges?.message ||
                error?.response?.data?.errors?.ExtraCharges?.map((e: any) => e)
              }
            />

            <Input
              containerClass="col-lg-3"
              label="Discount %"
              type="text"
              onChange={(e: any) => {
                setValue("Discount", e.target.value);
                clearErrors(["Discount"]);
              }}
              register={{
                ...register("Discount"),
              }}
              errorMessage={
                errors?.Discount?.message ||
                error?.response?.data?.errors?.Discount?.map((e: any) => e)
              }
            />

            <Textarea
              containerClass="col-lg-6"
              label="Note"
              register={{
                ...register("Note"),
              }}
              maxLength={500}
              onChange={(e: any) => {
                setValue("Note", e.target.value);
                clearErrors(["Note"]);
              }}
              errorMessage={
                errors?.Note?.message ||
                error?.response?.data?.errors?.Note?.map((e: any) => e)
              }
              style={{ height: "75px" }}
            />
          </div>

          {/* Sales Invoice Details */}
          <PurchaseSaleInvoiceCommon
            Party={firstDropdownValue}
            purchaseDetailArray={salesDetailArray}
            setPurchaseDetailArray={setSalesDetailArray}
            activeIndex={activeAccordion === "Accordion1" ? activeIndex : null}
            onTabChange={(e: any) => handleTabChange("Accordion1", e)}
            type={id == undefined ? "sales" : "salesreturn"}
            selectedwarehouse={selectedwarehouse}
            SetAlertIndex={SetAlertIndex}
          />

          {/* Payment Details */}

          {id == undefined && (
            <PaymentInvoice
              paymentArray={paymentArray}
              setPaymentArray={setPaymentArray}
              activeIndex={
                activeAccordion === "Accordion2" ? activeIndex : null
              }
              SetAlertIndex={SetAlertIndex}
              onTabChange={(e: any) => handleTabChange("Accordion2", e)}
            />
          )}

          <div className="col-lg-12 text-center mt-3">
            <Link
              to={"/layout/inventory/SalesInvoice"}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Back
            </Link>

            {/* {id === undefined ? (
              <button
                type="button"
                className="btn btn-outline-danger mt-2 mx-2"
                onClick={() => {
                  reset();
                  setselectedwarehouse(null);
                }}
              >
                Reset
              </button>
            ) : (
              ""
            )} */}
            {isLoading ? (
              <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                Submit <Spinner width={"1rem"} height={"1rem"} />
              </button>
            ) : (
              <Button type="submit" color="primary" text="Submit"></Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditSalesInvoice;
