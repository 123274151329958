import { useState } from "react";
import { Action } from "../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import AddEditAttendee from "./AddEditAttendee";
import { DeleteModal } from "../../../common/DeleteModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Badge } from "primereact/badge";
import { NullHandler } from "../../../common/NullHandler";

const Attendee = () => {
  const apiname = process.env.REACT_APP_ATTENDEE_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const [bool, setbool]: any = useState(null);
  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const [updateId, setupdateId]: any = useState(null);

  const filterJson = {
    AttendeeTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DoctorName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    PatientName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ContactName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    EventName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3%",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionEventTemplate(e),
      width: "2vw",
    },
    {
      field: "EventName",
      header: "Event Name",
      sortable: true,
      bodyClassName: "text-success h6",
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by Event name",
    },
    {
      field: "AttendeeTypeName",
      header: "Attendee Type",
      sortable: true,
      body: (data: any) => {
        return (
          <Badge
            value={data.AttendeeTypeName}
            size="normal"
            severity={
              data.AttendeeTypeName === "Doctor"
                ? "success"
                : data.AttendeeTypeName === "Patient"
                  ? "info"
                  : "danger"
            }
          ></Badge>
        );
      },
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by attendee type",
    },
    {
      field: "DoctorName",
      header: "Doctor Name",
      sortable: true,
      bodyClassName: "text-success h6",
      body: (data: any) => {
        return NullHandler(data.DoctorName);
      },
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by doctor name",
    },
    {
      field: "PatientName",
      header: "Patient Name",
      sortable: true,
      bodyClassName: "text-info h6",
      body: (data: any) => {
        return NullHandler(data.PatientName);
      },
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by patient name",
    },
    {
      field: "ContactName",
      header: "Contact Name",
      sortable: true,
      bodyClassName: "text-danger h6",
      body: (data: any) => {
        return NullHandler(data.ContactName);
      },
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by contact name",
    },
  ];

  const actionEventTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Attendee</title>
      </Helmet>

      <div className="min-height-200px">
        <PageHeader
          headTitle="Attendee"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditAttendee
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default Attendee;
