interface iPropsspinner {
  width?: any;
  height?: any;
}

export const Spinner = ({width,height}:iPropsspinner) => {
  return (
    <div className="spinner-border" style={{width:width,height:height}} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};
