import { useEffect, useState } from "react";
import { Action } from "../../common/Action";
import { DeleteModal } from "../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../common/PageHeader";
import DatatableCommon from "../../common/DatatableCommon";
import AddEditProduct from "./AddEditProduct";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Badge } from "primereact/badge";
import { Link, useNavigate } from "react-router-dom";
import { Chart } from "primereact/chart";
import { Button } from "../../common/Button";
import axiosInstance from "../../_helper/Interceptor";
import { Bar } from "react-chartjs-2";
import { SelectButton } from "primereact/selectbutton";
import ReactSelect from "../../common/ReactSelect";
import { Input } from "../../common/Input";
import { useForm } from "react-hook-form";
import Select from "react-select";

export default function Product() {
  const controller = new AbortController();
  const apiname = process.env.REACT_APP_PRODUCT_API?.toString();
  let navigate = useNavigate();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [selectedProduct, setSelectedProduct]: any = useState([]);
  useEffect(() => {}, [selectedProduct]);

  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const {
    formState: { errors },
    control,
  }: any = useForm({ mode: "all" });

  let division: any = localStorage.getItem("Division");
  division = JSON.parse(division || {});

  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  let [divId, setDivId]: any = useState(null);

  const [selected, setSelected]: any = useState(null);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 1;
    const endingYear = currentYear;
    console.log(startingYear, endingYear, "year");
    setDivId({ value: 0, label: "All" });
    setSelected({
      value: `${startingYear}-${endingYear}`,
      label: `${startingYear}-${endingYear}`,
    });
  }, []);

  const saleoption = ["SALES", "QUANTITY"];
  const [salevalue, setSaleValue]: any = useState(saleoption[0]);

  let [ProductData, setProductData] = useState([]);

  const gettop10ProductsData = () => {
    axiosInstance
      .get(
        `v1/Reporting/Dashboard/${
          salevalue == "SALES"
            ? "GetTop10ProductSalesQtyData"
            : "GetTop10ProductSalesData"
        }?StartDate=${startDate}&EndDate=${endDate}${
          divId?.value == 0 ? "" : `&DivisionId=${divId?.value}`
        }`,
        { signal: controller.signal }
      )
      .then((e: any) => {
        console.log(e.data.Data);
        setProductData(e?.data?.Data || []);
      })
      .catch((err) => {
        if (controller.signal.reason.code == 20) {
          console.log("Request Canceled", err);
        } else {
          console.log(err, "this is error");
          setProductData([]);
        }
      });
  };

  const topProductOption: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },
    },
  };

  const topProductData = {
    labels: ProductData.map((e: any) => {
      return e.name;
    }),
    datasets: [
      {
        label: "Product",
        data: ProductData?.map((e: any) => {
          if (salevalue == "SALES") {
            return e.Amount;
          } else {
            return e.Qty;
          }
        }),
        backgroundColor: salevalue == "SALES" ? "#B9A37C" : "#E8B252",
        barThickness: 50,
      },
    ],
  };

  useEffect(() => {
    if (selected == null) {
      return;
    }
    let temp = selected?.value;
    let startdate = `${temp?.split("-")[0]}-04-01`;
    let enddate = `${temp?.split("-")[1]}-03-31`;
    setStartDate(startdate);
    setEndDate(enddate);
  }, [selected]);

  function generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;
    const endingYear = currentYear;

    const years = [];
    for (let i = startingYear; i <= endingYear; i++) {
      years.push({
        label: `${i}-${i + 1}`,
        value: `${i}-${i + 1}`,
      });
    }
    return years;
  }

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && divId !== null) {
      gettop10ProductsData();
    }
    return () => {
      controller.abort();
    };
  }, [startDate, endDate, divId, salevalue]);

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ShortName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DivisionName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductGroup: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DrugHead: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductLine: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductClass: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    PackingTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Mrp: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const handleModalOpen = (e: any, i: any) => {
    navigate("/layout/inventory/WarehouseProductHistory", {
      state: { name: e, id: i },
    });
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "2%",
    },
    {
      header: "Action",
      body: (e: any) => actionProductTemplate(e),
      sortable: false,
      // frozen: true,
      width: "6%",
    },
    {
      field: "Name",
      header: "Product Name | Code | Division",
      bodyClassName: "text-info h6",
      body: (rowData: any) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                <Link to={``} className="text-bold">
                  {rowData.Name.toUpperCase()}
                </Link>
              </div>
              <div className="ml-auto">
                <Badge
                  value={rowData.Code}
                  size="normal"
                  severity={"success"}
                />
              </div>
            </div>
            <div>
              <Badge
                value={rowData.DivisionName}
                size="normal"
                severity={"info"}
                style={{ backgroundColor: "#978EEB" }}
              />
            </div>
          </>
        );
      },
      mergeColums: true,
      mergeColumnsName: ["Name", "Code", "DivisionName"],
      sortable: true,
      // frozen: true,
      width: "30%",
      filter: true,
      filterPlaceholder: "Search by name",
    },
    {
      field: "ShortName",
      header: "Short Name",
      body: null,
      sortable: true,
      width: "20%",
      filter: true,
      filterPlaceholder: "Search by short name",
    },
    {
      field: "Mrp",
      header: "MRP | PTS | PTR",
      body: (id: any) => {
        return (
          <span className="text-success">
            {id?.Mrp?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}{" "}
            |{" "}
            {id?.Pts?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}{" "}
            |{" "}
            {id?.Ptr?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}
          </span>
        );
      },
      mergeColums: true,
      mergeColumnsName: ["Mrp", "Pts", "Ptr"],
      width: "20%",
    },
    {
      field: "New",
      header: "New | Upcoming | Key Product",
      body: (id: any) => {
        return `${
          id?.New === false ? "No" : id?.New === true ? "Yes" : "-"
        } | ${
          id?.OnHold === false ? "No" : id?.OnHold === true ? "Yes" : "-"
        } | ${
          id?.KeyProduct === false
            ? "No"
            : id?.KeyProduct === true
            ? "Yes"
            : "-"
        }`;
      },
      mergeColums: true,
      mergeColumnsName: ["New", "OnHold", "KeyProduct"],
      sortable: false,
      width: "20%",
    },
    {
      header: "Product History",
      body: (data: any) => {
        return (
          <>
            {/* <button className="btn btn-secondary btn-sm">
              <Link
                to={`/layout/inventory/WarehouseProductHistory/${data.ProductId}`}
              >
                <span style={{ color: "white" }}>Show History</span>
              </Link>
            </button> */}

            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                handleModalOpen(data.Name, data.ProductId);
              }}
            >
              <span style={{ color: "white" }}>Show History</span>
            </button>
          </>
        );
      },
    },
  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionProductTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ProductId}
          editModal={() => {
            setupdateId(id.ProductId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> Product </title>
      </Helmet>

      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Product</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/adminpanel"}>Admin Panel</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Product
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6">
              <div className="pull-right">
                <Button
                  text={`Compititor Product`}
                  onclick={() => {
                    navigate("/layout/master/RCPA/CompetitorsProduct");
                  }}
                  color="secondary"
                />
              </div>
              <div className="pull-right">
                <Button
                  text={`Add Product`}
                  onclick={() => {
                    setupdateId(0);
                  }}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <ReactSelect
            label="Division"
            containerClass="col-lg-6"
            name={"DivisionId"}
            onChange={(e: any) => {
              setDivId(e);
            }}
            value={divId}
            control={control}
            api={process.env.REACT_APP_DIVISIONGETDASHBOARDLOOKUP_API}
            keys={{ label: "Value", value: "Id" }}
            errorMessage={errors?.DivisionId?.message}
          />
          <div className="col-lg-6">
            <div className="form-group">
              <label className="col-form-label">
                Financial Year <span className="text-danger">*</span>
              </label>
              <Select
                options={generateFinancialYears()}
                className="z-3"
                isClearable
                classNamePrefix={"z-3"}
                onChange={(e: any) => {
                  setSelected(e);
                }}
                value={selected}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card flex justify-content-center mx-3">
            <SelectButton
              value={salevalue}
              onChange={(e) => {
                setSaleValue(e.value);
              }}
              options={saleoption}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 mb-20">
            <div className="pd-20 card-box mb-30">
              <Bar
                height={80}
                options={topProductOption}
                data={topProductData}
              />
            </div>
          </div>
        </div>
        <DatatableCommon
          apiname={apiname}
          // check={true}
          setselecteditem={setSelectedProduct}
          selecteditem={selectedProduct}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditProduct
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
