import { useEffect, useState } from "react";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import Textarea from "../../../common/Textarea";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Spinner } from "../../../common/Spinner";
interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
export const AddEditAnnouncement = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_NOTIFICATION_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });
  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (data) {
      reset(data);
      data.NotificationUses = data?.NotificationUses?.map((e: any) => {
        return {
          label: e.UserName,
          value: e.Id,
        };
      });
      setValue("To", data.NotificationUses);
      setselectedemployee(data.NotificationUses);
      setContent(data.Body);
    }
  }, [data]);

  const [selectedemployee, setselectedemployee]: any = useState([]);

  const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(
    apiname,
    id === 0 ? undefined : id
  );

  const AnnouncementFormData = (data: any) => {

    if (content !== "") {
      data.Body = content;
    }
    //     data.Body = `

    //     <div style="width: 100%; display: inline-flex;">
    //     <div style="width: 100%; float: left;">
    //       <h1 style=" text-decoration: underline;  color: #263475; margin-left: 40%">New Product</h1>
    //       <h4 style="margin-left: 10px;">BIOXIGA L </h4>
    //       <p style="margin-left: 10px;">LINAGLIPTIN 5MG + DAPAGLIFLOZIN 10MG</p>
    //       <table style="border-collapse: collapse; width: 100%; margin-bottom: 10px; padding:"10px"">
    //           <tr>
    //               <td style=" border: rgb(160, 160, 160) 1px solid; 
    //                       text-align: left; padding: 8px;">MRP</td>
    //               <td style=" border: rgb(160, 160, 160) 1px solid; 
    //                       text-align: left; padding: 8px;">RS.135 </td>
    //           </tr>
    //           <tr>
    //               <td style=" border: rgb(160, 160, 160) 1px solid; 
    //                       text-align: left; padding: 8px;">HSN Code</td>
    //               <td style=" border: rgb(160, 160, 160) 1px solid; 
    //                       text-align: left; padding: 8px;">30049099</td>
    //           </tr>
    //       </table>
    //     </div>
    //     <div style="width: 100%; ">
    //       <img src="https://qaapi.aisante.in/media/Frame%201865.png" style="width: 100%; border:rgb(160, 160, 160) solid 1px; border-radius: 20px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; ">
    //     </div>
    // </div>    
    //     `;
    data.To = data.To.map((e: any) => e?.value);
    mutate(data);
  };

  const [content, setContent] = useState();

  const handleChange = (e: any) => {
    setContent(e);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Announcement`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form
            onSubmit={handleSubmit((data: any) => {
              AnnouncementFormData(data);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-12"
                label="To"
                isRequired
                name={"To"}
                onChange={(e: any) => {
                  setValue("To", e);
                  setselectedemployee(e);
                }}
                value={selectedemployee}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.To?.message ||
                  error?.response?.data?.errors?.To?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "To is required" },
                }}
                Multi
              />

              <Input
                containerClass="col-lg-12"
                label="Title"
                type="text"
                isRequired
                errorMessage={
                  errors?.Title?.message ||
                  error?.response?.data?.errors?.Title?.map((e: any) => e)
                }
                onChange={() => clearErrors(["Title"])}
                register={{
                  ...register("Title", {
                    required: {
                      value: true,
                      message: "Title is required",
                    },
                    pattern: {
                      value: /^\w[a-zA-Z\s'-,.]{2,100}$/,
                      message:
                        "Enter a valid chemistname (2-100 alphabet characters or special characters (a-zA-Z'-.,))",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Message"
                isRequired
                maxLength={1000}
                errorMessage={
                  errors?.Message?.message ||
                  error?.response?.data?.errors?.Message?.map((e: any) => e)
                }
                onChange={() => clearErrors(["Message"])}
                register={{
                  ...register("Message", {
                    required: { value: true, message: "Message is required" },
                  }),
                }}
              />

              <div className="col-lg-12 mb-3">
                <div className="form-group">
                  <label className="col-form-label">Body</label>
                  <ReactQuill
                    value={content}
                    style={{ height: "100px" }}
                    onChange={handleChange}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "link",
                      // "video",
                    ]}
                  />


                </div>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => reset()}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
