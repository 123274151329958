import { Helmet } from "react-helmet";
import Lottie from "lottie-react";
import animationData from '../assets/lottie/Animation - 1714136928308.json';
export const NotFound = () => {
    return (
        <>
            {/* <h1 className="text-center">404 Not Found Somthing Went Wrong...!</h1> */}
            <>
                <Helmet>
                    <title>Not Found</title>
                </Helmet>

                {/* <div
                    className="login-wrap d-flex align-items-center flex-wrap justify-content-center"
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        bottom: "0",
                        right: "0",
                    }}
                >
                    <div className="container"> */}
                {/* <div className="row align-items-center align-items-center">

                    <div className="col-md-12 col-lg-12 align-items-center"> */}
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                        style={{ width: 700, height: 700 }}
                    />
                </div>
                {/* </div> */}

                {/* </div>
                    </div> */}
                {/* </div> */}
            </>
        </>
    )
}