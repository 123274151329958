import { Helmet } from "react-helmet";

interface iPropstitle {
    title?: string;
}

export const Title = ({ title }: iPropstitle) => {
    return (
        <Helmet>
            <title> {title} </title>
        </Helmet>
    );
};