import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import Textarea from "../../../../common/Textarea";
import { Input } from "../../../../common/Input";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export default function AddEditProceduralEquipmentsModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname =
    process.env.REACT_APP_DOCTORPROCEDURALEQUIPMENT_API?.toString();
  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      console.log(data);
      const Date = data?.ProceduralEquipmentInstallationDate?.split("T")[0];
      reset(data);
      setValue("ProceduralEquipmentInstallationDate", Date);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const areaofinterestFormData = (data: any) => {
    data.DoctorId = Number(doctorid);
    data.ProceduralEquipmentPrice = Number(data.ProceduralEquipmentPrice);
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {

      console.log(error?.response?.data?.errors);
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Procedural Equipments`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(areaofinterestFormData);
              }
            }}
            onSubmit={handleSubmit(areaofinterestFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Name of Equipment"
                type="text"
                isRequired
                errorMessage={
                  errors?.ProceduralEquipmentName?.message ||
                  error?.response?.data?.errors?.ProceduralEquipmentName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ProceduralEquipmentName", e.target.value);
                  clearErrors(["ProceduralEquipmentName"]);
                }}
                register={{
                  ...register("ProceduralEquipmentName", {
                    required: {
                      value: true,
                      message: "Equipment Name is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid equipment name (2-50 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Vendor Name / Brand"
                type="text"
                maxLength={30}
                errorMessage={
                  errors?.ProceduralEquipmentVendorName?.message ||
                  error?.response?.data?.errors?.ProceduralEquipmentVendorName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ProceduralEquipmentVendorName", e.target.value);
                  clearErrors(["ProceduralEquipmentVendorName"]);
                }}
                register={{
                  ...register("ProceduralEquipmentVendorName"),
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Installation Date / Year"
                type="date"
                errorMessage={
                  errors?.ProceduralEquipmentInstallationDate?.message ||
                  error?.response?.data?.errors?.ProceduralEquipmentInstallationDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue(
                    "ProceduralEquipmentInstallationDate",
                    e.target.value
                  );
                  clearErrors(["ProceduralEquipmentInstallationDate"]);
                }}
                register={{
                  ...register("ProceduralEquipmentInstallationDate"),
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Price"
                type="text"
                maxLength={7}
                errorMessage={
                  errors?.ProceduralEquipmentPrice?.message ||
                  error?.response?.data?.errors?.ProceduralEquipmentPrice?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("ProceduralEquipmentPrice", e.target.value);
                  clearErrors(["ProceduralEquipmentPrice"]);
                }}
                // value={amount}
                register={{
                  ...register("ProceduralEquipmentPrice"),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                rows={3}
                maxLength={500}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Remarks", e.target.value);
                  clearErrors(["Remarks"]);
                }}
                register={{
                  ...register("Remarks"),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
