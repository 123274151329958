import React, { useEffect, useState } from "react";
import ComposeEmail from "./subComponents/ComposeEmail";
import SentItems from "./subComponents/SentItems";
import Drafts from "./subComponents/Drafts";
import Inbox from "./subComponents/Inbox";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Email = () => {
  const [currentSection, setCurrentSection] = useState("inbox");
  const handleEmailSent = (data: any) => {
    setCurrentSection(data); // Redirect to Sent Items after email is sent
  };

  const renderSection = () => {
    switch (currentSection) {
      case "compose":
        return <ComposeEmail onEmailSent={(e: any) => handleEmailSent(e)} />;
      case "sent":
        return <SentItems />;
      case "drafts":
        return <Drafts onEmailSent={((e: any) => handleEmailSent(e))} />;
      case "inbox":
      default:
        return <Inbox />;
    }
  };
  return (
    <>
    <Helmet>
      <title>Email</title>
    </Helmet>
      <div className="mb-20">
        <button
          className="btn btn-primary m-2"
          onClick={() => setCurrentSection("compose")}
        >
          <i className="fa fa-plus mr-2" aria-hidden="true"></i>
          Compose
        </button>
        <button
          className="btn btn-primary m-2"
          onClick={() => setCurrentSection("sent")}
        >
          Sent Items
        </button>
        <button
          className="btn btn-primary m-2"
          onClick={() => setCurrentSection("drafts")}
        >
          Drafts
        </button>
        <button
          className="btn btn-primary m-2"
          onClick={() => setCurrentSection("inbox")}
        >
          Inbox
        </button>
      </div>
      {renderSection()}
    </>
  );
};

export default Email;
