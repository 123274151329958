import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import axiosInstance from "../../../_helper/Interceptor";

const AddSampleRequest = () => {

    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_SAMPLEEMPLOYEE_CREATESAMPLEREQUEST_API?.toString();
    const [selectedProduct, setSelectedProduct]: any = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        clearErrors,
        reset,
    }: any = useForm();



    const { mutate, isSuccess, isError, error, isLoading }: any = useCreateByID(apiname);
    const onSubmit = (data: any) => {
        data.ProductId = Number(data.ProductId?.value?.value);
        data.Qty = Number(data.Qty);
        const formdata = new FormData();
        formdata.append("ProductId", data.ProductId)
        formdata.append("Qty", data.Qty)
        mutate(formdata);
    };

    const [giftOptions, setgiftOptions] = useState([]);
    const fetchgiftOptions = async () => {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GIFTMASTER_API}`
        );
        const data = response?.data?.Data?.map((option: any) => {
            return {
                label: option?.GiftName,
                value: option?.GiftMasterId,
            };
        });
        setgiftOptions(data);
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success("Added Successfully", { position: "top-right" });
            navigate("/layout/inventory/sampleRequest");
        }

        if (isError) {
            if (error?.response?.data?.StatusCode !== 400) {
                toast.error(error?.response?.data?.Message, { position: "top-right" });
            } else {
                toast.error("error...", { position: "top-right" });
            }
        }
    }, [isError, isSuccess]);


    return (
        <div>
            <Helmet>
                <title>
                    Add Sample Request
                </title>
            </Helmet>

            <PageHeader
                headTitle="Sample Request"
                headTitleURL={"/layout/inventory/sampleRequest"}
                list={false}
            />

            <div className="pd-20 card-box mb-30">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <ReactSelect
                            containerClass="col-lg-3"
                            control={control}
                            isRequired
                            api={process.env.REACT_APP_PRODUCT_API}
                            keys={{ label: "Value", value: "Id" }}
                            onChange={(e: any) => {

                                setValue("ProductId", e);
                                setSelectedProduct(e);
                            }}
                            value={selectedProduct}
                            name="ProductId"
                            label="Product"
                            rules={{
                                required: {
                                    value: true,
                                    message: "Product is required",
                                },
                            }}
                            errorMessage={errors?.ProductId?.message}
                        />
                        <Input
                            isRequired
                            containerClass="col-lg-3"
                            label="Quantity"
                            min={0}
                            type="number"
                            onChange={(e: any) => {
                                setValue("Qty", Number(e.target.value));
                                clearErrors(["Qty"]);
                            }}
                            register={{
                                ...register(`Qty`, {
                                    required: {
                                        value: true,
                                        message: "Quantity is required",
                                    },
                                }),
                            }}
                            errorMessage={errors?.Quantity?.message}
                        />
                        {/* <Select
                            {...register(`GiftId`, {
                                required: {
                                    value: true,
                                    message: "Gift is required",
                                }
                            })}
                            options={giftOptions}
                            className='z-3'
                            classNamePrefix={"z-3"}
                            onChange={(e: any) => { setValue(`GiftId`, e?.value) }}
                        />
                        <ErrorMessage message={errors?.GiftId?.message} /> */}

                    </div>

                    <div className="col-lg-12 text-center mt-3">
                        <Link
                            to={"/layout/inventory/sampleRequest"}
                            className="btn btn btn-outline-secondary mt-2 mx-2"
                        >
                            Back
                        </Link>
                        {isLoading ? (
                            <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                                Submit <Spinner width={"1rem"} height={"1rem"} />
                            </button>
                        ) : (
                            <Button type="submit" color="primary" text="Submit"></Button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSampleRequest;
