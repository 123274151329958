import { useFetchAll } from "../../../hooks/Api/Api";
import photos from "../../../assets/images/photo1.jpg";
import { useEffect, useState } from "react";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AddDCR } from "./AddDCR";
import moment from "moment";
import { CancelDCRModal } from "../../../common/CancelDCRModal";
import { Spinner } from "../../../common/Spinner";

const DCR = () => {
  const [id, setId] = useState(null);
  const [visitName, setvisitName] = useState("");
  const apiname = process.env.REACT_APP_DCR_API?.toString();
  const [UpdateDCRID, setUpdateDCRID]: any = useState(null);
  const [state, setState] = useState();
  const [visitData, setvisitData]: any = useState();
  const { data, isLoading: DCRLoader } = useFetchAll(apiname);
  const [time, setTime] = useState(new Date());

  const navigate = useNavigate();

  const date = Date();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (data) {
      setvisitData(data);
    }
  }, [data]);

  const handle = async () => {
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DCR_API}?date=${state}`)
      .then((res) => {
        setvisitData(res.data.Data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    if (state !== undefined) {
      handle();
    }
  }, [state]);

  const handleHospital = (e: any, i: any) => {
    navigate("/layout/mr/visitForm", {
      state: { name: e, id: i, state: "Hospital" },
    });
  };

  const handleDoctor = (e: any, i: any) => {
    navigate("/layout/mr/visitForm", {
      state: { name: e, id: i, state: "Doctor" },
    });
  };

  const handleChemist = (e: any, i: any) => {
    navigate("/layout/mr/ChemistvisitForm", {
      state: { name: e, id: i, state: "Chemist" },
    });
  };

  return (
    <>
      <Helmet>
        <title>DCR</title>
      </Helmet>
      <div className="col-md-12 col-sm-12 mb-30">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>DCR </h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/layout/dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    DCR
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="pull-right">
                {moment(time).format("M/D/YYYY, HH:mm:ss") <=
                moment(state === undefined ? date : state).format(
                  "M/D/YYYY, 09:00:00"
                ) ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      setUpdateDCRID(visitData?.TourPlanId);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="btn btn-secondary"
                    type="button"
                    disabled
                    onClick={() => {
                      setUpdateDCRID(visitData?.TourPlanId);
                    }}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pd-20 card-box">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Select Date:-</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e: any) => setState(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Areas:-{" "}
                  <span className="weight-600 text-blue">
                    {visitData?.Area}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-sm-12 mb-30">
        <div className="pd-20 card-box">
          <div className="tab">
            <ul className="nav nav-pills justify-content-center" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link text-blue active"
                  data-toggle="tab"
                  href="#home5"
                  role="tab"
                  aria-selected="true"
                >
                  Doctor
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-blue"
                  data-toggle="tab"
                  href="#profile5"
                  role="tab"
                  aria-selected="false"
                >
                  Hospital
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-blue"
                  data-toggle="tab"
                  href="#contact5"
                  role="tab"
                  aria-selected="false"
                >
                  Chemist
                </a>
              </li>
            </ul>

            {!DCRLoader ? (
              <>
                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="home5"
                    role="tabpanel"
                  >
                    <div className="user-list mt-30">
                      <ul key={"docotor"}>
                        {visitData?.DoctorList?.map((e: any, index: any) => {
                          return (
                            <li className="d-flex mt-30" key={index}>
                              <div className="col-1 text-center">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src={photos}
                                    className="border-radius-100 box-shadow"
                                    width={70}
                                    height={70}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-9">
                                <div className="font-14">
                                  Doctor Name:{" "}
                                  <span className="weight-600 text-blue">
                                    {" "}
                                    Dr.{e.DoctorName}
                                  </span>
                                </div>
                                <div className="font-14">
                                  Hospital Name:{" "}
                                  <span className="weight-600">
                                    {" "}
                                    {e.HospitalName}
                                  </span>
                                </div>
                                <div className="font-14">
                                  City Name:{" "}
                                  <span className="weight-600"> {e.City}</span>
                                </div>
                              </div>
                              <div className="col-2 text-right align-self-center">
                                {/* <button className="btn btn-sm btn-outline-primary" onClick={() => handleDoctor(e.DoctorName, e.DoctorVisitId)}>Open</button> */}
                                {e.VisitStatusName == "Open" ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-outline-primary"
                                      onClick={() =>
                                        handleDoctor(
                                          e.DoctorName,
                                          e.DoctorVisitId
                                        )
                                      }
                                    >
                                      Open
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-sm ml-2"
                                      onClick={() => {
                                        setId(e.DoctorVisitId);
                                        setvisitName("doctor");
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : e.VisitStatusName == "Pending" ? (
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    disabled
                                  >
                                    Pending
                                  </button>
                                ) : e.VisitStatusName == "Close" ? (
                                  <button
                                    className="btn btn-warning btn-sm"
                                    disabled
                                  >
                                    Closed
                                  </button>
                                ) : e.VisitStatusName == "Cancel" ? (
                                  <button
                                    className="btn btn-danger btn-sm"
                                    disabled
                                  >
                                    Cancelled
                                  </button>
                                ) : e.VisitStatusName == "Missed Call" ? (
                                  <button
                                    className="btn btn-info btn-sm"
                                    disabled
                                  >
                                    Missed
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile5" role="tabpanel">
                    <div className="user-list mt-30">
                      <ul key={"hospital"}>
                        {visitData?.HospitalList?.map((e: any, index: any) => {
                          return (
                            <li className="d-flex mt-30" key={index}>
                              <div className="col-1 text-center">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src={photos}
                                    className="border-radius-100 box-shadow"
                                    width={70}
                                    height={70}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-9">
                                <div className="font-14">
                                  Hospital Name:
                                  <span className="weight-600 text-blue">
                                    {" "}
                                    {e.HospitalName}{" "}
                                  </span>
                                </div>
                                <div className="font-14">
                                  City Name:
                                  <span className="weight-600">{e.City}</span>
                                </div>
                              </div>
                              <div className="col-2 text-right align-self-center">
                                {/* <button className="btn btn-sm btn-outline-primary" onClick={() => handleHospital(e.HospitalName, e.HospitalVisitId)}>Open</button> */}
                                {e.VisitStatusName == "Open" ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-outline-primary"
                                      onClick={() =>
                                        handleHospital(
                                          e.HospitalName,
                                          e.HospitalVisitId
                                        )
                                      }
                                    >
                                      Open
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-sm ml-2"
                                      onClick={() => {
                                        setId(e.HospitalVisitId);
                                        setvisitName("hospital");
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : e.VisitStatusName == "Pending" ? (
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    disabled
                                  >
                                    Pending
                                  </button>
                                ) : e.VisitStatusName == "Close" ? (
                                  <button
                                    className="btn btn-warning btn-sm"
                                    disabled
                                  >
                                    Closed
                                  </button>
                                ) : e.VisitStatusName == "Cancel" ? (
                                  <button
                                    className="btn btn-danger btn-sm"
                                    disabled
                                  >
                                    Cancelled
                                  </button>
                                ) : e.VisitStatusName == "Missed Call" ? (
                                  <button
                                    className="btn btn-info btn-sm"
                                    disabled
                                  >
                                    Missed
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="contact5" role="tabpanel">
                    <div className="user-list mt-30">
                      <ul key={"chemist"}>
                        {visitData?.ChemistList?.map((e: any, index: any) => {
                          return (
                            <li className="d-flex mt-30" key={index}>
                              <div className="col-1 text-center">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src={photos}
                                    className="border-radius-100 box-shadow"
                                    width={70}
                                    height={70}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-9">
                                <div className="font-14">
                                  Chemist Name:
                                  <span className="weight-600 text-blue">
                                    {" "}
                                    {e.ChemistName}{" "}
                                  </span>
                                </div>
                                <div className="font-14">
                                  City Name:
                                  <span className="weight-600"> {e.City} </span>
                                </div>
                              </div>
                              <div className="col-2 text-right align-self-center">
                                {/* <button className="btn btn-sm btn-outline-primary" onClick={() => handleChemist(e.ChemistName, e.ChemistVisitId)}>Open</button> */}
                                {e.VisitStatusName == "Open" ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-outline-primary"
                                      onClick={() =>
                                        handleChemist(
                                          e.ChemistName,
                                          e.ChemistVisitId
                                        )
                                      }
                                    >
                                      Open
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-sm ml-2"
                                      onClick={() => {
                                        setId(e.ChemistVisitId);
                                        setvisitName("chemist");
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : e.VisitStatusName == "Pending" ? (
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    disabled
                                  >
                                    Pending
                                  </button>
                                ) : e.VisitStatusName == "Close" ? (
                                  <button
                                    className="btn btn-warning btn-sm"
                                    disabled
                                  >
                                    Closed
                                  </button>
                                ) : e.VisitStatusName == "Cancel" ? (
                                  <button
                                    className="btn btn-danger btn-sm"
                                    disabled
                                  >
                                    Cancelled
                                  </button>
                                ) : e.VisitStatusName == "Missed Call" ? (
                                  <button
                                    className="btn btn-info btn-sm"
                                    disabled
                                  >
                                    Missed
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-12 text-center">
                <Spinner width={"10rem"} height={"10rem"} />
              </div>
            )}
          </div>
        </div>
      </div>

      <AddDCR
        id={UpdateDCRID}
        setisOPen={() => setUpdateDCRID(null)}
        fetch={(data: boolean) => {
          if (data) {
            handle();
          }
        }}
        open={UpdateDCRID != null}
      />

      <CancelDCRModal
        id={Number(id)}
        jsonData={visitName}
        setisOPen={() => setId(null)}
        open={id != null}
        apiname={apiname}
      />
    </>
  );
};

export default DCR;
