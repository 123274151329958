import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import { Spinner } from "../../common/Spinner";
import ReactSelect from "../../common/ReactSelect";
import { ErrorMessage } from "../../common/ErrorMessage";

export const PrimaryVsSecondary = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  }: any = useForm({ mode: "all" });

  const d = watch();

  const [isLoading, setIsLoading] = useState(false);

  // Division
  const [selecteddivision, setselecteddivision] = useState([]);

  // Zone
  const [selectedZone, setselectedZone] = useState([]);
  const zoneid = selectedZone?.map((e: any) => `id=${e?.value}`);

  // State
  const [selectedState, setselectedState] = useState([]);
  const stateid = selectedState?.map((e: any) => `id=${e?.value}`);

  // Region
  const [selectedrigion, setselectedrigion] = useState([]);

  // Stockist
  const [selecteddoctor, setselecteddoctor]: any = useState([]);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownload = async (data: any) => {

    const division = data.DivisionId?.map(
      (e: any) => `DivisionIds=${e?.value}`
    );
    const Zone = data.ZoneId?.map((e: any) => `ZoneIds=${e?.value}`);
    const State = data.StateId?.map((e: any) => `StateIds=${e?.value}`);
    const Region = data.RegionId?.map((e: any) => `RegionIds=${e?.value}`);
    const stockiest = data.StockistId?.map(
      (e: any) => `StockistIds=${e?.value}`
    );
    console.log(data, "data");
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL
        }PrimarySale/GetPrimaryVsSecondaryReport?FromDate=${data.FromDate
        }&ToDate=${data.ToDate}&ReportType=${data.ReportType}${division == undefined
          ? ""
          : `&${String(division)?.replaceAll(",", "&")}`
        }${Region == undefined ? "" : `&${String(Region)?.replaceAll(",", "&")}`
        }${State == undefined ? "" : `&${String(State)?.replaceAll(",", "&")}`
        }${Zone == undefined ? "" : `&${String(Zone)?.replaceAll(",", "&")}`}${stockiest == undefined
          ? ""
          : `&${String(stockiest)?.replaceAll(",", "&")}`
        }`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(
          response.data,
          data.ReportType === "Primary"
            ? `PrimarySales.xlsx`
            : data.ReportType === "Secondary"
              ? "SecondarySales.xlsx"
              : data.ReportType === "Vs"
                ? "Primary VS Secondary.xlsx"
                : ""
        );
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Primary Vs Secondary</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Primary Vs Secondary</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Primary Vs Secondary
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              label="Stockist"
              control={control}
              name="StockistId"
              api={process.env.REACT_APP_STOCKIST_API}
              Multi
              keys={{ label: "Value", value: "Id" }}
              onChange={(e: any) => {
                setselecteddoctor(e);
                setValue("StockistId", e);
              }}
              value={selecteddoctor}
              errorMessage={errors?.StockistId?.message}
            />

            <Input
              containerClass="col-lg-4"
              label="From Month"
              type="date"
              isRequired
              errorMessage={errors?.FromDate?.message}
              register={{
                ...register("FromDate", {
                  required: {
                    value: true,
                    message: "From Month is required",
                  },
                }),
              }}
            />

            <Input
              containerClass="col-lg-4"
              label="To Month"
              type="date"
              isRequired
              errorMessage={errors?.ToDate?.message}
              register={{
                ...register("ToDate", {
                  required: {
                    value: true,
                    message: "To Month is required",
                  },
                }),
              }}
            />

            <div className="col-lg-4">
              <div className="form-group">
                <label className="col-form-label">
                  Report Type <span className="text-danger">*</span>
                </label>
                <select
                  className="custom-select col-12"
                  {...register("ReportType", {
                    required: {
                      value: true,
                      message: "Report Type is required",
                    },
                    onchange,
                  })}
                >
                  <option value={1}>Primary</option>
                  <option value={2}>Secondary</option>
                  <option value={3}>Primary Vs Secondary</option>
                </select>
              </div>
              <ErrorMessage message={errors?.ReportType?.message} />
            </div>

            <ReactSelect
              containerClass="col-lg-4"
              label="Division"
              name={"DivisionId"}
              Multi
              onChange={(e: any) => {
                setselecteddivision(e);
                setValue("DivisionId", e);
              }}
              value={selecteddivision}
              control={control}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.DivisionId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Zone"
              control={control}
              name="ZoneId"
              Multi
              onChange={(e: any) => {
                setselectedZone(e);
                setValue("ZoneId", e);
                if (e.length === 0) {
                  setselectedState([]);
                  setselectedrigion([]);
                }
              }}
              value={selectedZone}
              api={process.env.REACT_APP_ZONE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ZoneId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="State"
              control={control}
              name="StateId"
              Multi
              onChange={(e: any) => {
                setselectedState(e);
                setValue("StateId", e);
                if (e.length === 0) {
                  setselectedrigion([]);
                }
              }}
              value={selectedState}
              api={`State/GetAllStateByMultipleZoneId?${String(
                zoneid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              errorMessage={errors?.StateId?.message}
              Disabled={String(zoneid)?.replaceAll(",", "&") ? false : true}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Region"
              control={control}
              name="RegionId"
              Multi
              onChange={(e: any) => {
                setselectedrigion(e);
                setValue("RegionId", e);
              }}
              value={selectedrigion}
              api={`Region/GetAllRegionByMultipleStateId?${String(
                stateid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              errorMessage={errors?.RegionId?.message}
              Disabled={String(stateid)?.replaceAll(",", "&") ? false : true}
            />
          </div>

          <div className="col-md-12 text-center mt-30">
            {!isLoading ? (
              <button className="btn btn-outline-primary" type="submit">
                Download
              </button>
            ) : (
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled
              >
                Download <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
