import React, { useEffect, useState } from 'react'
import { Action } from '../../../../common/Action';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import DatatableCommon from '../../../../common/DatatableCommon';
import { DeleteModal } from '../../../../common/DeleteModal';
import AddEditTravelAllowance from './AddEditTravelAllowance';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { NullHandler } from "../../../../common/NullHandler";

const TravelAllowance = () => {

  const apiname = process.env.REACT_APP_TRAVELLINGALLOWANCE_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedRows, setSelectedRows]: any = useState([]);
  const multi = selectedRows?.map((e: any) => {
    return e.TravellingAllowanceId;
  });

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const filterJson = {
    EmployeeCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    EmployeeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    OrderNo: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    Condition: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    Criteria1: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    Criteria2: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    RatePerKm: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    EveryXkm: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  }

  const columnData = [
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionTravelAllowanceTemplate(e),
      width: "2rem",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      filter:true,
      bodyClassName: "text-info h6",
      filterPlaceholder:"Search by employee name",
      sortable: true,
      body: null,
      width: "13rem",
    },
    {
      field: "EmployeeCode",
      header: "Employee Code",
      filter:true,
      filterPlaceholder:"Search by employee code",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.EmployeeCode);
      },
      width: "13rem",
    },
    {
      field: "Type",
      header: "Calculation Type",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Type);
      },
      filter:true,
      filterPlaceholder:"Search by calculation type",
      width: "13rem",
    },
    {
      field: "OrderNo",
      header: "Order No.",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.OrderNo);
      },
      filter:true,
      dataType:"numeric",
      filterPlaceholder:"Search by order no.",
      width: "12rem",
    },
    {
      field: "Condition",
      header: "Condition",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Condition);
      },
      filter:true,
      filterPlaceholder:"Search by condition",
      width: "7rem",
    },
    {
      field: "Criteria1",
      header: "Criteria1",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Criteria1);
      },
      filter:true,
      dataType:"numeric",
      filterPlaceholder:"Search by criteria1",
      width: "7rem",
    },
    {
      field: "Criteria2",
      header: "Criteria2",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Criteria2);
      },
      filter:true,
      dataType:"numeric",
      filterPlaceholder:"Search by criteria2",
      width: "7rem",
    },
    {
      field: "RatePerKm",
      header: "Rate Per Km",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.RatePerKm);
      },
      dataType:"numeric",
      filter:true,
      filterPlaceholder:"Search by rate per km",
      width: "11rem",
    },
    {
      field: "EveryXkm",
      header: "Every X km",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.EveryXkm);
      },
      filter:true,
      filterPlaceholder:"Search by every x km",
      width: "11rem",
    },
    {
      field: "BaseValue",
      header: "Base Value",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.BaseValue);
      },
      width: "10rem",
    },
    {
      field: "Hq",
      header: "Hq",
      sortable: true,
      body: (data:any)=>{return data.Hq===false ? "False" : "True"},
      width: "5rem",
    },
    {
      field: "ExHq",
      header: "Ex Hq",
      sortable: true,
      body: (data:any)=>{return data.ExHq===false ? "False" : "True"},
      width: "7rem",
    },
    {
      field: "Os",
      header: "Os",
      sortable: true,
      body: (data:any)=>{return data.Os===false ? "False" : "True"},
      width: "5rem",
    },
   
  ];

  const [updateId, setupdateId]: any = useState(null);
  const actionTravelAllowanceTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.TravellingAllowanceId}
          editModal={() => {
            setupdateId(id.TravellingAllowanceId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Travelling Allowance</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Travelling Allowance"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedRows?.length === 0 ? null : multi);
            }
          }}
          setselecteditem={setSelectedRows}
          filterJson={filterJson}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditTravelAllowance
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      /> 
    </>
  )
}

export default TravelAllowance
