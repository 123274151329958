import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Button } from "../../../common/Button";
import { useEffect, useState } from "react";
import axiosInstance from "../../../_helper/Interceptor";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { displayName } from "react-quill";

const DailyTourPlan = () => {
  const controller = new AbortController();
  const navigate = useNavigate();
  const [data, setData]: any = useState({});
  const getTodayDailyTourPlan = () => {
    axiosInstance
      .get("v1/Reporting/DailyTourPlan/GetTodayDailyTourPlanByMr", { signal: controller.signal })
      .then((e: any) => {
        console.log(e.data.Data);
        if (e.data.Data === null) {
          navigate("/layout/mr/admin/addDailyTouPlan");
        } else {
          setData(e.data.Data);
        }
      }).catch((err) => {
        if (controller.signal.reason.code == 20) {
          console.log('Request Canceled', err)
        }
      });
  };

  useEffect(() => {
    getTodayDailyTourPlan();
    return () => {
      controller.abort();
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Daily Tour Plan</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Daily Tour Plan</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Daily Tour Plan
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <div className="col-md-12">
            <table className="table table-bordered table-hover table-striped">
              <tbody>
                <tr>
                  <td>Route</td>
                  <td>{data?.RouteName}</td>
                </tr>
                <tr>
                  <td>Sub Route</td>
                  <td>{data?.SubRouteName}</td>
                </tr>
                <tr>
                  <td>Doctor List</td>
                  <td>
                    {data?.doctorVisitViewModels?.length !== 0
                      ? data?.doctorVisitViewModels?.map((e: any, i: any) => {
                        return (
                          // <li>
                          <span style={{ display: "block" }}>
                            <strong>
                              {(i + 1) + ") "}
                            </strong>
                            {e.DoctorName} -{" "}
                            {moment(e.ExpectedVisitTime).format(
                              "DD MMM, YYYY  HH:mm A"
                            )}
                          </span>
                          // </li>
                        );
                      })
                      : `${data?.doctorVisitViewModels?.length} Doctor Selected`}
                  </td>
                </tr>
                <tr>
                  <td>Chemist List</td>
                  <td>
                    {data?.chemistVisitViewModels?.length !== 0
                      ? data?.chemistVisitViewModels?.map((e: any, i: any) => {
                        return (
                          <span style={{ display: "block" }}>
                            <strong>
                              {(i + 1) + ") "}
                            </strong>
                            {e.ChemistName} -{" "}
                            {moment(e.ExpectedVisitTime).format(
                              "DD MMM, YYYY  HH:mm A"
                            )}
                          </span>
                        );
                      })
                      : `${data?.chemistVisitViewModels?.length} Chemist Selected`}
                  </td>
                </tr>
                <tr>
                  <td>Stockist List</td>
                  <td>
                    {data?.stockistVisitViewModels?.length !== 0
                      ? data?.stockistVisitViewModels?.map((e: any, i: any) => {
                        return (
                          <span style={{ display: "block" }}>
                            <strong>
                              {(i + 1) + ") "}
                            </strong>
                            {e.StockistName} -{" "}
                            {moment(e.ExpectedVisitTime).format(
                              "DD MMM, YYYY  HH:mm A"
                            )}
                          </span>
                        );
                      })
                      : `${data?.stockistVisitViewModels?.length} Stockist Selected`}
                  </td>
                </tr>
                <tr>
                  <td>Hospital List</td>
                  <td>
                    {data?.hospitalVisitViewModels?.length !== 0
                      ? data?.hospitalVisitViewModels?.map((e: any, i: any) => {
                        return (
                          <span style={{ display: "block" }}>
                            <strong>
                              {(i + 1) + ") "}
                            </strong>
                            {e.HospitalName} -{" "}
                            {moment(e.ExpectedVisitTime).format(
                              "DD MMM, YYYY  HH:mm A"
                            )}
                          </span>
                        );
                      })
                      : `${data?.hospitalVisitViewModels?.length} Hospital Selected`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-12 text-center">
            <Button
              text="Edit"
              color="warning"
              onclick={() => {
                navigate(
                  `/layout/mr/admin/editDailyTouPlan/${data.DailyTourPlanId}`
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyTourPlan;
