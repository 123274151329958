import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";
import { Button } from "../../../common/Button";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { toast } from "react-toastify";
import inventoryenum from "../../../_helper/Enum";

const AddVendorCreditNote = () => {
  const navigate = useNavigate();
  const apiname =
    process.env.REACT_APP_INVENTORYVENDORCREATEVENDORCREDITNOTE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    reset,
  }: any = useForm();
  const { mutate, isSuccess, isError, error, isLoading }: any =
    useCreateByID(apiname);

  const [selectedVendor, setselectedVendor]: any = useState(null);
  const [selectedInvoice, setselectedInvoice]: any = useState(null);

  const onVendorCreditNoteSubmit = (data: any) => {
    data.Amount = parseFloat(data.Amount);
    console.log(data, "Vendor credit note");
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/inventory/VendorCreditNote");
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <div>
      <div>
        <Helmet>
          <title>Add Vendor Credit Note</title>
        </Helmet>

        <PageHeader
          headTitle={"Vendor Credit Note"}
          headTitleURL={"/layout/inventory/VendorCreditNote"}
          list={false}
        />

        <div className="pd-20 card-box mb-30">
          <form onSubmit={handleSubmit(onVendorCreditNoteSubmit)}>
            <div className="row">
              <ReactSelect
                containerClass="col-lg-3"
                label="Invoice"
                name={"InvoiceId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setselectedInvoice(e);
                    setValue("InvoiceId", e?.value);
                  } else {
                    setselectedInvoice(null);
                    setValue("InvoiceId", null);
                  }
                }}
                value={selectedInvoice}
                flag
                control={control}
                api={`${process.env.REACT_APP_INVENTORYINVOICE_API}?invoiceType=${inventoryenum.PurchaseReturn}`}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.InvoiceId?.message}
                isRequired
                rules={{
                  required: { value: true, message: "Invoice is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-3"
                label="Vendor"
                name={"VendorId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setselectedVendor(e);
                    setValue("VendorId", e?.value);
                  } else {
                    setselectedVendor(null);
                    setValue("VendorId", null);
                  }
                }}
                value={selectedVendor}
                control={control}
                api={process.env.REACT_APP_INVENTORYVENDOR_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.VendorId?.message}
                isRequired
                rules={{
                  required: { value: true, message: "Vendor is required" },
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Credit Note No."
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("CreditNoteNo", e.target.value);
                  clearErrors(["CreditNoteNo"]);
                }}
                register={{
                  ...register("CreditNoteNo", {
                    required: {
                      value: true,
                      message: "Credit Note No. is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.CreditNoteNo?.message ||
                  error?.response?.data?.errors?.CreditNoteNo?.map(
                    (e: any) => e
                  )
                }
              />

              <Input
                containerClass="col-lg-3"
                label="Amount"
                type="number"
                isRequired
                onChange={(e: any) => {
                  setValue("Amount", e.target.value);
                  clearErrors(["Amount"]);
                }}
                register={{
                  ...register("Amount", {
                    required: { value: true, message: "Amount is required" },
                  }),
                }}
                errorMessage={
                  errors?.Amount?.message ||
                  error?.response?.data?.errors?.Amount?.map((e: any) => e)
                }
              />
            </div>

            <div className="col-lg-12 text-center mt-3">
              <Link
                to={"/layout/inventory/VendorCreditNote"}
                className="btn btn btn-outline-secondary mt-2 mx-2"
              >
                Back
              </Link>

              <button
                type="button"
                className="btn btn-outline-danger mt-2 mx-2"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </button>

              {isLoading ? (
                <button className="btn btn-outline-primary mt-2 mx-2" disabled>
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              ) : (
                <Button type="submit" color="primary" text="Submit"></Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVendorCreditNote;
