import React, { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import { AddEditFamilyFriendsDetailsModal } from "./AddEditFamilyFriendsDetailsModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { Button } from "primereact/button";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const FamilyFriendsDetails = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname =
    process.env.REACT_APP_DOCTORFAMILYANDFRIENDDETAIL_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);

  const filterJson = {
    FamilyAndFriendName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    FamilyAndFriendRelationName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    FamilyAndFriendSpeicialDay: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    }
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "FamilyAndFriendName",
      bodyClassName: "text-info h6",
      header: "Name",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by name",
    },
    {
      field: "FamilyAndFriendRelationName",
      header: "Relation",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by relation name",
    },
    {
      field: "FamilyAndFriendDateOfBirth",
      header: "DOB",
      body: (e: any) => actionDateTemplate(e),
      sortable: true,
      dataType: "date"
    },
    {
      field: "FamilyAndFriendDateOfAnniversary",
      header: "DOA",
      // body : null,
      body: (e: any) => actionDOATemplate(e),
      sortable: true,
      dataType: "date"
    },
    {
      field: "FamilyAndFriendSpeicialDay",
      header: "Special Day",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by special day",
    },
    {
      field: "FamilyAndFriendSpeicialDate",
      header: "Special Date",
      body: (e: any) => actionspTemplate(e),
      sortable: true,
      dataType: "date"
    }
  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionDateTemplate = (id: any) => {
    return id.FamilyAndFriendDateOfBirth?.split("T")[0];
  };

  const actionDOATemplate = (id: any) => {
    // return moment(id.FamilyAndFriendDateOfAnniversary).format("YYYY-MM-DD HH:mm:ss");
    return id.FamilyAndFriendDateOfAnniversary?.split("T")[0];
  };

  const actionspTemplate = (id: any) => {
    return id.FamilyAndFriendSpeicialDate?.split("T")[0];
  };

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.FamilyAndFriendDetailsId}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.FamilyAndFriendDetailsId);
          }}
          onClick={() => modelFunction(apiname, id.FamilyAndFriendDetailsId)}
        />
      </>
    );
  };

  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);

  return (
    <>
      <Accordion>
        <AccordionTab header="Family / Friends Details">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Family / Friends Details`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              selecteditem={selectedRow}
              setselecteditem={setselectedRow}
              filterJson={filterJson}
              pdfTitle="Family Friends Details"
            />
          </div>
        </AccordionTab>
      </Accordion>
      {updateId !== null && (
        <AddEditFamilyFriendsDetailsModal
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
