import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const ViewReport = () => {
  const location = useLocation();

  console.log(location.state);

  const [gridData, setgridData]: any = useState();

  const { setValue }: any = useForm({ mode: "all" });

  const [doctor, setDoctor] = useState({
    name: "",
    year: "",
    id: null,
  });

  const fetchData = async (year: any, docId: any) => {
    const apiname = `${process.env.REACT_APP_BASEURL
      }${process.env.REACT_APP_GETDOCTORWISECRMREPORT_API?.toString()}?FinancialYear=${year}&DoctorId=${docId}`;
    await axiosInstance
      .get(apiname)
      .then((response) => {

        console.log(response, "response");
        setgridData(response.data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {

    if (location.state) {
      setDoctor({ ...location.state });
      setValue("DoctorId", location.state.name);
      fetchData(location.state.year.value, location.state.id);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>View Report</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>View Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {gridData?.crmReportViewModels.length > 0 ? (
        <div className="pd-20 card-box mb-30 table-responsive">
          <div className="col-md-6 col-sm-12 mb-3">
            <h4>
              Doctor Name:- <span className="text-blue">{doctor.name}</span>
            </h4>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Months</th>
                <th scope="col">Date</th>
                <th scope="col">Credits</th>
                <th scope="col">Total Credits</th>
                <th scope="col">Total Debits</th>
                <th scope="col">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {gridData?.crmReportViewModels?.map((e: any, index: any) => {
                return (
                  <>
                    <tr>
                      <td>{e.Months}</td>
                      <td>
                        {e?.DateTime?.split(",").map(
                          (value: any, subIndex: any) => {
                            return (
                              <li key={subIndex}>{value.split(" ")[0]}</li>
                            );
                          }
                        )}
                      </td>
                      <td>
                        {e?.Credits?.split(",").map(
                          (value: any, subIndex: any) => {
                            return <li key={subIndex}>{value}</li>;
                          }
                        )}
                      </td>
                      <td>{e.TotalCredit}</td>
                      <td>{e.TotalDebit}</td>
                      {/* {(index == gridData.length - 1 ? e.flag ? 
                                                <td className="text-danger">({e.TotalCredit})</td>
                                                :
                                                <td className={"text-success"}>{e.TotalCredit}</td>
                                                :
                                                <td>{e.TotalCredit}</td>
                                            )}
                                              {(index == gridData.length - 1 ? e.flag ? 
                                                <td className="text-danger">({e.TotalDebit})</td>
                                                :
                                                <td className={"text-success"}>{e.TotalDebit}</td> :
                                                <td>{e.TotalDebit}</td> 
                                            )} */}
                      {/* <td className={(index == gridData.length - 1 ? e.flag ? "(text-danger)" : "text-success" : "")}>{e.TotalCredit}</td>
                                            {/* <td style={{ backgroundColor: e.flag ? "green" : "red" }}>{e.TotalDebit}</td> */}
                      <td>
                        {e?.Remarks?.split("*").map(
                          (value: any, subIndex: any) => {
                            return <li key={subIndex}>{value}</li>;
                          }
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
              <tr>
                <td colSpan={3} style={{ textAlign: "end" }}>
                  Total
                </td>
                <td>{gridData?.TotalCredit}</td>
                <td>{gridData?.TotalDebit}</td>
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "end" }}>
                  Remaing
                </td>
                {gridData.flag ? (
                  <td colSpan={2} className={"text-success"}>
                    {gridData.TotalRemaining}
                  </td>
                ) : (
                  <td colSpan={2} className="text-danger">
                    ({gridData.TotalRemaining})
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          <div className="col-lg-12 text-center">
            <Link
              to={"/layout/crm/crmreport"}
              className="btn btn btn-outline-secondary btn-sm mt-2 mx-2"
            >
              {" "}
              Back{" "}
            </Link>
          </div>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <h4>No Data Found</h4>
          <div className="col-lg-12 text-center">
            <Link
              to={"/layout/crm/crmreport"}
              className="btn btn btn-outline-secondary btn-sm mt-2 mx-2"
            >
              {" "}
              Back{" "}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewReport;
