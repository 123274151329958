import { useEffect, useState } from "react";
import { Action } from "../../../../common/Action";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddEditAssociatedHospitalsModal from "./AddEditAssociatedHospitalsModal";
import DatatableCommon from "../../../../common/DatatableCommon";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";

interface iProps {
  doctorid?: number;
  modelFunction?: any;
  refetchData?: any;
  api?: any;
  resetstate?: any;
}

export const AssociatedHospitals = ({
  doctorid,
  modelFunction,
  refetchData,
  api,
  resetstate,
}: iProps) => {
  const apiname = process.env.REACT_APP_DOCTORATTACHEDHOSPITAL_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);

  const filterJson = {
    AssociatedHospitalName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CityName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    AssociatedHospitalRecusiveName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    AssociatedHospitalMonthly: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    AssociatedHospitalDaily: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    AssociatedHospitalWeekly: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionChemistCategoryTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "AssociatedHospitalName",
      header: "Name of Hospital",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by hospital name",
    },
    {
      field: "AssociatedHospitalAddress",
      header: "Address",
      body: null,
      sortable: true,
    },
    {
      field: "CityName",
      header: "Place (City)",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by city",
    },
    {
      field: "AssociatedHospitalRecusiveName",
      header: "Is Recursive",
      body: null,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by recursive name",
    },
    {
      field: "AssociatedHospitalMonthly",
      header: "Monthly",
      body: null,
      sortable: true,
      filter: true,
      // dataType:"numeric",
      filterPlaceholder: "Search by monthly",
    },
    {
      field: "AssociatedHospitalWeekly",
      header: "Weekly",
      body: null,
      sortable: true,
      filter: true,
      // dataType:"numeric",
      filterPlaceholder: "Search by weekly",
    },
    {
      field: "AssociatedHospitalDaily",
      header: "Daily",
      body: null,
      sortable: true,
      filter: true,
      // dataType:"numeric",
      filterPlaceholder: "Search by daily",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.AssociatedHospitalId}
          doctorid={doctorid}
          editModal={() => {
            setupdateId(id.AssociatedHospitalId);
          }}
          onClick={() => modelFunction(apiname, id.AssociatedHospitalId)}
        />
      </>
    );
  };

  useEffect(() => {
    if (refetchData == true && api == apiname) {
      setbool(true);
      resetstate(false);
    }
  }, [refetchData]);

  return (
    <>
      <Accordion>
        <AccordionTab header="Associated Hospital">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Button
                label={`Add Associated Hospital`}
                onClick={() => setupdateId(0)}
              />
            </div>
            <DatatableCommon
              apiname={apiname}
              columnData={columns}
              refetchData={bool}
              setrefetchData={setbool}
              doctorid={doctorid}
              filterJson={filterJson}
              selecteditem={selectedRow}
              setselecteditem={setselectedRow}
              pdfTitle="Associated Hospital"
            />
          </div>
        </AccordionTab>
      </Accordion>
      {updateId !== null && (
        <AddEditAssociatedHospitalsModal
          refetchData={refetchData}
          resetstate={resetstate}
          api={api}
          deleteFunction={modelFunction}
          setisOPen={() => {
            return setupdateId(null);
          }}
          open={updateId !== null}
          doctorid={doctorid}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}
    </>
  );
};
