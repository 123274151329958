import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../common/Action";
import { PageHeader } from "../../common/PageHeader";
import { DeleteModal } from "../../common/DeleteModal";
import DatatableCommon from "../../common/DatatableCommon";
import { NullHandler } from "../../common/NullHandler";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge } from "primereact/badge";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button"

export const MR = () => {
  const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const [confirmMsg, setConfirmMsg]: any = useState(false);
  const [lockDetail, setLockDetail]: any = useState(null);
  const multi = selectedRow?.map((e: any) => {
    return e.EmployeeId;
  });
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const lockUnlockEmployee = async (action: string, userId: number) => {
    const url = `${process.env.REACT_APP_BASEURL_MAIN}Account/${action === "unlock" ? "unlock-user" : "lock-user"
      }?userId=${userId}`;

    try {
      await axiosInstance.post(url);
      toast.success(`User ${action === "unlock" ? "Unlocked" : "Locked"}`, {
        position: "top-right",
      });
      setLockDetail(null)
      setConfirmMsg(false);
      setbool(true);
    } catch (error: any) {
      toast.error(error.response.data.Message, {
        position: "top-right",
      });
      setConfirmMsg(false);
    }
  };

  const lockUnlockTemplate = (e: any) => {
    return <button
      className="btn p-3"
      title={e.IsLocked ? "Unlock" : "Lock"}
      onClick={() => {
        setLockDetail({
          lock: e.IsLocked ? "unlock" : "lock",
          id: e.UserId

        })
        setConfirmMsg(true)
      }
      }
    >
      <i
        className={`bi ${e.IsLocked ? "bi-lock-fill" : "bi-unlock-fill"
          } text-primary`}
      ></i>
    </button>

  }
  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "2%",
    },
    {
      header: "Action",
      body: (e: any) => actionMRTemplate(e),
      sortable: false,
      width: "6%",
    },
    {
      header: "Lock / Unlock",
      body: (e: any) => lockUnlockTemplate(e),
      sortable: false,
      width: "6%",
    },
    {
      field: "Name",
      header: "BirthDate | Name | Blood Group ",
      bodyClassName: "text-info h6",
      body: (rowData: any) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                <Link to={``} className="text-bold">
                  {rowData.Name.toUpperCase()}
                </Link>
              </div>
              <div className="ml-auto">
                {rowData.BloodGroup !== null && (
                  <Badge
                    value={NullHandler(rowData.BloodGroup)}
                    size="normal"
                    severity={"success"}
                  />
                )}
              </div>
            </div>
            <div>
              {rowData.BirthDate !== null && (
                <Badge
                  value={NullHandler(
                    rowData.BirthDate == null
                      ? ""
                      : moment(rowData.BirthDate).format("DD MMM, YYYY")
                  )}
                  size="normal"
                  // severity={"info"}
                  style={{ backgroundColor: "#978EEB" }}
                />
              )}
            </div>
          </>
        );
      },
      mergeColums: true,
      mergeColumnsName: ["Name", "BloodGroup", "BirthDate"],
      // frozen: true,
      sortable: true,
      width: "20%",
      dataType: "date",
      dataTypePosition: 2,
    },
    {
      field: "Code",
      header: "Code",
      body: (data: any) => {
        return NullHandler(data.Code);
      },
      sortable: true,
      width: "20%",
    },
    {
      field: "Phone",
      header: "Phone No. | Mobile No. | Email",
      body: (data: any) => {
        return `${NullHandler(data.Phone)} | ${NullHandler(
          data.Mobile
        )} | ${NullHandler(data.Email)}`;
      },
      width: "30%",
      mergeColums: true,
      mergeColumnsName: ["Phone", "Mobile", "Email"],
    },

    {
      field: "CommunicationAddress",
      header: "Communication Address",
      body: (data: any) => {
        return (
          <p
            title={data.CommunicationAddress}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {data.CommunicationAddress === ""
              ? " - "
              : data.CommunicationAddress}
          </p>
        );
      },
      sortable: true,
      width: "22%",
    },
  ];

  const actionMRTemplate = (id: any) => {
    console.log(id, "lock");
    return (
      <>
        <Action
          id={id.EmployeeId}
          editurl={"layout/EditMR"}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> MR </title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="MR"
          list={true}
          LinkURL={"/layout/AddMR"}
          linkflag
          linkflagURL={"/layout/adminpanel"}
          linkflagtitle={"Admin Panel"}
        />
        <DatatableCommon
          apiname={apiname}
          selecteditem={selectedRow}
          setselecteditem={setselectedRow}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
        />
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
      <Dialog
        visible={confirmMsg}
        onHide={() => setConfirmMsg(false)}
        header={lockDetail?.lock == "unlock" ? "Confirm UnLock" : "Confirm Lock"}
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setConfirmMsg(false)}
            />
            <Button
              label={lockDetail?.lock == "unlock" ? "UnLock" : "Lock"}
              icon="pi pi-check"
              className="p-button-text"
              onClick={() => lockUnlockEmployee(lockDetail?.lock, lockDetail?.id)}
            />
          </div>
        }
      >

        Are you sure you want to {lockDetail?.lock == "unlock" ? "UnLock" : "Lock"}?
      </Dialog>

    </>
  );
};
