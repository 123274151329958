import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}

export const AddEditEventAttendance = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_EVENTATTENDANCE_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
    watch,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  const [selectedevent, setselectedevent] = useState({});
  const [selectedattendee, setselectedattendee] = useState({});
  const [selectedattendeestatus, setselectedattendeestatus] = useState({});

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const EventAttendanceFormData = (data: any) => {


    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};

    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Event Attendance`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(EventAttendanceFormData);
              }
            }}
            onSubmit={handleSubmit(EventAttendanceFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-6"
                label={"Event"}
                isRequired
                name={"EventId"}
                onChange={(e: any) => {
                  setselectedevent(e);
                  setValue("EventId", e?.value);
                }}
                value={selectedevent}
                control={control}
                api={process.env.REACT_APP_EVENT_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EventId?.message ||
                  error?.response?.data?.errors?.EventId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Event is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label={"Attendee"}
                isRequired
                name={"AttendeeId"}
                onChange={(e: any) => {
                  setselectedattendee(e);
                  setValue("AttendeeId", e?.value);
                }}
                value={selectedattendee}
                control={control}
                api={process.env.REACT_APP_ATTENDEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.AttendeeId?.message ||
                  error?.response?.data?.errors?.AttendeeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Attendee is required" },
                }}
              />

              <ReactSelect
                containerClass="col-lg-6"
                label={"Attendance Status"}
                isRequired
                name={"AttendanceStatusId"}
                onChange={(e: any) => {
                  setselectedattendeestatus(e);
                  setValue("AttendanceStatusId", e?.value);
                }}
                value={selectedattendeestatus}
                control={control}
                api={process.env.REACT_APP_ATTENDANCESTATUS_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.AttendanceStatusId?.message ||
                  error?.response?.data?.errors?.AttendanceStatusId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Attendee Status is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="Attendance Date"
                type="date"
                isRequired
                errorMessage={
                  errors?.AttendanceDate?.message ||
                  error?.response?.data?.errors?.AttendanceDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => { setValue("AttendanceDate", e.target.value); clearErrors(["AttendanceDate"]) }}
                register={{
                  ...register("AttendanceDate", {
                    required: {
                      value: true,
                      message: "Attendance Date is required",
                    },
                  }),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0 && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};
