import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { DeleteModal } from "../../../../common/DeleteModal";
import { PageHeader } from "../../../../common/PageHeader";
import { AddEditEntertainmentTypeModal } from "./AddEditEntertainmentTypeModal";
import DatatableCommon from "../../../../common/DatatableCommon";

export const EntertainmentType = () => {
  const apiname = process.env.REACT_APP_ENTERTAINMENTTYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);

  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.EntertainmentTypeId;
  });

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionEntertainmentTypeTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "EntertainmentTypeName",
      header: "Entertainment Type",
      body: null,
      sortable: true,
      bodyClassName: "text-info h6",
      width: "10vw",
    },
  ];

  const actionEntertainmentTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.EntertainmentTypeId}
          editModal={() => {
            setupdateId(id.EntertainmentTypeId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <>
      <Helmet>
        <title>Entertainment Type</title>
      </Helmet>

      <PageHeader
        headTitle="Entertainment Type"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
        linkflag
        linkflagURL={"/layout/doctorattributes"}
        linkflagtitle={"Doctor Attributes"}
      />

      <div className="pd-20 card-box mb-30">
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          // check={true}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
        />
        {updateId !== null && (
          <AddEditEntertainmentTypeModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
