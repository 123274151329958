import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import { Button } from "primereact/button";
import ReactSelect from "../../../common/ReactSelect";


interface iPropsmodal {
    id?: any;
    setisOPen?: any;
    open?: any;
    fetch?: any;
    defaultValue?: any;
}
export const AddEditHsn = ({
    id,
    setisOPen,
    open,
    fetch,
}: iPropsmodal) => {
    const apiname = process.env.REACT_APP_INVENTARYHSN_API?.toString();
    const [disabled, setDisabeld]: any = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        reset,
        control,
        getValues
    }: any = useForm({ mode: "all" });

    const { data, isLoading: SampleLoader }: any = useFetchAllID(
        apiname,
        id === 0 ? undefined : id
    );
    const [selectedHsnType, setSelectedHsnType]: any = useState();
    useEffect(() => {

        if (data) {
            reset(data);
            if (id) {
                if (data.Sgst !== null && data.Sgst !== undefined && data.Cgst !== null && data.Cgst !== undefined) {
                    setDisabeld("editIgst")
                }
                if (data.Igst !== null && data.Igst !== undefined && data.Igst !== null && data.Igst !== undefined) {
                    setDisabeld("editother")
                }
                setSelectedHsnType({
                    label: data.HSNTypeName,
                    value: data.HsntypeId,
                });

            }
        }
    }, [data]);

    const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
        data: response,
    }: any = useCreateByID(apiname, id === 0 ? undefined : id);



    const HsnFormData = (data: any) => {

        if (data.Igst == "") {
            delete data.Igst;
        }
        if (data.Sgst == "") {
            delete data.Sgst;
        }
        if (data.Cgst == "") {
            delete data.Cgst;
        }
        let submitData = {};
        if (id) {
            submitData = {
                HsnToUpdate: data
            }
        }
        else {

            submitData = {
                HsnToCreate: data
            }
        }
        mutate(submitData);
    };

    useEffect(() => {
        if (isSuccess) {
            if (id === 0) {
                toast.success("Added Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            } else {
                toast.success("Updated Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            }
            setisOPen(false);
        }

        if (isError) {
            if (error?.response?.data?.StatusCode !== 400) {
                toast.error(error?.response?.data?.Message, { position: "top-right" });
            } else {
                toast.error("error...", { position: "top-right" });
            }
        }
    }, [isError, isSuccess, id]);

    return <>
        <Dialog
            visible={open}
            onHide={() => setisOPen(false)}
            header={`${id === 0 ? "Add" : "Edit"} Hsn`}
            modal
            style={{ width: "1100px" }}
        >
            {!SampleLoader ? (
                <form onSubmit={handleSubmit(HsnFormData)}>
                    <div className="row">
                        <Input
                            containerClass="col-lg-4"
                            label="HSN Name"
                            type="text"
                            isRequired
                            onChange={(e: any) => {
                                setValue("Name", e.target.value);
                                clearErrors(["Name"]);
                            }}
                            register={{
                                ...register("Name", {
                                    required: {
                                        value: id == 0 ? true : false,
                                        message: " HSN Name is required",
                                    },

                                }),
                            }}
                            errorMessage={
                                errors?.Name?.message ||
                                error?.response?.data?.errors?.Name?.map(
                                    (e: any) => e
                                )
                            }
                        />
                        <Input
                            containerClass="col-lg-4"
                            label="SGST"
                            type="text"
                            isRequired={disabled !== "other" && disabled !== "editother"}
                            onChange={(e: any) => {
                                setValue("Sgst", e.target.value);
                                clearErrors(["Sgst"]);
                                if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                                    setValue("Igst", "");
                                    setDisabeld("igst")
                                }

                                else if (getValues("Cgst") === null || getValues("Cgst") === undefined || getValues("Cgst") === "") {

                                    setDisabeld(false)
                                }
                            }}
                            register={{
                                ...register("Sgst", {
                                    required: {
                                        value: disabled !== "other" && disabled !== "editother",
                                        message: " SGST is required",
                                    },

                                }),
                            }}
                            errorMessage={
                                errors?.Sgst?.message ||
                                error?.response?.data?.errors?.Sgst?.map(
                                    (e: any) => e
                                )
                            }
                            disabled={disabled === "other"}
                        />
                        <Input
                            containerClass="col-lg-4"
                            label="CGST"
                            type="text"
                            isRequired={disabled !== "other" && disabled !== "editother"}
                            onChange={(e: any) => {
                                setValue("Cgst", e.target.value);
                                clearErrors(["Cgst"]);

                                if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                                    setValue("Igst", "");

                                    setDisabeld("igst")
                                }
                                else if (getValues("Sgst") === null || getValues("Sgst") === undefined || getValues("Sgst") === "") {

                                    setDisabeld(false)
                                }
                            }}
                            register={{
                                ...register("Cgst", {
                                    required: {
                                        value: disabled !== "other" && disabled !== "editother",
                                        message: " CGST is required",
                                    },

                                }),
                            }}
                            errorMessage={
                                errors?.Cgst?.message ||
                                error?.response?.data?.errors?.Cgst?.map(
                                    (e: any) => e
                                )
                            }
                            disabled={disabled === "other"}
                        />
                        <Input
                            containerClass="col-lg-4"
                            label="IGST"
                            type="text"
                            isRequired={disabled !== "igst" && disabled !== "editIgst"}
                            onChange={(e: any) => {
                                setValue("Igst", e.target.value);
                                clearErrors(["Igst"]);

                                if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "") {
                                    setValue("Sgst", "");
                                    setValue("Cgst", "");
                                    setDisabeld("other")
                                }
                                else {
                                    setDisabeld(false)
                                }
                            }}
                            register={{
                                ...register("Igst", {
                                    required: {
                                        value: disabled !== "igst" && disabled !== "editIgst",
                                        message: " IGST is required",
                                    },

                                }),
                            }}
                            errorMessage={
                                errors?.Igst?.message ||
                                error?.response?.data?.errors?.Igst?.map(
                                    (e: any) => e
                                )
                            }
                            disabled={disabled === "igst"}
                        />
                        <ReactSelect
                            containerClass="col-lg-4"
                            isRequired
                            label="Hsn Type"
                            name={"HsntypeId"}
                            onChange={(e: any) => {
                                if (e !== null) {

                                    setValue("HsntypeId", e?.value);
                                    setSelectedHsnType(e);
                                }
                                else {

                                    setValue("HsntypeId", null);
                                    setSelectedHsnType(null);
                                }
                            }}
                            value={selectedHsnType}
                            control={control}
                            api="Inventory/Hsntype"
                            keys={{ label: "Value", value: "Id" }}
                            rules={{
                                required: {
                                    value: true,
                                    message: "HSN Type Is Required"
                                }
                            }}
                            errorMessage={errors?.HsntypeId?.message}
                        />
                        <Input
                            containerClass="col-lg-4"
                            label="UQC Unit"
                            type="text"
                            isRequired
                            onChange={(e: any) => {
                                setValue("Uqcunit", e.target.value);
                                clearErrors(["Uqcunit"]);
                            }}
                            register={{
                                ...register("Uqcunit", {
                                    required: {
                                        value: id == 0 ? true : false,
                                        message: "UQC Unit is required",
                                    },

                                }),
                            }}
                            errorMessage={
                                errors?.Uqcunit?.message ||
                                error?.response?.data?.errors?.Uqcunit?.map(
                                    (e: any) => e
                                )
                            }
                        />
                        <div className="col-lg-12 text-center mt-3">
                            <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                                <span className="p-button-label p-c">Back</span>
                            </Button>

                            {
                                id === 0 && <Button type="reset" severity="danger" onClick={() => { reset(); setSelectedHsnType(null); setValue("HsntypeId", null); }} outlined className="ml-2">
                                    <span className="p-button-label p-c">Reset</span>
                                </Button>
                            }

                            {isLoading ? (
                                <Button type="submit" outlined disabled className="ml-2">
                                    <span className="p-button-label p-c">
                                        Submit <Spinner width={"1rem"} height={"1rem"} />
                                    </span>
                                </Button>
                            ) : (
                                <Button type="submit" outlined className="ml-2">
                                    <span className="p-button-label p-c">Submit</span>
                                </Button>
                            )}
                        </div>
                    </div>
                </form>
            ) : (
                <div className="pd-20 card-box mb-30 text-center">
                    <Spinner width={"15rem"} height={"15rem"} />
                </div>
            )}
        </Dialog></>;
};


