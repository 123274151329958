import { useEffect, useState } from "react";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import moment from "moment";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export default function AddEditHolidayModal({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) {
  const apiname = process.env.REACT_APP_HOLYDAY_API?.toString();
  const { data, isLoading: holidayLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (id) {
      if (data) {

        const dateUpdate = data?.Date;
        reset(data);
        // let date = new Date(data?.Date.split("T")[0]);
        setValue("Date", dateUpdate);
        if (
          data.StateId !== null &&
          data.StateId !== undefined
        ) {
          setValue("StateId", {
            label: data.State,
            value: data.StateId,
          });
          setselectedstate({
            label: data.State,
            value: data.StateId,
          });
        } else {
          setValue("StateId", null);
          setselectedstate(null);
        }

      }
    }
  }, [data]);

  const onChange = (e: any) => {
    return true;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate: holidayForm,
    isSuccess: successHoliday,
    isError: errorHoliday,
    error,
    data: response,
    isLoading,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const holidayFormData = (data: any) => {

    data.Date = moment(data.Date).format("YYYY-MM-DDTHH:mm:ss");
    data.StateId = selectedstate?.value;
    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    holidayForm(newData);
  };

  const [selectedstate, setselectedstate]: any = useState(null);

  // Success & Error Messages
  useEffect(() => {
    if (successHoliday) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (errorHoliday) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [errorHoliday, successHoliday, id]);

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Holiday`}
        modal
        style={{ width: "1250px" }}
      >
        {!holidayLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(holidayFormData);
              }
            }}
            onSubmit={handleSubmit(holidayFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-4"
                label="Date"
                isRequired
                type="datetime-local"
                register={{
                  ...register("Date", {
                    required: { value: true, message: "Please select Date" },
                  }),
                }}
                onChange={(e: any) => {
                  setValue("Date", e.target.value);
                  clearErrors(["Date"]);
                }}
                errorMessage={
                  errors?.Date?.message ||
                  error?.response?.data?.errors?.Date?.map((e: any) => e)
                }
                min={currentDate}
              />

              <div className="col-lg-4">
                <div className="form-group">
                  <label className="col-form-label">
                    Type <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="HolidayType"
                    control={control}
                    rules={{ required: "Please select a holiday type" }}
                    render={({ field }) => (
                      <div className="d-flex ms-2 align-items-baseline mt-2">
                        <div className="custom-control custom-radio mb-5">
                          <input
                            id="holiday"
                            {...field}
                            type="radio"
                            className="custom-control-input"
                            value="holiday"
                            checked={field.value === "holiday"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="holiday"
                          >
                            Holiday
                          </label>
                        </div>
                        <div className="custom-control custom-radio mb-5">
                          <input
                            id="weekOff"
                            {...field}
                            className="custom-control-input"
                            type="radio"
                            value="week-Off"
                            checked={field.value === "week-Off"}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="weekOff"
                          >
                            Week-Off
                          </label>
                        </div>
                      </div>
                    )}
                  />
                  {errors.HolidayType && (
                    <div className="text-danger mt-1 ms-2">
                      {errors.HolidayType.message}
                    </div>
                  )}
                </div>
              </div>

              <Input
                containerClass="col-lg-4"
                label="Holiday Name"
                isRequired
                type="text"
                register={{
                  ...register("HolidayName", {
                    required: { value: true, message: "Name is required" },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid holiday name (1-50 alphabet characters only)",
                    },
                  }),
                }}
                onChange={(e: any) => {
                  setValue("HolidayName", e.target.value);
                  clearErrors(["HolidayName"]);
                }}
                errorMessage={
                  errors?.HolidayName?.message ||
                  error?.response?.data?.errors?.HolidayName?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="State"
                isRequired
                name={"StateId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("StateId", e?.value);
                    setselectedstate(e);
                  } else {
                    setValue("StateId", null);
                    setselectedstate(null);
                  }
                }}
                value={selectedstate}
                control={control}
                api={process.env.REACT_APP_STATE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.StateId?.message ||
                  error?.response?.data?.errors?.StateId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "State is required" },
                }}
              />

              <Input
                containerClass="col-lg-4"
                isRequired
                label="Reason"
                type="text"
                register={{
                  ...register("Reason", {
                    required: { value: true, message: "Reason is required" },
                  }),
                }}
                maxLength={500}
                onChange={(e: any) => {
                  setValue("Reason", e.target.value);
                  clearErrors(["Reason"]);
                }}
                errorMessage={
                  errors?.Reason?.message ||
                  error?.response?.data?.errors?.Reason?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedstate(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
