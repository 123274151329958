import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";
import { useFetchCalender } from "../../../hooks/Api/Api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axiosInstance from "../../../_helper/Interceptor";

const localizer = momentLocalizer(moment);
function getDaysInMonth(month: any, year: any) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(moment(date).format("YYYY-MM-DD"));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
const LeaveCalendar = () => {
  const controller = new AbortController();
  const navigate = useNavigate();
  let handleSelectSlot = (start: any) => {
    navigate("/layout/mr/leave", { state: moment(start).format("YYYY-MM-DD") });
  };
  let [dates, setDates]: any = useState(
    getDaysInMonth(moment().month(), moment().year())
  );
  let [events, setEvents] = useState([]);
  let getCalendersData: any = (date: any) => {
    return axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_LEAVE_API_DATE_WISE}?LeaveDate=${date}&PageNumber=1&PageSize=10`, { signal: controller.signal })
      .then((res) => {
        return res.data.Data?.length || 0;
      })
      .catch((err) => {

        if (controller.signal.reason.code == 20) {
          console.log('Request Canceled', err)
        }
        else {
          console.log(err);
        }
      });
  };
  useEffect(() => {
    (async () => {
      let eventsCount: any = await Promise.all(
        dates.map(async (date: any) => {
          let data = await getCalendersData(date);
          return data == 0 ? {} :
            {
              start: moment(date).toDate(),
              end: moment(date).toDate(),
              title: data,
              color: "bg-info",
              resource: false,
            }
        })
      );
      setEvents(eventsCount);
    })();
    return () => {
      controller.abort();
    }
  }, [dates]);
  return (
    <>
      <Helmet>
        <title>Leave Calendar</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Leave Calendar</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/tourprogram"}>Tour Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Leave Calendar
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          defaultDate={moment().toDate()}
          // onSelectSlot={handleSelectSlot}
          onSelectEvent={(data) => {

            if (data.title != 0) {
              handleSelectSlot(data.start)
            }
          }}
          selectable
          eventPropGetter={({ start, end, color }: any) => {
            return { className: color };
          }}
          onNavigate={(date) => {
            let dates = getDaysInMonth(date.getMonth(), date.getFullYear());
            setDates(dates);
          }}
          views={["month"]}
        />
      </div>
    </>
  );
};

export default LeaveCalendar;
