import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import Textarea from "../../../common/Textarea";
import { Spinner } from "../../../common/Spinner";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";

const AddEditCRMAccount = () => {
  const controller = new AbortController();
  const controller1 = new AbortController();
  const navigate = useNavigate();
  const { id } = useParams();
  const apiname = process.env.REACT_APP_DOCTORACCOUNT_API?.toString();

  const { data, isLoading: crmaccountLoader }: any = useFetchAllID(apiname, id, undefined, controller.signal);

  useEffect(() => {
    if (id) {
      const dateUpdate = data?.DateTime?.split("T")[0];
      reset(data);
      if (data) {
        setValue("DateTime", dateUpdate);
        setDoctorId(data.DoctorId);
        setDocId(data.DoctorId);
        setselectedEmployee({
          label: data.EmployeeName,
          value: data.EmployeeId,
        });
        setselecteddoctor({
          label: data.Doctor,
          value: data.DoctorId,
        });
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error,
    data: response,
  }: any = useCreateByID(apiname, id);

  const crmAccountData = (data: any) => {
    data.DoctorId =
      typeof data.DoctorId === "object"
        ? Number(data.DoctorId.value)
        : Number(data.DoctorId);
    mutate(data);
  };

  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  const [EmployeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setselectedEmployee]: any = useState(null);

  // Success & Error Messages
  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/crm/crmaccount");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/crm/crmaccount");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  // History grid
  const [gridData, setgridData] = useState([]);
  const [doctorId, setDoctorId] = useState("");

  const fetchDoctor = async (api_with_parameter: any) => {
    await axiosInstance
      .get(api_with_parameter, { signal: controller1.signal })
      .then((response) => {
        setgridData(response.data?.Data);
      })
      .catch((error) => {
        console.log("Error ", error);
      });
  };

  useEffect(() => {
    if (doctorId) {
      let api_with_parameter = `${process.env.REACT_APP_BASEURL
        }${process.env.REACT_APP_DOCTORACCOUNTHISTORY_API?.toString()}/Get?doctorId=${doctorId}`;
      fetchDoctor(api_with_parameter);
    }
    return () => {
      controller1.abort();
    }
  }, [doctorId]);

  const [DoctorData, setDoctorData]: any = useState({});
  const fetchDoctorData = async (api_with_parameter: any) => {
    await axiosInstance
      .get(api_with_parameter, { signal: controller1.signal })
      .then((response) => {
        setDoctorData(response.data?.Data);
        let EmployeeOpt = response.data?.Data.Employees?.map((e: any) => {
          let temp = {
            label: e.EmployeeName,
            value: e.EmployeeId,
          };
          return temp;
        });
        setEmployeeOptions(EmployeeOpt);
      })
      .catch((error) => {
        console.log("Error ", error);
      });
  };

  const [docId, setDocId] = useState("");
  useEffect(() => {
    if (docId) {
      let doctordetails = `${process.env.REACT_APP_BASEURL
        }${process.env.REACT_APP_GETDOCTORDETAILSACC_API?.toString()}?doctorId=${docId}`;
      fetchDoctorData(doctordetails);
    }
    return () => {
      controller1.abort();
    }
  }, [docId]);

  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>{id === undefined ? "Add" : "Edit"} Account </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/crm/crmaccount"}>CRM Account </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {id === undefined ? "Add" : "Edit"} Account
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {!crmaccountLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((pinfo: any) => {
              crmAccountData(pinfo);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-3"
                label="Doctor"
                name={"DoctorId"}
                isRequired
                onChange={(e: any) => {
                  setselecteddoctor(e);
                  setValue("DoctorId", e?.value);
                  setDocId(e?.value);
                }}
                value={selecteddoctor}
                control={control}
                api={process.env.REACT_APP_DOCTORDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DoctorId?.message ||
                  error?.response?.data?.errors?.DoctorId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Doctor is required." },
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="Zone"
                type="text"
                disabled
                value={DoctorData?.ZoneName}
              />

              <Input
                containerClass="col-lg-3"
                label="Region"
                type="text"
                value={DoctorData?.RegionName}
                disabled
              />

              <Input
                containerClass="col-lg-3"
                label="City"
                type="text"
                value={DoctorData?.CityName}
                disabled
              />

              <Input
                containerClass="col-lg-3"
                label="Mobile No."
                type="text"
                value={DoctorData?.MobileNumber}
                disabled
              />

              <Input
                containerClass="col-lg-3"
                label="Phone No."
                type="text"
                value={DoctorData?.PhoneNumber}
                disabled
              />

              <>
                <Input
                  containerClass="col-lg-3"
                  label="Percentage"
                  type="number"
                  isRequired
                  onChange={() => clearErrors(["Percentage"])}
                  errorMessage={
                    errors?.Percentage?.message ||
                    error?.response?.data?.errors?.Percentage?.map(
                      (e: any) => e
                    ) ||
                    errors?.Percentage?.Message
                  }
                  register={{
                    ...register("Percentage", {
                      required: {
                        value: true,
                        message: "Percentage is required",
                      },
                      pattern: {
                        value: /^(?:(?:\d{1,3}.\d{1,2})|(?:\d{1,3}))$/,
                        message:
                          "Enter only digits and Remove whitespace from both sides of a number",
                      },
                      validate: (value: any) =>
                        value <= 100 || "Value should be less or equal to 100",
                    }),
                  }}
                />

                <Input
                  containerClass="col-lg-3"
                  label="Date-Time"
                  type="date"
                  isRequired
                  errorMessage={
                    errors?.DateTime?.message ||
                    error?.response?.data?.errors?.DateTime?.map((e: any) => e)
                  }
                  onChange={() => clearErrors(["DateTime"])}
                  register={{
                    ...register("DateTime", {
                      required: {
                        value: true,
                        message: "Date-Time is required.",
                      },
                    }),
                  }}
                />
              </>

              <ReactSelect
                containerClass="col-lg-3"
                label="Employee"
                name={"EmployeeId"}
                isRequired
                onChange={(e: any) => {
                  setselectedEmployee(e);
                  setValue("EmployeeId", e?.value);
                }}
                value={selectedEmployee}
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.EmployeeId?.message ||
                  error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
                }
                rules={{
                  required: { value: true, message: "Employee is required." },
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                register={{
                  ...register("Remarks"),
                }}
                maxLength={500}
                rows={3}
                onChange={() => clearErrors(["Remarks"])}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
              />

              <div className="col-lg-12 text-center mt-3">
                <Link to={"/layout/crm/crmaccount"}>
                  <Button type="button" severity="secondary" outlined>
                    <span className="p-button-label p-c">Back</span>
                  </Button>
                </Link>

                {id == undefined && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselecteddoctor(null);
                      setDoctorData(null);
                      setselectedEmployee(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    outlined
                    className="ml-2"
                    onClick={handleSubmit((data: any) => {
                      crmAccountData(data);
                    })}
                  >
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}

      {gridData.length > 0 ? (
        <div className="pd-20 card-box mb-30 table-responsive">
          <div className="title mb-4 ml-2">
            <h4>History Table </h4>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">DoctorName</th>
                <th scope="col">Percentage</th>
                <th scope="col">DateTime</th>
                {/* <th scope="col">Remarks</th> */}
                <th scope="col">CreatedOn</th>
                <th scope="col">CreatedBy Name</th>
              </tr>
            </thead>
            <tbody>
              {gridData?.map((e: any) => {
                return (
                  <>
                    <tr>
                      <td>{e.DoctorName}</td>
                      <td>{e.Percentage}</td>
                      <td>{e.DateTime.split("T")[0]}</td>
                      {/* <td>{e.Remarks}</td> */}
                      <td>{e.CreatedOn.split("T")[0]}</td>
                      <td>{e.CreatedByName}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AddEditCRMAccount;
