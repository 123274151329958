import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFieldArray, useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import ReactSelect from "../../../common/ReactSelect";
import Select from "react-select";
import { Input } from "../../../common/Input";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { useCreate } from "../../../hooks/Api/Api";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";

const RCPA = () => {
  const navigate = useNavigate();

  const apiname = process.env.REACT_APP_RCPA_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch
  }: any = useForm({
    defaultValues: {
      DoctorId: "",
      ChemistId: "",
      OurCompetingProductId: "",
      Price: "",
      OurCompetingProductSellQty: "",
      DailyRx: "",
      WeeklyRx: "",
      MonthlyRx: "",
      CompititorProducts: [
        {
          CompetitorProductId: "",
          CompetitorProductSellQty: "",
        },
      ],
    },
  });

  const da = watch()
  console.log(da, "sdsdsdsdsdsdsd")

  const {
    fields: compititor,
    append: compititoradd,
    remove: compititorremove,
  } = useFieldArray({ control, name: "CompititorProducts" });

  const [doctorOptions, setdoctorOptions] = useState([]);
  const [selecteddoctor, setselecteddoctor]: any = useState({});
  const fetchdoctorOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORDETAIL_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.DoctorName,
        value: option?.DoctorId,
      };
    });
    setdoctorOptions(data);
  };

  const [chemistOptions, setchemistOptions] = useState([]);
  const [selectedchemist, setselectedchemist]: any = useState({});
  const fetchchemistOptions = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTGETCHEMISTBYDOCTOR_API}?doctorId=${selecteddoctor.value}`
      )
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.ChemistName,
            value: option?.ChemistId,
          };
        });
        setchemistOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };
  // console.log(Object.keys(selecteddoctor).length, "dsdsdds");

  useEffect(() => {
    // console.log(selecteddoctor, "doctor list");
    if (Object.keys(selecteddoctor).length !== 0) {
      fetchchemistOptions();
    }
  }, [selecteddoctor]);

  const [productOptions, setproductOptions] = useState([]);
  const [selectedproduct, setselectedproduct]: any = useState({});
  const fetchproductOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRODUCT_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.Name,
        value: option?.ProductId,
      };
    });
    setproductOptions(data);
  };

  useEffect(() => {
    fetchdoctorOptions();
    fetchproductOptions();
  }, []);

  const [competitorproductOptions, setcompetitorproductOptions] = useState([]);
  const fetchcompetitorproductOptions = async () => {

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRODUCTGETCOMPETITORPRODUCTBYID_API}?productid=${selectedproduct.value}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.Name,
        value: option?.ProductId,
      };
    });
    setcompetitorproductOptions(data);
  };

  useEffect(() => {
    if (Object.keys(selectedproduct).length !== 0) {
      fetchcompetitorproductOptions();
    }
  }, [selectedproduct]);

  const { mutate, isSuccess, isError, error, isLoading }: any =
    useCreate(apiname);

  const RCPAModel = async (data: any) => {
    data.Price = Number(data.Price);
    data.DailyRx = Number(data.DailyRx);
    data.MonthlyRx = Number(data.MonthlyRx);
    data.WeeklyRx = Number(data.WeeklyRx);
    data.OurCompetingProductSellQty = Number(data.OurCompetingProductSellQty);

    const test = data.CompititorProducts.map((e: any) => {
      return {
        CompetitorProductId: e.CompetitorProductId,
        CompetitorProductSellQty: Number(e.CompetitorProductSellQty),
      };
    });

    data.CompititorProducts = test;

    console.log(data, "xyz");
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/mr/rcpa");
      reset();
      setselecteddoctor({});
      setselectedchemist({});
      setselectedproduct({});
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Helmet>
        <title>RCPA</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>RCPA</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  RCPA
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit((giftmasterinfo: any) => {
          RCPAModel(giftmasterinfo);
        })}
      >
        <div className="pd-20 card-box mb-30">
          <div className="clearfix">
            <div className="pull-left">
              <h4 className="text-blue h4">RCPA</h4>
            </div>
          </div>
          <div className="row">
            <ReactSelect
              containerClass="col-lg-4"
              label="Doctor"
              isRequired
              onChange={(e: any) => {
                setselecteddoctor(e);
                setselectedchemist({});
                setValue("DoctorId", e?.value);
              }}
              value={selecteddoctor}
              name={"DoctorId"}
              control={control}
              options={doctorOptions}
              errorMessage={errors?.DoctorId?.message}
              rules={{
                required: { value: true, message: "Doctor is required" },
              }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Chemist"
              isRequired
              onChange={(e: any) => {
                setselectedchemist(e);
                setValue("ChemistId", e?.value);
              }}
              value={selectedchemist}
              name={"ChemistId"}
              control={control}
              options={chemistOptions}
              errorMessage={errors?.ChemistId?.message}
              rules={{
                required: { value: true, message: "Doctor is required" },
              }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Product"
              isRequired
              onChange={(e: any) => {
                setselectedproduct(e);
                setValue("OurCompetingProductId", e?.value);
              }}
              value={selectedproduct}
              name={"OurCompetingProductId"}
              control={control}
              options={productOptions}
              errorMessage={errors?.OurCompetingProductId?.message}
              rules={{
                required: {
                  value: true,
                  message: "OurCompetingProduct is required",
                },
              }}
            />

            <Input
              containerClass="col-lg-4"
              label="Quantity"
              isRequired
              type="text"
              register={{
                ...register("OurCompetingProductSellQty", {
                  required: {
                    value: true,
                    message: "Product Qty is Required",
                  },
                }),
              }}
              errorMessage={errors?.OurCompetingProductSellQty?.message}
            />

            <Input
              containerClass="col-lg-4"
              label="Price"
              isRequired
              type="text"
              register={{
                ...register("Price", {
                  required: {
                    value: true,
                    message: "Price is Required",
                  },
                }),
              }}
              errorMessage={errors?.Price?.message}
            />

            <Input
              containerClass="col-lg-4"
              label="DailyRx"
              isRequired
              type="text"
              register={{
                ...register("DailyRx", {
                  required: {
                    value: true,
                    message: "DailyRx is Required",
                  },
                }),
              }}
              errorMessage={errors?.DailyRx?.message}
            />

            <Input
              containerClass="col-lg-4"
              label="WeeklyRx"
              isRequired
              type="text"
              register={{
                ...register("WeeklyRx", {
                  required: {
                    value: true,
                    message: "WeeklyRx is Required",
                  },
                }),
              }}
              errorMessage={errors?.WeeklyRx?.message}
            />

            <Input
              containerClass="col-lg-4"
              label="MonthlyRx"
              isRequired
              type="text"
              register={{
                ...register("MonthlyRx", {
                  required: {
                    value: true,
                    message: "MonthlyRx is Required",
                  },
                }),
              }}
              errorMessage={errors?.MonthlyRx?.message}
            />

            <div className="col-lg-12 text-right">
              <button
                type="button"
                onClick={() => {
                  compititoradd({
                    CompetitorProductId: "",
                    CompetitorProductSellQty: "",
                  });
                }}
                className="btn btn-primary mb-2"
              >
                Add Competitor Product
              </button>
            </div>

            <div className="col-lg-12">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="text-center">
                    <th>Competitor Product Name</th>
                    <th>Competitor Product Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {compititor.map((field: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Select
                            {...register(
                              `CompititorProducts.${i}.CompetitorProductId`,
                              {
                                required: {
                                  value: true,
                                  message: "Competitor is required",
                                },
                              }
                            )}
                            options={competitorproductOptions}
                            className="z-3"
                            classNamePrefix={"z-3"}
                            onChange={(e: any) => {
                              setValue(
                                `CompititorProducts.${i}.CompetitorProductId`,
                                e?.value
                              );
                            }}
                          />
                          <ErrorMessage
                            message={
                              errors?.["CompititorProducts"]?.[i]?.[
                                "CompetitorProductId"
                              ]?.message
                            }
                          />
                        </td>
                        <td>
                          <div className="mt-3">
                            <Input
                              containerClass="col-lg-12"
                              isRequired
                              type="text"
                              register={{
                                ...register(
                                  `CompititorProducts.${i}.CompetitorProductSellQty`,
                                  {
                                    required: {
                                      value: true,
                                      message:
                                        "CompetitorProductSellQty is Required",
                                    },
                                  }
                                ),
                              }}
                              errorMessage={
                                errors?.["CompititorProducts"]?.[i]?.[
                                  "CompetitorProductSellQty"
                                ]?.message
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="col-lg-12 text-center">
                            {compititor.length !== 1 ? (
                              <button
                                type="button"
                                onClick={() => {
                                  debugger
                                  compititorremove(i);
                                }}
                                className="btn btn-danger"
                              >
                                Delete Competitor Product {i}
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  compititorremove(i);
                                }}
                                className="btn btn-danger"
                                disabled
                              >
                                Delete Competitor Product
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              ) : (
                <button className="btn btn-success" disabled>
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default RCPA;
