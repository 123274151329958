import React, { useState } from 'react'
import { DeleteModal } from '../../../common/DeleteModal';
import { Action } from '../../../common/Action';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../common/PageHeader';
import DatatableCommon from '../../../common/DatatableCommon';
import { AddEditAnnouncement } from './AddEditAnnouncement';


export const Announcement = () => {
  const apiname = process.env.REACT_APP_NOTIFICATION_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setSelectedRow]: any = useState([]);
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedRows, setselectedRows]: any = useState([]);

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3%",
    },
    {
      field: "Title",
      header: "Title",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "Message",
      header: "Message",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "NotificationUses.UserName",
      header: "UserName",
      body: (e: any) => userdata(e),
      sortable: true,
      width: "10vw",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const userdata = (e: any) => {
    return e.NotificationUses.map((ab: any) => { return ab.UserName }).join(", ")
  };

  const actionNotificationTemplate = (id: any) => {
    console.log("id", id)
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={setDeleteidtoDelete}
          flag
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Announcement</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Announcement"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setSelectedRow}
          selecteditem={selectedRow}
        />
        {updateId !== null && (
          <AddEditAnnouncement
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => { if (data) { setbool(true) } }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
