import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../../../common/PageHeader";
import { DeleteModal } from "../../../../../common/DeleteModal";
import { Action } from "../../../../../common/Action";
import DatatableCommon from "../../../../../common/DatatableCommon";
import { AddEditPresentation } from "./AddEditPresentation";
import { NullHandler } from "../../../../../common/NullHandler";


export const Presentation = () => {
  const apiname = process.env.REACT_APP_PRESENTATION_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedRow, setSelectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.PresentationId;
  });
  useEffect(() => { }, [selectedRow]);

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };
  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "5%",
    },
    {
      header: "Action",
      body: (e: any) => actionPresentationAccountTemplate(e),
      sortable: false,
      width: "7%",
    },
    {
      field: "PresentationName",
      header: "Presentation Name",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "Description",
      header: "Description",

      body: (data: any) => {
        return NullHandler(data.Description);
      },
      width: "10vw",
    },
    {
      field: "PresentationVaUploadViewModel.VaUploadName",
      header: "VA Upload",
      body: (e: any) => actionVATemplate(e),
      sortable: false,
      width: "10vw",
    },

  ];

  const actionVATemplate = (id: any) => {
    return id.PresentationVaUploadViewModel?.length == 0 ? " - " : id.PresentationVaUploadViewModel
      ?.map((ab: any) => {
        return ab.VaUploadName;
      })
      .join(", ");
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionPresentationAccountTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.PresentationId}
          editModal={() => {
            setupdateId(id.PresentationId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Presentation</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Presentation"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedRow?.length === 0 ? null : multi);
            }
          }}
          setselecteditem={setSelectedRow}
          selecteditem={selectedRow}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        // check={true}
        />
        {updateId !== null && (
          <AddEditPresentation
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </div>
  );
};
