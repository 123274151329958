import React, { useState } from "react";
import { Action } from "../../../../common/Action";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../../common/PageHeader";
import DatatableCommon from "../../../../common/DatatableCommon";
import AddEditMonthlyAllowance from "./AddEditMonthlyAllowance";
import { DeleteModal } from "../../../../common/DeleteModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const MonthlyAllowance = () => {
  const apiname = process.env.REACT_APP_MONTHLYALLOWANCE_API?.toString();

  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedRows, setSelectedRows]: any = useState([]);
  const multi = selectedRows?.map((e: any) => {
    return e.MonthlyAllowanceId;
  });

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const filterJson = {
    EmployeeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    AllowancesTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CalculationTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Criteria: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    DrVisitCriteriaDays: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const columnData = [
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionMonthlyAllowanceTemplate(e),
      width: "2rem",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      sortable: true,
      bodyClassName: "text-info h6",
      body: null,
      filter: true,
      filterPlaceholder: "Search by employee name",
      width: "10rem",
    },
    {
      field: "AllowancesTypeName",
      header: "Allowance Type",
      sortable: true,
      body: null,
      filter: true,
      filterPlaceholder: "Search by allowance type",
      width: "10rem",
    },
    {
      field: "CalculationTypeName",
      header: "Calculation Type",
      sortable: true,
      body: null,
      filter: true,
      filterPlaceholder: "Search by calculation type",
      width: "10rem",
    },
    {
      field: "Criteria",
      header: "Criteria",
      sortable: true,
      body: null,
      dataType: "numeric",
      filter: true,
      filterPlaceholder: "Search by criteria",
      width: "7rem",
    },
    {
      field: "DrVisitCriteriaDays",
      header: "Dr Visit Criteria Days",
      sortable: true,
      body: null,
      dataType: "numeric",
      filter: true,
      filterPlaceholder: "Search by visit criteria days",
      width: "7rem",
    },
    {
      field: "ProRata",
      header: "Pro Rate",
      sortable: true,
      body: (id: any) => {
        return id.ProRata === false ? "False" : "True";
      },
      width: "7rem",
    },
    {
      field: "ConsiderVisitCriteria",
      header: "Consider Visit Criteria",
      sortable: true,
      body: (id: any) => {
        return id.ConsiderVisitCriteria === false ? "False" : "True";
      },
      width: "7rem",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);
  const actionMonthlyAllowanceTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.MonthlyAllowanceId}
          editModal={() => {
            setupdateId(id.MonthlyAllowanceId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Monthly Allowance</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Monthly Allowance"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedRows?.length === 0 ? null : multi);
            }
          }}
          setselecteditem={setSelectedRows}
          filterJson={filterJson}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditMonthlyAllowance
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default MonthlyAllowance;
