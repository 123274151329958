import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Input } from "../../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../../common/Spinner";
import Textarea from "../../../../../common/Textarea";
import axiosInstance from "../../../../../_helper/Interceptor";
import ReactSelect from "../../../../../common/ReactSelect";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

interface Item {
  id: string;
  content: string;
}

const initialItems: Item[] = [
  { id: "1", content: "Item 1" },
  { id: "2", content: "Item 2" },
  { id: "3", content: "Item 3" },
];

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
export const AddEditPresentation = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) => {
  const [items, setItems] = useState<any>(initialItems);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems = [...items];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setItems(newItems);
  };

  const apiname = process.env.REACT_APP_PRESENTATION_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        let EditPresentationVaUploadViewModel =
          data?.PresentationVaUploadViewModel?.map((d: any) => ({
            label: d?.VaUploadName,
            value: d?.VaUploadId,
          }));

        if (data.DoctorId !== null && data.DoctorId !== undefined) {
          setValue("DoctorId", {
            label: data.DoctorName,
            value: data.DoctorId,
          });
          setselecteddoctor({
            label: data.DoctorName,
            value: data.DoctorId,
          });
        } else {
          setValue("DoctorId", null);
          setselecteddoctor(null);
        }

        setValue(
          "PresentationVaUploadViewModel",
          EditPresentationVaUploadViewModel
        );
        setselectedpresentation(EditPresentationVaUploadViewModel);
      }
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedpresentation, setselectedpresentation] = useState([]);
  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  const presentationFormData = (data: any) => {
    data.PresentationVaUploadViewModel = selectedpresentation?.map((e: any) => {
      return e?.value;
    });

    data.DoctorId = Number(data?.DoctorId?.value);
    console.log("submittedData", data?.DoctorId);
    if (typeof data?.DoctorId == "object") {
      data.DoctorId = Number(data?.DoctorId?.value);
    } else {
      data.DoctorId = Number(data?.DoctorId);
    }

    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  useEffect(() => {
    // const res = selectedpresentation.map((e:any)=>{
    //   return {
    //     id:e?.value,
    //     content:e.label
    //   }
    // })
    setItems(selectedpresentation);
  }, [selectedpresentation]);

  return (
    <div>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Presentation`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(presentationFormData);
              }
            }}
            onSubmit={handleSubmit(presentationFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Presentation Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("PresentationName", e.target.value);
                  clearErrors(["PresentationName"]);
                }}
                register={{
                  ...register("PresentationName", {
                    required: {
                      value: true,
                      message: "Presentation Name is required",
                    },
                  }),
                }}
                maxLength={50}
                errorMessage={
                  errors?.PresentationName?.message ||
                  error?.response?.data?.errors?.PresentationName?.map(
                    (e: any) => e
                  )
                }
              />

              <ReactSelect
                containerClass="col-lg-6"
                label={"VaUpload"}
                isRequired
                name={"PresentationVaUploadViewModel"}
                onChange={(e: any) => {
                  setValue("PresentationVaUploadViewModel", e);
                  setselectedpresentation(e);
                }}
                value={selectedpresentation}
                control={control}
                api={process.env.REACT_APP_VAUPLOAD_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.PresentationVaUploadViewModel?.message ||
                  error?.response?.data?.errors?.PresentationVaUploadViewModel?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "VaUpload is required",
                  },
                }}
                Multi
              />

              <ReactSelect
                containerClass="col-lg-6"
                label={"Doctor"}
                isRequired
                name={"DoctorId"}
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DoctorId", e?.value);
                    setselecteddoctor(e);
                  } else {
                    setValue("DoctorId", null);
                    setselecteddoctor(null);
                  }
                }}
                value={selecteddoctor}
                control={control}
                api={process.env.REACT_APP_DOCTORDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DoctorId?.message ||
                  error?.response?.data?.errors?.DoctorId?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Doctor is required",
                  },
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Description"
                isRequired
                maxLength={500}
                errorMessage={
                  errors?.Description?.message ||
                  error?.response?.data?.errors?.Description?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("Description", e.target.value);
                  clearErrors(["Description"]);
                }}
                register={{
                  ...register("Description", {
                    required: {
                      value: true,
                      message: "Description is required",
                    },
                  }),
                }}
              />

              <div className="col-md-12">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="drag-ul"
                      >
                        {items?.map((item: any, index: any) => {
                          console.log(item, "item");
                          return (
                            <Draggable
                              key={item.value}
                              draggableId={item.value}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  className="drag-li"
                                >
                                  {item.label}
                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedpresentation([]);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </div>
  );
};
