import ReactSelect from "../../../../common/ReactSelect";
import { Input } from "../../../../common/Input";
import { useEffect, useRef, useState } from "react";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import cameraLogo from "../../../../assets/images/Camera.png";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";

interface iPropsmodal {
  id?: any;
  doctorid?: number;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  deleteFunction?: any;
  refetchData?: any;
  resetstate?: any;
  api?: any;
}
export default function AddEditDegreeModal({
  id,
  doctorid,
  setisOPen,
  open,
  fetch,
  deleteFunction,
  refetchData,
  resetstate,
  api,
}: iPropsmodal) {
  const [DegreeImageDisplay, setDegreeImageDisplay]: any = useState(cameraLogo);
  useEffect(() => {
    if (refetchData == true && api == "DoctorDegreeType") {
      // fetchdegreeOptions();
      setselecteddegree(null);
      resetstate(false);
    } else if (refetchData == true && api == "Speciality") {
      // fetchspecialityOptions();
      setselectedspeciality(null);
      resetstate(false);
    }
  }, [refetchData]);

  const apiname = process.env.REACT_APP_DOCTORDEGREE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const addmissionyear = useRef({});
  addmissionyear.current = watch("DegreeAdmissionYear", "");

  const { data, isLoading }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    doctorid
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        setDegreeImageDisplay(data.DegreePath);
        if (data.DegreeTypeId !== null) {
          setValue("DegreeTypeId", {
            label: data.DegreeTypeName,
            value: data.DegreeTypeId,
          });
          setselecteddegree({
            label: data.DegreeTypeName,
            value: data.DegreeTypeId,
          });
        } else {
          setValue("DegreeTypeId", null);
          setselecteddegree(null);
        }

        if (data.DegreeSpecialityId !== null) {
          setValue("DegreeSpecialityId", {
            label: data.DegreeSpecialityName,
            value: data.DegreeSpecialityId,
          });
          setselectedspeciality({
            label: data.DegreeSpecialityName,
            value: data.DegreeSpecialityId,
          });
        } else {
          setValue("DegreeSpecialityId", null);
          setselectedspeciality(null);
        }

        if (data.DegreeCollegeCityId !== null) {
          setValue("DegreeCollegeCityId", {
            label: data.DegreeCollegeCity,
            value: data.DegreeCollegeCityId,
          });
          setselectedcity({
            label: data.DegreeCollegeCity,
            value: data.DegreeCollegeCityId,
          });
        } else {
          setValue("DegreeCollegeCityId", null);
          setselectedcity(null);
        }
        if (data.DegreeCollegePincodeId !== null) {
          setValue("DegreeCollegePincodeId", {
            label: data.DegreeCollegePincode,
            value: data.DegreeCollegePincodeId,
          });
          setselectedpincode({
            label: data.DegreeCollegePincode,
            value: data.DegreeCollegePincodeId,
          });
        } else {
          setValue("DegreeCollegePincodeId", null);
          setselectedpincode(null);
        }
      }
    }
  }, [data, refetchData]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: achievementLoader,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [DegreeImage, setDegreeImage]: any = useState(null);
  const [selecteddegree, setselecteddegree]: any = useState(null);
  const [selectedspeciality, setselectedspeciality]: any = useState(null);
  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedpincode, setselectedpincode]: any = useState(null);
  const handleError = (e: any) => {
    e.target.onerror = null;
    e.target.src = cameraLogo;
  };
  const doctorDegreeFormData = (form: any) => {
    if (DegreeImage !== null) {
      form.DegreeImage = DegreeImage[0];
    }
    form.DoctorId = Number(doctorid);

    form.DegreeTypeId = selecteddegree?.value;
    form.DegreeSpecialityId = selectedspeciality?.value;
    form.DegreeCollegeCityId = selectedcity?.value;
    form.DegreeCollegePincodeId = selectedpincode?.value;

    for (let prop in form) {
      if (
        form[prop] === null ||
        form[prop] === undefined ||
        form[prop] === "" ||
        form[prop]?.length == 0
      ) {
        delete form[prop];
      }
    }

    let newData: any = {};
    Object.keys(form).forEach(
      (k) =>
        (newData[k] = typeof form[k] == "string" ? form[k].trim() : form[k])
    );
    const formData = new FormData();
    for (const [key, value] of Object.entries(newData)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Doctor Degree`}
        modal
        style={{ width: "1250px" }}
      >
        {!isLoading ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(doctorDegreeFormData);
              }
            }}
            onSubmit={handleSubmit(doctorDegreeFormData)}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Degree"
                control={control}
                name="DegreeTypeId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DegreeTypeId", e?.value);
                    setselecteddegree(e);
                  } else {
                    setValue("DegreeTypeId", null);
                    setselecteddegree(null);
                  }
                }}
                value={selecteddegree}
                api={process.env.REACT_APP_DOCTORDEGREETYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DegreeTypeId?.message ||
                  error?.response?.data?.errors?.DegreeTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Degree Type is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Speciality"
                control={control}
                name="DegreeSpecialityId"
                isRequired
                onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DegreeSpecialityId", e?.value);
                    setselectedspeciality(e);
                  } else {
                    setValue("DegreeSpecialityId", null);
                    setselectedspeciality(null);
                  }

                }}
                value={selectedspeciality}
                api={process.env.REACT_APP_SPECIALITY_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.DegreeSpecialityId?.message ||
                  error?.response?.data?.errors?.DegreeSpecialityId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Speciality is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Admission Year"
                type="text"
                isRequired
                errorMessage={
                  errors?.DegreeAdmissionYear?.message ||
                  error?.response?.data?.errors?.DegreeAdmissionYear?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DegreeAdmissionYear", e.target.value);
                  clearErrors(["DegreeAdmissionYear"]);
                }}
                register={{
                  ...register("DegreeAdmissionYear", {
                    required: {
                      value: true,
                      message: " Admission Year is required",
                    },
                    pattern: {
                      value: /^\d{4}$/,
                      message: "Enter a valid admission year (4 digits only)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="PassOut Year"
                type="text"
                isRequired
                errorMessage={
                  errors?.DegreePassOutYear?.message ||
                  error?.response?.data?.errors?.DegreePassOutYear?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DegreePassOutYear", e.target.value);
                  clearErrors(["DegreePassOutYear"]);
                }}
                register={{
                  ...register("DegreePassOutYear", {
                    required: {
                      value: true,
                      message: " PassOut Year is required",
                    },
                    pattern: {
                      value: /^\d{4}$/,
                      message: "Enter a valid passout year (4 digits only)",
                    },
                    validate: (value: any) =>
                      value >= addmissionyear.current ||
                      "Year should be greater or equal to Admission year.",
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="College"
                type="text"
                isRequired
                errorMessage={
                  errors?.DegreeCollege?.message ||
                  error?.response?.data?.errors?.DegreeCollege?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DegreeCollege", e.target.value);
                  clearErrors(["DegreeCollege"]);
                }}
                register={{
                  ...register("DegreeCollege", {
                    required: {
                      value: true,
                      message: " College is required",
                    },
                    pattern: {
                      value: /^.{2,50}$/gm,
                      message:
                        "Enter a valid college name (2-50 alphabet characters or special characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Registration Number"
                type="text"
                isRequired
                errorMessage={
                  errors?.DegreeRegistrationNumber?.message ||
                  error?.response?.data?.errors?.DegreeRegistrationNumber?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DegreeRegistrationNumber", e.target.value);
                  clearErrors(["DegreeRegistrationNumber"]);
                }}
                register={{
                  ...register("DegreeRegistrationNumber", {
                    required: {
                      value: true,
                      message: " Registration Number is required",
                    },
                    pattern: {
                      value: /^\d{10}$/,
                      message:
                        "Enter a valid registration number (10 digits only)",
                    },
                  }),
                }}
              />

              <div className={"col-lg-4"}>
                <div className="form-group image-upload">
                  <label className="col-form-label">
                    Degree Image <span className="text-danger">*</span>
                  </label>
                  <div>
                    <label style={{ cursor: "pointer" }}>
                      <img src={DegreeImageDisplay} alt="Click Here to add Image" className="uploaded-image" onError={handleError} width={"150px"} height={"150px"} />


                      <input
                        {...register("DegreeImage", {
                          required: {
                            value:
                              !data.DegreeImage &&
                              DegreeImage?.name == undefined &&
                              true,
                            message: "Degree Image is required",
                          },
                        })}
                        type="file"
                        data-required="image"
                        onChange={(e: any) => {
                          if (e.target.files[0]) {
                            const filePath = URL.createObjectURL(e.target.files[0]);
                            setDegreeImageDisplay(filePath);
                          }
                          setDegreeImage(e.target.files)

                        }}
                        className="form-control"
                        hidden
                        accept=".pdf,image/*"
                      />
                    </label>
                  </div>
                  {
                    DegreeImageDisplay !== cameraLogo &&
                    (DegreeImage == null ? (
                      <>
                        <a href={`${data?.DegreePath}`} target="blank">
                          {data?.DegreeImage}
                        </a>
                        {/* <i className="bi bi-x-circle-fill ml-2" style={{ cursor: "pointer" }} title="Remove Image" onClick={() => {
                          setValue("DegreeImage", null);
                          setValue("DegreePath", null);
                          setDegreeImage(null);
                          setDegreeImageDisplay(cameraLogo);

                        }}></i> */}
                      </>
                    ) : (
                      <p>{DegreeImage[0]?.name}
                        {/* <i className="bi bi-x-circle-fill ml-2" style={{ cursor: "pointer" }} title="Remove Image" onClick={() => {
                        setValue("DegreeImage", null);
                        setValue("DegreePath", null);
                        setDegreeImage(null);
                        setDegreeImageDisplay(cameraLogo);

                      }}></i> */}
                      </p>
                    ))
                  }

                  {!data.DegreeImage && DegreeImage == null && (
                    <ErrorMessage message={errors?.DegreeImage?.message} />
                  )}
                </div>
              </div>

              <Address
                control={control}
                header="Address"
                address1register={{
                  ...register("DegreeCollegeAddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  console.log(e.target.value);
                  setValue("DegreeCollegeAddressLine1", e.target.value);
                  clearErrors(["DegreeCollegeAddressLine1"]);
                }}
                address1errorMessage={
                  errors?.DegreeCollegeAddressLine1?.message ||
                  error?.response?.data?.errors?.DegreeCollegeAddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("DegreeCollegeAddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  if (
                    e.target.value == "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    setValue("DegreeCollegeAddressLine2", null);
                    clearErrors(["DegreeCollegeAddressLine2"]);
                  } else {
                    setValue("DegreeCollegeAddressLine2", e.target.value);
                    clearErrors(["DegreeCollegeAddressLine2"]);
                  }
                }}
                address2errorMessage={
                  errors?.DegreeCollegeAddressLine2?.message ||
                  error?.response?.data?.errors?.DegreeCollegeAddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DegreeCollegeCityId", e?.value);
                    setselectedcity(e);
                    setselectedpincode({});
                  } else {
                    setValue("DegreeCollegeCityId", null);
                    setValue("DegreeCollegePincodeId", null);
                    setselectedcity(null);
                    setselectedpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.DegreeCollegeCityId?.message ||
                  error?.response?.data?.errors?.DegreeCollegeCityId?.map(
                    (e: any) => e
                  )
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("DegreeCollegePincodeId", e?.value);
                    setselectedpincode(e);
                  } else {
                    setValue("DegreeCollegePincodeId", null);
                    setselectedpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.DegreeCollegePincodeId?.message ||
                  error?.response?.data?.errors?.DegreeCollegePincodeId?.map(
                    (e: any) => e
                  )
                }
                selectedCity={selectedcity}
                selectedPincode={selectedpincode}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityKeys={{ label: "Value", value: "Id" }}
                cityName={"DegreeCollegeCityId"}
                pincodeApi={
                  selectedcity != null ?
                    `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedcity?.value}` : ""
                }
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeName={"DegreeCollegePincodeId"}
                pincodeDisable={selectedcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselecteddegree(null);
                      setselectedspeciality(null);
                      setselectedcity(null);
                      setselectedpincode(null);
                      setDegreeImage(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {achievementLoader ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
