import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { Action } from "../../../common/Action";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";


export default function Product() {
  const apiname = process.env.REACT_APP_COMPETITORSPRODUCT_API?.toString();
  // const apiname1 = process.env.REACT_APP_PRODUCT_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [deleteID, setdeleteID]: any = useState(null);
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedcompetitorproduct, setselectedcompetitorproduct]: any =
    useState([]);
  const multi = selectedcompetitorproduct?.map((e: any) => {
    return e.CompetitorsProductId;
  });
  useEffect(() => { }, [selectedcompetitorproduct]);

  const filterJson = {
    CompetitorName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CompetitorProductCode: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductGroup: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductClass: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DrugHead: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    PackingTypeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductLine: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    ProductType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    OurCompetingProductName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Mrp: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "2%",
    },
    {
      header: "Action",
      body: (e: any) => actionProductTemplate(e),
      width: "6%",
      sortable: false,
    },
    {
      field: "CompetitorName",
      header: "Competitor Name",
      bodyClassName: "text-info h6",
      filter: true,
      body: null,
      sortable: true,
      width: "20%",
      filterPlaceholder: "Search by competitor name",
    },
    // {
    //   field: "CompetitorProductCode",
    //   header: "Code",
    //   filter: true,
    //   body: (data: any) => {
    //      return NullHandler(data.CompetitorProductCode);
    //   },
    //   sortable: true,
    //   width: "5rem",
    //   filterPlaceholder: "Search by competitor product code",
    // },
    {
      field: "Name",
      header: "Competitor Product Name",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.Name);
      },
      width: "20%",
      sortable: true,
      filterPlaceholder: "Search by competitor product name",
    },

    {
      field: "OurCompetingProductName",
      header: "Our Competing Product",
      body: (data: any) => {
        return NullHandler(data.OurCompetingProductName);
      },
      filter: true,
      sortable: true,
      width: "20%",
      filterPlaceholder: "Search by our competing product",
    },
    {
      field: "Mrp",
      header: "Mrp",
      body: (data: any) => {
        return NullHandler(data.Mrp);
      },
      dataType: "numeric",
      filter: true,
      sortable: true,
      width: "10%",
      filterPlaceholder: "Search by mrp",
    },
    {
      field: "Contents",
      header: "Contents",
      body: (data: any) => {
        return NullHandler(data.Contents);
      },
      width: "20%",
      sortable: true,
    },

  ];

  const actionProductTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.CompetitorsProductId}
          editurl={"layout/master/RCPA/EditCompetitorsProduct"}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> Competitor Product </title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Competitor Product"
          list={true}
          LinkURL={"/layout/master/RCPA/AddCompetitorsProduct"}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          filterJson={filterJson}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedcompetitorproduct}
          selecteditem={selectedcompetitorproduct}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(
                selectedcompetitorproduct?.length === 0 ? null : multi
              );
            }
          }}
        />
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
