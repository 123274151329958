import { useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import AddEditQualificationModal from "./AddEditQualificationModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { NullHandler } from "../../../common/NullHandler";

export default function Qualification() {
  const apiname = process.env.REACT_APP_QUALIFICATION_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [deleteID, setdeleteID]: any = useState(null);
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedqualification, setselectedqualification]: any = useState([]);
  const multi = selectedqualification?.map((e: any) => {
    return e.QualificationId;
  });

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "3%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionQualificationTemplate(e),
      sortable: false,
      width: "7%",
    },
    {
      field: "QualificationName",
      header: "Qualification Name",
      body: null,
      bodyClassName: "text-info h6",
      sortable: true,
      width: "5vw",
    },
    {
      field: "Remarks",
      header: "Remarks",
      body: (data: any) => {
        return NullHandler(data.Remarks);
      },
      width: "5vw",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionQualificationTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.QualificationId}
          editModal={() => {
            setupdateId(id.QualificationId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  const actionSpecialityTemplate = (id: any) => {
    return id.specialityViewModels
      ?.map((ab: any) => {
        return ab.SpecialityName;
      })
      .join(", ");
  };

  return (
    <>
      <Helmet>
        <title>Qualification</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Qualification"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/doctorattributes"}
          linkflagtitle={"Doctor Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          selecteditem={selectedqualification}
          setselecteditem={setselectedqualification}
          multiDelete={(e: boolean) => {
            if (e) {
              setdeleteID(selectedqualification?.length === 0 ? null : multi);
            }
          }}
        />

        {updateId !== null && (
          <AddEditQualificationModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
