import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import Textarea from "../../../common/Textarea";

const AddEditManageCredit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const controller = new AbortController();
  const apiname = process.env.REACT_APP_DOCTORCREDIT_API?.toString();
  const { data, isLoading: ManageCreditLoader }: any = useFetchAllID(
    apiname,
    id,
    undefined,
    controller.signal
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    isLoading,
    error,
    data: response,
  }: any = useCreateByID(apiname, id);

  useEffect(() => {
    if (id) {
      const dateUpdate = data?.CreditDateTime?.split("T")[0];
      reset(data);
      if (data) {
        setValue("CreditDateTime", dateUpdate);
        setselectedCreditBy({
          label: data.CreditName,
          value: data.CreditBy,
        });
        setselecteddoctor({
          label: data.DoctorName,
          value: data.DoctorId,
        });
        setselectedcreditType({
          label: data.CreditTypeName,
          value: data.CreditTypeId,
        });
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const [selectedcredutType, setselectedcreditType]: any = useState(null);

  // Credited By
  const [selectedCreditBy, setselectedCreditBy]: any = useState(null);

  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  const crmAccountData = (data: any) => {
    data.CreditValue = Number(data.CreditValue);
    data.CreditBy = Number(data.CreditBy);
    data.DoctorId = typeof data.DoctorId === "object" ? Number(data.DoctorId.value) : Number(data.DoctorId);

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/crm/managecredit");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/crm/managecredit");
      }
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>{id === undefined ? "Add" : "Edit"} Manage Credit </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/crm/managecredit"}>Manage Credit </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {id === undefined ? "Add" : "Edit"} Manage Credit
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {!ManageCreditLoader ? (
        <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((pinfo: any) => {
              crmAccountData(pinfo);
            })}
          >
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Doctor"
                control={control}
                name="DoctorId"
                onChange={(e: any) => {
                  setselecteddoctor(e);
                  setValue("DoctorId", e?.value);
                }}
                value={selecteddoctor}
                api={process.env.REACT_APP_DOCTORDETAIL_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.DoctorId?.message}
                isRequired
                rules={{ required: { value: true, message: "Doctor is required" } }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Credit Type"}
                isRequired
                name={"CreditTypeId"}
                value={selectedcredutType}
                control={control}
                onChange={(e: any) => {
                  setselectedcreditType(e);
                  setValue("CreditTypeId", e?.value);
                }}
                api={process.env.REACT_APP_CREDITTYPE_API}
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.CreditTypeId?.message ||
                  error?.response?.data?.errors?.CreditTypeId?.map(
                    (e: any) => e
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Credit Type is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Credit Value"
                isRequired
                type="text"
                register={{
                  ...register("CreditValue", {
                    required: {
                      value: true,
                      message: "Credit Value is required",
                    },
                  }),
                }}
                maxLength={5}
                minLength={2}
                onChange={() => clearErrors(["CreditValue"])}
                errorMessage={
                  errors?.CreditValue?.message ||
                  error?.response?.data?.errors?.CreditValue?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-4"
                label={"Credited By"}
                isRequired
                name={"CreditBy"}
                value={selectedCreditBy}
                control={control}
                onChange={(e: any) => {
                  setselectedCreditBy(e);
                  setValue("CreditBy", e?.value);
                }}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                // api="Employee/GetAllEmployeeWithUserId"
                keys={{ label: "Value", value: "Id" }}
                errorMessage={
                  errors?.CreditBy?.message ||
                  error?.response?.data?.errors?.CreditBy?.map((e: any) => e)
                }
                rules={{
                  required: {
                    value: true,
                    message: "Credit By is required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-4"
                label="Credit Date-Time"
                // type="datetime-local"
                type="date"
                isRequired
                errorMessage={
                  errors?.CreditDateTime?.message ||
                  error?.response?.data?.errors?.CreditDateTime?.map(
                    (e: any) => e
                  )
                }
                onChange={() => clearErrors(["CreditDateTime"])}
                register={{
                  ...register("CreditDateTime", {
                    required: {
                      value: true,
                      message: "Date-Time is required.",
                    },
                  }),
                }}
              />

              <Textarea
                containerClass="col-lg-12"
                label="Remarks"
                rows={3}
                isRequired
                maxLength={1000}
                errorMessage={
                  errors?.Remarks?.message ||
                  error?.response?.data?.errors?.Remarks?.map((e: any) => e)
                }
                onChange={() => clearErrors(["Remarks"])}
                register={{
                  ...register("Remarks", {
                    required: { value: true, message: "Remarks is required" },
                    pattern: {
                      value:
                        /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                      message:
                        "Enter Valid Remarks and Remove whitespace from both sides of a string",
                    },
                  }),
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Link to={"/layout/crm/managecredit"}>
                  <Button type="button" severity="secondary" outlined>
                    <span className="p-button-label p-c">Back</span>
                  </Button>
                </Link>

                {id == undefined && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    outlined
                    className="ml-2"
                    onClick={handleSubmit((data: any) => {
                      crmAccountData(data);
                    })}
                  >
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="pd-20 card-box mb-30 text-center">
          <Spinner width={"15rem"} height={"15rem"} />
        </div>
      )}
    </>
  );
};

export default AddEditManageCredit;
