import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DeleteModal } from "../../../../common/DeleteModal";
import { Action } from "../../../../common/Action";
import AddEditCityModal from "./AddEditCityModal";
import { PageHeader } from "../../../../common/PageHeader";
import DatatableCommon from "../../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const City = () => {
  const apiname = process.env.REACT_APP_CITY_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.CityId;
  });

  const filterJson = {
    CityName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "5%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionCityTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "CityName",
      header: "City",
      bodyClassName: "text-info h6",
      body: null,
      filter: true,
      filterPlaceholder: "Search by City",
      sortable: true,
      width: "10vw",
    },

  ];

  const actionCityTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.CityId}
          editModal={() => {
            setupdateId(id.CityId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <>
      <Helmet>
        <title> City </title>
      </Helmet>

      <PageHeader
        headTitle="City"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
      />

      <DatatableCommon
        apiname={apiname}
        columnData={columns}
        refetchData={bool}
        setrefetchData={setbool}
        filterJson={filterJson}
        setselecteditem={setselectedRow}
        selecteditem={selectedRow}
        multiDelete={(e: boolean) => {
          if (e) {
            setdeleteID(selectedRow?.length === 0 ? null : multi);
          }
        }}
      />

      {updateId !== null && (
        <AddEditCityModal
          setisOPen={() => {
            return setupdateId(null);
          }}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
          open={updateId !== null}
          id={updateId}
        />
      )}

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default City;
