import  { useState } from "react";
import { Helmet } from "react-helmet";
import DatatableCommon from "../../common/DatatableCommon";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const apiname = process.env.REACT_APP_USERNOTIFICATION_API?.toString();
  const [bool, setbool]: any = useState(null);
  const [selectedRow, setSelectedRow]: any = useState([]);
  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };
  const navigate = useNavigate()


  const columns = [
    {
      header: "Action",
      body: (e: any) => actionNotificationTemplate(e),
      sortable: false,
      width:"2vw",
    },
    {
      field: "Title",
      header: "Title",
      body: null,
      sortable: true,
      width:"10vw",
    },
    {
      field: "Message",
      header: "Message",
      body: null,
      sortable: true,
      width:"10vw",
    },
    
  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionNotificationTemplate = (id: any) => {
    return (
      <>
        <button className="btn btn-success" onClick={()=>{
          navigate(`/layout/ViewNotification/${id.Id}`)
        }}>
        <i
            className="icon-copy fa fa-eye"
            style={{ fontSize: "20px" }}
            aria-hidden="true"
          ></i>
        </button>
      </>
    );
  };
  return (
    <>
      <Helmet>
        <title>Announcement</title>
      </Helmet>
      <div className="min-height-200px">
        {/* <PageHeader headTitle="Anoouncement" list={true} LinkURL={"/layout/master/Announcement/addannouncement"} /> */}
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setSelectedRow}
          selecteditem={selectedRow}
          // check={true}
        />
      </div>
    </>
  );
};

export default Notification;
