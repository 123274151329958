import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../common/Address";
import { InputMask } from "primereact/inputmask";
import { ErrorMessage } from "../../../common/ErrorMessage";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  parameter?: boolean;
}

const AddEditStockistModal = ({
  id,
  setisOPen,
  open,
  fetch,
  parameter = false,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_STOCKIST_API?.toString();
  const controller = new AbortController();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    clearErrors,
    watch,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: StockistLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    undefined,
    controller.signal
  );

  const formd = watch("CityId");
  const role = localStorage.getItem("Roles");

  useEffect(() => {
    if (id) {
      if (data) {

        reset(data);

        if (data.StockistTypeId !== null && data.StockistTypeId !== undefined) {

          setselectedstockisttype({
            label: data.StockistTypeName,
            value: data.StockistTypeId,
          });
          setValue("StockistTypeId", data.StockistTypeId);

        } else {
          setValue("StockistTypeId", null);
          setselectedstockisttype(null);
        }

        if (data.CityId !== null) {
          setValue("CityId", {
            label: data.CityName,
            value: data.CityId,
          });
          setselectedcity({
            label: data.CityName,
            value: data.CityId,
          });
        } else {
          setValue("CityId", null);
          setselectedcity(null);
        }
        if (data.PincodeId !== null) {
          setValue("PincodeId", {
            label: data.Pincode,
            value: data.PincodeId,
          });
          setselectedPincode({
            label: data.Pincode,
            value: data.PincodeId,
          });
        } else {
          setValue("PincodeId", null);
          setselectedPincode(null);
        }

        data.EmpList = data?.EmpList?.map((d: any) => ({
          label: d?.EmployeeName,
          value: d?.EmployeeId,
        }));
        setValue("EmployeeIds", data.EmpList);
        setselectedemployee(data?.EmpList);
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    data: response,
    isLoading,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const [selectedstockisttype, setselectedstockisttype]: any = useState(null);
  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedpincode, setselectedPincode]: any = useState(null);
  const [selectedemployee, setselectedemployee] = useState([]);

  const FormData = (data: any) => {


    data.IsUnlinked = parameter;

    data.EmployeeIds = selectedemployee?.map((e: any) => {
      return e?.value;
    });

    data.MobileNo1 = data.MobileNo1?.replace(/[^\d]/g, "");
    data.MobileNo2 = data.MobileNo2?.replace(/[^\d]/g, "");
    data.MobileNo3 = data.MobileNo3?.replace(/[^\d]/g, "");

    data.CityId = selectedcity?.value;

    data.PincodeId = selectedpincode?.value;

    // for (let prop in data) {
    //   if(data[prop] === null || data[prop] === undefined || data[prop] === "" || data[prop]?.length == 0){
    //     delete data[prop]
    //   }
    // }

    if (
      data.AddressLine1 === null ||
      data.AddressLine1 === undefined ||
      data.AddressLine1 === ""
    ) {
      delete data.AddressLine1;
    }

    if (
      data.AddressLine2 === null ||
      data.AddressLine2 === undefined ||
      data.AddressLine2 === ""
    ) {
      delete data.AddressLine2;
    }

    if (data.CityId === null || data.CityId === undefined) {
      delete data.CityId;
    }

    if (data.PincodeId === null || data.PincodeId === undefined) {
      delete data.PincodeId;
    }

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  // Success & Error Messages
  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true);
      }
      setisOPen(false);
    }

    if (isError) {
      toast.error("error...", { position: "top-right" });
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Stockist`}
        modal
        style={{ width: "1250px" }}
      >
        {!StockistLoader ? (
          <div className="pd-20 card-box mb-30">
            <form
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.key === "NumpadEnter") {
                  handleSubmit(FormData);
                }
              }}
              onSubmit={handleSubmit(FormData)}
            >
              <div className="row">
                <Input
                  containerClass="col-lg-4"
                  label="Stockist Name"
                  isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("StockistName", e.target.value);
                    clearErrors(["StockistName"]);
                  }}
                  register={{
                    ...register("StockistName", {
                      required: {
                        value: true,
                        message: "Stockist Name is required",
                      },
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid name (1-50 alphabet characters or special characters)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.StockistName?.message ||
                    error?.response?.data?.errors?.StockistName?.map(
                      (e: any) => e
                    )
                  }
                />

                <ReactSelect
                  containerClass="col-lg-4"
                  label="Stockist Type"
                  name={"StockistTypeId"}
                  onChange={(e: any) => {
                    // setValue("StockistTypeId", e?.value);
                    // setselectedstockisttype(e);
                    if (e?.value !== undefined) {
                      setValue("StockistTypeId", e?.value);
                      setselectedstockisttype(e);

                    } else {
                      setValue("StockistTypeId", null);
                      setselectedstockisttype(null);
                    }
                  }}
                  isRequired
                  value={selectedstockisttype}
                  control={control}
                  api={process.env.REACT_APP_STOCKISTTYPE_API}
                  keys={{ label: "Value", value: "Id" }}
                  errorMessage={
                    errors?.StockistTypeId?.message ||
                    error?.response?.data?.errors?.StockistTypeId?.map(
                      (e: any) => e
                    )
                  }
                  rules={{
                    required: {
                      value: true,
                      message: "Stockist Type is required",
                    }
                  }}
                />

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      Mobile No.1<span className="text-danger">*</span>
                    </label>
                    <InputMask
                      {...register("MobileNo1", {
                        required: {
                          value: true,
                          message: "Mobile No 1 is required",
                        },
                      })}
                      mask="(999) 999-9999"
                      className="form-control"
                      onChange={(e) => {
                        setValue("MobileNo1", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      message={
                        errors?.MobileNo1?.message ||
                        error?.response?.data?.errors?.MobileNo1?.map(
                          (e: any) => e
                        )
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">Mobile No.2</label>
                    <InputMask
                      {...register("MobileNo2")}
                      mask="(999) 999-9999"
                      className="form-control"
                      onChange={(e) => {
                        setValue("MobileNo2", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      message={
                        errors?.MobileNo2?.message ||
                        error?.response?.data?.errors?.MobileNo2?.map(
                          (e: any) => e
                        )
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">Mobile No.3</label>
                    <InputMask
                      {...register("MobileNo3")}
                      mask="(999) 999-9999"
                      className="form-control"
                      onChange={(e) => {
                        setValue("MobileNo3", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      message={
                        errors?.MobileNo3?.message ||
                        error?.response?.data?.errors?.MobileNo3?.map(
                          (e: any) => e
                        )
                      }
                    />
                  </div>
                </div>

                <Input
                  containerClass="col-lg-4"
                  label="Phone No"
                  type="text"
                  onChange={(e: any) => {
                    setValue("PhoneNo", e.target.value);
                    clearErrors(["PhoneNo"]);
                  }}
                  register={{
                    ...register("PhoneNo", {
                      pattern: {
                        value: /^\d{6,10}$/,
                        message: "Enter a valid phone no. (6-10 digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.PhoneNo?.message ||
                    error?.response?.data?.errors?.PhoneNo?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Place"
                  type="text"
                  onChange={(e: any) => {
                    setValue("StockistPlace", e.target.value);
                    clearErrors(["StockistPlace"]);
                  }}
                  register={{
                    ...register("StockistPlace", {
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid place (2-50 alphabet characters or special characters)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.StockistPlace?.message ||
                    error?.response?.data?.errors?.StockistPlace?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="CFA Location"
                  // isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("CfaLocation", e.target.value);
                    clearErrors(["CfaLocation"]);
                  }}
                  register={{
                    ...register("CfaLocation", {
                      // required: {
                      //   value: true,
                      //   message: "CFA Location is required",
                      // },
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid CFA Location (2-50 alphabet characters or special characters)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.CfaLocation?.message ||
                    error?.response?.data?.errors?.CfaLocation?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Contact Person"
                  // isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("ContactPerson", e.target.value);
                    clearErrors(["ContactPerson"]);
                  }}
                  register={{
                    ...register("ContactPerson", {
                      // required: {
                      //   value: true,
                      //   message: "Contact Person is required",
                      // },
                      pattern: {
                        value: /^.{2,50}$/gm,
                        message:
                          "Enter a valid contact person (2-50 characters)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.ContactPerson?.message ||
                    error?.response?.data?.errors?.ContactPerson?.map(
                      (e: any) => e
                    )
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Email"
                  // isRequired
                  type="email"
                  onChange={(e: any) => {
                    setValue("Email", e.target.value);
                    clearErrors(["Email"]);
                  }}
                  register={{
                    ...register("Email", {
                      // required: { value: true, message: "Email is required" },
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)$/,
                        message: "Email format is Invalid..",
                      },
                    }),
                  }}
                  maxLength={50}
                  errorMessage={
                    errors?.Email?.message ||
                    error?.response?.data?.errors?.Email?.map((e: any) => e)
                  }
                />

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">DL No1</label>
                    <InputMask
                      {...register("DLno1", {
                        // required: {
                        //   value: true,
                        //   message: "DLno1 is required",
                        // },
                      })}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      mask="9999 99999999999"
                      className="form-control"
                    />
                    <ErrorMessage
                      message={
                        errors?.DLno1?.message ||
                        error?.response?.data?.errors?.DLno1?.map((e: any) => e)
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="col-form-label">DL No2</label>
                    <InputMask
                      {...register("DLno2", {
                        // required: {
                        //   value: true,
                        //   message: "DLno2 is required",
                        // },
                      })}
                      onChange={(e) => setValue(e.target.value)}
                      mask="9999 99999999999"
                      className="form-control"
                    />
                    <ErrorMessage
                      message={
                        errors?.DLno2?.message ||
                        error?.response?.data?.errors?.DLno2?.map((e: any) => e)
                      }
                    />
                  </div>
                </div>

                <Input
                  containerClass="col-lg-4"
                  label="FSSAI"
                  // isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("FssAi", e.target.value);
                    clearErrors(["FssAi"]);
                  }}
                  register={{
                    ...register("FssAi", {
                      // required: { value: true, message: "FSSAI is required" },
                      pattern: {
                        value: /^[0-9]{14}$/,
                        message: "Enter a valid FSSAI (14 digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.FssAi?.message ||
                    error?.response?.data?.errors?.FssAi?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="Code Ref"
                  // isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("CodeRef", e.target.value);
                    clearErrors(["CodeRef"]);
                  }}
                  register={{
                    ...register("CodeRef", {
                      pattern: {
                        value: /^\w[A-Za-z0-9]{2,5}$/,
                        message:
                          "Enter a valid code ref (2-5 alphanumeric characters)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.CodeRef?.message ||
                    error?.response?.data?.errors?.CodeRef?.map((e: any) => e)
                  }
                />

                <Input
                  containerClass="col-lg-4"
                  label="GST No."
                  // isRequired
                  type="text"
                  onChange={(e: any) => {
                    setValue("GstNo", e.target.value);
                    clearErrors(["GstNo"]);
                  }}
                  register={{
                    ...register("GstNo", {
                      // required: { value: true, message: "GST is required" },
                      pattern: {
                        value: /^[A-Z0-9]{15}$/,
                        message:
                          "Enter a valid GstNo (15 Capital letters & digits only)",
                      },
                    }),
                  }}
                  errorMessage={
                    errors?.GstNo?.message ||
                    error?.response?.data?.errors?.GstNo?.map((e: any) => e)
                  }
                />

                {role !== "MR" && (
                  <ReactSelect
                    containerClass="col-lg-4"
                    label={"Employee"}
                    name={"EmployeeIds"}
                    onChange={(e: any) => {
                      setValue("EmployeeIds", e);
                      setselectedemployee(e);
                    }}
                    value={selectedemployee}
                    control={control}
                    api={process.env.REACT_APP_EMPLOYEE_API}
                    keys={{ label: "Value", value: "Id" }}
                    errorMessage={
                      errors?.EmployeeIds?.message ||
                      error?.response?.data?.errors?.EmployeeIds?.map(
                        (e: any) => e
                      )
                    }
                    Multi
                  />
                )}

                <Address
                  header="Address"
                  control={control}
                  address1register={{
                    ...register("AddressLine1", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message:
                          "Enter a valid Address(2-250 alphanumeric characters)",
                      },
                    }),
                  }}
                  address1onChange={(e: any) => {
                    setValue("AddressLine1", e.target.value);
                    clearErrors(["AddressLine1"]);
                  }}
                  address1errorMessage={
                    errors?.AddressLine1?.message ||
                    error?.response?.data?.errors?.AddressLine1?.map(
                      (e: any) => e
                    )
                  }
                  address2register={{
                    ...register("AddressLine2", {
                      pattern: {
                        value: /^.{2,250}$/gm,
                        message:
                          "Enter a valid Area(2-250 alphanumeric characters)",
                      },
                    }),
                  }}
                  address2onChange={(e: any) => {
                    setValue("AddressLine2", e.target.value);
                    clearErrors(["AddressLine2"]);
                  }}
                  address2errorMessage={
                    errors?.AddressLine2?.message ||
                    error?.response?.data?.errors?.AddressLine2?.map(
                      (e: any) => e
                    )
                  }
                  address4onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("CityId", e?.value);
                      setselectedcity(e);
                      setselectedPincode({});
                    } else {
                      setValue("CityId", null);
                      setValue("PincodeId", null);
                      setselectedcity(null);
                      setselectedPincode(null);
                    }
                  }}
                  address4errorMessage={
                    errors?.CityId?.message ||
                    error?.response?.data?.errors?.CityId?.map((e: any) => e)
                  }
                  selectedCity={selectedcity}
                  address5onChange={(e: any) => {
                    if (e?.value !== undefined) {
                      setValue("PincodeId", e?.value);
                      setselectedPincode(e);
                    } else {
                      setValue("PincodeId", null);
                      setselectedPincode(null);
                    }
                  }}
                  address5errorMessage={
                    errors?.PincodeId?.message ||
                    error?.response?.data?.errors?.PincodeId?.map((e: any) => e)
                  }
                  selectedPincode={selectedpincode}
                  cityKeys={{ label: "Value", value: "Id" }}
                  cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                  cityName={"CityId"}
                  cityrules={{
                    required: { value: true, message: "Please select city" },
                  }}
                  pincodeKeys={{ label: "Value", value: "Id" }}
                  pincodeApi={
                    selectedcity !== null ?
                      `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedcity?.value}`
                      : ""
                  }
                  pincodeName={"PincodeId"}
                  pincodeDisable={selectedcity !== null ? false : true}
                  pincoderules={{
                    required: { value: true, message: "Pincode is required" },
                  }}
                />

                <div className="col-lg-12 text-center mt-3">
                  <Button
                    type="button"
                    severity="secondary"
                    outlined
                    onClick={() => setisOPen(false)}
                  >
                    <span className="p-button-label p-c">Back</span>
                  </Button>

                  {id === 0 && (
                    <Button
                      type="reset"
                      severity="danger"
                      onClick={() => {
                        reset();
                        setselectedemployee([]);
                        setselectedPincode({});
                        setselectedcity(null);
                        setselectedstockisttype([]);
                      }}
                      outlined
                      className="ml-2"
                    >
                      <span className="p-button-label p-c">Reset</span>
                    </Button>
                  )}

                  {isLoading ? (
                    <Button type="submit" outlined disabled className="ml-2">
                      <span className="p-button-label p-c">
                        Submit <Spinner width={"1rem"} height={"1rem"} />
                      </span>
                    </Button>
                  ) : (
                    <Button type="submit" outlined className="ml-2">
                      <span className="p-button-label p-c">Submit</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditStockistModal;
