import React, { useState } from 'react'
import { Action } from '../../../../common/Action';
import { DeleteModal } from '../../../../common/DeleteModal';
import DatatableCommon from '../../../../common/DatatableCommon';
import { Helmet } from 'react-helmet';
import { PageHeader } from '../../../../common/PageHeader';
import AddEditNoWorkingAllowance from './AddEditNoWorkingAllowance';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { NullHandler } from "../../../../common/NullHandler";

const NoWorkingAllowance = () => {

    const apiname = process.env.REACT_APP_NOWORKINGALLOWANCES_API?.toString();

    const [deleteID, setdeleteID]: any = useState(null);
  
    const [selectedRows, setSelectedRows]: any = useState([]);
    const multi = selectedRows?.map((e: any) => {
      return e.NoWorkingAllowanceId;
    });
  
    const [bool, setbool]: any = useState(null);
  
    const deleteiddetect = (row: any) => {
      setdeleteID(row);
    };

    const filterJson = {
      NwReasonCode: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      EmployeeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      CalculationTypeName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      Criteria: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    }
  
    const columnData = [
      {
        header: "Action",
        sortable: false,
        filter: false,
        body: (e: any) => actionNoWorkingAllowanceTemplate(e),
        width:"1vw",
      },
      {
        field: "EmployeeName",
        header: "Employee Name",
        bodyClassName: "text-info h6",
        sortable: true,
        body: null,
        filter:true,
        filterPlaceholder:"Search by employee name",
        width:"10vw",
      },
      {
        field: "NwReasonCode",
        header: "Nw Reason Code",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.NwReasonCode);
        },
        filter:true,
        filterPlaceholder:"Search by nw reason code",
        width:"10vw",
      },
      {
        field: "CalculationTypeName",
        header: "Calculation Type",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.CalculationTypeName);
        },
        filter:true,
        filterPlaceholder:"Search by nw calculation type",
        width:"10vw",
      },
      {
        field: "Criteria",
        header: "Criteria",
        sortable: true,
        body: (data: any) => {
          return NullHandler(data.Criteria);
        },
        filter:true,
        dataType:"numeric",
        filterPlaceholder:"Search by nw criteria",
        width:"10vw",
      },
     
    ];
  
    const [updateId, setupdateId]: any = useState(null);
    const actionNoWorkingAllowanceTemplate = (id: any) => {
      return (
        <>
          <Action
            id={id.NoWorkingAllowanceId}
            editModal={() => {
              setupdateId(id.NoWorkingAllowanceId);
            }}
            displayid={deleteiddetect}
          />
        </>
      );
    };


  return (
    <>
      <Helmet>
        <title>No Working Allowance</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="No Working Allowance"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedRows?.length === 0 ? null : multi);
            }
          }}
          setselecteditem={setSelectedRows}
          filterJson={filterJson}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditNoWorkingAllowance
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      /> 
    </>
  )
}

export default NoWorkingAllowance
