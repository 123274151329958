import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useCreate } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import ReactSelect from "../../../common/ReactSelect";
import axiosInstance from "../../../_helper/Interceptor";
import { Tooltip } from 'primereact/tooltip';

export const AddEditEmployeeJWRObservation = () => {
  const controller = new AbortController();
  const apiname = process.env.REACT_APP_EMPLOYEEJWROBSERVATION_API?.toString();
  const [totalData, settotalData]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  }: any = useForm({
    defaultValues: {
      EmployeeId: "",
      Month: "",
      jwrScores: totalData,
    },
  });

  const [Loding, setIsLoading]: any = useState(null);

  const GridData = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REPORTINGJWROBSERVATION_API}`, { signal: controller.signal })
      .then((res) => {
        const temp = res.data.Data;
        settotalData(temp);
        setIsLoading(false);
      })
      .catch((error) => {
        if (controller.signal.reason.code !== 20) {

          setIsLoading(false);
          toast.error(`${error.message}`, { position: "top-right" });
        }
      });
  };

  useEffect(() => {
    GridData();
    return () => {
      controller.abort();
    }
  }, []);

  const [selectedemployee, setselectedemployee] = useState({});

  const { mutate, isSuccess, isError, error, isLoading }: any =
    useCreate(apiname);

  const employeejwrobservationform = async (data: any) => {

    const jwrdata = data?.jwrScores.map((e: any, index: any) => {
      return {
        JwrId: totalData[index].Id,
        Score: Number(e.Score),
      };
    });
    data.jwrScores = jwrdata;

    console.log(data, "xyz");
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      reset();
      setselectedemployee({});
    }

    if (isError) {
      toast.error("Error....", { position: "top-right" });
    }
  }, [isSuccess, isError]);

  const [date, setDate] = useState(null)

  return (
    <>
      <Helmet>
        <title>Add Employee JWR Observation</title>
      </Helmet>
      <PageHeader
        headTitle="Employee JWR Observation"
        headTitleURL={"/layout/AddEmployeeJWRObservation"}
        list={false}
      />

      <form
        onSubmit={handleSubmit((info: any) => {
          employeejwrobservationform(info);
        })}
      >
        <div className="pd-20 card-box mb-30">
          <div className="row">
            <Input
              containerClass="col-lg-6"
              label="Month"
              type="date"
              isRequired
              errorMessage={errors?.Month?.message}
              onChange={(e: any) => {
                console.log(e.target.value, "data")
                setDate(e.target.value)
              }}
              register={{
                ...register("Month", {
                  required: {
                    value: true,
                    message: "Month is required",
                  },
                }),
              }}
            />
            <ReactSelect
              containerClass="col-lg-6"
              label={"Employee"}
              isRequired
              flag
              name={"EmployeeId"}
              onChange={(e: any) => {
                setselectedemployee(e);
                setValue("EmployeeId", e?.value);
              }}
              value={selectedemployee}
              Disabled={date !== null ? false : true}
              control={control}
              api={
                date !== null
                  ? `${process.env.REACT_APP_EMPLOYEELISTJWR_API}?Month=${date}`
                  : ""
              }
              keys={{ label: "Value", value: "Id" }}
              errorMessage={
                errors?.EmployeeId?.message ||
                error?.response?.data?.errors?.EmployeeId?.map((e: any) => e)
              }
              rules={{
                required: { value: true, message: "Employee is required" },
              }}
            />

            <div className="col-lg-12">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="text-center">
                    <th>Title</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {totalData?.map((field: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td>
                          {field.Title + " "}
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                            data-pr-tooltip={field.Guideline}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"
                            style={{ fontSize: "1.2rem", cursor: "pointer", }}
                          >
                          </i>
                        </td>
                        <td>
                          <div className="mt-3">
                            <Input
                              containerClass="col-lg-12"
                              isRequired
                              type="text"
                              register={{
                                ...register(`jwrScores.${i}.Score`, {
                                  required: {
                                    value: true,
                                    message: "Score is Required",
                                  },
                                  validate: (value: any) =>
                                    value <= field.MaxScore ||
                                    "Value should be less or equal to max score",
                                }),
                              }}
                              errorMessage={
                                errors?.["jwrScores"]?.[i]?.["Score"]?.message
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button type="submit" className="btn btn-outline-success">
                  Submit
                </button>
              ) : (
                <button className="btn btn-success" disabled>
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
