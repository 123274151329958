import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSelect from "../../common/ReactSelect";
import axiosInstance from "../../_helper/Interceptor";
import { useForm } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const AllGeography = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  }: any = useForm();
  const products = [
    {
      srno: "1",
      contry: "Contry Name",
      zone: "Zone Name",
      state: "State Name",
      region: "Region Name",
      district: "District Name",
      city: "City Name",
      pincode: "Pincode Name",
    },
  ];
  return (
    <>
      <Helmet>
        <title> Geography </title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Geography </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Geography
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form>
          <div className="row">
            <div className="col-4 row align-items-center">
              <div className="col-2 " style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="Contry"
                label="Contry"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
            <div className="col-4 row align-items-center">
              <div className="col-2" style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="Zone"
                label="Zone"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
            <div className="col-4 row align-items-center">
              <div className="col-2" style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="State"
                label="State"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 row align-items-center">
              <div className="col-2" style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="Region"
                label="Region"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
            <div className="col-4 row align-items-center">
              <div className="col-2" style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="District"
                label="District"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
            <div className="col-4 row align-items-center">
              <div className="col-2" style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="City"
                label="City"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 row align-items-center">
              <div className="col-2" style={{ paddingTop: "16px" }}>
                <button className="btn btn-primary btn-sm">
                  <span className="micon bi bi-plus"></span>
                </button>
              </div>
              <ReactSelect
                containerClass="col-10"
                control={control}
                isRequired
                // options={{}}
                api=""
                keys={{ label: "Value", value: "Id" }}
                name="Pincode"
                label="Pincode"
                rules={{
                  required: { value: true, message: "Division is required" },
                }}
                errorMessage={errors?.DivisionId?.message}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="card">
        <DataTable value={products} tableStyle={{ minWidth: "50rem" }}>
          <Column field="srno" header="SR. No" sortable style={{}}></Column>
          <Column field="contry" header="Contry" sortable style={{}}></Column>
          <Column field="zone" header="Zone" sortable style={{}}></Column>
          <Column field="state" header="State" sortable style={{}}></Column>
          <Column field="region" header="Region" sortable style={{}}></Column>
          <Column
            field="district"
            header="District"
            sortable
            style={{}}
          ></Column>
          <Column field="city" header="City" sortable style={{}}></Column>
          <Column field="pincode" header="Pincode" sortable style={{}}></Column>
        </DataTable>
      </div>
    </>
  );
};
