import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Action } from "../../../../common/Action";
import { DeleteModal } from "../../../../common/DeleteModal";
import AddEditRegionModal from "./AddEditRegionModal";
import { PageHeader } from "../../../../common/PageHeader";
import DatatableCommon from "../../../../common/DatatableCommon";

export const Region = () => {
  const apiname = process.env.REACT_APP_REGION_API?.toString();
  const [bool, setbool]: any = useState(true);
  const [deleteID, setdeleteID]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);
  const multi = selectedRow?.map((e: any) => {
    return e.RegionId;
  });
  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "5%",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionRegionTemplate(e),
      sortable: false,
      width: "30%",
    },
    {
      field: "RegionName",
      header: "Region",
      bodyClassName: "text-info h6",
      body: null,
      sortable: true,
      width: "10vw",
    },

  ];

  const actionRegionTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.RegionId}
          editModal={() => {
            setupdateId(id.RegionId);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  const [updateId, setupdateId]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  return (
    <>
      <Helmet>
        <title> Region </title>
      </Helmet>

      <PageHeader
        headTitle="Region"
        onClick={() => {
          setupdateId(0);
        }}
        id={0}
        list={true}
      />

      <DatatableCommon
        apiname={apiname}
        columnData={columns}
        refetchData={bool}
        setrefetchData={setbool}
        setselecteditem={setselectedRow}
        selecteditem={selectedRow}
        multiDelete={(e: boolean) => {
          if (e) {
            setdeleteID(selectedRow?.length === 0 ? null : multi);
          }
        }}
      />

      {updateId !== null && (
        <AddEditRegionModal
          setisOPen={() => {
            return setupdateId(null);
          }}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
          open={updateId !== null}
          id={updateId}
        />
      )}

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
