import React, { useEffect, useState } from 'react'
import ReactSelect from '../../../common/ReactSelect'
import { set, useFieldArray, useForm } from 'react-hook-form';
import axiosInstance from '../../../_helper/Interceptor';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../../common/Input';
import Textarea from '../../../common/Textarea';
import { useCreate } from '../../../hooks/Api/Api';
import { toast } from 'react-toastify';
import { Alert } from '../../../common/Alert';
import { Helmet } from 'react-helmet';
import AddEditCallTypeModal from '../../master/submaster/callType/AddEditCallTypeModal';
import AddEditCallObjectiveModal from '../../master/submaster/callObjective/AddEditCallObjectiveModal';
import { DeleteModal } from '../../../common/DeleteModal';
import Select from 'react-select';
import { ErrorMessage } from '../../../common/ErrorMessage';

const DoctorModel = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const apiname = process.env.REACT_APP_DOCTORVISIT_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  }: any = useForm({
    defaultValues: {
      WorkingWith: "",
      CallTypeId: "",
      CallObjective: "",
      DoctorFeedback: "",
      PromotedProducts: [{ ProductId: "", Qty: "", SampleQty: "", Isprescribe: "" }],
      PromotedGift: [{ GiftId: "", SampleQty: "" }]
    }
  });

  const { mutate, isSuccess, isError, error }: any = useCreate(apiname)

  const { fields: products, append: productadd, remove: productremove } = useFieldArray({
    control,
    name: "PromotedProducts"
  })

  const { fields: gifts, append: giftadd, remove: giftremove } = useFieldArray({
    control,
    name: "PromotedGift"
  })

  const [callTypeOptions, setcallTypeOptions] = useState([]);
  const [selectedcallType, setselectedcallType]: any = useState({});
  const fetchcallTypeOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CALLTYPE_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.CallTypeName,
        value: option?.CallTypeId,
      };
    });
    setcallTypeOptions(data);
  };
  const [calltype, setcalltype]: any = useState(null)
  const [defaultValue, setdefaultValue]: any = useState(null);
  const handlecalltypeChange = (inputvalue: any) => {
    setcalltype(0);
    setdefaultValue(inputvalue)
  }

  const [callObjectiveOptions, setcallObjectiveOptions] = useState([]);
  const [selectedcallObjective, setselectedcallObjective]: any = useState({});
  const fetchcallObjectiveOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CALLOBJECTIVE_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.CallObjectiveName,
        value: option?.CallObjectiveId,
      };
    });
    setcallObjectiveOptions(data);
  };
  const [callobjective, setcallobjective]: any = useState(null)
  const handlecallobjectiveChange = (inputvalue: any) => {
    setcallobjective(0);
    setdefaultValue(inputvalue)
  }

  const [joinworkwithOptions, setjoinworkwithOptions] = useState([]);
  const [selectedjoinworkwith, setselectedjoinworkwith]: any = useState({});
  const fetchjoinworkwithOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GETALLEMPLOYEEBYDESIGNATION_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.EmployeeName,
        value: option?.UserId,
      };
    });
    setjoinworkwithOptions(data);
  };

  const [productOptions, setproductOptions] = useState([]);
  const fetchproductOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRODUCT_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.Name,
        value: option?.ProductId,
      };
    });
    setproductOptions(data);
  };

  const [giftOptions, setgiftOptions] = useState([]);
  const [selectedgift, setselectedgift]: any = useState([]);
  const fetchgiftOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GIFTMASTER_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.GiftName,
        value: option?.GiftMasterId,
      };
    });
    setgiftOptions(data);
  };

  const [deleteID, setdeleteID]: any = useState(null);
  const [api, setApi] = useState("");

  useEffect(() => {
    fetchjoinworkwithOptions()
    fetchcallTypeOptions()
    fetchcallObjectiveOptions()
    fetchproductOptions()
    fetchgiftOptions()
  }, [])

  const doctorModel = async (data: any) => {
    delete data.DoctorFeedback;
    console.log(data)
    const formData: any = new FormData();
    console.log(selectedcallObjective?.value, "selectedcallObjective")
    console.log(selectedcallType?.value, "selectedcallType")
    formData.append("id", Number(location.state.id))
    formData.append("CallObjective", selectedcallObjective?.value)
    formData.append("CallTypeId", selectedcallType?.value)
    formData.append("WorkingWith", selectedjoinworkwith.value)
    data.PromotedProducts.map((e: any, index: any) => {
      console.log(e, "aniket")
      formData.append("PromotedProducts[" + index + "].ProductId", e?.ProductId)
      formData.append("PromotedProducts[" + index + "].Qty", e.Qty)
      formData.append("PromotedProducts[" + index + "].SampleQty", e.SampleQty)
      formData.append("PromotedProducts[" + index + "].Isprescribe", e.Isprescribe === true ? true : false)
    })
    data.PromotedGift.map((e: any, index: any) => {
      console.log(e, "Krima")
      formData.append("PromotedGift[" + index + "].GiftId", e?.GiftId)
      formData.append("PromotedGift[" + index + "].SampleQty", e.SampleQty)
    })

  
    mutate(formData)
  }

  const [enotify, setEnotify] = useState(false);
  const [color, setColor] = useState("");
  useEffect(() => {
    if (isSuccess) {
      navigate("/layout/mr/visit")
      toast.success("Visit Added Successfully", { position: "top-right" });
    }
    if (isError) {
      setColor("danger");
      setEnotify(true);
      setTimeout(() => {
        setEnotify(false);
      }, 3000);
    }
  }, [isError, isSuccess]);
  return (
    <>
      <div>
        <Helmet>
          <title>Doctor Detail</title>
        </Helmet>
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Doctor Detail</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/layout/dashboard">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Doctor Detail</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <h4>Doctor Name:- <span className='text-blue'>{location.state.name}</span></h4>
            </div>
          </div>
        </div>
        <div className="pd-20 card-box">
          {enotify && (
            <Alert color={color} message={error?.response?.data?.Message} />
          )}
          <form onSubmit={handleSubmit((giftmasterinfo: any) => {
            doctorModel(giftmasterinfo);
          })}>
            <div className="row">
              <ReactSelect
                containerClass="col-lg-4"
                label="Call type"
                control={control}
                name="CallTypeId"
                isRequired
                useCustomOption
                onDelete={(deletedValue: number) => {
                  setdeleteID(deletedValue);
                  setApi("CallType");
                }}
                updateid={setcalltype}
                onChange={(e: any) => { setselectedcallType(e); setValue("CallTypeId", e?.value) }}
                value={selectedcallType}
                options={callTypeOptions}
                handleCreate={handlecalltypeChange}
                errorMessage={errors?.CallTypeId?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Call Type is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Call Objective"
                control={control}
                name="CallObjective"
                isRequired
                onChange={(e: any) => { setselectedcallObjective(e); setValue("CallObjective", e?.value) }}
                value={selectedcallObjective}
                options={callObjectiveOptions}
                useCustomOption
                onDelete={(deletedValue: number) => {
                  setdeleteID(deletedValue);
                  setApi("CallObjective");
                }}
                updateid={setcallobjective}
                handleCreate={handlecallobjectiveChange}
                errorMessage={errors?.CallObjective?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Call Objective is required",
                  },
                }}
              />

              <ReactSelect
                containerClass="col-lg-4"
                label="Joint Work With"
                control={control}
                name="WorkingWith"
                isRequired
                onChange={(e: any) => { setselectedjoinworkwith(e); setValue("WorkingWith", e?.value) }}
                value={selectedjoinworkwith}
                options={joinworkwithOptions}
                errorMessage={errors?.WorkingWith?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Working With is required",
                  },
                }}
              />
            </div>
            <div className='row mb-3'>
              <div className='col-md-6'>
                <h4 className="mt-3">Product</h4>
              </div>
              <div className='col-md-6 text-right'>
                <button type='button' onClick={() => {
                  productadd({ ProductId: "", Qty: "", SampleQty: "", Isprescribe: "" })
                }} className="btn btn-sm btn-outline-primary mt-4">Add Product</button>

              </div>
            </div>
            {/* {
              products.map((field, index) => {
                return (
                  <>
                    <div className="row ">
                      <ReactSelect
                        containerClass="col-lg-3"
                        label="Product"
                        control={control}
                        name={`PromotedProducts.${index}.ProductId`}
                        isRequired
                        onChange={(e: any) => { setValue(`PromotedProducts.${index}.ProductId`, e?.value) }}
                        // value={selectedproduct}
                        options={productOptions}
                        errorMessage={errors?.['PromotedProducts']?.[index]?.['ProductId']?.message}
                        rules={{
                          required: {
                            value: true,
                            message: "Product is required",
                          },
                        }}
                      />

                      <Input
                        containerClass='col-lg-3'
                        label='Order Qty'
                        isRequired
                        type='text'
                        register={{
                          ...register(`PromotedProducts.${index}.Qty`, {
                            required: { value: true, message: "Order Qty is Required" }
                          })
                        }}
                        errorMessage={errors?.['PromotedProducts']?.[index]?.['Qty']?.message}
                      />

                      <Input
                        containerClass='col-lg-3'
                        label='Sample Qty'
                        isRequired
                        type='text'
                        register={{
                          ...register(`PromotedProducts.${index}.SampleQty`, {
                            required: { value: true, message: "Sample Qty is Required" }
                          })
                        }}
                        errorMessage={errors?.['PromotedProducts']?.[index]?.['SampleQty']?.message}
                      />

                      <div className="col-lg-3">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox mt-4">
                            <input
                              {
                              ...register(`PromotedProducts.${index}.Isprescribe`, {
                              })
                              }
                              id={`PromotedProducts.${index}.Isprescribe`} type="checkbox" className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor={`PromotedProducts.${index}.Isprescribe`}>Isprescribe</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 text-center">
                        <button type='button' onClick={() => { productremove(index) }} className="btn btn-sm btn-outline-danger">Delete Product</button>
                      </div>
                    </div>


                  </>
                )
              })
            } */}

            <table className='table table-bordered table-striped'>
              <thead>
                <tr className='text-center'>
                  <th>Product Name</th>
                  <th>Product Quantity</th>
                  <th>Sample Quantity</th>
                  <th>Is Prescribe</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  products.map((field, index) => {

                    return (
                      <tr key={index}>
                        <td>
                          <Select
                            {...register(`PromotedProducts.${index}.ProductId`, {
                              required: {
                                value: true,
                                message: "Product is required",
                              }
                            })}
                            options={productOptions}
                            className='z-3'
                            classNamePrefix={"z-3"}
                            onChange={(e: any) => { setValue(`PromotedProducts.${index}.ProductId`, e?.value) }}
                          />
                           <ErrorMessage message={errors?.['PromotedProducts']?.[index]?.['ProductId']?.message} />
                        </td>
                        <td>
                          <div className='mt-3'>
                            <Input
                              containerClass='col-lg-12'
                              // label='Order Qty'
                              isRequired
                              type='text'
                              register={{
                                ...register(`PromotedProducts.${index}.Qty`, {
                                  required: { value: true, message: "Order Qty is Required" }
                                })
                              }}
                              errorMessage={errors?.['PromotedProducts']?.[index]?.['Qty']?.message}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='mt-3'>
                            <Input
                              containerClass='col-lg-12'
                              // label='Sample Qty'
                              isRequired
                              type='text'
                              register={{
                                ...register(`PromotedProducts.${index}.SampleQty`, {
                                  required: { value: true, message: "Sample Qty is Required" }
                                })
                              }}
                              errorMessage={errors?.['PromotedProducts']?.[index]?.['SampleQty']?.message}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox mt-4">
                                <input
                                  {
                                  ...register(`PromotedProducts.${index}.Isprescribe`, {
                                  })
                                  }
                                  id={`PromotedProducts.${index}.Isprescribe`} type="checkbox" className="custom-control-input"
                                />
                                <label className="custom-control-label" htmlFor={`PromotedProducts.${index}.Isprescribe`}>Isprescribe</label>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="col-lg-12 text-center">
                            {
                              products.length !== 1 ?
                                <button type='button' onClick={() => { productremove(index) }} className="btn btn-sm btn-outline-danger">Delete Product</button> :
                                <button type='button' onClick={() => { productremove(index) }} className="btn btn-sm btn-outline-danger" disabled>Delete Product</button>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>


            <div className='row mb-3'>
              <div className='col-md-6'>
                <h4 className="mt-3">Gift</h4>
              </div>
              <div className='col-md-6 text-right'>
                <button type='button' onClick={() => {
                  giftadd({ GiftId: "", SampleQty: "" })
                }} className="btn btn-sm btn-outline-primary mt-4">Add Gift</button>
              </div>
            </div>

            <table className='table table-bordered table-striped'>
              <thead>
                <tr className='text-center'>
                  <th>Gift Name</th>
                  <th>Sample Qty</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  gifts.map((field, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Select
                            {...register(`PromotedGift.${index}.GiftId`, {
                              required: {
                                value: true,
                                message: "Gift is required",
                              }
                            })}
                            options={giftOptions}
                            className='z-3'
                            classNamePrefix={"z-3"}
                            onChange={(e: any) => { setValue(`PromotedGift.${index}.GiftId`, e?.value) }}
                          />
                          <ErrorMessage message={errors?.['PromotedGift']?.[index]?.['GiftId']?.message} />

                          {/* <div className="row ">
                      <ReactSelect
                        containerClass="col-lg-6"
                        label="Gift"
                        control={control}
                        name={`PromotedGift.${index}.GiftId`}
                        isRequired
                        onChange={(e: any) => { setValue(`PromotedGift.${index}.GiftId`, e?.value) }}
                        // value={selectedgift}
                        options={giftOptions}
                        errorMessage={errors?.['PromotedGift']?.[index]?.['GiftId']?.message}
                        rules={{
                          required: {
                            value: true,
                            message: "Gift is required",
                          },
                        }}
                      /> */}
                        </td>
                        <td>
                          <div className='mt-3'>
                            <Input
                              containerClass='col-lg-12'
                              isRequired
                              type='text'
                              register={{
                                ...register(`PromotedGift.${index}.SampleQty`, {
                                  required: { value: true, message: "Sample Qty is Required" }
                                })
                              }}
                              errorMessage={errors?.['PromotedGift']?.[index]?.['SampleQty']?.message}
                            />
                          </div>
                        </td>

                        <td>
                          <div className="col-lg-12 text-center">
                            {
                              gifts.length !== 1 ?
                                <button type='button' onClick={() => { giftremove(index) }} className="btn btn-sm btn-outline-danger">Delete Gift</button> :
                                <button type='button' onClick={() => { giftremove(index) }} className="btn btn-sm btn-outline-danger" disabled>Delete Gift</button>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>

            {/* {
              gifts.map((field, index) => {
                return (
                  <>
                   

                      <Input
                        containerClass='col-lg-6'
                        label='Sample Qty'
                        isRequired
                        type='text'
                        register={{
                          ...register(`PromotedGift.${index}.SampleQty`, {
                            required: { value: true, message: "Sample Qty is Required" }
                          })
                        }}
                        errorMessage={errors?.['PromotedGift']?.[index]?.['SampleQty']?.message}
                      />

                      <div className="col-lg-12 text-center">
                        <button type='button' onClick={() => { giftremove(index) }} className="btn btn-sm btn-outline-danger">Delete Gift</button>
                      </div>
                    </div>


                  </>
                )
              })
            } */}

            <Textarea
              containerClass="col-lg-12"
              label="Remarks"
              isRequired
              register={{
                ...register("Feedback", {
                  required: { value: true, message: "Remarks is required" },
                })
              }}
              // maxLength={150}
              rows={3}
              errorMessage={errors?.DoctorFeedback?.message}
            />

            <div className="col-md-12 text-center align-self-center mt-3">
              <Link to="/layout/mr/visit" className="btn btn-sm btn-outline-primary mr-1">Back</Link>
              <button type='submit' className="btn btn-sm btn-outline-danger mr-1">Close-Visit</button>
              <a href="" className="btn btn-sm btn-outline-primary">Show-presentation</a>
            </div>
          </form>

          {calltype !== null && (
            <AddEditCallTypeModal
              defaultValue={defaultValue}
              setisOPen={() => {
                return setcalltype(null);
              }}
              fetch={(data: boolean, res: any) => {
                if (data) {
                  setValue("CallTypeId", res.CallTypeId);
                  setselectedcallType({ label: res.CallTypeName, value: res.CallTypeId });
                  fetchcallTypeOptions();
                }
              }}
              open={calltype !== null}
              id={calltype}
            />
          )}

          {callobjective !== null && (
            <AddEditCallObjectiveModal
              defaultValue={defaultValue}
              setisOPen={() => {
                return setcallobjective(null);
              }}
              fetch={(data: boolean, res: any) => {
                if (data) {
                  setValue("CallObjective", res.CallObjectiveId);
                  setselectedcallObjective({ label: res.CallObjectiveName, value: res.CallObjectiveId });
                  fetchcallObjectiveOptions();
                }
              }}
              open={callobjective !== null}
              id={callobjective}
            />
          )}

          <DeleteModal
            id={deleteID}
            deleteMessage={(data: boolean, e: any) => {
              if (data) {
                if (e == "CallType") {
                  fetchcallTypeOptions();
                  setselectedcallType({});
                } else if (e == "CallObjective") {
                  fetchcallObjectiveOptions();
                  setselectedcallObjective({})
                }
              }
            }}
            apiname={api}
            setisOPen={() => setdeleteID(null)}
            open={deleteID != null}
          />

        </div>
      </div>
    </>
  )
}

export default DoctorModel
