import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DatatableCommon from "../../../common/DatatableCommon";
import moment from "moment";
import { ApprovalModal } from "../../../common/ApproveModal";
import { Badge } from "primereact/badge";

const AdminLockUnlock = () => {
  const apiname = process.env.REACT_APP_LOCKUNLOCKAPPROVAL_API?.toString();
  const api = process.env.REACT_APP_LOCKUNLOCKAPPROVALPOST_API?.toString();
  const [selectedRow, setselectedRow]: any = useState([]);
  const [approveId, setapproveId] = useState(null);
  const [bool, setbool] = useState(false);
  const [status, setStatus] = useState("");
  const columnData = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3%",
    },
    {
      header: "Action",
      // sortable: true,
      body: (e: any) => actionFunction(e),
      width: "13%",
    },
    {
      field: "EmployeeName",
      header: "Employee Name",
      bodyClassName: "text-info h6",
      sortable: true,
      body: null,
      width: "10vw",
    },
    {
      field: "MtpDate",
      header: "Month / year",
      body: (e: any) => monthFunction(e),
      width: "10vw",
      dataType: "date"
    },
    {
      field: "StatusName",
      header: "Status",
      sortable: true,
      body: (e: any) => statusFunction(e),
      width: "10vw",
    },
  ];

  const monthFunction = (e: any) => {
    return <>{moment(e.MtpDate.split("T")[0]).format("MMMM/YYYY")}</>;
  };

  const statusFunction = (e: any) => {
    return (
      <Badge
        value={e.StatusName == "Approve" ? "Approved" : e.StatusName == "Reject" ? "Rejected" : e.StatusName}

        size="normal"
        severity={
          e.StatusName === "Approve"
            ? "success"
            : e.StatusName === "Pending"
              ? "warning"
              : "danger"
        }
      ></Badge>
    );
  };

  const actionFunction = (e: any) => {
    return e.StatusName == "Pending" ? (
      <>
        <button
          className="btn btn-success btn-sm mr-1"
          onClick={() => {
            setapproveId(e.MonthlyTourPlanUnlockId);
            setStatus("Approve");
          }}
        >
          Approve
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => {
            setapproveId(e.MonthlyTourPlanUnlockId);
            setStatus("Reject");
          }}
          type="button"
        >
          Reject
        </button>
      </>
    ) : (
      " - "
    );
  };
  return (
    <>
      <Helmet>
        <title>Admin Lock/Unlock Plan</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="title">
                <h4>Admin Lock / Unlock Monthly Tour Plan</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/approvals"}>Approvals</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Admin Lock / Unlock Monthly Tour Plan
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <DatatableCommon
          apiname={apiname}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
        />
      </div>

      <ApprovalModal
        id={approveId}
        apiname={api}
        setisOPen={() => setapproveId(null)}
        open={approveId != null}
        status={status}
        refetch={(data: any) => {
          console.log(data, "dsdsdsdsd");
          if (data) {
            setbool(true);
          }
        }}
      />
    </>
  );
};

export default AdminLockUnlock;
