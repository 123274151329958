import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditCustomer } from "./AddEditCustomer";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";
import moment from "moment";

export const Customer = () => {
  const apiname = process.env.REACT_APP_INVENTORYCUSTOMER_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedCustomer, setSelectedCustomer]: any = useState([]);
  const multi = selectedCustomer?.map((e: any) => {
    return e.Id;
  });

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionCustomerTemplate(e),
      width: "10%",
    },
    {
      field: "Name",
      header: "Name",
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by name",
      body: null,
      width: "15%",
    },
    {
      field: "GRNumber",
      header: "GR Number",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.GRNumber);
      },
      sortable: true,
      filterPlaceholder: "Search by GRNumber",
      width: "10vw",
    },
    {
      field: "CompanyName",
      header: "Company Name",
      filterPlaceholder: "Search by Company Name",
      sortable: true,
      filter: true,
      body: (data: any) => {
        return NullHandler(data.CompanyName);
      },
      width: "15%",
    },
    {
      field: "Address",
      header: "Address",
      filterPlaceholder: "Search by Address",
      sortable: true,
      filter: true,
      body: (data: any) => {
        return (
          <p
            title={data.Address}
            style={{
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {data.Address === "" ? " - " : data.Address}
          </p>
        );
      },
      width: "25%",
    },
    // {
    //   field: "HSNName",
    //   header: "HSN",
    //   filterPlaceholder: "Search by HSN",
    //   sortable: true,
    //   dataType: "numeric",
    //   filter: true,
    //   body: (data: any) => {
    //     return NullHandler(data.HSNName);
    //   },
    //   width: "10%",
    // },
    {
      field: "Gstno",
      header: "GST NO.",
      filterPlaceholder: "Search by GST No.",
      sortable: true,
      filter: true,
      body: (data: any) => {
        return NullHandler(data.Gstno);
      },
      width: "15%",
    },
    {
      field: "Phone",
      header: "Phone",
      // filter: true,
      body: (data: any) => {
        return NullHandler(data.Phone);
      },
      // sortable: true,
      // filterPlaceholder: "Search by Phone",
      width: "10vw",
    },
    {
      field: "Discount",
      header: "Discount",
      filterPlaceholder: "Search by Discount",
      sortable: true,
      dataType: "numeric",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.Discount);
      },
      width: "10%",
    },

    // {
    //   field: "FssAi",
    //   header: "FSSAI",
    //   filterPlaceholder: "Search by FSSAI",
    //   sortable: true,
    //   filter: true,
    //   body: (data: any) => {
    //     return NullHandler(data.FssAi);
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "DlNo1",
    //   header: "DL No1",
    //   filterPlaceholder: "Search by DL No1",
    //   sortable: true,
    //   filter: true,
    //   body: (data: any) => {
    //     return NullHandler(data.DlNo1);
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "DlNo2",
    //   header: "DL No 2",
    //   filterPlaceholder: "Search by DL No 2",
    //   sortable: true,
    //   filter: true,
    //   body: (data: any) => {
    //     return NullHandler(data.DlNo2);
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "Nrx",
    //   header: "NRX",
    //   filterPlaceholder: "Search by NRX",
    //   sortable: true,
    //   filter: true,
    //   body: (data: any) => {
    //     return NullHandler(data.Nrx);
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "FssAiExpiryDate",
    //   header: "FSSAI Expiry Date",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.FssAiExpiryDate == null
    //         ? ""
    //         : moment(data.FssAiExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "DlNo1ExpiryDate",
    //   header: "DL No1 Expiry Date",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.DlNo1ExpiryDate == null
    //         ? ""
    //         : moment(data.DlNo1ExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "DlNo2ExpiryDate",
    //   header: "DL No2 Expiry Date",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.DlNo2ExpiryDate == null
    //         ? ""
    //         : moment(data.DlNo2ExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "NrxExpiryDate",
    //   header: "NRX Expiry Date",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.NrxExpiryDate == null
    //         ? ""
    //         : moment(data.NrxExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },


  ];

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    GRNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Address: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CompanyName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    HSNName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    Gstno: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Discount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    FssAi: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DlNo1: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DlNo2: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Nrx: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionCustomerTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Customer</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Customer"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/InventoryMaster"}
          linkflagtitle={"Master"}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedCustomer?.length === 0 ? null : multi);
            }
          }}
          filterJson={filterJson}
          setselecteditem={setSelectedCustomer}
          selecteditem={selectedCustomer}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditCustomer
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
