import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import ReactSelect from "../../../common/ReactSelect";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import Textarea from "../../../common/Textarea";
import { Button } from "primereact/button";

const AddCredit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const apiname = process.env.REACT_APP_DOCTORCREDIT_API?.toString();

  const [selectedcredutType, setselectedcreditType]: any = useState(null);

  // Credited By
  const [selectedCreditBy, setselectedCreditBy]: any = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const form = watch();
  console.log(form, "form");

  const {
    mutate: productForm,
    isSuccess,
    isError: errorProduct,
    error,
    isLoading,
  }: any = useCreateByID(apiname, id);

  const productFormData = (data: any) => {
    data.CreditValue = Number(data.CreditValue);
    data.CreditBy = Number(data.CreditBy);

    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    productForm(newData);
  };

  // Success & Error Messages
  useEffect(() => {
    if (id === undefined) {
      if (isSuccess) {
        toast.success("Added Successfully", { position: "top-right" });
        navigate("/layout/crm/crmreport");
      }
    } else {
      if (isSuccess) {
        toast.success("Updated Successfully", { position: "top-right" });
        navigate("/layout/crm/crmreport");
      }
    }

    if (errorProduct) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [errorProduct, isSuccess]);

  const [doctor, setDoctor] = useState({
    name: "",
    id: null,
  });

  useEffect(() => {

    if (location.state) {
      setDoctor({ ...location.state });
      setValue("DoctorId", location.state.id);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Add Credit </title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4> Add Credit </h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={"/layout/crm/crmreport"}>CRM Report </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add Credit
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <h4>
              Doctor Name:- <span className="text-blue">{doctor?.name}</span>
            </h4>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((pinfo: any) => {
            productFormData(pinfo);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label={"Credit Type"}
              isRequired
              name={"CreditTypeId"}
              value={selectedcredutType}
              control={control}
              onChange={(e: any) => {
                setselectedcreditType(e);
                setValue("CreditTypeId", e?.value);
              }}
              api={process.env.REACT_APP_CREDITTYPE_API}
              // api="CreditType"
              keys={{ label: "Value", value: "Id" }}
              errorMessage={
                errors?.CreditTypeId?.message ||
                error?.response?.data?.errors?.CreditTypeId?.map((e: any) => e)
              }
              rules={{
                required: {
                  value: true,
                  message: "Credit Type is required",
                },
              }}
            />

            <Input
              containerClass="col-lg-6"
              label="Credit Value"
              isRequired
              type="text"
              register={{
                ...register("CreditValue", {
                  required: {
                    value: true,
                    message: "Credit Value is required",
                  },
                }),
              }}
              maxLength={5}
              minLength={2}
              onChange={() => clearErrors(["CreditValue"])}
              errorMessage={
                errors?.CreditValue?.message ||
                error?.response?.data?.errors?.CreditValue?.map((e: any) => e)
              }
            />

            <ReactSelect
              containerClass="col-lg-6"
              label={"Credited By"}
              isRequired
              name={"CreditBy"}
              value={selectedCreditBy}
              control={control}
              onChange={(e: any) => {
                setselectedCreditBy(e);
                setValue("CreditBy", e?.value);
              }}
              api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
              // api="Employee/GetAllEmployeeWithUserId"
              keys={{ label: "Value", value: "Id" }}
              errorMessage={
                errors?.CreditBy?.message ||
                error?.response?.data?.errors?.CreditBy?.map((e: any) => e)
              }
              rules={{
                required: {
                  value: true,
                  message: "Credit By is required",
                },
              }}
            />

            <Input
              containerClass="col-lg-6"
              label="Credit DateTime"
              // type="datetime-local"
              type="date"
              isRequired
              errorMessage={
                errors?.CreditDateTime?.message ||
                error?.response?.data?.errors?.CreditDateTime?.map(
                  (e: any) => e
                )
              }
              onChange={() => clearErrors(["CreditDateTime"])}
              register={{
                ...register("CreditDateTime", {
                  required: { value: true, message: "Date-Time is required." },
                }),
              }}
            />

            <Textarea
              containerClass="col-lg-12"
              label="Remarks"
              rows={3}
              isRequired
              maxLength={1000}
              errorMessage={
                errors?.Remarks?.message ||
                error?.response?.data?.errors?.Remarks?.map((e: any) => e)
              }
              onChange={() => clearErrors(["Remarks"])}
              register={{
                ...register("Remarks", {
                  required: { value: true, message: "Remarks is required" },
                  pattern: {
                    value: /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                    message:
                      "Enter Valid Remarks and Remove whitespace from both sides of a string",
                  },
                }),
              }}
            />

            <div className="col-lg-12 text-center mt-3">
              <Link to={"/layout/crm/crmreport"}>
                <Button type="button" severity="secondary" outlined>
                  <span className="p-button-label p-c">Back</span>
                </Button>
              </Link>

              {id == undefined && (
                <Button
                  type="reset"
                  severity="danger"
                  onClick={() => {
                    reset();
                    setselectedcreditType(null);
                    setselectedCreditBy(null);
                  }}
                  outlined
                  className="ml-2"
                >
                  <span className="p-button-label p-c">Reset</span>
                </Button>
              )}

              {isLoading ? (
                <Button type="submit" outlined disabled className="ml-2">
                  <span className="p-button-label p-c">
                    Submit <Spinner width={"1rem"} height={"1rem"} />
                  </span>
                </Button>
              ) : (
                <Button
                  type="submit"
                  outlined
                  className="ml-2"
                  onClick={handleSubmit((data: any) => {
                    productFormData(data);
                  })}
                >
                  <span className="p-button-label p-c">Submit</span>
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCredit;
