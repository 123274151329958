import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { DeleteModal } from "../../../common/DeleteModal";
import { toast } from "react-toastify";
import { Degree } from "./DoctorDegree/Degree";
import { Areaofinterest } from "./AreaofInterest/Areaofinterest";
import { GuidelinesFollowed } from "./GuidelinesFollowed/GuidelinesFollowed";
import { ConferencesSpeakerParticipants } from "./ConferencesSpeakerParticipants/ConferencesSpeakerParticipants";
import { WeekendVacationActivity } from "./WeekendVacationActivity/WeekendVacationActivity";
import { FamilyFriendsDetails } from "./FamilyFriendsDetails/FamilyFriendsDetails";
import { CollegeMateAlumniDetails } from "./CollegeMateAlumniDetails/CollegeMateAlumniDetails";
import { SocialActivitiesCSR } from "./SocialActivitiesCSR/SocialActivitiesCSR";
import { AssociatedHospitals } from "./AssociatedHospitals/AssociatedHospitals";
import { PatientsReferTo } from "./PatientsReferTo/PatientsReferTo";
import { PatientReferencesSource } from "./PatientReferencesSource/PatientReferencesSource";
import { ConsultingFeesClinic } from "./ConsultingFeesClinic/ConsultingFeesClinic";
import { ConsultingFeesMR } from "./ConsultingFeesMR/ConsultingFeesMR";
import { MedicalAdvisorySpeaker } from "./MedicalAdvisorySpeaker/MedicalAdvisorySpeaker";
import { Achievements } from "./Achievements/Achievements";
import { ProceduralEquipments } from "./ProceduralEquipments/ProceduralEquipments";
import { PageHeader } from "../../../common/PageHeader";
import DoctorBankDetails from "./DoctorBankDetails/DoctorBankDetails";
import { SpecialDay } from "./SpecialDay/SpecialDay";
import { VendorService } from "./VendorService/VendorService";

export const DoctorDetails = () => {
  const { doctorid } = useParams();
  const DoctorId = Number(doctorid);

  const [snotify, setSnotify] = useState(false);

  const [api, setapi]: any = useState();
  const [Delete, setDelete] = useState(null);

  const setDeleteDetail = async (api_name: any, deleteid: any) => {
    setDelete(deleteid);
    setapi(api_name);
  };

  return (
    <>
      <Helmet>
        <title>Doctor Details</title>
      </Helmet>

      <PageHeader
        headTitle="Doctor Details"
        headTitleURL={"/layout/doctor"}
        list={false}
        id={0}
      />

      <div className="row">
        <div className="col-lg-12 mt-2">
          <Degree
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>

        <div className="col-lg-12 mt-2">
          <Areaofinterest
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>

        <div className="col-lg-12 mt-2">
          <GuidelinesFollowed
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>

        <div className="col-lg-12 mt-2">
          <ConferencesSpeakerParticipants
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <WeekendVacationActivity
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <FamilyFriendsDetails
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <CollegeMateAlumniDetails
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <SocialActivitiesCSR
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <AssociatedHospitals
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <PatientsReferTo
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <PatientReferencesSource
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <ConsultingFeesClinic
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <ConsultingFeesMR
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <MedicalAdvisorySpeaker
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2">
          <Achievements
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2 mb-5">
          <ProceduralEquipments
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2 mb-5">
          <DoctorBankDetails
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2 mb-5">
          <SpecialDay
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
        <div className="col-lg-12 mt-2 mb-5">
          <VendorService
            doctorid={DoctorId}
            modelFunction={setDeleteDetail}
            refetchData={snotify}
            api={api}
            resetstate={setSnotify}
          />
        </div>
      </div>

      <DeleteModal
        id={Delete}
        deleteMessage={(data: boolean) => {
          if (data) {
            setSnotify(data);
          }
        }}
        apiname={api}
        setisOPen={() => setDelete(null)}
        open={Delete != null}
      />
    </>
  );
};
