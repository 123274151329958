import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { PaginationService } from "../../../service/PaginationService";
import { toast } from "react-toastify";
import { DataTablePrime } from "../../../common/Datatable";
import AddEditExpense from "./AddEditExpense";
import { DeleteModal } from "../../../common/DeleteModal";
import { MultiSelect } from "primereact/multiselect";
import { Input } from "../../../common/Input";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";

const Expense = () => {
  const [totalData, settotalData] = useState(null);
  const apiname = process.env.REACT_APP_EXPENSE_API?.toString();
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ mode: "all" });

  const [deleteID, setdeleteID]: any = useState(null);

  const columns = [
    {
      field: "srNo",
      header: "#",
      // frozen: true,
      width: "3rem",
    },
    {
      field: "action",
      header: "Action",
      body: (e: any) => actionExpenseTemplate(e),
      sortable: false,
      width: "2vw",
    },
    {
      field: "ExpenseTypeName",
      header: "Expense Type",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "ExpenseDate",
      header: "Expense Date",
      body: (e: any) => actionExpenseDateTemplate(e),
      sortable: true,
      width: "10vw",
      dataType: "date"
    },
    {
      field: "Amount",
      header: "Amount",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "BillImage",
      header: "Bill Image",
      body: null,
      width: "10vw",
    },
    {
      field: "Note",
      header: "Note",
      body: null,
      width: "10vw",
    },
    {
      field: "StatusName",
      header: "Status",
      sortable: true,
      width: "10vw",

      body: (e: any) => statusFunction(e),
    },

  ];
  const [updateId, setupdateId]: any = useState(null);

  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const actionExpenseTemplate = (id: any) => {
    return (
      <>
        {id.StatusName != "Approve" && (
          <Action
            id={id.ExpenseId}
            editModal={() => {
              setupdateId(id.ExpenseId);
            }}
            displayid={setDeleteidtoDelete}
          />
        )}
      </>
    );
  };

  const statusFunction = (e: any) => {
    return (
      <span
        className={`badge badge-${e.StatusName == "Approve"
          ? "success"
          : e.StatusName == "Pending"
            ? "primary"
            : "danger"
          }`}
      >
        {/* {e.StatusName} */}
        {e.StatusName == "Approve" ? "Approved" : e.StatusName == "Reject" ? "Rejected" : e.StatusName}

      </span>
    );
  };

  const actionExpenseDateTemplate = (id: any) => {
    return id.ExpenseDate?.split("T")[0];
  };

  const expenseFormData = async (e: any) => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_EXPENSE_API}?startDate=${e.startDate}&endDate=${e.endDate}`
      )
      .then((res) => {
        settotalData(res.data.Data);
        setStartDate("");
        setEndDate("");
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  const [bool, setbool]: any = useState(null);
  const [selectedRow, setselectedRow]: any = useState([]);

  return (
    <>
      <Helmet>
        <title>Expense</title>
      </Helmet>
      <div>
        <PageHeader
          headTitle="Expense"
          list={true}
          onClick={() => {
            setupdateId(0);
          }}
          linkflag
          linkflagURL={"/layout/tourprogram"}
          linkflagtitle={"Tour Program"}
        />
        {/* <div className="pd-20 card-box mb-30">
          <form
            onSubmit={handleSubmit((data: any) => {
              expenseFormData(data);
            })}
          >
            <Input
              containerClass="col-lg-12"
              label="From Date"
              isRequired
              type="date"
              value={StartDate}
              onChange={(e: any) => setStartDate(e.target.value)}
              register={{
                ...register("startDate", {
                  required: {
                    value: true,
                    message: "Please select From Date",
                  },
                }),
              }}
              errorMessage={errors?.startDate?.message}
            />

            <Input
              containerClass="col-lg-12"
              label="To Date"
              isRequired
              type="date"
              value={EndDate}
              onChange={(e: any) => setEndDate(e.target.value)}
              register={{
                ...register("endDate", {
                  required: { value: true, message: "Please select To Date" },
                }),
              }}
              errorMessage={errors?.endDate?.message}
            />

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
              // data-toggle="modal"
              // onClick={handleExpenseSearch}s
              // data-target="#doctor-modal"
              >
                Search
              </button>
            </div>
          </form>
        </div> */}

        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          setselecteditem={setselectedRow}
          selecteditem={selectedRow}
          refetch={totalData}
        />
      </div>

      {updateId !== null && (
        <AddEditExpense
          setisOPen={() => {
            setupdateId(null);
          }}
          open={updateId !== null}
          id={updateId}
          fetch={(data: boolean) => {
            if (data) {
              setbool(true);
            }
          }}
        />
      )}

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default Expense;
