import { ErrorMessage } from "./ErrorMessage";

interface iPropsinput {
  type?: string;
  maxLength?: number;
  minLength?: number;
  className?: string;
  label?: string;
  isRequired?: boolean;
  register?: object;
  errorMessage?: string;
  containerClass?:string;
  value?: any;
  onChange?: any;
  placeholder?:string
  disabled?:boolean;
  min?:any;
  max?:any;
  multiple?:boolean;
  defaultValue?:any
}

export const Input = ({
  type,
  maxLength,
  minLength,
  className,
  label,
  isRequired = false,
  register,
  errorMessage,
  containerClass,
  value,
  onChange,
  placeholder,
  disabled,
  min,
  max,
  multiple = false,
  defaultValue
}: iPropsinput) => {
  return (
    <div className={containerClass}>
      <div className="form-group">
      {label &&
        <label className="col-form-label">
          {label} {isRequired && <span className="text-danger">*</span>}
        </label>}
        <input
          {...register}
          placeholder={placeholder}
          type={type}
          maxLength={maxLength}
          minLength={minLength}
          value={value}
          disabled={disabled}
          onChange={onChange}
          className={"form-control " + className}
          max = {max}
          min = {min}
          multiple = {multiple}
          defaultValue = {defaultValue}
        />
      <ErrorMessage message={errorMessage} />
      </div>
    </div>
  );
};
