import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditCompany } from "./AddEditCompany";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";
import moment from "moment";

export const Company = () => {
  const apiname = process.env.REACT_APP_INVENTORYCOMPANY_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedCompany, setSelectedCompany]: any = useState([]);
  const multi = selectedCompany?.map((e: any) => {
    return e.Id;
  });

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionCompanyTemplate(e),
      width: "1vw",
    },
    {
      field: "Name",
      header: "Company Name",
      bodyClassName: "text-info h6",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Company name",

      body: null,
      width: "10vw",
    },
    {
      field: "FssAi",
      header: "FSSAI",
      filterPlaceholder: "Search by FSSAI",
      sortable: true,
      filter: true,
      body: (data: any) => {
        return NullHandler(data.FssAi);
      },
      width: "10vw",
    },
    {
      field: "DlNo1",
      header: "DL No1",
      filterPlaceholder: "Search by DL No1",
      sortable: true,
      dataType: "numeric",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.DlNo1);
      },
      width: "10vw",
    },
    {
      field: "DlNo2",
      header: "DL No 2",
      filterPlaceholder: "Search by DL No2",
      sortable: true,
      dataType: "numeric",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.DlNo2);
      },
      width: "10vw",
    },
    {
      field: "Nrx",
      header: "NRX",
      filterPlaceholder: "Search by NRX",
      sortable: true,
      filter: true,
      body: (data: any) => {
        return NullHandler(data.Nrx);
      },
      width: "10vw",
    },
    // {
    //   field: "FssAiExpiryDate",
    //   header: "FSSAI Expiry Date",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.FssAiExpiryDate == null
    //         ? ""
    //         : moment(data.FssAiExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "DlNo1ExpiryDate",
    //   header: "DL No 1 Expiry Date",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.DlNo1ExpiryDate == null
    //         ? ""
    //         : moment(data.DlNo1ExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "DlNo2ExpiryDate",
    //   header: "DL No 2 Expiry Date",
    //   sortable: true,
    //   dataType: "numeric",
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.DlNo2ExpiryDate == null
    //         ? ""
    //         : moment(data.DlNo2ExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    // {
    //   field: "NrxExpiryDate",
    //   header: "NRX Expiry Date",
    //   sortable: true,
    //   dataType: "numeric",
    //   body: (data: any) => {
    //     return NullHandler(
    //       data.NrxExpiryDate == null
    //         ? ""
    //         : moment(data.NrxExpiryDate).format("DD MMM, YYYY")
    //     );
    //   },
    //   width: "10vw",
    // },
    {
      field: "CurrencyName",
      header: "Currency",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.CurrencyName);
      },
      width: "10vw",
      filter: true,
      filterPlaceholder: "Search by Currency",
    },

  ];

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    FssAi: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Nrx: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    CurrencyName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    DlNo1: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    DlNo2: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionCompanyTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Company</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Company"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/InventoryMaster"}
          linkflagtitle={"Master"}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedCompany?.length === 0 ? null : multi);
            }
          }}
          filterJson={filterJson}
          setselecteditem={setSelectedCompany}
          selecteditem={selectedCompany}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditCompany
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
