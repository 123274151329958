import { Link } from "react-router-dom";
import AdminPanelCard from "../common/AdminPanelCard";
import { Helmet } from "react-helmet";

export const InventoryMaster = () => {
  let admincard = [
    {
      name: "Company",
      link: "/layout/inventory/company",
    },
    {
      name: "Currency",
      link: "/layout/inventory/currency",
    },
    {
      name: "Customer",
      link: "/layout/inventory/customer",
    },
    {
      name: "HSN",
      link: "/layout/inventory/hsn",
    },
    {
      name: "Payment Method",
      link: "/layout/inventory/paymentmethod",
    },
    {
      name: "Warehouse",
      link: "/layout/inventory/warehouse",
    },
    {
      name: "Vendor",
      link: "/layout/inventory/vendor",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Inventory Master</title>
      </Helmet>

      <div className="d-flex row ml-4 admin-panel">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Inventory Master</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/inventory"}>Inventory</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Inventory Master
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard name={card?.name} link={card?.link} />
          </div>
        ))}
      </div>
    </>
  );
};
