import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";

export const SecondaryReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  }: any = useForm({ mode: "all" });

  const d = watch();
  console.log(d, "watch data");

  const [isLoading, setIsLoading] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownload = async (data: any) => {

    data.FromDate = `${data.FromDate}-01`;
    data.ToDate = `${data.ToDate}-01`;
    console.log(data, "data");
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRIMARYSALESGETSTOCKISTSALESREPORT_API}?FromDate=${data.FromDate}&ToDate=${data.ToDate}&StockistId=${data.StockistId}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "StockistReport.xlsx");
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const [doctorOptions, setdoctorOptions] = useState([]);
  const [selecteddoctor, setselecteddoctor]: any = useState({});
  const fetchdoctorOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKIST_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.StockistName,
        value: option?.StockistId,
      };
    });
    setdoctorOptions(data);
  };
  useEffect(() => {
    fetchdoctorOptions();
  }, []);
  return (
    <>
      <Helmet>
        <title>Stockiest Sale Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Stockiest Sale Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Stockiest Sale Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>


      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <Input
              containerClass="col-lg-6"
              label="From Month"
              type="month"
              isRequired
              errorMessage={errors?.FromDate?.message}
              register={{
                ...register("FromDate", {
                  required: {
                    value: true,
                    message: "From Date is required",
                  },
                }),
              }}
            />

            <Input
              containerClass="col-lg-6"
              label="To Month"
              type="month"
              isRequired
              errorMessage={errors?.ToDate?.message}
              register={{
                ...register("ToDate", {
                  required: {
                    value: true,
                    message: "To Date is required",
                  },
                }),
              }}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Stockist"
              control={control}
              name="StockistId"
              isRequired
              options={doctorOptions}
              onChange={(e: any) => {
                setselecteddoctor(e);
                setValue("StockistId", e?.value);
              }}
              value={selecteddoctor}
              // handleCreate={handlecontactpersonChange}
              errorMessage={errors?.StockistId?.message}
              rules={{
                required: {
                  value: true,
                  message: "Doctor is required",
                },
              }}
            />
          </div>

          <div className="col-md-12 text-center mt-30">
            {!isLoading ? (
              <button className="btn btn-outline-primary" type="submit">
                Download
              </button>) : (
              <button className="btn btn-outline-primary" type="submit" disabled>
                Download <Spinner width={"15rem"} height={"15rem"} />
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
