import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASEURL_MAIN}`,
});

let cancelTokens:any = [];
let isRefreshing = false;
let refreshSubscribers:any = [];

const onRrefreshed = (token:any) => {
  refreshSubscribers.map((cb:any) => cb(token));
};

const addRefreshSubscriber = (cb:any) => {
  refreshSubscribers.push(cb);
};

const refreshAccessToken = async (refreshToken:any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL_MAIN}Account/refresh-token`,
      {},
      {
        headers: {
          refreshToken: refreshToken,
          currentappversion: "v1",
        },
      }
    );

    return response;
  } catch (error) {
    localStorage.clear();
    window.location.href = "/";
    throw error; // Re-throw the error so it can be handled elsewhere if needed
  }
};

const cancelAllRequests = () => {
  cancelTokens.forEach((source:any) => source.cancel("Request canceled due to 401"));
  cancelTokens = [];
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("Access");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["currentappversion"] = `v1`;
    }

    // Create a cancel token and add it to the request
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    cancelTokens.push(source);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      localStorage.getItem("Refresh")
    ) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token:any) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      isRefreshing = true;

      // Cancel all ongoing requests
      cancelAllRequests();

      const refreshToken = localStorage.getItem("Refresh");

      try {
        const newAccessToken = await refreshAccessToken(refreshToken);

        if (newAccessToken?.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        } else {
          const token = newAccessToken?.data.Data.JWToken;
          localStorage.setItem("Access", token);
          localStorage.setItem(
            "Refresh",
            newAccessToken?.data.Data?.RefreshToken
          );

          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;

          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${token}`;

          isRefreshing = false;
          onRrefreshed(token);
          refreshSubscribers = [];

          return axiosInstance(originalRequest);
        }
      } catch (error) {
        console.error("Token refresh error:", error);
        localStorage.clear();
        window.location.href = "/";
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
