import { useState } from "react"
import doctorlogo from "../assets/images/doctor.png";
import chemistlogo from "../assets/images/chemist.png";
import stockistlogo from "../assets/images/stock.png";
import { Helmet } from "react-helmet";
const Details = ({ header }: any) => {
    return (
        <>
       
        <div className="card-box m-2 p-3" >
            <h4 style={{color:'#495761'}} className="pb-2 pl-3 pt-3 "> {header}</h4>
            <h6 className="pb-1 pl-3 ">ASM Rajkot</h6>
            <table className="table">
                <thead>
                    <th>Doctor</th>
                    <th>Chemist</th>
                    <th>Stockist</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Sunil Shah</td>
                        <td>Abc Medicine kalavad</td>
                        <td>Abc Medicine kalavad</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Sunil Shah</td>
                        <td>Abc Medicine kalavad</td>
                        <td>Abc Medicine kalavad</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}


export const TodayActivity = () => {
    const [data, setData]: any = useState({});
    return (
        <>
         <Helmet>
            <title>Today Activity</title>
        </Helmet>
            <div className=" d-flex justify-content-around pt-20 pb-20">

                <div className="col-2 card text-center p-4  activityStyle" style={{  cursor: "pointer", borderRadius: "10px" }} onClick={() => { setData({ name: "Aashtik Parmar" }) }}>
                    <div className="align-items-center " >

                        <h3 style={{ color: "#0B3049" }} className="mb-2">Aashtik Parmar</h3>
                    </div>
                    {/* <div className="d-flex justify-content-around" > */}
                    <div className="m-auto">
                        <h6 >Asm Rajkot</h6></div>
                    <div className="d-flex  m-auto p-2">
                        <img src={doctorlogo} style={{ height: "35px",color:'black', width: "35px", objectFit: "contain" }} />
                        <h6 className="p-2"> 5</h6>
                        <img src={chemistlogo} style={{ height: "35px", width: "35px", objectFit: "contain" }} />
                        <h6 className="p-2"> 3</h6>
                        <img src={stockistlogo} style={{ height: "35px", width: "35px", objectFit: "contain" }} />
                        <h6 className="p-2"> 1</h6>
                    </div>
                    {/* </div> */}
                </div>
                <div className="col-2 card text-center p-4  activityStyle" style={{  cursor: "pointer", borderRadius: "10px" }} onClick={() => { setData({ name: "Abc Medicines" }) }}>
                    <div className="align-items-center ">

                        <h3 style={{ color: "#0B3049" }} className="mb-2">Abc Medicines</h3>
                    </div>
                    {/* <div className="d-flex justify-content-around "> */}
                    <div className="m-auto">
                        <h6>Asm Rajkot</h6></div>

                    <div className="d-flex  m-auto p-2">
                        <img src={doctorlogo} style={{ height: "35px", width: "35px", objectFit: "contain" }} />
                        <h6 className="p-2"> 5</h6>
                        <img src={chemistlogo} style={{ height: "35px", width: "35px", objectFit: "contain" }} />
                        <h6 className="p-2"> 3</h6>
                        <img src={stockistlogo} style={{ height: "35px", width: "35px", objectFit: "contain" }} />
                        <h6 className="p-2"> 1</h6>
                    </div>
                    {/* </div> */}
                </div>
                <div className="col-2 card text-center p-4 activityStyle" style={{cursor: "pointer", borderRadius: "10px" }} onClick={() => { setData({ name: "Aashtik Parmar" }) }}>
                    <div className="align-items-center">

                        <h3 style={{ color: "#0B3049" }} className="mb-2">Aashtik Parmar</h3>
                    </div>
                    <div className="d-flex justify-content-around m-auto" >
                        {/* <div>Asm Rajkot</div>
                        <div className="d-flex flex-column ">
                            <span>Dr. - 5</span>
                            <span>Ch. - 3</span>
                            <span>St. - 1</span>
                        </div> */}
                        <h6>No TP Found</h6>
                    </div>
                </div>
                {/* <div className="col-3 card text-center p-2 rounded" style={{ backgroundColor: "#20234252" }}>
                    <h3>No Working Days</h3>
                    <h6 className="h">0</h6>
                </div> */}
            </div>
            {
                data.name !== undefined &&
                <Details header={data?.name} />
            }
        </>
    )
}