import AdminPanelCard from "../common/AdminPanelCard";
import DoctorImg from "../assets/images/Vector (1).png";
import DailyPlanImg from "../assets/images/Vector (2).png";
import TourPlanImg from "../assets/images/tour 2.png";
import ExpenceImg from "../assets/images/Group 1.png";
import NAImg from "../assets/images/na.png";
import leaveImg from "../assets/images/Group (2).png";
import jwrImg from "../assets/images/data.png";
import { Helmet } from "react-helmet";

export const TourProgram = () => {
  const role = localStorage.getItem("Roles");

  let admincard = [
    {
      name: "Doctor Availability",
      link: "/layout/doctoravailability",
      img: DoctorImg,
    },
  ];

  if (role !== "admin") {
    admincard.push(
      {
        name: "Daily Plan",
        link: "/layout/mr/admin/addDailyTouPlan",
        img: DailyPlanImg,
      },
      {
        name: "Tour Plan",
        link: "/layout/mr/calender",
        img: TourPlanImg,
      },
      {
        name: "Leave",
        link: "/layout/mr/leave",
        img: leaveImg,
      },
      {
        name: "Leave Calendar",
        link: "/layout/mr/leavecalender",
        img: leaveImg,
      },
      {
        name: "Expense",
        link: "/layout/mr/expense",
        img: ExpenceImg,
      },
      {
        name: "N/W Days Settings",
        link: "/layout/eventmanagement/nwdays",
        img: NAImg,
      }
    );
  }

  if (role !== "MR") {
    admincard.push({
      name: "Employee JWR Observation",
      link: "/layout/AddEmployeeJWRObservation",
      img: jwrImg,
    });
  }

  return (
    <>
      <Helmet>
        <title>Tour Program</title>
      </Helmet>
      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard
              name={card?.name}
              link={card?.link}
              imageSrc={card?.img}
            />
          </div>
        ))}
      </div>
    </>
  );
};
