import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { NullHandler } from "../../../common/NullHandler";
import { Action } from "../../../common/Action";

const ManageCredit = () => {
  const apiname = process.env.REACT_APP_DOCTORCREDIT_API?.toString();
  const [bool, setbool]: any = useState(null);

  const [deleteID, setdeleteID]: any = useState();
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const columns = [
    {

      header: "#",
      field: "srNo",
      width: "1vw",
    },
    {
      header: "Action",
      body: (e: any) => actioncrmAccountTemplate(e),
      sortable: false,
      width: "1vw",
    },
    {
      field: "DoctorName",
      header: "Doctor Name",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "CreditTypeName",
      header: "Credit Type",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "CreditName",
      header: "Name",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "CreditValue",
      header: "Credit Value",
      body: null,
      sortable: true,
      width: "10vw",
    },
    {
      field: "CreditDateTime",
      header: "Credit Date-Time",
      body: (e: any) => actionDateTemplate(e),
      sortable: true,
      width: "10vw",
      dataType: "date"
    },
  ];

  const actionDateTemplate = (id: any) => {
    return id.CreditDateTime?.split("T")[0];
  };

  const actioncrmAccountTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.DoctorCreditId}
          editurl={"layout/crm/Editmanagecredit"}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };
  return (
    <>
      <Helmet>
        <title>Manage Credit</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Manage Credit"
          list={true}
          LinkURL={"/layout/crm/Addmanagecredit"}
        />
        <DatatableCommon
          apiname={apiname}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
        // check={true}
        />
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default ManageCredit;
