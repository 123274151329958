import { Accordion, AccordionTab } from "primereact/accordion";
import { Input } from "./Input";
import ReactSelect from "./ReactSelect";

interface iPropsaaction {
  header?: string;
  address1register?: any;
  address1onChange?: any;
  address1errorMessage?: any;
  address2register?: any;
  address2onChange?: any;
  address2errorMessage?: any;
  address3register?: any;
  address3onChange?: any;
  address3errorMessage?: any;
  control?: any;
  address4onChange?: any;
  address4errorMessage?: any;
  selectedCity?: any;
  cityrules?: any;
  address5onChange?: any;
  address5errorMessage?: any;
  selectedPincode?: any;
  pincoderules?: any;
  cityName?: any;
  pincodeName?: any;
  cityKeys?: object;
  pincodeKeys?: object;
  cityApi?: string;
  pincodeApi?: string;
  pincodeDisable?:boolean;
  requiredflag? : boolean;
  cityFlag?:boolean;
}

export const Address = ({
  header,
  address1register,
  address1onChange,
  address1errorMessage,
  address2register,
  address2onChange,
  address2errorMessage,
  address3register,
  address3onChange,
  address3errorMessage,
  control,
  address4onChange,
  address4errorMessage,
  selectedCity,
  cityrules,
  address5onChange,
  address5errorMessage,
  selectedPincode,
  pincoderules,
  cityName,
  pincodeName,
  cityKeys,
  pincodeKeys,
  cityApi,
  pincodeApi,
  pincodeDisable,
  requiredflag=false,
  cityFlag=false
}: iPropsaaction) => {
  return (
    <>
      <div className="col-lg-12 mb-3">
        <Accordion activeIndex={0}>
          <AccordionTab header={header}>
            <div className="card-body">
              {requiredflag ? (<>
                <div className="row">
                <Input
                  containerClass="col-lg-3"
                  label="Address Line1"
                  isRequired
                  type="text"
                  register={address1register}
                  onChange={address1onChange}
                  errorMessage={address1errorMessage}
                />

                <Input
                  containerClass="col-lg-3"
                  label="Area"
                  isRequired
                  type="text"
                  register={address2register}
                  onChange={address2onChange}
                  errorMessage={address2errorMessage}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label="City"
                  control={control}
                  name={cityName}
                  isRequired
                  onChange={address4onChange}
                  value={selectedCity}
                  keys={cityKeys}
                  flag={cityFlag}
                  api={cityApi}
                  errorMessage={address4errorMessage}
                  rules={cityrules}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label="Pincode"
                  control={control}
                  name={pincodeName}
                  Disabled={pincodeDisable}
                  isRequired
                  onChange={address5onChange}
                  value={selectedPincode}
                  keys={pincodeKeys}
                  api={pincodeApi}
                  flag
                  errorMessage={address5errorMessage}
                  rules={pincoderules}
                />
              </div>
              </>) : (
                <>
                 <div className="row">
                <Input
                  containerClass="col-lg-3"
                  label="Address Line1"
                  type="text"
                  register={address1register}
                  onChange={address1onChange}
                  errorMessage={address1errorMessage}
                />

                <Input
                  containerClass="col-lg-3"
                  label="Area"
                  type="text"
                  register={address2register}
                  onChange={address2onChange}
                  errorMessage={address2errorMessage}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  label="City"
                  control={control}
                  name={cityName}
                  onChange={address4onChange}
                  value={selectedCity}
                  keys={cityKeys}
                  api={cityApi}
                  isRequired
                  errorMessage={address4errorMessage}
                  rules={cityrules}
                />

                <ReactSelect
                  containerClass="col-lg-3"
                  isRequired
                  label="Pincode"
                  control={control}
                  name={pincodeName}
                  Disabled={pincodeDisable}
                  onChange={address5onChange}
                  value={selectedPincode}
                  keys={pincodeKeys}
                  api={pincodeApi}
                  flag
                  errorMessage={address5errorMessage}
                  rules={pincoderules}
                />
              </div></>
              )}
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </>
  );
};
