import { useEffect, useState } from "react";
import { Input } from "../../../../common/Input";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Address } from "../../../../common/Address";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}

export default function AddEditHospital({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) {
  useEffect(() => {
    if (defaultValue) {
      setValue("HospitalName", defaultValue);
    }
  }, [defaultValue]);
  const apiname = process.env.REACT_APP_HOSPITAL_API?.toString();
  const controller = new AbortController();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const Hcity = watch("CityId");

  const [selectedhcity, setselectedhcity]: any = useState(null);
  const [selectedhpincode, setselectedhpincode]: any = useState(null);

  const { data, isLoading: chemistContactPersonLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id,
    undefined,
    controller.signal
  );

  useEffect(() => {
    if (data) {
      if (id) {
        reset(data);
        if (data.CityId !== null) {
          setValue("CityId", {
            label: data.City, value: data.CityId
          });
          setselectedhcity({
            label: data.City, value: data.CityId
          });
        } else {
          setValue("CityId", null);
          setselectedhcity(null);
        }
        if (data.ChemistContactPersonPincodeId !== null) {
          setValue("PincodeId", {
            label: data.Pincode, value: data.PincodeId
          });
          setselectedhpincode({
            label: data.Pincode, value: data.PincodeId
          });
        } else {
          setValue("PincodeId", null);
          setselectedhpincode(null);
        }
      }
    }
    return () => {
      controller.abort();
    }
  }, [data]);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const HospitalFormData = (data: any) => {
    data.CityId = selectedhcity?.value;
    data.PincodeId = selectedhpincode?.value;

    for (let prop in data) {
      if (
        data[prop] === null ||
        data[prop] === undefined ||
        data[prop] === "" ||
        data[prop]?.length == 0
      ) {
        delete data[prop];
      }
    }
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Hospital`}
        modal
        style={{ width: "1100px" }}
      >
        {!chemistContactPersonLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(HospitalFormData);
              }
            }}
            onSubmit={handleSubmit(HospitalFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-6"
                label="Hospital Name"
                type="text"
                isRequired
                errorMessage={
                  errors?.HospitalName?.message ||
                  error?.response?.data?.errors?.HospitalName?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("HospitalName", e.target.value);
                  clearErrors(["HospitalName"]);
                }}
                register={{
                  ...register("HospitalName", {
                    required: {
                      value: true,
                      message: "Hospital Name is required",
                    },
                    pattern: {
                      value: /^.{2,100}$/gm,
                      message:
                        "Enter a valid hospital name (2-100 alphabet characters)",
                    },
                  }),
                }}
              />

              <Input
                containerClass="col-lg-6"
                label="GST No."
                type="text"
                isRequired
                errorMessage={
                  errors?.GstNo?.message ||
                  error?.response?.data?.errors?.GstNo?.map((e: any) => e)
                }
                onChange={(e: any) => {
                  setValue("GstNo", e.target.value);
                  clearErrors(["GstNo"]);
                }}
                register={{
                  ...register("GstNo", {
                    required: {
                      value: true,
                      message: "GST No. is required",
                    },
                    pattern: {
                      value: /^[A-Z0-9]{15}$/,
                      message:
                        "Enter a valid GstNo (15 Capital letters & digits only)",
                    },
                  }),
                }}
              />

              <Address
                header="Residential Address"
                control={control}
                address1register={{
                  ...register("AddressLine1", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Address(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("AddressLine1", e.target.value);
                  clearErrors(["AddressLine1"]);
                }}
                address1errorMessage={
                  errors?.AddressLine1?.message ||
                  error?.response?.data?.errors?.AddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("AddressLine2", {
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message:
                        "Enter a valid Area(2-250 alphanumeric characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("AddressLine2", e.target.value);
                  clearErrors(["AddressLine2"]);
                }}
                address2errorMessage={
                  errors?.AddressLine2?.message ||
                  error?.response?.data?.errors?.AddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("CityId", e?.value);
                    setselectedhcity(e);
                    setValue("PincodeId", null);
                    setselectedhpincode(null);
                  } else {
                    setValue("CityId", null);
                    setselectedhcity(null);
                    setValue("PincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.CityId?.message ||
                  error?.response?.data?.errors?.CityId?.map((e: any) => e)
                }
                cityrules={{
                  required: { value: true, message: "Please select city" },
                }}
                selectedCity={selectedhcity}
                address5onChange={(e: any) => {
                  if (e?.value !== undefined) {
                    setValue("PincodeId", e?.value);
                    setselectedhpincode(e);
                  } else {
                    setValue("PincodeId", null);
                    setselectedhpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.PincodeId?.message ||
                  error?.response?.data?.errors?.PincodeId?.map((e: any) => e)
                }
                selectedPincode={selectedhpincode}
                cityKeys={{ label: "Value", value: "Id" }}
                cityApi={process.env.REACT_APP_CITY_AND_STATE_API}
                cityName={"CityId"}
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeApi={
                  selectedhcity != null ?
                    `${process.env.REACT_APP_PARENT_PINCODE_API}?CityId=${selectedhcity?.value}`
                    : ""
                }
                pincodeName={"PincodeId"}
                pincodeDisable={selectedhcity != null ? false : true}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />

              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setselectedhcity(null);
                      setselectedhpincode(null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
}
