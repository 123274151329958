import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useCreateByID } from "../../../hooks/Api/Api";
import { Link } from "react-router-dom";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { ErrorMessage } from "../../../common/ErrorMessage";
import ReactSelect from "../../../common/ReactSelect";
import { LoaderContext } from "../../../App";

const NoWorkingAllowanceUpload = () => {
  const fullScreenLoader = useContext(LoaderContext);
  const apiname = process.env.REACT_APP_NOWORKINGALLOWANCES_API?.toString();

  const [isLoad, setIsLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    register,
    setValue,
  }: any = useForm({ mode: "all" });

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading: noworkingAllowanceLoading,
  }: any = useCreateByID(apiname);

  const { reset: downloadReset, handleSubmit: downloadSubmit }: any = useForm({
    mode: "all",
  });

  const [selecteddivision, setselecteddivision]: any = useState(null);
  const [selectedzone, setselectedzone]: any = useState(null);
  const [selectedregion, setselectedregion] = useState({});
  const [selecteddesignation, setselecteddesignation]: any = useState(null);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };

  const handleDownloadBlank = async () => {
    setIsLoad(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_NOWORKINGALLOWANCESDOWNLOADBLANK_API}`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        saveFile(response.data, "NoWorkingAllowanceDownloadBlank.xlsx");
        setIsLoad(false);
        toast.success(`Download Blank Successfull`, { position: "top-right" });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoad(false);
      });
  };

  const handleDownload = async (data: any) => {

    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_NOWORKINGALLOWANCESWITHDATA_API}${data.zoneId == undefined ? "" : `?zoneId=${data.zoneId}`
        }${data.divisionId == undefined ? "" : `&divisionId=${data.divisionId}`
        }${data.designationId == undefined
          ? ""
          : `&designationId=${data.designationId}`
        }${data.regionId == undefined ? "" : `&regionId=${data.regionId}`}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "NoWorkingAllowanceWithData.xlsx");
        downloadReset();
        setIsLoading(false);
        toast.success(`Download With Data Successfull`, {
          position: "top-right",
        });
      })
      .catch((error) => {
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };

  const handleUpload = (data: any) => {

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(`File Uploaded Successfull`, { position: "top-right" });
      reset();
    }
    if (isError) {
      toast.error(`${error?.response?.data?.Message}`, {
        position: "top-right",
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    if (noworkingAllowanceLoading) {
      fullScreenLoader.setloader(true)
    }
    else {
      fullScreenLoader.setloader(false)
    }
  }, [noworkingAllowanceLoading])
  return (
    <>
      <Helmet>
        <title>No Working Allowance</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>No Working Allowance</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  No Working Allowance
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6">
            {!isLoad ? (
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={handleDownloadBlank}
              >
                Download Blank
              </button>
            ) : (
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={handleDownloadBlank}
                disabled
              >
                Download Blank <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Download Excel</h5>
        </div>

        <form
          onSubmit={downloadSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <ReactSelect
              containerClass="col-lg-6"
              label="Division"
              name={"divisionId"}

              onChange={(e: any) => {
                setselecteddivision(e);
                setValue("divisionId", e?.value);
              }}
              value={selecteddivision}
              control={control}
              api={process.env.REACT_APP_DIVISION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.divisionId?.message}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Designation"
              name={"designationId"}
              onChange={(e: any) => {
                setselecteddesignation(e);
                setValue("designationId", e?.value);
              }}
              value={selecteddesignation}
              control={control}
              api={process.env.REACT_APP_DESIGNATION_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.designationId?.message}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Zone"

              name={"zoneId"}
              onChange={(e: any) => {
                setselectedzone(e);
                setValue("zoneId", e?.value);
              }}
              value={selectedzone}
              control={control}
              api={process.env.REACT_APP_ZONE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.zoneId?.message}
            />

            <ReactSelect
              containerClass="col-lg-6"
              label="Region"
              name={"regionId"}
              onChange={(e: any) => {
                setselectedregion(e);
                setValue("regionId", e?.value);
              }}
              value={selectedregion}
              control={control}
              api={
                selectedzone?.value &&
                `${process.env.REACT_APP_STATE_BY_PARENT_API}?ZoneId=${selectedzone?.value}`
              }
              Disabled={selectedzone?.value ? false : true}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.regionId?.message}
              flag
            />

            <div className="col-lg-12 text-center">
              {!isLoading ? (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={downloadSubmit((data: any) => {
                    handleDownload(data);
                  })}
                >
                  Download
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary mx-2"
                  type="submit"
                  onClick={downloadSubmit((data: any) => {
                    handleDownload(data);
                  })}
                  disabled
                >
                  Download <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Upload Excel</h5>
        </div>
        <form
          onSubmit={handleSubmit((data: any) => {
            handleUpload(data);
          })}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Select File <span className="text-danger">*</span>
                </label>
                <input
                  {...register("NoWorkingAllowancesExcel", {
                    required: {
                      value: true,
                      message: "Please Upload Excel",
                    },
                  })}
                  type="file"
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <ErrorMessage
                  message={errors?.NoWorkingAllowancesExcel?.message}
                />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!noworkingAllowanceLoading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Uploading...
                  {/* <Spinner width={"1rem"} height={"1rem"} /> */}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NoWorkingAllowanceUpload;
