import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditInvoiceType } from "./AddEditInvoiceType";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";

export const InvoiceType = () => {
  const apiname = process.env.REACT_APP_INVENTORYINVOICETYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedInvoiceType, setSelectedInvoiceType]: any = useState([]);
  const multi = selectedInvoiceType?.map((e: any) => {
    return e.Id;
  });

  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "3rem",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionInvoiceTypeTemplate(e),
      width: "10%",
    },
    {
      field: "Name",
      header: "Invoice Type Name",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Invoice Type",
      body: null,
      width: "90%",
    },

  ];

  const filterJson = {
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const [updateId, setupdateId]: any = useState(null);
  const actionInvoiceTypeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Invoice Type</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Invoice Type"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/inventoryattributes"}
          linkflagtitle={"Inventory Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => {
            if (data) {
              setdeleteID(selectedInvoiceType?.length === 0 ? null : multi);
            }
          }}
          filterJson={filterJson}
          setselecteditem={setSelectedInvoiceType}
          selecteditem={selectedInvoiceType}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
        />

        {updateId !== null && (
          <AddEditInvoiceType
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};
