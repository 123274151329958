import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Input } from "../common/Input";
import { useForm } from "react-hook-form";
import axiosInstance from "../_helper/Interceptor";
import { toast } from "react-toastify";
import moment from "moment";

export const SalarySlip = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm({ mode: "all" });

  const [isLoading, setIsLoading] = useState(false);
  const [bool, setBool]: any = useState(false);
  const [data, setData]: any = useState({});

  const handleForm = async (data: any) => {
    data.Month = `${data.Month}-01`;
    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_SALARYTRANSACTION_API}?Month=${data.Month}`
      )
      .then((response) => {
        setData(response.data.Data);
        setIsLoading(false);
        setBool(true);
        toast.success("Execute Successfully", { position: "top-right" });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setBool(false);
        if (error?.response?.data?.StatusCode !== 400) {
          toast.error(error?.response?.data?.Message, {
            position: "top-right",
          });
        } else {
          toast.error("error...", { position: "top-right" });
        }
      });
  };

  const getDaysInMonth = (month: any, year: any) => {
    return new Date(year, month, 0).getDate();
  };

  return (
    <>
      <Helmet>
        <title>Salary Slip</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Salary Slip</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Salary Slip
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleForm(data);
          })}
        >
          <div className="row">
            <Input
              containerClass="col-lg-4"
              label="Month"
              type="month"
              isRequired
              errorMessage={errors?.Month?.message}
              register={{
                ...register("Month", {
                  required: {
                    value: true,
                    message: "Month is required",
                  },
                }),
              }}
            />

            <div className="col-md-2 text-center mt-30">
              <button className="btn btn-primary" type="submit">
                Execute
              </button>
            </div>
          </div>
        </form>
      </div>

      {bool && (
        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-6">
              <div className="pull-left">
                <img
                  src="https://bioslab.co.in/images/logo.svg"
                  height="150px"
                  width="200px"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-right">
                <span style={{ fontWeight: 800 }}>
                  Bios Lab Private Limited
                </span>{" "}
                <br />
                CIN : U24230GJ2015PTC082869 <br />
                206 MARUTINANDAN COMPLEX BHAKTINAGAR STATION <br />
                PLOT-2/10,BHAKTINAGAR STN PLOT RAJKOT
                <br />
                Rajkot GJ 360002
              </div>
            </div>
            <div
              className="col-md-12 text-center p-2 mt-2"
              style={{ backgroundColor: "#263475" }}
            >
              <h5 style={{ color: "#fff" }}>
                PaySlip for {data?.Month.replace("-", " ")}
              </h5>
            </div>
            <div className="col-md-6 mt-3">
              <div className="table-responsive"></div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="col" className="py-1" style={{ width: "50%" }}>
                      Name of Employee
                    </th>
                    <td className="py-1" style={{ width: "50%" }}>
                      {data?.EmployeeName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      Employee ID
                    </th>
                    <td className="py-1" style={{ width: "50%" }}>
                      {data?.EmployeeCode}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      Designation
                    </th>
                    <td className="py-1" style={{ width: "50%" }}>
                      {data?.DesignationName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      Department
                    </th>
                    <td className="py-1" style={{ width: "50%" }}>
                      Development
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      Total Days
                    </th>
                    <td className="py-1" style={{ width: "50%" }}>
                      {getDaysInMonth(
                        Number(
                          moment().month(data?.Month?.split("-")[0]).format("M")
                        ),
                        Number(data?.Month?.split("-")[1])
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6 mt-3">
              <div className="table-responsive"></div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      PF Number
                    </th>
                    <td className="py-1">
                      {data?.PfNumber === null ? "-" : data?.PfNumber}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1">
                      ESI Number
                    </th>
                    <td className="py-1">
                      {data?.EsiNumber === null ? "-" : data?.EsiNumber}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1">
                      Bank Name
                    </th>
                    <td className="py-1">
                      {data?.BankName === null ? "-" : data?.BankName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1">
                      Bank Account
                    </th>
                    <td className="py-1">
                      {data?.BankAccount === null ? "-" : data?.BankAccount}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1">
                      Paid Days
                    </th>
                    <td className="py-1">
                      {data?.PaidDays === null ? "-" : data?.PaidDays}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <div
                className="text-center p-2 mb-0"
                style={{ backgroundColor: "#459543" }}
              >
                <h5 style={{ color: "#fff" }}>Earning</h5>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row" className="py-1" style={{ width: "50%" }}>
                        Basic Wage{" "}
                      </th>
                      <td className="py-1">
                        {data?.BasicWage === null ? "-" : data?.BasicWage}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        HRA
                      </th>
                      <td className="py-1">
                        {data?.Hra === null ? "-" : data?.Hra}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        Conveyance Allowances
                      </th>
                      <td className="py-1">
                        {data?.ConveyancAllowances === null
                          ? "-"
                          : data?.ConveyancAllowances}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        Medical Allowance
                      </th>
                      <td className="py-1">
                        {data?.MedicalAllowances === null ? "-" : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        {" "}
                        Satutory
                      </th>
                      <td className="py-1">
                        {data?.Satutory === null ? "-" : data?.Satutory}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        {" "}
                        Other Allowance
                      </th>
                      <td className="py-1">
                        {data?.otherAllowance === null
                          ? "-"
                          : data?.otherAllowance}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        {" "}
                        Marketing Exp. (Reimburse)
                      </th>
                      <td className="py-1">
                        {data?.MarketingExp === null ? "-" : data?.MarketingExp}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="text-center p-2 mb-0"
                style={{ backgroundColor: "#459543" }}
              >
                <h5 style={{ color: "#fff" }}>Deductions</h5>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row" className="py-1" style={{ width: "50%" }}>
                        EPF
                      </th>
                      <td className="py-1">
                        {data?.Epf === null ? "-" : data?.Epf}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        ESI/Health Insurance
                      </th>
                      <td className="py-1">
                        {data?.Esi === null ? "-" : data?.Esi}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        {" "}
                        Professional Tax{" "}
                      </th>
                      <td className="py-1">
                        {data?.ProfessionalTax === null
                          ? "-"
                          : data?.ProfessionalTax}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        Loan Recovery
                      </th>
                      <td className="py-1">
                        {data?.LoanRecovery === null ? "-" : data?.LoanRecovery}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1">
                        {" "}
                        Other Deductions{" "}
                      </th>
                      <td className="py-1">
                        {data?.OtherDeduction === null
                          ? "-"
                          : data?.OtherDeduction}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6">
              <div className="table-responsive"></div>
              <table className="table table-bordered mt-2">
                <tbody>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      Net Salary{" "}
                    </th>
                    <td className="py-1">
                      {data?.NetSalary === null ? "-" : data?.NetSalary}
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row" className="py-1">
                      {" "}
                      Total Earning{" "}
                    </th>
                    <td className="py-1">
                      {data.TotalEarning === null ? "-" : data.TotalEarning}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <div className="table-responsive"></div>
              <table className="table table-bordered mt-2">
                <tbody>
                  <tr>
                    <th scope="row" className="py-1" style={{ width: "50%" }}>
                      Total Deduction
                    </th>
                    <td className="py-1">
                      {data?.TotalDeduction === null
                        ? "-"
                        : data?.TotalDeduction}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="py-1">
                      {" "}
                      Net Pay
                    </th>
                    <td className="py-1">
                      {data?.NetSalary === null ? "-" : data?.NetSalary}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className=" col-md-9 offset-md-2">
              <div className="table-responsive"></div>
              <table className="table table-bordered mt-2">
                <tbody>
                  <tr>
                    <th scope="row" className="py-1">
                      Amount in Words
                    </th>
                    <td className="py-1" style={{ fontWeight: 800 }}>
                      {data?.AmountInWorlds === null
                        ? "-"
                        : data?.AmountInWorlds}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-12">
              <div
                className="text-center p-2 mb-0"
                style={{ backgroundColor: "#459543" }}
              >
                <h5 style={{ color: "#fff" }}>Other Pay</h5>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row" className="py-1" style={{ width: "25%" }}>
                        Insentive{" "}
                      </th>
                      <td className="py-1">
                        {data?.Insentive === null ? "-" : data?.Insentive}
                      </td>
                      <th scope="row" className="py-1" style={{ width: "25%" }}>
                        EPF Company{" "}
                      </th>
                      <td className="py-1">
                        {data?.EpfCompany === null ? "-" : data?.EpfCompany}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="py-1" style={{ width: "25%" }}>
                        CTC{" "}
                      </th>
                      <td className="py-1">
                        {data?.TotalEarning === null ? "-" : data?.TotalEarning}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="col-md-12 text-center p-2"
              style={{ backgroundColor: "#263475" }}
            >
              <h5 style={{ color: "#fff" }}>
                This is computer generated no signature required
              </h5>
            </div>
          </div>
          {/* Default Basic Forms End */}
        </div>
      )}
    </>
  );
};
