import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import ReactSelect from "../../../common/ReactSelect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from "primereact/badge";
import moment from "moment";
import axiosInstance from "../../../_helper/Interceptor";
import "../../doctor/subComponents/doc.css";

const WarehouseProductHistory = () => {
  const location = useLocation();

  const [selectedwarehouse, setselectedwarehouse]: any = useState(null);
  const [selectedproduct, setselectedproduct]: any = useState(
    location?.state?.id !== undefined
      ? {
          label: location?.state?.name,
          value: location?.state?.id,
        }
      : null
  );
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);

  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [Nodata, setNodata] = useState(false);

  const handleTabChange = (accordion: any, e: any) => {
    if (accordion === activeAccordion && e.index === activeIndex) {
      setActiveAccordion(null);
      setActiveIndex(null);
    } else {
      setActiveAccordion(accordion);
      setActiveIndex(e.index);
    }
  };

  // Sub Accordian
  const [subactiveAccordion, setSubActiveAccordion] = useState(null);
  const [subactiveIndex, setSubActiveIndex] = useState(null);

  const handleSubTabChange = (accordion: any, e: any) => {
    if (accordion === subactiveAccordion && e.index === subactiveIndex) {
      setSubActiveAccordion(null);
      setSubActiveIndex(null);
    } else {
      setSubActiveAccordion(accordion);
      setSubActiveIndex(e.index);
    }
  };

  // Purchase
  const [purchaseexpandedRows, setPurchaseExpandedRows]: any = useState(null);
  const [purchasepreviousId, setPurchasePreviousId]: any = useState(1);

  // Sales
  const [salesexpandedRows, setSalesExpandedRows]: any = useState(null);
  const [salespreviousId, setSalesPreviousId]: any = useState(1);

  const {
    formState: { errors },
    control,
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const [startdate, setDate] = useState("");
  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split("T")[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState("");
  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split("T")[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  const loadLazyData = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_WAREHOUSESTOCKGETPRODUCTHISTORY_API
        }?${
          selectedwarehouse !== null
            ? `WarehouseId=${selectedwarehouse?.value}`
            : ""
        }${
          selectedproduct !== null
            ? `&ProductId=${
                typeof selectedproduct?.value === "object"
                  ? selectedproduct?.value?.value
                  : selectedproduct?.value
              }`
            : ""
        }&StartDate=${startdate}&EndDate=${defaultDate}`
      )
      .then((res) => {
        const temp = res?.data?.Data;
        settotalData(temp);
        setLoading(false);
        if (temp?.length === 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }

        setSubActiveAccordion(null);
        setSubActiveIndex(null);
        setActiveAccordion(null);
        setActiveIndex(null);
      })
      .catch((error) => {
        setLoading(false);
        settotalData(null);
        if (error?.response?.data?.StatusCode !== 400) {
          toast.error(error?.response?.data?.Message, {
            position: "top-right",
          });
        } else {
          toast.error(`${error.message}`, { position: "top-right" });
        }
      });
  };

  useEffect(() => {
    if (
      selectedwarehouse !== null &&
      selectedproduct !== null &&
      startdate !== "" &&
      defaultDate !== ""
    ) {
      loadLazyData();
    }
  }, [selectedwarehouse, selectedproduct, startdate, defaultDate]);

  const rowPurchaseExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <Accordion
          activeIndex={
            subactiveAccordion === "AccordionPurchase" ? subactiveIndex : null
          }
          onTabChange={(e: any) => handleSubTabChange("AccordionPurchase", e)}
        >
          <AccordionTab header="Purchase Details">
            <DataTable
              scrollable
              showGridlines
              value={data?.ProductDetails}
              size="small"
              className="custom-datatable"
              stripedRows
              dataKey="Id"
            >
              <Column
                field="InvoiceNumber"
                header="Invoice No"
                body={(e: any) => {
                  return (
                    <Link
                      to={`/layout/viewinvoice/p/${e.InvoiceId}`}
                      target="_blank"
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {e.InvoiceNumber}
                    </Link>
                  );
                }}
              ></Column>
              <Column field="BatchNo" header="Batch"></Column>
              <Column field="Quantity" header="Qty"></Column>
              <Column field="SampleQuantity" header="Sample Qty"></Column>
              <Column field="BillNo" header="Bill No"></Column>
              <Column
                field="BillDate"
                header="Bill Date"
                body={(data: any) => {
                  if (data.BillDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"warning"}
                          value={
                            <div
                              style={{
                                display: "inline-block",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                              title={moment(data.BillDate).format(
                                "DD MMM, YYYY"
                              )}
                            >
                              {moment(data.BillDate).format("DD MMM, YYYY")}
                            </div>
                          }
                        ></Badge>
                      </>
                    );
                  }
                }}
              ></Column>
              <Column field="PurchasePrice" header="Purchase Price"></Column>
              <Column field="MRP" header="MRP"></Column>
              <Column field="Discount" header="Discount %"></Column>
              <Column field="GSTPer" header="GST %"></Column>
              <Column field="Gstamount" header="GST Amt"></Column>
              <Column field="BillAmount" header="Bill Amt"></Column>
              <Column
                field="ManufactureDate"
                header="Mfg"
                body={(data: any) => {
                  if (data.ManufactureDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={
                            <div
                              style={{
                                display: "inline-block",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                              title={moment(data.ManufactureDate).format(
                                "DD MMM, YYYY"
                              )}
                            >
                              {moment(data.ManufactureDate).format(
                                "DD MMM, YYYY"
                              )}
                            </div>
                          }
                        ></Badge>
                      </>
                    );
                  }
                }}
              ></Column>
              <Column
                field="ExpiryDate"
                header="Exp"
                body={(data: any) => {
                  if (data.ExpiryDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"danger"}
                          value={
                            <div
                              style={{
                                display: "inline-block",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                              title={moment(data.ExpiryDate).format(
                                "DD MMM, YYYY"
                              )}
                            >
                              {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                            </div>
                          }
                        ></Badge>
                      </>
                    );
                  }
                }}
              ></Column>
            </DataTable>
          </AccordionTab>
        </Accordion>

        {data?.PurchaseReturns?.length > 0 && (
          <Accordion
            activeIndex={
              subactiveAccordion === "PurchaseReturn" ? subactiveIndex : null
            }
            onTabChange={(e: any) => handleSubTabChange("PurchaseReturn", e)}
          >
            <AccordionTab header="Purchase Return">
              <DataTable
                scrollable
                showGridlines
                value={data?.PurchaseReturns}
                size="small"
                className="custom-datatable"
                stripedRows
                dataKey="Id"
              >
                <Column
                  field="InvoiceNumber"
                  header="Invoice No"
                  body={(e: any) => {
                    return (
                      <Link
                        to={`/layout/viewinvoice/p/${e.InvoiceId}`}
                        target="_blank"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {e.InvoiceNumber}
                      </Link>
                    );
                  }}
                ></Column>
                <Column field="ReturnType" header="Return Type"></Column>
                <Column field="BatchNo" header="Batch"></Column>
                <Column field="Quantity" header="Qty"></Column>
                <Column field="PurchasePrice" header="Return Price"></Column>
                <Column field="MRP" header="MRP"></Column>
                <Column field="Discount" header="Discount %"></Column>
                <Column
                  field="DiscountedPrice"
                  header="Total Discount"
                ></Column>
                <Column field="GSTPer" header="GST %"></Column>
                <Column field="Gstamount" header="GST Amt"></Column>
                <Column field="BillAmount" header="Bill Amt"></Column>
                <Column
                  field="ManufactureDate"
                  header="Mfg"
                  body={(data: any) => {
                    if (data.ManufactureDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"success"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="ExpiryDate"
                  header="Exp"
                  body={(data: any) => {
                    if (data.ExpiryDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"danger"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ExpiryDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="CreatedDate"
                  header="Invoice Date"
                  body={(data: any) => {
                    if (data.CreatedDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}
      </div>
    );
  };

  const rowSalesExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <Accordion
          activeIndex={
            subactiveAccordion === "AccordionSales" ? subactiveIndex : null
          }
          onTabChange={(e: any) => handleSubTabChange("AccordionSales", e)}
        >
          <AccordionTab header="Sales Details">
            <DataTable
              scrollable
              showGridlines
              value={data?.ProductDetails}
              size="small"
              className="custom-datatable"
              stripedRows
              dataKey="Id"
            >
              <Column
                field="InvoiceNumber"
                header="Invoice No"
                body={(e: any) => {
                  return (
                    <Link
                      to={`/layout/viewinvoice/s/${e.InvoiceId}`}
                      target="_blank"
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {e.InvoiceNumber}
                    </Link>
                  );
                }}
              ></Column>
              <Column field="BatchNo" header="Batch"></Column>
              <Column field="Quantity" header="Qty"></Column>
              <Column field="SalePrice" header="Sale Price"></Column>
              <Column field="MRP" header="MRP"></Column>
              <Column field="Discount" header="Discount %"></Column>
              <Column field="GSTPer" header="GST %"></Column>
              <Column field="Gstamount" header="GST Amt"></Column>
              <Column field="BillAmount" header="Bill Amt"></Column>
              <Column
                field="ManufactureDate"
                header="Mfg"
                body={(data: any) => {
                  if (data.ManufactureDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"success"}
                          value={
                            <div
                              style={{
                                display: "inline-block",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                              title={moment(data.ManufactureDate).format(
                                "DD MMM, YYYY"
                              )}
                            >
                              {moment(data.ManufactureDate).format(
                                "DD MMM, YYYY"
                              )}
                            </div>
                          }
                        ></Badge>
                      </>
                    );
                  }
                }}
              ></Column>
              <Column
                field="ExpiryDate"
                header="Exp"
                body={(data: any) => {
                  if (data.ExpiryDate === null) {
                    return "-";
                  } else {
                    return (
                      <>
                        <Badge
                          className="m-1"
                          severity={"danger"}
                          value={
                            <div
                              style={{
                                display: "inline-block",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                              }}
                              title={moment(data.ExpiryDate).format(
                                "DD MMM, YYYY"
                              )}
                            >
                              {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                            </div>
                          }
                        ></Badge>
                      </>
                    );
                  }
                }}
              ></Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
        {data?.SalesReturnInvoiceViews?.length > 0 && (
          <Accordion
            activeIndex={
              subactiveAccordion === "SalesReturn" ? subactiveIndex : null
            }
            onTabChange={(e: any) => handleSubTabChange("SalesReturn", e)}
          >
            <AccordionTab header="Sales Return">
              <DataTable
                scrollable
                showGridlines
                value={data?.SalesReturnInvoiceViews}
                size="small"
                className="custom-datatable"
                stripedRows
                dataKey="Id"
              >
                <Column
                  field="InvoiceNumber"
                  header="Invoice No"
                  body={(e: any) => {
                    return (
                      <Link
                        to={`/layout/viewinvoice/s/${e.InvoiceId}`}
                        target="_blank"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {e.InvoiceNumber}
                      </Link>
                    );
                  }}
                ></Column>
                <Column field="ReturnType" header="Return Type"></Column>
                <Column field="BatchNo" header="Batch"></Column>
                <Column field="Quantity" header="Qty"></Column>
                <Column field="SalePrice" header="Sale Price"></Column>
                <Column field="MRP" header="MRP"></Column>
                <Column field="Discount" header="Discount %"></Column>
                <Column
                  field="DiscountedPrice"
                  header="Total Discount"
                ></Column>
                <Column field="GSTPer" header="GST %"></Column>
                <Column field="Gstamount" header="GST Amt"></Column>
                <Column field="BillAmount" header="Bill Amt"></Column>
                <Column
                  field="ManufactureDate"
                  header="Mfg"
                  body={(data: any) => {
                    if (data.ManufactureDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"success"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ManufactureDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="ExpiryDate"
                  header="Exp"
                  body={(data: any) => {
                    if (data.ExpiryDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"danger"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.ExpiryDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.ExpiryDate).format("DD MMM, YYYY")}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="CreatedDate"
                  header="Invoice Date"
                  body={(data: any) => {
                    if (data.CreatedDate === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              >
                                {moment(data.CreatedDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}
      </div>
    );
  };

  const nameDisplay = (e: any) => {
    let chemist = e?.ChemistName == null ? "" : `Chemist : ${e?.ChemistName}`;
    let stockist =
      e?.StockistName == null ? "" : `Stockist : ${e?.StockistName}`;
    let doctor = e?.DoctorName == null ? "" : `Doctor : ${e?.DoctorName}`;
    let customer =
      e?.CustomerName == null ? "" : `Customer : ${e?.CustomerName}`;

    return chemist + "" + stockist + "" + doctor + "" + customer;
  };

  const idDisplay: any = (e: any) => {
    let chemist = e?.ChemistId == null ? "" : `${e?.ChemistId}`;
    let stockist = e?.StockistId == null ? "" : `${e?.StockistId}`;
    let doctor = e?.DoctorId == null ? "" : `${e?.DoctorId}`;
    let customer = e?.CustomerId == null ? "" : `${e?.CustomerId}`;

    return chemist + "" + stockist + "" + doctor + "" + customer;
  };

  const actionVendorNameTemplate: any = (rowData: any) => {
    return "Vendor : " + rowData?.VendorName;
  };

  const nameTemplate = (id: any) => {
    let gift = id?.GiftName == null ? "" : `${id?.GiftName}`;
    let product = id?.ProductName == null ? "" : `${id?.ProductName}`;

    return product + " " + gift || " - ";
  };

  return (
    <>
      <Helmet>
        <title>Warehouse Product History</title>
      </Helmet>
      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>Warehouse Product History</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/layout/Inventory"}>Inventory</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Warehouse Product History
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <form>
            <div className="row">
              <ReactSelect
                containerClass={"col-lg-3"}
                isRequired
                label="Warehouse"
                name={"WarehouseId"}
                onChange={(e: any) => {
                  setselectedwarehouse(e);
                  setValue("WarehouseId", e);
                }}
                value={selectedwarehouse}
                api={process.env.REACT_APP_INVENTORYWAREHOUSE_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.WarehouseId?.message}
              />

              <ReactSelect
                containerClass={"col-lg-3"}
                isRequired
                label="Product"
                name={"ProductId"}
                onChange={(e: any) => {
                  setselectedproduct(e);
                  setValue("ProductId", e);
                }}
                value={selectedproduct}
                api={process.env.REACT_APP_PRODUCT_API}
                keys={{ label: "Value", value: "Id" }}
                control={control}
                errorMessage={errors?.ProductId?.message}
              />

              <Input
                containerClass={"col-lg-3"}
                label="Start Date"
                type="date"
                isRequired
                errorMessage={errors?.StartDate?.message}
                onChange={(e: any) => {
                  clearErrors(["StartDate"]);
                  handleDateChange(e);
                }}
                value={startdate}
              />

              <Input
                containerClass={"col-lg-3"}
                label="End Date"
                type="date"
                isRequired
                errorMessage={errors?.EndDate?.message}
                onChange={(e: any) => {
                  clearErrors(["EndDate"]);
                  handleEndDateChange(e);
                }}
                value={defaultDate}
              />
            </div>
          </form>
        </div>

        {!loading && totalData !== null ? (
          <div className="pd-20 card-box mb-30">
            <>
              <div className="p-0">
                <Accordion activeIndex={0}>
                  <AccordionTab
                    header={
                      <span className="customHeader">
                        <span className="font-bold white-space-nowrap">
                          Warehouse Stock Details
                        </span>
                        <Badge
                          size={"large"}
                          value={"Total Available Qty : " + totalData?.TotalQty}
                          className="ml-auto"
                        />
                      </span>
                    }
                  >
                    <DataTable
                      value={totalData?.WarehouseStockDetailsViewModels}
                      size="small"
                      className="custom-datatable"
                      stripedRows
                      scrollable
                      showGridlines
                    >
                      <Column field="BatchNo" header="Batch"></Column>
                      <Column field="StockType" header="Stock Type"></Column>
                      <Column field="Quantity" header="Quantity"></Column>
                      <Column
                        field="ManufactureDate"
                        header="Mfg"
                        body={(data: any) => {
                          if (data.ManufactureDate === null) {
                            return "-";
                          } else {
                            return (
                              <>
                                <Badge
                                  className="m-1"
                                  severity={"success"}
                                  value={
                                    <div
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        verticalAlign: "middle",
                                      }}
                                      title={moment(
                                        data.ManufactureDate
                                      ).format("DD MMM, YYYY")}
                                    >
                                      {moment(data.ManufactureDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </div>
                                  }
                                ></Badge>
                              </>
                            );
                          }
                        }}
                      ></Column>
                      <Column
                        field="ExpiryDate"
                        header="Exp"
                        body={(data: any) => {
                          if (data.ExpiryDate === null) {
                            return "-";
                          } else {
                            return (
                              <>
                                <Badge
                                  className="m-1"
                                  severity={"info"}
                                  value={
                                    <div
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        verticalAlign: "middle",
                                      }}
                                      title={moment(data.ExpiryDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    >
                                      {moment(data.ExpiryDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </div>
                                  }
                                ></Badge>
                              </>
                            );
                          }
                        }}
                      ></Column>
                    </DataTable>
                  </AccordionTab>
                </Accordion>

                <Accordion
                  activeIndex={
                    activeAccordion === "Accordion1" ? activeIndex : null
                  }
                  onTabChange={(e: any) => handleTabChange("Accordion1", e)}
                >
                  <AccordionTab header="Purchase History">
                    <DataTable
                      scrollable
                      showGridlines
                      value={totalData?.PurchaseInvoiceViewModels}
                      size="small"
                      className="no-header"
                      stripedRows
                      expandedRows={purchaseexpandedRows}
                      dataKey="VendorId"
                      onRowToggle={(e) => {
                        setSalesExpandedRows({});
                        setSubActiveAccordion(null);
                        setSubActiveIndex(null);
                        let expanded: any = e.data;
                        if (Object.keys(e.data).length > 1) {
                          delete expanded[purchasepreviousId];
                          setPurchaseExpandedRows(expanded);
                        } else {
                          setPurchaseExpandedRows(expanded);
                        }
                        if (Object.keys(e.data).length !== 0) {
                          setPurchasePreviousId(Object.keys(e.data)[0]);
                        }
                      }}
                      rowExpansionTemplate={rowPurchaseExpansionTemplate}
                    >
                      <Column
                        headerStyle={{
                          display: "none",
                        }}
                        expander
                        style={{ width: "2rem" }}
                      />
                      <Column
                        headerStyle={{
                          display: "none",
                        }}
                        field="VendorName"
                        body={actionVendorNameTemplate}
                      ></Column>
                    </DataTable>
                  </AccordionTab>
                </Accordion>

                <Accordion
                  activeIndex={
                    activeAccordion === "Accordion2" ? activeIndex : null
                  }
                  onTabChange={(e: any) => handleTabChange("Accordion2", e)}
                >
                  <AccordionTab header="Sales History">
                    <DataTable
                      scrollable
                      showGridlines
                      value={totalData?.SalesInvoiceViewModels}
                      size="small"
                      className="no-header"
                      stripedRows
                      dataKey={idDisplay}
                      expandedRows={salesexpandedRows}
                      onRowToggle={(e) => {
                        setPurchaseExpandedRows({});
                        setSubActiveAccordion(null);
                        setSubActiveIndex(null);
                        let expanded: any = e.data;
                        if (Object.keys(e.data).length > 1) {
                          delete expanded[salespreviousId];
                          setSalesExpandedRows(expanded);
                        } else {
                          setSalesExpandedRows(expanded);
                        }
                        if (Object.keys(e.data).length !== 0) {
                          setSalesPreviousId(Object.keys(e.data)[0]);
                        }
                      }}
                      rowExpansionTemplate={rowSalesExpansionTemplate}
                    >
                      <Column
                        expander
                        style={{ width: "2rem" }}
                        headerStyle={{
                          display: "none",
                        }}
                      />
                      <Column
                        headerStyle={{
                          display: "none",
                        }}
                        body={nameDisplay}
                      ></Column>
                    </DataTable>
                  </AccordionTab>
                </Accordion>

                <Accordion
                  activeIndex={
                    activeAccordion === "Accordion3" ? activeIndex : null
                  }
                  onTabChange={(e: any) => handleTabChange("Accordion3", e)}
                >
                  <AccordionTab header="Product Sample & Gift Details">
                    <DataTable
                      scrollable
                      showGridlines
                      value={totalData?.ProductSampleAndGiftViewModels}
                      size="small"
                      className="custom-datatable mb-3"
                      stripedRows
                      dataKey={idDisplay}
                    >
                      <Column field="EmployeeName" header="Employee"></Column>
                      <Column
                        body={nameTemplate}
                        header="Product/Gift"
                      ></Column>
                      <Column field="Qty" header="Qty"></Column>
                      <Column
                        field="IssueDate"
                        header="Issue Date"
                        body={(data: any) => {
                          if (data.IssueDate === null) {
                            return "-";
                          } else {
                            return (
                              <>
                                <Badge
                                  className="m-1"
                                  severity={"info"}
                                  value={
                                    <div
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        verticalAlign: "middle",
                                      }}
                                      title={moment(data.IssueDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    >
                                      {moment(data.IssueDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </div>
                                  }
                                ></Badge>
                              </>
                            );
                          }
                        }}
                      ></Column>
                    </DataTable>
                  </AccordionTab>
                </Accordion>
              </div>
            </>
          </div>
        ) : (
          Nodata && (
            <div className="col-md-12 col-sm-12 mb-30">
              <div className="pd-20 card-box text-center">
                <h3 className="text-secondary">No data found.</h3>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default WarehouseProductHistory;
