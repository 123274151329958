import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { Address } from "../../../common/Address";
import { Button } from "primereact/button";
import { Spinner } from "../../../common/Spinner";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
}
const AddEditInventoryVendor = ({
  id,
  setisOPen,
  open,
  fetch,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_INVENTORYVENDOR_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    watch,
    control,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: SampleLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );
  const [selectedCompany, setSelectedCompany]: any = useState();
  const [selectedFssAiExpiryDate, setSelectedFssAiExpiryDate]: any =
    useState(null);
  const [selectedDlNo1ExpiryDate, setSelectedDlNo1ExpiryDate]: any =
    useState(null);
  const [selectedDlNo2ExpiryDate, setSelectedDlNo2ExpiryDate]: any =
    useState(null);
  const [selectedNrxExpiryDate, setSelectedNrxExpiryDate]: any = useState(null);
  const [selectedcity, setselectedcity]: any = useState(null);
  const [selectedpincode, setselectedpincode]: any = useState(null);

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);
  const Hcity = watch("CityId");
  useEffect(() => {
    if (id) {
      if (data) {
        reset(data);
        if (
          data?.FssAiExpiryDate !== undefined &&
          data?.FssAiExpiryDate !== null
        ) {
          setValue("FssAiExpiryDate", data?.FssAiExpiryDate?.split("T")[0]);
          setSelectedFssAiExpiryDate(data?.FssAiExpiryDate?.split("T")[0]);
        } else {
          setValue("FssAiExpiryDate", null);
          setSelectedFssAiExpiryDate(null);
        }
        if (
          data?.DlNo1ExpiryDate !== undefined &&
          data?.DlNo1ExpiryDate !== null
        ) {
          setValue("DlNo1ExpiryDate", data?.DlNo1ExpiryDate?.split("T")[0]);
          setSelectedDlNo1ExpiryDate(data?.DlNo1ExpiryDate?.split("T")[0]);
        } else {
          setValue("DlNo1ExpiryDate", null);
          setSelectedDlNo1ExpiryDate(null);
        }
        if (
          data?.DlNo2ExpiryDate !== undefined &&
          data?.DlNo2ExpiryDate !== null
        ) {
          setValue("DlNo2ExpiryDate", data?.DlNo2ExpiryDate?.split("T")[0]);
          setSelectedDlNo2ExpiryDate(data?.DlNo2ExpiryDate?.split("T")[0]);
        } else {
          setValue("DlNo2ExpiryDate", null);
          setSelectedDlNo2ExpiryDate(null);
        }
        if (data?.NrxExpiryDate !== undefined && data?.NrxExpiryDate !== null) {
          setValue("NrxExpiryDate", data?.NrxExpiryDate?.split("T")[0]);
          setSelectedNrxExpiryDate(data?.NrxExpiryDate?.split("T")[0]);
        } else {
          setValue("NrxExpiryDate", null);
          setSelectedNrxExpiryDate(null);
        }

        if (
          data.CompanyName !== undefined &&
          data.CompanyName !== null &&
          data.CompanyId !== undefined &&
          data.CompanyId !== null
        ) {
          setSelectedCompany({
            label: data.CompanyName,
            value: data.CompanyId,
          });
          setValue("CompanyId", data.CompanyId);
        } else {
          setSelectedCompany(null);
          setValue("CompanyId", null);
        }

        if (
          data.City !== undefined &&
          data.City !== null &&
          data.CityId !== undefined &&
          data.CityId !== null
        ) {
          setselectedcity({
            label: data.City,
            value: data.CityId,
          });
          setValue("CityId", data.CityId);
        } else {
          setselectedcity(null);
          setValue("CityId", null);
        }

        if (
          data.PinCode !== undefined &&
          data.PinCode !== null &&
          data.PinCodeId !== undefined &&
          data.PinCodeId !== null
        ) {
          setselectedpincode({
            label: data.PinCode,
            value: data.PinCodeId,
          });
          setValue("PincodeId", data.PinCodeId);
        } else {
          setselectedpincode(null);
          setValue("PincodeId", null);
        }
      }
    }
  }, [id, data]);

  const VendorFormData = (formData: any) => {
    for (let prop in formData) {
      if (
        formData[prop] === null ||
        formData[prop] === undefined ||
        formData[prop] === "" ||
        formData[prop]?.length == 0
      ) {
        delete formData[prop];
      }
    }
    const tempData = { ...formData };
    let submitData = {};

    if (id === 0) {
      submitData = {
        VendorToCreate: {
          VendorName: tempData.VendorName,
          Gstno: tempData.Gstno,
          Discount: tempData.Discount,
          CompanyId: tempData.CompanyId,
          IsActive: true,
          FssAi: tempData.FssAi,
          DlNo1: tempData.DlNo1,
          DlNo2: tempData.DlNo2,
          Nrx: tempData.Nrx,
          FssAiExpiryDate: tempData.FssAiExpiryDate,
          DlNo1ExpiryDate: tempData.DlNo1ExpiryDate,
          DlNo2ExpiryDate: tempData.DlNo2ExpiryDate,
          NrxExpiryDate: tempData.NrxExpiryDate,
          Phone: tempData.Phone,
        },
        AddressLine1: tempData.AddressLine1,
        AddressLine2: tempData.AddressLine2,
        PincodeId: tempData.PincodeId,
        CityId: tempData.CityId,
      };
    } else {
      submitData = {
        VendorToUpdate: {
          Id: id,
          VendorName: tempData.VendorName,
          Gstno: tempData.Gstno,
          Discount: tempData.Discount,
          CompanyId: tempData.CompanyId,
          FssAi: tempData.FssAi,
          DlNo1: tempData.DlNo1,
          DlNo2: tempData.DlNo2,
          Nrx: tempData.Nrx,
          FssAiExpiryDate: tempData.FssAiExpiryDate,
          DlNo1ExpiryDate: tempData.DlNo1ExpiryDate,
          DlNo2ExpiryDate: tempData.DlNo2ExpiryDate,
          NrxExpiryDate: tempData.NrxExpiryDate,
          Phone: tempData.Phone,
          CreatedBy: tempData.CreatedBy,
          CreatedOn: tempData.CreatedOn,
        },
        AddressLine1: tempData.AddressLine1,
        AddressLine2: tempData.AddressLine2,
        PincodeId: tempData.PincodeId,
        CityId: tempData.CityId,
      };
    }
    mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", { position: "top-right" });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);

  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Inventory Vendor`}
        modal
        style={{ width: "1100px" }}
      >
        {!SampleLoader ? (
          <form onSubmit={handleSubmit(VendorFormData)}>
            <div className="row">
              <Input
                containerClass="col-lg-3"
                label="Vendor Name"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("VendorName", e.target.value);
                  clearErrors(["VendorName"]);
                }}
                register={{
                  ...register("VendorName", {
                    required: {
                      value: true,
                      message: "Vendor Name is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.VendorName?.message ||
                  error?.response?.data?.errors?.VendorName?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="GST No"
                type="text"
                isRequired
                onChange={(e: any) => {
                  setValue("Gstno", e.target.value);
                  clearErrors(["Gstno"]);
                }}
                register={{
                  ...register("Gstno", {
                    required: {
                      value: true,
                      message: "Gst no is required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Gstno?.message ||
                  error?.response?.data?.errors?.Gstno?.map((e: any) => e)
                }
              />

              <ReactSelect
                containerClass="col-lg-3"
                label="Company"
                name={"CompanyId"}
                isRequired
                onChange={(e: any) => {
                  if (e !== null) {
                    setValue("CompanyId", e?.value);
                    setSelectedCompany(e);
                  } else {
                    setValue("CompanyId", null);
                    setSelectedCompany(null);
                  }
                }}
                value={selectedCompany}
                control={control}
                api="Reporting/Company?pageNumber=1&pageSize=10"
                keys={{ label: "Value", value: "Id" }}
                errorMessage={errors?.CompanyId?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Company Is Required",
                  },
                }}
              />

              <Input
                containerClass="col-lg-3"
                label="DL No 1"
                isRequired
                type="text"
                onChange={(e: any) => {
                  setValue("DlNo1", e.target.value);
                  clearErrors(["DlNo1"]);
                }}
                register={{
                  ...register("DlNo1", {
                    required: {
                      value: true,
                      message: "DL Number Is Required",
                    },
                  }),
                }}
                errorMessage={
                  errors?.DlNo1?.message ||
                  error?.response?.data?.errors?.DlNo1?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="FSSAI Expiry Date"
                type="date"
                errorMessage={
                  errors?.FssAiExpiryDate?.message ||
                  error?.response?.data?.errors?.FssAiExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("FssAiExpiryDate", e.target.value);
                  setSelectedFssAiExpiryDate(e.target.value);

                  clearErrors(["FssAiExpiryDate"]);
                }}
                register={{
                  ...register("FssAiExpiryDate"),
                }}
                value={selectedFssAiExpiryDate}
              />
              <Input
                containerClass="col-lg-3"
                label="DL No1 Expiry Date"
                type="date"
                errorMessage={
                  errors?.DlNo1ExpiryDate?.message ||
                  error?.response?.data?.errors?.DlNo1ExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DlNo1ExpiryDate", e.target.value);
                  setSelectedDlNo1ExpiryDate(e.target.value);
                  clearErrors(["DlNo1ExpiryDate"]);
                }}
                register={{
                  ...register("DlNo1ExpiryDate"),
                }}
                value={selectedDlNo1ExpiryDate}
              />
              <Input
                containerClass="col-lg-3"
                label="DL No2 Expiry Date"
                type="date"
                errorMessage={
                  errors?.DlNo2ExpiryDate?.message ||
                  error?.response?.data?.errors?.DlNo2ExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("DlNo2ExpiryDate", e.target.value);
                  setSelectedDlNo2ExpiryDate(e.target.value);
                  clearErrors(["DlNo2ExpiryDate"]);
                }}
                register={{
                  ...register("DlNo2ExpiryDate"),
                }}
                value={selectedDlNo2ExpiryDate}
              />
              <Input
                containerClass="col-lg-3"
                label="NRX Expiry Date"
                type="date"
                errorMessage={
                  errors?.NrxExpiryDate?.message ||
                  error?.response?.data?.errors?.NrxExpiryDate?.map(
                    (e: any) => e
                  )
                }
                onChange={(e: any) => {
                  setValue("NrxExpiryDate", e.target.value);
                  setSelectedNrxExpiryDate(e.target.value);
                  clearErrors(["NrxExpiryDate"]);
                }}
                register={{
                  ...register("NrxExpiryDate"),
                }}
                value={selectedNrxExpiryDate}
              />

              <Input
                containerClass="col-lg-3"
                label="FSSAI"
                type="text"
                onChange={(e: any) => {
                  setValue("FssAi", e.target.value);
                  clearErrors(["FssAi"]);
                }}
                register={{
                  ...register("FssAi", {
                    pattern: {
                      value: /^[0-9]{14}$/,
                      message: "Enter a valid FSSAI (14 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.FssAi?.message ||
                  error?.response?.data?.errors?.FssAi?.map((e: any) => e)
                }
              />

              <Input
                containerClass="col-lg-3"
                label="DL No 2"
                type="text"
                onChange={(e: any) => {
                  setValue("DlNo2", e.target.value);
                  clearErrors(["DlNo2"]);
                }}
                register={{
                  ...register("DlNo2"),
                }}
                errorMessage={
                  errors?.DlNo2?.message ||
                  error?.response?.data?.errors?.DlNo2?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="NRX"
                type="text"
                maxLength={10}
                onChange={(e: any) => {
                  setValue("Nrx", e.target.value);
                  clearErrors(["Nrx"]);
                }}
                register={{
                  ...register("Nrx"),
                }}
                errorMessage={
                  errors?.Nrx?.message ||
                  error?.response?.data?.errors?.Nrx?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="Phone No"
                type="text"
                onChange={(e: any) => {
                  setValue("Phone", e.target.value);
                  clearErrors(["Phone"]);
                }}
                register={{
                  ...register("Phone", {
                    pattern: {
                      value: /^\d{6,10}$/,
                      message: "Enter a valid phone no. (6-10 digits only)",
                    },
                  }),
                }}
                errorMessage={
                  errors?.Phone?.message ||
                  error?.response?.data?.errors?.Phone?.map((e: any) => e)
                }
              />
              <Input
                containerClass="col-lg-3"
                label="Discount"
                type="text"
                onChange={(e: any) => {
                  setValue("Discount", e.target.value);
                  clearErrors(["Discount"]);
                }}
                register={{
                  ...register("Discount", {}),
                }}
                errorMessage={
                  errors?.Discount?.message ||
                  error?.response?.data?.errors?.Discount?.map((e: any) => e)
                }
              />
              <Address
                requiredflag
                header="Vendor Address"
                control={control}
                address1register={{
                  ...register("AddressLine1", {
                    required: {
                      value: true,
                      message: "AddressLine 1 Is Required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid Address(2-250 characters)",
                    },
                  }),
                }}
                address1onChange={(e: any) => {
                  setValue("AddressLine1", e.target.value);
                  clearErrors(["AddressLine1"]);
                }}
                address1errorMessage={
                  errors?.AddressLine1?.message ||
                  error?.response?.data?.errors?.AddressLine1?.map(
                    (e: any) => e
                  )
                }
                address2register={{
                  ...register("AddressLine2", {
                    required: {
                      value: true,
                      message: "Area Is Required",
                    },
                    pattern: {
                      value: /^.{2,250}$/gm,
                      message: "Enter a valid Area(2-250 characters)",
                    },
                  }),
                }}
                address2onChange={(e: any) => {
                  setValue("AddressLine2", e.target.value);
                  clearErrors(["AddressLine2"]);
                }}
                address2errorMessage={
                  errors?.AddressLine2?.message ||
                  error?.response?.data?.errors?.AddressLine2?.map(
                    (e: any) => e
                  )
                }
                address4onChange={(e: any) => {
                  if (e !== null) {
                    setValue("CityId", e?.value);
                    setselectedcity(e);
                    setselectedpincode(null);
                  } else {
                    setValue("CityId", null);
                    setselectedcity(null);
                    setValue("PincodeId", null);
                    setselectedpincode(null);
                  }
                }}
                address4errorMessage={
                  errors?.CityId?.message ||
                  error?.response?.data?.errors?.CityId?.map((e: any) => e)
                }
                selectedCity={selectedcity}
                address5onChange={(e: any) => {

                  if (e !== null) {
                    setValue("PincodeId", e?.value);
                    setselectedpincode(e);
                  } else {
                    setValue("PincodeId", null);
                    setselectedpincode(null);
                  }
                }}
                address5errorMessage={
                  errors?.PincodeId?.message ||
                  error?.response?.data?.errors?.PincodeId?.map((e: any) => e)
                }
                selectedPincode={selectedpincode}
                cityKeys={{ label: "Value", value: "Id" }}
                cityApi={
                  // !cityDrop ?
                  "Reporting/city/GetAllCityAndState"
                  // : "Reporting/city/GetAllCityAndState?underHQ=true"
                }
                cityFlag={true}
                cityName={"CityId"}
                pincodeKeys={{ label: "Value", value: "Id" }}
                pincodeApi={
                  Hcity &&
                  `Reporting/Pincode/GetAllPincodeByParent?CityId=${Hcity}`
                }
                pincodeName={"PincodeId"}
                pincodeDisable={Hcity ? false : true}
                cityrules={{
                  required: { value: true, message: "City is required" },
                }}
                pincoderules={{
                  required: { value: true, message: "Pincode is required" },
                }}
              />
              <div className="col-lg-12 text-center mt-3">
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  onClick={() => setisOPen(false)}
                >
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {id === 0 && (
                  <Button
                    type="reset"
                    severity="danger"
                    onClick={() => {
                      reset();
                      setValue("FssAiExpiryDate", null);
                      setSelectedFssAiExpiryDate(null);
                      setValue("DlNo1ExpiryDate", null);
                      setSelectedDlNo1ExpiryDate(null);
                      setValue("DlNo2ExpiryDate", null);
                      setSelectedDlNo2ExpiryDate(null);
                      setValue("NrxExpiryDate", null);
                      setSelectedNrxExpiryDate(null);
                      // setSelectedHSN(null);
                      // setValue("Hsnid", null);
                      setSelectedCompany(null);
                      setValue("CompanyId", null);
                      setselectedcity(null);
                      setValue("CityId", null);
                      setselectedpincode(null);
                      setValue("PincodeId", null);
                    }}
                    outlined
                    className="ml-2"
                  >
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                )}

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditInventoryVendor;
