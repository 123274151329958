import AdminPanelCard from "../common/AdminPanelCard";
import DoctorImg from "../assets/images/Vector (1).png";
import ChemistImg from "../assets/images/Group 5252.png";
import StockistImg from "../assets/images/Group (1).png";
import HospitalImg from "../assets/images/homehospital.png";
import ProductImg from "../assets/images/product-svgrepo-com.png";
import EmployeeImg from "../assets/images/Group.png";
import EventImg from "../assets/images/EventImg.png";
import { Helmet } from "react-helmet";

export const Attributes = () => {
  let admincard = [
    {
      name: "Doctor",
      link: "/layout/doctorattributes",
      img: DoctorImg,
    },
    {
      name: "Chemist",
      link: "/layout/chemistattributes",
      img: ChemistImg,
    },
    {
      name: "Hospital",
      link: "/layout/hospitalattributes",
      img: HospitalImg,
    },
    {
      name: "Stockist",
      link: "/layout/stockistattributes",
      img: StockistImg,
    },
    {
      name: "Product",
      link: "/layout/productattributes",
      img: ProductImg,
    },
    {
      name: "Employee(MR)",
      link: "/layout/employeeattributes",
      img: EmployeeImg,
    },
    {
      name: "Event",
      link: "/layout/eventattributes",
      img: EventImg,
    },
    {
      name: "Inventory",
      link: "/layout/inventoryattributes",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Attributes</title>
      </Helmet>
      <div className="d-flex row m-2 admin-panel">
        {admincard?.map((card, index) => (
          <div key={index} className="col-4 pb-3">
            <AdminPanelCard
              name={card?.name}
              link={card?.link}
              imageSrc={card?.img}
            />
          </div>
        ))}
      </div>
    </>
  );
};
