import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { Input } from "../../../common/Input";
import ReactSelect from "../../../common/ReactSelect";
import { useFieldArray, useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useCreate } from "../../../hooks/Api/Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../../common/Spinner";
import Textarea from "../../../common/Textarea";

export const ChemistVisit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const apiname = process.env.REACT_APP_CHEMISTVISIT_API?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  }: any = useForm({
    defaultValues: {
      LinkedDoctorId: [],
      UnlinkedDoctorId: [],
      RcpaOurProducts: [
        {
          OurCompetingProductId: "",
          OurCompetingProductSellQty: "",
          Price: "",
          DailyRx: "",
          WeeklyRx: "",
          MonthlyRx: "",
          CompititorProducts: [
            {
              CompetitorProductId: "",
              CompetitorProductSellQty: "",
            },
          ],
        },
      ],
      PromotedGift: [
        {
          GiftId: "",
          SampleQty: "",
        },
      ],
    },
  });

  const {
    fields: gift,
    append: giftadd,
    remove: giftremove,
  } = useFieldArray({ control, name: "PromotedGift" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "RcpaOurProducts",
  });

  const renderCompetitorProducts = (i: any, product: any) => {
    return product.CompititorProducts.map((e: any, index: any) => (
      <tr key={i}>
        <td>
          <Select
            {...register(
              `RcpaOurProducts[${i}].CompititorProducts[${index}].CompetitorProductId`,
              {
                required: {
                  value: true,
                  message: "Competitor is required",
                },
              }
            )}
            options={competitorproductOptions}
            className="z-3"
            classNamePrefix={"z-3"}
            onChange={(e: any) => {
              setValue(
                `RcpaOurProducts[${i}].CompititorProducts[${index}].CompetitorProductId`,
                e?.value
              );
            }}
          />
          <ErrorMessage
            message={
              errors?.["RcpaOurProducts"]?.[i]?.["CompititorProducts"]?.[
                index
              ]?.["CompetitorProductId"]?.message
            }
          />
        </td>
        <td>
          <div className="mt-3">
            <Input
              containerClass="col-lg-12"
              isRequired
              type="text"
              register={{
                ...register(
                  `RcpaOurProducts[${i}].CompititorProducts[${index}].CompetitorProductSellQty`,
                  {
                    required: {
                      value: true,
                      message: "CompetitorProductSellQty is Required",
                    },
                  }
                ),
              }}
              errorMessage={
                errors?.["RcpaOurProducts"]?.[i]?.["CompititorProducts"]?.[
                  index
                ]?.["CompetitorProductSellQty"]?.message
              }
            />
          </div>
        </td>
        <td>
          <div className="col-lg-12 text-center">
            {product.CompititorProducts.length !== 1 ? (
              <button
                type="button"
                onClick={() => {
                  removeCompetitorProduct(i, index);
                }}
                className="btn btn-danger"
              >
                Delete Competitor Product
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  removeCompetitorProduct(i, index);
                }}
                className="btn btn-danger"
                disabled
              >
                Delete Competitor Product
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  const appendCompetitorProduct = (productIndex: any) => {
    const products = watch("RcpaOurProducts");
    const updatedProducts = [...products];
    updatedProducts[productIndex].CompititorProducts.push({
      CompetitorProductId: "",
      CompetitorProductSellQty: "",
    });
    setValue("RcpaOurProducts", updatedProducts);
  };

  const removeCompetitorProduct = (productIndex: any, competitorIndex: any) => {
    const products = watch("RcpaOurProducts");
    const updatedProducts = [...products];
    updatedProducts[productIndex].CompititorProducts.splice(competitorIndex, 1);
    setValue("RcpaOurProducts", updatedProducts);
  };

  const [selectedlinkeddoctor, setselectedlinkeddoctor]: any = useState([]);
  const [selectedunlinkeddoctor, setselectedunlinkeddoctor]: any = useState([]);
  const [selectedproduct, setselectedproduct]: any = useState({});
  const [productOptions, setproductOptions] = useState([]);
  const fetchproductOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRODUCT_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.Name,
        value: option?.ProductId,
      };
    });
    setproductOptions(data);
  };

  const [giftOptions, setgiftOptions] = useState([]);
  const fetchgiftOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_GIFTMASTER_API}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.GiftName,
        value: option?.GiftMasterId,
      };
    });
    setgiftOptions(data);
  };

  useEffect(() => {
    fetchgiftOptions();
    fetchproductOptions();
  }, []);

  const [competitorproductOptions, setcompetitorproductOptions] = useState([]);
  const fetchcompetitorproductOptions = async () => {

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRODUCTGETCOMPETITORPRODUCTBYID_API}?productid=${selectedproduct.value}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.Name,
        value: option?.ProductId,
      };
    });
    setcompetitorproductOptions(data);
  };

  useEffect(() => {
    if (Object.keys(selectedproduct).length !== 0) {
      fetchcompetitorproductOptions();
    }
  }, [selectedproduct]);

  const { mutate, isSuccess, isError, error, isLoading }: any =
    useCreate(apiname);

  const RCPAModel = async (data: any) => {

    console.log(data, "Final Data");
    data.Id = location.state.id;
    data.LinkedDoctorId = selectedlinkeddoctor?.map((e: any) => {
      return e?.value;
    });
    data.UnlinkedDoctorId = selectedunlinkeddoctor?.map((e: any) => {
      return e?.value;
    });

    const products = data?.RcpaOurProducts.map((p: any) => {
      return {
        OurCompetingProductId: p.OurCompetingProductId,
        Price: Number(p.Price),
        DailyRx: Number(p.DailyRx),
        MonthlyRx: Number(p.MonthlyRx),
        WeeklyRx: Number(p.WeeklyRx),
        OurCompetingProductSellQty: Number(p.OurCompetingProductSellQty),

        CompititorProducts: p?.CompititorProducts.map((e: any) => {
          return {
            CompetitorProductId: e.CompetitorProductId,
            CompetitorProductSellQty: Number(e.CompetitorProductSellQty),
          };
        })
      };
    });
    data.RcpaOurProducts = products;



    const gift = data?.PromotedGift.map((e: any) => {
      return {
        GiftId: e.GiftId,
        SampleQty: Number(e.SampleQty),
      };
    });
    data.PromotedGift = gift;

    console.log(data, "xyz");
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Added Successfully", { position: "top-right" });
      navigate("/layout/mr/visit");
      reset();
      setselectedlinkeddoctor({});
      setselectedproduct({});
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess]);

  return (
    <>
      <Helmet>
        <title>Chemist Visit</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Chemist Visit</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page"></li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <h4>
              Chemist Name:-{" "}
              <span className="text-blue">{location?.state?.name}</span>
            </h4>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit((info: any) => {
          RCPAModel(info);
        })}
      >
        <div className="pd-20 card-box mb-30">
          <div className="row">
            {/* Linked Doctor */}
            <ReactSelect
              containerClass="col-lg-6"
              label="Doctor Linked"
              onChange={(e: any) => {
                setselectedlinkeddoctor(e);
                setValue("LinkedDoctorId", e?.value);
              }}
              value={selectedlinkeddoctor}
              name={"LinkedDoctorId"}
              control={control}
              api={`DoctorDetail/GetDoctorByChemistVisit?chemistVisitId=${location?.state?.id}`}
              flag
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.LinkedDoctorId?.message}
              Multi
            />

            {/* Unlinked Doctor */}
            <ReactSelect
              containerClass="col-lg-6"
              label="Doctor Unlinked"
              onChange={(e: any) => {
                setselectedunlinkeddoctor(e);
                setValue("UnlinkedDoctorId", e?.value);
              }}
              Multi
              value={selectedunlinkeddoctor}
              name={"UnlinkedDoctorId"}
              control={control}
              api={`DoctorDetail/GetUnlinkedDoctorByChemistVisit?chemistVisitId=${location?.state?.id}`}
              flag
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.UnlinkedDoctorId?.message}
            />

            {/* Promoted Product */}
            <div className="col-lg-12 text-right">
              <button
                type="button"
                onClick={() => {
                  append({
                    OurCompetingProductId: "",
                    OurCompetingProductSellQty: "",
                    Price: "",
                    DailyRx: "",
                    WeeklyRx: "",
                    MonthlyRx: "",
                    CompititorProducts: [
                      { CompetitorProductId: "", CompetitorProductSellQty: "" },
                    ],
                    // PromotedGift: [{ GiftId: "", SampleQty: "" }],
                  });
                }}
                className="btn btn-primary mb-2"
              >
                Add Product
              </button>
            </div>

            <div className="col-lg-12">
              {fields?.map((e: any, i: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="col-form-label">Product</label>
                          <Select
                            {...register(
                              `RcpaOurProducts.${i}.OurCompetingProductId`,
                              {
                                required: {
                                  value: true,
                                  message: "Gift is required",
                                },
                              }
                            )}
                            options={productOptions}
                            className="z-3"
                            classNamePrefix={"z-3"}
                            onChange={(e: any) => {
                              setValue(
                                `RcpaOurProducts.${i}.OurCompetingProductId`,
                                e?.value
                              );
                              setselectedproduct(e);
                            }}
                          />
                          <ErrorMessage
                            message={
                              errors?.["RcpaOurProducts"]?.[i]?.[
                                "OurCompetingProductId"
                              ]?.message
                            }
                          />
                        </div>
                      </div>

                      <Input
                        containerClass="col-lg-4"
                        label="Quantity(Monthly)"
                        isRequired
                        type="text"
                        register={{
                          ...register(
                            `RcpaOurProducts.${i}.OurCompetingProductSellQty`,
                            {
                              required: {
                                value: true,
                                message: "Product Qty is Required",
                              },
                            }
                          ),
                        }}
                        errorMessage={
                          errors?.["RcpaOurProducts"]?.[i]?.[
                            "OurCompetingProductSellQty"
                          ]?.message
                        }
                      />

                      <Input
                        containerClass="col-lg-4"
                        label="Price"
                        isRequired
                        type="text"
                        register={{
                          ...register(`RcpaOurProducts.${i}.Price`, {
                            required: {
                              value: true,
                              message: "Price is Required",
                            },
                          }),
                        }}
                        errorMessage={
                          errors?.["RcpaOurProducts"]?.[i]?.["Price"]?.message
                        }
                      />

                      <Input
                        containerClass="col-lg-4"
                        label="DailyRx"
                        isRequired
                        type="text"
                        register={{
                          ...register(`RcpaOurProducts.${i}.DailyRx`, {
                            required: {
                              value: true,
                              message: "DailyRx is Required",
                            },
                          }),
                        }}
                        errorMessage={
                          errors?.["RcpaOurProducts"]?.[i]?.["DailyRx"]?.message
                        }
                      />

                      <Input
                        containerClass="col-lg-4"
                        label="WeeklyRx"
                        isRequired
                        type="text"
                        register={{
                          ...register(`RcpaOurProducts.${i}.WeeklyRx`, {
                            required: {
                              value: true,
                              message: "WeeklyRx is Required",
                            },
                          }),
                        }}
                        errorMessage={
                          errors?.["RcpaOurProducts"]?.[i]?.["WeeklyRx"]
                            ?.message
                        }
                      />

                      <Input
                        containerClass="col-lg-4"
                        label="MonthlyRx"
                        isRequired
                        type="text"
                        register={{
                          ...register(`RcpaOurProducts.${i}.MonthlyRx`, {
                            required: {
                              value: true,
                              message: "MonthlyRx is Required",
                            },
                          }),
                        }}
                        errorMessage={
                          errors?.["RcpaOurProducts"]?.[i]?.["MonthlyRx"]
                            ?.message
                        }
                      />
                    </div>

                    <div className="col-lg-12 text-right">
                      <button
                        type="button"
                        onClick={() => {
                          appendCompetitorProduct(i);
                        }}
                        className="btn btn-primary mb-2"
                      >
                        Add Competitor Product
                      </button>
                    </div>
                    <div className="col-lg-12">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr className="text-center">
                            <th>Competitor Product Name</th>
                            <th>Competitor Product Quantity(Monthly)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{renderCompetitorProducts(i, e)}</tbody>
                      </table>
                    </div>
                    <hr style={{ border: "1px solid black" }} />
                  </>
                );
              })}
            </div>

            {/* Promoted Gift  */}
            <div className="col-lg-12 text-right">
              <button
                type="button"
                onClick={() => {
                  giftadd({
                    GiftId: "",
                    SampleQty: "",
                  });
                }}
                className="btn btn-primary mb-2"
              >
                Add Gift
              </button>
            </div>

            <div className="col-lg-12">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr className="text-center">
                    <th>Gift Name</th>
                    <th>Gift Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {gift.map((field: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Select
                            {...register(`PromotedGift.${i}.GiftId`, {
                              required: {
                                value: true,
                                message: "Gift is required",
                              },
                            })}
                            options={giftOptions}
                            className="z-3"
                            classNamePrefix={"z-3"}
                            onChange={(e: any) => {
                              setValue(`PromotedGift.${i}.GiftId`, e?.value);
                            }}
                          />
                          <ErrorMessage
                            message={
                              errors?.["PromotedGift"]?.[i]?.["GiftId"]?.message
                            }
                          />
                        </td>
                        <td>
                          <div className="mt-3">
                            <Input
                              containerClass="col-lg-12"
                              isRequired
                              type="text"
                              register={{
                                ...register(`PromotedGift.${i}.SampleQty`, {
                                  required: {
                                    value: true,
                                    message: "Sample Qty is Required",
                                  },
                                }),
                              }}
                              errorMessage={
                                errors?.["PromotedGift"]?.[i]?.["SampleQty"]
                                  ?.message
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="col-lg-12 text-center">
                            {gift.length !== 1 ? (
                              <button
                                type="button"
                                onClick={() => {
                                  giftremove(i);
                                }}
                                className="btn btn-danger"
                              >
                                Delete Gift
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  giftremove(i);
                                }}
                                className="btn btn-danger"
                                disabled
                              >
                                Delete Gift
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Textarea
              containerClass="col-lg-12"
              label="Remarks"
              // isRequired
              register={{
                ...register("Remarks", {
                  // required: { value: true, message: "Remarks is required" },
                }),
              }}
              errorMessage={errors?.Remarks?.message}
            />

            <div className="col-lg-12 text-center">
              <Link
                to="/layout/mr/visit"
                className="btn btn-sm btn-outline-primary mr-1"
              >
                Back
              </Link>
              {!isLoading ? (
                <button
                  type="submit"
                  className="btn btn-sm btn-outline-success"
                >
                  Submit
                </button>
              ) : (
                <button className="btn btn-success" disabled>
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
