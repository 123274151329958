import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface iPropsmoadl {
  open?: any;
  onClick?: any;
  onClose?:any
}

const TourPlanLockedModel = ({ open, onClick,onClose }: iPropsmoadl) => {
  return (
    <>
      <Dialog
        visible={open}
        onHide={onClose}
        header="Alert"
        modal
        style={{ width: "15vw" }}
        footer={
          <div className="col-md-12 d-flex justify-content-between">
            <Button
              label="Make AnyWay"
              icon="pi pi-check"
              className="p-button-text"
              onClick={onClose}
            />
            <Button
              label="Ok"
              icon="pi pi-times"
              className="p-button-text"
              onClick={onClick}
            />
          </div>
        }
      >
        No Working Day Today
      </Dialog>
    </>
  );
};

export default TourPlanLockedModel;
