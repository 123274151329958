import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axiosInstance from "../../_helper/Interceptor";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";

export const PartyWiseReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  }: any = useForm({ mode: "all" });


  const [isLoading, setIsLoading] = useState(false);

  const saveFile = (data: any, fileName: string) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    return window.URL.revokeObjectURL(url);
  };


  // Division
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [selecteddivision, setselecteddivision] = useState([]);
  const fetchdivisionOptions = async () => {
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DIVISION_API}`)
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.DivisionName,
            value: option?.DivisionId,
          };
        });
        setDivisionOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };
  useEffect(() => {
    fetchdivisionOptions()
  }, [])

  // Zone
  const [zoneOptions, setzoneOptions] = useState([]);
  const [selectedZone, setselectedZone] = useState([]);
  const fetchzoneOptions = async () => {
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_ZONE_API}`)
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.ZoneName,
            value: option?.ZoneId,
          };
        });
        setzoneOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    fetchzoneOptions()
  }, [])

  const zoneid = selectedZone?.map((e: any) => `id=${e?.value}`);
  // State
  const [stateOptions, setstateOptions] = useState([]);
  const [selectedState, setselectedState] = useState([]);
  const fetchstateOptions = async () => {
    await axiosInstance
      .get(`${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STATE_MULTIPLEZONE_API}?${String(zoneid)?.replaceAll(",", "&")}`)
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.StateName,
            value: option?.StateId,
          };
        });
        setstateOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    if (selectedZone.length !== 0) {
      fetchstateOptions();
    }
  }, [selectedZone]);

  const stateid = selectedState?.map((e: any) => `id=${e?.value}`);
  // Region
  const [regionOptions, setregionOptions] = useState([]);
  const [selectedrigion, setselectedrigion] = useState([]);
  const fetchregionOptions = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_REGION_MULTIPLESTATE_API}?${String(stateid)?.replaceAll(",", "&")}`
      )
      .then((res) => {
        const data = res?.data?.Data?.map((option: any) => {
          return {
            label: option?.RegionName,
            value: option?.RegionId,
          };
        });
        setregionOptions(data);
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    if (selectedState.length !== 0) {
      fetchregionOptions();
    }
  }, [selectedState]);


  const regionid = selectedrigion?.map((e: any) => `regionId=${e?.value}`);

  const [doctorOptions, setdoctorOptions] = useState([]);
  const [selecteddoctor, setselecteddoctor]: any = useState([]);
  const fetchdoctorOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKISTGETSTOCKISTBYREGION_API}?${String(regionid)?.replaceAll(",", "&")}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.StockistName,
        value: option?.StockistId,
      };
    });
    setdoctorOptions(data);
  };


  const [chemistOptions, setchemistOptions] = useState([]);
  const [selectedchemist, setselectedchemist]: any = useState([]);
  const fetchchemistOptions = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_CHEMISTBYREGION_API}?${String(regionid)?.replaceAll(",", "&")}`
    );
    const data = response?.data?.Data?.map((option: any) => {
      return {
        label: option?.ChemistName,
        value: option?.ChemistId,
      };
    });
    setchemistOptions(data);
  };
  useEffect(() => {
    if (selectedrigion.length !== 0) {
      fetchdoctorOptions()
      fetchchemistOptions();
    }
  }, [selectedrigion]);


  const handleDownload = async (data: any) => {

    const division = data.DivisionId?.map((e: any) => `DivisionIds=${e?.value}`);
    const Zone = data.ZoneId?.map((e: any) => `ZoneIds=${e?.value}`);
    const State = data.StateId?.map((e: any) => `StateIds=${e?.value}`);
    const Region = data.RegionId?.map((e: any) => `RegionIds=${e?.value}`);
    const stockiest = data.StockistId?.map((e: any) => `StockistIds=${e?.value}`);
    const chemist = data.ChemistId?.map((e: any) => `ChemistIds=${e?.value}`);

    setIsLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_PRIMARYSALEGETPARTYWISESALESREPORT_API}?Date=${data.Date}${division == undefined ? "" : `&${String(division)?.replaceAll(",", "&")}`}${chemist == undefined ? "" : `&${String(chemist)?.replaceAll(",", "&")}`}${Region == undefined ? "" : `&${String(Region)?.replaceAll(",", "&")}`}${State == undefined ? "" : `&${String(State)?.replaceAll(",", "&")}`}${Zone == undefined ? "" : `&${String(Zone)?.replaceAll(",", "&")}`}${stockiest == undefined ? "" : `&${String(stockiest)?.replaceAll(",", "&")}`}`,
        { responseType: "blob" }
      )
      .then((response) => {
        saveFile(response.data, "PartyWiseReport.xlsx");
        setIsLoading(false);
        toast.success(`Download Successfull`, { position: "top-right" });
        reset();
      })
      .catch((error) => {
        // toast.error(`${error.message}`, { position: "top-right" });
        var fr = new FileReader();
        fr.readAsArrayBuffer(error.response.data);
        fr.onload = function (evt: any) {
          var res = evt.target.result;
          let parsedJson = JSON.parse(
            new TextDecoder().decode(res as ArrayBuffer)
          );
          toast.error(`${parsedJson.Message}`, { position: "top-right" });
        };
        setIsLoading(false);
      });
  };


  return (
    <>
      <Helmet>
        <title>Partywise Sale Report</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="title">
              <h4>Partywise Sale Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Partywise Sale Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>


      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            handleDownload(data);
          })}
        >
          <div className="row">
            <Input
              containerClass="col-lg-4"
              label="Month"
              type="date"
              isRequired
              errorMessage={errors?.Date?.message}
              register={{
                ...register("Date", {
                  required: {
                    value: true,
                    message: "Date is required",
                  },
                }),
              }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Division"
              name={"DivisionId"}
              Multi
              onChange={(e: any) => {
                setselecteddivision(e);
                setValue("DivisionId", e);
              }}
              value={selecteddivision}
              control={control}
              options={divisionOptions}
              // rules={{
              //   required: { value: true, message: "Division is required" },
              // }}
              errorMessage={
                errors?.DivisionId?.message
              }
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Zone"
              control={control}
              name="ZoneId"
              Multi
              onChange={(e: any) => {
                setselectedZone(e);
                setValue("ZoneId", e);
              }}
              value={selectedZone}
              options={zoneOptions}
              errorMessage={errors?.ZoneId?.message}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Zone is required",
            //   },
            // }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="State"
              control={control}
              name="StateId"
              Multi
              onChange={(e: any) => {
                setselectedState(e);
                setValue("StateId", e);
              }}
              value={selectedState}
              options={stateOptions}
              errorMessage={errors?.StateId?.message}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "State is required",
            //   },
            // }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Region"
              control={control}
              name="RegionId"
              Multi
              onChange={(e: any) => {
                setselectedrigion(e);
                setValue("RegionId", e);
              }}
              value={selectedrigion}
              options={regionOptions}
              errorMessage={
                errors?.RegionId?.message}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Region is required",
            //   },
            // }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Stockist"
              control={control}
              name="StockistId"
              Multi
              options={doctorOptions}
              onChange={(e: any) => {
                setselecteddoctor(e);
                setValue("StockistId", e);
              }}
              value={selecteddoctor}
              // handleCreate={handlecontactpersonChange}
              errorMessage={errors?.StockistId?.message}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Stockist is required",
            //   },
            // }}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Chemist"
              control={control}
              name="ChemistId"
              Multi
              options={chemistOptions}
              onChange={(e: any) => {
                setselectedchemist(e);
                setValue("ChemistId", e);
              }}
              value={selectedchemist}
              // handleCreate={handlecontactpersonChange}
              errorMessage={errors?.ChemistId?.message}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "Chemist is required",
            //   },
            // }}
            />
          </div>

          <div className="col-md-12 text-center mt-30">
            {
              !isLoading ?
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                >
                  Download
                </button> : <button
                  type="submit"
                  className="btn btn-outline-primary"
                  disabled
                >
                  Download  <Spinner width={"1rem"} height={"1rem"} />
                </button>
            }
          </div>
        </form>
      </div>
    </>
  );
};
