import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../_helper/Interceptor";
import { useEffect, useState } from "react";
import { Input } from "../../common/Input";
import ReactSelect from "../../common/ReactSelect";
import { Spinner } from "../../common/Spinner";
import { Button } from "../../common/Button";
import { Accordion, AccordionTab } from "primereact/accordion";

const JWRObservationReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    clearErrors,
  }: any = useForm({ mode: "all" });
  let navigate = useNavigate();
  const [data, setData]: any = useState(null);
  const [selectedEmployee, setSelectedEmployee]: any = useState(null);
  const [loader, setLoader] = useState(false);

  let role = localStorage.getItem("Roles");

  const onSubmit = (data: any) => {
    setLoader(true);
    console.log(data, "dsad");
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${
          process.env.REACT_APP_PTHREEREPORTGETJWROBSERVATION_API
        }?StartDate=${startdate}&EndDate=${defaultDate}${
          data.EmployeeId !== undefined ? `&EmployeeId=${data.EmployeeId}` : ""
        }`
      )
      .then((e) => {
        if (e.data.Data?.JwrMonthWises?.length > 1) {
          let newData = e?.data?.Data;
          let Jwr = newData?.JwrMonthWises;
          let JwrManager = Jwr[0]?.JwrManagers.map((e: any) => {
            return {
              ManagerName: e?.ManagerName,
              JwrLists: e?.JwrLists?.map((a: any) => {
                return {
                  Id: 1,
                  Title: a?.Title,
                  Guideline: a?.Guideline,
                  MaxScore: a?.MaxScore,
                  ExpectedScore: a?.ExpectedScore,
                  OrderNo: a?.OrderNo,
                  Score:
                    Jwr?.map((b: any) => {
                      return b?.JwrManagers?.map((c: any) => {
                        return c?.JwrLists?.filter((d: any) => {
                          return d?.Title == a?.Title;
                        }).map((f: any) => (f.Score === null ? 0 : f.Score))[0];
                      });
                    }).reduce((a: any, b: any) => a + b, 0) / Jwr.length,
                };
              }),
            };
          });
          let ytdData = {
            Title: "YTD",
            TotalNoOfWorkDays: Jwr?.reduce(
              (a: any, { TotalNoOfWorkDays }: any) => a + TotalNoOfWorkDays,
              0
            ),
            TotalDoctorVisit: Jwr?.reduce(
              (a: any, { TotalDoctorVisit }: any) => a + TotalDoctorVisit,
              0
            ),
            Total_A_DoctorVisit: Jwr?.reduce(
              (a: any, { Total_A_DoctorVisit }: any) => a + Total_A_DoctorVisit,
              0
            ),
            Total_B_DoctorVisit: Jwr?.reduce(
              (a: any, { Total_B_DoctorVisit }: any) => a + Total_B_DoctorVisit,
              0
            ),
            Total_C_DoctorVisit: Jwr?.reduce(
              (a: any, { Total_C_DoctorVisit }: any) => a + Total_C_DoctorVisit,
              0
            ),
            Total_Other_DoctorVisit: Jwr?.reduce(
              (a: any, { Total_Other_DoctorVisit }: any) =>
                a + Total_Other_DoctorVisit,
              0
            ),
            TotalChemistVisit: Jwr?.reduce(
              (a: any, { TotalChemistVisit }: any) => a + TotalChemistVisit,
              0
            ),
            TotalHospitalVisit: Jwr?.reduce(
              (a: any, { TotalHospitalVisit }: any) => a + TotalHospitalVisit,
              0
            ),
            TotalStockistVisit: Jwr?.reduce(
              (a: any, { TotalStockistVisit }: any) => a + TotalStockistVisit,
              0
            ),
            JwrManager,
          };
          let JwrMonthWises = [ytdData, ...newData.JwrMonthWises];
          newData.JwrMonthWises = JwrMonthWises;
          console.log({ newData });
          setLoader(false);
          setData(newData);
          setMenuCollaps(null);
        } else {
          setLoader(false);
          setData(e.data.Data);
        }
      })
      .catch((err: any) => {
        console.log(err, "Error");
      });
  };

  const [menuCollaps, setMenuCollaps] = useState(null);
  const onTabChange = (e: any, tabIndex: any) => {
    setMenuCollaps(menuCollaps === tabIndex ? null : tabIndex);
  };

  console.log("data ==k", data);

  // date
  const [startdate, setDate] = useState("");

  useEffect(() => {
    const getFirstDateOfMonth = () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 2);
      return firstDay.toISOString().split("T")[0]; // format to 'YYYY-MM-DD'
    };

    setDate(getFirstDateOfMonth());
  }, []);

  const handleDateChange = (event: any) => {
    setDate(event.target.value);
  };

  const [defaultDate, setDefaultDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const formattedDate = lastDate.toISOString().split("T")[0]; // YYYY-MM-DD format
    setDefaultDate(formattedDate);
  }, []);

  const handleEndDateChange = (event: any) => {
    setDefaultDate(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>JWR Observation Report</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>JWR Observation Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/layout/reports"}>Reports</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  JWR Observation Report
                </li>
              </ol>
            </nav>
          </div>
          {role !== "MR" && (
            <div className="col-md-6">
              <div className="pull-right">
                <Button
                  text={`JWR`}
                  onclick={() => {
                    navigate("/layout/eventmanagement/jwrobservation");
                  }}
                  color="secondary"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((productinfo: any) => {
            onSubmit(productinfo);
          })}
        >
          <div className="row">
            <Input
              containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
              label="Start Date"
              type="date"
              isRequired
              errorMessage={errors?.StartDate?.message}
              onChange={(e: any) => {
                clearErrors(["StartDate"]);
                handleDateChange(e);
              }}
              // register={{
              //   ...register("StartDate", {
              //     required: {
              //       value: true,
              //       message: "Start Date is required",
              //     },
              //   }),
              // }}
              value={startdate}
            />

            <Input
              containerClass={role === "MR" ? "col-lg-6" : "col-lg-4"}
              label="End Date"
              type="date"
              isRequired
              errorMessage={errors?.EndDate?.message}
              onChange={(e: any) => {
                clearErrors(["EndDate"]);
                handleEndDateChange(e);
              }}
              value={defaultDate}
              // register={{
              //   ...register("EndDate", {
              //     required: {
              //       value: true,
              //       message: "End Date is required",
              //     },
              //   }),
              // }}
            />

            {role !== "MR" && (
              <ReactSelect
                containerClass="col-lg-4"
                control={control}
                api={process.env.REACT_APP_EMPLOYEE_WITH_USERID_API}
                keys={{ label: "Value", value: "Id" }}
                onChange={(e: any) => {
                  setValue("EmployeeId", e?.value);
                  setSelectedEmployee(e);
                }}
                value={selectedEmployee}
                name="EmployeeId"
                label="Employee"
              />
            )}

            <div className="col-md-12 text-center">
              {!loader ? (
                <button type="submit" className="btn btn-outline-primary">
                  Submit
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                  disabled
                >
                  Submit <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      {data !== null && (
        <div className="pd-20 card-box mb-30">
          {!loader ? (
            <>
              <table className="table table-striped table-bordered">
                <tr>
                  <td
                    className="col-md-6"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    Employee Name
                  </td>
                  <td
                    className="col-md-6"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    {data?.EmployeeName}
                  </td>
                </tr>
                <tr>
                  <td
                    className="col-md-6"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    Designation Name
                  </td>
                  <td
                    className="col-md-6"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    {data?.DesignationName}
                  </td>
                </tr>
                <tr>
                  <td
                    className="col-md-6"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    Hq Name
                  </td>
                  <td
                    className="col-md-6"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    {data?.HqName}
                  </td>
                </tr>
              </table>
              {data.JwrMonthWises.map((e: any) => {
                console.log(e, "this is e of paramter");
                return (
                  <Accordion
                    activeIndex={menuCollaps == e.Title ? 0 : null}
                    onTabChange={(d) => onTabChange(d, e.Title)}
                  >
                    <AccordionTab header={e.Title}>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                          <tr>
                            <td
                              rowSpan={2}
                              style={{ paddingTop: 10, paddingBottom: 10 }}
                            ></td>
                            <td
                              colSpan={5}
                              style={{ paddingTop: 10, paddingBottom: 10 }}
                            >
                              {e?.Title}
                            </td>
                          </tr>

                          <tr>
                            <>
                              <td
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                Total
                              </td>
                              <td
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                A
                              </td>
                              <td
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                B
                              </td>
                              <td
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                C
                              </td>
                              <td
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                O
                              </td>
                            </>
                          </tr>

                          <tr>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              Doctor Visit
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              {e?.TotalDoctorVisit}
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              {e?.Total_A_DoctorVisit}
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              {e?.Total_B_DoctorVisit}
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              {e?.Total_C_DoctorVisit}
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              {e?.Total_Other_DoctorVisit}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              Chemist Visit
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                              colSpan={5}
                            >
                              {e?.TotalChemistVisit}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              Hospital Visit
                            </td>
                            {/* )} */}
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                              colSpan={5}
                            >
                              {e?.TotalHospitalVisit}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              Stockist Visit
                            </td>
                            <td
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                              colSpan={5}
                            >
                              {e?.TotalStockistVisit}
                            </td>
                          </tr>
                          <>
                            {e.JwrManagers?.map((d: any, index: number) => {
                              return (
                                <>
                                  <tr
                                    style={{
                                      textAlign: "center",
                                      backgroundColor: "sandybrown",
                                    }}
                                  >
                                    <td
                                      colSpan={6}
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {d.ManagerName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        backgroundColor: "#459543",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      Observation during Joint working (Rating 1
                                      - 6)
                                    </td>
                                    <td
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        backgroundColor: "#459543",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                      colSpan={5}
                                    >
                                      {e.Title == "YTD"
                                        ? `Average Score`
                                        : `Month's Score`}
                                    </td>
                                  </tr>
                                  {d.JwrLists?.map((list: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              paddingTop: 10,
                                              paddingBottom: 10,
                                            }}
                                          >
                                            {list.Title}
                                          </td>
                                          <td
                                            style={{
                                              paddingTop: 10,
                                              paddingBottom: 10,
                                            }}
                                            colSpan={5}
                                          >
                                            {list.Score == null
                                              ? "-"
                                              : list.Score}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr>
                                    <>
                                      <td
                                        style={{
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                          backgroundColor: "#263475",
                                          color: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        Total JFWR Points
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                          backgroundColor: "#263475",
                                          color: "white",
                                          textAlign: "center",
                                        }}
                                        colSpan={5}
                                      >
                                        {d?.JwrLists?.reduce(
                                          (a: any, { Score }: any) => {
                                            return a + Score;
                                          },
                                          0
                                        )}
                                      </td>
                                    </>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        </table>
                      </div>
                    </AccordionTab>
                  </Accordion>
                );
              })}
            </>
          ) : (
            <div className="col-md-12 text-center">
              <Spinner width={"10rem"} height={"10rem"} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default JWRObservationReport;
