import { useEffect } from "react";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../../hooks/Api/Api";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../../common/Spinner";
import { Input } from "../../../../common/Input";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
  id?: any;
  setisOPen?: any;
  open?: any;
  fetch?: any;
  defaultValue?: any;
}
const AddEditProductLineModal = ({
  id,
  setisOPen,
  open,
  fetch,
  defaultValue,
}: iPropsmodal) => {
  const apiname = process.env.REACT_APP_PRODUCTLINE_API?.toString();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  }: any = useForm({ mode: "all" });

  const { data, isLoading: cityLoader }: any = useFetchAllID(
    apiname,
    id === 0 ? undefined : id
  );

  useEffect(() => {
    if (defaultValue) {
      setValue("ProductLineName", defaultValue.textbox);
      setValue("IsCompetitorProduct", defaultValue.checkbox);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (data) {
      console.log(data);
      reset(data);
    }
  }, [data]);

  const handleClose = () => {
    if (open == true) {
      setisOPen(false);
    }
  };

  const {
    mutate,
    isSuccess,
    isError,
    error,
    isLoading,
    data: response,
  }: any = useCreateByID(apiname, id === 0 ? undefined : id);

  const productlineFormData = (data: any) => {
    let newData: any = {};
    Object.keys(data).forEach(
      (k) =>
        (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    mutate(newData);
  };

  useEffect(() => {
    if (isSuccess) {
      if (id === 0) {
        toast.success("Added Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      } else {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        fetch(true, response.data.Data);
      }
      setisOPen(false);
    }

    if (isError) {
      if (error?.response?.data?.StatusCode !== 400) {
        toast.error(error?.response?.data?.Message, { position: "top-right" });
      } else {
        toast.error("error...", { position: "top-right" });
      }
    }
  }, [isError, isSuccess, id]);
  return (
    <>
      <Dialog
        visible={open}
        onHide={() => setisOPen(false)}
        header={`${id === 0 ? "Add" : "Edit"} Product Line`}
        modal
        style={{ width: "1100px" }}
      >
        {!cityLoader ? (
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "NumpadEnter") {
                handleSubmit(productlineFormData);
              }
            }}
            onSubmit={handleSubmit(productlineFormData)}
          >
            <div className="row">
              <Input
                containerClass="col-lg-12"
                label="Product Line Name"
                isRequired
                type="text"
                register={{
                  ...register("ProductLineName", {
                    onchange,
                    required: {
                      value: true,
                      message: "Please Enter Product Line",
                    },
                    pattern: {
                      value: /^.{1,50}$/gm,
                      message:
                        "Enter a valid product line name (1-50 alphanumeric characters only)",
                    }
                  }),
                }}
                onChange={(e:any) => {setValue("ProductLineName", e.target.value); clearErrors(["ProductLineName"])}}
                errorMessage={
                  errors?.ProductLineName?.message ||
                  error?.response?.data?.errors?.ProductLineName?.map(
                    (e: any) => e
                  )
                }
              />

              <div className="col-lg-12">
                <div className="custom-control custom-checkbox mb-5">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    {...register("IsCompetitorProduct")}
                    id="productLine"
                  />
                  <label className="custom-control-label" htmlFor="productLine">
                    Is Competitor Product
                  </label>
                </div>
              </div>

              <div className="col-lg-12 text-center mt-3">
                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                  <span className="p-button-label p-c">Back</span>
                </Button>

                {
                  id === 0  && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                    <span className="p-button-label p-c">Reset</span>
                  </Button>
                }

                {isLoading ? (
                  <Button type="submit" outlined disabled className="ml-2">
                    <span className="p-button-label p-c">
                      Submit <Spinner width={"1rem"} height={"1rem"} />
                    </span>
                  </Button>
                ) : (
                  <Button type="submit" outlined className="ml-2">
                    <span className="p-button-label p-c">Submit</span>
                  </Button>
                )}
              </div>

            </div>
          </form>
        ) : (
          <div className="pd-20 card-box mb-30 text-center">
            <Spinner width={"15rem"} height={"15rem"} />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AddEditProductLineModal;
