import React, { useEffect, useState } from "react";
import { useCreateByID } from "../hooks/Api/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Textarea from "./Textarea";
import ReactSelect from "./ReactSelect";
import axiosInstance from "../_helper/Interceptor";

interface iPropsmoadl {
  id?: any;
  type?: any;
  setisOPen?: any;
  open?: any;
}

const DCRActionModal = ({ id, type, setisOPen, open }: iPropsmoadl) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors
  }: any = useForm({ mode: "all" });

  const apiname = process.env.REACT_APP_UPDATEDOCTORVISIT_API?.toString();

  const getDoctorVisitAPI = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_DOCTORVISITGETBYID_API}?id=${id}&visitType=${type}`
      )
      .then((res) => {
        setValue("Remark", res.data.Data.Remark);
        let WorkingWith = res?.data?.Data?.jointWorks?.map((d: any) => ({
          label: d.Name,
          value: d.Id,
        }));
        setValue("WorkingWith", WorkingWith);
        setselectedworking(WorkingWith);
        return res.data.Data;
      })
      .catch((error) => {
        toast.error(`${error.message}`, { position: "top-right" });
      });
  };

  useEffect(() => {
    if (id) {
      getDoctorVisitAPI();
    }
  }, [id]);

  const { mutate, isSuccess, isError, error }: any = useCreateByID(apiname);

  const [selectedworking, setselectedworking]: any = useState([]);

  const multiData = (data: any) => {
    debugger;
    let temp = {
      Id: Number(id),
      VisitType: Number(type),
      WorkingWith: data.WorkingWith.map((item: any) => item.value),
      Remark: data.Remark,
    };

    console.log(temp, "temp");
    mutate(temp);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      toast.success("Successfull", { position: "top-right" });
      window.location.reload();
    }
  }, [isSuccess]);

  const handleClose = () => {
    clearErrors();
    if (open == true) {
      setisOPen(false);
    }
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-body">
          <form
            onSubmit={handleSubmit((productinfo: any) => {
              multiData(productinfo);
            })}
          >
            <ReactSelect
              containerClass="col-lg-12"
              label="Working With"
              name={"WorkingWith"}
              isRequired
              Multi
              onChange={(e: any) => {
                setselectedworking(e);
                setValue("WorkingWith", e);
              }}
              value={selectedworking}
              api={process.env.REACT_APP_EMPLOYEEGETALLMRMANAGER_API}
              keys={{ label: "Value", value: "Id" }}
              control={control}
              errorMessage={errors?.WorkingWith?.message}
              rules={{ required: { value: true, message: "Working With is required" }, }}
            />

            <Textarea
              containerClass="col-lg-12"
              label="Remarks"
              rows={2}
              isRequired
              maxLength={50}
              errorMessage={errors?.Remark?.message}
              register={{
                ...register("Remark", {
                  required: {
                    value: true,
                    message: "Remarks is required",
                  },
                  pattern: {
                    value: /^(?!\s)[ A-Za-z0-9$&+,:;=?@#|'<>.-^*()%!]+(?!\s)$/,
                    message:
                      "Enter Valid Remarks and Remove whitespace from both sides of a string",
                  },
                }),
              }}
            />
            <div className="col-lg-12 text-center">
              <button
                type="button"
                className="btn btn-secondary mr-1"
                onClick={handleClose}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DCRActionModal;
