import { useState } from "react";
import { PageHeader } from "../../../common/PageHeader";
import { Helmet } from "react-helmet";
import DatatableCommon from "../../../common/DatatableCommon";
import { DeleteModal } from "../../../common/DeleteModal";
import { Action } from "../../../common/Action";
import { NullHandler } from "../../../common/NullHandler";
import moment from "moment";
import AddEditInventoryVendor from "./AddEditInventoryVendor";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const InventoryVendor = () => {
  const apiname = process.env.REACT_APP_INVENTORYVENDOR_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);

  const [selectedVendor, setSelectedVendor]: any = useState([]);
  const [bool, setbool]: any = useState(null);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };

  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "2rem"
    },
    {
      header: "Action",
      sortable: false,
      body: (e: any) => actionVendorTemplate(e),
      width: "1vw",
    },
    {
      field: "VendorName",
      header: "Vendor Name",
      bodyClassName: "text-info h6",
      sortable: true,
      body: null,
      width: "10vw",
    },
    {
      field: "Address",
      header: "Address",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Address);
      },
      width: "10vw",
    },
    // {
    //   field: "HSNName",
    //   header: "HSN",
    //   sortable: true,
    //   body: (data: any) => {
    //     return NullHandler(data.HSNName);
    //   },
    //   width: "10vw",
    // },
    {
      field: "Gstno",
      header: "GST NO.",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Gstno);
      },
      width: "10vw",
    },
    {
      field: "Phone",
      header: "Phone",
      filter: true,
      body: (data: any) => {
        return NullHandler(data.Phone);
      },
      sortable: true,
      filterPlaceholder: "Search by Phone",
      width: "10vw",
    },
    {
      field: "Discount",
      header: "Discount",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Discount);
      },
      width: "10vw",
    },
    {
      field: "CompanyName",
      header: "Company",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.CompanyName);
      },
      width: "10vw",
    },
    {
      field: "FssAi",
      header: "FSSAI",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.FssAi);
      },
      width: "10vw",
    },

    {
      field: "DlNo1",
      header: "DL No1",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.DlNo1);
      },
      width: "10vw",
    },
    {
      field: "DlNo2",
      header: "DL No 2",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.DlNo2);
      },
      width: "10vw",
    },
    {
      field: "Nrx",
      header: "NRX",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.Nrx);
      },
      width: "10vw",
    },
    {
      field: "FssAiExpiryDate",
      header: "FSSAI ExpiryDate",
      sortable: true,
      dataType: "date",
      body: (data: any) => {
        return NullHandler(
          data.FssAiExpiryDate == null
            ? ""
            : moment(data.FssAiExpiryDate).format("DD MMM, YYYY")
        );
      },
      width: "10vw",
    },
    {
      field: "DlNo1ExpiryDate",
      header: "DL No1 Expiry Date",
      dataType: "date",
      sortable: true,
      body: (data: any) => {
        return NullHandler(
          data.DlNo1ExpiryDate == null
            ? ""
            : moment(data.DlNo1ExpiryDate).format("DD MMM, YYYY")
        );
      },
      width: "10vw",
    },
    {
      field: "DlNo2ExpiryDate",
      header: "DL No2 Expiry Date",
      dataType: "date",
      sortable: true,
      body: (data: any) => {
        return NullHandler(
          data.DlNo2ExpiryDate == null
            ? ""
            : moment(data.DlNo2ExpiryDate).format("DD MMM, YYYY")
        );
      },
      width: "10vw",
    },
    {
      field: "NrxExpiryDate",
      header: "NRX ExpiryDate",
      dataType: "date",
      sortable: true,
      body: (data: any) => {
        return NullHandler(
          data.NrxExpiryDate == null
            ? ""
            : moment(data.NrxExpiryDate).format("DD MMM, YYYY")
        );
      },
      width: "10vw",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);
  const actionVendorTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };
  const filterJson = {
    Phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },

  }
  return (
    <>
      <Helmet>
        <title>Vendor</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Inventory Vendor"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/InventoryMaster"}
          linkflagtitle={"Master"}
        />

        <DatatableCommon
          apiname={apiname}
          setselecteditem={setSelectedVendor}
          selecteditem={selectedVendor}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditInventoryVendor
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
};

export default InventoryVendor;
