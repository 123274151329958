import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Spinner } from '../../../common/Spinner';
import { ErrorMessage } from '../../../common/ErrorMessage';
import { toast } from 'react-toastify';
import axiosInstance from '../../../_helper/Interceptor';
import { useCreateByID } from '../../../hooks/Api/Api';
import { useForm } from 'react-hook-form';
import { LoaderContext } from '../../../App';

const StockistBulkUpload = () => {

    const fullScreenLoader = useContext(LoaderContext);
    const apiname = process.env.REACT_APP_STOCKISTUPLOADDOCTOEBULK_API?.toString();
    const [isLoad, setIsLoad] = useState(false);
  
    const {
      handleSubmit,
      formState: { errors },
      control,
      reset,
      register,
      setValue,
    }: any = useForm({ mode: "all" });
  
    const {
      mutate,
      isSuccess,
      isError,
      error,
      isLoading: giftLoading,
    }: any = useCreateByID(apiname);
  
    const saveFile = (data: any, fileName: string) => {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      let url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = fileName;
      a.click();
      return window.URL.revokeObjectURL(url);
    };
  
    const handleDownloadBlank = async () => {
      setIsLoad(true);
      await axiosInstance
        .get(
          `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKISTDOWNLOADBLANK_API}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          saveFile(response.data, "StockistBlankDownload.xlsx");
          setIsLoad(false);
          toast.success(`Download Blank Successfull`, { position: "top-right" });
        })
        .catch((error) => {
          var fr = new FileReader();
          fr.readAsArrayBuffer(error.response.data);
          fr.onload = function (evt: any) {
            var res = evt.target.result;
            let parsedJson = JSON.parse(
              new TextDecoder().decode(res as ArrayBuffer)
            );
            toast.error(`${parsedJson.Message}`, { position: "top-right" });
          };
          setIsLoad(false);
        });
    };
  
    const handleUpload = async (data: any) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        const formvalue: any = value;
        if (typeof value !== "object") {
          formData.append(key, formvalue);
        } else {
          formData.append(key, formvalue[0]);
        }
      }
      // mutate(formData);

      axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_STOCKISTUPLOADDOCTOEBULK_API}`,
        formData,
        { responseType: "blob" }
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(blob.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "UploadedFile.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("File Uploaded and Downloaded Successfully", {
          position: "top-right",
        });
      });
    };
  
    useEffect(() => {
      if (isSuccess) {
        toast.success(`File Uploaded Successfull`, { position: "top-right" });
        reset();
      }
      if (isError) {
        toast.error(`${error?.response?.data?.Message}`, {
          position: "top-right",
        });
      }
    }, [isSuccess, isError]);
  
    useEffect(() => {
      if (giftLoading) {
        fullScreenLoader.setloader(true);
      } else {
        fullScreenLoader.setloader(false);
      }
    }, [giftLoading]);

  return (
    <div>
      <Helmet>
        <title>Stockist Bulk Upload</title>
      </Helmet>

      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>Stockist Bulk Upload</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Stockist Bulk Upload
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6">
            {!isLoad ? (
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={handleDownloadBlank}
              >
                Download Blank
              </button>
            ) : (
              <button
                className="btn btn-outline-primary pull-right"
                type="button"
                onClick={handleDownloadBlank}
                disabled
              >
                Download Blank <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="pd-20 card-box mb-30">
        <div className="title mb-20">
          <h5 className="text-blue">Upload Excel</h5>
        </div>
        <form
          onSubmit={handleSubmit((data: any) => {
            handleUpload(data);
          })}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-form-label">
                  Select File <span className="text-danger">*</span>
                </label>
                <input
                  {...register("StockistBulkExcel", {
                    required: {
                      value: true,
                      message: "Please Upload Excel",
                    },
                  })}
                  type="file"
                  className="form-control"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <ErrorMessage message={errors?.StockistBulkExcel?.message} />
              </div>
            </div>

            <div className="col-lg-12 text-center">
              {!giftLoading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Upload
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Uploading...
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StockistBulkUpload
