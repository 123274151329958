import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import AddEditChemistCategoryModal from "./AddEditChemistCategoryModal";
import DatatableCommon from "../../../common/DatatableCommon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NullHandler } from "../../../common/NullHandler";


export default function () {
  const apiname = process.env.REACT_APP_CHEMISTCATEGORY_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const setDeleteidtoDelete = (id: any) => {
    setdeleteID(id);
  };

  const [selectedchemistcategory, setselectedchemistcategory]: any = useState([]);
  const multi = selectedchemistcategory?.map((e: any) => { return e.ChemistCategoryId })
  useEffect(() => {
    // console.log(multi, "smultichemistcategory")
    // console.log(typeof (multi), "typeof")
    // console.log(selectedchemistcategory.length)
  }, [selectedchemistcategory])

  const filterJson = {
    ChemistCategoryName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    ChemistCategoryOrder: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  }

  const columns = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      field: "action",
      header: "Action",
      sortable: false,
      body: (e: any) => actionChemistCategoryTemplate(e),
      width: "10%",
    },
    {
      field: "ChemistCategoryName",
      header: "Chemist Category Name",
      filterPlaceholder: "Search by chemist category name",
      filter: true,
      bodyClassName: "text-info h6",
      sortable: true,
      body: null,
      width: "10vw",
    },
    {
      field: "ChemistCategoryOrder",
      header: "Chemist Category Order",
      filterPlaceholder: "Search by chemist category order",
      filter: true,
      dataType: "numeric",
      sortable: true,
      body: (data: any) => {
        return NullHandler(data.ChemistCategoryOrder);
      },
      width: "10vw",
    },

  ];

  const [updateId, setupdateId]: any = useState(null);

  const actionChemistCategoryTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.ChemistCategoryId}
          editModal={() => {
            setupdateId(id.ChemistCategoryId);
          }}
          displayid={setDeleteidtoDelete}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Chemist Category</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Chemist Category"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/chemistattributes"}
          linkflagtitle={"Chemist Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          multiDelete={(data: boolean) => { if (data) { setdeleteID(selectedchemistcategory?.length === 0 ? null : multi) } }}
          setselecteditem={setselectedchemistcategory}
          selecteditem={selectedchemistcategory}
          columnData={columns}
          refetchData={bool}
          setrefetchData={setbool}
          // check={true}
          filterJson={filterJson}
        />

        {updateId !== null && (
          <AddEditChemistCategoryModal
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}

      </div>

      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />
    </>
  );
}
