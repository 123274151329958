import React from 'react';
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateByID, useFetchAllID } from "../../../hooks/Api/Api";
import { Input } from "../../../common/Input";
import { Spinner } from "../../../common/Spinner";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface iPropsmodal {
    id?: any;
    setisOPen?: any;
    open?: any;
    fetch?: any;
}

export const AddEditEventType = ({
    id,
    setisOPen,
    open,
    fetch,
}: iPropsmodal
) => {

    const apiname = process.env.REACT_APP_EVENTTYPE_API?.toString();

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        reset,
    }: any = useForm({ mode: "all" });

    const { data, isLoading: SampleLoader }: any = useFetchAllID(
        apiname,
        id === 0 ? undefined : id
    );

    useEffect(() => {
        if (data) {
            reset(data);
        }
    }, [data]);

    const {
        mutate,
        isSuccess,
        isError,
        error,
        isLoading,
        data: response,
    }: any = useCreateByID(apiname, id === 0 ? undefined : id);

    const EventTypeFormData = (data: any) => {

        for (let prop in data) {
            if (
                data[prop] === null ||
                data[prop] === undefined ||
                data[prop] === "" ||
                data[prop]?.length == 0
            ) {
                delete data[prop];
            }
        }

        let newData: any = {};

        Object.keys(data).forEach(
            (k) =>
                (newData[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
        );

        mutate(newData);
    };

    useEffect(() => {
        if (isSuccess) {
            if (id === 0) {
                toast.success("Added Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            } else {
                toast.success("Updated Successfully", { position: "top-right" });
                fetch(true, response.data.Data);
            }
            setisOPen(false);
        }

        if (isError) {
            if (error?.response?.data?.StatusCode !== 400) {
                toast.error(error?.response?.data?.Message, { position: "top-right" });
            } else {
                toast.error("error...", { position: "top-right" });
            }
        }
    }, [isError, isSuccess, id]);


    return (


        <>
            <Dialog
                visible={open}
                onHide={() => setisOPen(false)}
                header={`${id === 0 ? "Add" : "Edit"} Event Type`}
                modal
                style={{ width: "1100px" }}
            // footer={
            //     <div className="text-center">
            //         <Button
            //             label="Back"
            //             severity="secondary"
            //             onClick={() => setisOPen(false)}
            //             outlined
            //         />
            //         {id === 0 ? (
            //             <Button
            //                 label="Reset"
            //                 severity="danger"
            //                 onClick={() => {
            //                     reset();
            //                 }}
            //                 outlined
            //             />
            //         ) : (
            //             ""
            //         )}

            //         {isLoading ? (
            //             <Button
            //                 onClick={handleSubmit(EventTypeFormData)}
            //                 outlined
            //                 disabled
            //             >
            //                 <span className="p-button-label p-c mr-1">Submit</span>{" "}
            //                 <Spinner width={"1rem"} height={"1rem"} />
            //             </Button>
            //         ) : (
            //             <Button onClick={handleSubmit(EventTypeFormData)} outlined>
            //                 <span className="p-button-label p-c">Submit</span>
            //             </Button>
            //         )}
            //     </div>
            // }
            >
                {!SampleLoader ? (
                    <form
                        onKeyUp={(e) => {
                            if (e.key === "Enter" || e.key === "NumpadEnter") {
                                handleSubmit(EventTypeFormData);
                            }
                        }}
                        onSubmit={handleSubmit(EventTypeFormData)}
                    >
                        <div className="row">
                            <Input
                                containerClass="col-lg-12"
                                label="Name"
                                type="text"
                                isRequired
                                onChange={(e: any) => { setValue("Name", e.target.value); clearErrors(["Name"]) }}
                                register={{
                                    ...register("Name", {
                                        required: {
                                            value: id == 0 ? true : false,
                                            message: "Name is required",
                                        },
                                        pattern: {
                                            value: /^\w[a-zA-Z\s'-]{0,50}$/,
                                            message:
                                                "Enter a valid Name (1-50 alphabet characters only)",
                                        },
                                    }),
                                }}
                                errorMessage={
                                    errors?.Name?.message ||
                                    error?.response?.data?.errors?.Name?.map(
                                        (e: any) => e
                                    )
                                }
                            />

                            <div className="col-lg-12 text-center mt-3">
                                <Button type="button" severity="secondary" outlined onClick={() => setisOPen(false)}>
                                    <span className="p-button-label p-c">Back</span>
                                </Button>

                                {
                                    id === 0 && <Button type="reset" severity="danger" onClick={() => reset()} outlined className="ml-2">
                                        <span className="p-button-label p-c">Reset</span>
                                    </Button>
                                }

                                {isLoading ? (
                                    <Button type="submit" outlined disabled className="ml-2">
                                        <span className="p-button-label p-c">
                                            Submit <Spinner width={"1rem"} height={"1rem"} />
                                        </span>
                                    </Button>
                                ) : (
                                    <Button type="submit" outlined className="ml-2">
                                        <span className="p-button-label p-c">Submit</span>
                                    </Button>
                                )}
                            </div>

                        </div>
                    </form>
                ) : (
                    <div className="pd-20 card-box mb-30 text-center">
                        <Spinner width={"15rem"} height={"15rem"} />
                    </div>
                )}
            </Dialog>
        </>
    )
}

