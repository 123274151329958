import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../_helper/Interceptor";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "../../../common/ReactSelect";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import Select from "react-select";
import { ErrorMessage } from "../../../common/ErrorMessage";

const CRMReport = () => {
  const [gridData, setgridData] = useState([]);

  const apiname = process.env.REACT_APP_GETACCOUNTCRMREPORT_API?.toString();

  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
  }: any = useForm({ mode: "all" });

  // const {  }: any = useForm();

  // datepicker

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [financialYearStart, setFinancialYearStart] = useState(
    new Date(selectedYear, 0, 1)
  );
  const [financialYearEnd, setFinancialYearEnd] = useState(
    new Date(selectedYear + 1, 11, 31)
  );

  const handleYearChange = (date: any) => {
    const year = date.getFullYear();
    setFinancialYearStart(new Date(year, 0, 1));
    setFinancialYearEnd(new Date(year + 1, 11, 31));
    onYearChange(year);
  };

  const onYearChange = (year: any) => {
    setSelectedYear(year);
  };

  // Doctor
  const [selecteddoctor, setselecteddoctor]: any = useState(null);

  // Zone
  const [selectedZone, setselectedZone] = useState([]);
  const zoneid = selectedZone?.map((e: any) => `id=${e?.value}`);

  // State
  const [selectedState, setselectedState] = useState([]);
  const stateid = selectedState?.map((e: any) => `id=${e?.value}`);
  // Region
  const [selectedrigion, setselectedrigion] = useState([]);
  const regionid = selectedrigion?.map((e: any) => `id=${e?.value}`);
  // District
  const [selecteddistrict, setselecteddistrict] = useState([]);
  const cityid = selecteddistrict?.map((e: any) => `id=${e?.value}`);
  // City
  const [selectedcity, setselectedcity] = useState([]);
  const crmreportdata = async (form_data: any) => {

    setIsLoading(true);
    // let fromDate = form_data['FromDate'];
    // let toDate = form_data['ToDate'];
    const Zone = form_data.ZoneId?.map((e: any) => `ZoneIds=${e?.value}`);
    const State = form_data.StateId?.map((e: any) => `StateIds=${e?.value}`);
    const Region = form_data.RegionId?.map((e: any) => `RegionIds=${e?.value}`);
    // const District = form_data.DistrictId?.map((e: any) => `DistrictId=${e?.value}`);
    const City = form_data.CityId?.map((e: any) => `CityId=${e?.value}`);
    let financialYear = form_data.financialYear;
    let api_with_parameter = `${process.env.REACT_APP_BASEURL
      }${apiname}?FinancialYear=${financialYear}${form_data.DoctorId == undefined
        ? ""
        : `&DoctorId=${form_data.DoctorId.value}`
      }${Region == undefined ? "" : `&${String(Region)?.replaceAll(",", "&")}`}${State == undefined ? "" : `&${String(State)?.replaceAll(",", "&")}`
      }${Zone == undefined ? "" : `&${String(Zone)?.replaceAll(",", "&")}`}${City == undefined ? "" : `&${String(City)?.replaceAll(",", "&")}`
      }`;

    await axiosInstance
      .get(api_with_parameter)
      .then((response) => {
        const Temp = response.data.map((t: any, index: any) => {
          return { ...t, srNo: index + 1 };
        });
        setDatas(Temp);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.Message}`, {
          position: "top-right",
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (datas) {
      setgridData(datas);
    }
  }, [datas]);

  function getCurrentFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    return currentMonth < 3
      ? `${currentYear - 1}-${currentYear}`
      : `${currentYear}-${currentYear + 1}`;
  }

  const [selected, setSelected] = useState(getCurrentFinancialYear());

  const handleYear = (event: any) => {
    setSelected(event.target.value);
  };

  function generateFinancialYears() {
    const currentYear = new Date().getFullYear();
    console.log(currentYear, "currentYear");

    // let ending_century = Math.ceil(currentYear / 100) * 100;
    // let starting_century = Math.floor(currentYear / 100) * 100;

    const startingYear = currentYear - 10; // Assuming financial years start from the previous year
    const endingYear = currentYear;

    const years = [];
    for (let i = startingYear; i <= endingYear; i++) {
      //   years.push(`${i}-${i + 1}`);
      years.push({
        label: `${i}-${i + 1}`,
        value: `${i}-${i + 1}`,
      });
    }
    return years;
  }

  return (
    <>
      <Helmet>
        <title>CRM Report</title>
      </Helmet>
      <div className="page-header">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="title">
              <h4>CRM Report</h4>
            </div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/layout/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  CRM Report
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="pd-20 card-box mb-30">
        <form
          onSubmit={handleSubmit((data: any) => {
            crmreportdata(data);
          })}
        >
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="col-form-label">
                  Financial Year <span className="text-danger">*</span>
                </label>
                <Select
                  {...register("financialYear", {
                    required: {
                      value: true,
                      message: "financial Year is required",
                    },
                  })}
                  options={generateFinancialYears()}
                  className="z-3"
                  isClearable
                  classNamePrefix={"z-3"}
                  onChange={(e: any) => {
                    setValue("financialYear", e?.value);
                    setSelected(e);
                  }}
                />
                <ErrorMessage message={errors?.financialYear?.message} />
              </div>
            </div>

            <ReactSelect
              containerClass="col-lg-4"
              label="Doctor"
              control={control}
              name="DoctorId"
              onChange={(e: any) => {
                setselecteddoctor(e);
                setValue("DoctorId", e?.value);
              }}
              value={selecteddoctor}
              api={process.env.REACT_APP_DOCTORDETAIL_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.DoctorId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Zone"
              control={control}
              name="ZoneId"
              Multi
              onChange={(e: any) => {
                setselectedZone(e);
                setValue("ZoneId", e);
              }}
              value={selectedZone}
              api={process.env.REACT_APP_ZONE_API}
              keys={{ label: "Value", value: "Id" }}
              errorMessage={errors?.ZoneId?.message}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="State"
              control={control}
              name="StateId"
              Multi
              onChange={(e: any) => {
                setselectedState(e);
                setValue("StateId", e);
              }}
              value={selectedState}
              api={`${process.env.REACT_APP_STATE_MULTIPLEZONE_API}?${String(
                zoneid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              errorMessage={errors?.StateId?.message}
              Disabled={String(zoneid)?.replaceAll(",", "&") ? false : true}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="Region"
              control={control}
              name="RegionId"
              Multi
              onChange={(e: any) => {
                setselectedrigion(e);
                setValue("RegionId", e);
              }}
              value={selectedrigion}
              api={`${process.env.REACT_APP_REGION_MULTIPLESTATE_API}?${String(
                stateid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              errorMessage={errors?.RegionId?.message}
              Disabled={String(stateid)?.replaceAll(",", "&") ? false : true}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="District"
              control={control}
              name="DistrictId"
              Multi
              onChange={(e: any) => {
                setselecteddistrict(e);
                setValue("DistrictId", e);
              }}
              value={selecteddistrict}
              errorMessage={errors?.DistrictId?.message}
              api={`${process.env.REACT_APP_DISTRICT_MULTIPLEREGION_API
                }?${String(regionid)?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              Disabled={String(regionid)?.replaceAll(",", "&") ? false : true}
            />

            <ReactSelect
              containerClass="col-lg-4"
              label="City"
              control={control}
              name="CityId"
              Multi
              onChange={(e: any) => {
                setselectedcity(e);
                setValue("CityId", e);
              }}
              value={selectedcity}
              errorMessage={errors?.CityId?.message}
              api={`${process.env.REACT_APP_CITY_MULTIPLEDISTRICT_API}?${String(
                cityid
              )?.replaceAll(",", "&")}`}
              keys={{ label: "Value", value: "Id" }}
              flag
              Disabled={String(cityid)?.replaceAll(",", "&") ? false : true}
            />

            <div className="col-md-12 text-center mt-30">
              {!isLoading ? (
                <button className="btn btn-outline-primary" type="submit">
                  Execute
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  disabled
                >
                  Execute <Spinner width={"1rem"} height={"1rem"} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {gridData.length > 0 ? (
        <div className="pd-20 card-box mb-30 table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Action</th>
                <th scope="col">Doctor</th>
                <th scope="col">Credit</th>
                <th scope="col">Debit</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {gridData?.map((e: any) => {

                return (
                  <>
                    <tr>
                      <td style={{ width: "3%" }}>{e.srNo}</td>
                      <td style={{ width: "8%" }}>
                        <>
                          <Link
                            to={"/layout/crm/ViewReport"}
                            // className="btn btn-secondary mx-2 p-2"
                            className="p-2"
                            state={{
                              name: e.DoctorName,
                              year: selected,
                              id: e.DoctorId,
                            }}
                          >
                            {/* View */}
                            <button className="btn p-0" title="View">
                              <i className="pi pi-eye mr-1 text-primary"></i>
                            </button>
                          </Link>

                          {/* <Link
                            to={"/layout/crm/AddCredit"}
                            className="btn btn-primary mx-2 p-2"
                            state={{ name: e.DoctorName, id: e.DoctorId }}
                          >
                            Add Credit
                          </Link> */}
                          <Link to={"/layout/crm/AddCredit"} title="Add Credit" state={{ name: e.DoctorName, id: e.DoctorId }}>
                            <button className="btn p-0" title="Add Credit">
                              <i className="pi pi-plus"></i>
                            </button>
                          </Link>
                        </>
                      </td>
                      <td>{e.DoctorName}</td>
                      <td>{e.TotalCredit}</td>
                      <td>{e.TotalDebit}</td>
                      {e.flag ? (
                        <td className="text-danger">({e.Total})</td>
                      ) : (
                        <td className={"text-success"}>{e.Total}</td>
                      )}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CRMReport;
