import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

interface iPropsmoadl {
  open?: any;
  onClose?: any;
}

const MessageModal = ({ open, onClose }: iPropsmoadl) => {
  return (
    <>
      <Dialog
        visible={open}
        onHide={onClose}
        header="Tour Plan Locked"
        modal
        style={{ width: "20vw" }}
        footer={
          <div className="col-md-12 justify-space-between align-items-center">
            <Button
              label="Ok"
              severity="danger"
              icon="pi pi-times"
              onClick={onClose}
            />
            {/* <Button label="Secondary" severity="secondary" icon="pi pi-times"  /> */}
          </div>
        }
      >
        (No Working Today)
      </Dialog>
    </>
  );
};

export default MessageModal;
