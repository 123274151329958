import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { PaginationServiceOfSFC } from "../../service/PaginationService";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";
import { Badge } from "primereact/badge";
import moment from "moment";
import axiosInstance from "../../_helper/Interceptor";
import { Button } from "primereact/button";

const SFC = () => {
  const [lazyState, setlazyState]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    search: "",
  });
  const controller = new AbortController();
  const [totalRecord, setTotalRecords] = useState(0);
  const [totalData, settotalData]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows]: any = useState(null);
  const [previousId, setPreviousId]: any = useState(1);

  let networkTimeout: any = null;

  useEffect(() => {
    loadLazyData();
    return () => {
      controller.abort();
    };
  }, [lazyState]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      PaginationServiceOfSFC.getData(lazyState, controller.signal)
        .then((data: any) => {
          const temp = data.Data.map((e: any, i: any) => {
            return {
              ...e,
              id: i + 1,
              SrNo: data.PageNumber * data.PageSize - data.PageSize + (i + 1),
            };
          });
          console.log(temp, "temp");
          settotalData(temp);
          setTotalRecords(data.TotalDataCount);
          setLoading(false);
        })
        .catch((error: any) => {
          if (controller?.signal?.reason?.code == 20) {
            console.log("Request Canceled", error);
          } else {
            setLoading(false);
            toast.error(`${error.message}`, { position: "top-right" });
          }
        });
    }, Math.random() * 1000 + 250);
  };

  const onPageChange = (e: any) => {
    setlazyState({
      ...lazyState,
      first: e.first,
      rows: e.rows,
      page: e.page + 1,
    });
  };

  const OnSortChange = (e: any) => {
    setlazyState({
      ...lazyState,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
  };

  const handleSearch = (e: any) => {
    console.log(e.target.value.length, "Length");
    if (e.target.value.length >= 3) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    } else if (e.target.value.length === 0) {
      setlazyState({
        ...lazyState,
        first: 0,
        rows: 10,
        page: 1,
        search: e.target.value,
      });
    }
  };

  const header = (
    <>
      <div className="d-flex justify-content-around row">
        <div className="col-2 form-group mt-3 text-center">
          <input
            type="search"
            onChange={handleSearch}
            className="form-control ml-2"
            placeholder="Search"
          />
        </div>
        <div className="col-8 mt-1">
          <Paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalRecord}
            pageLinkSize={3}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onPageChange={onPageChange}
          />
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );

  const [bool, setbool]: any = useState(null);
  const handleApprove = async (params: any) => {
    const data = { Id: params };
    await axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_SRCAPPROVEALLOWANCE_API}`,
        data
      )
      .then(() => {
        toast.success(`Approved Successfully`, { position: "top-right" });
        setbool(true);
      })
      .catch((error) => {
        setbool(false);
        if (error?.response?.data?.StatusCode !== 400) {
          toast.error(error?.response?.data?.Message, {
            position: "top-right",
          });
        } else {
          toast.error(`${error.message}`, { position: "top-right" });
        }
      });
  };

  const handleReject = async (params: any) => {
    const data = {
      Id: params,
    };
    await axiosInstance
      .post(
        `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_SRCREJECTALLOWANCE_API}`,
        data
      )
      .then(() => {
        toast.error(`Rejected Successfull`, { position: "top-right" });
        setbool(true);
      })
      .catch((error) => {
        if (error?.response?.data?.StatusCode !== 400) {
          toast.error(error?.response?.data?.Message, {
            position: "top-right",
          });
        } else {
          toast.error(`${error.message}`, { position: "top-right" });
        }
      });
  };

  // const statusFunction = (e: any) => {
  //   return (
  //     <span
  //       className={`badge badge-${
  //         e.StatusId == 2 ? "success" : e.StatusId == 3 ? "danger" : "primary"
  //       }`}
  //     >
  //       {e.StatusName}
  //     </span>
  //   );
  // };

  const statusFunction = (e: any) => {
    if (e.StatusName === "Pending") {
      return (
        <span>
          <Button
            type="button"
            icon="pi pi-check"
            style={{ width: "2.5rem", height: "2.5rem" }}
            severity="success"
            className="mr-1"
            rounded
            onClick={() => handleApprove(e.Id)}
          ></Button>
          <Button
            type="button"
            icon="pi pi-times"
            style={{ width: "2.5rem", height: "2.5rem" }}
            severity="info"
            className="mr-1"
            rounded
            onClick={() => handleReject(e.Id)}
          ></Button>
        </span>
      );
    }

    return (
      <span
        className={`badge badge-${
          e.StatusId == 2 ? "success" : e.StatusId == 3 ? "danger" : "primary"
        }`}
      >
        {e.StatusName}
      </span>
    );
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <DataTable value={data.ListEmployeeCallExpenses} size="small">
          <Column field="AllowanceName" header="Allowance Name"></Column>
          <Column field="Amount" header="Amount"></Column>
          <Column
            field="StatusName"
            header="Status"
            body={statusFunction}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData: any) => {
    return rowData?.ListEmployeeCallExpenses?.length > 0;
  };

  return (
    <div>
      <Helmet>
        <title>SFC</title>
      </Helmet>

      <div className="min-height-200px">
        <div className="page-header">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4>SFC</h4>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/layout/dashboard"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    SFC
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="pd-20 card-box mb-30">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                value={totalData}
                scrollable
                lazy
                dataKey="EmployeeCallId"
                showGridlines
                className="custom-datatable"
                stripedRows
                removableSort
                header={header}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                onSort={OnSortChange}
                loading={loading}
                rowHover
                size="small"
                emptyMessage="No data found."
                tableStyle={{ minWidth: "50rem" }}
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                  console.log("onRowToggle", e);
                  let expanded: any = e.data;
                  if (Object.keys(e.data).length > 1) {
                    delete expanded[previousId];
                    setExpandedRows(expanded);
                  } else {
                    setExpandedRows(expanded);
                  }
                  if (Object.keys(e.data).length !== 0) {
                    setPreviousId(Object.keys(e.data)[0]);
                  }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column
                  expander={allowExpansion}
                  style={{ width: "2%", textAlign: "center" }}
                />
                <Column field="SrNo" header="#" style={{ width: "3%" }} />
                <Column
                  field="EmployeeName"
                  header="Employee"
                  sortable
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="NoOfDoctorCalls"
                  header="No Of Doctor Calls"
                  sortable
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="NoOfChemistCalls"
                  header="No Of Chemist Calls"
                  sortable
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="Date"
                  header="Date"
                  sortable
                  style={{ width: "15%" }}
                  body={(data: any) => {
                    if (data.Date === null) {
                      return "-";
                    } else {
                      return (
                        <>
                          <Badge
                            className="m-1"
                            severity={"info"}
                            value={
                              <div
                                style={{
                                  display: "inline-block",
                                  maxWidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                }}
                                title={moment(data.Date).format("DD MMM, YYYY")}
                              >
                                {moment(data.Date).format("DD MMM, YYYY")}
                              </div>
                            }
                          ></Badge>
                        </>
                      );
                    }
                  }}
                ></Column>
                <Column
                  field="RouteName"
                  header="Route"
                  sortable
                  style={{ width: "25%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SFC;
