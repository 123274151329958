interface iPropsbutton {
  type?: `button` | `submit` | `reset`,
  text?: string
  color?: string
  icon?: string
  disabled?: true | false
  onclick?: any
  loader?: any
  key?:any
}

export const Button = ({ type, text, color, icon, disabled, onclick, loader,key}: iPropsbutton) => {
  return (
    <>
      <button type={type} className={`btn btn-outline-${color} mt-2 mx-2`} key={key} disabled={disabled} onClick={onclick}>
        {text || <i className={icon}></i>} {loader}
      </button>
    </>
  )
}
