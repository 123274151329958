import { useEffect, useState, useRef, useCallback } from "react";
import { Input } from "../../../common/Input";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import ReactSelect from "../../../common/ReactSelect";
import { useCreateByID } from "../../../hooks/Api/Api";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/Spinner";
import { useDropzone } from "react-dropzone";

const ComposeEmail = ({ onEmailSent }: any) => {
  const dropzoneStyle = {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  }: any = useForm({ mode: "all" });

  const [files, setFiles]: any = useState([]);

  useEffect(() => {
    console.log("Updated Files = ", files)
  }, [files])


  const onDrop = useCallback((acceptedFiles: any) => {
    const temp = acceptedFiles.map((e: any) => ({
      id: 0,
      file: e
    }))

    var temp_final = [...temp, ...files]

    setFiles(temp_final);
  }, [files]);


  const removeFile = (fileToRemove: any, e: any) => {
    debugger
    e.preventDefault();
    const updatedFiles = files.filter((file: any) => file !== fileToRemove);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop });

  const [selectedemployee, setselectedemployee]: any = useState([]);
  const [EmailType, setEmailType] = useState("");

  const [content, setContent] = useState();

  const handleChange = (e: any) => {
    setContent(e);
  };

  const { mutate, isSuccess, isError, error, isLoading }: any =
    useCreateByID("Reporting/Email");

  const {
    mutate: draftmutate,
    isSuccess: draftsuccess,
    isError: drafterror,
    error: dError,
    isLoading: draftLoading,
  }: any = useCreateByID("Reporting/Email");

  const onSubmit = (data: any, type: string) => {

    data.Message = content;
    data.EmailType = type;
    data.Files = files;
    console.log(data, "Email Data");
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        if (key === "Files") {
          data.Files.map((e: any, index: any) => {
            formData.append(`Files[${index}].id`, e.id);
            formData.append(`Files[${index}].File`, e.file);
          });
        } else if (key === "ToUserId") {
          data.ToUserId.map((e: any, index: any) => {
            formData.append("ToUserId[" + index + "]", e?.value);
          });
        }
      }
    }
    console.log(formData, "formData");
    if (type === "Send") {
      mutate(formData);
    } else {
      draftmutate(formData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Email Send Successfully", { position: "top-right" });
      onEmailSent("sent");
    }

    if (isError) {
      toast.error("error...", { position: "top-right" });
    }

    if (draftsuccess) {
      toast.success("Addedd to draft Successfully", { position: "top-right" });
      onEmailSent("drafts");
    }

    if (drafterror) {
      toast.error("error...", { position: "top-right" });
    }
  }, [isError, isSuccess, draftsuccess, drafterror]);


  return (
    <div className="pd-20 card-box mb-30">
      <h5 className="mb-3">New Message</h5>
      <form>
        <div className="row">
          <ReactSelect
            containerClass="col-lg-12"
            label="To"
            isRequired
            name={"ToUserId"}
            onChange={(e: any) => {
              setValue("ToUserId", e);
              setselectedemployee(e);
            }}
            value={selectedemployee}
            control={control}
            api={process.env.REACT_APP_GETEMPLOYEEFOREMAIL_API}
            // api="Employee/GetEmployeeForEmail"
            keys={{ label: "Value", value: "Id" }}
            errorMessage={
              errors?.ToUserId?.message
              // error?.response?.data?.errors?.To?.map((e: any) => e)
            }
            rules={{
              required: { value: true, message: "To is Required" },
            }}
            Multi
          />

          <Input
            containerClass="col-lg-12"
            label="Subject"
            type="text"
            isRequired
            errorMessage={
              errors?.Subject?.message
              // error?.response?.data?.errors?.Title?.map((e: any) => e)
            }
            onChange={() => clearErrors(["Subject"])}
            register={{
              ...register("Subject", {
                required: {
                  value: true,
                  message: "Subject is required",
                },
              }),
            }}
          />

          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="col-form-label">Message</label>
              <ReactQuill
                value={content}
                style={{ height: "300px" }}
                onChange={handleChange}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image", "video"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "link",
                  "video",
                ]}
              />
            </div>
          </div>

          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
            style={{
              border: "2px dashed #cccccc",
              borderRadius: "4px",
              margin: "20px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              width: "100%",
              backgroundColor: isDragActive ? "#eee" : "white",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          {files.length !== 0 && (
            <div className="col-lg-12">
              <h2>Dropped Files:</h2>
              <ul style={{ width: "100%" }}>
                {files.map((file: any, index: any) => (
                  <li
                    key={index}
                    style={{
                      width: "100%",
                      margin: "5px 0",
                      padding: "10px",
                      border: "1px solid #cccccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {file.file.name}
                    <button
                      style={{
                        marginLeft: "10px",
                        padding: "5px 10px",
                        backgroundColor: "#ff0000",
                        color: "#ffffff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={(e: any) => removeFile(file, e)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="col-lg-12 text-center mt-3">
            <button
              onClick={handleSubmit((data: any) => {
                setEmailType("Draft");
                onSubmit(data, "Draft");
              })}
              className="btn btn btn-outline-secondary mt-2 mx-2"
            >
              Add to Draft
            </button>
            {!isLoading ? (
              <button
                type="submit"
                onClick={handleSubmit((data: any) => {
                  setEmailType("Send");
                  onSubmit(data, "Send");
                })}
                className="btn btn-outline-primary mt-2 mx-2"
              >
                Send
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleSubmit((data: any) => {
                  setEmailType("Send");
                  onSubmit(data, "Send");
                })}
                disabled
                className="btn btn-outline-primary mt-2 mx-2"
              >
                Send <Spinner width={"1rem"} height={"1rem"} />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ComposeEmail;
