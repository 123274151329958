import React from 'react';
import { useEffect, useState } from "react";
import { Action } from "../../../common/Action";
import { DeleteModal } from "../../../common/DeleteModal";
import { Helmet } from "react-helmet";
import { PageHeader } from "../../../common/PageHeader";
import { AddEditAttendeeType } from "./AddEditAttendeeType";
import DatatableCommon from "../../../common/DatatableCommon";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from 'primereact/api';

export const AttendeeType = () => {
  const apiname = process.env.REACT_APP_ATTENDEETYPE_API?.toString();
  const [deleteID, setdeleteID]: any = useState(null);
  const [bool, setbool]: any = useState(null);
  const [selectedRows, setSelectedRows]: any = useState([]);

  const deleteiddetect = (row: any) => {
    setdeleteID(row);
  };
  const columnData = [
    {
      field: "srNo",
      header: "#",
      width: "5%",
    },
    {
      header: "Action",
      sortable: false,
      filter: false,
      body: (e: any) => actionAttendeeTemplate(e),
      width: "30%",
    },
    {
      field: "Name",
      header: "Attendee Type Name",
      sortable: true,
      bodyClassName: "text-info h6",
      filter: true,
      filterPlaceholder: "Search by Name",
      body: null,
      width: "10vw",
    },

  ];
  const filterJson = {
    Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
  }
  const [updateId, setupdateId]: any = useState(null);

  const actionAttendeeTemplate = (id: any) => {
    return (
      <>
        <Action
          id={id.Id}
          editModal={() => {
            setupdateId(id.Id);
          }}
          displayid={deleteiddetect}
        />
      </>
    );
  };
  return (

    <>
      <Helmet>
        <title>Attendee Type</title>
      </Helmet>
      <div className="min-height-200px">
        <PageHeader
          headTitle="Attendee Type"
          onClick={() => {
            setupdateId(0);
          }}
          id={0}
          list={true}
          linkflag
          linkflagURL={"/layout/eventattributes"}
          linkflagtitle={"Event Attributes"}
        />

        <DatatableCommon
          apiname={apiname}
          filterJson={filterJson}
          setselecteditem={setSelectedRows}
          selecteditem={selectedRows}
          columnData={columnData}
          refetchData={bool}
          setrefetchData={setbool}

        />



        {updateId !== null && (
          <AddEditAttendeeType
            fetch={(data: boolean) => {
              if (data) {
                setbool(true);
              }
            }}
            setisOPen={() => {
              return setupdateId(null);
            }}
            open={updateId !== null}
            id={updateId}
          />
        )}
      </div>
      <DeleteModal
        id={deleteID}
        deleteMessage={(data: boolean) => {
          if (data) {
            setbool(true);
          }
        }}
        apiname={apiname}
        setisOPen={() => setdeleteID(null)}
        open={deleteID != null}
      />

    </>


  )


}



